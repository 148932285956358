export default function updatePaymentBenefits(value, stepId, claim) {
  const { digitalDocumentData } = claim;

  if (stepId !== "payment") {
    return claim;
  }

  const oldStepValue = digitalDocumentData.payment || {};
  const oldBenefits = oldStepValue.benefits || {};
  const newBenefits = value.benefits || {};

  if (!oldBenefits.confirmationFile && !newBenefits.confirmationFile) {
    return claim;
  }

  if (
    oldBenefits.confirmationFile &&
    newBenefits.confirmationFile &&
    oldBenefits.confirmationFile.attachId ===
      newBenefits.confirmationFile.attachId
  ) {
    return claim;
  }

  let attaches = digitalDocumentData.attaches || [];

  if (oldBenefits.confirmationFile) {
    attaches = attaches.filter(
      (attach) => attach.attachId !== oldBenefits.confirmationFile.attachId
    );
  }

  if (newBenefits.confirmationFile) {
    attaches = attaches.concat([newBenefits.confirmationFile]);
  }

  return Promise.resolve({
    ...claim,
    digitalDocumentData: { ...digitalDocumentData, attaches },
  });
}
