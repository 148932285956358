import store from "../../store";
import promiseChain from "../../helpers/promiseChain";
// import contentDisposition from "content-disposition";
import parser from "content-disposition-parser";
import config from "../../config";
const { backendUrl } = config();

const getHeaders = (token) => {
  if (!token) return {};
  let headers = {};
  headers["Content-Type"] = "stream";
  headers["Authorization"] = `Bearer ${token}`;
  return headers;
};

export const API_URL =
  backendUrl + (backendUrl.charAt(backendUrl.length - 1) !== "/" ? "/" : "");

const createRequestBody = (url) => {
  const method = "post";
  const { token } = store.getState().authorization || {};
  const headers = getHeaders(token);
  if (url.includes("#")) url = url.replace(/#/g, "screeningSharp2358");

  return {
    url: `${API_URL}${url}`,
    method,
    headers,
    type: "application/json",
  };
};

function createRequest(request) {
  const { url, ...fetchConfig } = request;

  return promiseChain([
    () => {
      return fetch(url, fetchConfig);
    },
    (response) => {
      let headerval = response.headers.get("Content-Disposition");

      let filename =
        headerval &&
        typeof headerval === "string" &&
        headerval.includes("attachment;") &&
        headerval.includes(".zip")
          ? parser(headerval)?.filename
          : "filename.zip";

      console.log("response: ", response);

      return {
        response: response,
        filename: filename,
      };
    },
  ]);
}

export function post(url, body, action, dispatch) {
  const request = createRequestBody(url);
  request.body = JSON.stringify(body);

  return createRequest(request, action, dispatch, body);
}
