export default function localizeError(error) {
  let out = "";
  let cond = "";
  let n = error.params.limit;
  switch (error.keyword) {
    case "$ref":
      out = "не знайдена схема " + error.params.ref;
      break;
    case "additionalItems":
      out = "";
      out += "повинно мати не більше, ніж " + n + " елемент";
      if (n >= 2 && n <= 4) {
        out += "а";
      } else if (n !== 1) {
        out += "ів";
      }
      break;
    case "additionalProperties":
      out = "не повинно мати додаткові поля";
      break;
    case "anyOf":
      out = 'повинно відповідати одній із схем в "anyOf"';
      break;
    case "const":
      out = "повинно дорівнювати заданому значенню";
      break;
    case "contains":
      out = "повинно містити значення відпівідно схемі";
      break;
    case "custom":
      out = 'повинно відповідати правилу "' + error.keyword + '"';
      break;
    case "dependencies":
      out = "";
      n = error.params.depsCount;
      out += "повинно мати пол";
      if (n === 1) {
        out += "е";
      } else {
        out += "я";
      }
      out +=
        " " +
        error.params.deps +
        ", коли присутнє поле " +
        error.params.property;
      break;
    case "enum":
      out = 'повинно дорівнювати одному із значень в "enum"';
      break;
    case "exclusiveMaximum":
      out = "";
      cond = error.params.comparison + " " + error.params.limit;
      out += "повинно бути " + cond;
      break;
    case "exclusiveMinimum":
      out = "";
      cond = error.params.comparison + " " + error.params.limit;
      out += "повинно бути " + cond;
      break;
    case "false schema":
      out = "схема недійсна";
      break;
    case "format":
      out = 'має відповідати формату "' + error.params.format + '"';
      break;
    case "formatExclusiveMaximum":
      out = "formatExclusiveMaximum має бути boolean";
      break;
    case "formatExclusiveMinimum":
      out = "formatExclusiveMinimum має бути boolean";
      break;
    case "formatMaximum":
      out = "";
      cond = error.params.comparison + " " + error.params.limit;
      out += "має бути " + cond;
      break;
    case "formatMinimum":
      out = "";
      cond = error.params.comparison + " " + error.params.limit;
      out += "має бути " + cond;
      break;
    case "if":
      out = 'Повинно відповідати схемі "' + error.params.failingKeyword + '"';
      break;
    case "maximum":
      out = "";
      cond = error.params.comparison + " " + error.params.limit;
      out += "має бути " + cond;
      break;
    case "maxItems":
      out = "";
      n = error.params.limit;
      out += "повинно мати не більше, ніж " + n + " елемент";
      if (n >= 2 && n <= 4) {
        out += "а";
      } else if (n !== 1) {
        out += "ів";
      }
      break;
    case "maxLength":
      out = "";
      n = error.params.limit;
      out += "повинно бути не довше " + n + " символ";
      if (n >= 2 && n <= 4) {
        out += "а";
      } else if (n !== 1) {
        out += "ів";
      }
      break;
    case "maxProperties":
      out = "";
      n = error.params.limit;
      out += "повинно мати не більше " + n + " пол";
      if (n === 1) {
        out += "е";
      } else if (n >= 2 && n <= 4) {
        out += "я";
      } else {
        out += "ів";
      }
      break;
    case "minimum":
      out = "";
      cond = error.params.comparison + " " + error.params.limit;
      out += "має бути " + cond;
      break;
    case "minItems":
      out = "";
      n = error.params.limit;
      out += "повинно мати не меньше " + n + " елемент";
      if (n >= 2 && n <= 4) {
        out += "а";
      } else if (n !== 1) {
        out += "ів";
      }
      break;
    case "minLength":
      out = "";
      n = error.params.limit;
      out += "має містити мінімум " + n + " символ";
      if (n >= 1 && n <= 4) {
        out += "а";
      } else if (n !== 1) {
        out += "ів";
      }
      break;
    case "minProperties":
      out = "";
      n = error.params.limit;
      out += "повинно мати не меньше " + n + " пол";
      if (n === 1) {
        out += "е";
      } else if (n >= 2 && n <= 4) {
        out += "я";
      } else {
        out += "ів";
      }
      break;
    case "multipleOf":
      out = "має бути кратным " + error.params.multipleOf;
      break;
    case "not":
      out = 'повинно не відповідати схемі в "not"';
      break;
    case "oneOf":
      out = 'повинно відповідати в точності одній схемі в "oneOf"';
      break;
    case "pattern":
      out = 'повинно бути в форматі "' + error.params.pattern + '"';
      break;
    case "patternRequired":
      out =
        'повинно мати поле, відповідно прикладу "' +
        error.params.missingPattern +
        '"';
      break;
    case "propertyNames":
      out = "ім'я поля '" + error.params.propertyName + "' не відповідає схемі";
      break;
    case "required":
      out = "обов'язкове поле"; // + (error.params.missingProperty);
      break;
    case "switch":
      out =
        "повинно відповідати правилу " + error.params.caseIndex + ' в "switch"';
      break;
    case "type":
      out = "повинно бути " + error.params.type;
      break;
    case "uniqueItems":
      out =
        "не повинно мати повторних елементів (" +
        error.params.j +
        " та " +
        error.params.i +
        " однакові)";
      break;
    default:
      break;
  }

  error.message = out;
  return error;
}
