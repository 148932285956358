export default {
  individual: "Основна роль",
  lawyer: "Адвокат",
  legalEntityAgent: "Представник",
  representative: "Представник",
  secretary: "Секретар суд.засідання",
  judge: "Суддя",
  judgeAssistant: "Помічник судді",
  judgeMember: "Член ВРП/ВККС",
  executiveDocumentsRegister: "Реєстратори ЕВД",
  courtExecutor: "Державні Виконавці",
  courtDecisions: "Особи, що мають повний доступ до ЄДРСР",
  courtHead: "Керівник суду/ОСВ",
  courtDeputyHead: "Заступник керівника суду/ОСВ",
  courtOfficer: "Працівник суду/ОСВ",
  statistician: "Статистик",
  videoConference: "Відеоконференція",
  humanResources: "Інспектор по кадрам",
  accountant: "Бухгалтер",
  police: "Слідчий",
  nabu: "Прокурор",
  tester: "Бета тестер",
};
