import React, { Fragment } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";

import { AccountCircle, CheckCircleOutline, Timer } from "@material-ui/icons";
import cx from "classnames";

import humanDateTimeFormat from "../../../helpers/humanDateFormat";

import { Button } from "../../../components";
import EDSForm from "../../../components/EDSForm";

import * as statuses from "../../../variables/claimStatuses";

import PdfDocument from "../../../components/PDF";

import getSigners from "../helpers/getSigners";
import { Link } from "react-router-dom";
import redirectes from "../../../helpers/documentRedirected";

const checkPermission = (
  claim,
  globalPermissions,
  itemShareList,
  userId,
  imNotInList
) => {
  const { owner, caseId, proceedingId, category, createdBy } = claim;
  let havePermission =
    userId === owner ||
    !imNotInList ||
    category === 4 ||
    (userId === createdBy && category === 5);

  if (!havePermission) {
    havePermission =
      !!globalPermissions.find(
        (item) =>
          (item.createdBy === owner && item.allowCommit === 1) ||
          (item.allowedBy === owner &&
            item.allowCommit === 1 &&
            item.userId === userId)
      ) ||
      !!(
        (proceedingId || caseId) &&
        !!itemShareList.find(
          (item) =>
            (item.targetId === caseId || item.targetId === proceedingId) &&
            item.userId === userId &&
            item.allowCommit === 1
        )
      );
  }
  return havePermission;
};

const SigningStatus = ({ signatures, person, t, classes }) => {
  const signature = signatures.find((sign) => sign.userId === person.id);
  const signed = signature && signature.createdAt;

  return (
    <span className={classes.displayFlex}>
      {signed ? (
        <CheckCircleOutline className={classes.success} />
      ) : (
        <Timer className={classes.warning} />
      )}
      <span className={classes.label}>
        {signed ? t("SIGNED") : t("WAITING_FOR_SIGN")}
      </span>
    </span>
  );
};

SigningStatus.propTypes = {
  signatures: PropTypes.array.isRequired,
  person: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const Signing = ({
  t,
  claim,
  claim: {
    state: claimState,
    signatures,
    digitalDocumentData,
    createdBy,
    createdByName,
    category,
  },
  jsonSchema: { properties },
  showSignModal,
  showSelectUsers,
  usersToSend,
  usersArray,
  manifest,
  pdf,
  removePdf,
  toggleSignModal,
  toggleSelectUser,
  backToEditClaim,
  toggleIsSendToUser,
  commitDocument,
  handleSendToParties,
  handleSelectKey,
  doc,
  userId,
  setId,
  classes,
  globalPermissions,
  itemShareList,
  noCheckPermission,
}) => {
  let signers = getSigners(digitalDocumentData, properties);
  let imNotInList =
    !signers.length || !signers.find((signer) => signer.id === userId);
  const havePermission =
    noCheckPermission ||
    checkPermission(
      claim,
      globalPermissions,
      itemShareList,
      userId,
      imNotInList
    );
  if (!signers.length && createdBy !== userId && havePermission) {
    signers = [{ name: createdByName, id: createdBy }];
    imNotInList = createdBy !== userId && category !== 4;
  }

  const mySignature = signatures.find((sign) => sign.userId === userId);

  const waitingForSign = signers.filter(
    (signer) =>
      !signatures.find((sign) => sign.userId === signer.id && sign.createdAt)
  );
  const notHaveSignPermission =
    !noCheckPermission &&
    (!!(signers.length && imNotInList) ||
      (userId !== createdBy && category === 5));

  const getBtnsStyle = () => {
    if (usersToSend.find((user) => user?.isToSend)) {
      return {
        width: "calc(100% - 10px)",
        paddingLeft: "5px",
        paddingRight: "5px",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-between",
      };
    } else {
      return {
        width: "calc(100% - 10px)",
        paddingLeft: "5px",
        paddingRight: "5px",
      };
    }
  };

  return (
    <Fragment>
      {!!(claim && claim.caseId && claim.proceedingId) && (
        <Link to={redirectes[6].getUrl(claim)} className={classes.buttonLink}>
          <Button color="yellow" variant="contained">
            {t(`REDIRECT_TO_CASE`)}
          </Button>
        </Link>
      )}
      <PdfDocument
        pdf={pdf}
        doc={doc}
        setId={(elementName) => setId(`pdf-dopcument-${elementName}`)}
      />
      {claimState === statuses.SENDED_TO_PARTIES && !manifest && (
        <Button color="yellow" onClick={toggleSignModal}>
          {t("FINISH_TO_COURT")}
        </Button>
      )}
      {claimState === statuses.CREATING_STATUS && !manifest && (
        <Button
          color="transparent"
          onClick={removePdf}
          id={setId("remove-pdf-button")}
          setId={(elmentName) => setId(`remove-pdf-${elmentName}`)}
        >
          {t("CONTINUE_EDITING")}
        </Button>
      )}

      {havePermission && claimState !== statuses.SENDED_TO_PARTIES && (
        <Button
          color="yellow"
          onClick={toggleSignModal}
          disabled={
            !pdf ||
            !!(mySignature && mySignature.createdAt && claimState !== 2) ||
            notHaveSignPermission
          }
          id={setId("finish-button")}
          setId={(elmentName) => setId(`finish-${elmentName}`)}
        >
          {waitingForSign.length <= 1 ? t("FINISH") : t("SIGN_DOCUMENT")}
        </Button>
      )}
      {notHaveSignPermission && (
        <Typography
          className={cx(classes.error, classes.noPermError)}
          component="h2"
          id={setId("not-have-perm")}
        >
          {t("NO_PERM")}
        </Typography>
      )}

      <Divider />

      {signers && (
        <List>
          {signers.map((person, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <AccountCircle className={classes.signingAvatar} />
              </ListItemAvatar>
              <ListItemText
                className={classes.signingNameAndStatus}
                primary={person.name || person.companyName}
                secondary={
                  <SigningStatus
                    signatures={signatures}
                    person={person}
                    classes={classes}
                    t={t}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      )}
      {showSignModal && (
        <Dialog
          open={showSignModal}
          onClose={toggleSignModal}
          id={setId("dialog")}
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
        >
          <DialogTitle
            id={setId("dialog-title")}
            className={classes.dialogContentWrappers}
          >
            {t("SIGNING_DOCUMENT")}
          </DialogTitle>
          <DialogContent
            id={setId("dialog-content")}
            className={classes.dialogContentWrappers}
          >
            <EDSForm onSelectKey={handleSelectKey} />
          </DialogContent>
        </Dialog>
      )}
      {showSelectUsers && claimState !== statuses.SENDED_TO_PARTIES && (
        <Dialog
          open={showSelectUsers}
          onClose={toggleSelectUser}
          id={setId("dialog")}
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
        >
          <DialogTitle
            id={setId("dialog-title")}
            className={classes.dialogContentWrappers}
          >
            {t("SELECT_USERS")}
            <div
              style={{
                display: "inline",
                marginLeft: "146px",
                fontSize: "14px",
              }}
            >
              <a
                href="https://wiki-ccs.court.gov.ua/w/Send_doc_to_participants"
                target="_blank"
                rel="noopener noreferrer"
              >
                ДЕТАЛЬНО
              </a>
            </div>
          </DialogTitle>
          <DialogContent
            id={setId("dialog-content")}
            className={classes.dialogContentWrappers}
          >
            {/*Отрисовка для НЕ найденных пользователей*/}
            {/* {usersArray.length && usersArray.map(user =>
                        <div style={{display: "flex"}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={false}
                                    disabled={false}
                                    onChange={()=> {}}
                                    color="primary"
                                />
                            }
                            label={`${user.name || user.companyName} (${user.ipn || user.edrpou})`}
                        />
                        </div>
                    )} */}
            {/*Отрисовка для найденных пользователей*/}
            {usersToSend.length &&
              usersToSend.map((user, index) => {
                return (
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <div style={{ marginBottom: "16px" }}>
                          <Checkbox
                            checked={user.isToSend}
                            disabled={!user.isFound}
                            onChange={({ target: { checked } }) => {
                              toggleIsSendToUser(usersToSend, index, checked);
                            }}
                            color="primary"
                          />
                        </div>
                      }
                      disabled={!user.isFound}
                      label={
                        <div>
                          {user.name || user.companyName},
                          {user.isLegal ? "ЄДРПОУ" : "РНОКПП"}:{user.code}
                          <br />
                          {user.createdAt
                            ? `Дата реєстрації: ${humanDateTimeFormat(
                                user.linkedAt
                              )}`
                            : "Кабінет не зареєстрований"}
                        </div>
                      }
                    />
                  </div>
                );
              })}
          </DialogContent>
          <div style={getBtnsStyle()}>
            <Button
              color="yellow"
              variant="contained"
              onClick={backToEditClaim}
              id={setId("back-to-edit-claim-btn")}
            >
              {"Повернутись"}
            </Button>
            <Button
              color="yellow"
              variant="contained"
              onClick={commitDocument}
              //disabled={!pdf || !!(mySignature && mySignature.createdAt) || notHaveSignPermission}
              id={setId("skip-send-to-users-btn")}
              //setId={"send-to-users-btn"}
            >
              {t("SKIP_SEND_TO_USERS")}
            </Button>
            {Array.isArray(usersToSend) &&
              usersToSend?.length &&
              usersToSend.find((user) => user?.isToSend) && (
                <Button
                  color="yellow"
                  variant="contained"
                  onClick={handleSendToParties}
                  //disabled={!pdf || !!(mySignature && mySignature.createdAt) || notHaveSignPermission}
                  id={setId("finish-send-to-users-btn")}
                  //setId={"send-to-users-btn"}
                >
                  {t("SEND_TO_USERS")}
                </Button>
              )}
          </div>
        </Dialog>
      )}
    </Fragment>
  );
};

Signing.propTypes = {
  claim: PropTypes.object.isRequired,
  globalPermissions: PropTypes.array.isRequired,
  showSignModal: PropTypes.bool.isRequired,
  showSelectUsers: PropTypes.bool.isRequired,
  usersArray: PropTypes.array,
  usersToSend: PropTypes.array,
  manifest: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pdf: PropTypes.string,
  removePdf: PropTypes.func.isRequired,
  toggleSignModal: PropTypes.func.isRequired,
  toggleSelectUser: PropTypes.func.isRequired,
  backToEditClaim: PropTypes.func.isRequired,
  toggleIsSendToUser: PropTypes.func.isRequired,
  commitDocument: PropTypes.func.isRequired,
  handleSendToParties: PropTypes.func.isRequired,
  doc: PropTypes.object,
  userId: PropTypes.string.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleSelectKey: PropTypes.func.isRequired,
  jsonSchema: PropTypes.object.isRequired,
  itemShareList: PropTypes.array.isRequired,
  noCheckPermission: PropTypes.bool,
};

Signing.defaultProps = {
  manifest: null,
  pdf: null,
  doc: null,
  setId: setComponentsId("signing"),
};

export default Signing;
