import * as api from "../services/api";
import store from "../store";

export const GET_EXECUTIVE_DOCS_INFO = "GET_EXECUTIVE_DOCS_INFO";
export const GET_EXECUTIVE_DOCS_TOTAL = "GET_EXECUTIVE_DOCS_TOTAL";
export const GET_DOCUMENT_INFO = "GET_DOCUMENT_INFO";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_PAYMENT_FORM = "GET_PAYMENT_FORM";
export const GET_EXECUTIVE_PERMISSIONS = "GET_EXECUTIVE_PERMISSIONS";

export const REQUEST_EXECUTIVE_CREATE = "REQUEST_EXECUTIVE_CREATE";
export const EXECUTIVE_CREATED = "EXECUTIVE_CREATED";
export const STORE_CLAIM_VALUES = "STORE_CLAIM_VALUES";

export const SEND_TO_ASVP = "SEND_TO_ASVP";
export const UPDATE_STATUS_ASVP = "UPDATE_STATUS_ASVP";

export const REQUEST_STATIC_ARCHIVE = "REQUEST_STATIC_ARCHIVE";

const TEMPLATES_URL = "api/templates";
const EXEC_URL = "api/executive";
const DOC_URL = "api/documents";

export async function getExecInfo(id) {
  const result = await api.get(
    `${EXEC_URL}/${id}`,
    GET_EXECUTIVE_DOCS_INFO,
    store.dispatch
  );
  if (result instanceof Error) throw result;
  return result;
}

export async function getExecOrganizations() {
  const result = await api.get(
    `${EXEC_URL}/organizations?sort=name&filter[isActive]=1`,
    GET_ORGANIZATION,
    store.dispatch
  );
  if (result instanceof Error) throw result;
  return result;
}

export async function getExecPermissions(id, execMyRole) {
  const result = await api.get(
    `${EXEC_URL}/${id}/permissions?execMyRole=${execMyRole}&allowAsvp=true`,
    GET_EXECUTIVE_PERMISSIONS,
    store.dispatch
  );
  if (result instanceof Error) throw result;
  return result;
}

export async function getDocumentInfo(id) {
  return await api.get(`${DOC_URL}/${id}`, GET_DOCUMENT_INFO, store.dispatch);
}

export function submitForExec(template, data) {
  return api
    .post(
      `${TEMPLATES_URL}/${template}/start`,
      data,
      REQUEST_EXECUTIVE_CREATE,
      store.dispatch
    )
    .then((claim) => {
      claim = {
        ...claim,
        digitalDocumentTemplateId: template,
      };
      if (!claim.message) {
        store.dispatch({
          type: EXECUTIVE_CREATED,
          payload: claim,
        });
      }
      return claim;
    });
}

export function setExecStatus(id, data) {
  return api.put(
    `${EXEC_URL}/${id}/status`,
    data,
    STORE_CLAIM_VALUES,
    store.dispatch
  );
}

export function updateASVPStatus(id) {
  return api.put(
    `${EXEC_URL}/${id}/asvp_status`,
    undefined,
    UPDATE_STATUS_ASVP,
    store.dispatch
  );
}

export async function getExecPaymentForm(id) {
  return await api.get(
    `${EXEC_URL}/${id}/payment_form`,
    GET_PAYMENT_FORM,
    store.dispatch
  );
}

export async function sendToASVP(id) {
  const result = await api.post(
    `${EXEC_URL}/${id}/send_asvp`,
    undefined,
    SEND_TO_ASVP,
    store.dispatch
  );
  if (result instanceof Error) throw result;
  return result;
}

export function downloadExecStaticArchive(id) {
  return api.get(
    `${DOC_URL}/${id}/static_file?includeSignatures=true&includeAttachments=true`,
    REQUEST_STATIC_ARCHIVE,
    store.dispatch
  );
}
