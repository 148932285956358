import PropTypes from "prop-types";

import DataSource from "./dataSource";

const { caseDataSource } = DataSource;

export const initialState = {
  init: false,
  error: "",
  loading: true,
  checked: "",
  openCaseDialog: false,
  openMarkDialog: false,
  display: "caseList",
  viewDocument: null,
  procChecked: "",
  activeProc: null,
  history: [],
  caseNotProcDoc: [],
  dataSource: caseDataSource,
  activeMark: "auto",
  newBookmarkName: "",
  openCreateClaimDialog: false,
  blockScreen: false,
  ownership: {
    claimant: true,
  },
  openPermissionDialog: false,
  activeCase: null,
  scanDocumentId: null,
  activeCaseError: "",
  dialog: "",
  pdf: null,
  doc: null,
  showSignModal: false,
  showSelectUsers: false,
  usersArray: [],
  usersToSend: [],
  signError: "",
  shareDocId: "",
  manifest: null,
  sharesChecked: [],
  changedShare: null,
  shareSubTitle: "",
  appealCourt: null,
  causeActionTypes: [0],
  appealCourtError: "",
};

export const propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  courts: PropTypes.array,
  statuses: PropTypes.array,
  memberRoles: PropTypes.array,
  judgeRoles: PropTypes.array,
  procStatuses: PropTypes.array,
  caseDocuments: PropTypes.array,
  proceedingsList: PropTypes.array,
  justiceTypes: PropTypes.array,
  userMetaData: PropTypes.object,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  owners: PropTypes.array,
  userId: PropTypes.string.isRequired,
};

export const defaultProps = {
  list: null,
  courts: [],
  statuses: [],
  memberRoles: [],
  judgeRoles: [],
  procStatuses: [],
  caseDocuments: [],
  proceedingsList: [],
  justiceTypes: [],
  userMetaData: {},
  owners: [],
};
