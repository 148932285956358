export default {
  EDIT_CONFERENCE: "Конференція",
  CREATE_CONFERENCE: "Створити конференцію",
  MAIN_INFO: "Заповніть усі поля",
  CONFERENCE_NAME: "Назва",
  CONFERENCE_DESCRIPTION:
    "Опишіть тему, план, орієнтовну тривалість конференції, тощо",
  MEMBERS_TITLE: "Учасники конференції",
  SELECT_MEMBERS: "Додайте учасників",
  SAVE_BUTTON: "Зберегти",
  TIME: "Час та дата",
  START_DATE: "Початок конференції",
  DURATION_HOURs: "Тривалість годин",
  DURATION_MINNUTES: "Тривалість хвилин",
  SUCCESS_DIALOG_HEADER: "Конференцію створено",
  SUCCESS_DIALOG_TEXT:
    'Конференцію створено успішно. <a href="{{meetingUrl}}">Перейти до конференції</a>',
  FOLLOW_CONFERENCE: "Перейти до конференції",
  OPEN_CONFERENCE: "Перейти до конференції",
  CLOSE_DIALOG: "Закрити",
  "Meeting name should be defined and has correct format.":
    "Назва конференції має бути заповнена",
  ERROR_DIALOG_HEADER: "Виникла помилка створення конференції",
};
