import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";

import humanDateFormat from "../../../helpers/humanDateFormat";
import objectPath from "object-path";

import { SavedDateFormulas } from "../../../helpers/savedFilterDates/saved.date.formulas";
import setComponentsId from "../../../helpers/setComponentsId";
import FilterInput from "../../../components/FilterInput";
import customInputStyle from "../../../variables/styles/customInputStyle";
import DateRangePicker from "../../../components/CustomInput/DateRangePicker_executiveDocs";
import StatusChip from "../decorators/index";
import classNames from "classnames";
import FromToNumericInput from "../../../components/CustomInput/FromToNumericInput";

const Filters = ({
  setId,
  t,
  dataSource,
  load,
  statuses,
  courts,
  roles,
  classes,
  userId,
  returnObject,
}) => {
  const initialState = {
    vpStateId: "",
    myRole: "",
    createdAt: "",
    docAcceptDate: "",
    sumUah: "",
    searchValue: "",
    sort: "",
  };

  // const sortingStatuses = sortStatuses(t, statuses);
  const sortingStatuses =
    statuses?.length > 0
      ? statuses.map((status) => ({
          ...status,
          name:
            status.name?.length > 1
              ? status.name?.[0].toUpperCase() + status.name.substring(1)
              : status.name,
        }))
      : [];

  const [localValues, setLocalValues] = useState(initialState);
  const [values, setValues] = useState(initialState);

  const [isSaveFilter, setIsSaveFilter] = useState(false);

  useEffect(() => {
    const filters = {
      ...localValues,
      vpStateId: objectPath.get(dataSource.filters, "vpStateId") || "",
      myRole: objectPath.get(dataSource.filters, "myRole") || "",
      createdAt: objectPath.get(dataSource.filters, "createdAt") || "",
      docAcceptDate: objectPath.get(dataSource.filters, "docAcceptDate") || "",
      sumUah: objectPath.get(dataSource.filters, "sumUah") || "",
      searchValue: localValues.searchValue || dataSource?.search || "",
    };
    setLocalValues({ ...filters });
    setValues({ ...filters });
  }, []);

  const clearValue = (filterName) => {
    setLocalValues({ ...localValues, [filterName]: "" });
  };

  const onChange = ({ target: { value, name: filterName } }) => {
    console.log("filterName", value);
    setLocalValues({ ...localValues, [filterName]: value });
  };

  const onIsSaveFilterChange = () => {
    setIsSaveFilter(!isSaveFilter);
  };

  const onCloseFilterCallback = () => {
    setLocalValues({ ...values, searchValue: localValues.searchValue });
  };

  const onClearCallback = () => {
    setLocalValues(initialState);
    setValues(initialState);

    let allUserFilter = localStorage.getItem("executiveDecisionsFilter");
    if (allUserFilter) {
      allUserFilter = JSON.parse(allUserFilter);
    }
    localStorage.setItem(
      "executiveDecisionsFilter",
      JSON.stringify({
        ...allUserFilter,
        [userId]: initialState,
      })
    );

    for (let filter in initialState) {
      if (filter === "searchValue") {
        dataSource.setSearch("");
        continue;
      } else if (filter === "sort") {
        dataSource.sort = {};
        continue;
      } else if (initialState[filter])
        dataSource.setFilter(filter, initialState[filter]);
      else delete dataSource.filters?.[filter];

      const { aggs } = dataSource;
      aggs[filter] = !!initialState[filter] || undefined;
      dataSource.setValue("aggs", aggs);
    }
  };

  const saveSearchValueCallback = (value) => {
    setLocalValues({ ...localValues, searchValue: value });
  };

  const onSearchCallback = (noSaving = false) => {
    if (noSaving) {
      setLocalValues({ ...values });
      return;
    }

    for (let filter in localValues) {
      if (filter === "searchValue") {
        dataSource.setSearch(localValues.searchValue);
        continue;
      } else if (localValues[filter]) {
        dataSource.setFilter(filter, localValues[filter]);
        continue;
      } else {
        delete dataSource.filters?.[filter];
      }
      const { aggs } = dataSource;
      aggs[filter] = !!localValues[filter] || undefined;
      dataSource.setValue("aggs", aggs);
    }

    setLocalValues({ ...localValues });

    if (isSaveFilter) {
      let allUserFilter = localStorage.getItem("executiveDecisionsFilter");
      if (allUserFilter) {
        allUserFilter = JSON.parse(allUserFilter);
      }

      const dateInfo = {
        createdAt: {},
        docAcceptDate: {},
      };

      if (localValues.createdAt) {
        const dateStr = localValues.createdAt.split(",");
        const savedDate = new SavedDateFormulas(dateStr?.[0], dateStr?.[1]);
        dateInfo.createdAt = {
          createdAtKDMD: savedDate.getKDMD(),
          createdAtKDDP: savedDate.getKDDP(),
          createdAtKDDZ: savedDate.getKDDZ(),
        };
      }

      if (localValues.docAcceptDate) {
        const dateStr = localValues.docAcceptDate.split(",");
        const savedDate = new SavedDateFormulas(dateStr?.[0], dateStr?.[1]);
        dateInfo.docAcceptDate = {
          docAcceptDateKDMD: savedDate.getKDMD(),
          docAcceptDateKDDP: savedDate.getKDDP(),
          docAcceptDateKDDZ: savedDate.getKDDZ(),
        };
      }

      localStorage.setItem(
        "executiveDecisionsFilter",
        JSON.stringify({
          ...allUserFilter,
          [userId]: {
            ...localValues,
            ...dateInfo.createdAt,
            ...dateInfo.docAcceptDate,
            changeFilterDate: humanDateFormat(Date.now(), {
              format: "YYYY-MM-DD",
            }),
            searchValue: localValues.searchValue,
            sort: JSON.stringify(dataSource.sort),
          },
        })
      );
      setIsSaveFilter(false);
    }
  };

  return (
    <FilterInput
      dataSource={dataSource}
      load={load}
      setId={setId}
      statuses={sortingStatuses}
      courts={courts}
      roles={roles}
      isExecutiveDocs
      onSearchCallback={onSearchCallback}
      onClearCallback={onClearCallback}
      saveSearchValueCallback={saveSearchValueCallback}
      filterSearchValue={localValues.searchValue}
      onCloseFilterCallback={onCloseFilterCallback}
      returnObject={returnObject}
      userId={userId}
    >
      {/* ФІЛЬТР ПО СТАТУСУ В РЕЄСТРІ ВД */}
      <TextField
        value={localValues.vpStateId}
        onChange={onChange.bind(this)}
        select={true}
        name="vpStateId"
        label={t("VP_STATE_ID_FILTER")}
        margin="normal"
        id={setId("select-vpStateId")}
        style={{ display: "inlineFlex" }}
        className={classNames(classes.margin, classes.textField, classes.flex)}
        SelectProps={{
          id: setId("select-vpStateId-filter"),
          SelectDisplayProps: {
            id: setId("select-vpStateId-filter-wrapper"),
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle"
              onClick={() => clearValue("vpStateId")}
            >
              <Icon style={{ fontSize: "16px" }}>close</Icon>
            </IconButton>
          ),
        }}
      >
        {sortingStatuses.map(({ id, name, color }, index) => {
          return (
            <MenuItem key={index} value={id} id={setId(`vpStateId-${id}`)}>
              <StatusChip
                status={id}
                statusText={name}
                tableChip={true}
                statusColor={color}
              />
            </MenuItem>
          );
        })}
      </TextField>

      {/* ФІЛЬТР ПО МОЇЙ РОЛІ */}
      <TextField
        value={localValues.myRole}
        onChange={onChange.bind(this)}
        key={setId("role-filter")}
        select={true}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => clearValue("myRole")}>
              <Icon style={{ fontSize: "16px" }}>close</Icon>
            </IconButton>
          ),
        }}
        name="myRole"
        label={t("USER_ROLE_FILTER")}
        margin="dense"
        id={setId("role-filter")}
        className={classNames(classes.margin, classes.textField, classes.flex)}
        SelectProps={{
          id: setId("select-role-filter"),
          SelectDisplayProps: {
            id: setId("select-role-filter-wrapper"),
          },
        }}
      >
        {Array.isArray(roles) &&
          roles.map((item, index) => (
            <MenuItem
              key={item.key + "_" + index}
              value={item.value}
              id={setId(`role-filter-${index + 1}`)}
            >
              {item.name}
            </MenuItem>
          ))}
      </TextField>

      {/* ФІЛЬТРИ ПО ДАТАХ */}
      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "-22px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("CREATED_AT_FILTER")}
        </div>

        <IconButton onClick={() => clearValue("createdAt")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>
      <DateRangePicker
        value={localValues.createdAt}
        onChange={onChange.bind(this)}
        name="createdAt"
        label={t("CREATED_AT_FILTER")}
        setId={setId}
      />

      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "-22px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("DOC_ACCEPT_DATE_FILTER")}
        </div>

        <IconButton onClick={() => clearValue("docAcceptDate")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>

      <DateRangePicker
        value={localValues.docAcceptDate}
        onChange={onChange.bind(this)}
        name="docAcceptDate"
        label={t("DOC_ACCEPT_DATE_FILTER")}
        setId={setId}
      />

      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "-5px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("SUM_UAH_FILTER")}
        </div>

        <IconButton onClick={() => clearValue("sumUah")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>

      <FromToNumericInput
        key={setId("sumUah-filter")}
        name="sumUah"
        label={t("SUM_UAH_FILTER")}
        value={localValues.sumUah}
        id={setId("sumUah-filter")}
        classes={classes}
        onChange={onChange}
        // className={classNames(classes.margin, classes.textField, classes.flex)}
      />

      <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
        <Checkbox
          className={classes.checkbox}
          checked={isSaveFilter}
          onChange={onIsSaveFilterChange}
          color="default"
          id={setId("cell-checkbox")}
        />
        Зробити фільтром за умовчанням
      </div>
    </FilterInput>
  );
};

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
  statuses: PropTypes.object,
};

Filters.defaultProps = {
  statuses: {},
  setId: setComponentsId("executive-filters"),
};

const styled = withStyles(customInputStyle)(Filters);
export default translate("ExecutiveOrders")(styled);
