import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import Typography from "@material-ui/core/Typography";

const NoOptionsMessage = ({ selectProps, innerProps, t }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {t("NOT_FOUND")}
  </Typography>
);

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  t: PropTypes.func.isRequired,
};

NoOptionsMessage.defaultProps = {
  selectProps: {},
  innerProps: {},
};

export default translate("Elements")(NoOptionsMessage);
