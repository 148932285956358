import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { Button } from "../../../components";

const CreateButton = ({
  docCategory,
  t,
  onChange,
  setId,
  userInfo: { isLegal },
}) => {
  if (isLegal) {
    return null;
  }
  return (
    <Button
      color="yellow"
      variant="contained"
      onClick={onChange}
      id={setId("create-button")}
      setId={(elmentName) => setId(`create-${elmentName}`)}
    >
      {docCategory === 4 ? t("CREATE_NEW_DOCUMENT") : t("CREATE_NEW")}
    </Button>
  );
};

CreateButton.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  setId: PropTypes.func,
  userInfo: PropTypes.object.isRequired,
  docCategory: PropTypes.number.isRequired,
};

CreateButton.defaultProps = {
  onChange: () => null,
  setId: setComponentsId("create-button"),
};

export default CreateButton;
