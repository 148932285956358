import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import customStyle from "../../../variables/styles/caseList.jsx";

import SplitPane from "react-split-pane";
import cx from "classnames";
import { Typography, Paper } from "@material-ui/core";
import { Button } from "../../../components";
import ScanDocument from "../../../components/ScanDocument";
import MobileDetect from "mobile-detect";

import {
  getSplitPosition,
  setSplitPosition,
} from "../../../helpers/splitPosition";

import CaseTable from "./Tables/CaseTable";
import DocumentProcTable from "./Tables/DocumentProcTable";
import DocumentCaseTable from "./Tables/DocumentCaseTable";
import MyProcDocumentTable from "./Tables/MyProcDocumentTable";
import useWindowSize from "../../../hooks/useWindowSize.jsx";

const downLoadAttachmentFile = (handleDownload, id) => (fileData) => () =>
  handleDownload("attachmentFile", fileData, id);

const previewAttachmentFile = (handleDownload, id) => (fileData) => () =>
  handleDownload("previewAttachmentFile", fileData, id);

const CaseListLayout = ({
  classes,
  dataSource,
  t,
  checked,
  list,
  statuses,
  memberRoles,
  userId,
  createSortHandler,
  onCheckItem,
  onCheckboxClick,
  setId,
  pagination,
  changeCount,
  display,
  onViewAttaches,
  scanDocumentId,
  closeScanDocument,
  onSelectAllClick,
  favorites,
  handleDownload,
  viewDocument,
  templates,
  page,
  togglePermissionDialog,
  activeProc,
  showCreateButton,
  noShowAttachPreview,
}) => {
  const size = useWindowSize();

  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = !!md.mobile();

  const notFound = list.length === 0 && dataSource.withSearch;
  const splitSize =
    scanDocumentId && !isMobile ? getSplitPosition("caseListSplit") : "100%";
  const viewDoc = scanDocumentId && viewDocument;
  const showProtestBtn =
    page === "cases" && viewDoc?.extActionType !== 1 ? true : false;

  const Component = size?.width > 575 ? SplitPane : Paper;

  return (
    <Component
      className={classes.splitPanel}
      split="vertical"
      size={splitSize}
      minSize={200}
      onChange={setSplitPosition("caseListSplit")}
      primary={viewDoc ? "second" : "first"}
    >
      {notFound && (
        <div className={cx(classes.scroll, classes.toolbar)}>
          <Typography
            className={classes.title}
            variant="h6"
            gutterBottom={true}
          >
            {t("NOT_FOUND")}
          </Typography>
        </div>
      )}
      {!notFound &&
        display !== "documentProcList" &&
        display !== "documentCaseList" &&
        display !== "myProcDocuments" && (
          <div className={classes.scroll} id={setId("content")}>
            <CaseTable
              t={t}
              list={list}
              dataSource={dataSource}
              statuses={statuses}
              createSortHandler={createSortHandler}
              checked={checked}
              onCheckItem={onCheckItem}
              memberRoles={memberRoles}
              userId={userId}
              onCheckboxClick={onCheckboxClick}
              setId={setId}
              pagination={pagination}
              changeCount={changeCount}
              display={display}
              onSelectAllClick={onSelectAllClick}
              favorites={display === "caseList" ? favorites : []}
              templates={templates || []}
            />
          </div>
        )}
      {!notFound &&
        display === "documentProcList" &&
        (!isMobile || (!viewDoc && isMobile)) && (
          <div className={classes.scroll} id={setId("content")}>
            <DocumentProcTable
              t={t}
              list={list}
              dataSource={dataSource}
              createSortHandler={createSortHandler}
              checked={scanDocumentId}
              setId={setId}
              pagination={pagination}
              changeCount={changeCount}
              onSelectAllClick={onSelectAllClick}
              onCellClick={onCheckItem}
            />
          </div>
        )}
      {!notFound && display === "documentCaseList" && (
        <div className={classes.scroll} id={setId("content")}>
          <DocumentCaseTable
            t={t}
            list={list}
            dataSource={dataSource}
            createSortHandler={createSortHandler}
            checked={scanDocumentId}
            setId={setId}
            pagination={pagination}
            changeCount={changeCount}
            onSelectAllClick={onSelectAllClick}
            onCellClick={onCheckItem}
            templates={templates || []}
          />
        </div>
      )}

      {!notFound && display === "myProcDocuments" && (
        <div className={classes.scroll} id={setId("content")}>
          <MyProcDocumentTable
            t={t}
            list={list}
            dataSource={dataSource}
            createSortHandler={createSortHandler}
            checked={checked}
            setId={setId}
            pagination={pagination}
            changeCount={changeCount}
            onSelectAllClick={onSelectAllClick}
            onCheckboxClick={onCheckboxClick}
            onCellClick={onCheckItem}
            templates={templates || []}
          />
        </div>
      )}
      {viewDoc ? (
        <ScanDocument
          size={getSplitPosition("caseListSplit")}
          name={viewDocument.description}
          attaches={viewDocument.attaches}
          viewDocument={viewDocument}
          close={closeScanDocument}
          disableToolbar={true}
          fromWhatComponent={"CaseListLayout"}
          tools={
            showProtestBtn && (
              // showCreateButton &&
              // page === "cases" && (
              // (viewDocument || {}).category === 8 &&
              // (activeProc || {}).procCourtTypeId !== 4 && (
              <Button
                color="yellow"
                variant="contained"
                onClick={togglePermissionDialog([1, 2], viewDocument)}
                id={setId("create-button")}
                setId={(elmentName) => setId(`create-${elmentName}`)}
              >
                {t("TO_PROTEST")}
              </Button>
            )
            // )
          }
          onViewAttaches={onViewAttaches}
          downloadAttach={downLoadAttachmentFile(
            handleDownload,
            scanDocumentId
          )}
          previewAttach={previewAttachmentFile(handleDownload, scanDocumentId)}
          noShowAttachPreview={noShowAttachPreview}
        />
      ) : (
        <span />
      )}
    </Component>
  );
};

CaseListLayout.propTypes = {
  userId: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  onCheckItem: PropTypes.func.isRequired,

  createSortHandler: PropTypes.func.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  pagination: PropTypes.func.isRequired,
  changeCount: PropTypes.func.isRequired,

  list: PropTypes.array.isRequired,
  statuses: PropTypes.array,
  memberRoles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  onViewAttaches: PropTypes.func,
  scanDocumentId: PropTypes.string,
  closeScanDocument: PropTypes.func,
  favorites: PropTypes.array,
  handleDownload: PropTypes.func,
  viewDocument: PropTypes.object,
  templates: PropTypes.array,
  page: PropTypes.string.isRequired,
  togglePermissionDialog: PropTypes.func.isRequired,
  activeProc: PropTypes.object,
  showCreateButton: PropTypes.bool.isRequired,
};

CaseListLayout.defaultProps = {
  checked: "",
  statuses: [],
  onSelectAllClick: undefined,
  favorites: [],
  scanDocumentId: "",
  onViewAttaches: undefined,
  closeScanDocument: undefined,
  handleDownload: undefined,
  viewDocument: null,
  templates: [],
  activeProc: null,
};

const styled = withStyles(customStyle)(CaseListLayout);
export default styled;
