import DataSource from "../helpers/dataSource";

import config from "../config";

const { application } = config();

const dataSource = DataSource(
  application.name === "cabinet" ? "proxy/registry/api/search" : "api/search"
);

// const dataSource = DataSource('proxy/registry/api/search');

const initialState = { dataSource };

const SET_DATA_SOURCE = "SEARCH/SET_DATA_SOURCE";
const REQUEST_REGIONS_SUCCESS = "SEARCH/REQUEST_REGIONS_SUCCESS";
const REQUEST_COURTS_SUCCESS = "SEARCH/REQUEST_COURTS_SUCCESS";
const REQUEST_JUDGEMENT_FORMS_SUCCESS =
  "SEARCH/REQUEST_JUDGEMENT_FORMS_SUCCESS";
const REQUEST_JUSTICE_KINDS_SUCCESS = "SEARCH/REQUEST_JUSTICE_KINDS_SUCCESS";
const REQUEST_CAUSE_CATEGORIES_SUCCESS =
  "SEARCH/REQUEST_CAUSE_CATEGORIES_SUCCESS";

const rootReducer = (state = initialState, action) => {
  state = dataSource.reducer()(state, action);

  switch (action.type) {
    case SET_DATA_SOURCE:
      return { ...state, dataSource: action.payload };
    case REQUEST_REGIONS_SUCCESS:
      return {
        ...state,
        regions: Array.isArray(action.payload)
          ? action.payload.sort((a, b) => a.name.localeCompare(b.name))
          : null,
      };
    case REQUEST_COURTS_SUCCESS:
      return {
        ...state,
        courts: Array.isArray(action.payload)
          ? action.payload.sort((a, b) => a.name.localeCompare(b.name))
          : null,
      };
    case REQUEST_JUDGEMENT_FORMS_SUCCESS:
      return { ...state, judgmentForms: action.payload };
    case REQUEST_JUSTICE_KINDS_SUCCESS:
      return { ...state, justiceKinds: action.payload };
    case REQUEST_CAUSE_CATEGORIES_SUCCESS:
      return { ...state, causeCategories: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
