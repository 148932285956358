import {
  addPermission,
  // deletePermission,
  requestDocumentPermissionsList,
} from "../../../../actions/permissions";
import { setWizardStates } from "../../../../actions/claim";
import promiseChain from "../../../../helpers/promiseChain";
import getWizardProps from "../../../../helpers/getWizardProps";
import getSigners from "../../helpers/getSigners";

export default function () {
  const {
    userId,
    claim: { id: claimId, createdBy, digitalDocumentData, category },
    permissions,
    template: {
      jsonSchema: { useEncryption, properties },
    },
  } = getWizardProps();

  const existedUserIds = getSigners(digitalDocumentData, properties).filter(
    ({ id }) => ![createdBy, userId].includes(id)
  );
  const newSigners = existedUserIds.filter(
    ({ id }) =>
      !(permissions || []).find((permission) => id === permission.userId)
  );
  const oldPermiss = (permissions || [])
    .filter(
      (permission) => !existedUserIds.find(({ id }) => id === permission.userId)
    )
    .filter((permission) => userId !== permission.userId);

  let actions = oldPermiss.map(({ id }) => () => {
    // спричиняє багато однакових запитів у циклі
    // console.log("deletePermission");
    // deletePermission(id);
  });

  if (useEncryption) {
    setWizardStates({ newSigners });
  } else {
    actions = actions.concat(
      newSigners.map(
        (user) => () =>
          addPermission({
            userId: user.id,
            targetId: claimId,
            targetType: 0, // It's a magic
            permissionType: "allowCommit",
          })
      )
    );
  }

  if (!actions.length || category === 4) {
    return Promise.resolve();
  }

  return promiseChain([
    ...actions,
    () => requestDocumentPermissionsList(claimId),
    (result) => setWizardStates({ permissions: result }),
  ]);
}
