import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import BlockScreen from "../BlockScreen";
// @ts-ignore
import { translate } from "react-translate";
import { requestAuth } from "../../actions/auth";
import { ping } from "../../actions";
import EmptyPage from "../../components/EmptyPage";
import Error503 from "../../components/503";
import storage from "../../helpers/storage";
import styles from "../../variables/styles/auth";

class Auth extends React.Component<any> {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    DBError: PropTypes.bool.isRequired,
    ERROR_503: PropTypes.bool.isRequired,
  };

  state = {
    ready: false,
    error: false,
  };

  componentDidMount() {
    (async () => {
      const { message, processPid } = await ping();

      if (message !== "pong" || !processPid) {
        return this.setState({
          error: true,
          ready: true,
        });
      }

      const existedToken = storage.getItem("token");
      if (existedToken) {
        await requestAuth();
      }
      this.setState({ ready: true });
    })();
  }

  render() {
    const { error, ready } = this.state;
    const { classes, children, t, DBError, ERROR_503 } = this.props;
    if (!ready) {
      return <BlockScreen open={true} transparentBackground={true} />;
    }
    if (error || DBError) {
      return (
        <div className={classes.wrap}>
          <EmptyPage title={t("ERROR")} description={t("ERROR_DESCRIPTION")} />
        </div>
      );
    }
    if (ERROR_503) {
      return <Error503 />;
    }
    return children;
  }
}

const translated = translate("Auth")(Auth);
export default connect(
  ({
    // @ts-ignore
    authorization: { DBError, ERROR_503 },
  }) => ({
    DBError,
    ERROR_503,
  })
)(withStyles(styles)(translated));
