import {
  ApplicationDialogState,
  CLOSE_APP_DIALOG,
  OPEN_APP_DIALOG,
} from "./appDialogTypes";

const initialState: ApplicationDialogState = {
  open: false,
  buttons: [],
  text: "",
  title: "",
  resolveDialog: null,
};

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case OPEN_APP_DIALOG: {
      return {
        ...payload,
        open: true,
      };
    }
    case CLOSE_APP_DIALOG:
      return { ...initialState };
    default:
      return state;
  }
};
