import moment from "moment-timezone";

// new toLocaleDateString code

// toLocaleString(value)
// "uk-UA" - Expected output (varies according to "uk-UA" local timezone)
// undefined - Expected output (varies according to local timezone and default locale)

export default function humanDateFormat(dateString, config) {
  if (!dateString) return "";

  const date = new Date(dateString).toLocaleDateString();

  const joined =
    config?.format === "YYYY-MM-DD"
      ? // YYYY-MM-DD
        date.split(".").reverse().join("-")
      : (config?.format === "DD.MM.YYYY" || !config?.format) && date;

  return joined;
}

export function humanDateTimeFormat(dateString) {
  if (!dateString) return "";

  const date = new Date(dateString).toLocaleString().replaceAll(",", "");
  // DD.MM.YYYY HH:mm
  return date.substring(0, date.length - 3);
}

export function humanDateTimeFormatWithSeconds(dateString, config) {
  if (!dateString) return "";
  // YYYY-MM-DD_HH-mm-ss, TODO without moment, moment => toLocaleDateString
  return config?.format === "YYYY-MM-DD_HH-mm-ss"
    ? moment(dateString).format("YYYY-MM-DD_HH-mm-ss")
    : moment(dateString).format("YYYY.MM.DD HH:mm:ss");
}

// Old moment code

export function dateToMoment(birthday) {
  let time = birthday;
  const elements = birthday.split("/");

  if (elements.length === 3) {
    time = moment();
    time.date(elements[0]);
    time.months(elements[1] - 1);
    time.year(elements[2]);
  }

  return time;
}

export function today() {
  return moment(new Date());
}

export function fourteenYearsAgo(format = "") {
  const date = today().subtract(14, "years");
  return format ? date.format(format) : date;
}

export const filterFormat = "YYYY-MM-DD";
export const filterMinDate = "1899-12-31";
export const filterMaxDate = today().add(10, "years").format(filterFormat);
