import {
  requestAttachPreview,
  uploadDocumentAttach,
  deleteDocumentAttach,
  downloadDocumentAttach,
  setWizardStates,
} from "../../../actions/claim";
import getWizardProps from "../../../helpers/getWizardProps";
import { normalizeFileName } from "../../../helpers/testFile";

/**
 * @param {File[]} files
 * @returns {Promise<*>}
 */
export async function handleUploadAttach(files) {
  const { claim } = getWizardProps();

  try {
    const fileIsCorrect = (file) =>
      !file.message && !file.serverMessage && file.attachId && file.link;

    let acceptedFiles = [],
      rejectedFiles = [];

    for (const file of files) {
      const attach = await uploadDocumentAttach(claim.id, file);
      if (fileIsCorrect(attach))
        acceptedFiles.push({
          ...attach,
          fileName: normalizeFileName(attach.fileName),
          name: normalizeFileName(attach.fileName),
          userFileName:
            normalizeFileName(attach.userFileName) ||
            normalizeFileName(attach.fileName),
          mimeType: attach.contentType,
        });
      else rejectedFiles.push(file);
    }

    setWizardStates({
      claim: {
        ...claim,
        attaches: (claim.attaches || []).concat(acceptedFiles),
      },
      error: rejectedFiles.length ? "ERROR_UPLOADING_FILE" : "",
    });

    return {
      acceptedFiles,
      rejectedFiles,
    };
  } catch (e) {
    setWizardStates({ error: "ERROR_UPLOADING_FILE" });
  }
  return [];
}

export function handleDeleteAttach(file) {
  const {
    claim: { id },
  } = getWizardProps();
  return deleteDocumentAttach(id, file);
}

export function handleDownloadAttach(attach) {
  const {
    claim: { id },
  } = getWizardProps();
  return () =>
    downloadDocumentAttach(id, attach.attachId, attach).then((data) => data);
}

export function requestAttachPreviews(attach) {
  const {
    claim: { id },
  } = getWizardProps();
  return () => requestAttachPreview(id, attach.attachId, attach);
}
