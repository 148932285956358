import React, { Component } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "material-ui-pickers";
import { translate } from "react-translate";
import setComponentsId from "../../helpers/setComponentsId";
import { today, filterFormat } from "../../helpers/humanDateFormat";
import moment from "moment";
import "moment/locale/uk";

moment.locale("uk");

const defaultFormat = "DD.MM.YYYY";
const defaultMinDate = moment("01.01.1900", defaultFormat);
const defaultMaxDate = today();

class CustomDatePicker extends Component {
  getIncomingDate = (date) =>
    moment(date, this.props.incomingFormat).format(defaultFormat);

  state = {
    date: this.props.date
      ? moment(this.props.date, this.props.incomingFormat)
      : today(),
    dateText: this.props.date ? this.getIncomingDate(this.props.date) : "",
    error: this.props.error || "",
    minDate: this.props.minDate
      ? this.getIncomingDate(this.props.minDate)
      : defaultMinDate.format(defaultFormat),
    maxDate: this.props.maxDate
      ? this.getIncomingDate(this.props.maxDate)
      : defaultMaxDate.format(defaultFormat),
  };

  onChange = (date) => {
    const { onChange, incomingFormat } = this.props;
    this.setState(
      {
        date,
        dateText: date.format(defaultFormat),
      },
      () => onChange(date.format(incomingFormat))
    );
  };

  validateDate = (value, update) => {
    const { t, onChange, required } = this.props;
    const { minDate, maxDate } = this.state;
    const isValid = moment(value, defaultFormat, true).isValid();
    let error = "";
    if ((required && !isValid) || (!required && value && !isValid)) {
      error = t("FORMAT_ERROR");
    } else if (isValid) {
      if (
        moment(value, defaultFormat).toDate() <
        moment(minDate, defaultFormat).toDate()
      ) {
        error = t("MIN_DATE_ERROR", { date: minDate });
      } else if (
        moment(value, defaultFormat).toDate() >
        moment(maxDate, defaultFormat).toDate()
      ) {
        error = t("MAX_DATE_ERROR", { date: maxDate });
      } else if (update) {
        this.onChange(moment(value, defaultFormat));
      }
    }
    if (!value.length && update) {
      onChange(value);
    }
    this.setState({
      dateText: value,
      error,
    });
  };

  onInputChange = ({ target: { value } }) => this.validateDate(value, true);

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { date, minDate, maxDate, error, helperText } = nextProps;
    this.setState({
      date: moment(date || new Date()),
      minDate: minDate
        ? this.getIncomingDate(minDate)
        : defaultMinDate.format(defaultFormat),
      maxDate: maxDate
        ? this.getIncomingDate(maxDate)
        : defaultMaxDate.format(defaultFormat),
    });
    if (date.length > 0) {
      this.validateDate(this.getIncomingDate(date));
    } else if (error !== this.props.error) {
      this.setState({
        error: typeof error === "string" ? error : helperText,
      });
    } else {
      this.setState({ dateText: "" });
    }
  }

  render() {
    const { t, label, id, helperText, margin, disabled } = this.props;
    const { date, dateText, error, minDate, maxDate } = this.state;
    const pickerId = this.props.setId
      ? this.props.setId(`date-picker ${id}`)
      : setComponentsId("date-picker")(` ${id} `);

    return (
      <DatePicker
        label={label || t("LABEL")}
        margin={margin}
        format={defaultFormat}
        placeholder={moment(date).format(defaultFormat)}
        cancelLabel={t("CANCEL")}
        helperText={typeof error === "string" && !!error ? error : helperText}
        onChange={this.onChange}
        error={!!error}
        keyboard={true}
        autoOk={true}
        id={pickerId}
        disabled={disabled}
        minDate={moment(minDate, defaultFormat)}
        maxDate={moment(maxDate, defaultFormat)}
        InputProps={{
          value: dateText,
          onChange: this.onInputChange,
        }}
      />
    );
  }
}

CustomDatePicker.propTypes = {
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  incomingFormat: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.object,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.object,
  ]),
  margin: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomDatePicker.defaultProps = {
  setId: undefined,
  date: "",
  incomingFormat: filterFormat,
  id: "",
  label: "",
  error: "",
  helperText: "",
  minDate: "",
  maxDate: "",
  margin: "normal",
  required: true,
  disabled: false,
};

export default translate("DatePicker")(CustomDatePicker);
