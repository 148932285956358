import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "../../components";
import { Typography, Icon } from "@material-ui/core";
import { getPermText } from "../../helpers/permission";
import cx from "classnames";

const fields = {
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 4],
  },
  tableFields: [
    {
      key: "userName",
      sort: true,
      title: "TO",
      classNames: ["cell", "shortCell"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "permission",
      sort: true,
      title: "PERMISSION_TITLE",
      classNames: ["cell", "shortCell"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "childCount",
      sort: false,
      title: "RESHARE_COUNT",
      classNames: ["cell", "shortCell"],
      grid: [2, 7, 3, 4],
    },
  ],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
};

const isOwner = (item) => item.isOwner;

const getText = (t) => (item, key) => {
  switch (key) {
    case "permission":
      return getPermText(t, item);
    default:
      return item[key];
  }
};

const getOwnerList = (list) => list.filter((item) => item.isOwner);

const onCLick = (onChange) => (item) => () => onChange(item, true)();

const allSelect = (onChange, changedShare) => () =>
  getOwnerList(changedShare.children).forEach(({ id }) => onChange(id, true)());

const renderDeleteButton = (
  t,
  toggleDialog,
  setId,
  classes,
  changedShare,
  checked
) => {
  if (!getOwnerList(changedShare.children).length) {
    return null;
  }
  const filterChecked = checked.reduce((acc, item) => {
    const found = changedShare.children.find(({ id }) => id === item);
    if (found) acc.push(item);
    return acc;
  }, []);

  return (
    <Button
      color="transparent"
      onClick={toggleDialog("delete")}
      id={setId("toolbar-delete-button")}
      setId={(elmentName) => setId(`toolbar-delete-${elmentName}`)}
      className={classes.mobileButtonFullWidth}
      disabled={filterChecked.length === 0}
    >
      <Icon>delete</Icon>
      {`${t("DELETE_PERMISSION")}${
        filterChecked.length > 0 ? ` (${filterChecked.length})` : ""
      }`}
    </Button>
  );
};

const ShareDialogReshare = ({
  classes,
  t,
  setId,
  changedShare,
  addReshare,
  dataSource,
  display,
  allowShare,
  createSortHandler,
  onCheckItem,
  handleClick,
  checked,
  toggleDialog,
}) => {
  const isActive = !!(changedShare && changedShare.isActive);
  const childrenActivePermissions = changedShare
    ? (changedShare.children || []).filter((item) => item.isActive)
    : [];

  return (
    <Fragment>
      {changedShare && display === "addReshare" && (
        <Fragment>
          {/* <Typography
          component="p"
          id={setId('permission')}
          className={classes.formControl}
        >
          {t('PERMISSION_TITLE')}:&nbsp;
          {getPermText(t, changedShare)}
        </Typography> */}
          <Button
            onClick={addReshare}
            color="yellow"
            id={setId("add-button")}
            setId={(elmentName) => setId(`addReshare-${elmentName}`)}
          >
            {t("ADD_RESHARE")}
          </Button>
        </Fragment>
      )}
      {changedShare && display === "viewOnly" && (
        <Fragment>
          <Typography
            component="p"
            id={setId("permission")}
            className={classes.formControl}
          >
            {t("PERMISSION_TITLE")}:&nbsp;
            {getPermText(t, changedShare)}
          </Typography>
          <Button
            onClick={addReshare}
            color="yellow"
            id={setId("add-button")}
            setId={(elmentName) => setId(`addReshare-${elmentName}`)}
          >
            {t("ADD_RESHARE")}
          </Button>
        </Fragment>
      )}
      {!!childrenActivePermissions.length && isActive && (
        <div
          id={setId("reshare-list-title")}
          className={cx(classes.formControl, classes.flexWrap)}
        >
          <Typography
            component="h2"
            id={setId("permission")}
            className={classes.title}
          >
            {t("RESHARE")}
          </Typography>
          {renderDeleteButton(
            t,
            toggleDialog,
            setId,
            classes,
            changedShare,
            checked
          )}
        </div>
      )}
      {!!childrenActivePermissions.length && !allowShare && isActive && (
        <Typography
          id={setId("error")}
          className={cx(classes.radioTitle, classes.error)}
          component="p"
        >
          {t("RESHARE_ERROR")}
        </Typography>
      )}
      {!!childrenActivePermissions.length && allowShare && (
        <Table
          fields={fields}
          checked={checked}
          getText={getText(t)}
          setId={setId}
          onCheckItem={handleClick}
          createSortHandler={createSortHandler}
          onCheckboxClick={onCLick(onCheckItem)}
          list={childrenActivePermissions}
          t={t}
          onSelectAllClick={allSelect(onCheckItem, changedShare)}
          needFullData
          dataSource={dataSource}
          isOwner={isOwner}
          ownerList={getOwnerList(changedShare.children).length > 0}
        />
      )}
    </Fragment>
  );
};

ShareDialogReshare.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  changedShare: PropTypes.object,
  addReshare: PropTypes.func,
  dataSource: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  allowShare: PropTypes.bool.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  onCheckItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  checked: PropTypes.array.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

ShareDialogReshare.defaultProps = {
  changedShare: {},
  addReshare: undefined,
};

export default ShareDialogReshare;
