import { Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../components";

const Actions = ({
  t,
  setId,
  busy,
  value: { claimant, representativeClaimant, claimentUserId, files },
  step,
  closeDialog,
  toggleStep,
  error,
}) => {
  const isOwner = claimant && !representativeClaimant;
  const needFiles = !isOwner && (!files || !files.length) && !claimentUserId;
  const disabled = step === 1 && (error || busy || needFiles);

  return (
    <Fragment>
      <Button
        onClick={closeDialog}
        color="transparent"
        disabled={busy}
        id={setId("back-button")}
        setId={(elmentName) => setId(`back-${elmentName}`)}
      >
        {t("BACK")}
      </Button>
      <Button
        onClick={toggleStep}
        color="yellow"
        autoFocus={true}
        id={setId("next-button")}
        setId={(elmentName) => setId(`next-${elmentName}`)}
        disabled={disabled}
      >
        {t("NEXT")}
      </Button>
    </Fragment>
  );
};

Actions.propTypes = {
  step: PropTypes.number.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleStep: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  value: PropTypes.object,
  error: PropTypes.string.isRequired,
};

Actions.defaultProps = {
  value: {},
};

export default Actions;
