export const fields = {
  pagination: true,
  tableFields: [
    {
      key: "execDocTypeName",
      sort: false,
      title: "DOCUMENT_TITLE",
      classNames: [
        "cell",
        "customCellDocNameExecutiveDocs",
        // "onlyBigScreen",
        "shortCell",
        "borderRight",
      ],
      grid: [2, 7, 1, 2],
    },
    {
      key: "enforcementName",
      sort: false,
      title: "PENALTY",
      classNames: [
        "cell",
        "onlyBigScreen",
        "customCellPenaltyExecutiveDocs",
        "shortCell",
        "borderRight",
      ],
      grid: [2, 7, 1, 2],
    },
    {
      key: "statusId",
      sort: true,
      title: "STATUS",
      classNames: ["cell", "textCentered", "shortCell", "borderRight"],
      grid: [2, 7, 3, 4],
    },
    // {
    //   key: "courtId",
    //   sort: true,
    //   title: "COURT_TITLE",
    //   classNames: ["cell", "onlyBigScreen", "customCellCourtNameExecutiveDocs"],
    // },
    // {
    //   key: "court",
    //   classNames: ["cell", "shortCell", "onlyBigScreen", "borderRight"],
    //   grid: [2, 7, 5, 6],
    // },
    {
      key: "execMyRole",
      sort: true,
      title: "MY_ROLE",
      classNames: [
        "cell",
        "shortCell",
        "textLeft",
        "customCellCourtNameExecutiveDocs",
        "onlyBigScreen",
        "borderRight",
      ],
      grid: [2, 7, 2, 3],
    },
    {
      key: "debtorName",
      sort: false,
      title: "COLLECTOR_DEBITOR",
      classNames: ["cell", "shortCell", "onlyBigScreen", "borderRight"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "createdAt",
      numeric: true,
      sort: true,
      title: "RECEIVED",
      classNames: [
        "cell",
        "shortCell",
        "customCellDocDateExecutiveDocs",
        "borderRight",
        "onlyBigScreen",
      ],
    },
    {
      key: "docDate",
      sort: true,
      title: "DOCUMENT_DATE",
      classNames: [
        "cell",
        "shortCell",
        "customCellDocDateExecutiveDocs",
        "borderRight",
      ],
      grid: [2, 7, 2, 3],
    },
    {
      key: "dateForce",
      sort: true,
      title: "DNZS_DATE",
      classNames: [
        "cell",
        "shortCell",
        "customCellDocDateExecutiveDocs",
        "borderRight",
        "onlyBigScreen",
      ],
      grid: [2, 7, 2, 3],
    },
    {
      key: "updatedAt",
      sort: true,
      title: "CHANGE_DATE",
      classNames: [
        "cell",
        "shortCell",
        "customCellDocDateExecutiveDocs",
        "borderRight",
        "onlyBigScreen",
      ],
      grid: [2, 7, 2, 3],
    },
    {
      key: "paymentInfoSumm",
      title: "PAYMENT_INFO_SUMM",
      classNames: ["cell", "shortCell", "onlyBigScreen", "borderRight"],
    },
    {
      key: "executorName",
      title: "EXECUTOR_NAME",
      classNames: ["cell", "shortCell", "onlyBigScreen", "borderRight"],
    },
    {
      key: "statusInfo",
      title: "FAULT_MESSAGE",
      classNames: ["cell", "shortCell", "onlyBigScreen", "borderRight"],
    },
    {
      key: "goToProcessing",
      title: "CASE_PROC_NUMBER",
      classNames: ["cell", "textCentered", "shortCell", "borderRight"],
      grid: [2, 7, 5, 6],
    },
  ],
  filters: [
    "statusId",
    "execMyRole",
    //"courtId",
    //"docDate",
    "createdAt",
    "dateForce",
    "updatedAt",
  ],
};
