import { downloadStaticFile, downloadScanFile } from "../../../actions/cases";
import {
  downloadDocumentAttach,
  requestAttachPreview,
} from "../../../actions/claim";
import { setSplitPosition } from "../../../helpers/splitPosition";
import DataSource from "./dataSource";

const { caseDocDataSource } = DataSource;

export function handleDownload(name, itemData, documentId = 0) {
  const { t } = this.props;
  const id = itemData.attachId || itemData.id;
  let action;
  if (!id) return this.setState({ error: t("DOWNLOAD_ERROR") });
  switch (name) {
    case "staticFile":
      action = downloadStaticFile(id);
      break;
    case "scanFile":
      action = downloadScanFile(id);
      break;
    case "attachmentFile":
      action = downloadDocumentAttach(documentId, id, itemData);
      break;
    case "previewAttachmentFile":
      action = requestAttachPreview(documentId, id, itemData);
      break;
    default:
      break;
  }
  return action
    .then((data) => {
      if (!data.message) {
        this.setState({
          error: "",
          noShowAttachPreview: false,
        });
        return data;
      }

      // if (data.toString().includes("Error")) {
      //   return this.setState({
      //     error: "",
      //     noShowAttachPreview: true,
      //   });
      // }

      if (
        data.serverMessage.includes(
          "The attachment is not available until the court fee is paid"
        ) &&
        data.status === 500
      ) {
        return this.setState({
          error:
            "Для можливості переглянути чи завантажити додаток до документу судового засідання необхідно сплатити судовий збір.",
          noShowAttachPreview: true,
        });
      }

      if (name !== "previewAttachmentFile") {
        return this.setState({ error: t("DOWNLOAD_ERROR") });
      }
      return data;
    })
    .catch((err) => console.log("Action error: ", err));
}

export function loadCaseDoc() {
  return caseDocDataSource.load().then(() => {
    const { caseDocuments, proceedingsList, userId } = this.props;
    const { caseMembers } = this.state.activeCase || {};
    const foundInMembers =
      Array.isArray(caseMembers) &&
      !!caseMembers.find((item) => item.userId === userId);
    let caseNotProcDoc = [];
    if (
      foundInMembers &&
      Array.isArray(caseDocuments) &&
      Array.isArray(proceedingsList)
    ) {
      caseNotProcDoc = caseDocuments.filter((item) => !item.proceedingId);
    }
    this.setState({ loading: false, blockScreen: false, caseNotProcDoc });
  });
}

export function closeScanDocument() {
  return this.setState({ scanDocumentId: null }, () =>
    setSplitPosition("caseListSplit")("50%")
  );
}
