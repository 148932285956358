import Index from "../containers/Index";
import Login from "../containers/Login";
import Search from "../containers/Search";

const indexRoutes = [
  { path: "/search", component: Search },
  { path: "/login", component: Login },
  { path: "/", component: Index },
];

export default indexRoutes;
