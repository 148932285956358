import React from "react";
import PropTypes from "prop-types";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Icon,
  DialogActions,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import cx from "classnames";
import { formatUserName } from "../../../helpers/userName";

import ShareDialogWrapper from "../../../components/ShareDialog/ShareDialogWrapper";
import ShareDialog from "../../../components/ShareDialog";
import { Button } from "../../../components";

const CaseShareDialog = ({
  t,
  classes,
  userId,
  error,
  dataSource,
  createSortHandler,
  loading,
  dialog,
  toggleDialog,
  pdf,
  doc,
  setPdf,
  onCheckShare,
  handleShareClick,
  setId,

  changedShare,
  sharesChecked,
  targetType,
  targetId,
  shareSubTitle,
  currentShares,
  expandedShare,
  foundInMembers,
}) => (
  <ShareDialogWrapper
    toggleDialog={toggleDialog}
    display={dialog}
    busy={loading}
    setId={setId}
  >
    {((foundInMembers || !currentShares.length) &&
    !currentShares.find((item) => item.id === -1)
      ? [
          ...currentShares,
          {
            id: -1,
            emptyName: "addShare",
            children: [],
            dialog: "addShare",
          },
        ]
      : currentShares
    ).map((share, i) => {
      const {
        id,
        userName,
        emptyName,
        createdBy,
        allowedByName,
        dialog: itemDialog,
      } = share;
      const expanded =
        !currentShares.length || !!(changedShare && changedShare.id === id);
      const isOwner = createdBy === userId;
      let notExpandedTitle = isOwner
        ? t("READONLY_SHARE", { userName: formatUserName(userName) })
        : t("RESHARE_SHARE", {
            userName: formatUserName(allowedByName),
          });
      if (itemDialog === "viewOnly" || dialog === "viewOnly") {
        notExpandedTitle = !isOwner
          ? t("VIEW_SHARE_FROM", {
              userName: formatUserName(allowedByName),
            })
          : t("VIEW_SHARE_TO", {
              userName: formatUserName(userName),
            });
      }
      if (!expanded && emptyName) {
        return (
          <DialogActions
            className={cx(
              classes.actions,
              classes.dialogContentWrappers,
              classes.mobilePadding
            )}
            key={`expanded-${id}-${i}`}
          >
            <Button
              id={setId("cancel-button")}
              onClick={toggleDialog("")}
              color="transparent"
              setId={(elmentName) => setId(`cancel-${elmentName}`)}
            >
              {t("CLOSE")}
            </Button>
            <Button
              color="yellow"
              onClick={expandedShare(share)}
              id={setId("add-share-button")}
              setId={(elmentName) => setId(`add-share-button-${elmentName}`)}
              className={classes.mobileButtonFullWidth}
            >
              <Icon>supervisor_account</Icon>&nbsp;&nbsp;
              {t("ADD_SHARE")}
            </Button>
          </DialogActions>
        );
      }
      return (
        <ExpansionPanel
          expanded={expanded}
          onChange={expandedShare(share)}
          id={setId("expanded")}
          key={`expanded-${id}-${i}`}
        >
          {!!currentShares.length && (
            <ExpansionPanelSummary
              id={setId("open")}
              expandIcon={<ExpandMoreIcon />}
            >
              {!expanded && (
                <Typography className={classes.heading} id={setId("title")}>
                  {notExpandedTitle}
                </Typography>
              )}
            </ExpansionPanelSummary>
          )}
          <ExpansionPanelDetails
            className={classes.panelContent}
            id={setId("content")}
          >
            {expanded && (
              <ShareDialog
                toggleDialog={toggleDialog}
                busy={loading}
                changedShare={emptyName ? null : changedShare}
                display={dialog}
                list={currentShares}
                handleClick={handleShareClick}
                currentUserId={userId}
                setId={setId}
                dataSource={dataSource}
                createSortHandler={createSortHandler}
                onCheckItem={onCheckShare}
                checked={[...sharesChecked]}
                targetType={targetType}
                targetId={targetId}
                pdf={pdf}
                doc={doc}
                setPdf={setPdf}
                error={error}
                shareSubTitle={shareSubTitle}
                notShowCloseButton={true}
              />
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    })}
  </ShareDialogWrapper>
);

CaseShareDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  error: PropTypes.string,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,

  createSortHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dialog: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  pdf: PropTypes.object,
  doc: PropTypes.object,
  setPdf: PropTypes.func.isRequired,
  onCheckShare: PropTypes.func.isRequired,
  handleShareClick: PropTypes.func.isRequired,
  changedShare: PropTypes.object,
  sharesChecked: PropTypes.array,
  targetType: PropTypes.number.isRequired,
  targetId: PropTypes.string.isRequired,
  shareSubTitle: PropTypes.string.isRequired,
  currentShares: PropTypes.array.isRequired,
  expandedShare: PropTypes.func.isRequired,
  foundInMembers: PropTypes.object,
};

CaseShareDialog.defaultProps = {
  error: "",
  pdf: null,
  doc: null,
  changedShare: null,
  sharesChecked: [],
  foundInMembers: null,
};

export default CaseShareDialog;
