import React from "react";
import { Dialog, withStyles } from "@material-ui/core";
import styles from "../../variables/styles/blockscreen";

import Preloader from "../../components/Preloader";

type Props = {
  classes: any;
  open: boolean;
  transparentBackground?: boolean;
};

const BlockScreen = ({
  classes,
  open,
  transparentBackground = false,
}: Props) => {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      className={transparentBackground ? classes.dialog : ""}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <Preloader />
    </Dialog>
  );
};

export default withStyles(styles)(BlockScreen);
