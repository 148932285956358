export default {
  dialog: {
    "& > :last-child": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        margin: "48px 10px",
        fontSize: ".7rem",
      },
    },
  },
  dialogTitle: {
    flex: 1,
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
    "@media (max-width:425px)": {
      // eslint-disable-line no-useless-computed-key
      display: "block",
    },
  },
  htmlPreviewWrapper: {
    width: 550,
    height: "100%",
    "@media (max-width:700px)": {
      // eslint-disable-line no-useless-computed-key
      width: 400,
    },
    "@media (max-width: 550px)": {
      // eslint-disable-line no-useless-computed-key
      width: 300,
    },
  },
  smWidth: {
    "@media (max-width:425px)": {
      // eslint-disable-line no-useless-computed-key
      margin: 0,
      "& > div > div": {
        margin: 15,
      },
    },
  },
  progress: {
    marginTop: "-8px",
    marginBottom: "8px",
  },
};
