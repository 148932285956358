// import store from "../store";

// const userIsJudje = () => {
//   const {
//     authorization: { info },
//   } = store.getState() || { authorization: {} };
//   const { courtIdUserScopes = [] } = info || {};
//   return courtIdUserScopes.includes("judge");
// };

export default {
  1: {
    name: "claims",
    title: "LIST",
    getUrl: () => "/claims",
  },
  4: {
    name: "executive",
    title: "EXECUTIVE",
    getUrl: () => "/executive",
  },
  5: {
    name: "c_executive",
    title: "C_EXECUTIVE",
    getUrl: (claim) =>
      claim && claim.parentDocumentId
        ? `/c_executive/${claim.parentDocumentId}`
        : "/c_executive",
  },
  6: {
    name: "cases",
    title: "CASE",
    text: "LIST",
    getUrl: (claim) =>
      claim && claim.caseId && claim.proceedingId
        ? `/cases/case=${claim.caseId}/proceeding=${claim.proceedingId}`
        : "/cases",
  },
  8: {
    name: "cases",
    title: "CASE",
    text: "LIST",
    getUrl: (claim) =>
      claim && claim.caseId && claim.proceedingId
        ? `/cases/case=${claim.caseId}/proceeding=${claim.proceedingId}`
        : "/cases",
  },
  // 8: {
  //   name: userIsJudje() ? "judge_cases" : "cases",
  //   title: "CASE",
  //   text: userIsJudje() ? "" : "LIST",
  //   getUrl: (claim) => {
  //     const page = userIsJudje() ? "judge_cases" : "cases";
  //     return claim && claim.caseId && claim.proceedingId
  //       ? `/${page}/case=${claim.caseId}/proceeding=${claim.proceedingId}`
  //       : `/${page}`;
  //   },
  // },
  9: {
    name: "police",
    title: "POLICE",
    getUrl: () => "/police",
  },
  10: {
    name: "inputdocs",
    title: "INPUTDOCS",
    getUrl: () => "/inputdocs",
  },
  11: {
    name: "claimsDocument",
    title: "CLAIM",
    getUrl: (id) => `/claims/${id}`,
  },
};
