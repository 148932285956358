import React from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles, Drawer, Hidden, List } from "@material-ui/core";
import { translate } from "react-translate";
import { compose } from "redux";
import setComponentsId from "../../helpers/setComponentsId";
import { HeaderLinks } from "../../components";
import sidebarStyle from "../../variables/styles/sidebarStyle.jsx";
import SidebarNavLink from "./SidebarNavLink.jsx";
import { withAdvocate } from "../withAdvocate";

const Sidebar = (props) => {
  const {
    t,
    classes,
    auth,
    color,
    logo,
    image,
    routes,
    open,
    handleDrawerToggle,
    setId,
    state,
    isAdvocate,
    ...rest
  } = props;
  if (!auth.info) {
    return <div id={setId("auth-error")} />;
  }

  const courtIdUserScopes = auth.info.courtIdUserScopes || [];
  const { role } = auth;

  let filteredRoutes = routes
    .filter(
      (prop) => prop.navbar && (!prop.navBarFilter || !!rest[prop.navBarFilter])
    )
    .filter(({ accessRoles }) =>
      (accessRoles || []).some((item) =>
        role ? item === role : courtIdUserScopes.includes(item)
      )
    )
    .map((item) => {
      if (item.path === "/share" && isAdvocate) {
        return {
          ...item,
          title: "SHARE_LIST_AND_ORDERS",
        };
      }

      return item;
    });

  if (role === "judge") {
    filteredRoutes = filteredRoutes.filter((item) => item.page !== "cases");
  }

  const links = (
    <List className={classes.list} id={setId("list")}>
      {filteredRoutes.map((prop) => {
        return <SidebarNavLink {...props} prop={prop} key={prop.setId()} />;
      })}
    </List>
  );
  const brand = (
    <div
      className={classes.logo}
      id={setId("logo")}
      onClick={handleDrawerToggle}
    >
      <Link to="/" className={classes.logoLink} id={setId("link-logo")}>
        <div className={classes.logoImage} id={setId("img-logo")}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {/* <Typography component="h1">{logoText}</Typography>
        <Typography component="h2">{logoHint}</Typography> */}
      </Link>
    </div>
  );
  return (
    <div id={setId("wrap")}>
      <Hidden mdUp={true}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <PerfectScrollbar className={classes.scrollBar}>
            <div
              className={classes.sidebarWrapper}
              id={setId("wrapper")}
              style={{ height: filteredRoutes.length * 50 + 450 }}
            >
              <HeaderLinks
                setId={setId}
                handleDrawerToggle={handleDrawerToggle}
              />
              {links}
            </div>
            {image && (
              <div
                className={classes.background}
                id={setId("background")}
                // style={{backgroundImage: "url(" + image + ")"}}
              />
            )}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <PerfectScrollbar className={classes.scrollBar}>
            <div className={classes.sidebarWrapper} id={setId("wrapper-2")}>
              {links}
            </div>
            {image && (
              <div
                className={classes.background}
                id={setId("background-2")}
                // style={{backgroundImage: "url(" + image + ")"}}
              />
            )}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,
  color: PropTypes.string,
  logo: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.array,
  isAdvocate: PropTypes.bool,
  location: PropTypes.object.isRequired,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

Sidebar.defaultProps = {
  setId: setComponentsId("sidebar"),
  color: "yellow",
  logo: "",
  image: "",
  routes: [],
  open: false,
};

function mapStateToProps(state) {
  return {
    auth: state.authorization,
    executiveCount: state.claim.executiveCount,
    state,
    approvalCount: state.claim.approvalCount,
  };
}

export default compose(
  withStyles(sidebarStyle),
  translate("Sidebar"),
  connect(mapStateToProps),
  withAdvocate
)(Sidebar);
