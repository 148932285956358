export default function iff(a, operator, b, optiuns) {
  var bool = false;
  switch (operator) {
    case "==":
      // eslint-disable-next-line eqeqeq
      bool = a == b;
      break;
    case ">":
      bool = a > b;
      break;
    case "<":
      bool = a < b;
      break;
    default:
      throw new Error("Unknown operator " + operator);
  }

  if (bool) {
    return optiuns.fn(this);
  } else {
    return optiuns.inverse(this);
  }
}
