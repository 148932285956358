import React, { Fragment } from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  GridList,
  GridListTile,
  withStyles,
} from "@material-ui/core";

import { Preloader } from "../../components";
import styles from "../../variables/styles/tableStyle";
import classNames from "classnames";

class DataTable extends React.Component {
  createSortHandler = (columnId) => () => {
    const { dataSource } = this.props;
    let order = "desc";

    if (columnId in dataSource.sort && dataSource.sort[columnId] === "desc") {
      order = "asc";
    }

    dataSource.sort = { [columnId]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
  };

  changeRowsPerPage = ({ target: { value } }) => {
    const { dataSource } = this.props;
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load();
  };

  goToPage = (e, page) => {
    const { dataSource } = this.props;
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource.load();
  };

  renderHead() {
    const { t, columns, dataSource } = this.props;
    if (!columns || !Array.isArray(columns)) {
      return (
        <TableHead>
          <TableRow>
            <TableCell>define columns pls</TableCell>
          </TableRow>
        </TableHead>
      );
    }

    return (
      <TableHead>
        <TableRow>
          {columns.map((column, columnKey) => (
            <TableCell key={columnKey}>
              {column.sortable ? (
                <TableSortLabel
                  active={column.id in dataSource.sort}
                  direction={dataSource.sort[column.id]}
                  onClick={this.createSortHandler(column.id)}
                >
                  {t(column.name)}
                </TableSortLabel>
              ) : (
                t(column.name)
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  renderBody() {
    const { list, columns } = this.props;
    const incorrectList = !list || !Array.isArray(list);
    const incorrectColumns = !columns || !Array.isArray(columns);

    if (incorrectList && incorrectColumns) {
      return null;
    }

    if (incorrectList) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Preloader />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return <TableBody>{list.map(this.renderRow.bind(this))}</TableBody>;
  }

  renderCards() {
    const { classes, list } = this.props;

    if (!list) {
      return <Preloader />;
    }

    return (
      <GridList className={classes.gridList} cellHeight={"auto"}>
        {list.map(this.renderCard.bind(this))}
      </GridList>
    );
  }

  renderCard(item, key) {
    const { t, classes, columns } = this.props;

    return (
      <GridListTile key={key} cols={2} classes={{ root: classes.gridListItem }}>
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardContent }}>
            <List>
              {columns.map(({ id, name, render }, columnKey) => (
                <ListItem key={columnKey}>
                  <ListItemText
                    primary={
                      render ? render(item[id], item, columnKey) : item[id]
                    }
                    secondary={t(name)}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </GridListTile>
    );
  }

  renderRow(item, key) {
    const { classes, columns, selected, dataSource } = this.props;

    if (!columns || !Array.isArray(columns)) {
      return (
        <TableRow
          key={key}
          className={classNames({
            [classes.selected]: selected === item.id,
          })}
        >
          <TableCell>define columns pls</TableCell>
        </TableRow>
      );
    }

    const { highlight } = item;
    let highlightTextes = [];

    item.highlight &&
      Object.keys(item.highlight)
        .filter((k) => !(k in item))
        .filter((k) => !columns.map((column) => column.id).includes(k))
        .forEach((k) => {
          highlightTextes = highlightTextes.concat(
            item.highlight[k].map(renderHTML)
          );
        });

    return (
      <Fragment key={key}>
        <TableRow
          hover={true}
          className={classNames(classes.row, classes.mobileGrid, {
            [classes.hasHighlight]: !!highlightTextes.length,
          })}
          selected={selected === item.id}
        >
          {columns.map(({ id, render, handleClick }, columnKey) => {
            const renderCell = render
              ? render(item[id], item, columnKey, dataSource)
              : item[id];
            const cellValue =
              highlight && highlight[id] && highlight[id].length
                ? renderHTML(highlight[id].shift())
                : renderCell;

            return (
              <TableCell
                key={columnKey}
                onClick={
                  handleClick
                    ? handleClick(item).bind(this)
                    : this.handleClick(item)
                }
              >
                {cellValue}
              </TableCell>
            );
          })}
        </TableRow>
        {!!highlightTextes.length &&
          this.renderHighlight(item, highlightTextes)}
      </Fragment>
    );
  }

  renderHighlight(item, highlight) {
    const { classes, columns, selected } = this.props;

    return (
      <TableRow
        className={classNames(classes.row, classes.mobileGrid)}
        selected={selected === item.id}
        onClick={this.handleClick(item)}
      >
        <TableCell colSpan={columns.length}>
          {highlight.map((highlightItem, index) => (
            <p key={index}>{highlightItem}</p>
          ))}
        </TableCell>
      </TableRow>
    );
  }

  handleClick = (item) => () => {
    const { handleClick } = this.props;
    handleClick && handleClick(item);
  };

  renderPagination() {
    const { t, dataSource, classes, list } = this.props;

    const { pagination } = (list && list.meta) || {};
    const { total } = pagination || { total: 0 };

    return (
      <TableHead>
        <TableRow>
          <TablePagination
            className={classes.pagination}
            classes={{ select: classes.paginationSelect }}
            count={total}
            onChangePage={this.goToPage}
            rowsPerPage={dataSource.count}
            labelRowsPerPage={t("ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to }) =>
              t("DISPLAYED_ROWS", { from, to, total })
            }
            rowsPerPageOptions={[10, 20, 50]}
            onChangeRowsPerPage={this.changeRowsPerPage}
            page={dataSource.page}
            SelectProps={{ className: classes.pagSelect }}
            backIconButtonProps={{ className: classes.pagButton }}
            nextIconButtonProps={{ className: classes.pagButton }}
          />
        </TableRow>
      </TableHead>
    );
  }

  render() {
    return (
      <Fragment>
        <Hidden smDown={true}>
          <Table>
            {this.renderPagination()}
            {this.renderHead()}
            {this.renderBody()}
            {this.renderPagination()}
          </Table>
        </Hidden>
        <Hidden mdUp={true}>
          <Table>{this.renderPagination()}</Table>
          {this.renderCards()}
        </Hidden>
      </Fragment>
    );
  }
}

DataTable.propTypes = {
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleClick: PropTypes.func.isRequired,
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selected: PropTypes.string,
};

DataTable.defaultProps = {
  list: null,
  columns: [],
  selected: "",
};

export default withStyles(styles)(DataTable);
