import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";
import { translate } from "react-translate";
import { withStyles, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TextFieldDummy from "../../../components/CustomInput/TextFieldDummy";
import MenuItem from "@material-ui/core/MenuItem";
import InputMask from "react-input-mask";

import customInputStyle from "../../../variables/styles/customInputStyle";

const Masked = (props) => (
  <InputMask {...props} maskChar="" inputRef={props.ref} />
);

Masked.propTypes = {
  ref: PropTypes.node,
};

Masked.defaultProps = {
  ref: undefined,
};

class StringElement extends React.Component {
  state = { value: this.props.value };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.value !== this.state.value ||
      this.props.errors !== nextProps.errors
    );
  }

  componentDidMount() {
    const { value, onChange } = this.props;
    onChange(value);
  }

  UNSAFE_componentWillReceiveProps({ value, readOnly }) {
    if ((this.state.value && !value && this.props.value) || readOnly) {
      this.setState({ value });
    }
  }

  handleChange = ({ target: { value } }) => {
    const { onChange } = this.props;
    const clearValue = value.trim();
    this.setState(
      { value },
      () => onChange && onChange(clearValue || undefined)
    );
  };

  children = () => {
    const { children, classes } = this.props;

    if (this.props.enum) {
      return Object.values(this.props.enum).map((option, key) => (
        <MenuItem key={key} value={option} className={classes.menuItem}>
          {option}
        </MenuItem>
      ));
    }

    return children;
  };

  render() {
    const {
      classes,
      sample,
      errors,
      description,
      formControlProps,
      readOnly,
      InputProps,
      SelectProps,
      type,
      mask,
      required,
      placeholder,
      setId,
      helperText,
      disabled,
      name,
      path,
      maxLength,
    } = this.props;
    const { value } = this.state;

    const select = this.props.select || !!this.props.enum;

    const Component = readOnly ? TextFieldDummy : TextField;
    const id = path
      ? path.replace(/\./g, "-")
      : this.props.id || setId("input");
    return (
      <FormControl
        {...formControlProps}
        className={classes.formControl}
        id={setId(id)}
      >
        <Component
          placeholder={placeholder}
          select={select}
          disabled={disabled}
          name={name}
          label={description + (required ? "*" : "")}
          value={value}
          onChange={this.handleChange}
          helperText={helperText || (!readOnly && (sample || errors))}
          error={!!errors}
          InputProps={{
            ...InputProps,
            inputComponent: Masked,
          }}
          inputProps={{ mask, maxLength }} // eslint-disable-line react/jsx-no-duplicate-props
          SelectProps={SelectProps}
          type={type}
          id={id}
          InputLabelProps={
            (description || "").length > 85 ? { style: { top: "-1rem" } } : {}
          }
        >
          {this.children()}
        </Component>
      </FormControl>
    );
  }
}

StringElement.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
  enum: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
  sample: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formControlProps: PropTypes.object,
  description: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  SelectProps: PropTypes.object,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.number,
};

StringElement.defaultProps = {
  children: "",
  enum: null,
  type: "string",
  placeholder: "",
  select: false,
  onChange: undefined,
  sample: "",
  formControlProps: {},
  errors: null,
  description: "",
  readOnly: false,
  InputProps: {},
  SelectProps: {},
  mask: "",
  required: false,
  setId: setComponentsId("string"),
  value: "",
  helperText: "",
  path: "",
  id: "",
};

const styled = withStyles(customInputStyle)(StringElement);
export default translate("Elements")(styled);
