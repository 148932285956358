import React from "react";
import { Table, TableCell, TableRow } from "@material-ui/core";
// import { Button } from "../../../components";
// import setComponentsId from "../../../helpers/setComponentsId";

export interface RegisterTableProps {
  classes: any;
  items: any[];
  onButtonClick: (itemId: number) => void;
  onRowSelect: (itemId: number) => void;
  t: (s: string) => string;
}

class RegisterTable extends React.Component<RegisterTableProps> {
  static defaultProps = {
    items: [],
  };

  render() {
    const { classes, onRowSelect, items } = this.props;
    return (
      <Table>
        {items.map((item) => {
          return (
            <TableRow key={item.id}>
              <TableCell>
                <p
                  title={`${item.code}: ${item.name}`}
                  className={classes.registersLink}
                  onClick={() => onRowSelect(item.id)}
                >
                  {`${item.code}: ${item.name}`}
                </p>
              </TableCell>
              {/* <TableCell>
                <Button
                  color="yellow"
                  onClick={() => this.props.onButtonClick(item.id)}
                  setId={setComponentsId(`create-request-to-${item.id}-btn`)}
                  disabled={item.id !== 1}
                >
                  {this.props.t("CREATE_REQUEST")}
                </Button>
              </TableCell> */}
            </TableRow>
          );
        })}
      </Table>
    );
  }
}

export default RegisterTable;
