import { routerActions } from "react-router-redux";
import humanDateFormat from "../../../helpers/humanDateFormat";
import DataSource from "./dataSource";

const {
  caseDataSource,
  procDataSource,
  caseDocDataSource,
  procDocDataSource,
  myCaseDocDataSource,
} = DataSource;

export function setDataSource(needload) {
  const { display, checked, procChecked, viewDocument } = this.state;
  const { dispatch, page } = this.props;
  let { dataSource } = this.state;
  this.setState({ loading: true });
  switch (display) {
    case "caseList":
      dataSource = caseDataSource;
      needload = true;
      dispatch(routerActions.replace(`/${page}`));
      break;
    case "procList":
      dispatch(routerActions.replace(`/${page}/case=${checked}`));
      dataSource = procDataSource;
      break;
    case "documentProcList":
      dispatch(
        routerActions.replace(
          `/${page}/case=${checked}/proceeding=${procChecked}`
        )
      );
      dataSource = procDocDataSource;
      break;
    case "documentCaseList":
      dispatch(routerActions.replace(`/${page}/case=${checked}/documents`));
      dataSource = caseDocDataSource;
      break;
    case "documentView":
      dispatch(
        routerActions.replace(
          `/${page}/case=${checked}/proceeding=${viewDocument.proceedingId}/document=${viewDocument.id}`
        )
      );
      break;
    default:
      break;
  }
  if (needload) {
    dataSource.reset();
  }
  this.setState({ dataSource }, () =>
    needload ? this.load(true) : this.setState({ loading: false })
  );
}

export function nextDisplay(newDisplay, needLoad = true) {
  this.setState({ loading: true });
  const { proceedingsList } = this.props;
  const { activeCase, history, display, procChecked } = this.state;
  if (display !== newDisplay) {
    if (newDisplay === "documentCaseList") {
      this.setState({ blockScreen: true }, () => {
        myCaseDocDataSource.setFilter("case", activeCase.id);
        myCaseDocDataSource.sort = { createdAt: "desc" };
        caseDocDataSource.setValue("params", { case_id: activeCase.id });
        caseDocDataSource.sort = { docDate: "desc" };
        Promise.all([
          caseDocDataSource.load(),
          myCaseDocDataSource.load(),
        ]).then(() =>
          this.setState({
            blockScreen: false,
          })
        );
      });
    }
    history.push(display);
  }
  const activeProc = Array.isArray(proceedingsList)
    ? proceedingsList.find(({ id }) => id === procChecked)
    : null;
  this.setState(
    {
      activeProc,
      display: newDisplay,
      history,
      procChecked: newDisplay === "procList" ? "" : procChecked,
    },
    () => this.setDataSource(needLoad)
  );
}

export function backToPrevDisplay() {
  let { history } = this.state;
  if (history.length > 0) {
    const display = history[history.length - 1];
    history = history.slice(0, -1);
    this.setState({ display, history, scanDocumentId: null }, () =>
      this.setDataSource(false)
    );
  } else {
    this.setDefaultProps();
  }
}

export function getSubTitle() {
  const { t, list, proceedingsList } = this.props;
  const { display, procChecked, viewDocument, activeCase } = this.state;
  let title = t(this.props.title);
  let subTitle = t(this.props.title + "2");
  let myProcDocTitle = "";
  let shareSubTitle = "";
  let myCaseDocTitle = "";
  const foundProc = Array.isArray(proceedingsList)
    ? proceedingsList.find(({ id }) => id === procChecked)
    : null;

  switch (display) {
    case "procList":
      title = t("PROC_LIST");
      subTitle = activeCase
        ? t("PROC_LIST2", {
            number: activeCase.number,
            date: humanDateFormat(activeCase.createdAt),
          })
        : "";
      shareSubTitle =
        activeCase && foundProc
          ? t("SHARE_SUBTITLE2", {
              proc: foundProc.number,
              number: activeCase.number,
              date: humanDateFormat(activeCase.createdAt),
            })
          : "";
      break;
    case "documentProcList":
      title = t("DOC_LIST1");
      subTitle =
        activeCase && foundProc
          ? t("DOC_LIST2", {
              proc: foundProc.number,
              number: activeCase.number,
              date: humanDateFormat(activeCase.createdAt),
            })
          : "";
      myProcDocTitle =
        activeCase && foundProc
          ? t("DOC_TITLE1", {
              proc: foundProc.number,
              number: activeCase.number,
              date: humanDateFormat(activeCase.createdAt),
            })
          : "";
      break;
    case "documentCaseList":
      title = t("DOC_LIST");
      subTitle = activeCase
        ? t("DOC_LIST3", {
            number: activeCase.number,
            date: humanDateFormat(activeCase.createdAt),
          })
        : "";
      myCaseDocTitle = activeCase
        ? t("DOC_TITLE2", {
            number: activeCase.number,
            date: humanDateFormat(activeCase.createdAt),
          })
        : "";
      break;
    case "documentView":
      title = t("DOC_TITLE");
      subTitle =
        activeCase && foundProc && viewDocument
          ? t("DOC_TITLE3", {
              doc: viewDocument.description,
              proc: foundProc.number,
              number: activeCase.number,
              date: humanDateFormat(activeCase.createdAt),
            })
          : "";
      break;
    case "caseList":
      shareSubTitle = activeCase
        ? t("SHARE_SUBTITLE", {
            number: activeCase.number,
            date: humanDateFormat(activeCase.createdAt),
          })
        : "";
      if (list !== null && list.length === 0) {
        title = t("CASE_EMPTY_PAGE_TITLE");
        subTitle = t("CASE_EMPTY_PAGE_DESCRIPTION");
      }
      break;
    default:
      break;
  }
  return { title, subTitle, myProcDocTitle, shareSubTitle, myCaseDocTitle };
}
