import React, { Fragment } from "react";
import { translate } from "react-translate";

import { Link } from "react-router-dom";
import classnames from "classnames";

import {
  withStyles,
  AppBar,
  Toolbar,
  Button,
  LinearProgress,
} from "@material-ui/core";

import HeaderLinks from "../../../components/Header/HeaderLinks";
import { isLoggedInCompletely } from "../../../actions/auth";

import config from "../../../config";

import searchStyle from "../../../variables/styles/searchStyle";
import logo from "../../../assets/img/logoRegistry.svg";

const { application, authLink } = config();

const CABINET_LINK = application.name === "cabinet" ? "/" : authLink;

const Header = ({ t, classes, loading }) => (
  <Fragment>
    <AppBar position="static" className={classes.header}>
      <Toolbar>
        <Link to="/search">
          <img src={logo} style={{ height: 54 }} alt="logo" />
        </Link>
        <div className={classnames(classes.grow, classes.headerButtons)}>
          {false && <Button color="transparent">Законодавство</Button>}
        </div>
        <Link to={CABINET_LINK}>
          <Button color="inherit">{t("TO_MAIN_PAGE")}</Button>
        </Link>
        {isLoggedInCompletely() && <HeaderLinks />}
      </Toolbar>
    </AppBar>
    {loading && <LinearProgress className={classes.absolutePosition} />}
  </Fragment>
);

const translated = translate("SearchPage")(Header);
export default withStyles(searchStyle)(translated);
