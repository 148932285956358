import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import customInputStyle from "../../../variables/styles/customInputStyle";

// import { getAuthMode, setAuthMode } from 'actions/user';

import PhoneEditModal from "./PhoneEditModal";
// import TwoFactorModal from './TwoFactorModal';

class PhoneInput extends React.Component {
  state = { showPhoneEditModal: false, showPhoneCheckModal: false };
  // componentDidMount = getAuthMode;

  closePhoneEditModal = () => this.setState({ showPhoneEditModal: false });
  // closePhoneCheckModal = () => this.setState({ showPhoneCheckModal: false }, getAuthMode);

  showPhoneEditModal = () => this.setState({ showPhoneEditModal: true });
  showPhoneCheckModal = () => this.setState({ showPhoneCheckModal: true });

  toggleAuthMode = () => {
    // const { auth } = this.props;
    // const { useTwoFactorAuth } = auth;

    // if (useTwoFactorAuth) {
    //     setAuthMode({ useTwoFactorAuth: false }).then(getAuthMode);
    // } else {
    this.showPhoneCheckModal();
    // }
  };

  render() {
    const { showPhoneEditModal } = this.state;
    const { t, value, onChange, disabled } = this.props;
    // const {useTwoFactorAuth} = auth;
    const setId = (elementName) => this.props.setId(`phone-${elementName}`);

    return (
      <Fragment>
        <TextField
          id={setId("input")}
          disabled={true}
          name="phone"
          label={t("PHONE_INPUT_LABEL")}
          value={value || ""}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.showPhoneEditModal}
                  disabled={disabled}
                  id={setId("edit-button")}
                >
                  <Icon>edit</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <span
                    style={{cursor: 'pointer', color: '#0059aa'}}
                    onClick={this.toggleAuthMode}
                    id={setId('two-factor-link')}
                >
                    {useTwoFactorAuth ?
                        t('TWO_FACTOR_AUTH_DISABLE') :
                        t('TWO_FACTOR_AUTH_ENABLE')
                    }
                </span> */}
        <PhoneEditModal
          open={showPhoneEditModal}
          onClose={this.closePhoneEditModal}
          onChange={onChange}
          setId={(elementName) => setId(`edit-dialog-${elementName}`)}
        />
        {/* <TwoFactorModal
                    phone={value}
                    open={showPhoneCheckModal}
                    onClose={this.closePhoneCheckModal}
                    setId={elementName => setId(`two-factor-dialog-${elementName}`)}
                /> */}
      </Fragment>
    );
  }
}

PhoneInput.propTypes = {
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PhoneInput.defaultProps = {
  value: "",
};

const styled = withStyles(customInputStyle)(PhoneInput);
const translated = translate("UserProfile")(styled);

function mapStateToProps(state) {
  return { auth: state.authorization };
}

// decorate and export
export default connect(mapStateToProps)(translated);
