import React, { Fragment } from "react";
import {
  withStyles,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { Clear, Check } from "@material-ui/icons";
import PropTypes from "prop-types";
import cx from "classnames";
import setComponentsId from "../../helpers/setComponentsId";

import customInputStyle from "../../variables/styles/customInputStyle";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id: propId,
    setId,
    labelProps,
    inputProps,
    error,
    success,
    onClear,
    helperText,
    showErrors,
  } = props;

  const labelClasses = cx(
    error && classes.labelRootError,
    success && !error && classes.labelRootSuccess
  );

  /*   const inkbarClasses = cx({
     [classes.inkbarError]: error,
     [classes.inkbarSuccess]: success && !error,
     [classes.inkbar]: !success && !error
     });
*/

  const marginTop = cx({
    [classes.marginTop]: labelText === undefined,
  });
  const id = propId ? setId(` ${propId}`) : setId("");
  return (
    <FormControl
      {...formControlProps}
      className={cx(formControlProps.className, classes.formControl)}
    >
      {labelText && (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          id={setId("label")}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      )}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          // inkbar: inkbarClasses,
          underline: classes.underline,
        }}
        id={id}
        {...inputProps}
      />
      {helperText && (
        <FormHelperText
          id={setId("helper-text")}
          className={cx(showErrors && error && classes.labelRootError)}
        >
          {helperText}
        </FormHelperText>
      )}
      {error && (
        <Fragment>
          <IconButton
            onClick={onClear}
            className={cx(classes.feedback, classes.labelRootError)}
            id={setId("clear-button")}
          >
            <Clear />
          </IconButton>
          {success && (
            <Check className={cx(classes.feedback, classes.labelRootSuccess)} />
          )}
        </Fragment>
      )}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  setId: PropTypes.func,
  onClear: PropTypes.func,
  helperText: PropTypes.string,
  showErrors: PropTypes.bool,
};

CustomInput.defaultProps = {
  onClear: () => null,
  setId: setComponentsId("custom-input"),
  labelText: "",
  labelProps: {},
  id: "",
  inputProps: {},
  formControlProps: {},
  error: false,
  success: false,
  helperText: "",
  showErrors: false,
};

export default withStyles(customInputStyle)(CustomInput);
