import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const RegionListFilterChip = ({ t, value, regions, ...rest }) => (
  <Chip
    label={`${t("REGIONS")}: ${(regions || [])
      .filter((region) => value.includes(region.id))
      .map((region) => region.name)
      .join(", ")}`}
    {...rest}
  />
);

RegionListFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  regions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

RegionListFilterChip.defaultProps = {
  value: "",
  regions: [],
};

const translated = translate("ClaimList")(RegionListFilterChip);
export default connect(({ dictionary: { regions } }) => ({ regions }))(
  translated
);
