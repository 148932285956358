import React from "react";
import PropTypes from "prop-types";

import PdfDocument from "../../components/PDF";
import IMGPreview from "../../components/IMG";
import DOCPreview from "../../components/DOC";
import HTMLPreview from "../../components/HTMLPreview";
import TextPreview from "../../components/TextPreview";
import PreloaderPreview from "../../components/PreloaderPreview";
import Media from "../../components/Media";

const Preview = ({
  doc,
  text,
  source,
  download,
  name = "file",
  format,
  url,
  size,
}) => {
  if (!doc && !text) {
    return <PreloaderPreview />;
  }

  if (!source) {
    return <TextPreview text={text} />;
  }

  switch (format) {
    case "pdf":
      return (
        <PdfDocument
          doc={source}
          pdf={doc}
          fileName={name}
          hideDownload={true}
        />
      );
    case "video":
    case "audio":
      return (
        <Media
          handleDownload={download}
          format={format}
          name={name}
          url={url}
        />
      );
    case "image":
      return (
        <IMGPreview imageUrl={url} fileName={name} handleDownload={download} />
      );
    case "googleViewDoc":
      return (
        <DOCPreview docUrl={url} fileName={name} handleDownload={download} />
      );
    case "html":
      if (source) {
        return (
          <HTMLPreview
            size={size}
            file={source}
            fileName={name}
            handleDownload={download}
          />
        );
      }
      if (text) {
        return (
          <HTMLPreview
            size={size}
            fileName={name}
            handleDownload={download}
            content={<div dangerouslySetInnerHTML={{ __html: text }} />}
          />
        );
      }
      return null;
    default:
      return (
        <HTMLPreview
          size={size}
          fileName={name}
          handleDownload={download}
          file={doc}
          url={`data:text/html;charset=utf-8,${encodeURIComponent(text)}`}
        />
      );
  }
};

Preview.propTypes = {
  doc: PropTypes.object,
  text: PropTypes.string,
  source: PropTypes.object,
  download: PropTypes.func,
  name: PropTypes.string,
  format: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Preview.defaultProps = {
  doc: null,
  text: "",
  source: null,
  download: () => null,
  name: "",
  format: "",
  url: "",
  size: 0,
};

export default Preview;
