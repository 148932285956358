import { FC, useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { FormValues, SchemaFormProps } from "../types";

const DatasetForm: FC<SchemaFormProps> = ({
  data,
  name,
  onValueChange,
  onDataUpdate,
  schema,
}) => {
  const [filteredRows, setFilteredRows] = useState<FormValues[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(0);

  useEffect(() => {
    let list = getData();
    if (searchValue) {
      list = list.filter((item: FormValues) => {
        const values = Object.values(item);
        for (let i = 0; i < values.length; i++) {
          const value = values[i].toString().toLowerCase();
          if (value.includes(searchValue.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }
    const index = list.findIndex((item) => item.checkbox);
    setFilteredRows(list);
    setSelectedCheckbox(index);
  }, [searchValue]);

  const getData = (): FormValues[] =>
    Array.isArray(data) ? (data as FormValues[]) : [];

  const handleChangeCheckBox = async (index: number) => {
    setSelectedCheckbox(index);
    const item = filteredRows[index];
    delete item.checkbox;
    const data = getData();

    const updatedData = data.map((value) => {
      delete value.checkbox;
      const checkbox = JSON.stringify(value) === JSON.stringify(item);
      return {
        checkbox,
        ...value,
      };
    });
    onDataUpdate && (await onDataUpdate(name, updatedData));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const renderProperties = () => {
    const properties = schema.items?.properties || {};
    const visibleColumns = Object.keys(properties).filter(
      (key: string) => !properties[key]?.hidden
    );

    const tableHeaders = visibleColumns.map((key: string) => (
      <TableCell key={key}>{properties[key]?.title}</TableCell>
    ));

    const rows: FormValues[] = filteredRows.map(
      (item: FormValues, index: number) => {
        const row: FormValues = {
          id: index + 1,
          key: index + 1,
          item: {},
          ...item,
        };
        visibleColumns.forEach((key: string) => {
          if (item.hasOwnProperty(key)) {
            row[key] = item[key];
          }
        });
        return row;
      }
    );

    const tableRows = rows.map((row: FormValues, index: number) => (
      <TableRow key={row.id}>
        <TableCell>
          <Checkbox
            color="primary"
            checked={index === selectedCheckbox}
            onChange={() => handleChangeCheckBox(index)}
          />
        </TableCell>
        {visibleColumns.map((key: string) => (
          <TableCell key={`${row.id}-${key}`}>{row[key]}</TableCell>
        ))}
      </TableRow>
    ));

    return (
      <div style={{ width: "100%" }}>
        <TextField
          style={{ display: "flex", marginBottom: "4px" }}
          label="Пошук"
          value={searchValue}
          onChange={handleSearchChange}
        />
        <div style={{ overflowX: "auto", maxHeight: "400px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {tableHeaders}
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </div>
      </div>
    );
  };

  return getData().length !== 0 ? (
    <div>{renderProperties()}</div>
  ) : (
    <div>"За заданими критеріями в ЄДР нічого не знайдено"</div>
  );
};

export default DatasetForm;
