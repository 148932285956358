import React from "react";
import { Toolbar } from "@material-ui/core";
import { Button } from "../../../components";
import Filters from "./Filters";

import statuses from "../../../variables/stateRegistersStatuses";

export interface RequestToolbarProps {
  classes: any;
  setId: (s: string) => string;
  t: (s: string, args?: Record<string, any>) => string;
  onClickCreate: (registerId: number) => void;
  registerId?: number;
  dataSource: any;
  load: any;
  returnObject: any;
}

const RequestToolbar: React.FC<RequestToolbarProps> = ({
  classes,
  onClickCreate,
  registerId,
  setId,
  t,
  dataSource,
  load,
  returnObject,
}: RequestToolbarProps) => {
  return (
    <Toolbar
      className={classes.toolbar}
      disableGutters={true}
      id={setId("toolbar")}
    >
      <Button
        disabled={!registerId}
        color="yellow"
        variant="contained"
        onClick={() => registerId && onClickCreate(registerId)}
        id={setId("create-button")}
        setId={(elmentName: string) => setId(`create-${elmentName}`)}
      >
        {t("CREATE_REQUEST")}
      </Button>
      &nbsp;
      <Filters
        t={t}
        classes={classes}
        setId={setId}
        dataSource={dataSource}
        statuses={statuses}
        load={load}
        returnObject={returnObject}
      />
    </Toolbar>
  );
};

export default RequestToolbar;
