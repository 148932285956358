const initialState = {};

const REQUEST_REGIONS_SUCCESS = "REQUEST_REGIONS_SUCCESS";
const REQUEST_COURTS_SUCCESS = "REQUEST_COURTS_SUCCESS";
const REQUEST_COURT_TYPES_SUCCESS = "REQUEST_COURT_TYPES_SUCCESS";
const REQUEST_JUSTICE_TYPES_SUCCESS = "REQUEST_JUSTICE_TYPES_SUCCESS";
const REQUEST_JUDGEMENT_FORMS_SUCCESS = "REQUEST_JUDGEMENT_FORMS_SUCCESS";
const REQUEST_JUSTICE_KINDS_SUCCESS = "REQUEST_JUSTICE_KINDS_SUCCESS";
const REQUEST_EDS_SERVER_LIST_SUCCESS = "REQUEST_EDS_SERVER_LIST_SUCCESS";
const REQUEST_CASES_MEMBER_ROLES_SUCCESS = "REQUEST_CASES_MEMBER_ROLES_SUCCESS";
const REQUEST_CASES_JUDGE_ROLES_SUCCESS = "REQUEST_CASES_JUDGE_ROLES_SUCCESS";
const REQUEST_CASES_STATUSES_SUCCESS = "REQUEST_CASES_STATUSES_SUCCESS";
const REQUEST_PROCEEDING_STATUSES_SUCCESS =
  "REQUEST_PROCEEDING_STATUSES_SUCCESS";
const REQUEST_JURISDICTION_TYPES_SUCCESS = "REQUEST_JURISDICTION_TYPES_SUCCESS";
const REQUEST_EXECUTE_ORG_SUCCESS = "REQUEST_EXECUTE_ORG_SUCCESS";
const REQUEST_EDD_STATUSES_SUCCESS = "REQUEST_EDD_STATUSES_SUCCESS";

const filterServers = (servers) =>
  servers.reduce((acc, server) => {
    if (server.address) {
      server.name = server.name.replace(
        "Акредитований Центр сертифікації ключів",
        "АЦСК"
      );
      server.name = server.name.replace(
        "Акредитований центр сертифікації ключів",
        "АЦСК"
      );
      server.name = server.name.replace(
        "Публічного акціонерного товариства",
        "ПАТ"
      );
      server.name = server.name.replace("Центр сертифікації ключів", "ЦСК");
      acc.push(server);
    }
    return acc;
  }, []);

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload,
      };
    case REQUEST_CASES_MEMBER_ROLES_SUCCESS:
      return {
        ...state,
        memberRoles: action.payload,
      };
    case REQUEST_CASES_STATUSES_SUCCESS:
      return {
        ...state,
        casesStatuses: action.payload,
      };
    case REQUEST_PROCEEDING_STATUSES_SUCCESS:
      return {
        ...state,
        proceeding_statuses: action.payload,
      };
    case REQUEST_CASES_JUDGE_ROLES_SUCCESS:
      return {
        ...state,
        judgeRoles: action.payload,
      };
    case REQUEST_COURTS_SUCCESS:
      return {
        ...state,
        courts: Array.isArray(action.payload)
          ? action.payload.sort((a, b) => a.name.localeCompare(b.name))
          : null,
      };
    case REQUEST_COURT_TYPES_SUCCESS:
      return {
        ...state,
        courtTypes: action.payload,
      };
    case REQUEST_JUSTICE_TYPES_SUCCESS:
      return {
        ...state,
        justiceTypes: action.payload,
      };
    case REQUEST_JUDGEMENT_FORMS_SUCCESS:
      return {
        ...state,
        judgmentForms: action.payload,
      };
    case REQUEST_JUSTICE_KINDS_SUCCESS:
      return {
        ...state,
        justiceKinds: action.payload,
      };
    case REQUEST_JURISDICTION_TYPES_SUCCESS:
      return {
        ...state,
        jurisdiction_types: action.payload,
      };
    case REQUEST_EDS_SERVER_LIST_SUCCESS:
      return {
        ...state,
        edsServers:
          Array.isArray(action.payload) && filterServers(action.payload),
      };
    case REQUEST_EXECUTE_ORG_SUCCESS:
      return {
        ...state,
        executeOrg: action.payload,
      };
    case REQUEST_EDD_STATUSES_SUCCESS:
      return {
        ...state,
        EDDStauses: action.payload,
      };
    default:
      return state;
  }
};
