const herb =
  "data:image/gif;base64,R0lGODlhPABQAKIHANHR0e7u7rS0tGVlZYyMjDk5OQAAAP///yH5BAEAAAcALAAAAAA8AFAAAAP/aLrc/jBKd6q9OOvNu1VeKI4bSJ4oZ6Ysu7aYMMDaSx+BItyXTROKAu9jGFYYAaPvsEMJGARlMWMoAE7AhfAU2GKWi+towPCKAODpdzETkbWkrLo3J7Ld5TGjVj822h1vQXh7VH0HD4AbglUeXRSGfA5RHIxmGowLfJIDcgZimHkcTw+bhgYzgpcXnpQaOQsEBZqRpzOwCqAxUJVws2mcFXKrFaQKrhhoYQe/h2moFbifGsYGyKx3zLRrwcKx1LwazUnawLYWyo0Z1dcV6V7NpmvQdofVuhZyTeXO/fQVgvAxYSDwQEAL8WrNUyRHYLppGRJWkEinm7djGaQJxGWG/yIRiwMxYtCYEQ7CbRXPoct2geRIlvzk0fl3IJ2iYgTXfTtpbmFLkxfuZRC0L6bCmTcLBc2JgegFj35AGr3AqB2Spyg/quRpgBxXay8HYe2JFIMcrzgaXHqHASoifzf1XXhow9glt8/iLihaLRc2kVxl2lFUTVEmsPm0oMULNzEDRRCoNvDKuJunbXaZ8ttW+Zy0AgLQBpTjioCsxxOzRj1VYECzVdJqKrVQTQCAzvMcFAW4s9lugxEE+3mw2yXbXZGP1mtwrRnkZX9LKR9eIIBQd1dpz34UpFk/SVu8E7DtO2IsAOhfJ8E9c4u0B2ixSxDD3o4X7hBcBbisO/D0t//qRCNDBDP05YAVY33H2isTQABafFOlNA8xGvF3TDPtpEaWfRrYhFMZSRhzk3/cLKgTYN6RE5s4qr0l1YeIhfTPiuZtOFwVBeSoo3c7lqGjjzvmOJtWuTVo5JEKFonkksmVqCSTULa4RAgEoSfHAOjdBhMJU3ogCCUiRpMdCjmy4AmMbdjFggyduLBMmC6hMMAOXaUwjHV3qMICGsKMGAJ+BNj00G9/gmZBm06UoUU4ZJrRxQAQejkBMY60hsmDJOAHHwq3UVoTGQREuoF3WhQ0So6EjgQEliJoSpMHAKxq6gb7oSqqZDq0qmWoMAAA6q04zWrBflUIy4WsHQDLxK8wRrQkC6tcCJAjr81Sg+oZZCBYLazTRlqrNcpuOxez7mSbqrghADFet+jSYF2g2yYAADs=";
const styles =
  '<style type="text/css">* {max-width: 100%; box-sizing: border-box;} body: {padding: 12px 24px;}</style>';

export default (file, text) =>
  new Promise((resolve) => {
    if (file.size && file.type === "application/octet-stream") {
      if (text.indexOf("PDF") > 0) {
        file = new Blob([file], { type: "application/pdf" });
      }
    }
    const index = text.indexOf("text/html");
    if (index > 0) {
      const charset = text.substring(index, index + 50);
      const isWindows1251 = charset.includes("-1251");
      if (isWindows1251) {
        text = text.replace("herb.gif", herb);
        text = text.replace("Windows-1251", "utf-8");
        text = text.replace("windows-1251", "utf-8");
        text = text.replace("WINDOWS-1251", "utf-8");
        text = text.replace("WINDOWS-1251", "utf-8");
        let endHeadIndex = text.indexOf("</head>");
        if (endHeadIndex < 0) endHeadIndex = text.indexOf("</HEAD>");
        if (endHeadIndex >= 0) {
          const start = text.substring(0, endHeadIndex);
          const end = text.substring(endHeadIndex);
          text = `${start}${styles}${end}`;
        }
        file = new Blob([text], { type: "text/html" });
      } else {
        file = new Blob([file], { type: "text/html" });
      }
    }
    return resolve(file);
  });
