import statuses from "../variables/claimStatuses";
import normalizeClaim from "../helpers/normalizeClaim";
import DataSource from "../helpers/dataSource";

const REQUEST_CLAIM_TEMPLATE_LIST_SUCCESS =
  "REQUEST_CLAIM_TEMPLATE_LIST_SUCCESS";
const REQUEST_CLAIM_TEMPLATE_CATEGORIES_SUCCESS =
  "REQUEST_CLAIM_TEMPLATE_CATEGORIES_SUCCESS";
const CLAIM_CREATED = "CLAIM_CREATED";
const SIGN_DOCUMENT_SUCCESS = "SIGN_DOCUMENT_SUCCESS";
const COMMIT_DOCUMENT_SUCCESS = "COMMIT_DOCUMENT_SUCCESS";
const RECOVER_CLAIM_SUCCESS = "RECOVER_CLAIM_SUCCESS";
const DELETE_CLAIM_SUCCESS = "DELETE_CLAIM_SUCCESS";
const PERMANENT_DELETE_CLAIM_SUCCESS = "PERMANENT_DELETE_CLAIM_SUCCESS";
const ADD_ENCODE_KEY = "ADD_ENCODE_KEY";
const REQUEST_CLAIM_SUCCESS = "REQUEST_CLAIM_SUCCESS";
const REQUEST_CLAIM_TEMPLATE_SUCCESS = "REQUEST_CLAIM_TEMPLATE_SUCCESS";
const GET_EXECUTIVE_COUNT = "GET_EXECUTIVE_COUNT_SUCCESS";
const GET_APPROVAL_COUNT = "GET_APPROVAL_COUNT_SUCCESS";

export const ADD_ACTIVE_CLAIM = "ADD_ACTIVE_CLAIM";
export const ADD_ACTIVE_TEMPLATE = "ADD_ACTIVE_TEMPLATE";
export const ADD_CLAIM_PERMISSIONS = "ADD_CLAIM_PERMISSIONS";
export const CLEAR_ACTIVE_CLAIM = "CLEAR_ACTIVE_CLAIM";
export const SET_WIZARD_STATES = "SET_WIZARD_STATES";
export const CLEAR_STATES = "CLEAR_STATES";
export const SAVE_CLAIM_SIGNATURE = "SAVE_CLAIM_SIGNATURE";
export const CLEAR_CLAIM_SIGNATURES = "CLEAR_CLAIM_SIGNATURES";

const wizardStates = {
  origin: null,
  error: null,
  errors: {},
  claim: null,
  manifest: null,
  template: null,
  stepOrders: [],
  loading: false,
  activeStepId: -1,
  maxStep: 0,
  saving: false,
  claimSaved: false,
  claimCommited: false,
  showSignModal: false,
  showSelectUsers: false,
  usersToSend: [],
  usersArray: [],
  pdf: null,
  doc: null,
  previews: {},
  courtNotFound: false,
  encryptionKey: null,
  busy: true,
  redirectTo: { name: "claims", title: "LIST", getUrl: () => "/claims" },
  init: true,
  rootComponent: "claims",
  history: [],
  pdfError: "",
};

const initialState = {
  highlightClaim: null,
  list: null,
  templates: null,
  categories: null,
  encryptKeys: {},
  statuses,
  activeClaim: null,
  permissions: null,
  activeTemplate: null,
  blockScreen: false,
  executiveCount: 0,
  approvalCount: 0,
  signatures: new Map(),
  ...wizardStates,
};

const dataSource = DataSource("api/documents/my");
const approvalDataSource = DataSource("api/documents/for_sign");

export default (state = initialState, action) => {
  const { payload, type } = action;
  const source = type.includes("FOR_SIGN") ? approvalDataSource : dataSource;
  state = source.reducer()(state, action);
  const { rootComponent, t, history, match } = state;

  switch (type) {
    case REQUEST_CLAIM_TEMPLATE_LIST_SUCCESS:
      if (!Array.isArray(payload)) {
        return state;
      }
      return { ...state, templates: payload };
    case REQUEST_CLAIM_TEMPLATE_CATEGORIES_SUCCESS:
      if (!Array.isArray(payload)) {
        return state;
      }
      return { ...state, categories: payload };
    case CLAIM_CREATED:
      return {
        ...state,
        list: [payload].concat(state.list || []),
        highlightClaim: payload.id,
        activeClaim: normalizeClaim(payload),
        origin: null,
      };
    case SIGN_DOCUMENT_SUCCESS:
    case COMMIT_DOCUMENT_SUCCESS:
    case RECOVER_CLAIM_SUCCESS:
    case DELETE_CLAIM_SUCCESS:
    case PERMANENT_DELETE_CLAIM_SUCCESS:
      if (!state.list) {
        return state;
      }
      return {
        ...state,
        list: state.list.map((claim) => {
          if (payload.id === claim.id) {
            return payload;
          }
          return claim;
        }),
        highlightClaim: payload.id,
      };
    case ADD_ACTIVE_CLAIM:
      return {
        ...state,
        activeClaim: payload,
        origin: null,
      };
    case ADD_CLAIM_PERMISSIONS:
      return { ...state, permissions: payload };
    case REQUEST_CLAIM_TEMPLATE_SUCCESS:
      return {
        ...state,
        activeTemplate: {
          ...payload,
          jsonSchema: JSON.parse(payload.jsonSchema),
        },
      };
    case ADD_ENCODE_KEY:
      return {
        ...state,
        encryptKeys: {
          ...state.encryptKeys,
          [payload.claimId]: payload.key,
        },
      };
    case REQUEST_CLAIM_SUCCESS:
      return {
        ...state,
        origin: normalizeClaim(payload),
      };
    case CLEAR_ACTIVE_CLAIM:
      return { ...state, activeClaim: null, permissions: null };
    case SET_WIZARD_STATES:
      if (
        state.claim &&
        state.claim.id &&
        payload.claim &&
        payload.claim.id === state.claim.id
      ) {
        return {
          ...state,
          ...payload,
          claim: { ...state.claim, ...payload.claim },
        };
      }
      return {
        ...state,
        ...payload,
      };
    case CLEAR_STATES:
      return {
        ...state,
        ...wizardStates,
        rootComponent,
        t,
        history,
        match,
      };
    case GET_EXECUTIVE_COUNT:
      return {
        ...state,
        executiveCount:
          typeof payload === "number" ? payload : (payload || {}).data || 0,
      };
    case GET_APPROVAL_COUNT:
      return { ...state, approvalCount: (payload || {}).count || 0 };
    case SAVE_CLAIM_SIGNATURE:
      const claimSigns = state.signatures.get(payload.id) || [];
      claimSigns.push(payload.signature);
      state.signatures.set(payload.id, claimSigns);
      return state;
    case CLEAR_CLAIM_SIGNATURES:
      state.signatures.delete(payload.id);
      return state;
    default:
      return state;
  }
};
