import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import classNames from "classnames";
import { translate } from "react-translate";

import { withStyles, Tabs, Tab } from "@material-ui/core";

import config from "../../config";

import FileKeySignForm from "./FileKeySignForm";
import HardwareKeySignForm from "./HardwareKeySignForm";
import IdGovWidget from "./IdGovWidget";

const { useOnlyIdGov } = config() || {};

const styles = (theme) => ({
  tab: {
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      margin: 0,
      padding: 0,
    },
  },
  tabsRoot: {
    [theme.breakpoints.down("xs")]: {
      margin: "0!important",
    },
  },
  containerXs: {
    [theme.breakpoints.down("xs")]: {
      padding: "0!important",
      justifyContent: "space-between",
    },
  },
});

const EDSForm = ({ t, classes, setId, ...rest }) => {
  const [tab, setTab] = React.useState(0);

  const fileKeySignForm = (
    <FileKeySignForm
      {...rest}
      setId={(elementName) => setId(`file-key-${elementName}`)}
    />
  );

  const hardwareKeySignForm = (
    <HardwareKeySignForm
      {...rest}
      setId={(elementName) => setId(`hardware-key-${elementName}`)}
    />
  );

  const idGovWidget = (
    <IdGovWidget
      t
      {...rest}
      setId={(elementName) => setId(`sign-widget-${elementName}`)}
    />
  );

  return (
    <>
      <Tabs
        value={tab}
        onChange={(event, value) => setTab(value)}
        indicatorColor="primary"
        textColor="primary"
        id={setId("tabs")}
        className={classes.tabsWrapper}
        classes={{
          flexContainer: classNames(classes.tabsContainer, classes.containerXs),
          root: classes.tabsRoot,
        }}
      >
        <Tab
          label={t("IdGovWidget")}
          id={setId("tab-id-gov-key")}
          className={classNames(classes.tab, classes.tabButton)}
        />
        {!useOnlyIdGov && (
          <Tab
            label={t("FileKeySignMethod")}
            id={setId("tab-file-key")}
            className={classNames(classes.tab, classes.tabButton)}
          />
        )}
        {!useOnlyIdGov && (
          <Tab
            label={t("HardwareKeySignMethod")}
            id={setId("tab-hardware-key")}
            className={classNames(classes.tab, classes.tabButton)}
          />
        )}
      </Tabs>
      {tab === 0 && idGovWidget}
      {tab === 1 && fileKeySignForm}
      {tab === 2 && hardwareKeySignForm}
    </>
  );
};

EDSForm.propTypes = {
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  onSelectKey: PropTypes.func.isRequired,
};

EDSForm.defaultProps = {
  setId: setComponentsId("sign-form"),
};

const styled = withStyles(styles)(EDSForm);
export default translate("SignForm")(styled);
