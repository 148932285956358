import { Component } from "react";
import { connect } from "react-redux";

// @ts-ignore
import { translate } from "react-translate";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Snackbar from "@material-ui/core/Snackbar";
import { compose } from "redux";

import { Button, Preloader, RegularCard } from "../../components";
import PageCardTitle from "../../components/PageCardTitle";

import customInputStyle from "../../variables/styles/customInputStyle";

import { requestUserInfo, setRole } from "../../actions/auth";

import {
  // getAuthMode,
  putUserSettings,
  updateUserInfo,
  setUserSettings,
} from "../../actions/user";

import {
  getLegalEntity,
  putLegalEntity,
  getLegalEntitySettings,
  putLegalEntitySettings,
  setLegalEntitySettings,
  checkLegalEntityUserRole,
} from "../../actions/legal-entity";

import promiseChain from "../../helpers/promiseChain";
import ProfileLayout from "./components/ProfileLayout";
import UploadDocsFilesDialog from "./components/UploadDocsFilesDialog";

import MyDocsTable from "./components/MyDocsTable";
import {
  requestDocsMeta,
  // previewMyDoc,
  delMyDoc,
  putMyDoc,
} from "../../actions/personalData";

import {
  InterfaceLayout,
  PersonalDataChangedHandler,
  PersonalDataState,
} from "./InterfaceLayout";
import {
  personalDataSelector,
  legalEntityDataStateSelector,
} from "../../selectors/personalData";
import { updatePersonData } from "../../actions/personalData";
import { Devices } from "./Devices";
import { SystemMessage } from "../../components/Header/SystemMessage";

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const styles = createStyles(customInputStyle);

enum TabPage {
  PrivateInfo,
  InterfaceSettings,
  profileDocs,
  Devices,
}

interface LocalFile {
  contentType: string;
  fileHash: string;
  fileLink: string;
  fileName: string;
  hashName: string;
  isChecked: boolean;
  name: string;
}

interface UserProfileProps extends WithStyles<typeof styles> {
  personalData: PersonalDataState;
  legalEntityData: PersonalDataState;
  updatePersonData: (data: PersonalDataState) => void;
  auth: Record<string, any>;
  t: any;
  setId: any;
  location: Location;
}

interface UserProfileState {
  values: Record<string, any> | null | any;
  saving: boolean;
  systemMessage: string;
  openUploadModal: boolean;
  showNotification: string;
  personalDataState: PersonalDataState;
  legalEntityDataState: PersonalDataState;
  activeTab: TabPage;
  localFiles: LocalFile[];
}

class UserProfile extends Component<UserProfileProps, UserProfileState> {
  state = {
    values: null,
    saving: false,
    systemMessage: "",
    showNotification: "",
    openUploadModal: false,
    localFiles: [],
    personalDataState: { ...this.props.personalData },
    legalEntityDataState: { ...this.props.legalEntityData },
    activeTab: TabPage.PrivateInfo,
  };

  componentDidMount() {
    (async () => {
      const values = this.getPersonalAuthInfo();
      const { address, subAddress } = values;
      if (address === null && subAddress) {
        values.address = subAddress;
        updateUserInfo(values);
      }

      let res = await requestDocsMeta();
      this.setState({
        localFiles: res,
      });
      await this.setValues();
      // getAuthMode();
    })();
  }

  openUploadModal = () => this.setState({ openUploadModal: true });
  closeUploadModal = () => this.setState({ openUploadModal: false });

  onMyDocsUploaded = async (uploadedFiles: LocalFile[]) => {
    this.setState({ saving: true });
    const localFiles: LocalFile[] = this.state.localFiles;

    for (const file of uploadedFiles) {
      localFiles.push(file);
    }

    this.setState(
      {
        localFiles,
        openUploadModal: false,
        saving: false,
      },
      () => this.showNotification(this.props.t("SAVED_DOCS"))
    );
  };

  componentDidUpdate = async (
    prevProps: Readonly<UserProfileProps>,
    prevState: Readonly<UserProfileState>
  ) => {
    if (prevProps.auth.role !== this.props.auth.role) {
      this.setValues();
    }
  };

  async componentWillUnmount() {
    await this.checkRole();
  }

  async checkRole() {
    const { auth } = this.props;
    let role = auth.role;
    // if (!role) {
    role = this.isLegalEntityProfile() ? "legalEntityAgent" : "individual";
    //}
    setRole(role);
  }

  isLegalEntityProfile() {
    return this.props.location.pathname === "/company-profile";
  }

  getPersonalAuthInfo() {
    return this.props.auth.info || {};
  }

  handleHaveNoDataInEDR(companyName: string) {
    this.setState({
      systemMessage: `Дані про ${companyName} відсутні в ЄДР`,
    });
  }

  async setValues() {
    const values = this.getPersonalAuthInfo();

    if (this.isLegalEntityProfile()) {
      const data = await getLegalEntity();
      await getLegalEntitySettings();
      // const settings = await getLegalEntitySettings();
      // this.setState({legalEntityDataState: settings});
      if (!data.edrpou) {
        return this.handleHaveNoDataInEDR(values.companyName);
      }

      if (!values.isLegalEntityHead) {
        return this.setState({
          values: data,
          systemMessage:
            "Відсутні права на редагування. Можливий тільки перегляд.",
        });
      }
      this.setState({ values: data });
    } else this.setState({ values });
  }

  /**
   * Если не адвокат то вернуть true.
   * Если адвокат и его сертификат проверен, то true.
   * @return {boolean}
   */
  validateAdvocate = () => {
    const { isAdvocate } = this.state.personalDataState;
    const {
      advocate: { isChecked },
    } = this.state.personalDataState;
    return isAdvocate ? isChecked : true;
  };

  validateEmail = () => {
    if (this.state.values !== null) {
      const { email, valid, emailIsValid, isLegal } = this.state.values;
      return email && (isLegal ? emailIsValid : (valid as any)?.email)
        ? true
        : false; //
    }
  };

  validatePhone = () => {
    if (this.state.values !== null) {
      const { phone } = this.state.values;
      return phone ? true : false; //
    }
  };

  showNotification = (text: string) => {
    this.setState({ showNotification: text }, () =>
      setTimeout(() => this.setState({ showNotification: "" }), 3000)
    );
  };

  validateBeforeSave() {
    if (!this.validateEmail()) {
      return this.showNotification(this.props.t("NEED_ACCEPT_EMAIL"));
    }
    if (!this.validatePhone()) {
      return this.showNotification(this.props.t("NEED_ACCEPT_PHONE"));
    }
    if (!this.validateAdvocate()) {
      return this.showNotification(this.props.t("NEED_ACCEPT_ADVOCATE"));
    }
    return true;
  }

  savePersonalInfo(info: any) {
    return updateUserInfo(info).then(requestUserInfo);
  }

  saveLegalEntity(info: any) {
    return putLegalEntity(info);
  }

  savePersonalSettings(info: PersonalDataState) {
    //return putUserSettings(info).then(requestUserInfo);
    return putUserSettings(info);
  }

  saveLegalEntitySettings(info: PersonalDataState) {
    return putLegalEntitySettings(info);
  }

  handleSave = async () => {
    if (!this.validateBeforeSave()) {
      return;
    }
    this.setState({ saving: true });
    //this.props.updatePersonData(this.state.personalDataState);

    // @ts-ignore
    if (!this.state?.values?.email?.trim()) {
      this.setState({ saving: false }, () =>
        this.showNotification(this.props.t("EMAIL_EMPTY_ERROR"))
      );
      // @ts-ignore
    } else if (!this.state?.values?.phone) {
      this.setState({ saving: false }, () =>
        this.showNotification(this.props.t("PHONE_EMPTY_ERROR"))
      );
    } else {
      if (this.isLegalEntityProfile()) {
        const { legalEntityId } = await this.saveLegalEntity(this.state.values);
        await this.saveLegalEntitySettings(this.state.legalEntityDataState);
        legalEntityId && (await requestUserInfo());
      } else {
        await this.savePersonalInfo(this.state.values);
        await this.savePersonalSettings(this.state.personalDataState);

        await this.checkRole();
      }
      this.setState({ saving: false }, () =>
        this.showNotification(this.props.t("PROFILE_SAVED"))
      );
    }
  };

  handleSaveSettings = async () => {
    this.setState({ saving: true });

    if (this.isLegalEntityProfile()) {
      await this.saveLegalEntitySettings(this.state.legalEntityDataState);
    } else {
      await this.savePersonalSettings(this.state.personalDataState);
    }
    this.setState({ saving: false }, () =>
      this.showNotification(this.props.t("PROFILE_SAVED"))
    );
  };

  handleCheckLegalEntityButton = async () => {
    this.setState({ saving: true });

    const { edrpou, isLegalEntityHead, companyName } =
      this.getPersonalAuthInfo();
    const data = await checkLegalEntityUserRole(edrpou);

    if (data.checkStatus === -2) {
      return this.handleHaveNoDataInEDR(companyName);
    }

    if (
      data.isLegalEntityHead !== isLegalEntityHead &&
      data.checkStatus === 1
    ) {
      await this.savePersonalInfo({
        ...this.getPersonalAuthInfo(),
        isLegalEntityHead,
      });
    }
    this.setState({ saving: false });
  };

  handleChange = ({ target }: { target: HTMLInputElement }) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          [target.name]: target.value,
        },
      };
    });
  };

  myDocsCheckBoxChange = async (target: any) => {
    const checkBoxId = target.target.id;
    const docIndex = parseInt(
      checkBoxId.replace("user-profile-doc-checkbox-", "")
    );
    const localFiles: LocalFile[] = this.state.localFiles;
    localFiles[docIndex].isChecked = target.target.checked;
    const result = await putMyDoc(localFiles[docIndex]);
    localFiles[docIndex] = result as LocalFile;
    this.setState({
      localFiles,
    });
  };

  checkboxChange = ({
    target: { checked, name },
  }: {
    target: HTMLInputElement;
  }) => {
    const { values = {} } = this.state;
    this.setState({
      values: {
        ...values,
        [name]: checked,
      },
    });
  };

  handleChangePhone = (phone: string) =>
    promiseChain([
      requestUserInfo,
      () =>
        new Promise((resolve) =>
          this.setState(
            {
              values: {
                ...(this.state.values || {}),
                phone,
              },
            },
            // @ts-ignore
            resolve
          )
        ),
    ]);

  handleChangeDate = (key: string) => (date: Date) => {
    const { values = {} } = this.state;
    this.setState({
      values: {
        ...values,
        [key]: date,
      },
    });
  };

  handleActiveTabChanged = (_: any, index: number) => {
    this.setState({ activeTab: index });
  };

  handleInterfaceChanged: PersonalDataChangedHandler = (name, value) => {
    if (this.isLegalEntityProfile()) {
      this.setState(({ legalEntityDataState }) => {
        return {
          legalEntityDataState: {
            ...legalEntityDataState,
            [name]: value,
          },
        };
      });
    } else {
      this.setState(({ personalDataState }) => {
        return {
          personalDataState: {
            ...personalDataState,
            [name]: value,
          },
        };
      });
    }
  };

  handlePersonalDataChanged: PersonalDataChangedHandler = (name, value) => {
    const personalDataState = setUserSettings(name, value).payload;
    this.setState({ personalDataState });
  };

  handleLegalDataChanged: PersonalDataChangedHandler = (name, value) => {
    const legalEntityDataState = setLegalEntitySettings(name, value).payload;
    this.setState({ legalEntityDataState });
  };

  getRoleForInputs() {
    if (this.isLegalEntityProfile()) return "legalEntityAgent";

    return this.getPersonalAuthInfo().isLegal ? "isLegal" : "notIsLegal";
  }

  getTitle() {
    const { t } = this.props;

    const { companyName, isLegalEntityHead } = this.getPersonalAuthInfo();

    if (this.isLegalEntityProfile()) {
      let right = isLegalEntityHead ? "без довіреності" : "по довіреності";
      return `${t(
        "PROFILE_PANEL_TITLE"
      )} ${companyName} (Ви маєте право вчиняти дії від імені юридичної особи ${right})`;
    }

    return t("EDIT_USER_HEADER");
  }

  removeFile = (fileLink: any) => {
    this.setState(({ localFiles }) => {
      return {
        localFiles: [...localFiles.filter((_) => _.fileLink !== fileLink)],
      };
    });
    delMyDoc(fileLink);
  };

  render() {
    const { t, setId, classes } = this.props;
    const {
      values,
      activeTab,
      saving,
      showNotification,
      systemMessage,
      openUploadModal,
      localFiles,
    } = this.state;

    const { isLegalEntityHead } = this.getPersonalAuthInfo();

    if (!values && !systemMessage) {
      return <Preloader />;
    }

    const { isLegal } = values || { isLegal: false };

    const readOnly =
      saving || (this.isLegalEntityProfile() && !isLegalEntityHead);

    return (
      <RegularCard
        headerColor="primary"
        cardTitle={
          <PageCardTitle
            text={this.getTitle()}
            stepName={`userProfile${isLegal ? "Legal" : ""}`}
            setId={setId}
            t={t}
          />
        }
        setId={setId}
      >
        <Tabs
          value={activeTab}
          onChange={this.handleActiveTabChanged}
          TabIndicatorProps={{
            // @ts-ignore
            style: { background: "#fcd700" },
          }}
        >
          <Tab label={t("SELF_INFORMATION")} />
          <Tab label={t("INTERFACE")} />
          <Tab label={t("MY_PROFILE_DOCS")} />
          <Tab label={t("MOBILE_DEVICES")} />
        </Tabs>
        <TabPanel value={activeTab} index={TabPage.PrivateInfo}>
          {systemMessage && <SystemMessage text={systemMessage} />}
          {values && (
            <ProfileLayout
              t={this.props.t}
              classes={this.props.classes}
              setId={this.props.setId}
              values={values!}
              // @ts-ignore
              role={this.getRoleForInputs()}
              checkboxChange={this.checkboxChange}
              handleChange={this.handleChange}
              handleChangePhone={this.handleChangePhone}
              handleChangeDate={this.handleChangeDate}
              personalData={this.state.personalDataState}
              handleInterfaceChanged={this.handleInterfaceChanged}
              readOnly={readOnly}
            />
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={TabPage.InterfaceSettings}>
          {this.isLegalEntityProfile() && (
            <InterfaceLayout
              t={this.props.t}
              values={this.props.legalEntityData}
              handleInterfaceChanged={this.handleLegalDataChanged}
              readOnly={readOnly}
            />
          )}

          {!this.isLegalEntityProfile() && (
            <InterfaceLayout
              t={this.props.t}
              values={this.props.personalData}
              handleInterfaceChanged={this.handlePersonalDataChanged}
              readOnly={readOnly}
            />
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={TabPage.profileDocs}>
          {openUploadModal && (
            <UploadDocsFilesDialog
              t={t}
              classes={classes}
              setId={setId}
              handleChange={this.myDocsCheckBoxChange}
              onMyDocsUploaded={this.onMyDocsUploaded}
              onClose={this.closeUploadModal}
              open={this.openUploadModal}
              showNotification={this.showNotification}
            />
          )}
          {activeTab === TabPage.profileDocs && (
            <Button
              color="yellow"
              onClick={this.openUploadModal}
              id={setId("save-button")}
              setId={(name: string) => setId(`save-${name}`)}
            >
              {t("UPLOAD_DOCS")}
            </Button>
          )}
          <div className="docs-list">
            <MyDocsTable
              handleChange={this.myDocsCheckBoxChange}
              removeFile={this.removeFile}
              localFiles={localFiles}
              setId={setId}
              t={t}
            />
          </div>
        </TabPanel>

        <TabPanel value={activeTab} index={TabPage.Devices}>
          <Devices t={this.props.t} />
        </TabPanel>

        {activeTab !== TabPage.Devices &&
          activeTab !== TabPage.InterfaceSettings &&
          activeTab !== TabPage.profileDocs && (
            <Button
              disabled={readOnly}
              color="yellow"
              onClick={this.handleSave}
              id={setId("save-button")}
              setId={(name: string) => setId(`save-${name}`)}
            >
              {t("SAVE_BUTTON")}
            </Button>
          )}

        {activeTab === TabPage.InterfaceSettings && (
          <Button
            disabled={readOnly}
            color="yellow"
            onClick={this.handleSaveSettings}
            id={setId("save-button")}
            setId={(name: string) => setId(`save-${name}`)}
          >
            {t("SAVE_SETTINGS_BUTTON")}
          </Button>
        )}

        <Snackbar
          id={setId("error")}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={!!showNotification}
          message={<span id="message-id">{showNotification}</span>}
        />
      </RegularCard>
    );
  }
}

function mapStateToProps(state: any) {
  const props = {
    auth: state.authorization,
    personalData: personalDataSelector(state),
    legalEntityData: legalEntityDataStateSelector(state),
  };
  return props;
}

function mapDispatchToProps() {
  return {
    updatePersonData: updatePersonData,
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate("UserProfile"),
  withStyles(customInputStyle)
)(UserProfile);
