export default function updateAttaches(value, stepId, claim) {
  const { digitalDocumentData } = claim;

  if (stepId !== "attaches" || !digitalDocumentData.payment) {
    return claim;
  }

  const { benefits } = digitalDocumentData.payment;
  if (!benefits || !benefits.confirmationFile) {
    return claim;
  }

  const exists =
    Array.isArray(value) &&
    value
      .filter(
        (attach) => attach.attachId === benefits.confirmationFile.attachId
      )
      .shift();

  if (exists) {
    return claim;
  }

  const payment = {
    ...digitalDocumentData.payment,
    benefits: {
      ...digitalDocumentData.payment.benefits,
      confirmationFile: undefined,
    },
  };

  return Promise.resolve({
    ...claim,
    digitalDocumentData: { ...digitalDocumentData, payment },
  });
}
