export default {
  OR: " або ",
  "image/*": "зображення",
  "audio/": "аудіо",
  "video/": "відео",
  "audio/*": "аудіо",
  "video/*": "відео",
  "image/gif": "GIF зображення",
  "image/jpg": "JPG зображення",
  "application/pdf": "PDF документ",
  "application/msword": "документ Word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "документ Word",
  "application/vnd.oasis.opendocument.text": "документ Word",
  "application/rtf": "документ Word",
  "application/zip": "ZIP архів",
  "application/x-zip-compressed": "ZIP архів",
  "text/html": "HTML документ",
  "application/pkcs7-signature": "p7s файл підпису",
};
