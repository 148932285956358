export const fields = {
  pagination: true,
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
  tableFields: [
    {
      key: "fullName",
      classNames: [
        "cell",
        "longCell",
        "onlyMobile",
        "verticalAlign",
        "onlyFullName",
      ],
      grid: [2, 7, 1, 2],
    },
    {
      key: "titleMessage",
      title: "NOTIFICATION_TITLE",
      classNames: ["cell", "onlyBigScreen"],
    },
    {
      key: "createdAt",
      numeric: true,
      title: "NOTIFICATION_DATE",
      classNames: ["cell", "dateTimeCell", "onlyBigScreen", "textRight"],
    },
  ],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
    title: "SELECT_ALL",
  },
};

export const getField = () => {
  return { ...fields };
};
