import {
  AttachFile,
  OpenInNew,
  PictureAsPdf,
  Note,
  Category,
  OndemandVideo,
  MusicNote,
  Image,
  Archive,
  TextFields,
  CloudDownload,
  // DeviceUnknown,
} from "@material-ui/icons";

const IMAGE_FORMATS = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/bmp",
  "image/ico",
  "image/svg",
  "image/pjpeg",
];

export default (file, text = "") => {
  const { type } = file || {};

  if (!type) return "text";

  if (IMAGE_FORMATS.includes(type)) {
    return "image";
  }

  if (
    (type.includes("image") && !IMAGE_FORMATS.includes(type)) ||
    type === "image/vnd.djvu" ||
    type === "image/x-portable-anymap" ||
    type === "image/x-portable-pixmap" ||
    type === "image/bmp"
  ) {
    return "binary";
  }
  if (type.indexOf("audio") === 0) return "audio";

  if (type.includes("text/html")) return "html";

  switch (type) {
    case "application/pdf":
      return "pdf";
    case "video/mp4":
    case "video/webm":
    case "video/ogg":
      return "video";
    case "audio/mp3":
    case "audio/mpeg":
    case "audio/wav":
    case "audio/ogg":
    case "audio/x-flac":
    case "audio/basic":
    case "audio/x-ms-wma":
    case "audio/x-wav":
    case "audio/x-aiff":
    case "audio/x-aac":
      return "audio";
    case "application/octet-stream":
      return "binary";
    case "application/msword":
    case "officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "rtf":
    case "application/rtf":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "googleViewDoc";
    case "application/zip":
    case "application/x-zip-compressed":
      return "zip";
    case "text/html":
    case "html":
    case "text/xml":
    case "text/xhtml":
    case "text/XHTML":
    case "XHTML":
    case "xhtml":
    case "xml":
      return "html";
    default:
      return "unknown";
  }
};

export const getFormat = (type = "") => {
  if (!type) return "text";

  if (IMAGE_FORMATS.includes(type)) {
    return "image";
  }

  if (
    (type.includes("image") && !IMAGE_FORMATS.includes(type)) ||
    type === "image/vnd.djvu" ||
    type === "image/x-portable-anymap" ||
    type === "image/x-portable-pixmap" ||
    type === "image/bmp"
  ) {
    return "binary";
  }
  if (type.indexOf("audio") === 0) return "audio";

  if (type.includes("text/html")) return "html";

  switch (type) {
    case "application/pdf":
      return "pdf";
    case "video/mp4":
    case "video/webm":
    case "video/ogg":
      return "video";
    case "audio/mp3":
    case "audio/mpeg":
    case "audio/wav":
    case "audio/ogg":
    case "audio/x-flac":
    case "audio/basic":
    case "audio/x-ms-wma":
    case "audio/x-wav":
    case "audio/x-aiff":
    case "audio/x-aac":
      return "audio";
    case "application/octet-stream":
      return "binary";
    case "application/msword":
    case "officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "rtf":
    case "application/rtf":
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "googleViewDoc";
    case "application/zip":
    case "application/x-zip-compressed":
      return "zip";
    case "text/html":
    case "html":
    case "text/xml":
    case "text/xhtml":
    case "text/XHTML":
    case "XHTML":
    case "xhtml":
    case "xml":
      return "html";
    default:
      return "unknown";
  }
};

export const getFormatIcon = (type) => {
  const format = getFormat(type);
  switch (format) {
    case "text": {
      return <TextFields fontSize="large" />;
    }
    case "image": {
      return <Image fontSize="large" />;
    }
    case "html": {
      return <OpenInNew fontSize="large" />;
    }
    case "pdf": {
      return <PictureAsPdf fontSize="large" />;
    }
    case "video": {
      return <OndemandVideo fontSize="large" />;
    }
    case "audio": {
      return <MusicNote fontSize="large" />;
    }
    case "binary": {
      return <Category fontSize="large" />;
    }
    case "googleViewDoc": {
      return <Note fontSize="large" />;
    }
    case "zip": {
      return <Archive fontSize="large" />;
    }
    case "unknown": {
      return <CloudDownload fontSize="large" />;
    }
    default: {
      return <AttachFile fontSize="large" />;
    }
  }
};
