export default {
  pdfWrap: {
    minHeight: 200,
  },
  pdfPage: {
    display: "flex",
    justifyContent: "center",
  },
  pdfNavigationWrapper: {
    backgroundColor: "rgb(50, 50, 50)",
    color: "rgb(255, 255, 255)",
    height: 68,
    display: "flex",
    justifyContent: "center",
  },
  pdfDocument: {
    overflow: "auto",
    background: "#aaa",
    paddingTop: 10,
    position: "relative",
    minHeight: 150,
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      paddingBottom: 62,
      "& .mgrpdf-navigation": {
        position: "absolute",
        bottom: 0,
        left: 0,
        textAlign: "right",
        paddingLeft: 85,
        boxSizing: "border-box",
      },
      "& button": {
        padding: 12,
      },
    },

    "& canvas": {
      boxShadow: "2px 2px 12px rgba(0,0,0,0.5)",
    },
  },
  pdfDownload: {
    position: "absolute",
    bottom: 0,
    left: "10px",
  },
  pageLabel: {
    display: "inline-block",
    color: "#fff",
    margin: "20px 24px",
    fontSize: 16,
    minHeight: 24,
  },
  pageLabelWithoutButtons: {
    marginLeft: 96,
  },
  progress: {
    marginTop: "8px",
  },
};
