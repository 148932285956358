import React from "react";
import { withStyles, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import cx from "classnames";

import iconButtonStyle from "../../variables/styles/iconButtonStyle";

const IconCustomButton = ({
  disabled,
  classes,
  color,
  children,
  customClass,
  setId,
  ...rest
}) => (
  <IconButton
    disabled={!!disabled}
    {...rest}
    id={setId("button")}
    className={cx(
      classes.button,
      color && classes[color],
      customClass && customClass
    )}
  >
    {children}
  </IconButton>
);

IconCustomButton.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "simple",
  ]),
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

IconCustomButton.defaultProps = {
  children: "",
  customClass: "",
  color: "yellow",
  disabled: false,
  setId: setComponentsId("icon"),
};

export default withStyles(iconButtonStyle)(IconCustomButton);
