import getWizardProps from "../../../helpers/getWizardProps";
import { requestPaymentForm } from "../../../actions/payment";
import handleChange from "./handleChange";

export async function handleUpdatePaymentInfo(value) {
  return handleChange(value, false, true);
}

export async function handleRequestPaymentForm() {
  const {
    claim: { id },
  } = getWizardProps();
  return requestPaymentForm(id);
}
