import React, { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "../../helpers/translate";
import { withStyles } from "@material-ui/core";
import { RegularCard } from "../../components";
import ReturnButton from "../../components/ReturnButton";
import SchemaWizard, { SignData } from "../../components/SchemaWizard";
import {
  DocumentWizardProps,
  DocumentWizardState,
  DocumentStepData,
  styles,
} from "./types";
import {
  documentCommit,
  documentCreate,
  documentRequest,
  documentSetDraft,
  documentUpdateCourt,
  documentUpdateData,
  pdfGenerate,
  pdfRequest,
  signDocument,
  signRequestData,
  templateRequest,
} from "../../actions/document";
import { FormContext } from "../FormFromSchema";
import normalizeDocument from "./helpers/normalizeDocument";
import blobToBase64 from "../../helpers/blobToBase64";
import * as claimStatus from "../../variables/claimStatuses";
import DocumenPreview from "./components/DocumentPreview";
import components from "./forms";

class DocumentWizard extends React.Component<
  DocumentWizardProps,
  DocumentWizardState
> {
  constructor(props: DocumentWizardProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      const { documentId, templateId, isResendClaim } = this.props;
      let doc, document, pdf, template;

      if (documentId && !isResendClaim) {
        document = await documentRequest(documentId);
        if (document instanceof Error) throw document;
        document = normalizeDocument(document);

        template = await templateRequest(document.digitalDocumentTemplateId);
        if (template instanceof Error) throw template;

        if (document.state === claimStatus.SIGNING_STATUS) {
          await documentSetDraft(document.id);
        } else if (
          (document.state || claimStatus.CREATING_STATUS) >
          claimStatus.CREATING_STATUS
        ) {
          const res = await this.downloadPdf(document.id);
          doc = res.doc;
          pdf = res.pdf;
        }
      } else {
        if (!templateId)
          throw new Error("One of documentId or templateId must be defined");
        template = await templateRequest(templateId);
        if (template instanceof Error) throw template;
      }

      this.setState({ doc, document, pdf, template });
    } catch (error: any) {
      this.setState({
        error: error.message,
        // loading: false,
      });
    }
  }

  downloadPdf = async (
    id: string
  ): Promise<{
    doc: any | undefined;
    pdf: any | undefined;
  }> => {
    try {
      const doc = await pdfRequest(id);
      const pdf = await blobToBase64(doc);
      return {
        doc,
        pdf,
      };
    } catch (error) {
      return {
        doc: undefined,
        pdf: undefined,
      };
    }
  };

  handleBackToEdit = async () => {
    let { document } = this.state;

    document = await documentRequest(document?.id);
    if (document instanceof Error) throw document;
    document = normalizeDocument(document);

    this.setState({ document });
  };

  handleClickReturn = () => {
    const { history, returnTo, onReturn } = this.props;
    if (onReturn) onReturn();
    else if (returnTo) history.push(returnTo);
  };

  handleDocumentCreate = async () => {
    const {
      // isRepresentative,
      caseId,
      onDocumentCreated,
      courtId,
      owner,
      parentDocumentId,
      proceedingId,
      templateId,
    } = this.props;

    const createClaimData = Object.assign({}, owner);
    if (caseId) createClaimData.caseId = caseId;
    if (proceedingId) createClaimData.proceedingId = proceedingId;
    if (courtId) createClaimData.courtId = courtId;
    if (parentDocumentId) createClaimData.decisionDocumentId = parentDocumentId;

    let document = await documentCreate(templateId, createClaimData);
    if (document instanceof Error) throw document;
    await documentUpdateCourt(document.id, courtId);
    document = normalizeDocument(document);

    onDocumentCreated && (await onDocumentCreated(document));
    this.setState({ document });
  };

  handleDocumentLoad = async () => {
    const { onDocumentLoaded } = this.props;
    const { document } = this.state;
    if (!document) throw new Error("this.state.document undefined");
    onDocumentLoaded && (await onDocumentLoaded(document));
  };

  handleDocumentCommit = async (wizardData: any, signature?: any) => {
    const { courtId, onDocumentCommit } = this.props;
    let { document } = this.state;
    if (!document) throw new Error("this.state.document undefined");

    let resp = await signDocument(document.id, signature);
    if (resp instanceof Error) throw resp;

    resp = await documentCommit(document.id, { courtId });
    if (resp instanceof Error) throw resp;

    document = normalizeDocument(resp);
    onDocumentCommit && (await onDocumentCommit(document));
    this.setState({ document });
  };

  handleDocumentFinish = async (wizardData: any | any[]) => {
    const { document } = this.state;
    try {
      if (!document) throw new Error("this.state.document undefined");
      if (!Array.isArray(wizardData.attaches)) {
        wizardData.attaches = [];
      }
      document.digitalDocumentData = wizardData;
      const doc = await pdfGenerate(document.id, {
        documentData: wizardData,
      });
      if (doc instanceof Error) throw doc;
      const pdf = await blobToBase64(doc);
      this.setState({
        pdf,
        doc,
      });
    } catch (error: any) {
      this.setState({
        error: error.message,
        pdf: null,
        doc: null,
      });
    }
  };

  handleError = (error: string) => {
    this.setState({ error });
  };

  handleErrorSign = async () => {
    const { document } = this.state;
    document && (await documentSetDraft(document.id));
  };

  handleGetDataToSign = async (
    data: any,
    getDataFn?: (data: any) => any
  ): Promise<SignData | any[]> => {
    const { document } = this.state;
    if (!document) throw new Error("this.state.document undefined");
    const resp = await signRequestData(document.id);
    if (resp instanceof Error) throw resp;
    return resp;
  };

  handleFormContext = async (context: FormContext): Promise<FormContext> => {
    context.document = this.state.document;
    return context;
  };

  handleStepCheck = async (stepName: string, values: any, errors?: any) => {
    for (const prop in values) {
      if (typeof values[prop] === "string") values[prop] = values[prop].trim();
    }
  };

  handleStepSave = async (
    stepName: string,
    stepData: any | any[],
    activeStep: string
  ) => {
    if (!stepData || !this.state.document) return;

    const { id, digitalDocumentData } = this.state.document;

    if (digitalDocumentData) digitalDocumentData.activeStep = activeStep;
    const data: DocumentStepData = {
      activeStep,
    };
    if (stepName === "attaches" && !Array.isArray(stepData)) stepData = [];
    data[stepName] = stepData;

    let document = await documentUpdateData(id, data);
    if (document instanceof Error) throw document;
    document = normalizeDocument(document);
    this.setState({ document });
  };

  render(): React.ReactNode {
    const {
      context,
      dictionary,
      finishButtonText,
      onClose,
      permissionId,
      returnTo,
      returnToText,
      setId,
      t,
      userInfo,
    } = this.props;
    const { template, document, error, pdf, doc } = this.state;

    const showWizard = !!template;
    return (
      <RegularCard
        cardTitle={
          <div id={setId("title")}>
            <ReturnButton onClick={onClose} t={t} />
            <span>{template?.jsonSchema.title}</span>
            {/* <VideoManual /> */}
          </div>
        }
        headerColor="primary"
        setId={setId}
        content={
          <Fragment>
            {showWizard && (
              <SchemaWizard
                context={{
                  ...context,
                  components,
                  dictionary,
                  permissionId,
                  user: userInfo,
                }}
                errors={error}
                finishButtonText={finishButtonText}
                onBackToEdit={this.handleBackToEdit}
                onDataCreate={this.handleDocumentCreate}
                onDataCommit={this.handleDocumentCommit}
                onDataFinish={this.handleDocumentFinish}
                onDataLoad={this.handleDocumentLoad}
                onError={this.handleError}
                onErrorCommit={this.handleErrorSign}
                onErrorSign={this.handleErrorSign}
                onFormContext={this.handleFormContext}
                onGetDataToSign={this.handleGetDataToSign}
                onStepCheck={this.handleStepCheck}
                onStepSave={this.handleStepSave}
                onStepUpdate={this.handleStepSave}
                // onClose={ }
                returnTo={returnTo}
                returnToText={returnToText}
                // setId={ }
                schema={template.jsonSchema}
                signPreview={
                  document && (
                    <DocumenPreview
                      pdf={pdf}
                      doc={doc}
                      document={document}
                      setId={setId}
                    />
                  )
                }
                user={userInfo}
                wizardData={document?.digitalDocumentData}
              />
            )}
          </Fragment>
        }
      ></RegularCard>
    );
  }
}

function mapStateToProps({
  authorization: { info },
  dictionary,
  permissions,
}: any) {
  return {
    dictionary,
    permissions,
    userInfo: info,
  };
}

export default translate("DocumentWizard")(
  compose(
    connect(mapStateToProps),
    withRouter,
    // ReturnAndLocateInTableHOC,
    withStyles(styles as any)
  )(DocumentWizard)
);
