import claimListStyles from "../../../variables/styles/claimList";

export const styles = {
  ...claimListStyles,
  statusImage: {
    marginTop: 10,
    marginLeft: 10,
  },
  typeFilter: {
    maxWidth: 300,
  },
  setIsReadButton: {
    marginRight: 4,
  },
};
