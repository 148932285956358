export default async function (
  {
    userFileName,
    propsName,
    fileName,
    description,
    scanDocumentName,
    contentType,
  },
  blob
) {
  if (!blob) return;
  let name =
    userFileName || propsName || fileName || description || scanDocumentName;
  const nameArr = name.split(".");
  const nameType = nameArr[nameArr.length - 1];
  let typeArr = fileName.split(".");
  let type = contentType;
  if (!type) {
    type = typeArr[typeArr.length - 1];
  }
  if (!type) {
    typeArr = blob.type.split("/");
    type = typeArr[typeArr.length - 1].replace("jpeg", "jpg");
  }
  const needChangeType = !nameType || nameType !== type;
  if (needChangeType) name = type ? `${name}.${type}` : `document.${name}`;
  const { document } = window;
  const URL = window.URL || window.webkitURL;

  const element = document.createElement("a");
  element.setAttribute("href", URL.createObjectURL(blob));
  element.setAttribute("download", name);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
