import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { translate } from "react-translate";
import {
  withStyles,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputAdornment,
  TextField,
  IconButton,
  Icon,
} from "@material-ui/core";

import StringElement from "../../../components/SchemaForm/elements/StringElement";
import { Button } from "../../../components";

import customInputStyle from "../../../variables/styles/customInputStyle";

import {
  // getAuthMode,
  sendEmailCode,
  verifyEmailCode,
  checkEmail,
} from "../../../actions/user";

const mailInspection =
  /^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$/;

class EmailInput extends React.Component {
  state = {
    showModal: false,
    codeSended: false,
    email: "",
    code: "",
    error: "",
  };
  // UNSAFE_componentWillMount = getAuthMode;

  getProps() {
    const { t, onChange, propValue, isLegal } = this.props;
    const { edrpou } = this.props.auth.info;
    const { code, email } = this.state;

    return {
      t,
      onChange,
      propValue,
      isLegal: !!isLegal,
      edrpou: isLegal ? edrpou : undefined,
      code,
      email,
    };
  }

  toggleModal = () =>
    this.setState({
      showModal: !this.state.showModal,
      codeSended: false,
      error: "",
      code: "",
    });

  onChangeCode = ({ target: { value } }) =>
    this.setState({
      code: value,
      error: this.state.code.length > 0 ? "" : this.state.error,
    });

  onChangeEmail = (value) => {
    const { t, value: propValue, isLegal, edrpou } = this.getProps();

    const test = mailInspection.test(value);
    let error = test || !value ? "" : t("EMAIL_ERROR");
    if (!error && value) {
      if (propValue === value) {
        error = t("NOT_CHANGE_EMAIL_ERROR");
        this.setState({
          email: value,
          error,
        });
      } else {
        this.setState(
          {
            email: value,
            error,
          },
          () =>
            checkEmail(value, isLegal, edrpou).then(({ isExist }) => {
              if (isExist && !this.state.error) {
                error = t("DUPLICATE_EMAIL_ERROR");
              }
              this.setState({
                error: error || this.state.error,
              });
            })
        );
      }
    } else {
      this.setState({
        email: value,
        error,
      });
    }
    return error;
  };

  sendEmailCode = () => {
    const { email, isLegal, edrpou } = this.getProps();
    const error = this.onChangeEmail(email);
    this.setState(
      { codeSended: true },
      () => !error && sendEmailCode(email, isLegal, edrpou)
    );
  };

  moreSendEmail = () => {
    const { email, isLegal, edrpou } = this.getProps();

    this.state.error
      ? this.setState({
          codeSended: false,
          error: "",
        })
      : sendEmailCode(email, isLegal, edrpou);
  };

  verifyEmailCode = () => {
    const { t, onChange, code, email, isLegal, edrpou } = this.getProps();

    verifyEmailCode(email, code, isLegal, edrpou).then(({ isAccepted }) => {
      if (!isAccepted) {
        this.setState({ error: t("VALIDATION_FALSE") });
      } else {
        this.setState({
          code: "",
          codeSended: false,
          email: email,
        });
        onChange({
          target: {
            name: "email",
            value: email,
          },
        });
        if (isLegal) {
          onChange({
            target: {
              name: "emailIsValid",
              value: true,
            },
          });
        } else {
          onChange({
            target: {
              name: "valid",
              value: { email: true, phone: false },
            },
          });
        }
        this.toggleModal();
      }
    });
  };

  render() {
    const { showModal, codeSended, error, code, email } = this.state;
    const { t, value, classes, disabled } = this.props;
    const disabledButton = !!error || (codeSended ? !code : !email);
    const setId = (elementName) => this.props.setId(`email-${elementName}`);

    return (
      <Fragment>
        <TextField
          id={setId("input")}
          disabled={true}
          name="email"
          label={t("EMAIL_INPUT_LABEL")}
          value={value || ""}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.toggleModal}
                  disabled={disabled}
                  id={setId("edit-button")}
                >
                  <Icon>edit</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Dialog
          open={showModal}
          onClose={this.toggleModal}
          aria-labelledby={setId("dialog-content")}
          aria-describedby={setId("dialog-text")}
          id={setId("dialog")}
          className={classes.dialog}
        >
          <DialogTitle
            id={setId("dialog-title")}
            className={classes.dialogContentWrappers}
          >
            {t("EMAIL_DIALOG_TITLE")}
          </DialogTitle>
          <DialogContent
            id={setId("dialog-content")}
            className={classes.dialogContentWrappers}
          >
            <DialogContentText id={setId("dialog-text")}>
              {codeSended ? t("TEXT_WAIT_FOR_CODE") : t("EMAIL_DIALOG_TEXT")}
            </DialogContentText>
            <FormControl
              fullWidth={true}
              className={classes.formControl}
              margin="dense"
              id={setId("dialog-input-wrap")}
            >
              {codeSended && (
                <TextField
                  placeholder={t("CODE_INPUT_LABEL")}
                  value={code}
                  helperText={error}
                  error={!!error}
                  onChange={this.onChangeCode}
                  id={setId("dialog-code-input")}
                />
              )}
              {!codeSended && (
                <StringElement
                  placeholder={t("EMAIL_INPUT_LABEL")}
                  value={email}
                  errors={error}
                  error={!!error}
                  onChange={this.onChangeEmail}
                  mask={mailInspection}
                  maxLength={100}
                  id={setId("dialog-email-input")}
                />
              )}
            </FormControl>
            <Button
              onClick={codeSended ? this.verifyEmailCode : this.sendEmailCode}
              color="yellow"
              disabled={disabledButton}
              autoFocus={true}
              id={setId("dialog-send-button")}
              setId={(elmentName) => setId(`dialog-send-${elmentName}`)}
            >
              {codeSended ? t("VERIFY_CODE") : t("SEND_CODE")}
            </Button>
            {codeSended && (
              <div id={setId("dialog-more-send-wrap")}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#0059aa",
                  }}
                  onClick={this.moreSendEmail}
                  id={setId("dialog-more-send")}
                >
                  {t("RESEND_CODE")}
                </span>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

EmailInput.propTypes = {
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isLegal: PropTypes.bool,
};

EmailInput.defaultProps = {
  value: "",
};

const styled = withStyles(customInputStyle)(EmailInput);
const translated = translate("UserProfile")(styled);

function mapStateToProps(state) {
  return { auth: state.authorization };
}

// decorate and export
export default connect(mapStateToProps)(translated);
