import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Route, Redirect } from "react-router-dom";
import { BlockScreen } from "../../components";
// import storage from "../../helpers/storage";
import { appRoutes } from "../../containers/Index/routes";

import { isLoggedIn } from "../../actions/auth";

import GoogleTagManager from "./GoogleTagManager";

export const PrivateRoute = ({
  component: Component,
  auth,
  accessRoles,
  location,
  role,
  ...rest
}) => {
  let isSigned = isLoggedIn();
  if (isSigned && !auth.info) {
    return <BlockScreen open={true} transparentBackground={true} />;
  }
  // в local storage ролі немає, треба брати з redux
  // let role = storage.getItem("role");
  // console.log("role: ", role);
  // console.log("accessRoles: ", accessRoles);

  if (auth.info) {
    // if (!role || role === "null") {
    //   role = "individual";
    //   setRole(role);
    // }
    if (!accessRoles) {
      return (
        <Redirect
          to={{
            pathname: "/notifications",
            state: { from: location },
          }}
        />
      );
    }
    const courtIdUserScopes = auth.info.courtIdUserScopes || [];

    if (
      Array.isArray(accessRoles) &&
      role &&
      !accessRoles.find((item) => item === role)
    ) {
      const foundRoute = appRoutes.find(
        (item) =>
          item.accessRoles &&
          item.accessRoles.includes(role) &&
          !!(item.accessRoles || []).find((roleName) =>
            courtIdUserScopes.includes(roleName)
          )
      );
      return (
        <Redirect
          to={{
            pathname: (foundRoute || {}).path || "/notifications",
            state: { from: location },
          }}
        />
      );
    }
    isSigned = Array.isArray(accessRoles)
      ? !!accessRoles.filter((item) => courtIdUserScopes.includes(item)).length
      : false;
  }

  /* if (!isLoggedIn()){
        storage.setItem('backToURL', location.pathname);
    } */

  return (
    <Route
      {...rest}
      render={(props) => (
        <GoogleTagManager>
          {isSigned ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )}
        </GoogleTagManager>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  auth: PropTypes.object,
  accessRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  location: PropTypes.object.isRequired,
};

PrivateRoute.defaultProps = {
  auth: {},
  accessRoles: [],
};

function mapStateToProps(state) {
  return { auth: state.authorization, role: state.authorization.role };
}

export default connect(mapStateToProps)(PrivateRoute);
