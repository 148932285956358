import React from "react";
import PropTypes from "prop-types";

const TemplateNotFound = ({ classes }) => (
  <div className={classes.noTemplateWrap}>
    <div className={classes.noTemplate}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
      >
        <path d="M362.087,164.017H149.915c-6.62,0-11.988,5.367-11.988,11.988c0,6.621,5.367,11.988,11.988,11.988h212.172 c6.621,0,11.988-5.368,11.988-11.988C374.075,169.384,368.708,164.017,362.087,164.017z" />
        <path d="M362.087,236.355H149.915c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.367,11.988,11.988,11.988h212.172 c6.621,0,11.988-5.368,11.988-11.988S368.708,236.355,362.087,236.355z" />
        <path
          d="M447.937,103.439c-0.082-3.008-1.276-5.944-3.489-8.158l-91.69-91.69c-2.176-2.214-5.2-3.592-8.55-3.592H89.285
                    c-13.92,0-25.247,11.325-25.247,25.248l0.005,299.353c0,0.106,0.013,0.207,0.016,0.313c0.164,6.065,3.303,11.562,8.484,14.779
                    l41.352,25.667c13.649,8.469,30.605,8.469,44.252-0.001l28.381-17.614c5.849-3.631,13.116-3.63,18.965-0.001l28.381,17.615
                    c6.824,4.237,14.474,6.355,22.126,6.354c7.651,0,15.304-2.118,22.126-6.354l28.381-17.615c5.849-3.629,13.116-3.629,18.965,0
                    l28.381,17.615c13.645,8.472,30.6,8.472,44.252,0l41.355-25.667c5.179-3.215,8.317-8.713,8.482-14.777
                    c0.002-0.104,0.016-0.207,0.016-0.313l0.005-220.845C447.964,103.64,447.946,103.543,447.937,103.439z M356.193,40.935h0.001
                    l50.834,50.834h-49.573c-0.695,0-1.262-0.567-1.262-1.262V40.935z M423.983,321.08l-38.52,23.908
                    c-5.849,3.631-13.116,3.63-18.965,0.001l-28.381-17.616c-13.649-8.469-30.605-8.468-44.252,0.001l-28.381,17.615
                    c-5.849,3.629-13.116,3.629-18.965,0l-28.381-17.615c-13.646-8.472-30.602-8.472-44.252,0l-28.381,17.615
                    c-5.849,3.629-13.116,3.629-18.965,0l-38.52-23.909L88.014,25.248c0-0.701,0.571-1.272,1.271-1.272h242.933v66.532
                    c0,13.916,11.321,25.238,25.238,25.238h66.532L423.983,321.08z"
        />
        <path
          d="M447.958,395.948c0.001-0.031-0.004-0.061-0.004-0.092s0.005-0.061,0.005-0.092c0-6.521-3.541-12.544-9.242-15.714
                    c-5.7-3.168-12.682-3.003-18.223,0.436l-35.44,21.994c-5.598,3.473-12.554,3.473-18.15,0.001l-29.197-18.121
                    c-13.397-8.317-30.042-8.317-43.437,0l-29.197,18.12c-5.598,3.473-12.553,3.473-18.151,0.001l-29.197-18.121
                    c-13.396-8.315-30.042-8.315-43.437,0l-29.195,18.12c-5.598,3.473-12.554,3.474-18.15,0l-35.439-21.994
                    c-5.541-3.438-12.523-3.608-18.224-0.436c-5.7,3.171-9.242,9.192-9.242,15.714c0,0.031,0.005,0.061,0.005,0.092
                    c0,0.031-0.005,0.061-0.005,0.092l-0.005,90.804c0,13.922,11.325,25.248,25.247,25.248h333.434
                    c13.92,0,25.247-11.325,25.247-25.249L447.958,395.948z M422.717,488.024H89.285c-0.7,0-1.271-0.571-1.271-1.271l0.004-80.215
                    l26.285,16.314c13.398,8.312,30.042,8.312,43.436-0.001l29.195-18.121c5.597-3.474,12.551-3.473,18.151,0l29.195,18.122
                    c6.699,4.156,14.209,6.235,21.719,6.235c7.509,0,15.02-2.079,21.719-6.236l29.195-18.121c5.597-3.474,12.551-3.475,18.151,0
                    l29.197,18.122c13.4,8.314,30.042,8.312,43.436,0l26.285-16.314l0.004,80.214C423.988,487.453,423.418,488.024,422.717,488.024z"
        />
      </svg>
    </div>
  </div>
);

TemplateNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default TemplateNotFound;
