export enum ClaimState {
  Rejected = 8,
}

export interface IAllowBy {
  address: string;
  companyName: string;
  edrpou: string;
  email: string;
  ipn: string;
  name: string;
  phone: string;
  isLegal: boolean;
}

export interface IOrderSelectClaiment {
  claimant: boolean;
  representativeClaimant: boolean;
  claimentUserId: IAllowBy | string;
}

// OrderSelectClaiment ==> Example:
// (such as object "allowedBy" from "meta""" field)
// {
//   "claimant": true,
//   "representativeClaimant": true,
//   "claimentUserId": {
//     "address": "Київ",
//     "companyName": "",
//     "edrpou": "",
//     "email": "oleg.rynda@gmail.com",
//     "ipn": "0101010101",
//     "name": "Тест Фізособович",
//     "phone": "380111111111",
//     "isLegal": false
//   }
// }

export interface IOrderMeta {
  caseNumber: string;
  contractDate: string;
  contractNumber: string;
  issueredByAddress: string;
  issueredByName: string;
  issueredByType: number;
  orderDate: string;
  orderNumber: string;
  allowedBy: IAllowBy | null;
}

// OrderMetaObject ==> Example:
// (such as object "allowedBy" from "meta""" field)
// {
//   "caseNumber": "",
//   "contractDate": "2021-03-15",
//   "contractNumber": "123456",
//   "issueredByAddress": "",
//   "issueredByName": "",
//   "issueredByType": 0,
//   "orderDate": "2021-03-15",
//   "orderNumber": "AA1234567",
//   "allowedBy": {
//     "address": "Київ",
//     "companyName": "",
//     "edrpou": "",
//     "email": "oleg.rynda@gmail.com",
//     "ipn": "0101010101",
//     "name": "Тест Фізособович",
//     "phone": "380111111111",
//     "isLegal": false
//   }
// }

export interface IOwner {
  addClaimPermission: boolean;
  allowCommit: number;
  allowEdit: number;
  allowRead: number;
  allowShare: number;

  allowedBy: IAllowBy | null | any;
  allowedByName: string | null | any;
  cancelDate: null | any;

  chainOfResharings: any[];
  childCount: number;
  children: any[];

  createdAt: string;
  createdBy: string;
  createdByName: string;
  documentId: string;
  entityName: string;
  id: string;

  isActive: boolean;
  isAlloweder: boolean;
  isOwner: boolean;

  meta: string | object | any;

  parent: { children: any[]; childCount: number };
  parentId: null | any;
  reSharePermission: boolean;
  shareRoles: null | any;

  targetId: string;
  targetKey: null | any;
  targetType: number;
  typeId: number;

  updatedAt: string;
  updatedBy: string;
  userId: string;
  userName: string;

  ["key"]?: any;
}
