import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const StatusIdFilterChip = ({
  t,
  value,
  list,
  filter = "statusId",
  ...rest
}) => (
  <Chip
    label={`${
      filter === "vpStateId" ? t("VP_STATE_ID_STATUS") : t("DOCUMENT_STATUS")
    }: ${
      (
        (list || []).find(
          (item) => item.value === value || item.id === value
        ) || {}
      ).name || ""
    }`}
    {...rest}
  />
);

StatusIdFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

StatusIdFilterChip.defaultProps = {
  value: "",
  list: [],
};

export default translate("ClaimList")(StatusIdFilterChip);
