import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { formatUserName } from "../../../helpers/userName";

const getName = ({ name, companyName }) => companyName || formatUserName(name);

const sortMembersForRoles = (members, roles) => {
  return (roles || []).reduce((acc, role) => {
    const foundMember = (members || []).find(
      ({ roleId }) => roleId === role.id
    );
    if (foundMember) {
      acc[role.name] = {
        ...role,
        users: (members || []).filter(({ roleId }) => roleId === role.id),
        // .map((item) => getName(item)) старое получение имен из объектов массива мемберс в pop-up Інформації по Справі
        // .filter((name, index, self) => self.indexOf(name) === index), собирает все полученные имена из объектов в массив users
      };
    }
    return acc;
  }, {});
};

class RolesBlock extends Component {
  state = { open: false };

  toggleMembersList = () => this.setState({ open: !this.state.open });

  renderOpenLink = () => {
    const { classes, t } = this.props;
    const { open } = this.state;
    return (
      <p className={classes.openRoles} onClick={this.toggleMembersList}>
        {t(open ? "CLOSE_ROLES" : "OPEN_ROLES")}
      </p>
    );
  };

  renderUser(user, index) {
    const { classes, title, t } = this.props;
    const icon =
      title !== "COURT_COMPOSE" && user?.isRegistered ? (
        <CheckIcon titleAccess={t("IS_USER")} />
      ) : null;

    return (
      <div className={classes.isUserIcon}>
        {icon}
        {getName(user) + (index >= 0 ? ";" : " ")}&nbsp;
      </div>
    );
  }

  render() {
    const { members, roles, setId, title, t, classes, name } = this.props;
    const membersForRoles = sortMembersForRoles(members, roles);
    const { open } = this.state;

    return (
      <TableRow id={setId(`dialog-table-row-${name}`)}>
        <TableCell
          id={setId(`dialog-table-row-${name}-cell-1`)}
          className={classes.verticalAlignTop}
        >
          <p>{t(title)}</p>
        </TableCell>
        <TableCell id={setId(`dialog-table-row-${name}-cell-2`)}>
          {Object.entries(membersForRoles).map(
            ([key, { description, users }]) => {
              // убираем объекты с одинаковым name|companyName из массива users
              const table = {};
              const uniqueUsers = users.filter(
                ({ name, companyName }) =>
                  !table[name || companyName] &&
                  (table[name || companyName] = 1)
              );
              const needToggle = uniqueUsers.length > 10;
              return (
                <div key={key} className={classes.rolesWrap}>
                  <p>{description}:&nbsp;</p>
                  <p className={classes.isUserIcon}>
                    {(!needToggle || open) &&
                      uniqueUsers.map((user, index) => {
                        return this.renderUser(user, index);
                      })}
                    {!open && needToggle && (
                      <div>
                        {t("CLOSED_ROLES_LIST", {
                          uniqueUsers: (
                            <div className={classes.isUserIcon}>
                              <div className={classes.isUserIcon}>
                                {uniqueUsers[0]?.isRegistered ? (
                                  <div>
                                    <CheckIcon titleAccess={t("IS_USER")} />
                                  </div>
                                ) : null}
                                {uniqueUsers[0].name ||
                                  uniqueUsers[0].companyName}
                                ,&nbsp;
                                {uniqueUsers[1]?.isRegistered ? (
                                  <div>
                                    <CheckIcon titleAccess={t("IS_USER")} />
                                  </div>
                                ) : null}
                                {uniqueUsers[1].name ||
                                  uniqueUsers[1].companyName}
                              </div>
                            </div>
                          ),
                          total: uniqueUsers.length - 2,
                        })}
                      </div>
                    )}
                  </p>
                  {needToggle && this.renderOpenLink()}
                </div>
              );
            }
          )}
        </TableCell>
      </TableRow>
    );
  }
}

RolesBlock.propTypes = {
  members: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  setId: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default RolesBlock;
