import React from "react";
import { connect } from "react-redux";
import { translate } from "react-translate";
import PropTypes from "prop-types";

import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  TextField,
  FormLabel,
  Toolbar,
} from "@material-ui/core";

import { Remove, ExpandMore } from "@material-ui/icons";

import FilterCleaner from "../../../components/FilterInput/FilterCleaner";
import Select from "../../../components/Select";
import MultiLevelsSelect from "../../../components/MultiLevelsSelect";
import DatePickerForm from "../../../components/DatePickerForm";
import FilterForm from "../../../components/FilterInput/FilterForm";

import { requestDictionary } from "../../../actions/search";

import searchStyle from "../../../variables/styles/searchStyle";

const panels = ["court", "claim", "adjudication"];

const fieldsNeedCount = ["judgment_code"];

const getOptions = (list, options, name) => {
  const needCount = !!fieldsNeedCount.find((item) => item === name);
  if (!needCount) return options || [];
  const counts = (((list || {}).meta || {}).aggregations || {})[name];
  return (options || []).map((item) => {
    if (!Array.isArray(counts)) return item;
    const found = counts.find(
      ({ key: itemKey }) => `${itemKey}` === `${item.id}`
    );
    const { doc_count: count } = found || {};
    return {
      ...item,
      name: `${item.name}(${typeof count === "number" ? count : "0"})`,
    };
  });
};

class FilterFields extends React.Component {
  state = {
    expanded: {
      claim: true,
      court: true,
      adjudication: true,
      categories: true,
    },
    instances: [2, 3, 4],
  };

  UNSAFE_componentWillMount() {
    const { courts, regions, judgmentForms, justiceKinds } = this.props;

    if (!courts || !regions || !judgmentForms || !justiceKinds) {
      requestDictionary();
    }
  }

  handleToggleInstance = (instance) => () => {
    const { instances } = this.state;

    if (instances.includes(instance)) {
      instances.splice(instances.indexOf(instance), 1);
    } else {
      instances.push(instance);
    }
    this.setState({ instances });
  };

  handleChangeExpanded = (panelName) => () =>
    this.setState({
      expanded: {
        ...this.state.expanded,
        [panelName]: !this.state.expanded[panelName],
      },
    });

  filterCourt = ({ id, courtTypeId, regionId }) => {
    const { dataSource } = this.props;
    if (!id) {
      return false;
    }

    const instances = dataSource.filters.instance_code || [];
    const regions = dataSource.filters.region_code || [];

    return (
      (!instances.length || !courtTypeId || instances.includes(courtTypeId)) &&
      (!regions.length || !regionId || regions.includes(regionId))
    );
  };

  getCauseCat = () =>
    (this.props.causeCategories || []).map((category) => ({
      id: category.csc_id,
      name: category.name,
      parentId: category.csc_csc_id,
    }));

  getFields = () => {
    const { t, regions, courts, justiceKinds, judgmentForms } = this.props;
    return {
      court: [
        {
          name: "instance_code",
          select: true,
          multi: true,
          label: "INSTANCE",
          array: [2, 3, 4].map((item) => ({
            id: item,
            name: t("INSTANCE" + item),
          })),
        },
        {
          name: "region_code",
          select: true,
          multi: true,
          label: "REGIONS",
          array: (regions || []).filter(({ id }) => id !== 1),
        },
        {
          name: "court_code",
          select: true,
          multi: true,
          label: "COURT_NAME",
          array: (courts || []).filter(this.filterCourt),
        },
        { name: "judge", label: "JUDGE" },
      ],
      claim: [
        {
          name: "justice_kind",
          select: true,
          multi: true,
          label: "JUSTICE_KIND",
          array: justiceKinds,
        },
        { name: "justicBr", isBr: true },
        {
          name: "cause_category",
          select: true,
          multilevel: true,
          label: "CATEGORIES_FILTER_GROUP",
          array: this.getCauseCat(),
        },
        { name: "case_num", label: "CASE_NUMBER", margin: "dense" },
      ],
      adjudication: [
        { name: "doc_id", label: "CASE_NUM", margin: "dense" },
        {
          name: "judgment_code",
          select: true,
          multi: true,
          label: "JUDGEMENT_FORMS",
          array: judgmentForms,
        },
        {
          name: "adjudication_date_label",
          formLabel: true,
          label: "ADJUDICATION",
        },
        {
          name: "adjudication_date",
          names: ["adjudication_date_start", "adjudication_date_end"],
          dateRange: true,
        },
        {
          name: "receipt_date_label",
          formLabel: true,
          label: "PUBLICATION",
        },
        {
          name: "receipt_date",
          names: ["receipt_date_start", "receipt_date_end"],
          dateRange: true,
        },
      ],
    };
  };

  renderField = ({
    name,
    formLabel,
    select,
    multi,
    label,
    array,
    multilevel,
    margin,
    dateRange,
    isBr,
    names,
  }) => {
    const { dataSource, t, classes, list, setId } = this.props;
    const options = select ? getOptions(list, array || [], name) : "";
    if (isBr) return <br />;
    if (formLabel) {
      return (
        <FormLabel
          className={classes.legend}
          component="legend"
          id={setId(`label-${name}`)}
        >
          {t(label)}
        </FormLabel>
      );
    }
    return (
      <FilterCleaner
        dataSource={dataSource}
        name={names || name}
        id={setId(`filter-${name}`)}
      >
        {select && !multilevel && !dateRange && (
          <Select
            multi={multi}
            name={name}
            label={t(label)}
            options={options}
          />
        )}
        {!select && !multilevel && !dateRange && (
          <TextField
            name={name}
            label={t(label)}
            margin={margin || "none"}
            fullWidth={true}
          />
        )}
        {select && multilevel && !dateRange && (
          <MultiLevelsSelect name={name} label={t(label)} options={options} />
        )}
        {!select && !multilevel && dateRange && (
          <Toolbar className={classes.dateRange}>
            <DatePickerForm
              margin="none"
              name={names[0]}
              label={t("DATE_START")}
            />
            <Remove className={classes.dateRangeDivider} />
            <DatePickerForm
              margin="none"
              name={names[1]}
              label={t("DATE_END")}
            />
          </Toolbar>
        )}
      </FilterCleaner>
    );
  };

  render() {
    const { t, classes, setId, load, dataSource } = this.props;

    const { expanded } = this.state;

    return (
      <FilterForm
        setId={setId}
        dataSource={dataSource}
        load={load}
        autoload={true}
      >
        {panels.map((name) => {
          const label = `${name.toUpperCase()}_FILTER_GROUP`;
          const fields = this.getFields()[name];
          const variant = name === "claim" ? "outlined" : undefined;
          return (
            <ExpansionPanel
              classes={{
                root: classes.optionsContainer,
                expanded: classes.expanded,
              }}
              expanded={expanded[name]}
              onChange={this.handleChangeExpanded(name)}
              key={`${name}-panel`}
            >
              <ExpansionPanelSummary
                classes={{
                  root: classes.panelSummary,
                  content: classes.panelSummaryContent,
                }}
                expandIcon={<ExpandMore className={classes.panelSummary} />}
              >
                {t(label)}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panelDetails}>
                <FormControl
                  fullWidth={true}
                  className={classes.formControl}
                  id={setId(`${name}-form`)}
                  variant={variant}
                >
                  {fields.map((item) => this.renderField(item))}
                </FormControl>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </FilterForm>
    );
  }
}

FilterFields.propTypes = {
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  regions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  judgmentForms: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  justiceKinds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  causeCategories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataSource: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  load: PropTypes.func,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

FilterFields.defaultProps = {
  courts: null,
  regions: null,
  judgmentForms: null,
  justiceKinds: null,
  causeCategories: null,
  load: () => null,
  list: [],
};

const mapStateToProps = ({
  search: {
    courts,
    regions,
    judgmentForms,
    justiceKinds,
    causeCategories,
    list,
  },
}) => ({
  courts,
  regions,
  judgmentForms,
  justiceKinds,
  causeCategories,
  list,
});

const translated = translate("SearchPage")(FilterFields);
const styled = withStyles(searchStyle)(translated);
export default connect(mapStateToProps)(styled);
