import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components";
import { appDialogSelector } from "../../selectors/appDialog";
import customInputStyle from "../../variables/styles/customInputStyle";
import { closeAppDialog } from "../../actions/appDialog";
import { ButtonType } from "../../reducers/appDialogTypes";

const ApplicationDialog = ({ classes }: any) => {
  const { open, title, text, buttons, resolveDialog } =
    useSelector(appDialogSelector);

  const dispatch = useDispatch();

  const handleClick = (btn: ButtonType) => () => {
    resolveDialog && resolveDialog(btn);
    dispatch(closeAppDialog());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClick(ButtonType.CANCEL)}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogContentWrappers}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.dialogContentWrappers}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogContentWrappers}>
        {buttons.map(({ text, color, value }) => {
          return (
            <Button key={text} onClick={handleClick(value)} color={color}>
              {text}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(customInputStyle)(ApplicationDialog);
