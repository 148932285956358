import PropTypes from "prop-types";

import { Button } from "../../../components";
import Toolbar from "@material-ui/core/Toolbar";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import cx from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import CreateButton from "./CreateButton";
import CreateOrderButton from "./CreateOrderButton";
import { PermissionFilterStatus } from "../../../reducers/permissionTypes";
import CreateEDRRecipeButton from "./CreateEDRRecipeButton";

const getPermissionStatus = (filters) => {
  if (filters.isActive == null) {
    return PermissionFilterStatus.ALL;
  }

  return filters.isActive
    ? PermissionFilterStatus.ACTIVE
    : PermissionFilterStatus.CANCELED;
};

const ShareToolbar = ({
  t,
  classes,
  checked,
  toggleDialog,
  setId,
  orderButtonVisible,
  onAddOrderClick,
  handleChangeFilter,
  dataSource,
  auth,
  addEDRRecipeButtonVisible,
  changedShare,
}) => {
  const permissionStatus = getPermissionStatus(dataSource.filters);
  return (
    <Toolbar
      className={classes.toolbar}
      disableGutters={true}
      id={setId("toolbar")}
    >
      <CreateButton
        toggleDialog={toggleDialog}
        setId={setId}
        classes={classes}
        t={t}
        auth={auth}
      />
      {orderButtonVisible && (
        <CreateOrderButton
          onClick={onAddOrderClick}
          setId={setId}
          classes={classes}
          t={t}
        />
      )}
      {addEDRRecipeButtonVisible && (
        <CreateEDRRecipeButton
          toggleDialog={toggleDialog}
          classes={classes}
          t={t}
          setId={setId}
          disabled={!checked?.length || changedShare?.typeId !== 2}
        />
      )}
      <Button
        color="transparent"
        onClick={
          changedShare?.typeId !== 2
            ? toggleDialog("delete")
            : toggleDialog("deleteEDRRecipe")
        }
        id={setId("toolbar-delete-button")}
        setId={(elmentName) => setId(`toolbar-delete-${elmentName}`)}
        className={classes.mobileButtonFullWidth}
        disabled={!checked.length}
      >
        <Icon>delete</Icon>
        {`${t("CANCEL_PERMISSION")}${
          checked.length > 0 ? ` (${checked.length})` : ""
        }`}
      </Button>
      {/* <Button
        color="transparent"
        onClick={toggleDialog("deleteEDRRecipe")}
        id={setId("toolbar-deleteEDRRecipe-button")}
        setId={(elmentName) => setId(`toolbar-deleteEDRRecipe-${elmentName}`)}
        className={classes.mobileButtonFullWidth}
        disabled={!checked.length}
      >
        <Icon>delete</Icon>
        {`${t("CANCEL_PERMISSION_EDR")} `}
      </Button> */}
      <TextField
        key={setId("permissionStatus-filter")}
        select
        value={permissionStatus}
        name="permissionStatus"
        onChange={({ target: { value } }) =>
          handleChangeFilter("permissionStatus", value)
        }
        margin="dense"
        id={setId("permissionStatus-filter")}
        className={cx(classes.margin, classes.textField, classes.flex)}
        SelectProps={{
          id: setId("select-permissionStatus-filter"),
          SelectDisplayProps: {
            id: setId("select-permissionStatus-filter-wrapper"),
          },
        }}
      >
        <MenuItem value={PermissionFilterStatus.ACTIVE}>
          {t("ACTIVE_PERMISSIONS")}
        </MenuItem>
        <MenuItem value={PermissionFilterStatus.CANCELED}>
          {t("CANCELED_PERMISSIONS")}
        </MenuItem>
        <MenuItem value={PermissionFilterStatus.ALL}>
          {t("ALL_PERMISSIONS")}
        </MenuItem>
      </TextField>
    </Toolbar>
  );
};

ShareToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  checked: PropTypes.array.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  onAddOrderClick: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  orderButtonVisible: PropTypes.bool.isRequired,
  dataSource: PropTypes.object.isRequired,
  auth: PropTypes.object,
};

export default ShareToolbar;
