const rules = [
  {
    test: (filterName, filterValue) => {
      return (
        [
          "adjudication_date_start",
          "adjudication_date_end",
          "receipt_date_start",
          "receipt_date_end",
        ].includes(filterName) && filterValue
      );
    },
    execute: function () {
      const { dataSource } = this.props;
      const {
        filters: {
          adjudication_date_start,
          adjudication_date_end,
          receipt_date_start,
          receipt_date_end,
        },
      } = dataSource;

      const adjudicationDate = [
        adjudication_date_start,
        adjudication_date_end,
      ].filter(Boolean);
      const publicationDate = [receipt_date_start, receipt_date_end].filter(
        Boolean
      );

      if (adjudicationDate.length === 1) {
        const filter = adjudicationDate.shift();
        dataSource.setFilter("adjudication_date_start", filter, false);
        dataSource.setFilter("adjudication_date_end", filter, false);
      }

      if (publicationDate.length === 1) {
        const filter = publicationDate.shift();
        dataSource.setFilter("receipt_date_start", filter, false);
        dataSource.setFilter("receipt_date_end", filter, false);
      }
    },
  },
  {
    test: (filterName, filterValue) =>
      ["region_code", "instance_code"].includes(filterName) && filterValue,
    execute: function (filterName, filterValue) {
      const { dataSource, courts } = this.props;
      const {
        filters: { court_code: courtCode },
      } = dataSource;

      if (!courtCode) {
        return;
      }

      let courtCodes = (courtCode || []).map((code) =>
        courts.find(({ id }) => id === code)
      );

      switch (filterName) {
        case "region_code":
          courtCodes = courtCodes.filter(({ regionId }) =>
            filterValue.includes(regionId)
          );
          break;
        case "instance_code":
          courtCodes = courtCodes.filter(({ courtTypeId }) =>
            filterValue.includes(courtTypeId)
          );
          break;
        default:
          break;
      }

      dataSource.setFilter(
        "court_code",
        courtCodes.map(({ id }) => id)
      );
    },
  },
];

export default function (filterName, filterValue) {
  rules.forEach((rule) => {
    if (rule.test(filterName, filterValue)) {
      rule.execute.bind(this)(filterName, filterValue);
    }
  });
}
