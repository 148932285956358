export default {
  FULL_SCREEN: "відкрити сторінку з документом",
  RESEND_CLAIM: "Надіслати заяву повторно",
  ACCESS_TO_VIDEO: "Отримати доступ до файлу технічної фіксації",
  SAVE_TO_DEVICE: "Зберегти файл та електронний підпис архівом",
  CHECK_ECP: "Перевірка КЕП",
  DOCUMENT_HISTORY_FROM_COURT: "Картка руху судового документа",
  DOCUMENT_HISTORY_FROM_USER: "Картка руху документа користувача ЕС",
  DOCUMENT_HISTORY: "Картка руху документу",
  СLAIM_HISTORY_CART: "Картка руху заяви",
  NAME: "Назва:",
  COURT: "Суд:",
  CASE_NUMBER_CARD: "Номер справи:",
  PROCEEDINGS_NUMBER: "Номер провадження:",
  STATE: "Стан:",
  DATE_OF_STATUS_CHANGE: "Дата зміни стану:",
  DATE_OF_SIGNING_THE_KEP: "Дата підписання КЕП:",
  DATE_SENT_TO_THE_SIDES: "Дата надсилання сторонам:",
  RECEIVED_SIDES: "Сторони, яким надіслано:",
  DATE_SENT_TO_COURT: "Дата надсилання до суду:",
  DATE_OF_DELIVERY_TO_COURT: "Дата доставки до суду:",
  DATE_OF_REGISTRATION_IN_COURT: "Дата реєстрації в суді:",
  PREVIEW_LINK: "Відкрити у новому вікні",
  PRINT: "Друк",
  LEVEL: "{{level}}-й рівень",
  SELECT_REGION: "Виберіть регіон",
  SELECT_REGION_HINT: "Підказка",
  SELECT_COURT_TYPE: "Виберіть інстанцію",
  SELECT_COURT_TYPE_HINT: "Підказка",
  SELECT_COURT: "Виберіть суд",
  SELECT_COURT_HINT: "Підказка",
  USER_EMAIL: "Електронна пошта",
  USER_EMAIL_SAMPLE:
    'Електронна адреса особи, якій надається правова допомога (має бути в форматі "test@example.com")',
  IPN: "Реєстраційний номер облікової картки платника податків (раніше ІПН)",
  IPN_SAMPLE:
    "РНОКПП особи, якій надається правова допомога (у разі його відсутності, серія/номер паспорту або номер ID-карти)",
  CASE_NUMBER: "Номер справи",
  COMPANY_NAME: "Назва юр.особи",
  COMPANY_NAME_SAMPLE: " Найменування особи, якій надається правова допомога",
  EDRPOU: "Код ЄДРПОУ",
  EDRPOU_SAMPLE: "Код ЄДРПОУ особи, якій надається правова допомога",
  CONTRACT_NUMBER: "Номер договору/доручення на надання правової допомоги",
  CONTRACT_NUMBER_SAMPLE:
    "Номер договору/доручення на надання правової допомоги",
  TO_PROTEST: "Оскаржити",
  PAY: "Сплатити",
  PAY_TITLE_FOR_BTN:
    "Сплатити судовий збір для отримання доступу до файлів технічної фіксації",
  PAYMENT_ERROR: "Сталась помилка, спробуйте пізніше",
  TEXT_FOR_PAYMENT: `Для отримання доступу та можливості збереження файлів технічної фіксації (відеозапис) судового засідання з КЕП 
    необхідно сплатити судовий збір. 
    Одразу після сплати судового збору файл буде доступний для перегляду та збереження в додатках до протоколу.`,
  CONTRACT_DATE: "Дата договору/доручення на надання правової допомоги",
  CONTRACT_DATE_SAMPLE: "Дата договору/доручення на надання правової допомоги",
  ORDER_NUMBER: "Серія/номер ордеру",
  ORDER_NUMBER_SAMPLE:
    "Серія та номер ордеру на надання правничої (правової) допомоги",
  ORDER_DATE: "Дата видачі ордеру",
  ORDER_DATE_SAMPLE:
    "Дата видачі ордеру на надання правничої (правової) допомоги",
  TARGET_ORGAN: "Орган в якому надається допомога",
  GIVEN_BY: "Виданий",
  BY_ADVOCATE: "Адвокатом",
  BY_ADVOCATES_OFFICE: `Адвокатським об'єднанням/бюро`,
  ISSUERED_BY_NAME: "Найменування об'єднання/бюро",
  ISSUERED_BY_NAME_HINT:
    "Повне найменування адвокатського об'єднання/бюро, що видає ордер",
  ISSUERED_ADDRESS: "Місце знаходження об'єднання/бюро",
  ISSUERED_ADDRESS_HINT:
    "Місце знаходження адвокатського об'єднання/бюро, що видає ордер",
  CASE: "Справа",
  WORK_ADDRESS:
    "Адреса робочого місця адвоката (якщо відрізняється від адреси в профілі)",
  WORK_ADDRESS_SAMPLE:
    "Введіть область, район, місто (село, селище), вул (бульв. провул, узвіз тощо), буд, кв, поштовий індекс",
  UN_LEGAL: "Фізична особа",
  LEGAL: "Юридична особа",
  SIGN_AND_SAVE: "Підписати та зберегти",
  CREATING_NEW_ORDER: "Створення нового ордеру",
  CREATING_NEW_ORDER_ATTENTION_MSG:
    'Увага! Після створення ордеру на справу, системою автоматично буде відправлено до суду "Повідомлення про надання адвокату доступу до справи"',
  VIEW_ORDER: "Перегляд ордеру",
  USER_NAME: "Прізвище, ім'я та по батькові",
  USER_NAME_SAMPLE:
    "Прізвище, ім'я, по батькові особи, якій надається правова допомога",
  USER_ADDRESS: "Адреса проживання",
  ANY_ADDRESS: "Адреса проживання/перебування/місцезнаходження",
  ANY_ADDRESS_SAMPLE:
    "Область, район, місто (село, селище), вул (бульв. провул, узвіз тощо), буд, кв, поштовий індекс особи, якій надається правова допомога",
  USER_PHONE: "Контактний номер телефону",
  USER_PHONE_SAMPLE:
    "Контактний номер телефону особи, якій надається правова допомога",
  USER_IPN: "Індивідуальний податковий номер",
  REQUIRED_PARAM: "Обов'язковий параметр",
  SOME_TEXT: "Довільний текстовий елемент",
  SOME_TEXT_HINT: "Довільний текстовий елемент",
  PAYMENT: "Судовий збір",
  PAYMENT_PAYED_TEXT: "Сплачено",
  PAYMENT_NEED_PAY_TEXT: "Судовий збір стягується згідно ...",
  PAY_BUTTON: "Сплатити",
  FILE_NAME: "Назва файлу",
  FILE_SIZE: "Розмір",
  UPLOAD: "Завантажити додатки до заяви",
  UPLOAD_FILE: "Завантажити додаток",
  UPLOAD_FILES: "Завантажити додатки",
  UPLOAD_FILE_DOC: "Завантажити документ",
  UPLOAD_FILE_PAYMENT: "Завантажити документ",
  DROP_FILES: "Перетягнути або",
  SELECT_FILES: "Вибрати файл",
  SAMPLE_EXPAND: "Приклад",
  SAVE: "Зберегти",
  FILE_TITLE: "Назва додатка",
  FILE_TITLE_DOC: "Назва документу",
  FILE_TITLE_PAYMENT: "Назва документу",
  FILE_TITLE_HINT: 'наприклад, "Копія свідоцтва"',
  FILE_TITLE_HINT_DESCRIPTION: "Ця назва буде відображатись у заяві",
  FILE_TITLE_HINT_DOC: 'наприклад, "Копія паспорта"',
  FILE_TITLE_HINT_PAYMENT: 'наприклад, "Копія квитанції"',
  VERIFIED: "У заяві додаток буде відображатись як {{name}}",
  VERIFY: "Підтвердити",
  ERROR_TYPE_FILE: "Не коректний тип файлу",
  ERROR_SIZE_FILE: "Завеликий файл (не більше {{maxSize}} Мb)",
  ERROR_FILE_NAME_LENGTH: 'Назва файлу має бути не більше 250 символів"',
  ERROR_EMPTY_FILE_NAME:
    'Назва файлу не може бути не строкою або порожньою строкою"',
  ERROR_FILE_NAME: 'Некоректна назва файлу "{{fileName}}"',
  ERROR_ALLOWED_FILE_SYMBOLS:
    'Некоректна назва файлу "{{fileName}}". В назві файлу можна використовувати наступні символи: літери, цифри, -, _, \', +, (, ), =, ;, ^, $, !, ,.',
  ERROR_FILE_TITLE:
    "В назві файлу можна використовувати наступні символи: літери, цифри, -, _, ', +, (, ), =, ;, ^, $, !. Назва файлу має містити коректний тип файлу після точки, а також бути не більше 250 символів.",
  ERROR_ATTACH_NAME_LENGTH: "Назва додатка має бути не більше 250 символів",
  ERROR_ATTACH_NAME: "Некоректна назва додатка",
  ERROR_ATTACH_TITLE:
    "В назві додатка можна використовувати наступні символи: літери, цифри, -, _, ', +, (, ), =, ;, ^, $, !, ., а також бути не більше 250 символів.",
  ERROR_UPLOADING_FILE: "Виникла помилка при завантаженні файлу",
  SHOW_SAMPLE_DIALOG: "Ознайомитись із прикладом",
  CLOSE: "Закрити",
  NON_MATERIAL: "Позов не містить вимоги матеріального характеру",
  MIN_SELECTED_ERROR: "Необхідно відзначити мінімум {{count}}",
  MAX_SELECTED_ERROR: "Необхідно відзначити максимум {{count}}",
  ALL_PAGE_ERROR: "Перевірте чи відзначені всі необхідні поля",
  OPEN_EDITOR: "Відкрити редактор",
  EMPTY_LINKS: "Файли-додатки не прикріплені.",
  EMPTY_FILE:
    "Документ в процесі завантаження. Спробуйте, будь ласка, пізніше.",
  USE_SEARCH: "Cкористайтеся пошуком",
  REDIRECT_TO_LIST: "Перейти до списку заяв",
  REDIRECT_TO_EXECUTIVE: "Перейти до списку виконавчих документів",
  REDIRECT_TO_C_EXECUTIVE: "Перейти до виконавчого документа",
  REDIRECT_TO_CASE: "Перейти до провадження",
  REDIRECT_TO_POLICE: "Перейти до списку клопотань",
  REDIRECT_TO_INPUTDOCS: "Перейти до списку документів сторін",
  TITLE_503: "Сервіс тимчасово недоступний",
  SUBTITLE_503:
    "В зв’язку з необхідністю проведення планових технічних робіт доступ до Кабінету електронного суду тимчасово обмежений. Приносимо вибачення за незручності. Спробуйте, будь ласка, пізніше",
  TEXT_503: "Код помилки: 503",
  NOT_FOUND: "Нічого не знайдено",
  DOCUMENT_TITLE: "Електронний суд",
  COPY_ALL: "Скопіювати текст прикладу",
  COPY_SELECTED: "Скопіювати виділене",
  SELECT_EXECUTIVE: "Виберіть виконавця",
  USER_EXISTS_WITH_EDRPOU:
    "Користувач з таким ЄДРПОУ зареєстрований в кабінеті, зверніться до нього для видачі довіреності",
  USER_EXISTS_WITH_IPN:
    "Користувач з таким РНОКПП зареєстрований в кабінеті, зверніться до нього для видачі довіреності",
  DOCUMENT_STATE: "Стан:",
  DOCUMENT_CREATED: "Дата створення:",
  DOCUMENT_CREATING_DATE_OR_DELIVERY: "Дата доставки до електронного суду:", // Дата створення (доставки в кабінет)
  DOCUMENT_EDITING_DATE: "Дата останнього коригування:", // Дата останнього коригування (оновлення)
  SIGN_DATE: "Дата підпису:",
  SEND_TO_COURT_DATE: "Дата відправки до суду:",
  DELIVERY_TO_COURT_DATE: "Дата доставки в суд:",
  REG_IN_COURT_DATE: "Дата документу:", // Дата реєстрації в суді
  DNZS_DATE2: "Дата набрання законної сили (ДНЗС):",
};
