import PropTypes from "prop-types";
import { compose } from "redux";
import { formatMoney } from "../helpers/formatMoney";

import { fields } from "../data/executive.docs.table.fields";
import statuses from "../../../variables/executiveStatuses";
import StatusChip from "../decorators/index";
import redirectes from "../../../helpers/documentRedirected";
import humanDateFormat, {
  humanDateTimeFormat,
} from "../../../helpers/humanDateFormat";

import { Table } from "../../../components";
import { ReturnAndLocateInTableHOC } from "../../../components/ReturnAndLocateInTableHOC";

import { getRoleSimpleStr } from "../data/getRoleSimpleStr";
import { getRoleForArrStr } from "../data/getRoleForArrStr";

const getCourt = (item, courts) => {
  let { courtId } = item;
  let courtName = "";
  if (!courtId) {
    const { digitalDocumentData } = item;
    const data =
      digitalDocumentData && digitalDocumentData[0] === "{"
        ? JSON.parse(digitalDocumentData)
        : {};
    courtId = (data.court || {}).court;
  }
  if (courtId && courts) {
    const foundCourt = courts.find(({ id }) => id === courtId);
    if (foundCourt) {
      courtName = foundCourt.name;
    }
  }
  return courtName || "";
};

const ExecutiveDocsTable = ({
  classes,
  setId,
  t,
  changeCount,
  checked,
  dataSource,
  handleClick,
  list,
  onSelectAllClick,
  onCheckboxClick,
  createSortHandler,
  pagination,
  courts,
  history,
  returnObject,
  meta,
  total,
}) => {
  const getText = (item, key) => {
    const {
      // createdAt,
      // docDate,
      // updatedAt,
      // forceDate,
      execDocTypeName,
      execMyRole,
      enforcementName,
      debtorName,
      penaltyTypeName,
      collectorName,
      statusId,
      statusName,
      // isDeleted,
    } = item;

    switch (key) {
      case "execDocTypeName":
        return execDocTypeName;
      case "enforcementName":
        return enforcementName;
      case "penaltyTypeName":
        return penaltyTypeName;
      case "debtorName":
        return (
          <div title={collectorName + "/" + debtorName}>
            {" "}
            {collectorName} <br /> {debtorName}
          </div>
        );
      // case "court":
      case "courtId":
        return getCourt(item, courts);
      case "courtTitle":
        return `${t("COURT_TITLE")} :`;
      case "createdAt":
      case "updatedAt":
        return item[key] ? humanDateTimeFormat(item[key]) : "";
      case "docDate":
        return item[key] ? humanDateFormat(item[key]) : "";
      case "dateForce":
        return item[key] ? humanDateFormat(item[key]) : "";
      case "execMyRole":
        const array = execMyRole?.split(",");
        if (!array?.length || !array?.[0]?.length) {
          return "";
        } else if (array?.length === 1) {
          return getRoleSimpleStr(execMyRole, t);
        } else {
          return getRoleForArrStr(array, t);
        }
      case "statusId":
        return statusId ? (
          <StatusChip
            t={t}
            setId={setId}
            status={statusId}
            statusName={statusName}
            tableChip={true}
            catStatuses={statuses}
            style={{ margin: "0" }}
          />
        ) : (
          ""
        );
      case "statusInfo": {
        return JSON.parse(item[key])?.result?.fault?.message || "";
      }
      case "paymentInfoSumm":
        return formatMoney(item[key]);
      case "goToProcessing":
        if (!(item && item.caseId && item.proceedingId)) return null;
        return (
          <div
            className={classes.link}
            title={t("REDIRECT_TO_CASE")}
            onClick={(event) => {
              event.stopPropagation();
              sessionStorage.setItem("executivedocs_documentId", item.id);
              returnObject.setReturnObject({
                form: "executivedocs",
                id: item.id,
                filters: dataSource.filters,
                sort: dataSource.sort,
                search: dataSource.search,
                path: window.location.pathname + window.location.search,
              });
              history.push(redirectes[6].getUrl(item));
            }}
          >
            {item.caseNumber || "б/н"} <br />
            {item.procNumber || "б/н"}
          </div>
        );
      default:
        return item[key];
    }
  };

  return (
    <Table
      fields={fields}
      getText={getText}
      setId={setId}
      createSortHandler={createSortHandler}
      dataSource={dataSource}
      onCheckItem={handleClick}
      list={list}
      total={total}
      needFullData={true}
      pagination={pagination}
      changeCount={changeCount}
      t={t}
      onSelectAllClick={onSelectAllClick}
      onCheckboxClick={onCheckboxClick}
      checked={checked}
    />
  );
};

ExecutiveDocsTable.propTypes = {
  changeCount: PropTypes.func.isRequired,
  checked: PropTypes.array,
  classes: PropTypes.object.isRequired,
  dataSource: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  pagination: PropTypes.func.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ExecutiveDocsTable.defaultProps = {
  courts: null,
};

export default compose(
  //withRouter,
  ReturnAndLocateInTableHOC
)(ExecutiveDocsTable);
