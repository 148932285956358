import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { ListItem, ListItemIcon, ListItemText, Chip } from "@material-ui/core";

import Mustache from "mustache";

const getCount = (classes, rest, { showingList, showingListFilter }) => {
  if (!rest[showingList] || !rest[showingList].length) {
    return null;
  }
  const list = rest[showingList];
  let count = list.length;
  if (list.meta && "unread" in list.meta) {
    count = list.meta.unread;
  } else if (showingListFilter) {
    count = list.filter(showingListFilter).length;
  }
  if (count === 0) {
    return null;
  }

  const label = count > 99 ? "99+" : count;
  return <Chip label={label} color="secondary" className={classes.chip} />;
};

const SidebarNavLink = ({
  classes,
  t,
  location: { pathname },
  color,
  handleDrawerToggle,
  state,
  prop,
  ...rest
}) => {
  const activeRoute = (routeName) => {
    return pathname.split("/")[1] === routeName.split("/")[1];
  };

  if (prop.redirect) return null;

  const listItemClasses = cx({
    [" " + classes[color]]: activeRoute(prop.path),
  });

  const isActive = activeRoute(prop.path);

  const whiteFontClasses =
    " " +
    cx({
      [classes.whiteFont]: isActive,
      [classes.smallFont]: t(prop.title).length > 20,
    });

  if (prop.external) {
    const children = (
      <ListItem
        button={true}
        className={classes.itemLink + listItemClasses}
        id={prop.setId("sidebar-link-button")}
      >
        <ListItemIcon
          className={classes.itemIcon + whiteFontClasses}
          id={prop.setId("sidebar-link-icon")}
        >
          <prop.icon />
        </ListItemIcon>
        <ListItemText
          primary={t(prop.title)}
          className={classes.itemText + whiteFontClasses}
          disableTypography={true}
          id={prop.setId("sidebar-link-text")}
        />
      </ListItem>
    );
    if (prop.handler) {
      return (
        <span
          key={prop.setId("sidebar-link")}
          onMouseDown={prop.handler(state)}
          className={classes.item}
          id={prop.setId("sidebar-link")}
        >
          {children}
        </span>
      );
    }
    return (
      <a
        key={prop.setId("sidebar-link")}
        href={Mustache.render(prop.path, state)}
        target={"_blank"}
        rel="noopener noreferrer"
        className={classes.item}
        id={prop.setId("sidebar-link")}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      to={prop.path}
      onClick={handleDrawerToggle}
      className={classes.item}
      activeClassName="active"
      key={prop.setId("sidebar-link")}
      id={prop.setId("sidebar-link")}
    >
      <ListItem
        button={true}
        className={classes.itemLink + listItemClasses}
        id={prop.setId("sidebar-link-button")}
        title={
          prop.path === "/executive-decisions"
            ? "Документи виконавчого провадження"
            : ""
        }
      >
        {prop.icon && (
          <ListItemIcon
            className={classes.itemIcon + whiteFontClasses}
            id={prop.setId("sidebar-link-icon")}
          >
            <prop.icon isactive={isActive ? 1 : 0} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <span>
              {t(prop.title)}
              {getCount(classes, rest, prop)}
            </span>
          }
          className={classes.itemText + whiteFontClasses}
          disableTypography={true}
          id={prop.setId("sidebar-link-text")}
        />
      </ListItem>
    </NavLink>
  );
};

SidebarNavLink.propTypes = {
  classes: PropTypes.object.isRequired,
  prop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  color: PropTypes.string,
  location: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

SidebarNavLink.defaultProps = {
  color: "yellow",
};

export default SidebarNavLink;
