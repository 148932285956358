export default {
  claimTitleTextWrapper: {
    flex: 1,
  },
  claimStatus: {
    display: "flex",
    alignItems: "center",

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      paddingRight: "24px",
      position: "relative",
    },
  },
};
