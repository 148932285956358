import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";

class Checkboxes extends Component {
  state = { checked: this.props.checked, updated: false };

  componentDidMount() {
    const { checked, onChange, itemKey } = this.props;
    onChange(itemKey, checked);
  }

  UNSAFE_componentWillReceiveProps({ checked }) {
    const { updated } = this.state;
    if (
      checked !== this.props.checked &&
      checked !== this.state.checked &&
      !updated
    ) {
      this.onCheck({ target: { checked } });
    }
  }

  onCheck = ({ target: { checked } }) =>
    this.setState({ checked, updated: true }, () =>
      this.props.onChange(this.props.itemKey, checked)
    );

  render() {
    const { itemKey, classes, setId, index, checkboxLabel } = this.props;
    const { checked } = this.state;

    return (
      <FormControlLabel
        id={setId(`form-control-${index}-checkbox-wrap`)}
        control={
          <Checkbox
            checked={checked}
            onClick={this.onCheck}
            color="primary"
            classes={{ checked: classes.checked }}
            id={setId(`form-control-${itemKey}-checkbox`)}
          />
        }
        label={checkboxLabel}
      />
    );
  }
}

Checkboxes.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Checkboxes;
