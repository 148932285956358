import React from "react";
import { Table } from "../../../../components";
import { docsFields } from "./docsFields";
import {
  documentProcTableGetText,
  DocumentProcTableProps,
} from "./DocumentProcTable";
import { useCourtName } from "./useCourtName";

const fields = {
  ...docsFields,
  tableFields: [
    ...docsFields.tableFields,
    {
      key: "courtName",
      sort: true,
      title: "CASE_COURT",
      classNames: ["cell"],
      grid: [1, 7, 4, 5],
    },
  ],
};

interface DocumentCaseTableProps extends DocumentProcTableProps {
  templates: Record<string, any>[];
}

const DocumentCaseTable = ({
  t,
  list,
  dataSource,
  createSortHandler,
  checked = "",
  onCellClick,
  setId,
  pagination,
  changeCount,
  templates,
}: DocumentCaseTableProps) => {
  const getCourtName = useCourtName(t);

  const documentCaseTableGetText = (item: Record<string, any>, key: string) => {
    switch (key) {
      case "courtName": {
        const template = templates.find(
          ({ id }) => id === item.digitalDocumentTemplateId
        );
        return (
          getCourtName(item.courtId, template && template.causeActionType) ||
          t("NUMBER")
        );
      }
      default:
        return documentProcTableGetText(t, item, key);
    }
  };

  return (
    <Table
      fields={fields}
      checked={checked}
      getText={documentCaseTableGetText}
      setId={setId}
      dataSource={dataSource}
      onCheckItem={onCellClick}
      createSortHandler={createSortHandler}
      pagination={pagination}
      changeCount={changeCount}
      list={list}
      t={t}
      labelRowsPerPage="COUNT_CASES"
      labelDisplayedRows="DISPLAYED_CASES"
    />
  );
};

export default DocumentCaseTable;
