import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
} from "@material-ui/core";

import { Button } from "../../../components";

import StringElement from "../../../components/SchemaForm/elements/StringElement";

import {
  sendSMSCode,
  checkPhoneExists,
  verifySMSCode,
} from "../../../actions/user";

import promiseChain from "../../../helpers/promiseChain";

import customInputStyle from "../../../variables/styles/customInputStyle";

class PhoneEditModal extends React.Component {
  state = { codeSended: false, phone: "", code: "", error: null };

  onChangeCode = ({ target }) =>
    this.setState({ code: target.value, error: null });
  onChangePhone = (value) => this.setState({ phone: value, error: null });

  onClose = () => {
    const { onClose } = this.props;
    onClose();
    this.setState({ codeSended: false, phone: "", code: "", error: null });
  };

  sendSMSCode = () => {
    const { t } = this.props;
    const { phone } = this.state;
    return (
      phone &&
      promiseChain([
        () => checkPhoneExists(phone),
        ({ isExist }) =>
          isExist
            ? Promise.reject(t("PHONE_ALREADY_EXISTS"))
            : Promise.resolve(),
        () => this.setState({ codeSended: true, error: null }),
        () => sendSMSCode(phone),
      ]).catch((error) => this.setState({ error }))
    );
  };

  updateProfilePhone = async () => {
    const { onChange } = this.props;
    const { phone } = this.state;

    try {
      // const { isConfirmed } = await verifySMSCode(phone);
      // if (!isConfirmed) {
      //   throw new Error(t("VALIDATION_FALSE"));
      // }
      onChange(phone);
      this.onClose();
    } catch (error) {
      this.setState({ error });
    }
  };

  verifySMSCode = () => {
    const { onChange, t } = this.props;
    const { code, phone } = this.state;
    if (!code) {
      return this.setState({ error: t("VALIDATION_FALSE") });
    }

    return promiseChain([
      () => verifySMSCode(phone, code),
      ({ isConfirmed }) => {
        if (!isConfirmed) {
          throw new Error(t("VALIDATION_FALSE"));
        }
        onChange(phone);
        this.onClose();
      },
    ]).catch((error) => this.setState({ error }));
  };

  renderContent() {
    const { t, classes } = this.props;
    const { code, phone, error, codeSended } = this.state;
    const setId = (elementName) =>
      this.props.setId(`${codeSended ? "code-" : ""}${elementName}`);
    if (codeSended) {
      return (
        <Fragment>
          <DialogContentText id={setId("wait-text")}>
            {t("TEXT_WAIT_FOR_CODE")}
          </DialogContentText>

          <FormControl
            fullWidth={true}
            className={classes.formControl}
            margin="dense"
            id={setId("wrap")}
          >
            <TextField
              placeholder={t("CODE_INPUT_LABEL")}
              value={code}
              helperText={error && error.message}
              error={!!error}
              onChange={this.onChangeCode}
              id={setId("input")}
            />
          </FormControl>
          <Button
            onClick={this.verifySMSCode}
            color="yellow"
            disabled={!!error || !code}
            autoFocus={true}
            id={setId("verify-button")}
            setId={(elmentName) => setId(`verify-button-${elmentName}`)}
          >
            {t("VERIFY_CODE")}
          </Button>
          <div id={setId("resend-link-wrap")}>
            <span
              style={{ cursor: "pointer", color: "#0059aa" }}
              onClick={this.sendSMSCode}
              id={setId("resend-link")}
            >
              {t("RESEND_CODE")}
            </span>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <DialogContentText id={setId("text")}>{t("TEXT")}</DialogContentText>
        <FormControl
          fullWidth={true}
          className={classes.formControl}
          margin="dense"
          id={setId("input-wrap")}
        >
          <StringElement
            label={t("PHONE_INPUT_LABEL")}
            value={phone}
            errors={error}
            onChange={this.onChangePhone}
            mask="380999999999"
            t
            id={setId("input")}
          />
        </FormControl>
        <Button
          onClick={this.updateProfilePhone}
          color="yellow"
          disabled={!!error || !phone || phone.length < 12}
          autoFocus={true}
          id={setId("send-button")}
          setId={(elmentName) => setId(`send-${elmentName}`)}
        >
          {t("VERIFY_CODE")}
        </Button>
      </Fragment>
    );
  }

  render() {
    const { t, open, setId, classes } = this.props;

    return (
      <Dialog
        id={setId("")}
        open={open}
        onClose={this.onClose}
        aria-labelledby={setId("title")}
        aria-describedby={setId("content")}
        className={classes.dialog}
      >
        <DialogTitle
          id={setId("title")}
          className={classes.dialogContentWrappers}
        >
          {t("TITLE")}
        </DialogTitle>
        <DialogContent
          id={setId("content")}
          className={classes.dialogContentWrappers}
        >
          {this.renderContent()}
        </DialogContent>
      </Dialog>
    );
  }
}

PhoneEditModal.propTypes = {
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const translated = translate("PhoneEditModal")(PhoneEditModal);

export default withStyles(customInputStyle)(translated);
