import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "../../variables/styles/emptyPage";

const EmptyPage = ({ title, description, classes, children }) => (
  <div className={classes.wrap}>
    <Typography className={classes.title} variant="h4" gutterBottom={true}>
      {title}
    </Typography>
    <Typography
      className={classes.subtitle}
      variant="subtitle2"
      gutterBottom={true}
    >
      {description}
    </Typography>
    {children}
  </div>
);

EmptyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
};

EmptyPage.defaultProps = {
  children: <div />,
};

export default withStyles(styles)(EmptyPage);
