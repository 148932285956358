export default {
  CONFIRM_DELETE_ALL: "Так",
  CANCEL: "Відміна",
  HEADER: "Повідомлення",
  HEADER2: "Системні повідомлення",
  NOTIFICATION_TITLE: "Заголовок",
  SELECT_ALL: "Вибрати все",
  NOTIFICATION_EVENT: "Розділ",
  NOTIFICATION_DATE: "Дата",
  FULL_NAME: " від {{createdAt}}",
  NOTE_VIEWED: "Показувати",
  SHOW_ALL: "Усі повідомлення ({{total}})",
  SHOW_NOT_VIEWED: "Непрочитані повідомлення ({{unread}})",
  SHOW_VIEWED: "Прочитані повідомлення ({{read}})",
  NOTIFICATIONS_EMPTY_PAGE_TITLE: "Повідомлень немає",
  NOT_FOUND: "Повідомлення було видалено",
  DISPLAYED_CASES: "Відображати:",
  COUNT_CASES: "з {{from}} по {{to}} із {{total}}",
  TOTAL: "Всього: {{total}}",
  SET_IS_READ: "Як прочитані",
  SET_IS_NOT_READ: "Як не прочитані",
  DELETE: "Видалити",
  DELETE_ALL: "ВИДАЛИТИ ВСЕ",
  NOTIFICATIONS_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись важливі повідомлення про зміни статусів заяв, відкриття справ, отримання довіреностей тощо",
};
