import { Fragment } from "react";
import PropTypes from "prop-types";
import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";
import setComponentsId from "../../../helpers/setComponentsId";
import { Button } from "../../../components";

import { translate } from "react-translate";

const CheckCabinetResult = ({
  t,
  result,
  onClickNewRequest,
  code,
  onClickDownload,
}) => {
  const isLegalCode = !!code.match(/^\d{8}$/g);
  return (
    <Fragment>
      {result.isFound === 1 && result.isLegal === 0 && (
        <div style={{ margin: "10px" }}>
          <div style={{ padding: "5px" }}>{t("INDIVIDUAL")}</div>
          <table>
            <tbody>
              <tr>
                <td>{t("RNOKPP")}</td>
                <td>
                  <b>{result.code}</b>
                </td>
              </tr>
              <tr>
                <td>{t("NAME")}</td>
                <td>
                  <b>{result.name}</b>
                </td>
              </tr>
              <tr>
                <td>{t("REGISTRATION_DATE")}</td>
                <td>
                  <b>{humanDateTimeFormat(result.linkedAt)}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ padding: "5px" }}>{t("IS_FOUND")}</div>
        </div>
      )}
      {result.isFound === 1 && result.isLegal === 1 && (
        <div style={{ margin: "10px" }}>
          <div style={{ padding: "5px" }}>{t("LEGAL_ENTITY")}</div>
          <table>
            <tbody>
              <tr>
                <td>{t("EDRPOU")}</td>
                <td>
                  <b>{result.code}</b>
                </td>
              </tr>
              <tr>
                <td>{t("COMPANY_NAME")}</td>
                <td>
                  <b>{result.name}</b>
                </td>
              </tr>
              <tr>
                <td>{t("REGISTRATION_DATE")}</td>
                <td>
                  <b>{humanDateTimeFormat(result.linkedAt)}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ padding: "5px" }}>{t("IS_FOUND")}</div>
        </div>
      )}
      {result.isFound === 0 && isLegalCode && (
        <div style={{ margin: "20px" }}>
          <div style={{ padding: "5px" }}>{t("LEGAL_ENTITY")}</div>
          <table>
            <tbody>
              <tr>
                <td>{t("EDRPOU")}</td>
                <td>
                  <b>{result.code || code}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ padding: "5px" }}>{t("IS_NOT_FOUND")}</div>
        </div>
      )}
      {result.isFound === 0 && !isLegalCode && (
        <div style={{ margin: "20px" }}>
          <div style={{ padding: "5px" }}>{t("INDIVIDUAL")}</div>
          <table>
            <tbody>
              <tr>
                <td>{t("RNOKPP")}</td>
                <td>
                  <b>{result.code || code}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ padding: "5px" }}>{t("IS_NOT_FOUND")}</div>
        </div>
      )}
      <Button
        color="yellow"
        onClick={onClickDownload}
        setId={setComponentsId("check-cabinet-response-btn")}
      >
        {t("RESPONS_WITH_KEP")}
      </Button>
      <Button
        style={{ margin: "10px 11px" }}
        color="yellow"
        onClick={onClickNewRequest}
        setId={setComponentsId("check-cabinet-new-request-btn")}
      >
        {t("NEW_REQUEST")}
      </Button>
    </Fragment>
  );
};

CheckCabinetResult.propTypes = {
  t: PropTypes.func.isRequired,
  onClickDownload: PropTypes.func.isRequired,
  onClickNewRequest: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  result: PropTypes.shape({
    isFound: PropTypes.number,
    isLegal: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    linkedAt: PropTypes.string,
  }).isRequired,
};

export default translate("CheckCabinet")(CheckCabinetResult);
