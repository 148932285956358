import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import TextFieldDummy from "../../components/CustomInput/TextFieldDummy";
import Select from "../../components/Select";

const DefaultTextField = (props) => {
  const { dummy, select } = props;
  if (dummy) return <TextFieldDummy {...props} />;
  if (select) return <Select {...props} />;
  return <TextField {...props} />;
};

DefaultTextField.propTypes = {
  dummy: PropTypes.bool,
  select: PropTypes.bool,
};

DefaultTextField.defaultProps = {
  dummy: false,
  select: false,
};

export default DefaultTextField;
