import { FC, useState, useEffect } from "react";
import AttachList from "../../Attach/AttachList";
import { BlockScreen, Button } from "../..";
import { SchemaFormProps } from "../../FormFromSchema";
import { UploadMultipleFilesDialog } from "../../UploadMultipleFiles";
import { AttachInfo } from "../types";
import {
  requestAttachPreview,
  uploadDocumentAttach,
  deleteDocumentAttach,
  downloadDocumentAttach,
} from "../../../actions/claim";
import { normalizeFileName } from "../../../helpers/testFile";

const DocumentFilesForm: FC<SchemaFormProps> = ({
  context,
  data,
  name,
  onDataUpdate,
  onError,
  schema,
  setId,
  t,
}) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  const options = schema.$vars || {};
  const document = context.document;
  // data = Array.isArray(data) ? data : [];

  useEffect(() => {
    if (!data || data.length === 0) {
      data = ((data || []) as AttachInfo[])
        .concat(document.attaches)
        .sort((a, b) => a.attachId - b.attachId);
      if (onDataUpdate)
        onDataUpdate(name, data).then(() => {
          setIsMounted(true);
        });
    } else {
      setIsMounted(true);
    }
  }, []);

  const openUploadModal = () => setUploadModal(true);

  const closeUploadModal = () => {
    setUploadModal(false);
    setSaving(false);
    setLocalFiles([]);
  };

  const handleDeleteLocalFiles = (index: number) => {
    setLocalFiles(localFiles.filter((_, i) => i !== index));
  };

  const handleAddedLocalFiles = (addedFiles: File[]) => {
    setLocalFiles(localFiles.concat(addedFiles));
  };

  const handleRenameFile = (index: number, newName: string) => {
    setLocalFiles(
      localFiles.map((file, i) => {
        if (i === index) {
          return new File([file], newName, {
            type: file.type,
            lastModified: file.lastModified,
          });
        }
        return file;
      })
    );
  };

  const handleDelete = (index: number) => async () => {
    setSaving(true);

    const result = await deleteDocumentAttach(
      document?.id,
      (data as AttachInfo[])[index].attachId
    );
    if (result.isAccepted) {
      (data as AttachInfo[]).splice(index, 1);
      onDataUpdate && (await onDataUpdate(name, data));
    } else {
      onError && onError("Помилка видалення документу");
    }

    setSaving(false);
  };

  const handleRequestPreview = (attach: AttachInfo) => async () => {
    return requestAttachPreview(document?.id, attach.attachId, attach);
  };

  const handleDownload = (attach: AttachInfo) => async () => {
    return await downloadDocumentAttach(document?.id, attach.attachId, attach);
  };

  const fileIsCorrect = (file: any) =>
    !file.message && !file.serverMessage && file.attachId && file.link;

  const handleSave = async () => {
    if (!localFiles) {
      return closeUploadModal();
    }
    //запускаем лоадер
    setSaving(true);

    try {
      /**
       * Выполняется сохранение текущих локальных файлов.
       * accepted - массив модифицированных объектов файлов которые успешно сохранились на беке
       * rejected - массива с объектами типа Files, которые почему-то не сохранились
       */
      let accepted: AttachInfo[] = [],
        rejected: File[] = [];

      let attach;

      for (let i = 0; i < localFiles.length; i++) {
        attach = await uploadDocumentAttach(document?.id, localFiles[i]);
        if (fileIsCorrect(attach)) {
          accepted.push({
            ...attach,
            fileName: normalizeFileName(attach.fileName),
            name: normalizeFileName(attach.fileName),
            userFileName:
              normalizeFileName(attach.userFileName) ||
              normalizeFileName(attach.fileName),
            mimeType: attach.contentType,
          });
        } else {
          rejected.push(localFiles[i]);
        }
      }
      //якщо є збережені файли то оновлюємо данні в БД
      if (accepted.length > 0) {
        data = ((data || []) as AttachInfo[])
          .concat(accepted)
          .sort((a, b) => a.attachId - b.attachId);
        // document.attaches = data;
        onDataUpdate && (await onDataUpdate(name, data));
        //  onDataUpdate && onDataUpdate(name, accepted);
      }
      // если есть отклоненные файлы, просто оставим их в попапе
      if (rejected.length > 0) {
        setLocalFiles(rejected);
        return setSaving(false);
      }

      closeUploadModal();
    } catch (error: any) {
      setSaving(false);
      onError && onError(error.message);
    }
  };

  return isMounted ? (
    <div style={{ width: "100%" }} id={setId("wrap")}>
      <div id={setId("actions")}>
        <Button
          onClick={openUploadModal}
          color="yellow"
          setId={(elementName: string) => setId(`upload-${elementName}`)}
        >
          {options.addItemText || t("UPLOAD")}
        </Button>
      </div>
      <AttachList
        handleDelete={handleDelete}
        handleDownload={handleDownload}
        requestPreview={handleRequestPreview}
        attaches={data}
        // attaches={document.attaches}
        setId={(elementName: string) => setId(`-attach-list-${elementName}`)}
      />
      <UploadMultipleFilesDialog
        accept={options.accept}
        files={localFiles}
        maxSize={options.maxSize}
        noDefaultAccept={true}
        onSave={handleSave}
        onClose={closeUploadModal}
        onDelete={handleDeleteLocalFiles}
        onFilesAdded={handleAddedLocalFiles}
        onRenameFile={handleRenameFile}
        open={uploadModal}
        setId={(elementName: string) => setId(`-dialog-${elementName}`)}
      />
      <BlockScreen open={saving} />
    </div>
  ) : null;
};

export default DocumentFilesForm;
