import elements from "./Elements";

export default {
  ...elements,
  SHARE_LIST: "Довіреності",
  SHARE_LIST_AND_ORDERS: "Довіреності/Ордери",
  CHAIN_TITLE: "ланцюжок довіреностей",
  PREVIEW_LINK: "переглянути довіреність",
  SHARE: "Довіреність",
  SHARE_LIST2: "Уповноважені представники та довірителі",
  SHARE_LIST_AND_ORDERS2: "Уповноважені представники та довірителі",
  ADD_SHARE: "Створити довіреність",
  ADD_ORDER: "Створити ордер",
  CONTINUE: "Продовжити",
  REJECT_EDR_RECIPE: "Анулювати Виписку ЄДР",
  REJECT_EDR_RECIPE_WITH_SHARES: "Анулювати Виписку з довіреностями",
  ORDER: "Ордер",
  ADD_EDRRecipe: "Переформувати виписку з ЄДР",
  EDRRecipe: "Виписка з ЄДР",
  TO: "Кому",
  FROM: "Від кого",
  FROM_TO: "Від кого / Кому",
  FORM: "Форма",
  PERMISSION_TITLE: "Права доступу",
  CHANGE_PERMISSION: "Передоручити",
  DELETE_PERMISSION: "Анулювати довіреність",
  DELETE_DIALOG_TITLE: "Ви дійсно бажаєте анулювати довіреність?",
  DELETE_DIALOG_SHARE_ORDER: "Ви дійсно бажаєте анулювати довіреність/ордер?",
  DELETE_DIALOG_TEXT: "Цю дію неможливо відмінити",
  ADD_PERMISSION_TITLE: "Виберіть представника та права доступу",
  ADD_PERMISSION_TEXT: "Надати права {{user}}",
  INN: "РНОКПП/ЄДРПОУ",
  DESCRIPTION:
    "Вкажіть РНОКПП/ЄДРПОУ особи якій Ви хочете надати права доступу",
  INN_BASE_ERROR: "Користувача з таким РНОКПП/ЄДРПОУ не знайдено в системі",
  CASE_NUMBER_ERROR: "Поле не може бути пустим",
  INN_DUPLICATE_ERROR: "Довіреність на цього користувача вже створено",
  DUPLICATE_IN_SHARE: "Цей користувач вже існує в ланцюжку цього доручення",
  INN_SELF_ERROR: "Це ваш РНОКПП/ЄДРПОУ",
  INN_FORMAT_ERROR: "Некоректний формат РНОКПП/ЄДРПОУ",
  PERMISSION_1: "Перегляд",
  PERMISSION_1_DESCRIPTION: "Представник може ознайомитись з документами",
  PERMISSION_2: "Редагування",
  PERMISSION_2_DESCRIPTION:
    "Представник редагує заяви без права подання до суду",
  PERMISSION_3: "Подання",
  PERMISSION_3_DESCRIPTION:
    "Представник створює, редагує, підписує заяви своїм електронним підписом та направляє до суду",
  RESHARE: "Передоручення",
  AT_CASE: "На справу",
  RESHARE_DESCRIPTION:
    "Представник може передати надані йому права іншим представникам",
  CASE_DESCRIPTION: "Test description",
  ABBR_PERMISSION_1: "Перегляд",
  ABBR_PERMISSION_2: "Редагування",
  ABBR_PERMISSION_3: "Перегляд, редагування, подання",
  ABBR_PERMISSION_4: "; Передоручення",
  ABBR_PERMISSION_5: "; Подача ВД до АСВП",
  CLOSE: "Закрити",
  CANCEL_ACTION: "Відмінити",
  ERROR_WHILE_CREATING_PDF: "Сталася помилка під час створення ПДФ документа",
  CANCEL: "Відміна",
  SUBMIT: "Надати",
  DELETE: "Видалити",
  ADD_RESHARE: "Передоручити",
  RESHARE_COUNT: "Кількість передоручень",
  CHANGE_PERMISSION_TITLE: "Змінити права доступу",
  READONLY_PERMISSION_TITLE: "Перегляд довіреності",
  CHANGE_PERMISSION_FROM: "Довіритель",
  CHANGE_PERMISSION_TO: "Представник",
  CHANGE_PERMISSION_RESHARED: "Замісник",
  CHANGE_SUBMIT: "Змінити",
  NO_PERNISSIONS: "Вибачте, у вас немає прав на редагування цієї довіреності",
  RESHARE_ERROR:
    "Увага! Після збереження змін, усі передоручення будуть відкликані",
  RESHARE_DUPLICATE_ERROR: "Цей користувач вже має доручення з такими правами",
  SUB_RESHARE: "Передоручитель: {{subReshare}}",
  DOCUMENT_CREATING_DATE: "Дата видачі",
  DOCUMENT_CANCELING_DATE: "Дата анулювання",
  CREATING_DATE: "Довіреність видана {{date}} р.",
  ORDER_GIVEN_DATE: "Ордер виданий {{date}} р.",
  PERMISSION_CANCEL_DATE: "Довіреність анульована {{date}} р.",
  ORDER_CANCEL_DATE: "Ордер анульований {{date}} р.",
  ORDER_PREVIEW_LINK: "переглянути ордер",
  LOADING: "Дочекайтеся збереження даних",
  SHARE_EMPTY_PAGE_TITLE: "Довіреностей немає",
  SHARE_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись надані вам та вами довіреності",
  FROM_ME: "Від мене",
  GLOBAL_STATE: "загальна",
  COURT_STATE: "На судочинство",
  CASE_STATE: "на справу",
  PROC_STATE: "на провадження",
  TO_ME: "Мені",
  SERVER_ERROR: "Помилка на сервері",
  UNKNOWN_ERROR: "Щось пішло не так: {{error}}",
  SIGN_CERTIFICATE_PEM_NOT_MATCH_USERS_PEM:
    "Сертифікат не співпадає з сертифікатом користувача.",
  GLOBAL_SHARE_DESCRIPTION:
    'Довіреності на конкретну справу або провадження створюються в розділі "Мої справи"',
  SHARE_CASE_TITLE: "на справу № {{caseNumber}} від {{createdAt}}",
  SHARE_JUSTICE_TITLE: "на справи {{justiceTypeName}}",
  SHARE_PROC_TITLE:
    "на провадження № {{number}} по справі №{{caseNumber}} від {{createdAt}}",
  NOT_SPECIFIED: "не визначено",
  DONT_HAVE_DELETE_PERMISSIONS: "Немає прав на видалення",
  ADD_TO_DELETE: "Додати на видалення",
  TYPE: "Тип",
  CANCEL_PERMISSION: "Анулювати",
  CANCEL_PERMISSION_EDR: "Анулювати виписки з ЄДР",
  ACTIVE_PERMISSIONS: "Діючі",
  CANCELED_PERMISSIONS: "Анульовані",
  ALL_PERMISSIONS: "Всі",
  PERMISSION_STATUS: "Стан довіреності/ордеру",
  CHECK_CERTIFICATE_ERROR:
    "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено",
  COUNT_SHARES: "Відображати",
  DISPLAYED_SHARES: "з {{from}} по {{to}} із {{total}}",
  SELECT_ALL: "Вибрати все",
  SUPPLY_TO_ASVP: "Подача ВД до АСВП",
  SUPPLY_TO_ASVP_DESCR: `Представник може подавати на виконання виконавчі документи в електронному вигляді до автоматизованої системи виконавчих проваджень - розділ "Виконавчі документи"`,
};
