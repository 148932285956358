import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
// import {createLogger} from 'redux-logger';
import rootReducer from "../reducers";
// import config from 'config.json';

// const {application: {environment}} = config;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(history) {
  let applyOptions = [routerMiddleware(history), thunk];
  // if (environment !== 'production') {
  //     applyOptions = [
  //         ...applyOptions,
  // createLogger({
  //     collapsed: true,
  //     predicate: (getState, action) => action.type !== 'SET_WIZARD_STATES'
  // })
  // ];
  // }
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...applyOptions))
  );
}
