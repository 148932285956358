import blobToBase64 from "../helpers/blobToBase64";
import { blobToTextEncoded } from "./blobToText";
import stringToBlob from "../helpers/stringToBlob";
import { getFormat } from "../helpers/getAttachFormat";
import formatFile from "../helpers/formatFile";
import getFileUrl from "../helpers/getFileUrl";

export default function (props, state, setState) {
  const { handleDownload, attach } = props;
  let { showPreview, preview } = state;
  const setDocType = async (file, string = "", doc, preview) => {
    const text = string || (await blobToTextEncoded("Windows-1251")(file));
    const formatingFile = await formatFile(file, text);
    const format = getFormat(formatingFile.type, text);
    const url = await getFileUrl(formatingFile, format, text);
    setState({
      ...state,
      format,
      type: file.type,
      url,
      text,
      file: formatingFile,
      doc: doc ? doc : state.doc,
      preview: format === "image" ? doc : preview,
    });
  };

  if (handleDownload) {
    setState({ ...state, doc: null, format: "", url: "", file: null });
    (handleDownload && handleDownload(attach))().then((file) => {
      if (typeof file === "object" && !file.message) {
        blobToBase64(file).then((doc) => {
          setState({
            ...state,
            doc,
            preview: preview,
            showPreview: showPreview || file.type.indexOf("image") === 0,
          });
          setDocType(file, null, doc, preview);
        });
      }
      if (typeof file === "string") {
        const blob = stringToBlob(file);
        setDocType(blob, file);
      }
      if (typeof file === "object" && file.message) {
        setState({
          ...state,
          text: file ? file.message || file : "",
          format: file ? "text" : "",
          preview: "",
          doc: null,
        });
      }
    });
  } else {
    setState({ ...state, doc: preview, preview });
    setDocType(preview);
  }
}
