/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";
import { translate } from "react-translate";
import {
  withStyles,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TextFieldDummy from "../../../components/CustomInput/TextFieldDummy";
import InputMask from "react-input-mask";
import InputAdornment from "@material-ui/core/InputAdornment";

import customInputStyle from "../../../variables/styles/customInputStyle";

const Masked = (props) => (
  <InputMask {...props} maskChar=" " inputRef={props.ref} />
);

const regExp = /^(\d{1,13})([.,](\d{1,2})?)?$/;

Masked.propTypes = {
  ref: PropTypes.node,
};

Masked.defaultProps = {
  ref: undefined,
};

class Money extends React.Component {
  state = { value: 0, nonMaterial: this.props.value === 0 };

  UNSAFE_componentWillMount() {
    const { value } = this.props;
    this.handleChange({ target: { value: `${value}` } });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, readOnly } = nextProps;
    if (readOnly && value !== this.state.value) {
      this.setState({ value });
    }
  }

  handleChange = ({ target }) => {
    const newValue = target.value.replace(/^0+/, "") || "0";
    const { onChange, value: propsValue } = this.props;
    let { value, value: setValue } = this.state;

    if (!newValue || regExp.test(newValue)) {
      value = newValue.replace(".", ",");
      setValue = newValue.replace(",", ".") || propsValue;
    }

    this.setState({ value }, () => onChange && onChange(parseFloat(setValue)));
  };

  setNonMaterial = ({ target: { checked } }) =>
    this.setState(
      { nonMaterial: checked },
      () => this.props.onChange && this.props.onChange(0)
    );

  render() {
    const {
      classes,
      sample,
      errors,
      description,
      formControlProps,
      InputProps,
      SelectProps,
      mask,
      required,
      t,
      readOnly,
      setId,
      label,
    } = this.props;
    const { nonMaterial, value } = this.state;

    const Component = readOnly ? TextFieldDummy : TextField;
    return (
      <FormControl
        {...formControlProps}
        className={classes.formControl}
        id={setId("form-control")}
      >
        <FormControlLabel
          id={setId("checkbox-wrap")}
          control={
            <Checkbox
              checked={nonMaterial}
              onClick={this.setNonMaterial}
              color="primary"
              classes={{ checked: classes.checked }}
              id={setId("checkbox")}
            />
          }
          label={label || t("NON_MATERIAL")}
        />
        {!nonMaterial && (
          <Component
            label={description && `${description}${required ? "*" : ""}`}
            value={value}
            helperText={!readOnly && (sample || errors)}
            error={!!errors}
            onChange={this.handleChange}
            InputProps={{
              ...InputProps,
              inputComponent: Masked,
              startAdornment: (
                <InputAdornment position="start">UAH</InputAdornment>
              ),
            }}
            inputProps={{ mask }}
            SelectProps={SelectProps}
            id={setId("input")}
          />
        )}
      </FormControl>
    );
  }
}

Money.propTypes = {
  onChange: PropTypes.func,
  sample: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formControlProps: PropTypes.object,
  description: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  SelectProps: PropTypes.object,
  mask: PropTypes.string,
  required: PropTypes.bool,
  t: PropTypes.func.isRequired,
  ref: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

Money.defaultProps = {
  onChange: undefined,
  sample: "",
  formControlProps: {},
  errors: null,
  description: "",
  readOnly: false,
  InputProps: {},
  SelectProps: {},
  mask: "",
  required: false,
  setId: setComponentsId("money"),
  ref: undefined,
  value: "",
  label: "",
};

const styled = withStyles(customInputStyle)(Money);
export default translate("Elements")(styled);
