import React from "react";
import { translate } from "react-translate";
import PropTypes from "prop-types";
import { RegularCard } from "../../components";
import setComponentsId from "../../helpers/setComponentsId";
import CheckCabinetLayout from "./components/CheckCabinetLayout";
import CheckCabinetResult from "./components/CheckCabinetResult";
import { CHECK_CABINET_URL } from "../../variables/wikiUrls";

import {
  getCheckCabinet,
  getCheckCabinetDownload,
  putCheckCabinet,
} from "../../actions/check-cabinet";

import Preloader from "../../components/Preloader";
import { humanDateTimeFormatWithSeconds } from "../../helpers/humanDateFormat";

class CheckCabinet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      result: null,
      code: "",
    };
  }

  onClickCheck = async () => {
    const { t } = this.props;
    const codeWithoutSpaces = this.state.code.replace(/\s/g, "");

    if (codeWithoutSpaces.length < 8 || codeWithoutSpaces.length > 12) {
      return this.setState({
        error: t("NOT_VALID_CODE_ERROR"),
      });
    }

    // если длина code подходит под условие
    this.setState({
      loading: true,
    });
    try {
      // проверяю code на наличие личшних пробелов перед запросом, и если такие есть, то удаляю
      const result = await getCheckCabinet(codeWithoutSpaces);
      if (!result?.reqId) {
        throw new Error(t("GENERAL_ERROR"));
      } else {
        await putCheckCabinet(result.reqId, new Date());
        this.setState({
          result,
        });
      }
    } catch (error) {
      this.setState({
        error: error?.message || t("GENERAL_ERROR"),
      });
      console.log("On check cabinet error: ", error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  onClearCode = () =>
    this.setState({
      busy: false,
      code: "",
      error: "",
    });

  onChangeCode = ({ target: { value } }) => {
    this.setState({ code: value });
  };

  onClickNewRequest = () => {
    this.setState({
      result: null,
      code: "",
      error: "",
    });
  };

  onClickDownload = async () => {
    try {
      this.setState({
        loading: true,
      });
      const blob = await getCheckCabinetDownload(
        this.state.result.reqId,
        this.state.result.code,
        this.state.result.name
      );
      const code = this.state.code;
      const docDate = humanDateTimeFormatWithSeconds(
        this.state.result.createdAt,
        { format: "YYYY-MM-DD_HH-mm-ss" }
      );
      const name = `Запит_про_наявність_кабінету_${code}_${docDate}`; //getDocumentFileName(viewDocument);
      const { document } = window;
      const URL = window.URL || window.webkitURL;

      const element = document.createElement("a");
      element.setAttribute("href", URL.createObjectURL(blob));
      element.setAttribute("download", name);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch (error) {
      alert("Error download file");
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { result, error, code, loading } = this.state;
    return (
      <RegularCard
        cardTitle={t("MAIN_TITLE")}
        setId={setComponentsId("regular-card-check-cabinet")}
        wikiUrl={CHECK_CABINET_URL}
      >
        {!loading && !result && (
          <CheckCabinetLayout
            t={t}
            code={code}
            onClickCheck={this.onClickCheck}
            onChangeCode={this.onChangeCode}
            onClearCode={this.onClearCode}
            error={error}
          />
        )}
        {!loading && result && (
          <CheckCabinetResult
            code={code}
            result={result}
            onClickDownload={this.onClickDownload}
            onClickNewRequest={this.onClickNewRequest}
          />
        )}
        {loading && <Preloader />}
      </RegularCard>
    );
  }
}

CheckCabinet.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate("CheckCabinet")(CheckCabinet);
