import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { BlockScreen, Button } from "../../../components";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";

import { addMyDoc } from "../../../actions/personalData";

import UploadMultipleFiles from "../../../components/UploadMultipleFiles/UploadMultipleFiles";

class UploadDocsFilesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      fileList: [], // Список файлов
      maxSize: 100485760, //
      accept: `image/*,audio/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/x-zip-compressed,application/zip`, // ,application/x-tar
    };
  }

  // Загрузить предыдущее событие
  beforeUpload = (file) => {
    const fileList = [];
    // Получить новый список загрузки
    fileList.push(file);
    // Выполняем сохранение присваивания
    this.setState((preState) => ({
      fileList,
    }));
  };

  // Событие изменения загрузки файла
  updateChange = (file) => {
    // console.log("file: ", file);
    const { fileList } = this.state;
    const { showNotification } = this.props;
    // проверка на дубликат в модальном окне
    for (let key2 in fileList) {
      for (let key in file) {
        if (fileList.length > 0 && fileList[key2].name !== file[key].name) {
          console.log();
        } else {
          return showNotification("Файл з таким іменем вже існує");
        }
      }
    }

    this.setState(({ fileList }) => {
      return {
        fileList: fileList.concat(file),
      };
    });
  };

  savingStart = () => this.setState({ saving: true });
  savingEnd = () => this.setState({ saving: false });

  onSave = async () => {
    const { fileList } = this.state;
    const { onMyDocsUploaded } = this.props;
    this.setState({
      saving: true,
    });

    const uploadedFiles = [];
    for (let key in fileList) {
      const file = await addMyDoc(fileList[key]);
      uploadedFiles.push(file);
    }
    onMyDocsUploaded(uploadedFiles);
    this.setState({ saving: false });
  };

  // удалить файл
  removeFile = (index) => {
    this.setState(({ fileList }) => {
      return {
        fileList: fileList.filter((_, i) => i !== index),
      };
    });
  };

  render() {
    const { fileList, maxSize, accept, saving } = this.state;

    const { classes, setId, t, open, onClose } = this.props;

    return (
      <div>
        <Dialog
          fullWidth={true}
          open={open}
          onClose={() => {
            onClose();
            // setError("");
          }}
          aria-labelledby={setId("dialog-title")}
          id={setId("")}
          className={classes.dialog}
        >
          <DialogTitle
            id={setId("dialog-title")}
            className={classes.dialogContentWrappers}
          >
            {t("UPLOAD_DOCS")}
          </DialogTitle>
          <Fragment>
            {/* ИНПУТ ИМЯ ДОКУМЕНТА */}
            {/* {fileList.length === 1 && (
              <TextField
                style={{ margin: '10px 10px 10px 10px' }}
                className={classes.search}
                id={setId("doc-name-input")}
                label={t('FILE_TITLE_DOC')}
                type="search"
                onChange={handleChange}
                // value={value}
                helperText={
                  <span>
                    {t('NAME_HELPER')}
                  </span>
                }
              />
            )} */}
            <div className="uploadFiles" style={{ margin: "12px" }}>
              <UploadMultipleFiles
                files={fileList}
                onDeleteFile={this.removeFile}
                // onRenameFile={onRenameFile}
                onDrop={this.updateChange}
                maxSize={maxSize}
                accept={accept}
                // error={error}
                id={setId(`upload-files-modal`)}
              />
            </div>
          </Fragment>
          {saving && (
            <BlockScreen open={saving} id={setId("my-docs-blockscreen")} />
          )}
          <DialogActions
            className={cx(classes.actions, classes.dialogContentWrappers)}
            id={setId("actions")}
          >
            <Button
              disabled={!fileList.length || saving} //|| !!error
              onClick={this.onSave} //() => onSave()
              color="yellow"
              id={setId("save-button")}
              setId={(elementName) => setId(`save-${elementName}`)}
            >
              {t("SAVE_DOCS")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UploadDocsFilesDialog.propTypes = {
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
  onMyDocsUploaded: PropTypes.func.isRequired,
  onRenameFile: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default UploadDocsFilesDialog;
