export const hasPermissionForDelete = (item, isOwner) => {
  if ("haveDeletePermission" in item) {
    return item.haveDeletePermission;
  }
  let havePermission = true;
  if ("haveEditPermission" in item) {
    havePermission = item.haveEditPermission;
  }
  if ("state" in item && havePermission) {
    return item.state === 1 || item.state === -1;
  }
  if (typeof isOwner === "function" && havePermission) {
    havePermission = isOwner(item);
  }
  return havePermission;
};
