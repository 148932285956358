import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import SelectFileArea from "../SelectFileArea";
import cn from "classnames";
import humanFileSize from "../../helpers/humanFileSize";
import { translate } from "react-translate";
import { testFileName, normalizeFileName } from "../../helpers/testFile";

function EditNameCell({
  name,
  onRenameFile,

  classes,
  t,
}) {
  const fileExt = name.split(".").pop();
  const [localName, setLocalName] = useState(
    name.substring(0, name.length - fileExt.length - 1)
  );
  const [error, setError] = useState("");

  return (
    <TableCell component="th" scope="row" className={classes.cell}>
      <TextField
        error={!!error}
        value={localName}
        helperText={error}
        onChange={(event) => {
          setLocalName(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  const localFileName = normalizeFileName(localName);
                  const fileName = `${localFileName}.${fileExt}`;
                  const msg = testFileName(localFileName, t);
                  if (msg) {
                    setError(msg);
                    console.log(
                      `UploadMultipleFilesDialog, error ${msg} of filename: ${localFileName}`
                    );
                  } else {
                    onRenameFile(fileName);
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  onRenameFile(name);
                }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </TableCell>
  );
}

const UploadMultipleFiles = ({
  classes,
  t,
  setId,
  files,
  error,
  onDeleteFile,
  onRenameFile,
  onDrop,
  maxSize = 10485760,
  // accept = "image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  accept = `image/*,audio/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/x-zip-compressed,application/zip`, // ,application/x-tar
}) => {
  const [editingIndex, setEditingIndex] = useState(null);

  // accept = accept.replace(
  //   "application/zip",
  //   "application/zip, application/x-zip-compressed"
  // );

  // console.log("UploadMultipleFiles accept: ", accept);

  return (
    <>
      <SelectFileArea
        accept={accept}
        maxSize={maxSize}
        multiple={true}
        onDrop={onDrop}
        setId={setId}
      />
      {error && (
        <Fragment>
          <br />
          <Typography
            className={styles.flex}
            align="left"
            color="error"
            id={setId("error")}
            title={t("ERROR_FILE_TITLE")}
          >
            {error}
          </Typography>
        </Fragment>
      )}
      {files && !!files.length && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>{t("FILE_NAME")}</TableCell>
                <TableCell align="right" className={classes.cell}>
                  {t("FILE_SIZE")}
                </TableCell>
                <TableCell padding="none" className={classes.cell} />
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {files.map((file, index) => (
                <TableRow key={index} className={classes.row}>
                  {index === editingIndex ? (
                    <EditNameCell
                      name={file.name}
                      classes={classes}
                      t={t}
                      onRenameFile={(newName) => {
                        onRenameFile(index, newName);
                        setEditingIndex(null);
                      }}
                    />
                  ) : (
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}
                    >
                      {file.name}
                    </TableCell>
                  )}

                  <TableCell align="right" className={classes.cell}>
                    {humanFileSize(file.size)}
                  </TableCell>
                  <TableCell padding="none" className={classes.cell}>
                    <div className={classes.displayFlex}>
                      <IconButton
                        className={cn(classes.button, {
                          [classes.displayNone]:
                            editingIndex != null || !onRenameFile,
                        })}
                        onClick={() => {
                          setEditingIndex(index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        className={cn(classes.button, {
                          [classes.displayNone]: editingIndex != null,
                        })}
                        onClick={() => {
                          onDeleteFile(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
};

UploadMultipleFiles.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  onDeleteFile: PropTypes.func.isRequired,
  onRenameFile: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
};

const styles = {
  root: {
    maxWidth: "100%",
  },
  displayNone: {
    display: "none",
  },
  displayFlex: {
    display: "flex",
  },
  flex: {
    flex: 1,
  },
  table: {
    marginTop: 30,
    maxWidth: "100%",
  },
  tableBody: {
    maxWidth: "100%",
  },
  row: {
    width: "100%",
    overflow: "hidden",
  },
  cell: {
    maxWidth: "100%",
    wordBreak: "break-all",
  },
  "@media (max-width:767px)": {
    // eslint-disable-line no-useless-computed-key
    table: {
      marginTop: 10,
    },
    cell: {
      padding: "0 10px",
      height: "36px",
      minWidth: 60,
    },
  },
};

export default translate("Elements")(withStyles(styles)(UploadMultipleFiles));
