import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import customInputStyle from "../../variables/styles/customInputStyle";
import { RadioItem } from "./types";

type Props = {
  classes: any;
  t: any;
  value: any;
  name: string;
  disabled?: boolean;
  label: string;
  onChange: (key: string, value: any) => void;
  items: RadioItem[];
};

function CustomRadioGroup({
  classes,
  t,
  value,
  onChange,
  items,
  name,
  label,
  disabled,
}: Props) {
  return (
    <>
      <FormLabel>{t(label)}</FormLabel>
      <RadioGroup
        className={classes.group}
        value={value}
        // @ts-ignore
        onChange={({ target: { value } }) => {
          onChange(name, value);
        }}
        name={name}
      >
        {items.map(({ value, label, key }, i) => (
          <FormControlLabel
            className={classes.radioLabel}
            key={key}
            value={value}
            control={
              <Radio
                className={classes.radio}
                color="default"
                disabled={disabled}
              />
            }
            label={
              <Fragment>
                <span className={classes.radioTitle}>{t(label)}</span>
                {/*<span className={classes.radioDescription}>*/}
                {/*  {t(`PERMISSION_${textId}_DESCRIPTION`)}*/}
                {/*                </span>*/}
              </Fragment>
            }
          />
        ))}
      </RadioGroup>
    </>
  );
}

const styles = {
  ...customInputStyle,
} as const;

export default withStyles(styles)(CustomRadioGroup);
