import getWizardProps from "../../../../helpers/getWizardProps";

export default function updateFieldValue(value, stepId, claim) {
  const {
    template: { jsonSchema },
  } = getWizardProps();
  const stepProps = jsonSchema.properties[stepId];

  const { digitalDocumentData } = claim;
  claim = {
    ...claim,
    digitalDocumentData: {
      ...digitalDocumentData,
      activeStep: stepId,
      uploadError: undefined,
    },
  };

  if (["array", "object"].includes(stepProps.type)) {
    return claim;
  }

  return Promise.resolve({
    ...claim,
    digitalDocumentData: { ...digitalDocumentData, [stepId]: value },
  });
}
