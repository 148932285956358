import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import cx from "classnames";
import { withStyles } from "@material-ui/core";

import typographyStyle from "../../variables/styles/typographyStyle.jsx";

const Small = ({ classes, children, setId, ...rest }) => (
  <a
    {...rest}
    className={cx(classes.defaultFontStyle, classes.smallText)}
    id={setId("")}
  >
    {children}
  </a>
);

Small.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  setId: PropTypes.func,
};

Small.defaultProps = {
  children: <span />,
  setId: setComponentsId("typography-small"),
};

export default withStyles(typographyStyle)(Small);
