import React from "react";
import PropTypes from "prop-types";
// import {connect} from 'react-redux';
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const PaymentInfoSummFilterChip = ({ t, value, ...rest }) => {
  const sums = value.split("-");
  if (sums?.[0] && sums?.[1]) {
    return (
      <Chip
        label={`${t("FROM_SUM_CHIP")} ${sums?.[0] || ""} - ${t(
          "TO_SUM_CHIP"
        )} ${sums?.[1] || ""}`}
        {...rest}
      />
    );
  } else if (sums?.[0]) {
    return (
      <Chip label={`${t("FROM_SUM_CHIP")} ${sums?.[0] || ""}`} {...rest} />
    );
  } else if (sums?.[1]) {
    return <Chip label={`${t("TO_SUM_CHIP")} ${sums?.[1] || ""}`} {...rest} />;
  }
};

PaymentInfoSummFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PaymentInfoSummFilterChip.defaultProps = {
  value: "",
};

export default translate("ExecutiveDocs")(PaymentInfoSummFilterChip);
