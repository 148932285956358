export default {
  dialog: {
    "& > div": {
      background: "transparent",
      transition: "none",
    },
  },
  dialogPaper: {
    background: "transparent",
    boxShadow: "none",
  },
};
