import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const InputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

InputComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const Control = ({ innerRef, selectProps, children, innerProps }) => {
  return (
    <TextField
      {...selectProps.textFieldProps}
      fullWidth={true}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
  );
};

Control.propTypes = {
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

Control.defaultProps = {
  innerRef: () => null,
  innerProps: {},
  children: "",
};

export default Control;
