import DataSource from "../helpers/dataSource";

const dataSource = DataSource("cases/my_as_judge");
const initialState = { dataSource };

const SET_DATA_SOURCE = "JUDGE/SET_DATA_SOURCE";

export default (state = initialState, action) => {
  state = dataSource.reducer()(state, action);

  switch (action.type) {
    case SET_DATA_SOURCE:
      return { ...state, dataSource: action.payload };
    default:
      return state;
  }
};
