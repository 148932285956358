import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import { Preloader } from "../../components";

import pdfStyles from "../../variables/styles/pdfDocument";
import attachStyles from "../../variables/styles/attaches";

const styles = { ...pdfStyles, ...attachStyles };

const PreloaderPreview = ({ classes }) => (
  <div className={classes.previewWrap}>
    <div className={classes.previewScrollBox}>
      <div className={classes.previewBox}>
        <div className={classes.previewFrame}>
          <Preloader />
        </div>
      </div>
    </div>
    <div className={classes.previewActions} />
  </div>
);

PreloaderPreview.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PreloaderPreview);
