export default {
  flexWrap: {
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      paddingRight: "24px",
      position: "relative",
    },
  },
  dialog: {
    "& > :last-child": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        margin: "48px 10px",
      },
    },
  },
  dialogContentWrappers: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "24px 15px 20px",
    },
  },
  videoLink: {
    display: "flex",
    height: "24px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  videoLinkText: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  videoIcon: {
    opacity: "0.5",
  },
  wikiIcon: {
    marginLeft: "7px",
    opacity: "0.5",
    "&:hover": {
      cursor: "pointer",
    },
    "@media screen and (max-width: 767px)": {
      marginBottom: "-5px",
    },
  },
  videoFrame: {
    width: "100%",
    maxWidth: "560px",
    height: "315px",
  },
};
