import React from "react";
import PropTypes from "prop-types";
import { SupervisorAccount, Attachment } from "@material-ui/icons";
import humanDateFormat from "../../../../helpers/humanDateFormat";
import { formatUserName } from "../../../../helpers/userName";
import { Table } from "../../../../components";
import StatusChip from "../../../../components/StatusChip";
import Status from "../Status";
import caseFields from "./caseFields";
import { useCourtName } from "./useCourtName";

const getRoleFromPerm = (caseMembers, permissions, memberRoles, userId, t) => {
  let roleText = "";
  const foundPermissionsUser =
    Array.isArray(caseMembers) && Array.isArray(permissions)
      ? caseMembers.find(
          (member) =>
            !!permissions.find(
              (item) =>
                member.userId === item.allowedBy && item.userId === userId
            )
        )
      : null;
  const foundPermissionsRole =
    foundPermissionsUser && Array.isArray(memberRoles)
      ? memberRoles.find((data) => data.id === foundPermissionsUser.roleId)
      : null;
  const representativeRoleName = foundPermissionsRole
    ? `representative${foundPermissionsRole.name[0].toUpperCase()}${foundPermissionsRole.name.slice(
        1
      )}`
    : "";
  const foundRepresentativeRole = Array.isArray(memberRoles)
    ? memberRoles.find((data) => data.name === representativeRoleName)
    : null;
  if (foundRepresentativeRole) {
    ({ description: roleText } = foundRepresentativeRole);
  } else if (representativeRoleName === "representativeComplainant") {
    roleText = t("REPRESENTATIVE_COMPLAINANT");
  } else if (foundPermissionsRole) {
    roleText = t("REPRESENTATIVE", {
      permissionName: foundPermissionsRole.description,
    });
  }
  return roleText;
};

const findRoleInMembers = (item, memberRoles, userId, t) => {
  const { caseMembers, permissions, permissionsToCaseProceedings } = item;
  const filterUsers = Array.isArray(caseMembers)
    ? caseMembers.reduce((acc, member) => {
        if (member.userId === userId) {
          const findRole = (memberRoles || []).find(
            (data) => data.id === member.roleId
          );
          const foundDouble = acc.find(
            (role) => role === (findRole || {}).description
          );
          if (findRole && !foundDouble) {
            acc.push(findRole.description);
          }
        }
        return acc;
      }, [])
    : [];
  let roleText = filterUsers.join(" / ");
  if (!roleText && permissions && !!permissions.length && permissions[0]) {
    roleText = getRoleFromPerm(
      caseMembers,
      permissions,
      memberRoles,
      userId,
      t
    );
  }
  if (
    !roleText &&
    permissionsToCaseProceedings &&
    !!permissionsToCaseProceedings.length &&
    permissionsToCaseProceedings[0]
  ) {
    roleText = getRoleFromPerm(
      caseMembers,
      permissionsToCaseProceedings,
      memberRoles,
      userId,
      t
    );
  }
  return roleText;
};

const getRole = (item, memberRoles, userId, t) => {
  let roleText = "";
  if (item.me && item.me.caseMemberRoles) {
    const names = new Set();
    item.me.caseMemberRoles.forEach((role) => {
      names.add(role.description);
    });
    roleText = [...names].join(", ");
  }

  return (
    roleText ||
    findRoleInMembers(item, memberRoles, userId, t) ||
    t("NOT_FOUND_ROLE")
  );
};

const CaseTable = ({
  t,
  list,
  dataSource,
  createSortHandler,
  checked,
  onCheckItem,
  statuses,
  memberRoles,
  userId,
  onCheckboxClick,
  setId,
  pagination,
  changeCount,
  display,
  onSelectAllClick,
  favorites,
  templates,
}) => {
  const getCourtName = useCourtName(t);

  const getText = (item, key) => {
    const {
      // digitalDocumentTemplateId,
      resharing,
      number,
      createdAt,
      owner,
      ownerName,
      state,
      documentStateInfo,
      scanDocumentLink,
      digitalDocumentStaticFile,
    } = item;

    // const template = templates.find(
    //   ({ id }) => id === digitalDocumentTemplateId
    // );
    const foundDocLink = !!(scanDocumentLink || digitalDocumentStaticFile);

    const foundStatus = (statuses &&
      statuses.find((status) => status.id === item.status)) || {
      id: 100,
      name: "",
    };
    let subTitle = key === "fullName" ? "FULL_NAME" : "PROC_FULL_NAME";
    if (key === "docFullName") subTitle = "DOC_FULL_NAME";
    switch (key) {
      case "procFullName":
      case "fullName":
        return t(subTitle, {
          number: number || t("NUMBER"),
          createdAt: humanDateFormat(createdAt),
        });
      case "docFullName":
      case "myProcDocFullName":
        subTitle = "MY_PROC_DOC_FULL_NAME";
        return t(subTitle, {
          number: number || t("WITHOUT_NUMBER"),
          createdAt: humanDateFormat(createdAt),
        });
      case "templateName":
        return item.description ? item.description : "б/н"; // показ содержимого колонки "Назва" в "Документах користувача по справі"
      // return digitalDocumentTemplateId ? (template || {}).name || "" : "";
      case "resharing":
        return resharing ? <SupervisorAccount /> : "";
      case "state":
        return (
          <StatusChip
            setId={setId}
            status={state}
            tableChip={true}
            documentStateInfo={documentStateInfo}
          />
        );
      case "owner":
      case "ownerName":
        return ownerName ? formatUserName(ownerName) : owner || "";
      case "courtName": {
        const template = templates.find(
          ({ id }) => id === item.digitalDocumentTemplateId
        );
        return (
          getCourtName(item.courtId, template && template.causeActionType) ||
          t("NUMBER")
        );
      }
      case "caseNumber":
      case "number":
        return number || t("NUMBER");
      case "docNumber":
        return number || t("WITHOUT_NUMBER");
      case "roleName":
      case "roleNameText":
        return getRole(item, memberRoles, userId, t);
      case "docDateFirst":
        return humanDateFormat(item[key]);
      case "docDateLast":
        return humanDateFormat(item[key]);
      case "createdAt":
      case "updatedAt":
        return humanDateFormat(item[key]);
      case "status":
        return <Status setId={setId} status={foundStatus} tableChip={true} />;
      case "scanDoc":
        return foundDocLink ? <Attachment /> : "";
      default:
        return item[key];
    }
  };
  return (
    <Table
      fields={caseFields[display]}
      checked={checked}
      getText={getText}
      setId={setId}
      dataSource={dataSource}
      onCheckItem={onCheckItem}
      createSortHandler={createSortHandler}
      onCheckboxClick={onCheckboxClick}
      pagination={pagination}
      changeCount={changeCount}
      onSelectAllClick={onSelectAllClick}
      list={list}
      t={t}
      labelRowsPerPage="COUNT_CASES"
      labelDisplayedRows="DISPLAYED_CASES"
      favorites={favorites}
    />
  );
};

CaseTable.propTypes = {
  userId: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  onCheckItem: PropTypes.func.isRequired,

  createSortHandler: PropTypes.func.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  pagination: PropTypes.func.isRequired,
  changeCount: PropTypes.func.isRequired,

  list: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  memberRoles: PropTypes.array,
  t: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  favorites: PropTypes.array,
  templates: PropTypes.array,
};

CaseTable.defaultProps = {
  checked: "",
  onSelectAllClick: undefined,
  favorites: [],
  memberRoles: [],
  userId: "",
  templates: [],
};

export default CaseTable;
