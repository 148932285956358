import { AttachInfo, Document } from "../types";

export default function (doc: any): Document {
  if (doc.digitalDocumentData) {
    try {
      if (typeof doc.digitalDocumentData === "string")
        doc.digitalDocumentData = JSON.parse(doc.digitalDocumentData);
      if (doc.attaches) {
        doc.attaches = (doc.attaches as AttachInfo[]).sort(
          (a, b) => a.attachId - b.attachId
        );

        doc.digitalDocumentData.attaches = (doc.attaches as AttachInfo[]).map(
          (a) => Object.assign({}, a)
        );
      }
    } catch (e) {
      throw e;
      // Nothing to do
    }
  }

  return doc;
}
