import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import cx from "classnames";
import VideoManual from "../../videoManual/VideoManual";
import videoUrls from "../../../variables/videoUrls";
import PermissionsRadioGroup from "./PermissionsRadioGroup.jsx";
import SelectInstance from "./SelectInstance";
import OwnerMode from "./OwnerMode.jsx";
import Actions from "./Actions.jsx";

const stepNames = ["addClaim", "addClaimShare"];

const Layout = ({
  t,
  open,
  classes,
  closeDialog,
  role,
  userInfo: { isLegal, isIndividualEntrepreneur },
  disabledPersonally,
  setId,
  value,
  owners,
  ownersFilter,
  busy,
  step,
  claimentSelectMode,
  openedTooltip,
  error,
  showSelectInctance,
  toggleMode,
  toggleIndividualEntrepreneur,
  toggleSelectMode,
  toggleSelectInstance,
  toggleTooltip,
  setOwnerId,
  handleDeleteFile,
  onDropAuthorization,
  toggleStep,
  closeOrToggleDialog,
  allowAttachOrder,
  allowAttachFile,
  isAdvocate,
  wikiUrl,
}) => {
  const showIndividualEntrepreneurChoose =
    !isLegal &&
    isIndividualEntrepreneur &&
    !disabledPersonally &&
    role === "individual";
  const showRepresentativeChoose = role === "individual" ? false : true;
  const videoUrl =
    videoUrls[
      `${stepNames[step]}${showIndividualEntrepreneurChoose ? "IE" : ""}`
    ];

  const getTitle = () => {
    if (step === 0) return t("PERMISSION_TITLE" + step);
    return !isAdvocate
      ? t("PERMISSION_TITLE" + step)
      : t("PERMISSION_TITLE_SHARE_ORDER");
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby={setId("dialog-title")}
      aria-describedby={setId("content")}
      fullWidth={true}
      id={setId("")}
      className={classes.dialog}
    >
      <DialogTitle
        id={setId("dialog-title")}
        className={classes.dialogContentWrappers}
      >
        <VideoManual
          setId={setId}
          title={getTitle()}
          videoUrl={videoUrl}
          wikiUrl={wikiUrl}
        />
      </DialogTitle>
      <DialogContent
        id={setId("content")}
        className={classes.dialogContentWrappers}
      >
        {step === 0 && (
          <PermissionsRadioGroup
            toggleMode={toggleMode}
            toggleIndividualEntrepreneur={toggleIndividualEntrepreneur}
            classes={classes}
            setId={setId}
            t={t}
            value={value}
            disabledPersonally={disabledPersonally}
            showIndividualEntrepreneurChoose={showIndividualEntrepreneurChoose}
            showRepresentativeChoose={showRepresentativeChoose}
          />
        )}
        {step === 0 && showSelectInctance && (
          <SelectInstance
            classes={classes}
            setId={setId}
            t={t}
            openedTooltip={openedTooltip}
            toggleTooltip={toggleTooltip}
            toggleSelectInstance={toggleSelectInstance}
            value={value}
          />
        )}
        {step === 1 && (
          <OwnerMode
            classes={classes}
            t={t}
            setId={setId}
            owners={owners}
            ownersFilter={ownersFilter}
            value={value}
            claimentSelectMode={claimentSelectMode}
            openedTooltip={openedTooltip}
            error={error}
            toggleSelectMode={toggleSelectMode}
            toggleTooltip={toggleTooltip}
            setOwnerId={setOwnerId}
            handleDeleteFile={handleDeleteFile}
            onDropAuthorization={onDropAuthorization}
            allowAttachOrder={allowAttachOrder}
            allowAttachFile={allowAttachFile}
          />
        )}
      </DialogContent>
      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
        id={setId("actions")}
      >
        <Actions
          t={t}
          setId={setId}
          busy={busy}
          value={value}
          step={step}
          closeDialog={closeOrToggleDialog}
          toggleStep={toggleStep}
          error={error}
        />
      </DialogActions>
    </Dialog>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  closeDialog: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  allowAttachOrder: PropTypes.bool.isRequired,
  allowAttachFile: PropTypes.bool.isRequired,
  owners: PropTypes.array,
  ownersFilter: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
  disabledPersonally: PropTypes.bool,

  step: PropTypes.number.isRequired,
  claimentSelectMode: PropTypes.string.isRequired,
  openedTooltip: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isAdvocate: PropTypes.bool,
  role: PropTypes.string,
  showSelectInctance: PropTypes.bool,
  toggleMode: PropTypes.func.isRequired,
  toggleIndividualEntrepreneur: PropTypes.func.isRequired,
  toggleSelectMode: PropTypes.func.isRequired,
  toggleSelectInstance: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func.isRequired,
  setOwnerId: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  onDropAuthorization: PropTypes.func.isRequired,
  toggleStep: PropTypes.func.isRequired,
  closeOrToggleDialog: PropTypes.func.isRequired,
};

Layout.defaultProps = {
  owners: [],
  open: false,
  value: {},
  disabledPersonally: false,
};

export default Layout;
