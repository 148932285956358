import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import setComponentsId from "../../../helpers/setComponentsId";
import { withStyles } from "@material-ui/core";
import customInputStyle from "../../../variables/styles/customInputStyle";
import CheckboxesForm from "./CheckboxesForm";

class CheckboxesTree extends Component {
  state = { value: this.props.value || {}, updated: false };

  UNSAFE_componentWillReceiveProps({ value }) {
    const { updated } = this.state;
    if (value !== this.props.value && value !== this.state.value && !updated) {
      this.setState({ value, updated: true });
    }
  }

  onChange = (key, checked, error, index) => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (key) {
      value[key] = checked ? 1 : 0;
    }

    value.error = value.error || {};

    if (error) {
      value.error[index] = error;
    } else {
      delete value.error[index];
    }
    if (Object.keys(value.error).length === 0) {
      delete value.error;
    }
    this.setState({ value }, () => onChange(value));
  };

  render() {
    const { formControlProps, classes, errors, tree, setId, t } = this.props;
    const { value } = this.state;
    return (
      <Fragment>
        {value &&
          tree.map((item, i) => (
            <CheckboxesForm
              item={item}
              index={i}
              classes={classes}
              formControlProps={formControlProps}
              onChange={this.onChange}
              key={`${item.label}-${i}`}
              showErrors={!!errors}
              data={value}
              setId={(elementName) => setId(`checkkboxes-form-${elementName}`)}
            />
          ))}
        {!!errors && (
          <p className={classes.labelRootError}>{t("ALL_PAGE_ERROR")}</p>
        )}
      </Fragment>
    );
  }
}
CheckboxesTree.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tree: PropTypes.array,
  formControlProps: PropTypes.object,
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  value: PropTypes.object,
  t: PropTypes.func.isRequired,
};

CheckboxesTree.defaultProps = {
  onChange: undefined,
  errors: null,
  formControlProps: {},
  tree: [],
  value: null,
  setId: setComponentsId("checkboxes-tree"),
};
export default translate("Elements")(
  withStyles(customInputStyle)(CheckboxesTree)
);
