import { commitDocument, setWizardStates } from "../../../actions/claim";
import normalizeClaim from "../../../helpers/normalizeClaim";
import getWizardProps from "../../../helpers/getWizardProps";
// import getCourtId from "./getCourtId";

export async function updateDocument() {
  const {
    match: {
      params: { claimId },
    },
  } = getWizardProps();
  const claim = await this.loadClaim(claimId);
  return setWizardStates({ claim });
}

export async function handleCommitDocument() {
  const {
    claim: propsClaim,
    claim: { id },
  } = getWizardProps();
  // const courtId = await getCourtId();
  const courtId = propsClaim.courtId;
  const claim = await commitDocument(id, { courtId });
  return setWizardStates({
    claim: { ...propsClaim, ...normalizeClaim(claim) },
    showSignModal: false,
    claimCommited: true,
    blockScreen: false,
  });
}
