import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core";
import setComponentsId from "../../helpers/setComponentsId";
import { normalizeFileName, testFileName } from "../../helpers/testFile";
import customInputStyle from "../../variables/styles/customInputStyle";
import selectFilesDialogStyle from "../../variables/styles/selectFilesDialogStyle";
import SelectFilesDialogLayout from "../../components/SelectFilesDialog/SelectFilesDialogLayout";

const defaultFileLimit = {
  types: {
    total: "image",
    formats: ["application/pdf"],
  },
  accept: "image/*,application/pdf",
  maxSize: 10485760,
};

class SelectFilesDialog extends React.Component {
  state = {
    file: null,
    busy: false,
    name: "",
    showEmptyNameError: false,
    error: "",
    openVerifiedDialog: false,
    nameError: "",
  };

  onDrop = (files, rejected) => {
    const { t } = this.props;
    let error = "";
    if (rejected.length) {
      const { fileLimit } = this.props;
      const rejectedFile = rejected.shift();
      const { maxSize } = fileLimit || this.props;

      if (maxSize < rejectedFile.size) {
        error = t("ERROR_SIZE_FILE", {
          maxSize: (maxSize / 1048576).toFixed(1),
        });
      } else {
        error = t("ERROR_TYPE_FILE");
      }
    } else {
      const file = files.shift();

      if (file.name) {
        error = testFileName(normalizeFileName(file.name), t);
      }

      if (!error) {
        this.setState({
          file,
          name: file.name,
          error: "",
        });
      }
    }
    this.setState({ error });
  };

  handleChange = ({ target: { value } }) => {
    const { t } = this.props;
    let error = "";
    if (value) {
      error = testFileName(normalizeFileName(value), t);
    }
    if (error) {
      this.setState({
        name: normalizeFileName(value),
        nameError: error,
        showEmptyNameError: false,
      });
    } else {
      this.setState({
        name: normalizeFileName(value),
        nameError: "",
        showEmptyNameError: false,
      });
    }
  };

  removeFile = () => this.setState({ file: null });

  uploadFile = async () => {
    const { uploadAction, onUpload } = this.props;
    const { file, name } = this.state;
    this.setState(
      {
        error: null,
        busy: true,
        openVerifiedDialog: false,
      },
      async () => {
        try {
          file.userFileName = normalizeFileName(name);
          const {
            acceptedFiles: [aFile],
          } = (await uploadAction([file])) || {};

          //здесь всегда 1 файл, так что некоторые параметры всегда берем из
          onUpload({
            attachId: aFile.attachId,
            fileLink: aFile.uploadedFileLink || aFile.fileLink || aFile.link,
            name:
              normalizeFileName(aFile.userFileName) ||
              normalizeFileName(file.name) ||
              normalizeFileName(aFile.fileName),
            fileName: normalizeFileName(file.name),
            userFileName:
              normalizeFileName(aFile.userFileName) ||
              normalizeFileName(file.name),
            size: file.size,
            mimeType: file.type,
          });

          return this.setState({
            file: null,
            name: "",
            busy: false,
          });
        } catch (e) {
          return this.setState({
            error: e.message,
            name: "",
            busy: false,
          });
        }
      }
    );
  };

  toggleVerifiedDialog = () =>
    this.setState({ openVerifiedDialog: !this.state.openVerifiedDialog });

  handleDone = () => {
    const { uploadAction, onUpload, claim } = this.props;
    const name = this.state.name.trim();
    if (!uploadAction || !onUpload) {
      return;
    }

    if (name) {
      this.setState({ name });
    }

    if (!name) {
      this.setState({ showEmptyNameError: true });
    } else if (claim) {
      this.toggleVerifiedDialog();
    } else {
      this.uploadFile();
    }
  };

  handleClose = () => {
    const { busy } = this.state;
    if (busy) {
      return;
    }
    const { handleClose } = this.props;
    handleClose && handleClose();
    this.setState({
      file: null,
      name: "",
    });
  };

  render() {
    return (
      <SelectFilesDialogLayout
        {...this.props}
        {...this.state}
        handleClose={this.handleClose}
        handleChange={this.handleChange}
        removeFile={this.removeFile}
        onDrop={this.onDrop}
        handleDone={this.handleDone}
        toggleVerifiedDialog={this.toggleVerifiedDialog}
        uploadFile={this.uploadFile}
      />
    );
  }
}

SelectFilesDialog.propTypes = {
  uploadAction: PropTypes.func,
  onUpload: PropTypes.func,
  fileName: PropTypes.string,
  fileLimit: PropTypes.object,
  t: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  accept: PropTypes.string,
  setId: PropTypes.func,
  maxSize: PropTypes.number,
  claim: PropTypes.bool,
};

SelectFilesDialog.defaultProps = {
  uploadAction: undefined,
  onUpload: undefined,
  fileName: undefined,
  fileLimit: defaultFileLimit,
  handleClose: undefined,
  open: false,
  accept: undefined,
  setId: setComponentsId("select-files-dialog"),
  maxSize: undefined,
  claim: false,
};

const styled = withStyles({
  ...customInputStyle,
  ...selectFilesDialogStyle,
})(SelectFilesDialog);
const translated = translate("Elements")(styled);

const mapStateToProps = ({ dictionary: { regions, courts } }) => ({
  regions,
  courts,
});

// decorate and export
export default connect(mapStateToProps)(translated);
