import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Icon, List, ListItem, ListItemText } from "@material-ui/core";
import cx from "classnames";
// import {Tooltip} from 'components';

const TemplatesCategory = ({
  cat,
  classes,
  open,
  groupIndex,
  index,
  setId,
  searchText,
  children,
  toggleGroup,
}) => (
  <List
    key={cat.categoryId}
    className={cx(classes.groupWrap, classes.contentWrap)}
    id={setId(`cat-${index + 1}-${cat.categoryId}`)}
  >
    <ListItem
      className={classes.groupTitle}
      button={true}
      onClick={toggleGroup(cat.categoryId, open, groupIndex)}
      id={setId(`cat-${index + 1}-toggle-${cat.categoryId}`)}
      title={cat.name}
    >
      <Icon>folder</Icon>
      <ListItemText
        primary={cat.name}
        id={setId(`cat-${index + 1}-name-${cat.categoryId}`)}
      />
      {!searchText && <Icon>{open ? "arrow_drop_up" : "arrow_drop_down"}</Icon>}
    </ListItem>

    {open && (
      <ListItem
        className={classes.groupWrap}
        id={setId(`cat-${index + 1}-content-${cat.categoryId}`)}
      >
        <div>{children}</div>
      </ListItem>
    )}
  </List>
);

TemplatesCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  cat: PropTypes.object.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  toggleGroup: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

TemplatesCategory.defaultProps = {
  children: <Fragment />,
};

export default TemplatesCategory;
