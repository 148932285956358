import SelectCourt from "./SelectCourt";
import SelectUser from "./SelectUser";
import Textarea from "./Textarea";
import Payment from "./Payment";
import Money from "./Money";
import Date from "./Date";
import SelectFiles from "./SelectFiles";
import StringElement from "./StringElement";
import IntegerElement from "./IntegerElement";
import CheckboxesTree from "./CheckboxesTree";
import SelectExecutive from "./SelectExecutive";

export default {
  SelectCourt,
  SelectUser,
  Textarea,
  Payment,
  Money,
  Date,
  SelectFiles,
  StringElement,
  IntegerElement,
  CheckboxesTree,
  Checkboxestree: CheckboxesTree,
  SelectExecutive,
};
