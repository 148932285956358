import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import cx from "classnames";
import { Button } from "../../../components";

const FavoritesDialog = ({
  t,
  error,
  closeDialog,
  deleteErrorFromFavorite,
  classes,
  setId,
}) => (
  <Dialog
    open={!!error}
    onClose={closeDialog}
    id={setId("dialog-wrapper")}
    className={cx(classes.dialog, classes.fullWidth)}
  >
    <DialogTitle
      id={setId("dialog-title")}
      className={classes.dialogContentWrappers}
    >
      {t("NEED_ACCESS")}
    </DialogTitle>
    <DialogContent>{t("DELETE_FROM_FAVORITES")}</DialogContent>
    <DialogActions
      className={cx(classes.dialogContentWrappers, classes.mobilePadding)}
      id={setId("dialog-actions")}
    >
      <Button
        color="yellow"
        id={setId("documents-list-button")}
        setId={(elementName) => setId(`documents-list-${elementName}`)}
        onClick={deleteErrorFromFavorite}
      >
        {t("YES")}
      </Button>
      <Button
        color="danger"
        onClick={closeDialog}
        id={setId("close-button")}
        className={classes.rightButton}
        setId={(elementName) => setId(`close-${elementName}`)}
      >
        {t("NO")}
      </Button>
    </DialogActions>
  </Dialog>
);

FavoritesDialog.propTypes = {
  error: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  deleteErrorFromFavorite: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default FavoritesDialog;
