import PropTypes from "prop-types";
import { Toolbar } from "@material-ui/core";

import Filters from "./Filters";
import setComponentsId from "../../../helpers/setComponentsId";

const ExecutiveDocsToolbar = ({
  classes,
  setId,
  t,
  dataSource,
  docCategory,
  load,
  courts,
  roles,
  vpStatuses,
  userId,
  returnObject,
}) => {
  return (
    <Toolbar className={classes.toolbar} disableGutters={true}>
      <Filters
        t={t}
        classes={classes}
        setId={setId}
        dataSource={dataSource}
        statuses={vpStatuses}
        load={load}
        roles={roles}
        courts={courts}
        userId={userId}
        returnObject={returnObject}
      />
    </Toolbar>
  );
};

ExecutiveDocsToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  docCategory: PropTypes.number.isRequired,
  load: PropTypes.func.isRequired,
};

ExecutiveDocsToolbar.defaultProps = {
  setId: setComponentsId("executivedocs-toolbar"),
};

export default ExecutiveDocsToolbar;
