import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import {
  requestClaimTemplates,
  requestClaimTemplatesCat,
} from "../../actions/claim";
import { requestjusticeTypes, requestCourts } from "../../actions/dictionary";

export default function UNSAFE_componentWillMount() {
  const { categories, courts, jurisdictionTypes, templates } = this.props;
  const actions = [];
  if (!templates) actions.push(requestClaimTemplates);
  if (!categories) actions.push(requestClaimTemplatesCat);
  if (!courts) actions.push(requestCourts);
  if (!jurisdictionTypes) actions.push(requestjusticeTypes);
  Promise.all(actions.map((action) => action()));
}

export function setTemplates() {
  const {
    templates,
    docCategory,
    courtId,
    courts,
    jurisdictionTypes,
    categories,
    appealCourt,
    causeActionTypes,
    procCourtTypeId,
    jurisdictionTypeId,
  } = this.props;
  const unCatTemplates = [];
  let count = 0;

  const temp = (templates || []).reduce((acc, item) => {
    let template = null;
    const {
      docTypeCourts,
      docCategory: itemDocCategory,
      causeActionType,
    } = item;
    const clearName = item.name.replace(/\s+/g, " ");
    item = { ...item, text: undefined, name: clearName };
    const isCurrentCat = docCategory === itemDocCategory;
    if (courtId && jurisdictionTypes && isCurrentCat) {
      const foundCourt = (courts || []).find(({ id }) => id === courtId);
      const foundActionType = !!causeActionTypes.includes(causeActionType);
      let currentCourt = foundCourt;
      let currentCauseActionType = true;
      const isAppealClaim = causeActionType === 1 || causeActionType === 2;
      if (isAppealClaim) {
        currentCourt = appealCourt;
        currentCauseActionType =
          causeActionType === 1 ? procCourtTypeId === 2 : procCourtTypeId === 3;
      }
      if (
        foundCourt &&
        currentCourt &&
        docTypeCourts &&
        Array.isArray(docTypeCourts) &&
        docTypeCourts.length > 0 &&
        foundActionType &&
        currentCauseActionType
      ) {
        docTypeCourts.forEach(
          ({ jurisdictionType, courtType, justiceType }) => {
            const isDouble = acc.some(
              ({ id, name, categoryId }) =>
                id === item.id ||
                (name === item.name && item.categoryId === categoryId)
            );
            if (!isDouble) {
              let jurisdictionIsCurrent =
                jurisdictionType === null ||
                jurisdictionTypeId === jurisdictionType;
              if (!jurisdictionTypeId && !jurisdictionIsCurrent) {
                const currentJur = Array.isArray(jurisdictionTypes)
                  ? jurisdictionTypes.find(({ id }) => id === jurisdictionType)
                  : [];
                jurisdictionIsCurrent =
                  currentJur.justiceType === currentCourt.justiceTypeId;
              }
              if (!isAppealClaim && !jurisdictionIsCurrent) {
                jurisdictionIsCurrent = jurisdictionType === 1;
              }
              if (
                jurisdictionIsCurrent &&
                (courtType === null ||
                  courtType === currentCourt.courtTypeId) &&
                (justiceType === null ||
                  justiceType === currentCourt.justiceTypeId)
              ) {
                template = item;
              }
            }
          }
        );
      }
    } else if (isCurrentCat && !courtId) {
      template = item;
    }
    if (template) {
      const foundCat = (categories || []).find(
        ({ categoryId }) => categoryId === item.categoryId
      );
      const arr = foundCat ? acc : unCatTemplates;
      arr.push(template);
      count += 1;
    }
    return acc;
  }, []);
  this.setState({ count, templates: temp, unCatTemplates });
}

export const propTypes = {
  templates: PropTypes.array,
  categories: PropTypes.array,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  docCategory: PropTypes.number,

  openCreateClaimDialog: PropTypes.bool.isRequired,
  handleClaimCreate: PropTypes.func.isRequired,
  toggleTemplateDialog: PropTypes.func.isRequired,
  jurisdictionTypes: PropTypes.array,
  courts: PropTypes.array,
  courtId: PropTypes.number,
  ownership: PropTypes.object,
  appealCourt: PropTypes.object,
  causeActionTypes: PropTypes.array,
  jurisdictionTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export const defaultProps = {
  categories: null,
  docCategory: 1,
  setId: setComponentsId("create-claim-dialog"),
  templates: null,
  jurisdictionTypes: null,
  courts: null,
  courtId: null,
  ownership: null,
  appealCourt: null,
  causeActionTypes: [],
  jurisdictionTypeId: null,
};

export const initialState = {
  searchText: "",
  openGroups: [],
  count: 0,
  templates: [],
  unCatTemplates: [],
  search: false,
};
