import React, { Fragment } from "react";
import { connect } from "react-redux";
import { translate } from "react-translate";

import {
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Divider,
  ListItemIcon,
  ListItemText,
  ButtonBase,
  IconButton,
  withStyles,
} from "@material-ui/core";

import { Add, Delete } from "@material-ui/icons";
import { requestUserMeta, addUserMeta } from "../../../actions/usermeta";

const styles = {
  root: {
    marginLeft: 24,
  },
  chip: {
    height: 25,
    marginLeft: 4,
    "& > span": {
      padding: "0 6px",
    },
  },
};

class FilterMenu extends React.Component {
  state = { anchorEl: null, ready: false, filterName: "" };

  UNSAFE_componentWillMount = async () => {
    const { userMetaData } = this.props;
    if (!userMetaData) {
      await requestUserMeta();
    }
    this.setState({ ready: true });
  };

  toggleMenu = ({ currentTarget }) => {
    const { anchorEl } = this.state;
    this.setState({
      anchorEl: anchorEl ? null : currentTarget,
    });
  };

  handleSetFilter = (option) => () => {
    const { dataSource, load } = this.props;
    dataSource.deserialize(option.data);
    (load || dataSource.load)();
    this.setState({ anchorEl: null });
  };

  handleFilterNameChange = ({ target: { value } }) => {
    this.setState({ filterName: value });
  };

  handleDeleteFilter = (index) => async () => {
    const {
      userMetaData: {
        meta: { registryFilters },
      },
    } = this.props;

    registryFilters.splice(index, 1);

    await addUserMeta({ meta: { registryFilters } });
    // await requestUserMeta();
  };

  handleCreateFilter = async () => {
    const { dataSource } = this.props;
    const { filterName } = this.state;

    const registryFilters = this.getFilterList();

    if (!filterName) {
      return;
    }

    this.setState({ filterName: "" });
    await addUserMeta({
      meta: {
        registryFilters: [
          {
            name: filterName,
            data: dataSource.getQueryString(),
          },
          ...registryFilters,
        ],
      },
    });
  };

  getFilterList() {
    const { userMetaData } = this.props;
    if (
      !userMetaData ||
      !userMetaData.meta ||
      !userMetaData.meta.registryFilters
    ) {
      return [];
    }

    const {
      meta: { registryFilters },
    } = userMetaData;
    return (registryFilters || []).filter(Boolean);
  }

  renderFilters() {
    const { t } = this.props;
    return this.getFilterList().map((option, index) => (
      <MenuItem key={index}>
        <ListItemText
          onClick={this.handleSetFilter(option)}
          primary={option.name || t("UNNAMED")}
        />
        <ListItemIcon>
          <IconButton
            style={{ marginRight: 0 }}
            onClick={this.handleDeleteFilter(index)}
          >
            <Delete />
          </IconButton>
        </ListItemIcon>
      </MenuItem>
    ));
  }

  render() {
    const { t, classes } = this.props;
    const { anchorEl, ready, filterName } = this.state;

    return (
      <Fragment>
        <ButtonBase onClick={this.toggleMenu} className={classes.root}>
          {t("MY_FILTERS")}
        </ButtonBase>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.toggleMenu}
        >
          <Toolbar>
            <TextField
              id="standard-name"
              placeholder={t("FILTER_NAME")}
              value={filterName}
              onChange={this.handleFilterNameChange}
              margin="none"
            />
            <IconButton onClick={this.handleCreateFilter}>
              <Add />
            </IconButton>
          </Toolbar>
          {!!this.getFilterList().length && <Divider />}
          {ready && this.renderFilters()}
        </Menu>
      </Fragment>
    );
  }
}
const translated = translate("SearchPage")(FilterMenu);
const styled = withStyles(styles)(translated);
export default connect(({ usermeta: { userMetaData } }) => ({
  userMetaData,
}))(styled);
