import { ChipsItem } from "../Chips";
import { RadioItem } from "../CustomRadioGroup";

export const IPN_REGEXP = /^[a-zA-Zа-яА-ЯёЁ0-9]{8,10}$/; // ^[A-Z], [^\s], ^[0-9]{9,10}, /^[A-Z0-9]{8,10}/g
export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
export const EDRPOU_REGEXP = /^[0-9]{8,10}$/;
export const PHONE_REGEXP = /^380[0-9]{9}$/;
export const PHONE_MASK = "380999999999";
export type ItemTypes = "chips" | "string" | "date" | "checkbox" | "radio";
export type TextItemOptions = {
  maxLen?: number;
  regexp?: RegExp;
  mask?: string;
};
export type ChipsItemOptions = {
  items: ChipsItem[];
};
export type RadioItemOptions = {
  items: RadioItem[];
};

export interface BaseItem<T> {
  type: ItemTypes;
  disabled?: boolean;
  visible: boolean;
  name: keyof T;
  required?: boolean;
  label?: string;
  helperText?: string;
  options?: TextItemOptions | ChipsItemOptions;
}

export type RenderControlValueChanged = (key: string, value: any) => void;
