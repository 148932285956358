import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core";
import CustomDatePicker from "../../components/CustomInput/CustomDatePicker";
import TextFieldDummy from "../../components/CustomInput/TextFieldDummy";

import customInputStyle from "../../variables/styles/customInputStyle";

class DatePickerForm extends React.Component {
  state = { value: this.props.value };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value !== this.state.value) {
      this.setState({ value });
    }
  }

  handleChange = (value) => {
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange && onChange({ target: { value: value || undefined } });
    });
  };

  render() {
    const {
      sample,
      errors,
      label,
      margin,
      readOnly,
      InputProps,
      placeholder,
      setId,
      incomingFormat,
    } = this.props;
    const { value } = this.state;

    const Component = readOnly ? TextFieldDummy : CustomDatePicker;
    return (
      <Component
        incomingFormat={incomingFormat || "YYYY-MM-DD"}
        placeholder={placeholder}
        label={label}
        value={value}
        date={value}
        margin={margin}
        onChange={this.handleChange}
        helperText={!readOnly && (sample || errors)}
        error={!!errors}
        InputProps={InputProps}
        id={setId("input")}
      />
    );
  }
}
DatePickerForm.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  margin: PropTypes.string,
  incomingFormat: PropTypes.string,
  placeholder: PropTypes.string,
  sample: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setId: PropTypes.func,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DatePickerForm.defaultProps = {
  label: "",
  margin: "none",
  incomingFormat: "YYYY-MM-DD",
  placeholder: "",
  onChange: undefined,
  sample: "",
  errors: null,
  readOnly: false,
  InputProps: {},
  setId: setComponentsId("date"),
  value: "",
};

const styled = withStyles(customInputStyle)(DatePickerForm);
export default translate("Elements")(styled);
