import { routerActions } from "react-router-redux";
import { requestPermissionsList } from "../../../actions/permissions";
import { requestClaimTemplates } from "../../../actions/claim";
import {
  requestActiveCase,
  requestProceedingsCourts,
  requestCasesCourts,
  clearCases,
  // requestJudgeCasesCourts,
  requestDocument,
} from "../../../actions/cases";
import { setSplitPosition } from "../../../helpers/splitPosition";
import promiseChain from "../../../helpers/promiseChain";
import DataSource from "./dataSource";
import { initialState } from "./stateAndProps";
import { replaceHistory } from "../replaceHistory";

const {
  caseDataSource,
  caseDocDataSource,
  procDataSource,
  procDocDataSource,
  myProcDocDataSource,
  myMemberRolesDataSource,
  myCaseDocDataSource,
} = DataSource;

const getIdFromParam = (param) => (param ? param.split("=")[1] : "");

export default async function componentDidMount() {
  const {
    dispatch,
    match: {
      params: { caseId, proceedingId, documentId },
    },
    page,
    role,
    t,
    // userId,
    templates,
  } = this.props;
  let { activeCase } = this.props;
  this.setState({ init: true });
  setSplitPosition("caseListSplit")("50%");
  caseDataSource.reset();
  // caseDataSource.setValue("id", "my");
  caseDataSource.setValue("id", role === "judge" ? "my_as_judge" : "my");
  myMemberRolesDataSource.baseUrl =
    role === "judge" ? "api/cases/my_judge_roles" : "api/cases/my_member_roles";

  const actions = [
    clearCases(),
    myMemberRolesDataSource.load(),
    requestCasesCourts(),
    // page === "cases" ? requestCasesCourts() : requestJudgeCasesCourts(),
  ];

  const id = getIdFromParam(caseId);
  const procId = getIdFromParam(proceedingId);
  const docId = getIdFromParam(documentId);
  const searchParams = new URLSearchParams(window.location.search);

  if (role === "representative" || role === "legalEntityAgent") {
    actions.push(requestPermissionsList());
  }
  if (page === "cases") actions.push(requestProceedingsCourts());
  if (!caseId || !id) actions.push(dispatch(routerActions.replace(`/${page}`)));

  const { history } = this.props;
  const historyData = history?.location?.state?.dataFromNotification;
  if (historyData) {
    activeCase = historyData;
  } else if (id) {
    activeCase = await requestActiveCase(id);
  }

  if (!templates) actions.push(requestClaimTemplates());
  // if (docId) dispatch(routerActions.replace(`/document/${docId}`));

  Promise.all(actions).then(() => {
    const isCurrentCase =
      id && activeCase && activeCase.id && activeCase.id === id;

    /*Если это список дел то isCurrentCase false, если true то значит мы зашли внутрь какого-то дела*/
    if (isCurrentCase) {
      const display =
        proceedingId === "documents" ? "documentCaseList" : "procList";
      myCaseDocDataSource.setFilter("case", id);
      caseDocDataSource.setValue("params", { case_id: id });

      const procDocDataSourceParams = { proceeding_id: procId };
      if (sessionStorage.getItem("claim_documentId")) {
        procDocDataSourceParams.documentId =
          sessionStorage.getItem("claim_documentId");
        sessionStorage.removeItem("claim_documentId");
      }

      let promisses = [
        () =>
          this.setState({
            ...initialState,
            init: true,
            loading: true,
            checked: id,
            activeCase,
            activeCaseError: "",
            display,
          }),
        () => procDataSource.load(id),
        () => myCaseDocDataSource.load(),
        () => caseDocDataSource.load(),
      ];
      let dataSource = procDataSource;

      /*Если procId значит мы внутри производства по делу*/
      if (procId) {
        procDocDataSource.setValue("params", procDocDataSourceParams);
        myProcDocDataSource.setFilter("proceeding", procId);
        dataSource = procDocDataSource;
        promisses = [
          ...promisses,
          async () => {
            const data = await procDocDataSource.load();
            const docID = procDocDataSourceParams.documentId;
            if (docID) {
              procDocDataSource.setValue("page", data.meta.pagination.page);
              delete procDocDataSourceParams.documentId;
              procDocDataSource.setValue("params", procDocDataSourceParams);
              if (!data.req.length) {
                return procDocDataSource.load();
              }
              this.onViewDocument(docID)();
            }
            return data;
          },
          () => myProcDocDataSource.load(),
          () => {
            let proceedingsList = [];
            const { history } = this.props;
            const historyData = history?.location?.state?.dataFromNotification;

            if (historyData) {
              proceedingsList = historyData;
            } else {
              proceedingsList = this.props.proceedingsList;
            }

            // const { proceedingsList } = this.props;
            const activeProc = Array.isArray(proceedingsList)
              ? proceedingsList.find((proc) => procId === proc.id)
              : null;
            return this.setState({
              display: "documentProcList",
              procChecked: procId,
              activeProc,
            });
          },
          () => {
            const viewDocId = searchParams.get("document");
            if (viewDocId) {
              this.onViewDocument(viewDocId)();
            }
          },
        ];
      }
      /*Если docId значит мы внутри производства по делу и открыт какой-то документ*/
      if (docId) {
        promisses = [
          ...promisses,
          () => requestDocument(docId),
          (viewDocument) =>
            this.setState({
              viewDocument,
              scanDocumentId: docId,
              display: "documentView",
            }),
        ];
      }
      promisses.push(() =>
        this.setState({
          loading: false,
          init: false,
          dataSource,
        })
      );
      promiseChain(promisses);
    } else if (activeCase && activeCase.message) {
      const { message } = activeCase;
      const activeCaseError =
        message === "User should have access to case."
          ? t("NEED_ACCESS")
          : t("UNKNOWN_ERROR", { error: message });
      this.setState({ init: false });
      this.setState({ activeCaseError }, this.setDefaultProps());
    } else {
      caseDataSource.load().then(() =>
        this.setState({
          loading: false,
          init: false,
        })
      );
    }
  });

  window.addEventListener(
    "beforeunload",
    () => replaceHistory(this.props.history),
    true
  );
}

export function setDefaultProps(callback) {
  const { dispatch, page } = this.props;
  const { activeCaseError } = this.state;
  dispatch(routerActions.replace(`/${page}`));
  this.setState(
    {
      ...initialState,
      activeCaseError,
    },
    () => {
      caseDataSource.reset();
      caseDataSource.load().then(
        () => this.setState({ loading: false }),
        () => callback
      );
    }
  );
}
