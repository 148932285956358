import { useState } from "react";
import { Button } from "../../../components";
import { PERSON_TYPE_CODES } from "../constants/person.type.codes";
import humanDateFormat from "../../../helpers/humanDateFormat";
import {
  InfoBlockWrapper,
  InfoBlock as infoBlock,
} from "../layout/layout.components";

export const CreditorInfo = ({ classes, creditors, t }) => {
  const [isOpenCreditors, setIsOpenCreditors] = useState(false);

  return (
    <InfoBlockWrapper
      classes={classes}
      title={t("CREDITOR")}
      button={
        creditors?.length > 1 && (
          <Button
            onClick={() => setIsOpenCreditors(!isOpenCreditors)}
            variant="text"
            color="transparent"
            autoFocus={true}
            size="small"
          >
            {t(!isOpenCreditors ? "SHOW_ALL" : "HIDE_ALL")}
          </Button>
        )
      }
    >
      <div>
        {creditors?.length && isOpenCreditors ? (
          <>
            {creditors.map((creditor) => {
              return infoBlock(classes, "", [
                PERSON_TYPE_CODES.isPerson.includes(creditor?.personTypeCode)
                  ? [t("PERSON_NAME_LABEL"), creditor?.name]
                  : [t("ORG_NAME_LABEL"), creditor?.name],
                PERSON_TYPE_CODES.isPerson.includes(creditor?.personTypeCode)
                  ? [t("PERSON_CODE_LABEL"), creditor?.code]
                  : [t("ORG_CODE_LABEL"), creditor?.code],
                PERSON_TYPE_CODES.isPerson.includes(creditor?.personTypeCode)
                  ? [t("BIRTH_DATE"), humanDateFormat(creditor?.birthDate)]
                  : [t("REGISTRY_DATE"), humanDateFormat(creditor?.birthDate)],
                [t("PERSON_TYPE_NAME"), creditor?.personTypeName],
                [
                  t("IS_ACTIVE_LABEL"),
                  creditor?.isActive
                    ? t("ACTIVE_STATE")
                    : t("NOT_ACTIVE_STATE"),
                ],
                [
                  t("ADDRESS_LABEL"),
                  `${creditor?.postIndex || ""} ${
                    creditor?.address || ""
                  }`.trim(),
                ],
                [t("PHONE_LABEL"), creditor?.phone],
              ]);
            })}
          </>
        ) : (
          creditors?.length > 0 && (
            <>
              {infoBlock(classes, "", [
                PERSON_TYPE_CODES.isPerson.includes(
                  creditors?.[0]?.personTypeCode
                )
                  ? [t("PERSON_NAME_LABEL"), creditors?.[0]?.name]
                  : [t("ORG_NAME_LABEL"), creditors?.[0]?.name],
                PERSON_TYPE_CODES.isPerson.includes(
                  creditors?.[0]?.personTypeCode
                )
                  ? [t("PERSON_CODE_LABEL"), creditors?.[0]?.code]
                  : [t("ORG_CODE_LABEL"), creditors?.[0]?.code],
                PERSON_TYPE_CODES.isPerson.includes(
                  creditors?.[0]?.personTypeCode
                )
                  ? [
                      t("BIRTH_DATE"),
                      humanDateFormat(creditors?.[0]?.birthDate),
                    ]
                  : [
                      t("REGISTRY_DATE"),
                      humanDateFormat(creditors?.[0]?.birthDate),
                    ],
                [t("PERSON_TYPE_NAME"), creditors?.[0]?.personTypeName],
                [
                  t("IS_ACTIVE_LABEL"),
                  creditors?.[0]?.isActive
                    ? t("ACTIVE_STATE")
                    : t("NOT_ACTIVE_STATE"),
                ],
                [
                  t("ADDRESS_LABEL"),
                  `${creditors?.[0]?.postIndex || ""} ${
                    creditors?.[0]?.address || ""
                  }`.trim(),
                ],
                [t("PHONE_LABEL"), creditors?.[0]?.phone],
              ])}
            </>
          )
        )}
      </div>
    </InfoBlockWrapper>
  );
};
