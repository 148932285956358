// ts-nocheck
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  withStyles,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import customInputStyle from "../../variables/styles/customInputStyle";
import Chips from "../Chips/Chips";
import { CustomDatePicker } from "../index";
import CustomRadioGroup from "../CustomRadioGroup";
import {
  BaseItem,
  ChipsItemOptions,
  RadioItemOptions,
  RenderControlValueChanged,
  TextItemOptions,
} from "./types";

const Masked = (props: any) => (
  <InputMask {...props} maskChar="" inputRef={props.ref} />
);

type Props = {
  classes?: any;
  t: (s: string) => string;
  onValueChanged?: RenderControlValueChanged;
  value: any;
  errorMessage: string;
  name: string;
} & BaseItem<any>;

const RenderControl = ({
  t,
  classes,
  name,
  label,
  helperText,
  required,
  type,
  options,
  onValueChanged = () => {},
  value,
  disabled,
  errorMessage,
}: Props) => {
  function innerRender() {
    if (type === "chips")
      return (
        <Chips
          items={(options as ChipsItemOptions).items}
          value={value}
          t={t}
          onChanged={(value) => !disabled && onValueChanged(name, value)}
        />
      );

    if (type === "string")
      return (
        <TextField
          name={name}
          label={t(label!)}
          disabled={disabled}
          required={required}
          value={(!!value && value) || ""}
          error={!!errorMessage}
          onChange={({ target: { value, selectionStart } }: any) => {
            onValueChanged(name, value);
          }}
          margin="dense"
          helperText={errorMessage || (helperText ? t(helperText) : "")}
          inputProps={{
            mask: (options as TextItemOptions).mask,
          }}
          InputProps={{
            inputComponent: Masked,
          }}
        />
      );

    if (type === "date") {
      return (
        <CustomDatePicker
          label={t(label!) + (required ? " *" : "")}
          margin="dense"
          onChange={(value: string) => onValueChanged(name, value)}
          date={value || ""}
          required={required}
          disabled={disabled}
          error={!!errorMessage}
          helperText={errorMessage || (helperText ? t(helperText) : "")}
          minDate="01/01/1900"
          // maxDate={maxDate}
        />
      );
    }
    if (type === "checkbox") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              disabled={disabled}
              onChange={({ target: { checked } }) =>
                onValueChanged(name, checked)
              }
              color="primary"
              classes={{ checked: classes.checked }}
            />
          }
          label={t(label!)}
        />
      );
    }

    if (type === "radio") {
      return (
        <CustomRadioGroup
          onChange={onValueChanged}
          disabled={disabled}
          value={value}
          items={(options as RadioItemOptions).items}
          name={name}
          label={label!}
          t={t}
        />
      );
    }

    return type;
  }

  return (
    <FormControl
      fullWidth={true}
      className={classes.formControl}
      margin="dense"
      key={name}
    >
      {innerRender()}
    </FormControl>
  );
};

const styles = {
  ...customInputStyle,
} as const;

export default withStyles(styles)(RenderControl);
