import PropTypes from "prop-types";
import { Button } from "../../../components";

const CreateButton = ({ t, classes, toggleDialog, setId, auth }) => {
  return (
    <>
      <Button
        color="yellow"
        onClick={toggleDialog(
          (!auth?.info.companyName && !auth?.info.legalEntityId) ||
            !auth?.info.isLegalEntityHead
            ? "addShare"
            : "warningShare"
        )}
        id={setId("toolbar-add-button")}
        setId={(elmentName) => setId(`toolbar-add-${elmentName}`)}
        className={classes.mobileButtonFullWidth}
      >
        {t("ADD_SHARE")}
      </Button>
    </>
  );
};

CreateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

export default CreateButton;
