import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { translate } from "react-translate";

import { withStyles } from "@material-ui/core";

import { RegularCard } from "../../../components";
import AttachList from "../../../components/Attach/AttachList";

import attachesStyles from "../../../variables/styles/attaches";
import attachesWizardStep from "../../../variables/styles/attachesWizardStep";

const getAttachesList = ({ digitalDocumentData, attaches }) => {
  if (!digitalDocumentData) {
    return [];
  }

  const { payment, attaches: userAttaches } = digitalDocumentData || {};
  return (attaches || []).reduce((acc, item) => {
    if (item) {
      let found = (userAttaches || []).find(
        (attach) => attach && attach.attachId === item.attachId
      );
      if (
        !found &&
        payment &&
        payment.benefits &&
        payment.benefits.confirmationFiles &&
        !!payment.benefits.confirmationFiles.length
      ) {
        found = payment.benefits.confirmationFiles.find(
          (attach) => attach && attach.attachId === item.attachId
        );
      }
      if (found) item = { ...item, ...found };
      acc.push(item);
    }
    return acc;
  }, []);
};

const Attaches = ({ t, handleDownload, requestPreview, setId, claim }) => (
  <RegularCard
    headerColor="primary"
    cardTitle={t("ATTACHES")}
    setId={setId}
    content={
      <AttachList
        cols={5}
        attaches={getAttachesList(claim)}
        handleDownload={handleDownload}
        requestPreview={requestPreview}
        setId={setId}
      />
    }
  />
);

Attaches.propTypes = {
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,

  handleDownload: PropTypes.func,
  requestPreview: PropTypes.func,
  claim: PropTypes.object,
};

Attaches.defaultProps = {
  setId: setComponentsId("attaches"),

  handleDownload: undefined,
  requestPreview: undefined,
  claim: { attaches: [], digitalDocumentData: { attaches: [] } },
};

const styled = withStyles({
  ...attachesStyles,
  ...attachesWizardStep,
})(Attaches);
const translated = translate("ClaimWizard")(styled);

// decorate and export
export default translated;
