import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Button } from "../../../components";
import handleFinish from "../decorators/handleFinish";
import { handleNext, handleBack } from "../decorators/stepActions";

const Actions = ({
  t,
  classes,
  setId,
  activeStepId,
  stepOrders,
  claim,
  busy,
  saving,
  claimSaved,
  rootComponent,
  redirectTo,
  template: {
    jsonSchema: { useEncryption },
  },
}) => {
  const isLastStep = activeStepId === stepOrders.length - 1;
  const lastStepDisabled =
    isLastStep && ([3, 4].indexOf(claim.state) >= 0 || busy);
  const onClickNext = isLastStep ? handleFinish : handleNext;
  const disabledNext = (lastStepDisabled || saving) && isLastStep;

  return (
    <div
      className={classes.actions}
      id={setId("actions")}
      title={busy ? t("LOADING") : ""}
    >
      <div>
        {activeStepId > 0 && (
          <Button
            color="transparent"
            onClick={handleBack}
            className={classes.backButton}
            disabled={saving || busy}
            id={setId("back-button")}
            setId={(elementName) => setId(`back-${elementName}`)}
          >
            {t("BACK")}
          </Button>
        )}
        <Button
          color="yellow"
          onClick={onClickNext}
          disabled={disabledNext || saving || busy}
          id={setId("next-button")}
          setId={(elementName) => setId(`next-${elementName}`)}
        >
          {claim.category === 8 && isLastStep && t("CREATE_DECISION")}
          {claim.category !== 8 && isLastStep && t("CREATE_DOCUMENT")}
          {!isLastStep && t("NEXT")}
        </Button>
      </div>
      <div className={classes.preloaderText} id={setId("saving-description")}>
        {useEncryption && <p>{t("ENCRYPTED_DOCUMENT")}</p>}
        {(saving || busy) && t("SAVING_PROCESS")}
        {!saving && !busy && claimSaved && rootComponent && (
          <Fragment>
            <span>{t("ALL_DATA_SAVED")}&nbsp;</span>
            <Link to={redirectTo.getUrl(claim)}>
              {t(`${redirectTo.title}_BACK_LINK`)}
            </Link>
          </Fragment>
        )}
      </div>
    </div>
  );
};

Actions.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  claim: PropTypes.object,
  busy: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  claimSaved: PropTypes.bool.isRequired,
  stepOrders: PropTypes.array.isRequired,
  activeStepId: PropTypes.number.isRequired,
  rootComponent: PropTypes.string,
  template: PropTypes.object,
  redirectTo: PropTypes.object.isRequired,
};

Actions.defaultProps = {
  claim: null,
  rootComponent: "",
  template: { jsonSchema: { useEncryption: false } },
};

export default Actions;
