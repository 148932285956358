import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const JusticeTypeFilterChip = ({ t, value, justiceTypes, ...rest }) => (
  <Chip
    label={`${t("JUSTICE_TYPE")}: ${
      (justiceTypes || []).find(({ id }) => id === value).name
    }`}
    {...rest}
  />
);

JusticeTypeFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  justiceTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

JusticeTypeFilterChip.defaultProps = {
  value: "",
  justiceTypes: [],
};

const translated = translate("ClaimList")(JusticeTypeFilterChip);
export default connect(({ dictionary: { justiceTypes } }) => ({
  justiceTypes,
}))(translated);
