import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core/styles";

import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FilterInput from "../../../components/FilterInput";
import customInputStyle from "../../../variables/styles/customInputStyle";

import claimStatuses, { sortStatuses } from "../../../variables/claimStatuses";
import DateRangePicker from "../../../components/CustomInput/DateRangePicker";

import StatusChip from "../../../components/StatusChip";
import Status from "./Status";
import caseFields from "./Tables/caseFields";
import MyCaseMemberStatus from "./MyCaseMemberStatus";

const Filters = ({
  classes,
  t,
  dataSource,
  setId,
  statuses,
  loadData,
  display,
  courtTypes,
  justiceTypes,
  courts,
}) => (
  <FilterInput
    dataSource={dataSource}
    load={loadData}
    setId={setId}
    statuses={
      display === "procList" ? statuses : sortStatuses(t, claimStatuses, 6)
    }
    courtTypes={courtTypes}
    justiceTypes={justiceTypes}
    courts={courts}
  >
    {Array.isArray(caseFields[display].filters) &&
      caseFields[display].filters.map((filterName) => {
        switch (filterName) {
          case "claimStatus":
            return (
              <TextField
                key={setId("status-filter")}
                select={true}
                name="state"
                label={t("DOCUMENT_STATUS")}
                margin="dense"
                id={setId("status-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
                SelectProps={{
                  id: setId("select-state-filter"),
                  SelectDisplayProps: {
                    id: setId("select-state-filter-wrapper"),
                  },
                }}
              >
                {sortStatuses(t, claimStatuses).map(
                  ({ id, name, value }, index) => (
                    <MenuItem
                      key={index}
                      value={value}
                      id={setId(`status-${id}`)}
                    >
                      <StatusChip status={id} statusText={name} tableChip />
                    </MenuItem>
                  )
                )}
              </TextField>
            );
          case "status":
            return (
              <TextField
                key={setId("status-filter")}
                select={true}
                name="state"
                label={t("DOCUMENT_STATUS")}
                margin="dense"
                id={setId("status-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
                SelectProps={{
                  id: setId("select-state-filter"),
                  SelectDisplayProps: {
                    id: setId("select-state-filter-wrapper"),
                  },
                }}
              >
                {statuses.map((status, index) => (
                  <MenuItem
                    key={index}
                    value={status.id}
                    id={setId(`status-filter-${index + 1}`)}
                  >
                    <Status status={status} setId={setId} />
                  </MenuItem>
                ))}
              </TextField>
            );
          case "courtId":
            return (
              <TextField
                key={setId("courtId-filter")}
                select={true}
                name="courtId"
                label={t("CASE_COURT")}
                margin="dense"
                id={setId("courts-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
                SelectProps={{
                  id: setId("select-state-filter"),
                  SelectDisplayProps: {
                    id: setId("select-state-filter-wrapper"),
                  },
                }}
              >
                {Array.isArray(courts) &&
                  courts.map(({ id, name }, index) => (
                    <MenuItem
                      key={id}
                      value={id}
                      id={setId(`courts-filter-${index + 1}`)}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </TextField>
            );
          case "courtType":
            return (
              <TextField
                key={setId("courtType-filter")}
                select={true}
                name="courtType"
                label={t("COURT_TYPE")}
                margin="dense"
                id={setId("courtType-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
                SelectProps={{
                  id: setId("select-courtType-filter"),
                  SelectDisplayProps: {
                    id: setId("select-courtType-filter-wrapper"),
                  },
                }}
              >
                {Array.isArray(courtTypes) &&
                  courtTypes.map(({ id, name }, index) => (
                    <MenuItem
                      key={id}
                      value={id}
                      id={setId(`courtType-filter-${index + 1}`)}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </TextField>
            );
          case "justiceType":
            return (
              <TextField
                key={setId("justiceType-filter")}
                select={true}
                name="justiceType"
                label={t("JUSTICE_TYPE")}
                margin="dense"
                id={setId("justiceType-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
                SelectProps={{
                  id: setId("select-justiceType-filter"),
                  SelectDisplayProps: {
                    id: setId("select-justiceType-filter-wrapper"),
                  },
                }}
              >
                {Array.isArray(justiceTypes) &&
                  justiceTypes.map(({ id, name }, index) => (
                    <MenuItem
                      key={id}
                      value={id}
                      id={setId(`justiceType-filter-${index + 1}`)}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </TextField>
            );
          case "caseMemberName":
            return (
              <TextField
                key={setId("caseMemberName-filter")}
                name="caseMemberName"
                label={t("CASE_MEMBER_NAME_FILTER")}
                margin="dense"
                id={setId("caseMemberName-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
              />
            );
          case "criminalProcNum":
            return (
              <TextField
                key={setId("criminalProcNum-filter")}
                name="criminalProcNum"
                label={t("ERDR_NUMBER")}
                margin="dense"
                id={setId("criminalProcNum-filter")}
                className={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
              />
            );
          case "caseMemberRoleId":
            return (
              <MyCaseMemberStatus
                key={setId("caseMemberRoleId-filter")}
                name={"caseMemberRoleId"}
                setId={setId}
                t={t}
                customClasses={classNames(
                  classes.margin,
                  classes.textField,
                  classes.flex
                )}
              />
            );
          case "createdAt":
          case "docDate":
          default:
            return (
              <DateRangePicker
                key={setId(`${filterName}-filter`)}
                name={filterName}
                margin={"dense"}
                setId={(elementName) => setId(`${filterName}-${elementName}`)}
              />
            );
        }
      })}
  </FilterInput>
);

Filters.propTypes = {
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  courtTypes: PropTypes.array.isRequired,
  justiceTypes: PropTypes.array.isRequired,
  courts: PropTypes.array.isRequired,
};

const styled = withStyles(customInputStyle)(Filters);
export default translate("ClaimList")(styled);
