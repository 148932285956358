import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip, withStyles } from "@material-ui/core";
import cx from "classnames";

// import { getCheckCabinet } from "../../../actions/check-cabinet";

import SelectUserDialog from "../../../components/SelectUserDialog";
import SelectUserComponent from "./SelectUserComponent.jsx";

const legalFields = ["companyName", "edrpou"];
const unLegalFields = ["name", "ipn"];

const fiterProps = (tabs, props, currentTab) => {
  const keys = Object.keys(props);
  const filterFields = currentTab === 1 ? legalFields : unLegalFields;
  const filterForTabs = tabs
    ? keys.filter((key) =>
        props[key].tabsIds
          ? !!props[key].tabsIds.find((id) => id === currentTab)
          : true
      )
    : keys.filter((key) => !filterFields.find((id) => id === key));
  return filterForTabs.filter((key) => !props[key].hidden);
};

const getTabId = (isLegal) => (isLegal ? 2 : 1);

const styles = {
  tab: {
    margin: "27px 15px 0 0",
    background: "#E1E1E1",
    color: "#818181",
    display: "inline-flex",
  },
  activeTab: {
    "&, &:focus": {
      background: "#A8CFE8",
      color: "#818181",
    },
  },
};

class SelectUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSelectUserDialog: false,
      currentTab: null,
      values: this.props.value || {},
    };
  }

  handleSelect = (user) => {
    const { onChange } = this.props;

    const { isLegal, encodeCertSerial, encodeCert } = user || {};
    const properties = this.props.properties || {};

    const values = Object.keys(properties).reduce(
      (acc, key) => ({ ...acc, [key]: user[key] }),
      {
        encodeCertSerial,
        encodeCert,
        isLegal,
      }
    );

    this.setState(
      { openSelectUserDialog: false, values },
      () => onChange && onChange(values)
    );
  };

  handleChange = (name) => (value) => {
    const { onChange, tabs } = this.props;
    const { values } = this.state;
    const newValues = {
      ...values,
      [name]: value === "" ? undefined : value,
      id: values.id || "",
    };

    if (name === "isLegal") {
      const { isLegal, ...rest } = values || {};
      const properties = this.props.properties || {};
      const currentTab = getTabId(value);
      this.setState({ currentTab });
      const keys = fiterProps(tabs, properties, currentTab);

      Object.keys(rest).forEach((key) => {
        if (!keys.includes(key)) {
          newValues[key] = null;
        }
      });
    }

    this.setState({ values: newValues }, () => onChange(newValues));
  };

  // handleChange = (name) => async (value) => {
  //   const { onChange, tabs } = this.props;
  //   const { values } = this.state;
  //   const newValues = {
  //     ...values,
  //     [name]: value === "" ? undefined : value,
  //     id: values.id || "",
  //   };

  //   if (name === "isLegal") {
  //     const { isLegal, ...rest } = values || {};
  //     const properties = this.props.properties || {};
  //     const currentTab = getTabId(value);
  //     this.setState({ currentTab });
  //     const keys = fiterProps(tabs, properties, currentTab);

  //     Object.keys(rest).forEach((key) => {
  //       if (!keys.includes(key)) {
  //         newValues[key] = null;
  //       }
  //     });
  //   } else if (name === "ipn") {
  //     if (value?.length >= 8) {
  //       const result = await getCheckCabinet(value);
  //       if (result?.isFound === 1) {
  //         newValues.name = result.name;
  //       }
  //       if (result?.isLegal === 1) {
  //         newValues.address = result.address;
  //       }
  //     }
  //   }
  //   this.setState({ values: newValues }, () => onChange(newValues));
  //   // this.componentWillUpdate(newValues);
  // };

  toggleSelectUserDialog = () =>
    this.setState({
      openSelectUserDialog: !this.state.openSelectUserDialog,
    });

  render() {
    const { openSelectUserDialog } = this.state;
    const {
      tabs,
      value,
      owner,
      path,
      classes,
      document: doc,
      ...props
    } = this.props;
    const isRepresentStep = path.includes("represent");
    const { isLegal, id: valueId } = value || {};
    const isOwner = owner === valueId;
    const currentTab = this.state.currentTab || getTabId(isLegal);
    const properties = this.props.properties || {};
    let readonly = properties.isLegal && properties.isLegal.readonly;
    let hidden = properties.isLegal && properties.isLegal.hidden;
    const keys = fiterProps(tabs, properties, currentTab);
    if (
      ((hidden || readonly) &&
        doc &&
        doc.digitalDocumentData &&
        doc.digitalDocumentData.ownerShip &&
        doc.digitalDocumentData.ownerShip.representativeClaimant &&
        !doc.digitalDocumentData.ownerShip.claimentUserId &&
        properties &&
        properties.isClaimant &&
        !!properties.isClaimant.value) ||
      ((hidden || readonly) && !doc.attaches[0]?.fileHash)
    ) {
      readonly = false;
      hidden = false;
    }
    return (
      <Fragment>
        {tabs && (
          <section id={"select-isLegal-tabs"}>
            {tabs.map((tab) => {
              const { id } = tab;
              const isActiveTab = currentTab === id;

              const isHidden =
                hidden ||
                (isRepresentStep && tab.id === 2 && owner === valueId) ||
                (readonly && !isRepresentStep && !isActiveTab);

              if (isHidden) return null;

              const onClick =
                !isOwner && !readonly
                  ? () => this.handleChange("isLegal")(tab.id === 2)
                  : undefined;
              const name = "select-isLegal-tab-";
              let key = id === 1 ? `${name}${id}-not` : `${name}${id}`;
              if (id === 2) key = `${name}${id}-yes`;
              return (
                <Chip
                  id={key}
                  key={key}
                  label={tab.description}
                  onClick={onClick}
                  className={cx(classes.tab, isActiveTab && classes.activeTab)}
                />
              );
            })}
          </section>
        )}
        {keys.map((key, index) => (
          <SelectUserComponent
            {...props}
            properties={properties}
            path={path}
            value={value}
            key={key}
            name={key}
            index={index}
            handleOpenSelectUserDialog={this.toggleSelectUserDialog}
            handleChange={this.handleChange}
          />
        ))}
        <SelectUserDialog
          open={openSelectUserDialog}
          onClose={this.toggleSelectUserDialog}
          onUserSelect={this.handleSelect}
        />
      </Fragment>
    );
  }
}

SelectUser.propTypes = {
  tabs: PropTypes.array,
  properties: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  formControlProps: PropTypes.object,
  owner: PropTypes.string,
  signer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  document: PropTypes.object,
};

SelectUser.defaultProps = {
  tabs: null,
  properties: null,
  value: null,
  onChange: undefined,
  errors: null,
  required: false,
  formControlProps: {},
  owner: "",
  signer: null,
  document: null,
};

const translated = translate("Elements")(SelectUser);
export default withStyles(styles)(translated);
