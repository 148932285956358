import * as api from "../services/api";
import store from "../store";

const { dispatch } = store;

const REQUEST_REGIONS = "REQUEST_REGIONS";
const REQUEST_COURTS = "REQUEST_COURTS";
const REQUEST_CASES_STATUSES = "REQUEST_CASES_STATUSES";
const REQUEST_PROCEEDING_STATUSES = "REQUEST_PROCEEDING_STATUSES";
const REQUEST_COURT_TYPES = "REQUEST_COURT_TYPES";
const REQUEST_JUSTICE_TYPES = "REQUEST_JUSTICE_TYPES";
const REQUEST_EDS_SERVER_LIST = "REQUEST_EDS_SERVER_LIST";
const REQUEST_CASES_MEMBER_ROLES = "REQUEST_CASES_MEMBER_ROLES";
const REQUEST_CASES_JUDGE_ROLES = "REQUEST_CASES_JUDGE_ROLES";
const REQUEST_JURISDICTION_TYPES = "REQUEST_JURISDICTION_TYPES";
const REQUEST_EXECUTE_ORG = "REQUEST_EXECUTE_ORG";
const REQUEST_EDD_STATUSES = "REQUEST_EDD_STATUSES";

const REGIONS_URL = "api/dictionaries/regions";
const COURTS_URL = "api/dictionaries/courts";
const CASES_URL = "api/dictionaries/cases";
const JURISDICTION_URL = "api/dictionaries/jurisdiction_types";
// const DICTIONARIES_URL = 'api/dictionaries';
const EDS_URL = "api/eds";
const COURT_TYPES_URL = "api/dictionaries/court_types";
const JUSTICE_TYPES_URL = "api/dictionaries/justice_types";
const CASE_STATUSES_URL = "api/dictionaries/case_statuses";
const PRACEEDING_STATUSES_URL = "api/dictionaries/proceeding_statuses";

export function requestRegions() {
  return api.get(REGIONS_URL, REQUEST_REGIONS, dispatch);
}

export function requestCourts() {
  return api.get(COURTS_URL, REQUEST_COURTS, dispatch);
}

export function requestCaseStatuses() {
  return api.get(CASE_STATUSES_URL, REQUEST_CASES_STATUSES, dispatch);
}

export function requestMemberRoles() {
  return api.get(
    `${CASES_URL}/member_roles`,
    REQUEST_CASES_MEMBER_ROLES,
    dispatch
  );
}

export function requestJudgeRoles() {
  return api.get(
    `${CASES_URL}/judge_roles`,
    REQUEST_CASES_JUDGE_ROLES,
    dispatch
  );
}

export function requestEDSServerList() {
  return api.get(`${EDS_URL}/servers`, REQUEST_EDS_SERVER_LIST, dispatch);
}

export function requestCourtTypes() {
  return api.get(COURT_TYPES_URL, REQUEST_COURT_TYPES, dispatch);
}

export function requestJusticeTypes() {
  return api.get(JUSTICE_TYPES_URL, REQUEST_JUSTICE_TYPES, dispatch);
}

export function requestProceedingStatuses() {
  return api.get(
    PRACEEDING_STATUSES_URL,
    REQUEST_PROCEEDING_STATUSES,
    dispatch
  );
}

export function requestjusticeTypes() {
  return api.get(JURISDICTION_URL, REQUEST_JURISDICTION_TYPES, dispatch);
}

export function requestExecuteOrganizations() {
  return api.get(
    "api/dictionaries/organizations",
    REQUEST_EXECUTE_ORG,
    dispatch
  );
}

export function requestEDDStatuses() {
  return api.get(
    "api/dictionaries/edd_status_types",
    REQUEST_EDD_STATUSES,
    dispatch
  );
}

export function requestEDDDictionary() {
  return Promise.all([requestExecuteOrganizations(), requestEDDStatuses()]);
}

export function requestDictionary() {
  return Promise.all([
    requestRegions(),
    requestCourts(),
    requestCaseStatuses(),
    requestMemberRoles(),
    requestJudgeRoles(),
    // requestEDSServerList(),
    requestCourtTypes(),
    requestJusticeTypes(),
    requestProceedingStatuses(),
    requestjusticeTypes(),
  ]);
}
