/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

// ##############################
// // // CustomInput styles
// #############################

import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
  blackColor,
} from "../styles";

const halfBlock = {
  width: "50%",
  boxSizing: "border-box",
  display: "inline-block",
} as const;

const customInputStyle = {
  checked: {
    color: primaryColor,
  },
  dummy: {
    fontWeight: 400,
    fontSize: "16px",
    borderBottom: "1px dashed rgb(149, 149, 149);",
  },
  dummyLabel: {
    position: "absolute",
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  },
  first: {
    ...halfBlock,
  },
  last: {
    ...halfBlock,
    textAlign: "right",
  },
  underlineLink: {
    color: primaryColor,
    fontWeight: 300,
    display: "inline-block",
    borderBottom: `2px dotted ${primaryColor}`,
    fontSize: "1rem",
    cursor: "pointer",
  },
  underline: {
    "&:before": {
      backgroundColor: "#D2D2D2",
      height: "1px !important",
    },
  },
  inkbar: {
    "&:after": {
      backgroundColor: primaryColor,
    },
  },
  inkbarError: {
    "&:after": {
      backgroundColor: dangerColor,
    },
  },
  inkbarSuccess: {
    "&:after": {
      backgroundColor: successColor,
    },
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.42857",
  },
  labelRootError: {
    color: dangerColor,
    padding: "5px 0 5px 27px",
    marginTop: 0,
    boxSizing: "border-box",
  },
  labelRootSuccess: {
    color: successColor,
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: 2,
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",

    "& > span": {
      height: "100%",
    },
  },
  marginTop: {
    marginTop: "16px",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "11px 0 0 0",
    position: "relative",
    maxWidth: 700,
    display: "flex",
    "@media (max-width:500px)": {
      // eslint-disable-line no-useless-computed-key
      "& label": {
        fontSize: "0.75rem",
      },
    },
  },
  edsFormControl: {
    "& label": {
      fontSize: "0.9rem",
    },
    "@media (max-width:610px)": {
      // eslint-disable-line no-useless-computed-key
      "& label": {
        fontSize: "0.75rem",
      },
    },
  },
  formMultiControl: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  passportNumber: {
    flexGrow: 1,
    marginLeft: "10px",
  },
  quillErrored: {
    "& .ql-toolbar": {
      borderColor: dangerColor,
      borderBottomColor: "#ccc",
    },
    "& .ql-container": {
      borderColor: dangerColor,
    },
  },
  error: {
    color: dangerColor,
    borderBottomColor: dangerColor,
  },
  wrapper: {
    padding: "5px 0 5px 27px",
    marginTop: 0,
    boxSizing: "border-box",
  },
  heading: {
    fontSize: "16px",
  },
  panelContent: {
    display: "block",
  },
  toolTipIcon: {
    fontSize: 16,
    opacity: 0.5,
  },
  toolTip: {
    maxWidth: 400,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 16,
    background: "#fff",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: 165,
      fontSize: "14px",
      maxHeight: 280,
      overflow: "auto",
    },
  },
  group: {
    display: "flex",
  },
  flex: {
    flex: 1,
  },
  dialog: {
    "& > :last-child": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        fontSize: ".7rem",
      },
    },
    "@media (max-width:425px)": {
      // eslint-disable-line no-useless-computed-key
      margin: 0,
      "& > div > div": {
        margin: 15,
      },
    },
  },
  dialogContentWrappers: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "24px 15px 20px",
      "& tr": {
        margin: "3px 0",
      },
    },
  },
  smallWrapper: {
    overflowY: "initial",
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "12px 15px",
      "& > fieldset": {
        marginTop: 12,
      },
    },
  },
  fromToWrapper: {
    padding: "0 24px",
  },
  attachList: {
    "& > li": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        width: "50%!important",
      },
    },
  },
  menu: {},
  menuItem: {
    "@media (max-width:992px)": {
      // eslint-disable-line no-useless-computed-key
      whiteSpace: "initial",
      height: "unset",
      fontSize: 15,
    },
  },
  benefits: {
    whiteSpace: "normal",
    height: "unset",
  },
  mobilePadding: {
    "@media (max-width:425px)": {
      // eslint-disable-line no-useless-computed-key
      padding: 0,
    },
  },
  tab: {
    "@media (max-width:425px)": {
      // eslint-disable-line no-useless-computed-key
      fontSize: 12,
    },
  },
  fullWidth: {
    width: "100%",
    display: "block",
  },
  checkboxFormWrapper: {
    boxSizing: "border-box",
    paddingRight: 5,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
  },
  parentWrapper: {
    marginTop: 10,
  },
  checkboxFormLabel: {
    boxSizing: "border-box",
    fontSize: 14,
    fontWeight: 400,
    padding: "10px 0 10px 27px",
    margin: 0,
  },
  verticalAlignTop: {
    verticalAlign: "top",
  },
  isUserIcon: {
    display: "flex",
    flexWrap: "wrap",
  },
  rolesWrap: {
    display: "flex",
  },
  openRoles: {
    color: "#00f",
    borderBottom: "1px dotted #00f",
    height: 20,
    cursor: "pointer",
    marginLeft: 10,
  },
  radio: {
    color: blackColor,
  },
  radioTitle: {
    display: "block",
    fontSize: "16px",
  },
  radioDescription: {
    opacity: "0.54",
    fontSize: "12px",
  },
} as const;

export default customInputStyle;
