import React from "react";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../selectors/auth";

type Props = {
  isLegal: boolean;
};

function withUser<T extends Props = Props>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithUser = (props: Omit<T, keyof Props>) => {
    const info = useSelector(userInfoSelector);
    return (
      <WrappedComponent {...(props as T)} isLegal={info && info.isLegal} />
    );
  };

  ComponentWithUser.displayName = `withUser(${displayName})`;

  return ComponentWithUser;
}

export default withUser;
