import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import { withStyles, Grid } from "@material-ui/core";

const style = {
  grid: {
    padding: "0 15px",
  },
};

const ItemGrid = ({ classes, setId, children, ...rest }) => (
  <Grid item={true} {...rest} className={classes.grid} id={setId("")}>
    {children}
  </Grid>
);

ItemGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  setId: PropTypes.func,
};

ItemGrid.defaultProps = {
  setId: setComponentsId("grid"),
  children: "",
};

export default withStyles(style)(ItemGrid);
