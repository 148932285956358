import PropTypes from "prop-types";
import { translate } from "react-translate";

import { TableRow, TableBody, TableCell, Table } from "@material-ui/core";

const stylesForLeftColum = {
  border: "1px solid #dddddd",
  borderCollapse: "collapse",
  textAlign: "left",
  padding: "8px",
  fontWeight: "bold",
  margin: "0 0 0 0",
};

const stylesForRightColum = {
  border: "1px solid #dddddd",
  textAlign: "left",
  padding: "8px",
  margin: "0 0 0 0",
};

const DialogTable = ({ tableData, header }) => {
  return (
    <>
      <h3>{header}</h3>
      <Table style={{ borderCollapse: "collapse", maxWidth: "100%" }}>
        <TableBody>
          {tableData?.length &&
            tableData.map((item) => {
              return item?.[0] && item?.[1] ? (
                <TableRow>
                  <TableCell style={stylesForLeftColum}>{item?.[0]}</TableCell>
                  <TableCell style={stylesForRightColum}>{item?.[1]}</TableCell>
                </TableRow>
              ) : (
                ""
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

DialogTable.propTypes = {
  tableData: PropTypes.object,
  header: PropTypes.string,
};

DialogTable.defaultProps = {};

export default translate("Elements")(DialogTable);
