export default {
  ENTER_CODE: "Введіть РНОКПП/код ЄДРПОУ:",
  CODE_DESCRIPTION:
    "*Для фізичних осіб, які через свої релігійні переконання відмовилися від прийняття реєстраційного номера облікової картки платника податків, та мають відповідну відмітку в паспорті, вноситься серія та номер паспорта",
  SEND_REQUEST: "Надіслати запит",
  INDIVIDUAL: "Фізична особа:",
  RNOKPP: "РНОКПП:",
  NAME: "ПІБ:",
  IS_FOUND:
    "має зареєстрований Електронний кабінет у підсистемі Електронний суд ЄСІТС.",
  REGISTRATION_DATE: "Дата реєстрації:",
  LEGAL_ENTITY: "Юридична особа:",
  EDRPOU: "ЄДРПОУ:",
  COMPANY_NAME: "Назва:",
  IS_NOT_FOUND:
    "не має зареєстрованого Електронного кабінету в підсистемі Електронний суд ЄСІТС.",
  NEW_REQUEST: "Новий запит",
  RESPONS_WITH_KEP: "Сформувати відповідь з КЕП",
  MAIN_TITLE:
    "Запит на отримання інформації про наявність зареєстрованого Електронного кабінету ЄСІТС.",
  GENERAL_ERROR:
    "Помилка перевірки наявності кабінету або некоректний РНОКПП/код ЄДРПОУ",
  NOT_VALID_CODE_ERROR: "Невірний формат коду",
};
