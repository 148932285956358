/*
-1  Видалення   0   Чернетка
1   Створення   0   Чернетка
2   Підписання  1   Підписання
3   Очікує на відправлення в АСДС   2   Відправлено
4   В дорозі    2   Відправлено
5   Помилка відправлення до АСДС    -1  Помилка обробки
6   Помилка отримання АСДС  -1  Помилка обробки
7   Прийнятий. Очікує на реєстрацію 3   Доставлено
8   Відмовлено в реєстрації 4   Відмовлено в реєстрації
9   Помилка реєстрації  -1  Помилка обробки
10  Зареєстрований  5   Зареєстровано
11  Надійшов від АСДС   5   Зареєстровано
12  Первинний. Зареєстровано в АСДС. Відкрито провадження   5   Зареєстровано
14  Вторинний. Зареєстровано в АСДС 5   Зареєстровано
15  Чинна довіреність   6   Довіреність чинна
16  Відкликана довіреність  7   Довіреність відкликана

№     Old names                 New names
 -1    Видалення               'Видалення'
 1    Створення                'Чернетка'
 2    Підписання               'Підписання'
 3    Очікує на відправлення   'Відправлено'
 4    Відправлений             'Доставлено'
 5    Помилка відправлення     'Помилка обробки'
 6    Помилка отримання        'Помилка обробки'
 7    Прийнятий                'Доставлено'
 8    Відмовлено в реєстрації  'Відмовлено в реєстрації'
 9    Помилка реєстрації       'Помилка обробки'
 10    Очікує на реєстрацію    'Зареєстровано'
 11    Зареєстрований          'Зареєстровано'
 12    Зареєстрований          'Зареєстровано'
 14    Зареєстрований          'Зареєстровано'
 15    Зареєстрований          'Зареєстровано'
 16    Відкликана довіреність  'Помилка обробки'
 18   ---------------------    'Надіслано сторонам'

SCOPED
'1',          'Чернетка'
'2',          'Підписання'
'3, 4',       'Відправлено'
'7,10',       'Доставлено'
'5,6,9,16',   'Помилка обробки'
'8',          'Відмовлено в реєстрації'
'11,12,14,15' 'Зареєстровано'
*/

export const DELETING_STATUS = -1;
export const CREATING_STATUS = 1;
export const SIGNING_STATUS = 2;
export const READY_TO_SEND_STATUS = 3;
export const SENDED_STATUS = 4;
export const SENDING_ERROR_STATUS = 5;
export const RECEIVING_ERROR_STATUS = 6;
export const RECEIVED_STATUS = 7;
export const REGISTER_REFUSED_STATUS = 8;
export const REGISTER_ERROR_STATUS = 9;
export const WAITING_FOR_REGISTER_STATUS = 10;
export const REGISTERED_STATUS = 11;
export const COURT_IN_WORK_STATUS = 12;
export const REGISTER_STATUS_2 = 14;
export const REGISTER_STATUS_3 = 15;
export const SENDING_ERROR_STATUS_2 = 5;
export const SENDED_TO_PARTIES = 18;

const statuses = {
  [DELETING_STATUS]: "DELETING_STATUS",
  [CREATING_STATUS]: "CREATING_STATUS",
  [SIGNING_STATUS]: "SIGNING_STATUS",
  [READY_TO_SEND_STATUS]: "READY_TO_SEND_STATUS",
  [SENDED_STATUS]: "SENDED_STATUS",
  [SENDED_TO_PARTIES]: "SENDED_TO_PARTIES",
  [SENDING_ERROR_STATUS]: "SENDING_ERROR_STATUS",
  [RECEIVING_ERROR_STATUS]: "RECEIVING_ERROR_STATUS",
  [RECEIVED_STATUS]: "RECEIVED_STATUS",
  [REGISTER_REFUSED_STATUS]: "REGISTER_REFUSED_STATUS",
  [REGISTER_ERROR_STATUS]: "REGISTER_ERROR_STATUS",
  [WAITING_FOR_REGISTER_STATUS]: "WAITING_FOR_REGISTER_STATUS",
  [REGISTERED_STATUS]: "REGISTERED_STATUS",
  [COURT_IN_WORK_STATUS]: "COURT_IN_WORK_STATUS",
  [REGISTER_STATUS_2]: "JOINED_TO_CAUSE",
  [REGISTER_STATUS_3]: "REGISTERED_STATUS",
  [SENDING_ERROR_STATUS_2]: "SENDING_ERROR_STATUS",
};

const stagesStatuses = {
  1: "1",
  2: "2",
  3: "3,4,16",
  7: "7,10",
  5: "5",
  9: "9",
  6: "6",
  8: "8",
  10: "10",
  12: "12",
  14: "14",
  18: "18",
  // 11: '11,12,14,15',
};

const executiveStagesStatuses = {
  1: "1",
  2: "2,3,4,5,6,9,16,7,10,8,11,12,14,15,18",
};

export const sortStatuses = (t, statusesLib, docCategory = 1) => {
  return Object.keys(statusesLib)
    .reduce((acc, id) => {
      const stages =
        docCategory === 4 ? executiveStagesStatuses : stagesStatuses;
      if (+id !== -1 && stages[id]) {
        acc.push({
          id,
          textId: statusesLib[id],
          name: t(statusesLib[id]),
          value: stages[id],
        });
      }
      return acc;
    }, [])
    .sort((status, nextStatus) => (status.name < nextStatus.name ? -1 : 1));
};

export const executiveStatuses = {
  [DELETING_STATUS]: "DELETING_STATUS",
  [CREATING_STATUS]: "EXECUTIVE_CREATING_STATUS", // 1 = не підлягає виконанню;
  [SIGNING_STATUS]: "EXECUTIVE_NOT_SUBMITTED", // 2 = не пред’явлений до виконання;
  [READY_TO_SEND_STATUS]: "EXECUTIVE_READY_TO_SUBMIT", // 3 = очікує прийняття до виконання;
  [SENDED_STATUS]: "EXECUTIVE_SUBMITING", // 4 = перебуває на виконанні;
  [SENDED_TO_PARTIES]: "EXECUTIVE_SUBMITING",
  [SENDING_ERROR_STATUS]: "EXECUTIVE_TIMESTOPPED", // 5 = виконання зупинено;
  [RECEIVING_ERROR_STATUS]: "EXECUTIVE_STOPPED", // 6 = виконання припинено;
  [RECEIVED_STATUS]: "EXECUTIVE_COMPLETE", // 7 = виконання закінчено;

  [REGISTER_REFUSED_STATUS]: "EXECUTIVE_ORIGINAL",
  [REGISTER_ERROR_STATUS]: "EXECUTIVE_ORIGINAL",
  [WAITING_FOR_REGISTER_STATUS]: "EXECUTIVE_ORIGINAL",
  [REGISTERED_STATUS]: "EXECUTIVE_ORIGINAL",
  [COURT_IN_WORK_STATUS]: "EXECUTIVE_ORIGINAL",
  [REGISTER_STATUS_2]: "EXECUTIVE_ORIGINAL",
  [REGISTER_STATUS_3]: "EXECUTIVE_ORIGINAL",
  [SENDING_ERROR_STATUS_2]: "EXECUTIVE_ORIGINAL",
};

export const getStatusesForCat = (catId) => {
  switch (catId) {
    case 4:
      return executiveStatuses;
    default:
      return statuses;
  }
};

export default statuses;
