import { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { translate } from "react-translate";
import classNames from "classnames";

import TextField from "@material-ui/core/TextField";

const FromToNumericInput = ({
  t,
  classes,
  onChange,
  value: inputValue,
  setId,
  name,
  ...props
}) => {
  const [fromSum, setFromSum] = useState("");
  const [toSum, setToSum] = useState("");
  const message = `Максимальна сума не може бути меншою стартової`;
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!inputValue) {
      setFromSum("");
      setToSum("");
    } else {
      let splited = inputValue.split("-");
      setFromSum(splited?.[0] || "");
      setToSum(splited?.[1] || "");
    }
  }, [inputValue]);

  const onFromSumChangeHandler = ({ target: { value } }) => {
    var reg = new RegExp("^[0-9]+$");

    value = value.replace("-", "");
    if (!reg.test(value)) return;

    setFromSum(value);
    onChange({
      target: {
        value: `${value}-${toSum}`,
        name: name,
      },
    });
  };

  const onToSumChangeHandler = ({ target: { value } }) => {
    var reg = new RegExp("^[0-9]+$");
    value = value.replace("-", "");

    if (!reg.test(value)) return;
    if (Number(value) < Number(fromSum)) {
      setErrorMessage(message);
    } else {
      setErrorMessage("");
    }

    setToSum(value);
    onChange({
      target: {
        value: `${fromSum}-${value}`,
        name: name,
      },
    });
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <TextField
        value={fromSum}
        onChange={onFromSumChangeHandler}
        onKeyUp={({ target: { value } }) => (value = value.replace("-", ""))}
        name={name}
        label={t("FROM_SUM")}
        style={{ marginRight: "15px" }}
        className={classNames(classes.margin, classes.textField, classes.flex)}
      />
      <TextField
        value={toSum}
        onChange={onToSumChangeHandler}
        onKeyUp={({ target: { value } }) => (value = value.replace("-", ""))}
        name={name}
        label={t("TO_SUM")}
        className={classNames(classes.margin, classes.textField, classes.flex)}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </div>
  );
};

FromToNumericInput.propTypes = {};

FromToNumericInput.defaultProps = {};

export default translate("ExecutiveDocs")(FromToNumericInput);
