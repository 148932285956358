const ADD_USER_META = "ADD_USER_META";
const REQUEST_USER_META_SUCCESS = "REQUEST_USER_META_SUCCESS";

const initialState = {
  userMetaData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER_META_SUCCESS:
      return { ...state, userMetaData: action.payload };
    case ADD_USER_META:
      const payload = JSON.parse(action.body);
      return { ...state, userMetaData: payload };
    default:
      return state;
  }
};
