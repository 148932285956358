import React from "react";
import PropTypes from "prop-types";
// @ts-ignore
import { translate } from "react-translate";
import { setWizardStates } from "../../actions/claim";
import store from "../../store";
import setComponentsId from "../../helpers/setComponentsId";

const EndUser = window.EndUser;

const SIGN_WIDGET_PARENT_ID = "sign-widget-parent";
const SIGN_WIDGET_ID = "sign-widget";
const SIGN_WIDGET_URI = "https://id.gov.ua/sign-widget/v20200922/"; //?address=https://cabinet-dev.court.gov.ua/

const signExternal = true;
const signAsBase64String = true;
const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
const signType = EndUser.SignType.CAdES_X_Long;

class IdGovWidget extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      key: null,
      selectedKey: null,
      signDocId: undefined,
      signIndex: -1,
      totalDocs: 1,
      nameOfService: null,
      bannerText: t("MAIN_TITLE"),
    };
  }

  euSign = undefined;

  handleSelectKey = async (keyData) => {
    const { onSelectKey, t } = this.props;
    if (!onSelectKey) {
      return;
    }
    this.setState({
      bannerText: t("PREPARE_DOCS"),
    });

    const selectedKey = Array.isArray(keyData)
      ? keyData.find((item) => item.infoEx.keyUsage === "ЕЦП, Неспростовність")
      : keyData;
    this.setState({
      selectedKey,
      signIndex: 0,
    });

    const signer = this;
    await onSelectKey(
      selectedKey.infoEx,
      signer,
      () => signer.execute("ResetPrivateKey"),
      this.onDataToSign
    );
  };

  onDataToSign = async (signData) => {
    const { signDocId } = this.state;
    const signatures = store.getState().claim.signatures;
    const savedSignatures = signatures.get(signDocId) || [];
    this.setState({
      totalDocs: signData.length - 1,
      signIndex: savedSignatures.length,
    });
    setWizardStates({
      blockScreen: false,
    });
    return savedSignatures;
  };

  onConfirmKSPOperation = async (event) => {
    const { t } = this.props;
    if (this.state.signIndex === -1) {
      const { claim } = store.getState().claim;
      this.setState({
        bannerText: `${t("SIGN_DOCS_WITH_SERVICE")}"${event.mobileAppName}"`,
        nameOfService: event.mobileAppName,
        signDocId: claim.id,
      });
    } else {
      this.setState({
        bannerText: `${t("SIGN_FILE")} ${this.state.signIndex + 1} з ${
          this.state.totalDocs
        }`,
      });
    }
  };

  execute = async (method, ...params) => {
    const self = this;
    return await self[method](...params);
  };

  async Base64Encode(buffer) {
    return Buffer.from(buffer).toString("base64");
  }

  async GetCertificate(issuer, serial) {
    const {
      selectedKey,
      selectedKey: { infoEx },
    } = this.state;
    return infoEx.issuer === issuer && infoEx.serial === serial
      ? selectedKey?.data
      : undefined;
  }

  async ResetPrivateKey(...params) {
    return await this.euSign.ResetPrivateKey(...params);
  }

  async SignData(data) {
    const { signIndex } = this.state;
    const sign = await this.euSign.SignData(
      data,
      signExternal,
      signAsBase64String,
      signAlgo,
      null,
      signType
    );
    this.setState({ signIndex: signIndex + 1 });
    return sign;
  }

  async SignHash(data) {
    const { nameOfService, signDocId, signIndex, totalDocs } = this.state;
    const sign = await this.euSign.SignHash(
      data,
      signAsBase64String,
      signAlgo,
      signType,
      null
    );
    if (nameOfService) {
      if (signIndex < totalDocs - 1) {
        store.dispatch({
          type: "SAVE_CLAIM_SIGNATURE",
          payload: { id: signDocId, signature: sign },
        });
      } else {
        store.dispatch({
          type: "CLEAR_CLAIM_SIGNATURES",
          payload: { id: signDocId },
        });
      }
    }
    this.setState({ signIndex: signIndex + 1 });
    return sign;
  }

  componentDidMount() {
    this.euSign = new EndUser(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      EndUser.FormType.ReadPKey
    );

    this.euSign.AddEventListener(
      EndUser.EventType.ConfirmKSPOperation,
      this.onConfirmKSPOperation
    );

    this.euSign
      .ReadPrivateKey()
      .then(this.handleSelectKey)
      .catch(function (e) {
        alert(
          "Виникла помилка при зчитуванні ос. ключа. " +
            "Опис помилки: " +
            (e.message || e)
        );
      });
  }

  componentWillUnmount() {
    if (this.euSign) this.euSign.destroy();
  }

  render() {
    const { bannerText } = this.state;

    return (
      <div ref={(el) => (this.div = el)}>
        <div
          style={{
            textAlign: "center",
            background: "rgb(255, 227, 88)",
            margin: "5px",
            padding: "5px",
          }}
        >
          {bannerText}
        </div>
        <div
          sandbox="allow-scripts"
          id="sign-widget-parent"
          style={{ width: "561px", height: "442px" }}
        ></div>
        <div id="sign-data-block" style={{ display: "none" }}>
          Дані для підпису:
          <br />
          <textarea
            id="textAreaData"
            style={{ width: "510px", height: "50px" }}
          ></textarea>
          <br />
          Підпис\Підписані дані:
          <br />
          <textarea
            id="textAreaSign"
            style={{ width: "510px", height: "400px" }}
          ></textarea>
          <br />
          <button id="buttonSignData" onСlick="onSign();">
            Підписати
          </button>
        </div>
      </div>
    );
  }
}

IdGovWidget.propTypes = {
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,
  onSelectKey: PropTypes.func.isRequired,
};

IdGovWidget.defaultProps = {
  setId: setComponentsId("widget-gov-ua"),
};

// decorate and export
export default translate("IdGovWidget")(IdGovWidget);
