export default {
  wrap: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    backgroundColor: "#00224E",
    color: "#fff",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: "10%",
    maxWidth: 960,
    margin: "0 auto",
    letterSpacing: "0.6px",
  },
  block: {
    minWidth: 300,
    height: 276,
  },
  left: {
    width: "30%",
  },
  right: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingTop: 105,
  },
  errorTitle: {
    fontSize: 32,
    marginBottom: 0,
  },
  errorSubTitle: {
    fontSize: 20,
    marginBottom: 5,
  },
  errorText: {
    fontSize: 22,
    fontWeight: 100,
    marginTop: 27,
    opacity: "0.6",
  },
};
