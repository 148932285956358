import React from "react";
import PropTypes from "prop-types";

import { Dialog, withStyles } from "@material-ui/core";
import cx from "classnames";

import shareDialogStyles from "../../variables/styles/shareDialog";
import customInputStyle from "../../variables/styles/customInputStyle";

const styles = { ...customInputStyle, ...shareDialogStyles };

const ShareDialogWrapper = ({
  toggleDialog,
  display,
  classes,
  busy,
  setId,
  children,
}) => {
  const open = !!display && display !== "delete" && display !== "signing";
  return (
    <Dialog
      open={open}
      onClose={!busy ? toggleDialog(display) : undefined}
      id={setId("wrapper")}
      className={cx(classes.dialog, classes.fullWidth)}
    >
      {children}
    </Dialog>
  );
};

const styled = withStyles(styles)(ShareDialogWrapper);

ShareDialogWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default styled;
