import * as api from "../services/api";
import store from "../store";
import config from "../config";

import promiseChain from "../helpers/promiseChain";
import storage from "../helpers/storage";
import { AUTH_ERROR, SET_ROLE } from "../reducers/authorization";
import ApiException from "../services/api/ApiException";

import { REQUEST_USER_INFO } from "./user";

export const REQUEST_AUTH = "REQUEST_AUTH";
export const TOKEN_ERROR = "TOKEN_ERROR";
export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const GET_ROLE = "GET_ROLE";
export const POST_ROLE = "POST_ROLE";

export const SET_USER_PHONE_VALID = "SET_USER_PHONE_VALID";

const { backendUrl, logoutLink } = config();

export const LOGOUT_LINK = logoutLink || backendUrl + "/redirect/logout";

const AUTH_URL = "api/auth";

export function logout(notLogout = false) {
  const {
    authorization: { tokenError, DBError },
  } = store.getState() || {};
  storage.removeItem("token");
  storage.removeItem("code");
  storage.removeItem("role");
  if (!tokenError && !DBError && !notLogout) {
    window.location.href = LOGOUT_LINK;
  }
}

export const requestUserInfo = () =>
  promiseChain([
    () => api.get(`${AUTH_URL}/me`, REQUEST_USER_INFO, store.dispatch),
    async (info) => {
      if (info.message) {
        throw info;
      }
      return info; // Sentry.configureScope(scope => scope.setUser(info));
    },
  ]).catch((error) => {
    if ((error.message || error.serverMessage || "").includes("time")) {
      store.dispatch({
        type: "DB_ERROR",
        payload: true,
      });
      ApiException(error, `${AUTH_URL}/me`, "get", {
        message: "database not responding",
      });
    }
    logout(true);
  });

export function isLoggedInCompletely() {
  const { authorization } = store.getState() || {};
  return (
    !!storage.getItem("token") &&
    authorization &&
    authorization.token &&
    authorization.info
  );
}

export const requestAuth = (code) => {
  const existedToken = storage.getItem("token");
  if (!existedToken && !code) {
    code = storage.getItem("code");
  }
  if (existedToken && code && !isLoggedInCompletely()) {
    return requestUserInfo();
  } else if (existedToken && !code) {
    store.dispatch({
      type: AUTH_SET_TOKEN,
      payload: existedToken,
    });
    return requestUserInfo();
  } else if (!existedToken && code) {
    return api
      .post(`${AUTH_URL}/login`, { code }, REQUEST_AUTH, store.dispatch)
      .then((result) => {
        storage.setItem("code", code);
        if (!result || !result.token) {
          storage.removeItem("token");
          storage.removeItem("code");
          return store.dispatch({
            type: TOKEN_ERROR,
            payload: true,
          });
        }
        const { token } = result;
        storage.setItem("token", token);
        store.dispatch({
          type: AUTH_SET_TOKEN,
          payload: token,
        });
        return requestUserInfo();
      })
      .catch((e) => console.error(e)); // eslint-disable-line no-console
  } else {
    logout();
  }
  return false;
};

export function isLoggedIn() {
  return !!storage.getItem("token");
}

export function isRole(check) {
  const {
    authorization: { info },
  } = store.getState() || {};
  if (!info || !info.courtIdUserScopes) {
    return false;
  }
  const { courtIdUserScopes } = info;
  return courtIdUserScopes.includes(check);
}

export function loadRole() {
  return api.get(`${AUTH_URL}/role`, GET_ROLE, store.dispatch);
}

// export function postRole(role) {
//   return api.post(`${AUTH_URL}/role`, { role }, POST_ROLE, store.dispatch);
// }

export async function setAuthError(error) {
  return store.dispatch({
    type: AUTH_ERROR,
    payload: error,
  });
}

export async function setRole(role) {
  const res = await api.post(
    `${AUTH_URL}/role`,
    { role },
    POST_ROLE,
    store.dispatch
  );
  if (res instanceof Error) {
    return store.dispatch({
      type: AUTH_ERROR,
      payload: res.message,
    });
  } else {
    return store.dispatch({
      type: SET_ROLE,
      payload: res,
    });
  }
}
