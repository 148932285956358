// @ts-nocheck
import { getActualFilterDate } from "../../../helpers/savedFilterDates/saved.date.formulas";
import humanDateFormat from "../../../helpers/humanDateFormat";

export const setDateFilters = (userFilter, dataSource) => {
  // createdAt
  const createdAtFilter = getActualFilterDate(
    userFilter.createdAt,
    userFilter.createdAtKDMD,
    userFilter.createdAtKDDP,
    userFilter.createdAtKDDZ
  );
  dataSource.setFilter("createdAt", createdAtFilter || userFilter.createdAt);

  // updatedAt
  const docAcceptDateFilter = getActualFilterDate(
    userFilter.docAcceptDate,
    userFilter.docAcceptDateKDMD,
    userFilter.docAcceptDateKDDP,
    userFilter.docAcceptDateKDDZ
  );
  dataSource.setFilter(
    "docAcceptDate",
    docAcceptDateFilter || userFilter.docAcceptDate
  );

  dataSource.load();
};

export const checkSort = (dataSource) => {
  if (dataSource.sort["createdAt"] === "desc") {
    dataSource.sort = { createdAt: "asc" };
  } else if (dataSource.sort["createdAt"] === "asc") {
    dataSource.sort = { createdAt: "desc" };
  } else {
    dataSource.sort = { createdAt: "desc" };
  }
};

export const setDefaultFilters = ({ dataSource, userId }) => {
  checkSort(dataSource);

  const storageData = localStorage.getItem("executiveDecisionsFilter");
  let userFilter = storageData && userId && JSON.parse(storageData)?.[userId];

  if (!userFilter || !Object.values(userFilter)) return;

  dataSource.setFilter("vpStateId", userFilter.vpStateId);
  dataSource.setFilter("myRole", userFilter.myRole);
  dataSource.setFilter("sumUah", userFilter.sumUah);

  const changeFilterDate = userFilter.changeFilterDate;
  const currentDate = humanDateFormat(Date.now(), { format: "YYYY-MM-DD" });

  if (
    changeFilterDate?.split(" ")?.[0] &&
    changeFilterDate?.split(" ")?.[0] === currentDate?.split(" ")?.[0]
  ) {
    dataSource.setFilter("createdAt", userFilter.createdAt);
    dataSource.setFilter("docAcceptDate", userFilter.docAcceptDate);
  } else if (changeFilterDate) {
    setDateFilters(userFilter, dataSource);
  }

  dataSource.setValue("withSearch", true);
  dataSource.setSearch(userFilter.searchValue);

  if (userFilter.sort && userFilter.sort !== "{}") {
    dataSource.sort = JSON.parse(userFilter.sort);
  }
};
