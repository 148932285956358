import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import { routerActions } from "react-router-redux";

import { translate } from "react-translate";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core";

import blobToBase64 from "../../helpers/blobToBase64";
// import promiseChain from "../../helpers/promiseChain";
import normalizeClaim from "../../helpers/normalizeClaim";

import styles from "../../variables/styles/claimWizard";
import { generatePdf, setWizardStates } from "../../actions/claim";
import getWizardProps from "../../helpers/getWizardProps";

import { handleCommitDocument } from "./decorators/updateDocument";
import { sendParties } from "../../actions/claim";
import { updateClaimToDraft } from "../../actions/claim";

import ClaimWizardLayout from "./wizardComponents/claimWizardLayout";
import {
  renderForm,
  renderWizard,
  renderSigning,
  renderBody,
} from "./wizardComponents/renders";

import initComponent from "./decorators/initComponent";
import {
  componentDidUpdate,
  UNSAFE_componentWillReceiveProps /* , shouldComponentUpdate */,
} from "./decorators/updateComponent";
import { goToStep } from "./decorators/stepActions";
import {
  handleDecryptDocument,
  handleAddEncryptedPermission,
} from "./decorators/crypt";

class ClaimWizard extends React.Component {
  componentDidMount() {
    const { match, rootComponent, t, history } = this.props;
    setWizardStates({
      blockScreen: true,
      rootComponent,
      t,
      history,
      match,
    });
    initComponent(match, rootComponent);
  }

  componentDidUpdate = componentDidUpdate;
  UNSAFE_componentWillReceiveProps = UNSAFE_componentWillReceiveProps;

  closeSnackbar = (error) => () => {
    const docIsSigned = error && `${error}`.includes("isSign");
    const docIsDeleted = error && `${error}`.includes("isDeleted");
    if (docIsSigned || docIsDeleted) {
      const { rootComponent, claim, dispatch } = getWizardProps();
      setWizardStates({ error: "" });
      return dispatch(
        routerActions.replace(
          `/${rootComponent}${docIsSigned ? `/${claim.id}` : ""}`
        )
      );
    }
    return setWizardStates({ error: "" });
  };
  closeCourtNotFound = () => setWizardStates({ courtNotFound: false });
  closeCommitModal = () => setWizardStates({ claimCommited: false });
  toggleSignModal = () =>
    setWizardStates({ showSignModal: !this.props.showSignModal });
  toggleSelectUser = () =>
    setWizardStates({
      showSelectUsers: false,
    });
  toggleIsSendToUser = (usersToSend, index, checked) => {
    usersToSend[index].isToSend = checked;
    setWizardStates({ usersToSend: [...usersToSend] });
  };

  commitDocument = async () => {
    const { claim } = getWizardProps();
    setWizardStates({
      claim,
      origin: JSON.parse(JSON.stringify(claim)),
    });
    return await handleCommitDocument();
  };

  backToEditClaim = async () => {
    const {
      claim: { id },
    } = getWizardProps();

    const claim = await updateClaimToDraft(id);
    normalizeClaim(claim);
    setWizardStates({
      showSelectUsers: false,
      blockScreen: false,
      claim,
      origin: JSON.parse(JSON.stringify(claim)),
    });
  };

  handleSendToParties = async () => {
    const {
      claim: { id },
      usersToSend,
    } = getWizardProps();

    setWizardStates({
      blockScreen: true,
    });

    const data = [];
    usersToSend.forEach((user) => {
      if (user.isToSend) {
        data.push({
          code: user.code,
          isLegal: user.isLegal,
          name: user.name,
        });
      }
    });

    let claim;
    try {
      claim = await sendParties(id, data);
      if (claim instanceof Error) {
        console.error("error sendParties", claim);
        throw claim;
      }
      claim = normalizeClaim(claim);

      const doc = await generatePdf(claim.id, {
        documentData: claim.digitalDocumentData,
      });
      const pdf = await blobToBase64(doc);

      setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
        pdf,
        busy: false,
        doc,
        blockScreen: false,
        showSelectUsers: false,
      });
    } catch (error) {
      try {
        claim = await updateClaimToDraft(id);
        normalizeClaim(claim);
      } catch (error) {
        console.error(error);
      }
      await setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
        busy: false,
        error: error.message,
        blockScreen: false,
        showSelectUsers: false,
        showSignModal: false,
      });
    }
  };

  setBusy = (busy) => setWizardStates({ busy });
  removePdf = () => {
    const { activeStepId, stepOrders } = getWizardProps();
    const index = activeStepId > 0 ? activeStepId : stepOrders.length - 1;
    goToStep(stepOrders[index]);
    setWizardStates({ pdf: null, activeStepId: index });
  };

  redirect = (path) => () => this.props.history.push(path);

  handleDecryptDocument = handleDecryptDocument.bind(this);
  handleAddEncryptedPermission = handleAddEncryptedPermission.bind(this);

  renderForm = renderForm.bind(this);
  renderWizard = renderWizard.bind(this);
  renderSigning = renderSigning.bind(this);
  renderBody = renderBody.bind(this);

  render() {
    return (
      <ClaimWizardLayout
        {...this.props}
        closeCourtNotFound={this.closeCourtNotFound}
        closeSnackbar={this.closeSnackbar}
        closeCommitModal={this.closeCommitModal}
        redirect={this.redirect}
      >
        {this.renderBody()}
      </ClaimWizardLayout>
    );
  }
}

ClaimWizard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  permissions: PropTypes.array,
  userId: PropTypes.string,
  setId: PropTypes.func,
  memberRoles: PropTypes.array,
  activeClaim: PropTypes.object,
  globalPermissions: PropTypes.array,
  showSignModal: PropTypes.bool.isRequired,
  showSelectUsers: PropTypes.bool.isRequired,
  usersArray: PropTypes.array,
  usersToSend: PropTypes.array,
  activeStepId: PropTypes.number.isRequired,
  stepOrders: PropTypes.array.isRequired,
  rootComponent: PropTypes.string.isRequired,
};

ClaimWizard.defaultProps = {
  setId: setComponentsId("claim-wizard"),
  permissions: null,
  userId: "",
  memberRoles: [],
  globalPermissions: null,
  activeClaim: null,
};

const mapStateToProps = ({
  claim: {
    templates,
    encryptKeys,
    activeClaim,
    backToEditClaim,
    permission,
    permissions: claimPermissions,
    activeTemplate,
    error,
    errors,
    claim,
    manifest,
    template,
    stepOrders,
    loading,
    activeStepId,
    maxStep,
    saving,
    claimSaved,
    claimCommited,
    showSignModal,
    showSelectUsers,
    usersToSend,
    usersArray,
    pdf,
    doc,
    previews,
    blockScreen,
    courtNotFound,
    encryptionKey,
    origin,
    busy,
    redirectTo,
    init,
    pdfError,
  },
  cases: { activeCase, list: casesList },
  permissions: { list: globalPermissions, itemShareList },
  dictionary: { courts, memberRoles },
  authorization: {
    info: { userId },
  },
  datafetched: { loading: dataIsLoading },
}) => {
  return {
    templates,
    globalPermissions,
    courts,
    memberRoles,
    userId,
    activeCase,
    casesList,
    dataIsLoading,
    encryptKeys,
    activeClaim,
    backToEditClaim,
    permission,
    claimPermissions,
    activeTemplate,
    itemShareList,
    error,
    errors,
    claim,
    manifest,
    template,
    // template: {
    //   ...template,
    //   templateData: claim.template.templateData.replaceAll(
    //     /<body style="/,
    //     '<body style="padding-left: 200px; padding-right: 200px;'
    //   ),
    // },
    stepOrders,
    loading,
    activeStepId,
    maxStep,
    saving,
    claimSaved,
    claimCommited,
    showSignModal,
    showSelectUsers,
    usersToSend,
    usersArray,
    pdf,
    doc,
    previews,
    blockScreen,
    courtNotFound,
    encryptionKey,
    origin,
    busy,
    redirectTo,
    init,
    pdfError,
  };
};

// decorate and export
const styled = withStyles(styles)(ClaimWizard);
const translated = translate("ClaimWizard")(styled);
export default connect(mapStateToProps)(translated);
