import { requestUserMeta, addUserMeta } from "../../../actions/usermeta";
import humanDateFormat from "../../../helpers/humanDateFormat";
import { formatUserName } from "../../../helpers/userName";

import dataSource from "./dataSource";

const {
  caseDataSource,
  procDataSource,
  caseDocDataSource,
  procDocDataSource,
  getSourceParams,
  myProcDocDataSource,
} = dataSource;

export function dontAutoSaveState({ target: { checked } }) {
  const {
    userMetaData: {
      meta,
      meta: { cases },
    },
  } = this.props;
  this.setState(
    { activeMark: "", loading: true, openMarkDialog: false },
    () => {
      addUserMeta({
        meta: { ...meta, cases: { ...cases, dontAutoSave: !checked } },
      }).then(() =>
        requestUserMeta().then(() => this.setState({ loading: false }))
      );
    }
  );
}

export function toggleMarkDialog() {
  this.setState({ openMarkDialog: !this.state.openMarkDialog });
}

export function addNewBookmarkName({ target: { value } }) {
  this.setState({ newBookmarkName: value });
}

export function saveBookmark(markName, needReload) {
  return () => {
    const { userMetaData = {} } = this.props;
    if (needReload) {
      this.setState({ loading: true });
    }

    let bookmarks = [];
    if (
      userMetaData &&
      userMetaData.meta &&
      userMetaData.meta.cases &&
      userMetaData.meta.cases.bookmarks
    ) {
      ({
        meta: {
          cases: { bookmarks },
        },
      } = userMetaData);
    }

    const bookmarkData = {
      caseDataParams: getSourceParams(caseDataSource),
      procDataParams: getSourceParams(procDataSource),
      procDocDataParams: getSourceParams(procDocDataSource),
      caseDocDataParams: getSourceParams(caseDocDataSource),
      myProcDocDataParams: getSourceParams(myProcDocDataSource),
      stateParams: { ...this.state, loading: false, blockScreen: false },
    };
    const bookmark = {
      name: markName,
      data: bookmarkData,
      createdAt: new Date(),
    };
    const foundIndex = bookmarks.findIndex(({ name }) => name === markName);
    if (foundIndex >= 0) {
      bookmarks[foundIndex] = bookmark;
    } else {
      bookmarks.push(bookmark);
    }

    const meta = {
      ...userMetaData.meta,
      cases: { ...(userMetaData.meta || {}).cases, bookmarks },
    };

    addUserMeta({ meta }).then(
      () =>
        needReload &&
        requestUserMeta().then(() =>
          this.setState({ loading: false, openMarkDialog: false })
        )
    );
  };
}

export function updateActiveBookmark({ target: { value } }) {
  this.setState({ activeMark: value }, () => {
    this.toggleMarkDialog();
    this.updateFromBookmark(value);
  });
}

export function deleteBookmark({ target: { value } }) {
  const {
    userMetaData: {
      meta,
      meta: {
        cases,
        cases: { bookmarks },
      },
    },
  } = this.props;
  const foundIndex = bookmarks.findIndex(({ name }) => name === value);
  bookmarks.splice(foundIndex, 1);
  this.setState(
    { activeMark: "", loading: true, openMarkDialog: false },
    () => {
      addUserMeta({
        meta: { ...meta, cases: { ...cases, bookmarks } },
      }).then(() =>
        requestUserMeta().then(() => this.setState({ loading: false }))
      );
    }
  );
}

const setParams = (source, params) =>
  Object.keys(params || {}).forEach((key) => {
    if (key !== "baseUrl") {
      source.setValue(key, params[key]);
    }
  });

export function updateFromBookmark(bookmarkName) {
  const {
    userMetaData: {
      meta: {
        cases: { bookmarks },
      },
    },
  } = this.props;
  this.setState({ loading: true });
  const found = bookmarks.find(({ name }) => name === bookmarkName);
  const actions = [];
  if (found) {
    const {
      caseDataParams,
      procDataParams,
      procDocDataParams,
      caseDocDataParams,
      stateParams,
      myProcDocDataParams,
    } = found.data;
    const { checked, procChecked, viewDocument } = stateParams;
    setParams(caseDataSource, caseDataParams);
    setParams(procDataSource, procDataParams);
    setParams(procDocDataSource, procDocDataParams);
    setParams(caseDocDataSource, caseDocDataParams);
    setParams(myProcDocDataSource, myProcDocDataParams);
    actions.push(caseDataSource.load());
    if (checked) {
      actions.push(caseDocDataSource.load());
      actions.push(procDataSource.load(checked));
    }
    if (procChecked) {
      actions.push(procDocDataSource.load());
      actions.push(myProcDocDataSource.load());
    }
    if (actions.length > 0) {
      Promise.all(actions).then(({ message }) => {
        const {
          list,
          proceedingsList,
          procDocuments,
          caseDocuments,
          caseNotProcDoc,
          myProcDocuments,
        } = this.props;
        const allDocs = [].concat(
          procDocuments,
          caseDocuments,
          caseNotProcDoc,
          myProcDocuments
        );
        let error = !!message;
        if (checked)
          error = error || !list || !list.find((i) => (i || {}).id === checked);
        if (procChecked)
          error =
            error ||
            !proceedingsList ||
            !proceedingsList.find((i) => (i || {}).id === procChecked);
        if (viewDocument)
          error =
            error || !allDocs.find((i) => (i || {}).id === viewDocument.id);
        if (error) {
          this.deleteBookmark({ target: { value: bookmarkName } });
          this.setDefaultProps();
        } else {
          this.setState(
            {
              ...stateParams,
              loading: true,
              newBookmarkName: "",
              openMarkDialog: false,
              blockScreen: false,
            },
            () => {
              this.setDataSource();
              this.loadCaseDoc();
            }
          );
        }
      });
    } else {
      this.setState(
        {
          ...stateParams,
          loading: true,
          newBookmarkName: "",
          openMarkDialog: false,
          blockScreen: false,
        },
        () => this.setDataSource()
      );
    }
  } else {
    caseDataSource
      .load()
      .then(() => this.setState({ loading: false, blockScreen: false }));
  }
}

export function addToFavorites() {
  this.setState({ blockScreen: true });
  const { activeCase, display } = this.state;
  const { userMetaData = {}, t, page } = this.props;
  let favorites = [];
  const favorite = {};
  if (userMetaData && userMetaData.meta && userMetaData.meta.favorites) {
    ({
      meta: { favorites },
    } = userMetaData);
  }
  if (display === "caseList") {
    const { caseMembers, id, entityName, createdAt, number } = activeCase;
    const members = caseMembers
      .map(({ companyName, name }) => companyName || formatUserName(name))
      .join(", ");
    const fullName = t("FULL_NAME", {
      number: number || t("NUMBER"),
      createdAt: humanDateFormat(createdAt),
    });
    favorite.id = id;
    favorite.name = t("CASE_NAME_WITH_MEMBERS", {
      fullName,
      members,
    });
    favorite.link = `/${page}/case=${id}`;
    favorite.entityName = entityName;
    favorite.saveToFavorite = new Date();
  }
  favorites.push({ ...activeCase, ...favorite });
  const meta = {
    ...userMetaData.meta,
    favorites,
  };

  addUserMeta({ meta }).then(() =>
    requestUserMeta().then(() => this.setState({ blockScreen: false }))
  );
}

export function deleteFromFavorite() {
  this.setState({ blockScreen: true });
  const { activeCase, display } = this.state;
  const { userMetaData = {} } = this.props;
  const {
    meta: { favorites },
  } = userMetaData;
  if (display === "caseList") {
    const foundIndex = (favorites || []).findIndex(
      ({ id }) => id === activeCase.id
    );
    favorites.splice(foundIndex, 1);
  }
  const meta = {
    ...userMetaData.meta,
    favorites,
  };

  addUserMeta({ meta }).then(() =>
    requestUserMeta().then(() => this.setState({ blockScreen: false }))
  );
}
