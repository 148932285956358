import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";

import { ListItem, ListItemText } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
// import {Tooltip} from 'components';

const TemplateItem = ({
  template: { id, text, name, isPersonal },
  template,
  handleClaimCreate,
  setId,
  t,
  disabledRepresenter,
  classes,
}) => (
  <ListItem
    button={!disabledRepresenter}
    onClick={!disabledRepresenter ? handleClaimCreate(id, template) : undefined}
    id={setId(`list-item-${id}-templateId-${id}`)}
    title={disabledRepresenter ? t("NOT_REPRESENTER") : name}
    className={disabledRepresenter ? classes.disabledItem : ""}
  >
    {isPersonal ? <PersonIcon /> : <DescriptionIcon />}
    <ListItemText primary={text || name} />
  </ListItem>
);

TemplateItem.propTypes = {
  template: PropTypes.object.isRequired,
  setId: PropTypes.func,
  handleClaimCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabledRepresenter: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

TemplateItem.defaultProps = {
  setId: setComponentsId("template-item"),
};

export default TemplateItem;
