import updateObjectValue from "./updateObjectValue";
import updateArrayValue from "./updateArrayValue";
import updateFieldValue from "./updateFieldValue";
import updateAttaches from "./updateAttaches";
import updatePaymentBenefits from "./updatePaymentBenefits";

export default [
  updateAttaches,
  updatePaymentBenefits,
  updateObjectValue,
  updateArrayValue,
  updateFieldValue,
];
