import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import cx from "classnames";
import { withStyles } from "@material-ui/core";

import typographyStyle from "../../variables/styles/typographyStyle.jsx";

const Quote = ({ classes, text, author, setId }) => (
  <blockquote
    className={cx(classes.defaultFontStyle, classes.quote)}
    id={setId("")}
  >
    <p id={setId("text")} className={classes.quoteText}>
      {text}
    </p>
    <small id={setId("author")} className={classes.quoteAuthor}>
      {author}
    </small>
  </blockquote>
);

Quote.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.node,
  author: PropTypes.node,
  setId: PropTypes.func,
};

Quote.defaultProps = {
  text: "",
  author: "",
  setId: setComponentsId("typography-quote"),
};

export default withStyles(typographyStyle)(Quote);
