import React from "react";
import PropTypes from "prop-types";
import { Toolbar, Icon } from "@material-ui/core";
import { Button } from "../../../components";

const FavoritesToolbar = ({
  classes,
  t,
  checked,
  setId,
  deleteFromFavorite,
}) => (
  <Toolbar
    className={classes.toolbar}
    disableGutters={true}
    id={setId("toolbar")}
  >
    <Button
      color="transparent"
      disabled={!checked.length}
      onClick={deleteFromFavorite}
      id={setId("toolbar-info-button")}
      className={classes.mobileButtonFullWidth}
      setId={(elementName) => setId(`toolbar-info-${elementName}`)}
    >
      <Icon>star_border</Icon>&nbsp;&nbsp;
      <span>{t("UN_FAVORITES_BUTTON")}</span>
    </Button>
  </Toolbar>
);

FavoritesToolbar.propTypes = {
  checked: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  deleteFromFavorite: PropTypes.func.isRequired,
};

export default FavoritesToolbar;
