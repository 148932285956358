export const OPEN_APP_DIALOG = "appDialog/OPEN";
export const CLOSE_APP_DIALOG = "appDialog/CLOSE";

export enum ButtonType {
  OK = "ok",
  YES = "yes",
  NO = "no",
  CANCEL = "cancel",
}

export type ApplicationDialogButton = {
  text: string;
  value: ButtonType;
  color: "danger" | "yellow" | "normal" | "transparent";
};

export type ApplicationDialogState = {
  title: string;
  text: string;
  buttons: ApplicationDialogButton[];
  open: boolean;
  resolveDialog: null | ((b: ButtonType) => void);
};
