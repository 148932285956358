import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Icon, List, ListItem, ListItemText } from "@material-ui/core";

const TemplatesSubCategory = ({
  cat,
  classes,
  open,
  groupIndex,
  searchText,
  children,
  toggleGroup,
}) => (
  <List className={classes.groupWrap}>
    <ListItem
      button={true}
      onClick={toggleGroup(cat.categoryId, open, groupIndex)}
    >
      <Icon className={classes.subCatIcon}>folder_open</Icon>
      <ListItemText className={classes.subCatText} primary={cat.name} />
      {!searchText && <Icon>{open ? "arrow_drop_up" : "arrow_drop_down"}</Icon>}
    </ListItem>
    {open && (
      <ListItem className={classes.groupWrap}>
        <div>{children}</div>
      </ListItem>
    )}
  </List>
);

TemplatesSubCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  cat: PropTypes.object.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  toggleGroup: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

TemplatesSubCategory.defaultProps = {
  children: <Fragment />,
};

export default TemplatesSubCategory;
