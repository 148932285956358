import filterUnexists from "../helpers/filterUnexists";

export default ({
  courts,
  path,
  justiceType,
  courtType,
  region,
  filters: { docTypeCourts } = {},
}) =>
  Array.isArray(courts)
    ? courts.filter(
        (court) =>
          (!!court.isEnabled || (path !== "court" && path !== "court.court")) &&
          (!Array.isArray(docTypeCourts) ||
            !docTypeCourts.length ||
            (docTypeCourts || [])
              .map((filter) => {
                let accepted = true;

                let justiceTypeFilter = filter.justiceType || null;
                if (justiceType !== undefined) {
                  justiceTypeFilter = justiceType;
                }

                let courtTypeFilter = filter.courtType || null;
                if (courtType !== undefined) {
                  courtTypeFilter = courtType;
                }

                let regionFilter = filter.region || null;
                if (region !== undefined) {
                  regionFilter = region;
                }

                if (
                  accepted &&
                  filterUnexists(court.justiceTypeId, justiceTypeFilter)
                ) {
                  accepted = false;
                }

                if (
                  accepted &&
                  filterUnexists(court.courtTypeId, courtTypeFilter)
                ) {
                  accepted = false;
                }

                if (accepted && filterUnexists(court.regionId, regionFilter)) {
                  accepted = false;
                }

                return accepted;
              })
              .filter(Boolean).length > 0)
      )
    : [];
