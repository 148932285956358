export class Waiter {
  actions = {};
  handleFinish = () => {};

  async onFinish(handleFinish, key) {
    if (this.actions[key]) {
      this.handleFinish = handleFinish;
    }
  }

  updateStatus = async () => {
    if (Object.keys(this.actions).length) {
      return;
    }

    this.handleFinish();
  };

  addAction = async (key, action, time) => {
    if (this.actions[key]) {
      clearTimeout(this.actions[key].timer);
    }

    this.actions[key] = {
      timer: setTimeout(() => this.run(key), time),
      action,
    };
  };

  run = async (key) => {
    if (!this.actions[key]) {
      return;
    }
    const { action } = this.actions[key];
    await action();
    this.handleFinish();
  };
}

export default new Waiter();
