import DataSource from "../helpers/dataSource";
import store from "../store";

export const ADD_ACTIVE_MESSAGE = "ADD_ACTIVE_MESSAGE";

const dataSource = DataSource("api/messages/my");

const ADD_SNACKBAR_MESSAGE = "ADD_SNACKBAR_MESSAGE";
const DELETE_SNACKBAR_MESSAGE = "DELETE_SNACKBAR_MESSAGE";
const REQUEST_MY_MESSAGES = "DATA_SOURCE/REQUEST_API_MESSAGES/MY_SUCCESS";
const initialState = {
  list: null,
  snackbar: [],
  isViewed: [],
  notViewed: [],
  activeMessage: null,
};

const structure = (list) => {
  const { meta } = list;
  let isViewed = null;
  let notViewed = null;
  if (!meta || !("unread" in meta)) {
    let viewed = [];
    const {
      usermeta: { userMetaData },
    } = store.getState();
    if (
      userMetaData &&
      userMetaData.meta &&
      userMetaData.meta.viewedNotification
    ) {
      ({
        meta: { viewedNotification: viewed },
      } = userMetaData);
    }
    ({ isViewed, notViewed } = Array.isArray(list)
      ? list.reduce(
          (acc, note) => {
            const found = viewed.some((item) => item === note.messageId);
            const key = found ? "isViewed" : "notViewed";
            note.viewed = found;
            note.haveDeletePermission = !found;
            acc[key].push(note);
            return acc;
          },
          {
            isViewed: [],
            notViewed: [],
          }
        )
      : {
          isViewed: [],
          notViewed: [],
        });
    list = notViewed.concat(isViewed);
    if (meta) {
      isViewed.meta = meta;
      notViewed.meta = meta;
      list.meta = meta;
    }
  } else {
    list = list.map((item) => {
      item.id = item.messageId;
      return item;
    });
    list.meta = meta;
  }
  return {
    list,
    isViewed,
    notViewed,
  };
};

export default (state = initialState, action) => {
  state = dataSource.reducer()(state, action);
  const { snackbar } = state;

  switch (action.type) {
    case ADD_SNACKBAR_MESSAGE: {
      action.payload.id = action.payload.id || new Date().getTime();
      return {
        ...state,
        snackbar: snackbar.concat([action.payload]),
      };
    }
    case DELETE_SNACKBAR_MESSAGE: {
      snackbar.splice(
        snackbar.indexOf(snackbar.find(({ id }) => id === action.payload)),
        1
      );
      return {
        ...state,
        snackbar,
      };
    }
    case REQUEST_MY_MESSAGES: {
      return { ...state, ...structure(action.payload) };
    }
    case ADD_ACTIVE_MESSAGE:
    case "REQUEST_ACTIVE_MESSAGE_SUCCESS": {
      return {
        ...state,
        activeMessage: action.payload,
      };
    }
    default:
      return state;
  }
};
