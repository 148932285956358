import { FC } from "react";
import { compose } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";

import cx from "classnames";
import { IAuth } from "../../../types/auth.interface";
import { Button } from "../../../components";
import humanDateFormat from "../../../helpers/humanDateFormat";
import { addEDRRecipe } from "../../../actions/permissions";
interface Props {
  toggleDialog: (val: string) => any;
  classes: any;
  t: (val: string) => any;
  setId: (val: string) => any;
  auth?: IAuth;
  changedShare: any;
  openCreateDialog: boolean;
  handleDelete_Edr_Recipe_Without_Refresh: any;
  refreshAfterDeleteEdr: any;
  history?: any;
}

const EDRRecipeCreateDialog: FC<Props & RouteComponentProps> = ({
  toggleDialog,
  classes,
  t,
  setId,
  changedShare,
  openCreateDialog,
  handleDelete_Edr_Recipe_Without_Refresh,
  refreshAfterDeleteEdr,
  history,
}) => {
  const createRecipeHandler = async () => {
    try {
      await handleDelete_Edr_Recipe_Without_Refresh(
        changedShare?.id,
        true,
        true
      );
      const data = await addEDRRecipe();
      if (data?.edrCheckResult === 0 || data?.edrCheckResult === -1) {
        alert("Не вдалося підтвердити повноваження керівника");
        history?.go(0);
        // logout();
      }
      toggleDialog("createEDRRecipe");
      await refreshAfterDeleteEdr();
    } catch (err) {
      console.log("err: ", err);
      alert("Не вдалося переформувати виписку з ЄДР");
      history?.go(0);
      // logout();
    }
  };

  return (
    <>
      <Dialog
        open={openCreateDialog}
        fullWidth={true}
        onClose={toggleDialog("createEDRRecipe")}
        id={setId("wrapper")}
        className={cx(classes.dialog, classes.fullWidth)}
      >
        <DialogContent
          className={cx(classes.content, classes.dialogContentWrappers)}
        >
          <Typography
            variant="subtitle1"
            className={classes.heading}
          ></Typography>
          <Typography variant="subtitle1" className={classes.body1}>
            <p>
              Буде анульовано існуючу виписку з ЄДР від{" "}
              {changedShare?.allowedByName}{" "}
              {humanDateFormat(changedShare?.updatedAt)} на{" "}
              {changedShare?.userName} та всі довіреності, видані шляхом
              передоручення вказаної виписки.
            </p>
            Також буде сформована нова виписка з ЄДР.
          </Typography>
        </DialogContent>
        <DialogActions
          className={cx(classes.actions, classes.dialogContentWrappers)}
        >
          <Button
            onClick={toggleDialog("createEDRRecipe")}
            color="transparent"
            mr={2}
          >
            {t("CANCEL_ACTION")}
          </Button>
          <Button onClick={createRecipeHandler} color="yellow">
            {t("CONTINUE")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withRouter)(EDRRecipeCreateDialog);
