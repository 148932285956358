import { generatePdf, setWizardStates } from "../../../actions/claim";
import { requestPermissionsList } from "../../../actions/permissions";
import blobToBase64 from "../../../helpers/blobToBase64";
import promiseChain from "../../../helpers/promiseChain";
import getWizardProps from "../../../helpers/getWizardProps";
import validateStep from "./validateStep";
import loadClaim from "./loadClaim";
// import getCourtId from "./getCourtId";
import { goToStep } from "./stepActions";

export default async function handleFinish() {
  const { claim, activeStepId, stepOrders, rootComponent } = getWizardProps();
  setWizardStates({ blockScreen: true });
  let errors = {};
  try {
    stepOrders.forEach((orderStepName, orderStepId) => {
      errors = validateStep(orderStepId);
      if (Object.keys(errors).length > 0) {
        if (orderStepId !== activeStepId) {
          goToStep(orderStepName, rootComponent);
        }
        throw new Error();
      }
    });
  } catch (e) {
    // Nothing to do. Error is for break
  }

  if (!Object.keys(errors).length) {
    const courtId = claim.courtId; //claim.category !== 5 ? await getCourtId() : null;
    if (!courtId && claim.category !== 5) {
      setWizardStates({ error: "NOT_GET_COURTID", blockScreen: false });
    } else {
      promiseChain([
        () => setWizardStates({ pdf: null, busy: true, error: null }),
        () => loadClaim(claim.id),
        (data) => {
          setWizardStates({
            claim: data,
            origin: JSON.parse(JSON.stringify(data)),
          });

          return generatePdf(claim.id, {
            documentData: data.digitalDocumentData,
          });
        },
        (doc) =>
          promiseChain([
            requestPermissionsList,
            () => blobToBase64(doc),
            (pdf) =>
              setWizardStates({
                activeStepId: -1,
                pdf,
                busy: false,
                doc,
                blockScreen: false,
              }),
          ]),
        () => goToStep(stepOrders[stepOrders.length - 1], rootComponent),
      ]).catch((e) =>
        setWizardStates({
          error: e.message,
          pdf: null,
          busy: false,
          blockScreen: false,
        })
      );
    }
  } else {
    setWizardStates({
      errors,
      busy: false,
      blockScreen: false,
      error: errors.error || "",
    });
  }
}
