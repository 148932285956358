import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { PersonalDataChangedHandler, PersonalDataState } from "./types";

interface Props {
  values: PersonalDataState;
  readOnly: boolean;
  t: (s: string) => string;
  handleInterfaceChanged: PersonalDataChangedHandler;
}

const InterfaceLayout: React.FC<Props> = ({
  t,
  values,
  handleInterfaceChanged,
  readOnly,
}) => {
  return (
    <>
      <div style={{ textAlign: "left", marginTop: 10, fontSize: "10pt" }}>
        ІНТЕРФЕЙС:
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.showAttachesPreview}
            disabled={readOnly}
            onChange={(_, checked) => {
              handleInterfaceChanged("showAttachesPreview", checked);
            }}
            name={"showAttachesPreview"}
            color="primary"
          />
        }
        label={t("SHOW_ATTACHES_PREVIEW")}
      />
      <div style={{ textAlign: "left", marginBottom: 5, fontSize: "10pt" }}>
        ПОВІДОМЛЕННЯ:
      </div>

      <div style={{ display: "flex" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.notSendIndividual}
              disabled={readOnly}
              onChange={(_, checked) => {
                handleInterfaceChanged("notSendIndividual", checked);
              }}
              name={"notSendIndividual"}
              color="primary"
            />
          }
          label={t("DONT_GET_MESSAGE_AS_MEMBER")}
        />
      </div>

      <div style={{ display: "flex" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.notSendRepresantative}
              disabled={readOnly}
              onChange={(_, checked) => {
                handleInterfaceChanged("notSendRepresantative", checked);
              }}
              name={"notSendRepresantative"}
              color="primary"
            />
          }
          label={t("DONT_GET_MESSAGE_AS_REPRESENTATIVE")}
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.onlyProcedural}
            disabled={readOnly}
            onChange={(_, checked) => {
              handleInterfaceChanged("onlyProcedural", checked);
            }}
            name={"onlyProcedural"}
            color="primary"
          />
        }
        label={t("GET_MESSAGE_ONLY_PROCEDURAL_DOCUMENTS")}
      />
      <div style={{ display: "flex" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.emailAttachments}
              disabled={readOnly}
              onChange={(_, checked) => {
                handleInterfaceChanged("emailAttachments", checked);
              }}
              name={"emailAttachments"}
              color="primary"
            />
          }
          label={t("GET_DOCUMENTS_AS_ATTACHS_AT_EMAIL_DOCUMENTS")}
        />
      </div>
    </>
  );
};

export default InterfaceLayout;
