import ellementsTranslate from "./Elements";

export default {
  ...ellementsTranslate,
  POLICE_LIST: "Клопотання",
  POLICE_LIST2: "Клопотання слідчого",
  APPROVAL_DOCUMENT_LIST: "Погодження",
  APPROVAL_DOCUMENT_LIST2: "Документи на погодження",
  NABU_LIST: "Клопотання",
  NABU_LIST2: "Клопотання прокурора",
  CLAIM_LIST: "Заяви",
  CLAIM_LIST2: "Список доступних заяв",
  SEARCH_PLACEHOLDER: "Пошук шаблона",
  TEMPLATE_SEARCH_HELPER: "Почніть вводити назву заяви",
  DECISION_SEARCH_HELPER: "Почніть вводити назву рішення",
  EXECUTIVE_SEARCH_HELPER: "Почніть вводити назву документа",
  CLAIM_TRASH_LIST: "Кошик",
  CLAIM_TRASH_LIST2: "Список видалених заяв",
  CREATE_NEW: "Створити",
  CREATE_NEW_DOCUMENT: "Створити документ",
  CREATE_NEW_CLAIM: "Створити заяву",
  DOCUMENT_TITLE: "Назва",
  DOCUMENT_STATUS: "Статус",
  VP_STATE_ID_STATUS: "Стан провадження",
  DOCUMENT_FORM: "Форма видачі стягувачу",
  CASE_MEMBER_NAME_FILTER: "ПІБ/назвa сторони",
  CASE_ROLE: "Мій процесуальний статус у справі",
  DOCUMENT_CREATING_DATE: "Дата створення",
  SELECT_TEMPLATE: "Виберіть заяву",
  SELECT_DECISION: "Виберіть шаблон рішення",
  SELECT_EXECUTIVE: "Виберіть тип електронного виконавчого документа",
  TEMPLATE_COUNT: "Всього {{count}} заяв.",
  DECISION_COUNT: "Всього {{count}} шаблонів рішення",
  EXECUTIVE_COUNT:
    "Всього {{count}} шаблонів електронного виконавчого документа",
  TEMPLATE_SEARCH_ERROR:
    "Не знайшли потрібну заяву? Надішліть назву, опис та, за можливості, приклад шаблону на {{mail_new}}.",
  DECISION_SEARCH_ERROR:
    "Не знайшли потрібний шаблон рішення? Надішліть назву, опис та, за можливості, приклад шаблону на {{mail_new}}.",
  EXECUTIVE_SEARCH_ERROR:
    "Не знайшли потрібний шаблон електронного виконавчого документа? Надішліть назву, опис та, за можливості, приклад шаблону на {{mail_new}}.",
  CANCEL: "Закрити",
  DELETE: "Видалити",
  TOTAL_DELETE: "Видалити назавжди",
  CAN_NOT_UNDONE: "Цю дію неможливо відмінити",
  RECOVER: "Відновити",
  DELETE_CLAIMS_TITLE: "Ви дійсно бажаєте видалити заяви ({{count}})?",
  DELETE_PERMANENT_CLAIMS_TITLE:
    "Ви дійсно бажаєте видалити заяви ({{count}}) назавжди?",
  DELETE_CLAIM_TITLE: "Ви дійсно бажаєте видалити заяву?",
  DELETE_PERMANENT_CLAIM_TITLE: "Ви дійсно бажаєте видалити заяву назавжди?",
  DELETE_CLAIM_TEXT:
    "Заява буде переміщена в кошик. В будь-який момент її можна буде відновити.",
  DELETE_CLAIMS_TEXT:
    "Заяви будуть переміщені в кошик. В будь-який момент їх можна буде відновити.",
  DELETING_FALSE: "Деякі заяви не переносяться у кошик",
  FROM_DATE: "З дати",
  FROM_СREATED_DATE: "З дати надходження",
  FROM_DOC_DATE: "З дати документа",
  FROM_DNZS_DATE: "З ДНЗС",
  FROM_DOC_ACCEPT_DATE: "З дати постанови",
  FROM_CHANGE_DATE: "З дати зміни",
  TO_DATE: "По дату",
  TO_СREATED_DATE: "По дату надходження",
  TO_DOC_DATE: "По дату документа",
  TO_CHANGE_DATE: "По дату зміни",
  TO_DNZS_DATE: "По ДНЗС",
  TO_DOC_ACCEPT_DATE: "По дату постанови",
  CORRECT_DATE: "За дату",
  СREATED_DATE: "За дату надходження",
  DOC_DATE: "За дату документа",
  DNZS_DATE: "За ДНЗС",
  DOC_ACCEPT_DATE: "За дату постанови",
  CHANGE_DATE: "За дату зміни",
  FILTERS: "Фільтрація",
  DISPLAYED_CLAIMS: "с {{from}} по {{to}} із {{total}}",
  COUNT_CLAIMS: "Відображати:",
  TOTAL: "Всього: {{total}}",
  OWNER: "Заявник/Представник",
  PERMISSION_TITLE0: "Подача заяви",
  PERMISSION_TITLE1: "Довіреності",
  PERMISSION_TITLE_SHARE_ORDER: "Довіреності/Ордери",
  SELECT_PERMISSION_TYPE: "Я подаю заяву",
  NOT_HAVE_PERMISSIONS: "У Вас відсутні довіреності з правом редагування.",
  NOT_HAVE_ORDER_PERMISSIONS: "У Вас відсутні ордери з правом редагування.",
  SHARE_PERMISSION_HELPER_TEXT:
    "Для створення нового ордеру перейдіь у розділ «Довіреності» та виберіть «Створити ордер»",
  PERMISSION_OWNER: "Особисто",
  PERMISSION_NOT_OWNER: "Як представник (захисник)",
  PERMISSION_LABEL: "Вкажіть довірителя",
  PERMISSION_LABEL_ORDER: "Вкажіть кому надається правова допомога",
  BACK: "Повернутись",
  NEXT: "Наступний крок",
  DELETING_STATUS: "Видалення",
  CREATING_STATUS: "Чернетка",
  SIGNING_STATUS: "Підписання",
  READY_TO_SEND_STATUS: "Відправлено",
  SENDED_STATUS: "Відправлено",
  SENDED_TO_PARTIES: "Надіслано сторонам",
  SENDING_ERROR_STATUS: "Помилка відправлення", // Помилка обробки
  RECEIVING_ERROR_STATUS: "Помилка прийому", // Помилка обробки
  JOINED_TO_CAUSE: "Приєднано до справи", // Помилка обробки
  RECEIVED_STATUS: "Доставлено",
  REGISTER_REFUSED_STATUS: "Відмовлено в реєстрації",
  REGISTER_ERROR_STATUS: "Помилка реєстрації", // Помилка обробки
  WAITING_FOR_REGISTER_STATUS: "Зареєстровано",
  REGISTERED_STATUS: "Зареєстровано",
  COURT_IN_WORK_STATUS: "Створено картку справи",
  VIDEO_LINK: "Відеоінструкція",
  ELECTRONIC_AUTHORIZATION: "Електронна довіреність {{tip}}",
  ELECTRONIC_AUTHORIZATION_HINT:
    "Оберіть цей варіант, якщо Вам надано електронну довіреність, та вкажіть довірителя із списку нижче",
  ELECTRONIC_ORDER: "Електронний ордер {{tip}}",
  ELECTRONIC_ORDER_HINT:
    "Оберіть цей варіант, якщо Вам надано електронний ордер, та вкажіть довірителя із списку нижче",
  AUTHORIZATION_SCAN:
    "Копія документа, який підтверджує повноваження представника {{tip}}",
  AUTHORIZATION_SCAN_HINT:
    'Оберіть цей варіант, якщо документ, який підтверджує ваші повноваження (довіреність, ордер, договір про надання правової допомоги, доручення на надання безоплатної правової допомоги), наявний у паперовому вигляді (для адвокатів, інших представників, якщо їхні довірителі не зареєстровані в підсистемі "Електронний суд" або не надали електронну довіреність).',
  FILE_NAME: "Назва файлу",
  FILE_SIZE: "Розмір",
  COURT_TYPE: "Інстанція",
  JUSTICE_TYPE: "Юрисдикція",
  CASE_COURT: "Назва суду",
  FULL_NAME: "{{number}} від {{createdAt}}",
  ERROR_TYPE_FILE: "Не коректний тип файлу",
  ERROR_SIZE_FILE: "Завеликий файл (не більше {{maxSize}} Мb)",
  EXECUTIVE: "Виконавчі документи",
  EXECUTIVE2: "Список виконавчих документів",
  REGIONS: "Регіони",
  UNKNOWN_FORMAT:
    "Виникла проблема з попереднім переглядом, але ви можете завантажити файл на свій комп'ютер.",
  PREVIEW: "попередній перегляд",
  NOT_SUPPORTED: "Формат файлу не підтримується вашим браузером.",
  DOWNLOAD_ONLY: "Файл тільки для скачування",
  TRASH_EMPTY_PAGE_TITLE: "Кошик порожній",
  TRASH_EMPTY_PAGE_DESCRIPTION:
    "Видалені документи потрапляють в кошик. Ви зможете відновити їх або видалити назавжди",
  CLAIMS_EMPTY_PAGE_TITLE: "Заяв немає",
  CLAIMS_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись ваші заяви та заяви, до яких вам надано доступ",
  EXECUTIVE_EMPTY_PAGE_TITLE: "Виконавчих документів немає",
  EXECUTIVE_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись ваші виконавчі документи та виконавчі документи, до яких вам надано доступ",
  POLICE_EMPTY_PAGE_TITLE: "Клопотаннь слідчого немає",
  POLICE_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись ваші клопотання та клопотання, до яких вам надано доступ",
  NABU_EMPTY_PAGE_TITLE: "Клопотання прокурора",
  NABU_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись ваші клопотання та клопотання, до яких вам надано доступ",
  AS_INDIVIDUAL_ENTREPRENEUR: "Як фізична особа-підприємець",
  DONT_HAVE_DELETE_PERMISSIONS: "Немає прав на видалення",
  ADD_TO_DELETE: "Додати на видалення",
  TEMPLATE_DIALOG_HEADER: "Конструктор запитів",
  TEMPLATE_DIALOG_HELP_TEXT:
    'Введіть слова або фрази. Для відокремлення натисніть клавішу "Enter" або кнопку "+".',
  SEARCH: "Пошук",
  CLEAT_FILTER: "Очистити",
  ENVELOP_USES:
    "Цей документ використовує шифрування. Будь ласка, скористайтесь своїм особистим ключем.",
  NOT_FOUND: "За вашим запитом нічого не знайдено",
  COURT_NAME: "Суд, до якого направлено заяву",
  "Unexpected end of JSON input": "Шаблон заяви пошкоджений",
  "Invalid JSON schema.": "Шаблон заяви пошкоджений",
  NOT_REPRESENTER: "Шаблон недоступний для подання як представник (захисник)",
  EXECUTIVE_CREATING_STATUS: "Не підлягає виконанню",
  EXECUTIVE_NOT_SUBMITTED: "Не пред'явлений до виконання",
  EXECUTIVE_READY_TO_SUBMIT: "Очікує прийняття до виконання",
  EXECUTIVE_SUBMITING: "Перебуває на виконанні",
  EXECUTIVE_TIMESTOPPED: "Виконання зупинено",
  EXECUTIVE_STOPPED: "Виконання припинено",
  EXECUTIVE_COMPLETE: "Виконання закінчено",
  RECORDER: "Реєстратор",
  COLLECTOR: "Стягувач",
  DEBTOR: "Боржник",
  EXECUTIVE_UNKNOWN_ERROR:
    "Сталася помилка, зверніться до технічної підтримки.",
  UNKNOWN_ERROR: "Сталася помилка, спробуйте пізніше.",
  EDIT_CHILD_CLAIM: "Редагувати заяву",
  VIEW_CHILD_CLAIM: "Переглянути заяву",
  NoEncodeCert: "Сертифікат шифрування відсутній",
  ERDR_NUMBER: "Номер в ЄРДР",
  CASE_PROC_NUMBERS: "Справа № / Провадження №",
  SELECT_ALL: "Вибрати все",
  SUBMIT_TO_FIRST_INSTANCE: "В першу інстанцію {{tip}}",
  SUBMIT_TO_FIRST_INSTANCE_HINT:
    "Оберіть цей варіант, якщо Вам потрібно оскаржити рішення в першій інстанції",
  SUBMIT_TO_APEAL_INSTANCE: "В апеляційну інстанцію {{tip}}",
  SUBMIT_TO_APEAL_INSTANCE_HINT:
    "Оберіть цей варіант, якщо Вам потрібно оскаржити рішення в апеляційному суді",
};
