import * as api from "../services/api";
import store from "../store";

const { dispatch } = store;

const REQUEST_BENEFIT_TYPES = "PAYMENTS/REQUEST_BENEFIT_TYPES";
const REQUEST_PART_TYPES = "PAYMENTS/REQUEST_PART_TYPES";
const REQUEST_TRANSACTION_TYPES = "PAYMENTS/REQUEST_TRANSACTION_TYPES";
const REQUEST_PAYMENT_FORM = "PAYMENTS/REQUEST_PAYMENT_FORM";
const UPDATE_PAYMENT_INFO = "PAYMENTS/UPDATE_PAYMENT_INFO";

const PAYMENT_URL = "api/dictionaries/payment";
const DOCUMENTS_URL = "api/documents";

export function requestBenefitTypes() {
  return api.get(
    `${PAYMENT_URL}/benefit_types`,
    REQUEST_BENEFIT_TYPES,
    dispatch
  );
}

export function requestPartTypes() {
  return api.get(
    `${PAYMENT_URL}/payment_part_types`,
    REQUEST_PART_TYPES,
    dispatch
  );
}

export function requestTransactionTypes() {
  return api.get(
    `${PAYMENT_URL}/transaction_types`,
    REQUEST_TRANSACTION_TYPES,
    dispatch
  );
}

export function requestPaymentForm(claimId, params) {
  const paramString = !params
    ? ""
    : `?${new URLSearchParams(params).toString()}`;
  return api.get(
    `${DOCUMENTS_URL}/${claimId}/payment_form${paramString}`,
    REQUEST_PAYMENT_FORM,
    dispatch
  );
}

export function updatePaymentInfo(claimId, data) {
  return api.post(
    `${DOCUMENTS_URL}/${claimId}/payment_info`,
    data,
    UPDATE_PAYMENT_INFO,
    dispatch
  );
}
