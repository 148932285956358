import React from "react";
import setComponentsId from "../../helpers/setComponentsId";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CardActions,
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";
import VideoManual from "../videoManual/VideoManual";

import regularCardStyle from "../../variables/styles/regularCardStyle";

const RegularCard = ({
  classes,
  headerColor,
  plainCard,
  cardTitle,
  cardSubtitle,
  content,
  footer,
  setId,
  style,
  children,
  isLastComponent,
  wikiUrl,
}) => {
  return (
    <Card
      className={cx(
        classes.card,
        plainCard && classes.cardPlain,
        !isLastComponent && classes.notLastCard
      )}
      id={setId ? setId("card") : ""}
    >
      <CardHeader
        classes={{
          root: cx(
            classes.cardHeader,
            classes[headerColor + "CardHeader"],
            plainCard && classes.cardPlainHeader
          ),
          title: classes.cardTitle,
          subheader: classes.cardSubtitle,
        }}
        id={setId ? setId("card-header") : ""}
        title={
          wikiUrl ? (
            <VideoManual
              videoUrl=""
              wikiUrl={wikiUrl}
              setId={setId}
              title={cardTitle}
            />
          ) : (
            cardTitle
          )
        }
        subheader={cardSubtitle}
      >
        {wikiUrl && <VideoManual videoUrl="" wikiUrl={wikiUrl} setId={setId} />}
      </CardHeader>
      <CardContent style={style} id={setId ? setId("card-content") : ""}>
        {content}
        {children}
      </CardContent>
      {footer && (
        <CardActions
          className={classes.cardActions}
          id={setId ? setId("card-footer") : ""}
        >
          {footer}
        </CardActions>
      )}
    </Card>
  );
};
RegularCard.propTypes = {
  setId: PropTypes.func,
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "primary",
    "orange",
    "green",
    "red",
    "blue",
    "purple",
  ]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  style: PropTypes.object,
  children: PropTypes.node,
  isLastComponent: PropTypes.bool,
  wikiUrl: PropTypes.string,
};

RegularCard.defaultProps = {
  headerColor: "primary",
  plainCard: false,
  cardTitle: "",
  cardSubtitle: "",
  footer: "",
  content: "",
  children: "",
  setId: setComponentsId("regular-card"),
  style: {},
  isLastComponent: true,
  wikiUrl: "",
};

export default withStyles(regularCardStyle)(RegularCard);
