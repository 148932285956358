import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";

import appStyle from "../../variables/styles/appStyle";

import { Grid, withStyles } from "@material-ui/core";
import { RegularCard, ItemGrid, Preloader, Button } from "../../components";
import getUrlParams from "../../helpers/getUrlParams";
import { isLoggedInCompletely, requestAuth } from "../../actions/auth";

import config from "../../config";

import { translate } from "react-translate";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const { authLink } = config();

export class Login extends React.Component {
  mainPanel = null;
  state = { loading: false, isLoggedCompletely: false };

  UNSAFE_componentWillMount() {
    this.setLoading();
  }

  componentDidUpdate() {
    if (this.mainPanel) this.mainPanel.scrollTop = 0;
  }

  UNSAFE_componentWillReceiveProps({ info, tokenError }) {
    if (this.props.info !== info) {
      this.setState({ isLoggedCompletely: isLoggedInCompletely() });
    }
    if (this.props.tokenError !== tokenError) {
      this.setState({ loading: !tokenError });
    }
  }

  setLoading() {
    const { search } = this.props.location;
    const { code } = getUrlParams(search);
    if (code) {
      this.setState({ loading: true });
      requestAuth(code);
    }
  }

  render() {
    const { t, classes, location, setId, tokenError } = this.props;
    const { loading, isLoggedCompletely } = this.state;
    if (isLoggedCompletely) {
      return <Redirect to={{ pathname: "/", state: { from: location } }} />;
    }

    return (
      <div className={classes.wrapper} id={setId("all-wrapper")}>
        <div
          className={classes.noSidebarContent}
          ref={(ref) => {
            this.mainPanel = ref;
          }}
          id={setId("main-panel")}
        >
          <Grid container={true} id={setId("container")}>
            <ItemGrid xs={12} sm={12} md={4} />
            <ItemGrid xs={12} sm={12} md={4}>
              <RegularCard setId={setId} cardTitle={t("TITLE")}>
                {loading && <Preloader />}

                {!loading && (
                  <Button
                    href={authLink || "/redirect/auth"}
                    color="yellow"
                    id={setId("auth-button")}
                    setId={(elementName) => setId(`auth-${elementName}`)}
                  >
                    {t("AUTHORIZE")}
                  </Button>
                )}
                {tokenError && <p>{t("TOKEN_ERROR")}</p>}
              </RegularCard>
            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={4} />
          </Grid>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,
  info: PropTypes.object,
  tokenError: PropTypes.bool.isRequired,
};

Login.defaultProps = {
  location: {},
  setId: setComponentsId("login-page"),
  info: null,
};

const styled = withStyles(appStyle)(translate("LoginPage")(Login));

const mapStateToProps = ({ authorization }) => authorization;

// decorate and export
export default connect(mapStateToProps)(styled);
