import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles, FormControl, InputLabel } from "@material-ui/core";
import { DateTimePicker } from "material-ui-pickers";
import { Clear, Check } from "@material-ui/icons";
import cx from "classnames";
import setComponentsId from "../../helpers/setComponentsId";

import customInputStyle from "../../variables/styles/customInputStyle";

const CustomDateTimePicker = ({
  classes,
  formControlProps,
  labelText,
  id: propId,
  setId: propSetId,
  labelProps,
  inputProps,
  error,
  success,
}) => {
  const labelClasses = cx(
    success && !error && classes.labelRootSuccess,
    error && classes.labelRootError
  );

  const setId = propSetId || setComponentsId("date-time-picker");
  const id = propId ? setId(` ${propId}`) : setId("");
  return (
    <FormControl
      {...formControlProps}
      className={cx("CustomInput", classes.formControl)}
      id={setId("form")}
    >
      {labelText && (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          id={setId("label")}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      )}
      <DateTimePicker id={id} {...inputProps} />
      {error && (
        <Fragment>
          <Clear className={cx(classes.feedback, classes.labelRootError)} />
          {success && (
            <Check className={cx(classes.feedback, classes.labelRootSuccess)} />
          )}
        </Fragment>
      )}
    </FormControl>
  );
};

CustomDateTimePicker.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  success: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  formControlProps: PropTypes.object,
};

CustomDateTimePicker.defaultProps = {
  setId: () => null,
  labelText: "",
  labelProps: {},
  inputProps: {},
  success: false,
  error: "",
  id: "",
  formControlProps: {},
};

export default withStyles(customInputStyle)(CustomDateTimePicker);
