import React, { Component } from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

import { StandardTextFieldProps } from "@material-ui/core/TextField";
import { BaseControlProps, formControlStyle } from "../types";
// import InputMask from "../components/InputMask";

const styles = (theme: any) => ({
  ...formControlStyle,
});

interface BooleanControlProps
  extends BaseControlProps<StandardTextFieldProps> {}

interface BooleanControlState {
  // value: string;
}

class BooleanControl extends Component<
  BooleanControlProps,
  BooleanControlState
> {
  static defaultProps: Partial<BooleanControlProps> = {};

  constructor(props: BooleanControlProps) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    const { onFinish, name, value } = this.props;
    onFinish && onFinish(name, value);
  }

  handleChange = (event: any) => {
    const value = event.target.checked;
    const { onChange, name } = this.props;
    onChange && onChange(name, value);
  };

  render() {
    const {
      classes,
      disabled,
      errors,
      // error,
      hidden,
      name,
      readOnly,
      required,
      schema,
      setId,
      value,
    } = this.props;

    return (
      <div style={{ paddingTop: "10px" }}>
        <FormControl
          disabled={disabled}
          id={`${setId(name)}-wrapper`}
          className={classes.formControl}
          fullWidth={true}
          required={required}
          style={hidden ? { display: "none" } : { display: "block" }}
        >
          <FormControlLabel
            id={`${setId("name")}-checkbox-wrap`}
            control={
              <Checkbox
                checked={value}
                onClick={this.handleChange}
                color="primary"
                classes={{ checked: classes.checked }}
                id={setId("name")}
              />
            }
            label={schema.title}
          />
          {schema.description ||
            (errors && (
              <FormHelperText>
                {!readOnly && (errors || schema.description)}
              </FormHelperText>
            ))}
        </FormControl>
      </div>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(BooleanControl);
