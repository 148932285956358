import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import setComponentsId from "../../../helpers/setComponentsId";
import { translate } from "react-translate";
import { withStyles, FormControl } from "@material-ui/core";
import CustomDatePicker from "../../../components/CustomInput/CustomDatePicker";
import TextFieldDummy from "../../../components/CustomInput/TextFieldDummy";

import customInputStyle from "../../../variables/styles/customInputStyle";

const dateFormat = "DD.MM.YYYY";

class DateElement extends React.Component {
  state = { value: this.props.value };

  componentDidMount() {
    const { value, onChange } = this.props;
    onChange(value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value !== this.state.value) {
      this.setState({ value });
    }
  }

  handleChange = (value) => {
    const { onChange } = this.props;
    onChange && onChange(value || undefined);
    this.setState({ value });
  };

  render() {
    const {
      classes,
      sample,
      errors,
      description,
      formControlProps,
      readOnly,
      InputProps,
      required,
      placeholder,
      setId,
      isFuture,
    } = this.props;
    const { value } = this.state;
    let minDate = "";
    let maxDate = "";
    if (isFuture) {
      minDate = moment(new Date()).format(dateFormat);
      maxDate = moment(minDate, dateFormat)
        .add(100, "years")
        .format(dateFormat);
    }

    const Component = readOnly ? TextFieldDummy : CustomDatePicker;
    return (
      <FormControl
        {...formControlProps}
        className={classes.formControl}
        id={setId("form-control")}
      >
        <Component
          incomingFormat={dateFormat}
          placeholder={placeholder}
          label={description + (required ? "*" : "")}
          value={value}
          date={value}
          onChange={this.handleChange}
          helperText={!readOnly && (sample || errors)}
          error={!!errors}
          InputProps={InputProps}
          id={setId("input")}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
        />
      </FormControl>
    );
  }
}
DateElement.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
  enum: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  select: PropTypes.bool,
  sample: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formControlProps: PropTypes.object,
  description: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  SelectProps: PropTypes.object,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFuture: PropTypes.bool,
};

DateElement.defaultProps = {
  children: "",
  enum: null,
  type: "string",
  placeholder: "",
  select: false,
  onChange: undefined,
  sample: "",
  formControlProps: {},
  errors: null,
  description: "",
  readOnly: false,
  InputProps: {},
  SelectProps: {},
  mask: "",
  required: false,
  setId: setComponentsId("date"),
  value: "",
  isFuture: false,
};

const styled = withStyles(customInputStyle)(DateElement);
export default translate("Elements")(styled);
