import React from "react";
import { FormControl } from "@material-ui/core";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import RegularButton from "../../../components/CustomButtons/Button";
import { Preloader } from "../../../components";
import TextField from "../../../components/TextField";
import getAllCourts from "../../../helpers/getAllCourts";

export function renderField(idName, label, props, required = false) {
  const { t, classes, readOnly, setId } = this.props;
  return (
    <TextField
      id={setId(idName)}
      select={true}
      dummy={!!readOnly}
      label={t(label) + (required ? "*" : "")}
      onChange={this.handleChange}
      margin="normal"
      selectProps={{
        textFieldProps: { margin: "normal" },
        id: setId(`select-${idName}`),
        SelectDisplayProps: { id: setId(`select-${idName}-wrapper`) },
        MenuProps: { className: classes.menu },
      }}
      {...props}
    />
  );
}

export function renderRegionSelect() {
  const { value } = this.props;
  const regions = this.getRegions();
  let { regionId } = this.state;
  if (value) {
    ({ regionId } =
      getAllCourts(this.props)
        .filter((court) => court.id === value)
        .shift() || {});
  }
  const props = { value: regionId || "", options: regions, name: "regionId" };
  return this.renderField("region", "SELECT_REGION", props);
}

export function renderCourtTypeSelect() {
  const { value } = this.props;
  let { courtTypeId } = this.state;
  const { regionId } = this.state;
  if (value) {
    ({ courtTypeId } =
      getAllCourts(this.props)
        .filter((court) => court.id === value)
        .shift() || {});
  }

  const courtTypes = this.getTypes(regionId);
  const props = {
    value: courtTypeId || "",
    options: courtTypes,
    name: "courtTypeId",
  };
  return this.renderField("court-type", "SELECT_COURT_TYPE", props);
}

export function renderCourtSelect() {
  const { required, errors } = this.props;
  const { regionId, courtTypeId, activeCourt } = this.state;

  const courts = this.getCourts(regionId, courtTypeId);

  const courtsWithoutChilds = courts.filter(
    (item) => item.parentId === null || item.parentId === undefined
  );
  const props = {
    value: activeCourt || "",
    options: courtsWithoutChilds,
    name: "activeCourt",
    error: !!errors,
  };
  return this.renderField("court-name", "SELECT_COURT", props, required);
}

export function renderCourtChilds() {
  const { value, classes, required, setId, errors } = this.props;
  const { regionId, courtTypeId, subCourt } = this.state;
  const courts = this.getCourts(regionId, courtTypeId);

  const chosenCourtArr = courts.filter((item) => item.id === value);

  if (!chosenCourtArr || chosenCourtArr.length === 0) {
    return null;
  }

  const subCourts = courts.filter((item) => item.parentId);

  const matchSubCourts = subCourts.filter(
    (item) =>
      item.parentId ===
      (chosenCourtArr[0].parentId !== null
        ? chosenCourtArr[0].parentId
        : chosenCourtArr[0].id)
  );

  if (matchSubCourts.length === 0) {
    return (
      <div className={classes.labelRoot} id={setId("court-address")}>
        {chosenCourtArr[0].address}
      </div>
    );
  }
  const props = {
    value: subCourt || "",
    options: matchSubCourts,
    name: "subCourt",
    error: !!errors,
  };
  return this.renderField("court-address", "SELECT_COURT", props, required);
}

export function render() {
  const { regions, courts, courtTypes, formControlProps, classes, setId } =
    this.props;

  if (!regions || !courts || !courtTypes) {
    return <Preloader />;
  }

  const { jurisdictionToCourt } = this.state;
  const showModalJurisdiction = !!jurisdictionToCourt;

  return (
    <FormControl
      id={setId("court-form")}
      {...formControlProps}
      className={classes.formControl}
    >
      {showModalJurisdiction && (
        <Dialog
          open={showModalJurisdiction}
          onClose={() =>
            this.setState({
              activeCourt: "",
              jurisdictionToCourt: null,
            })
          }
          id={setId("dialog")}
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
        >
          <DialogTitle
            style={{ textAlign: "center" }}
            id={setId("dialog-title")}
            className={classes.dialogContentWrappers}
          >
            Увага!
          </DialogTitle>
          <DialogContent
            id={setId("dialog-content")}
            className={classes.dialogContentWrappers}
          >
            Територіальну підсудність цього суду змінено у зв’язку з
            неможливістю здійснювати правосуддя під час воєнного стану, суд
            якому визначається територіальна підсудність справ -{" "}
            <b>{jurisdictionToCourt.name}</b>
            <p>
              Вибрати <b>{jurisdictionToCourt.name}?</b>
            </p>
          </DialogContent>
          <div style={{ textAlign: "center" }}>
            <RegularButton
              color="yellow"
              onClick={() => {
                this.handleChange({
                  target: {
                    name: "activeCourt",
                    value: jurisdictionToCourt.id,
                  },
                });
              }}
              setId={(elementName) => setId(`delete-${elementName}`)}
            >
              ТАК
            </RegularButton>
            <RegularButton
              color="transparent"
              onClick={() =>
                this.setState({
                  activeCourt: "",
                  jurisdictionToCourt: null,
                })
              }
              setId={(elementName) => setId(`delete-${elementName}`)}
            >
              ВИБРАТИ ІНШИЙ СУД
            </RegularButton>
          </div>
        </Dialog>
      )}
      {this.renderRegionSelect()}
      {this.renderCourtTypeSelect()}
      {this.renderCourtSelect()}
      {this.renderCourtChilds()}
    </FormControl>
  );
}
