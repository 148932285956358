import React, { Component } from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";
import NoSsr from "@material-ui/core/NoSsr";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { StandardTextFieldProps } from "@material-ui/core/TextField";
import Select from "react-select";
import {
  InputActionMeta,
  SingleValue,
  MultiValue,
  Options,
} from "react-select";
import { searchAsync } from "../../../actions";
import { BaseControlProps, formControlStyle } from "../types";
import components from "../../Select/components";
import DOMPurify from "dompurify";

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
  input: {
    display: "flex",
    height: "auto",
    // height: 19,
    paddingTop: "3px",
    paddingBottom: "0px",
    alignItems: "flex-start",
  },
  singleValue: {
    fontSize: 16,
    marginTop: -2,
    marginBottom: -4,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px 0`,
    maxWidth: "100%",
    // boxSizing: "border-box",
    // padding: "10px 10px 10px 10px",
    "& span": {
      // maxWidth: "calc(100% - 24px)",
      display: "block",
      // boxSizing: "border-box",
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 10,
    },
    "& svg": {
      margin: "0 0 0 4px",
    },
  },
  chipWrap: {
    display: "block",
    // width: "100%",
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  paper: {
    // position: "absolute",
    zIndex: 1,
    // marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  ...formControlStyle,
});

interface SelectOption {
  value: string | number;
  label: string;
}

interface AutocompleteControlProps
  extends BaseControlProps<StandardTextFieldProps> {}

interface AutocompleteControlState {
  inputValue: string;
  // options: SelectOption[];
  options: Options<SelectOption>;
}

class AutocompleteControl extends Component<
  AutocompleteControlProps,
  AutocompleteControlState
> {
  static defaultProps: Partial<AutocompleteControlProps> = {};

  constructor(props: AutocompleteControlProps) {
    super(props);
    this.state = {
      inputValue: this.props.value,
      options: [],
    };
  }

  async componentDidMount() {
    this.getSelect();
  }

  async componentWillUnmount() {
    const { onFinish, name, value } = this.props;
    onFinish && onFinish(name, value);
  }

  handleSingleChange = (
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    const { onChange, name } = this.props;
    onChange && onChange(name, (newValue as SingleValue<SelectOption>)?.value);
  };

  handleMultiChange = (
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    const { onChange, name } = this.props;
    const value = (newValue as MultiValue<SelectOption>).map(
      (item) => item.value
    );
    onChange && onChange(name, value);
  };

  handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (
      actionMeta.action !== "input-change" ||
      newValue === actionMeta.prevInputValue
    )
      return;

    const {
      schema: { minLength },
    } = this.props;
    if (minLength && (newValue.length || 0) < minLength) return;

    this.getSelect(newValue);
  };

  private getSelect = async (inputValue: string = "") => {
    const {
      context,
      schema,
      schema: { minLength },
      schema: { select },
    } = this.props;

    // Get options from enum
    let options = (schema.enum || []).map((value) => {
      return {
        value: value,
        label: value,
      };
    });

    // Get options from select
    if (options.length === 0 && select) {
      const { items, label, source, value } = select;

      // eslint-disable-next-line no-eval
      const valueFn = value?.startsWith("(") ? eval(value) : undefined;

      if (source) {
        if (source.startsWith("#/definitions")) {
          // Get options from context
          options = (context[source.replace("#/definitions/", "")] || []).map(
            (item: any) => {
              return {
                value: valueFn ? valueFn(item) : item[value || "id"],
                label: item[label || "name"],
              };
            }
          );
        } else {
          if (minLength && (inputValue?.length || 0) < minLength) {
            const { value } = this.props;
            if (value) {
              options =
                typeof this.props.value === "object"
                  ? [
                      {
                        value: value,
                        label: value[label || "name"],
                      },
                    ]
                  : [
                      {
                        value: value,
                        label: value,
                      },
                    ];
            } else return;
          } else {
            if (minLength && (inputValue?.length || 0) > minLength) return;

            options = await searchAsync(source, inputValue);
            if (options instanceof Error) options = [];
            options = (options || []).map((item: any) => {
              return {
                value: valueFn ? valueFn(item) : item[value || "id"],
                label: item[label || "name"],
              };
            });
          }
        }
      } else if (items) {
        //Get options from items
        options = items.map((item: any) => {
          return {
            value: item[value || "id"],
            label: item[label || "name"],
          };
        });
      }
    }
    return this.setState({ inputValue, options });
  };

  render() {
    const {
      classes,
      disabled,
      errors,
      error,
      hidden,
      name,
      // path,
      readOnly,
      required,
      schema,
      theme,
      setId,
      // value,
    } = this.props;
    const id = setId(name);
    const { options } = this.state;
    const isMulti = schema.type === "array";
    const value = isMulti
      ? options.filter((item) => (this.props.value || []).includes(item.value))
      : options.find((item) => (this.props.value || "") === item.value) || null;

    const selectProps = {
      textFieldProps: { margin: "normal" },
      id: setId(`select-${name}`),
      SelectDisplayProps: { id: setId(`select-${name}-wrapper`) },
      MenuProps: { className: classes.menu },
    };

    const selectStyles = {
      input: (base: any) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    };

    const shrink =
      value &&
      (Array.isArray(value) ? value.length > 0 : value.label?.length > 0);

    const textFieldProps = Object.assign(
      { label: schema.title },
      { InputLabelProps: Object.assign({ error }, shrink && { shrink }) },
      selectProps.textFieldProps
    );

    const hasExamples =
      Array.isArray(schema.examples) && schema.examples.length > 0;

    return (
      <FormControl
        disabled={disabled}
        id={`${id}-wrapper`}
        className={classes.formControl}
        fullWidth={true}
        required={required}
        style={hidden ? { display: "none" } : { display: "block" }}
      >
        <div className={classes.flex}>
          <NoSsr>
            <Select
              id={setId(id)}
              inputId={`${id}-input`}
              classes={classes}
              styles={selectStyles}
              //@ts-ignore
              components={components}
              selectProps={selectProps}
              textFieldProps={textFieldProps}
              options={options}
              value={value}
              onChange={
                isMulti ? this.handleMultiChange : this.handleSingleChange
              }
              onInputChange={this.handleInputChange}
              placeholder={null}
              isMulti={isMulti}
              isClearable={true}
              menuPortalTarget={document.body}
            />
          </NoSsr>
        </div>
        <div
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            margin: 0,
            fontSize: "0.75rem",
            textAlign: "left",
            marginTop: "8px",
            minHeight: "1em",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            lineHeight: "1em",
          }}
        >
          {!readOnly && (errors || schema.description)}
          {!readOnly && hasExamples && (
            <div>
              {schema.examples.map((example: string, index: number) => (
                <div
                  key={index}
                  style={{ marginTop: "8px" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(example),
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </FormControl>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(
  AutocompleteControl
);
