import React from "react";

import { Chip } from "@material-ui/core";
import store from "../../../store";

import humanDateFormat from "../../../helpers/humanDateFormat";
import { Link } from "react-router-dom";

const setCaseNumber = (dataSource) => (value) => {
  dataSource.setFilter("case_num", value);
  dataSource.load();
};

const { btoa } = window;

export default [
  {
    id: "id",
    name: "DOCUMENT_ID",
    sortable: true,
    render: (documentId) => (
      <Link to={`/search/${btoa(documentId)}`} target="_blank">
        <strong>{documentId}</strong>
      </Link>
    ),
  },
  {
    id: "judgment_code",
    name: "JUDGMENT_CODE",
    sortable: false,
    render: (value) => {
      const {
        search: { judgmentForms },
      } = store.getState() || {};
      const found = (judgmentForms || []).find((i) => i.id === value);
      return (found || { name: "" }).name;
    },
  },
  {
    id: "case_num",
    name: "CASE_NUMBER",
    sortable: true,
    render: (value, item, key, dataSource) => (
      <Chip
        label={value}
        onClick={() => setCaseNumber(dataSource)(value)}
        variant="outlined"
      />
    ),
  },
  {
    id: "adjudication_date",
    name: "ADJUDICATION_DATE",
    render: humanDateFormat,
    sortable: true,
  },
  {
    id: "date_dnzs",
    name: "DNZS_DATE",
    render: humanDateFormat,
    sortable: true,
  },
  {
    id: "receipt_date",
    name: "RECEIPT_DATE",
    render: humanDateFormat,
    sortable: true,
  },
  {
    id: "court_name",
    name: "COURT_NAME",
    sortable: true,
  },
  {
    id: "judge",
    name: "JUDGE",
    sortable: true,
  },
];
