// import { yellowColor, dangerColor } from "../../variables/styles";

export default {
  backButton: {
    margin: 2,
  },
  formControl: {
    paddingBottom: "10px",
    // margin: "27px 0 0 0",
    // position: "relative",
    maxWidth: 700,
    display: "flex",
    "@media (max-width:500px)": {
      // eslint-disable-line no-useless-computed-key
      "& label": {
        fontSize: "0.75rem",
      },
    },
  },
  nextButton: {
    margin: 2,
  },
  mobileStepper: {
    margin: 2,
  },
  root: {
    margin: 2,
  },
  step: {
    margin: 2,
  },
  stepContent: {
    margin: 2,
  },
  stepHeader: {
    marginTop: 2,
  },
  stepTitle: {
    margin: 2,
  },
};
