import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

import DataSource from "../../helpers/dataSource";
import setComponentsId from "../../helpers/setComponentsId";

import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import StandartPageLayout from "../../components/StandartPageLayout";
import styles from "../../variables/styles/inputDocs";

import InputDocsTable from "./components/InputDocsTable";
import InputDocsToolbar from "./components/InputDocsToolbar";
import { INPUT_DOCS_URL } from "../../variables/wikiUrls";

const dataSource = DataSource("api/inputdocs/my");

class InputDocsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blockScreen: false,
      loading: false,
      subLoading: false,
      checked: [],
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.role !== this.props.role) {
      this.reload();
    }
  }

  reload() {
    this.setState({ blockScreen: true, loading: true, subLoading: true });
    const { filters, trash } = this.props;

    let returnObject = this.props.returnObject.getReturnObject();

    dataSource.clear();
    dataSource.privateFilters = filters || {};
    dataSource.setValue("isNotDeleted", !trash);

    if (this.props.returnObject.isForm("inputdocs") && !trash) {
      dataSource.filters = returnObject.filters;
      dataSource.sort = returnObject.sort;
      dataSource.search = returnObject.search;
    }

    dataSource.load().then(() => {
      setTimeout(() => {
        this.setState({
          blockScreen: false,
          loading: false,
          subLoading: false,
        });
      }, 500);
    });
  }

  createSortHandler = (property) => () => {
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }

    dataSource.sort = { [property]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
  };

  changeCount = ({ target: { value } }) => {
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load();
  };

  handleClick = (rowData) => () => {
    this.setState({ blockScreen: true });
    const { history } = this.props;

    this.props.returnObject.setReturnObject({
      form: "inputdocs",
      id: rowData.id,
      filters: dataSource.filters,
      sort: dataSource.sort,
      path: window.location.pathname + window.location.search,
    });

    this.setState({ blockScreen: false });
    return history.push(`/document/${rowData.docId}`);
  };

  load = () =>
    this.setState({ blockScreen: true, loading: true, subLoading: true }, () =>
      dataSource.load().then(() =>
        this.setState({
          blockScreen: false,
          loading: false,
          subLoading: false,
        })
      )
    );

  pagination = (e, page) => {
    this.setState({ blockScreen: true, loading: true, subLoading: true });
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource
      .load()
      .then(() =>
        this.setState({ blockScreen: false, loading: false, subLoading: false })
      );
  };

  changeCount = ({ target: { value } }) => {
    this.setState({ blockScreen: true, loading: true, subLoading: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource
      .load()
      .then(() =>
        this.setState({ blockScreen: false, loading: false, subLoading: false })
      );
  };

  onSelectAllClick = () => {
    const { checked } = this.state;
    const { list } = this.props;
    if (!checked.length) {
      return this.setState({
        checked: list.map((item) => item.id),
      });
    }

    return this.setState({ checked: [] });
  };

  onCheckboxClick = (id) => () => {
    const { checked } = this.state;
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
      return this.setState({ checked });
    }
    this.setState({ checked: [...checked, id] });
    return false;
  };

  render() {
    const { list, setId, t, courts, classes, history, returnObject } =
      this.props;
    const { blockScreen, checked, loading, subLoading } = this.state;
    // if (!list) dataSource.load();
    return (
      <StandartPageLayout
        blockScreen={blockScreen}
        cardTitle={t("HEADER")}
        cardSubtitle={t("HEADER2")}
        dataSource={dataSource}
        emptyPageTitle={t("INPUTDOCS_EMPTY_PAGE_TITLE")}
        emptyPageDescription={t("INPUTDOCS_EMPTY_PAGE_DESCRIPTION")}
        list={list}
        setId={setId}
        stepName={"inputdocs"}
        subLoading={subLoading}
        loading={loading}
        wikiUrl={INPUT_DOCS_URL}
        toolbar={
          <InputDocsToolbar
            t={t}
            classes={classes}
            setId={(elmentName) => setId(`toolbar-${elmentName}`)}
            dataSource={dataSource}
            load={this.load}
            docCategory={10}
          />
        }
      >
        <InputDocsTable
          classes={classes}
          setId={(elmentName) => setId(`table-${elmentName}`)}
          t={t}
          courts={courts}
          createSortHandler={this.createSortHandler}
          changeCount={this.changeCount}
          checked={checked}
          dataSource={dataSource}
          handleClick={this.handleClick}
          history={history}
          list={list}
          onSelectAllClick={this.onSelectAllClick}
          onCheckboxClick={this.onCheckboxClick}
          pagination={this.pagination}
          returnObject={returnObject}
        />
        {/* {list && this.renderBody()} */}
        {/* <BlockScreen open={blockScreen} /> */}
      </StandartPageLayout>
    );
  }
}

InputDocsList.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  userInfo: PropTypes.object.isRequired,
  meta: PropTypes.object,
  role: PropTypes.string.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
  trash: PropTypes.bool,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

InputDocsList.defaultProps = {
  list: null,
  meta: {},
  setId: setComponentsId("input-docs"),
  trash: false,
  courts: null,
};

const mapStateToProps = ({
  inputdocs: { list },
  usermeta: { userMetaData },
  dictionary: { courts },
  authorization: { info: userInfo, role },
}) => {
  const { meta } = userMetaData || {};
  return {
    courts,
    list,
    meta,
    role,
    userInfo,
  };
};

const InputDocs = compose(
  withStyles(styles),
  translate("InputDocs"),
  ReturnAndLocateInTableHOC,
  withRouter
)(InputDocsList);

export default connect(mapStateToProps)(InputDocs);
