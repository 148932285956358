export default {
  buttonLink: {
    "&, &:hover": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  executiveInfo: {
    display: "flex",
    alignItems: "flex-start",
    "@media (max-width: 767px)": {
      flexWrap: "wrap",
    },
  },
  container: {
    paddingLeft: "20px",
    width: "50%",
    "@media (max-width: 767px)": {
      paddingLeft: "0px",
      width: "100%",
      order: 2,
    },
    textAlign: "left",
  },
  title: {
    marginBottom: "10px",
    fontWeight: "bold",
  },
  linksTitle: {
    display: "inline-flex",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  underline: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  buttontitle: {
    width: "100%",
    height: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoblock: {
    position: "relative",
    marginTop: "10px",
    marginRight: "25px",
    paddingBottom: "15px",
    border: "1px solid",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingTop: "10px",
    "@media (max-width: 767px)": {
      marginRight: "0px",
      fontSize: "12px",
    },
  },
  splitMainContainer: {
    display: "flex",
  },
  previewContainer: {
    clear: "both",
    float: "right",
    width: "50%",
    "@media (max-width: 767px)": {
      width: "100%",
      order: 1,
    },
  },
  // buttons: {
  //   display: "flex",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   gap: "5px",
  //   "@media (max-width: 767px)": {
  //     justifyContent: "space-between",
  //   },
  // },
  message: {
    color: "#ff0000",
    marginTop: "15px",
    marginBottom: "15px",
  },
  dNone: {
    dislay: "none",
  },
  stylesInfoBlockTitle: {
    marginRight: "10px",
    verticalAlign: "baseline",
  },
  stylesInfoBlockData: {
    marginLeft: "1px",
    paddingLeft: "5px",
  },
  stylesWithoutPaddingInfoBlockData: {
    position: "relative",
    left: "-3px",
    marginLeft: 0,
    paddingLeft: 0,
  },
  // executive info block
  stylesExDocViewForRefreshBtn: {
    minWidth: "auto",
    width: "27px",
    height: "27px",
    backgroundColor: "#fcd700",
    color: "#464545",
  },
  stylesExDocViewForCircular: {
    width: "25px",
    height: "25px",
    marginLeft: "24px",
    marginRight: "24px",
  },
  stylesExDocViewInfoBlockTitle: {
    marginRight: "10px",
    verticalAlign: "baseline",
  },
  stylesExDocViewInfoBlockData: {
    marginLeft: "1px",
    paddingLeft: "5px",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
    alignItems: "center",
    "@media (max-width: 767px)": {
      justifyContent: "space-between",
    },
  },
};
