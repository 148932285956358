import React, { Fragment } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  Icon,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import cx from "classnames";
import VideoManual from "../../components/videoManual/VideoManual";
import videoUrls from "../../variables/videoUrls";

// const supportMail = "inbox@vkz.court.gov.ua";
const supportMail_new = "help@ccs.court.gov.ua";

const getTitle = (docCategory) => {
  switch (docCategory) {
    case 4:
      return "EXECUTIVE";
    case 8:
      return "DECISION";
    default:
      return "TEMPLATE";
  }
};

const TemplatesDialog = ({
  t,
  classes,
  openCreateClaimDialog,
  toggleTemplateDialog,
  setId,
  searchText,
  count,
  handleSearch,
  children,
  docCategory,
}) => (
  <Dialog
    open={openCreateClaimDialog}
    fullWidth
    onClose={toggleTemplateDialog}
    maxWidth="md"
    id={setId("")}
    className={cx(classes.dialog, classes.fullWidth)}
  >
    <DialogTitle className={classes.dialogContentWrappers} id={setId("title")}>
      <VideoManual
        setId={setId}
        title={t(`SELECT_${getTitle(docCategory)}`)}
        videoUrl={videoUrls.selectTemplates}
      />
    </DialogTitle>
    <DialogTitle
      className={classes.dialogContentWrappers}
      id={setId("title-2")}
    >
      <FormControl fullWidth={true} id={setId("search-wrap")}>
        <TextField
          label={t("SEARCH_PLACEHOLDER")}
          id={setId("search-input-template")}
          helperText={
            !searchText.length
              ? t(`${getTitle(docCategory)}_SEARCH_HELPER`)
              : ""
          }
          autoFocus={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
          inputProps={{
            // eslint-disable-line react/jsx-no-duplicate-props
            value: searchText,
            onChange: handleSearch,
          }}
        />
      </FormControl>
    </DialogTitle>
    <DialogContent
      id={setId("content")}
      className={classes.dialogContentWrappers}
    >
      <div
        className={cx(classes.scroll, classes.contentWrap)}
        id={setId("content-wrap")}
      >
        {children}
      </div>
    </DialogContent>
    <div
      className={cx(classes.dialogFooter, classes.dialogContentWrappers)}
      id={setId("footer")}
    >
      <Typography
        className={classes.subtitleInitialCase}
        variant="subtitle1"
        id={setId("footer-text-1")}
      >
        {t(`${getTitle(docCategory)}_COUNT`, { count })}
      </Typography>
      <Typography
        className={classes.subtitleInitialCase}
        variant="subtitle1"
        id={setId("footer-text-2")}
      >
        {t(`${getTitle(docCategory)}_SEARCH_ERROR`, {
          // mail: (
          //   <a href={`mailto:${supportMail}`} className={classes.link}>
          //     {supportMail}
          //   </a>
          // ),
          mail_new: (
            <a href={`mailto:${supportMail_new}`} className={classes.link}>
              {supportMail_new}
            </a>
          ),
        })}
      </Typography>
    </div>
  </Dialog>
);

TemplatesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  children: PropTypes.node,

  openCreateClaimDialog: PropTypes.bool.isRequired,
  toggleTemplateDialog: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  docCategory: PropTypes.number.isRequired,
};

TemplatesDialog.defaultProps = {
  children: <Fragment />,
  setId: setComponentsId("create-claim-dialog"),
};

export default TemplatesDialog;
