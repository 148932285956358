import * as api from "../services/api";
import store from "../store";

const { dispatch } = store;

const VP_STATUSES_URL = "api/executive/vp-statuses";
const REQUEST_VP_STATUSES = "REQUEST_VP_STATUSES";

const VD_STATUSES_URL = "api/executive/vd-statuses";
const REQUEST_VD_STATUSES = "REQUEST_VD_STATUSES";

export const GET_EXECUTIVE_DOCS_INFO = "GET_EXECUTIVE_DOCS_INFO";
export const GET_EXECUTIVE_DOCS_TOTAL = "GET_EXECUTIVE_DOCS_TOTAL";
export const GET_DOCUMENT_INFO = "GET_DOCUMENT_INFO";
export const CHECK_DOCUMENT_SIGN = "CHECK_DOCUMENT_SIGN";

export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_PAYMENT_FORM = "GET_PAYMENT_FORM";

export const REQUEST_EXECUTIVE_CREATE = "REQUEST_EXECUTIVE_CREATE";
export const EXECUTIVE_CREATED = "EXECUTIVE_CREATED";
export const STORE_CLAIM_VALUES = "STORE_CLAIM_VALUES";

export const SEND_TO_ASVP = "SEND_TO_ASVP";
export const UPDATE_STATUS_ASVP = "UPDATE_STATUS_ASVP";

const EXEC_URL = "api/executive/decisions";
const DOC2_URL = "api/documents";
const DOC_URL = "api/executive/decisions";

export async function getExecInfo(id) {
  return await api.get(
    `${EXEC_URL}/${id}`,
    GET_EXECUTIVE_DOCS_INFO,
    store.dispatch
  );
}

export async function getDocumentInfo(id) {
  return await api.get(
    `${DOC_URL}/${id}/file`,
    GET_DOCUMENT_INFO,
    store.dispatch
  );
}

export async function checkExecOrderSign(data) {
  return await api.post(
    `${DOC2_URL}/sign_check`,
    data,
    CHECK_DOCUMENT_SIGN,
    store.dispatch
  );
}

export function requestVpStatuses() {
  return api.get(VP_STATUSES_URL, REQUEST_VP_STATUSES, dispatch);
}

export function requestVdStatuses() {
  return api.get(VD_STATUSES_URL, REQUEST_VD_STATUSES, dispatch);
}
