import { getRoleLineStr } from "./getRoleSimpleStr";

export const getExecMyRoles = (t) => [
  {
    id: 1,
    key: "debtor",
    value: "debtor",
    name: getRoleLineStr("debtor", t),
  },
  {
    id: 2,
    key: "collector",
    value: "collector",
    name: getRoleLineStr("collector", t),
  },
  {
    id: 3,
    key: "payer",
    value: "payer",
    name: getRoleLineStr("payer", t),
  },
  {
    id: 4,
    key: "debtorRepresentative",
    value: "debtorRepresentative",
    name: getRoleLineStr("debtorRepresentative", t),
  },
  {
    id: 5,
    key: "collectorRepresentative",
    value: "collectorRepresentative",
    name: getRoleLineStr("collectorRepresentative", t),
  },
  {
    id: 6,
    key: "payerRepresentative",
    value: "payerRepresentative",
    name: getRoleLineStr("payerRepresentative", t),
  },
];

export const getExecForms = (t) => [
  {
    id: 1,
    key: "1",
    value: "1",
    name: t("ELECTRONIC_FORM"),
  },
  {
    id: 2,
    key: "2",
    value: "2",
    name: t("PAPER_FORM"),
  },
  // {
  //   id: 0,
  //   key: "0",
  //   value: "0", // або null
  //   name: "Не вказано",
  // },
];
