import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Preloader } from "../../components";

import { FormLabel, withStyles } from "@material-ui/core";

import Select from "../../components/Select";
import styles from "../../variables/styles/multiLevelSelect";

import subdirectoryImage from "../../assets/img/subdirectory-right.svg";

class MultiLevelsSelect extends React.Component {
  state = {
    levels: null,
  };

  UNSAFE_componentWillMount() {
    const { options, value } = this.props;
    this.updateOptionLevels(options, value);
  }

  UNSAFE_componentWillReceiveProps({ options, value }) {
    this.updateOptionLevels(options, value);
  }

  updateOptionLevels = (options, selected) => {
    const levels = [];

    const checkChildren = (parentId, level) => {
      if (!levels[level]) {
        levels[level] = [];
      }

      const children = (options || [])
        .filter((child) => child.parentId === parentId)
        .map((child) => ({
          ...child,
          selected:
            checkChildren(child.id, level + 1) ||
            (selected || []).map((id) => parseInt(id, 10)).includes(child.id),
        }));

      levels[level] = levels[level].concat(children);
      return !!children.filter((child) => child.selected).length;
    };

    checkChildren(null, 0);
    this.setState({ levels: levels.filter((level) => level.length) });
  };

  handleChange =
    (level) =>
    ({ target: { value } }) => {
      const { onChange, options } = this.props;
      const { levels } = this.state;

      let selected = [];

      for (let i = level; i >= 0; i -= 1) {
        if (level === i) {
          selected = selected.concat(value);
        } else {
          selected = selected.concat(
            levels[i]
              .filter((option) => option.selected)
              .map((option) => option.id)
          );
        }
      }

      const getParentId = (optionId) =>
        options.find((option) => option.id === optionId).parentId;
      const parentIds = selected.map(getParentId);

      const result = selected.filter((option) => !parentIds.includes(option));
      onChange && onChange({ target: { value: result } });
    };

  renderLevel = (levelOptions, level) => {
    const { classes, t } = this.props;
    const { levels } = this.state;

    const enabledOptions = levelOptions
      .filter((option) => {
        if (level === 0) {
          return true;
        }

        const parent = levels[level - 1].find(
          (item) => item.id === option.parentId
        );
        return parent.selected;
      })
      .sort((a, b) => a.id > b.id);

    if (!enabledOptions.length) {
      return null;
    }

    const value = enabledOptions
      .filter((option) => option.selected)
      .map((option) => option.id);

    return (
      <div
        key={level}
        style={{ paddingLeft: (level && level - 1) * 20 }}
        className={classes.root}
      >
        {level > 0 && (
          <img alt="" src={subdirectoryImage} className={classes.arrow} />
        )}
        <Select
          key={level}
          label={t("LEVEL", { level: level + 1 })}
          width={`calc(100% - ${level > 0 ? "20px" : "0"})`}
          options={enabledOptions}
          value={value}
          onChange={this.handleChange(level)}
          multi={true}
        />
      </div>
    );
  };

  render() {
    const { levels } = this.state;
    const { label } = this.props;

    return (
      <Fragment>
        <FormLabel component="legend">{label}</FormLabel>
        {(!levels || !levels.length) && <Preloader />}
        {levels && !!levels.length && levels.map(this.renderLevel)}
      </Fragment>
    );
  }
}

MultiLevelsSelect.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.node,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MultiLevelsSelect.defaultProps = {
  value: undefined,
  label: "",
  onChange: undefined,
  options: undefined,
};

const translated = translate("Elements")(MultiLevelsSelect);
export default withStyles(styles)(translated);
