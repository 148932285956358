export default {
  TITLE: "Зміна номеру телефону",
  TEXT: "Введіть новий номер:",
  TEXT_WAIT_FOR_CODE:
    "Будь ласка, зачекайте на смс та введіть код у поле нижче:",
  CONFIRM_TEXT: "Для увімкнення вам буде надіслано смс з кодом на номер:",
  SEND_CODE: "Надіслати СМС",
  VERIFY_CODE: "Підтвердити",
  CODE_INPUT_LABEL: "Код підтвердження",
  PHONE_INPUT_LABEL: "Телефон",
  VALIDATION_FALSE: "Код не пройшов перевірку",
  RESEND_CODE: "Надіслати смс ще раз",
  PHONE_ALREADY_EXISTS: "Цей телефонний номер вже використовується",
};
