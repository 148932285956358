/* 
  ... ... С Т А Т У С И ... ... .
  -6 CANT_ISSUE_ECOURT Не може бути подано в електронній формі
  -5 CANT_ISSUE_TO_COLLECTOR Не може бути вручено стягувачу в електронній формі
  -4 NOT_FOR_ASVP Не підлягає подачі до АСВП
  -3 NOT_ENFORCEABLE Не підлягає виконанню
  -2 NOT_ISSUED_TO_DEBTOR Не вручено боржнику
  -1 CANT_ISSUE_TO_DEBTOR Не може бути вручено боржнику в електронній формі
  ... ... ... ... ... ... ... ...
  0 CAME_FROM_COURT Надійшов з суду
  1	EXECUTE_IMMEDIATE	Негайне виконання => Повторна подача
  2	TO_EXECUTE_AUTO	Автоматична подача
  3	TO_EXECUTE	До виконання
  4	NO_DATE_FORCE	Не набрав законної сили
  5	MISSED_DEADLINE	Пропущено строк пред’явлення
  6	PAYMENT_TERM	Строк добровільної сплати
  7	PAID	Сплачено
  8	SENDING	Відправка до АСВП
  9	SENDING_ERROR	Помилка відправки до АСВП
  10	RECEPTION_ERROR	Помилка прийому до АСВП
  11	FAULT	Заява відхилена
  12	WAITING_PROCESSING	На обробці
  13	WAITING	Очікує прийняття
  14	REGISTERED	Зареєстровано
  15	ACCEPTED	Прийнятий до виконання
  16	DIRECTED	Направлений за належністю
  17	REFERRED	Переданий до підприємства
  18	TRANSFERRED	Переданий до ліквідаційної комісії
  19	UNACCEPTED	Повернений без прийняття
  20	REJECTED	Повернений без виконання
  21	EXECUTED	Виконаний
  22	CANCELED	Постанову скасовано
  23	RETCREDITOR	Повернений стягувачу
  24	RETURNED	Повернений до органу, що видав
  25  ECECUTE_AUTO_FAULT Неможливість автоматичної подачі
*/

export const CANT_ISSUE_ECOURT = -6;
export const CANT_ISSUE_TO_COLLECTOR = -5;
export const NOT_FOR_ASVP = -4;
export const NOT_ENFORCEABLE = -3;
export const NOT_ISSUED_TO_DEBTOR = -2;
export const CANT_ISSUE_TO_DEBTOR = -1;
export const CAME_FROM_COURT = 0;
export const EXECUTE_IMMEDIATE = 1;
export const TO_EXECUTE_AUTO = 2;
export const TO_EXECUTE = 3;
export const NO_DATE_FORCE = 4;
export const MISSED_DEADLINE = 5;
export const PAYMENT_TERM = 6;
export const PAID = 7;
export const SENDING = 8;
export const SENDING_ERROR = 9;
export const RECEPTION_ERROR = 10;
export const FAULT = 11;
export const WAITING_PROCESSING = 12;
export const WAITING = 13;
export const REGISTERED = 14;
export const ACCEPTED = 15;
export const DIRECTED = 16;
export const REFERRED = 17;
export const TRANSFERRED = 18;
export const UNACCEPTED = 19;
export const REJECTED = 20;
export const EXECUTED = 21;
export const CANCELED = 22;
export const RETCREDITOR = 23;
export const RETURNED = 24;
export const ECECUTE_AUTO_FAULT = 25;

const statuses = {
  [CANT_ISSUE_ECOURT]: "CANT_ISSUE_ECOURT",
  [CANT_ISSUE_TO_COLLECTOR]: "CANT_ISSUE_TO_COLLECTOR",
  [NOT_ENFORCEABLE]: "NOT_ENFORCEABLE",
  [NOT_FOR_ASVP]: "NOT_FOR_ASVP",
  [NOT_ISSUED_TO_DEBTOR]: "NOT_ISSUED_TO_DEBTOR",
  [CANT_ISSUE_TO_DEBTOR]: "CANT_ISSUE_TO_DEBTOR",
  [CAME_FROM_COURT]: "CAME_FROM_COURT",
  [EXECUTE_IMMEDIATE]: "EXECUTE_IMMEDIATE",
  [TO_EXECUTE_AUTO]: "TO_EXECUTE_AUTO",
  [TO_EXECUTE]: "TO_EXECUTE",
  [NO_DATE_FORCE]: "NO_DATE_FORCE",
  [MISSED_DEADLINE]: "MISSED_DEADLINE",
  [PAYMENT_TERM]: "PAYMENT_TERM",
  [PAID]: "PAID",
  [SENDING]: "SENDING",
  [SENDING_ERROR]: "SENDING_ERROR",
  [RECEPTION_ERROR]: "RECEPTION_ERROR",
  [FAULT]: "FAULT",
  [WAITING_PROCESSING]: "WAITING_PROCESSING",
  [WAITING]: "WAITING",
  [REGISTERED]: "REGISTERED",
  [ACCEPTED]: "ACCEPTED",
  [DIRECTED]: "DIRECTED",
  [REFERRED]: "REFERRED",
  [TRANSFERRED]: "TRANSFERRED",
  [UNACCEPTED]: "UNACCEPTED",
  [REJECTED]: "REJECTED",
  [EXECUTED]: "EXECUTED",
  [CANCELED]: "CANCELED",
  [RETCREDITOR]: "RETCREDITOR",
  [RETURNED]: "RETURNED",
  [ECECUTE_AUTO_FAULT]: "ECECUTE_AUTO_FAULT",
};

const stagesStatuses = {
  [-1]: "-1",
  [-2]: "-2",
  [-3]: "-3",
  [-4]: "-4",
  [-5]: "-5",
  [-6]: "-6",
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
  13: "13",
  14: "14",
  15: "15",
  16: "16",
  17: "17",
  18: "18",
  19: "19",
  20: "20",
  21: "21",
  22: "22",
  23: "23",
  24: "24",
  25: "25",
};

const executiveStagesStatuses = {
  1: "1",
  2: "0,2,3,4,5,6,9,16,7,10,8,11,12,14,15,18",
};

export const sortStatuses = (t, statusesLib, docCategory = 1) => {
  return Object.keys(statusesLib)
    .reduce((acc, id) => {
      const stages =
        docCategory === 4 ? executiveStagesStatuses : stagesStatuses;
      if (stages[id]) {
        acc.push({
          id,
          textId: statusesLib[id],
          name: t(statusesLib[id]),
          value: stages[id],
        });
      }
      return acc;
    }, [])
    .sort((status, nextStatus) => (status.name < nextStatus.name ? -1 : 1));
};

export const executiveStatuses = {
  [CANT_ISSUE_ECOURT]: "CANT_ISSUE_ECOURT",
  [CANT_ISSUE_TO_COLLECTOR]: "CANT_ISSUE_TO_COLLECTOR",
  [NOT_ENFORCEABLE]: "NOT_ENFORCEABLE",
  [NOT_FOR_ASVP]: "NOT_FOR_ASVP",
  [NOT_ISSUED_TO_DEBTOR]: "NOT_ISSUED_TO_DEBTOR",
  [CANT_ISSUE_TO_DEBTOR]: "CANT_ISSUE_TO_DEBTOR",
  [CAME_FROM_COURT]: "CAME_FROM_COURT",
  [EXECUTE_IMMEDIATE]: "EXECUTE_IMMEDIATE",
  [TO_EXECUTE_AUTO]: "TO_EXECUTE_AUTO",
  [TO_EXECUTE]: "TO_EXECUTE",
  [NO_DATE_FORCE]: "NO_DATE_FORCE",
  [MISSED_DEADLINE]: "MISSED_DEADLINE",
  [PAYMENT_TERM]: "PAYMENT_TERM",
  [PAID]: "PAID",
  [SENDING]: "SENDING",
  [SENDING_ERROR]: "SENDING_ERROR",
  [RECEPTION_ERROR]: "RECEPTION_ERROR",
  [FAULT]: "FAULT",
  [WAITING_PROCESSING]: "WAITING_PROCESSING",
  [WAITING]: "WAITING",
  [REGISTERED]: "REGISTERED",
  [ACCEPTED]: "ACCEPTED",
  [DIRECTED]: "DIRECTED",
  [REFERRED]: "REFERRED",
  [TRANSFERRED]: "TRANSFERRED",
  [UNACCEPTED]: "UNACCEPTED",
  [REJECTED]: "REJECTED",
  [EXECUTED]: "EXECUTED",
  [CANCELED]: "CANCELED",
  [RETCREDITOR]: "RETCREDITOR",
  [RETURNED]: "RETURNED",
  [ECECUTE_AUTO_FAULT]: "ECECUTE_AUTO_FAULT",
};

export const getStatusesForCat = (catId) => {
  switch (catId) {
    default:
      return executiveStatuses;
  }
};

export const ExecStage = {
  INACTIVE: 0,
  AUTO_APPLICATION: 1,
  MANUAL_APPLICATION: 2,
  NOT_TO_APPLICATION: 3,
  ASVP_SENDING: 4,
  ASVP_PROCESSING: 5,
  ASVP_REJECTED: 6,
};

export const ExecCategory = {
  NOT_DEFINED: 0,
  NOT_FOR_ASVP: 1,
  PENALTY: 2,
  COURT_FEE: 3,
};

export const vpStaus = {
  NONE: "не встановлений (стан до відкриття ВП або повернення ВД без виконання)",
  REFUSE: "Відмовлено у відкритті",
  OPEN: "Відкрито",
  ENFORCE: "Примусове виконання",
  STOPPED: "Зупинено",
  FINISHED: "Завершено",
  ERROR: "Помилкове",
  CANCELED: "Постанову скасовано",
  ENDED: "Закінчено",
};

export default statuses;

/* 
  ... ... С Т А Т У С И ... ... .
  -6 CANT_ISSUE_ECOURT Не може бути подано в електронній формі
  -5 CANT_ISSUE_TO_COLLECTOR Не може бути вручено стягувачу в електронній формі
  -4 NOT_FOR_ASVP Не підлягає подачі до АСВП
  -3 NOT_ENFORCEABLE Не підлягає виконанню
  -2 NOT_ISSUED_TO_DEBTOR Не вручено Боржнику
  -1 CANT_ISSUE_TO_DEBTOR Не може бути вручено боржнику в електронній формі
  ... ... ... ... ... ... ... ...
  0 CAME_FROM_COURT Надійшов з суду
  1	EXECUTE_IMMEDIATE	Негайне виконання => Повторна подача
  2	TO_EXECUTE_AUTO	Автоматична подача
  3	TO_EXECUTE	До виконання
  4	NO_DATE_FORCE	Не набрав законної сили
  5	MISSED_DEADLINE	Пропущено строк пред’явлення
  6	PAYMENT_TERM	Строк добровільної сплати
  7	PAID	Сплачено
  8	SENDING	Відправка до АСВП
  9	SENDING_ERROR	Помилка відправки до АСВП
  10	RECEPTION_ERROR	Помилка прийому до АСВП
  11	FAULT	Заява відхилена
  12	WAITING_PROCESSING	На обробці
  13	WAITING	Очікує прийняття
  14	REGISTERED	Зареєстровано
  15	ACCEPTED	Прийнятий до виконання
  16	DIRECTED	Направлений за належністю
  17	REFERRED	Переданий до підприємства
  18	TRANSFERRED	Переданий до ліквідаційної комісії
  19	UNACCEPTED	Повернений без прийняття
  20	REJECTED	Повернений без виконання
  21	EXECUTED	Виконаний
  22	CANCELED	Постанову скасовано
  23	RETCREDITOR	Повернений стягувачу
  24	RETURNED	Повернений до органу, що видав
  25  ECECUTE_AUTO_FAULT Неможливість автоматичної подачі
*/
