import moment from "moment";

export default function (doc) {
  const caseNumber = doc.caseNumber
    ? doc.caseNumber.replace(/\\|\/|:|\*|\?|"|<|>|\||\+/g, "-")
    : doc.vdCaseNumber
    ? doc.vdCaseNumber.replace(/\\|\/|:|\*|\?|"|<|>|\||\+/g, "-")
    : "б/н";
  const link = doc.scanDocumentLink;
  const fileName = link
    ? link.substring(link.indexOf("/") + 1, link.indexOf("."))
    : doc.id || "";
  const docDate = moment(doc.docDate || doc.updatedAt || doc.createdAt).format(
    "YYYY-MM-DD"
  );
  return `${caseNumber}_${docDate}_${fileName}`;
}
