import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import {
  IReturnObjectProps,
  IReturnObject,
} from "../../components/ReturnAndLocateInTableHOC/types";
import { translate } from "../../helpers/translate";

import { Button } from "../../components";

import StandartPageLayout from "../../components/StandartPageLayout";
import RequestToolbar from "./components/RequestToolbar";

import RequestTable from "./components/RequestTable";

import styles from "../../variables/styles/StateRegisters";

import {
  StateRegister,
  StateRegisterRequest,
  REGISTER_URL,
} from "../../actions/stateRegisters";
import {
  registerDataSource,
  requestDataSource as dataSource,
} from "../../reducers/stateRegisters";

export interface RegisterReqListProps {
  classes: any;
  setId: (s: string) => string;
  t: (s: string, args?: Record<string, any>) => string;
  activeRegister?: StateRegister;
  dataSource: any;
  dictionary: any;
  display: string;
  history: any;
  list: any[];
  loading: boolean;
  match: any;
  page: string;
  registerList: StateRegister[];
  returnObject: IReturnObjectProps;
  userInfo: any;
  filters?: IReturnObject | null;
  // sort?: IReturnObject;
  // search?: IReturnObject;
}

export interface RegisterReqListState {
  activeRegisterId: number;
  context: {
    case?: any;
    court?: any;
  };
  activeRequest?: StateRegisterRequest;
  activeTemplate?: any;
  isNewRequest?: boolean;
  blockScreen: boolean;
  error?: string;
  viewData?: any;
}

class RegisterReqList extends React.Component<
  RegisterReqListProps,
  RegisterReqListState
> {
  static initialState: RegisterReqListState = {
    activeRegisterId: -1,
    context: {},
    activeRequest: undefined,
    activeTemplate: undefined,
    blockScreen: false,
    error: "",
    viewData: undefined,
  };

  constructor(props: RegisterReqListProps) {
    super(props);
    this.state = RegisterReqList.initialState;
  }

  async componentDidMount() {
    const { page, returnObject } = this.props;
    returnObject.isForm(page) && returnObject.popReturnObject();
    await this.reload();
  }

  async componentDidUpdate() {}

  async componentWillUnmount() {
    dataSource.clear();
    dataSource.setValue("count", 20);
  }

  private get register() {
    const {
      match: {
        params: { registerId },
      },
      registerList,
    } = this.props;
    return registerList.find((item) => item.id === parseInt(registerId));
  }

  private get pageTitle(): string {
    const reg = this.register;
    return reg
      ? `${reg.code}: ${reg.name}${
          reg.statusId === 2 ? " " + this.props.t("IN_DEVELOPMENT") : ""
        }`
      : "";
  }

  reload = async () => {
    const {
      match: {
        params: { registerId },
      },
      registerList,
    } = this.props;

    if (registerList.length === 0) registerDataSource.load();

    let returnObject = this.props.returnObject.getReturnObject();

    if (this.props.returnObject.isForm(`state_registers/${returnObject?.id}`)) {
      dataSource.filters = returnObject?.filters;
      dataSource.sort = returnObject?.sort;
      dataSource.search = returnObject?.search;
    }

    dataSource.setBaseUrl(`${REGISTER_URL}/${registerId}/request`).load();
  };

  handleClick = (requestId: string) => async () => {
    const { history, page, returnObject } = this.props;

    returnObject.appendReturnObject({
      form: page,
      id: requestId,
      filters: {},
      sort: {},
      path: window.location.pathname + window.location.search,
      search: null,
    });

    this.setState({ blockScreen: false });

    return history.push(`/state_registers/request/${requestId}`);
  };

  createButton = () => {
    const { setId, t } = this.props;

    const { activeRegisterId } = this.state;

    return (
      <Button
        disabled={!activeRegisterId}
        color="yellow"
        variant="contained"
        onClick={() => activeRegisterId && this.handleCreate()}
        setId={setId}
      >
        {t("CREATE_REQUEST")}
      </Button>
    );
  };

  handleCreate = () => {
    const { page, history, returnObject } = this.props;
    returnObject.appendReturnObject({
      form: page,
      id: "",
      filters: {},
      sort: {},
      path: window.location.pathname + window.location.search,
      search: null,
    });

    history.push(
      `/state_registers/request/create?registerId=${this.register?.id}`
    );
  };

  pagination = (e: string, page: number) => {
    this.setState({ blockScreen: true });
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource.load().then(() => this.setState({ blockScreen: false }));
  };

  changeCount = ({ target: { value } }: any) => {
    this.setState({ blockScreen: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load().then(() => this.setState({ blockScreen: false }));
  };

  createSortHandler = (property: any) => () => {
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }

    dataSource.sort = { [property]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
  };

  load = () =>
    this.setState({ blockScreen: true }, () =>
      dataSource.load().then(() =>
        this.setState({
          blockScreen: false,
          // loading: false,
        })
      )
    );

  render(): React.ReactNode {
    const { classes, list, loading, setId, t, returnObject } = this.props;

    return (
      <StandartPageLayout
        loading={loading}
        list={list || []}
        dataSource={dataSource || {}}
        blockScreen={loading}
        cardTitle={this.pageTitle}
        createButton={this.register?.statusId === 1 && this.createButton()}
        setId={setId}
        // onlyDialog={showWizard}
        toolbar={
          <RequestToolbar
            classes={classes}
            dataSource={dataSource || {}}
            setId={setId}
            t={t}
            load={this.reload}
            registerId={this.register?.id}
            returnObject={returnObject}
            // onClickBack={this.backToRegisters}
            onClickCreate={this.handleCreate}
          />
        }
      >
        <RequestTable
          t={t}
          setId={setId}
          dataSource={dataSource || {}}
          classes={classes}
          list={list || []}
          pagination={this.pagination}
          changeCount={this.changeCount}
          handleClick={this.handleClick}
          createSortHandler={this.createSortHandler}
        />
      </StandartPageLayout>
    );
  }
}

StandartPageLayout.propTypes = {
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps({
  authorization: { info },
  dictionary,
  stateRegisters: {
    activeRegister,
    dataSource,
    display,
    list,
    loading,
    registerList,
  },
}: any) {
  return {
    activeRegister,
    dataSource,
    dictionary,
    display,
    list,
    loading,
    registerList,
    userInfo: info,
  };
}

export default translate("StateRegisters")(
  compose(
    connect(mapStateToProps),
    withRouter,
    ReturnAndLocateInTableHOC,
    withStyles(styles as any)
  )(RegisterReqList)
);
