import React from "react";

import { Typography } from "@material-ui/core";
import {
  ArraySchema,
  Formatter,
  JsonSchema,
  ObjectSchema,
} from "../../../components/FormFromSchema/json-schema";
import { isEmptyValue } from "../../../components/FormFromSchema/helpers";

const stylesForTitle = {
  "background-color": "#CCCCCC",
  border: "1px solid #dddddd",
  fontWeight: "bold",
  margin: "0 0 0 0",
  maxWidth: "60%",
  padding: "dense",
};

const stylesForLeftColum = {
  border: "1px solid #dddddd",
  fontWeight: "bold",
  margin: "0 0 0 0",
  maxWidth: "60%",
  padding: "dense",
};

const stylesForRightColum = {
  border: "1px solid #dddddd",
  margin: "0 0 0 0",
  padding: "0 0 0 1em",
};

export interface RequestPreviewProps {
  classes: any;
  setId: (s: string) => string;
  t: (key: string, params?: any[]) => string;
  data: any;
  schema: ObjectSchema;
  onError: (error: string) => void;
}

const RequestPreview: React.FC<RequestPreviewProps> = ({
  classes,
  setId,
  t,
  data,
  schema,
  onError,
}: RequestPreviewProps) => {
  if (!data || !schema) {
    return (
      <Typography id={setId("request-preview")}>{t("NO_PREVIEW")}</Typography>
    );
  }

  const formatValue = (value: any, type: string) => {
    switch (type) {
      case "boolean":
        return value ? "так" : "ні";
      case "date":
        value = Formatter.formatDate(value);
        return value;
      case "date-time":
        return Formatter.formatDateTime(value);
      default:
        if (Array.isArray(value)) {
          value = value.join(", ");
        }
        return value;
    }
  };

  const renderPropValue = (
    propName: string,
    value: any,
    schema: Partial<JsonSchema>
  ) => {
    if (propName === "state") {
      return null;
    }
    return schema.type === "object" || schema.type === "array" ? (
      <tr>
        <td style={stylesForTitle}>{schema.title || propName}:</td>
        <td style={stylesForTitle}>{value}</td>
      </tr>
    ) : (
      <tr>
        <td style={stylesForLeftColum}>{schema.title || propName}:</td>
        <td style={stylesForRightColum}>
          {formatValue(value, (schema.format || schema.type) as string)}
        </td>
      </tr>
    );
  };

  const renderObject = (propName: string, value: any, schema: ObjectSchema) => {
    //Add object info
    const { properties } = schema;
    const elem = renderPropValue(propName, "", schema);
    tableRows.push(elem);

    const objProps = properties || {};

    //Add object properties info
    Object.getOwnPropertyNames(value).forEach((propName) => {
      if (isEmptyValue(value[propName])) {
        return;
      }

      const propSchm = objProps[propName] || { type: "array" };

      if (Array.isArray(value[propName])) {
        const elem = renderPropValue(propName, "", propSchm);
        tableRows.push(elem);

        const itemSchm = (propSchm as ArraySchema)?.items || {};
        // const { title, type } = itemSchm;
        for (const item of value[propName]) {
          if (itemSchm.type === "object") {
            renderObject(propName, item, itemSchm);
          } else {
            const elem = renderPropValue(propName, item, itemSchm);
            tableRows.push(elem);
          }
        }
      } else if (typeof value[propName] === "object") {
        renderObject(propName, value[propName], propSchm);
      } else {
        const elem = renderPropValue(propName, value[propName], propSchm);
        tableRows.push(elem);
      }
    });
  };

  const tableRows: any[] = [];

  renderObject("ЗМІСТ ЗАПИТУ", data, schema);
  return (
    <Typography id={setId("request-preview")}>
      <table>
        <tbody>{tableRows}</tbody>
      </table>
    </Typography>
  );
};

export default RequestPreview;
