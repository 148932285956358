// -1  DELETED  Видалено
// 0  DRAFT  Чернетка
// 1  SIGNING  Підписання
// 2  SEND  Надіслано
// 3  SUCCESS  Отримано відповідь
// 4  ERROR  Помилка
// 5  ERROR_INSIDE Внутрішня помилка

export default {
  0: {
    background: "#d9d9d9",
  },
  1: {
    background: "#00b0f0",
  },
  2: {
    background: "#10b526",
  },
  3: {
    background: "#008a1e",
  },
  4: {
    background: "#e30019",
  },
  5: {
    background: "#b80417",
  },
};
