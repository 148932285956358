import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CaseMemberNameFilterChip = ({ t, value, ...rest }) => (
  <Chip label={`${t("CASE_MEMBER_NAME_FILTER")}: ${value}`} {...rest} />
);

CaseMemberNameFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CaseMemberNameFilterChip.defaultProps = {
  value: "",
};

export default translate("ClaimList")(CaseMemberNameFilterChip);
