import { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";

import cx from "classnames";
import { IAuth } from "../../../types/auth.interface";
import { Button } from "../../../components";

interface Props {
  toggleDialog: (val: string) => any;
  classes: any;
  t: (val: string) => any;
  setId: (val: string) => any;
  auth?: IAuth;
}

const ShareWarningDialog: FC<Props> = ({
  toggleDialog,
  classes,
  t,
  setId,
  auth,
}) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        open={true}
        onClose={toggleDialog("warningShare")}
        id={setId("wrapper")}
        className={cx(classes.dialog, classes.fullWidth)}
      >
        <DialogContent
          className={cx(classes.content, classes.dialogContentWrappers)}
        >
          <Typography
            variant="subtitle1"
            className={classes.heading}
          ></Typography>
          <Typography variant="subtitle1" className={classes.body1}>
            {auth?.info && auth?.info.linkedAt ? (
              <p>
                Буде створена довіреність на представника від{" "}
                <strong>фізичної особи</strong>.
              </p>
            ) : (
              <p>
                Неможливо створити довіреність від фізичної особи, так як Ви не
                зареєстровані як фізична особа.
              </p>
            )}
            <p>
              Якщо вам потрібно створити довіреність від юридичної особи,
              необхідно в списку довіреностей відкрити документ "Виписка з ЄДР"
              та натиснути в ньому кнопку "Передоручити".
            </p>
            <div>
              Детальніше:{" "}
              <a
                target="_blank"
                href="https://wiki-ccs.court.gov.ua/w/%D0%95%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B8%D0%B9_%D1%81%D1%83%D0%B4_-_%D0%BF%D0%B8%D1%82%D0%B0%D0%BD%D0%BD%D1%8F_%D1%82%D0%B0_%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D1%96#%D0%A0%D0%B5%D1%94%D1%81%D1%82%D1%80%D0%B0%D1%86%D1%96%D1%8F_%D0%BA%D0%B0%D0%B1%D1%96%D0%BD%D0%B5%D1%82%D1%83_%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%B8_%D1%82%D0%B0_%D0%BD%D0%B0%D0%B4%D0%B0%D0%BD%D0%BD%D1%8F_%D0%B4%D0%BE%D0%B2%D1%96%D1%80%D0%B5%D0%BD%D0%BE%D1%81%D1%82%D0%B5%D0%B9_%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D0%BD%D0%B8%D0%BA%D0%B0%D0%BC"
                rel="noreferrer"
              >
                Електронний суд - Реєстрація кабінету установи та надання
                довіреностей представникам
              </a>
              .
            </div>
          </Typography>
        </DialogContent>
        <DialogActions
          className={cx(classes.actions, classes.dialogContentWrappers)}
        >
          <Button
            onClick={toggleDialog("warningShare")}
            color="transparent"
            mr={2}
          >
            {t("CANCEL_ACTION")}
          </Button>
          {auth?.info && auth?.info.linkedAt && (
            <Button onClick={toggleDialog("addShare")} color="yellow">
              {t("CONTINUE")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareWarningDialog;
