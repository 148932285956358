// import React from 'react';
import store from "../../store";
import config from "../../config";

const LMK = 0;
const MMK = 1;

/**
 * @param {MouseEvent} e
 */
export const handleVKZClick = async (e) => {
  // let target = '_self';
  let target = "_blank";
  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
    target = "_blank";
  }

  e.preventDefault();
  const host = config().vkzRedirectUrl;
  const { token } = store.getState().authorization || {};
  if (!host || !token) return;
  window.open(host + "?token=" + token, target);
};

/**
 * @param {MouseEvent} e
 */
//перенести
export const handleEDRSRClick = async (e) => {
  let target = "_blank";

  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
    target = "_blank";
  }

  e.preventDefault();

  const host = config().edrsrRedirectUrl;
  const { token } = store.getState().authorization || {};
  if (!host || !token) return;
  try {
    const response = await fetch(host, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const responseData = await response.text();
      const newWindow = window.open("", target);
      newWindow.document.write(responseData);
      newWindow.document.close();
    } else {
      console.error("Failed to fetch data:", response.status);
    }
  } catch (error) {
    console.error("Request error:", error);
  }
};
