export default {
  root: {
    display: "flex",
  },
  arrow: {
    height: 38,
    opacity: 0.4,
  },
  group: {
    padding: "10px 0",
  },
};
