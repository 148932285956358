import React from "react";
import PropTypes from "prop-types";
// import {connect} from 'react-redux';
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const ProceedingsNumberFilterChip = ({ t, value, ...rest }) => (
  <Chip label={`${t("PROCEEDING_NUMBER")}: ${value}`} {...rest} />
);

ProceedingsNumberFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ProceedingsNumberFilterChip.defaultProps = {
  value: "",
};

export default translate("SearchPage")(ProceedingsNumberFilterChip);
