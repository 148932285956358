export default {
  ERROR: "Немає з'єднання з сервером",
  ERROR_DESCRIPTION: "Спробуйте, будь ласка, за кілька хвилин",
  E_USER_NOT_REGISTERED:
    'Для доступу до Ваших особових даних в ролі "Фізична особа" необхідно зареєструватись Користувачем кабінету, для цього необхідно перевірити, дозаповнити та зберегти свій профіль.',
  E_ORG_NOT_REGISTERED: `Ви ввійшли як {{agentType}} {{companyName}}, 
  але організація не зареєстрована в Кабінеті.`,
  E_NO_PERMISSIONS: `Ви ввійшли як {{agentType}} {{companyName}}, 
  але не маєте довіреності для отримання відповідної ролі. Для реєстрації довіреності зверніться до уповноваженої особи.`,
  REGISTER_COMPANY_HEAD:
    ' Для доступу до даних організації в ролі "Представник {{companyName}}" необхідно зареєструвати організацію в Кабінеті, для цього необхідно перевірити, дозаповнити та зберегти профіль організації.',
  REGISTER_COMPANY_AGENT:
    " Для реєстрації організації зверніться до керівника.",
};
