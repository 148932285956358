import React from "react";
import PropTypes from "prop-types";
// import {connect} from 'react-redux';
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const JudgeFilterChip = ({ t, value, ...rest }) => (
  <Chip label={`${t("JUDGE")}: ${value}`} {...rest} />
);

JudgeFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

JudgeFilterChip.defaultProps = {
  value: "",
};

export default translate("SearchPage")(JudgeFilterChip);
