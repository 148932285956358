import { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { compose } from "redux";

import useWindowSize from "../hooks/useWindowSize";
import styles from "./index.module.css";

const IndexPage = ({ t }) => {
  const size = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {/* Електронний суд */}
        {t("MESSAGE")}
        {size?.width > 959 ? t("OPEN_MENU") : ""}.
      </div>
    </div>
  );
};

IndexPage.propTypes = {
  t: PropTypes.func.isRequired,
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return <IndexPage t={t} />;
  }
}

Index.propTypes = {
  t: PropTypes.func.isRequired,
};

Index.defaultProps = {};

const IndexWrapper = compose(translate("IndexPage"))(Index);

export default IndexWrapper;
