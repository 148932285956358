const caseListStyle = {
  splitPanel: {
    "@media screen and (max-width: 575px)": {
      "& > div": {
        minWidth: "100%",
      },
      boxShadow: "none",
      maxWidth: "100%",
      display: "flex",
      flexWrap: "wrap",
      "& > div:first-child": {
        order: 2,
      },
      "& > div:last-child": {
        order: 1,
      },
    },
  },
};

export default caseListStyle;
