export default {
  title: {
    paddingBottom: 0,
  },
  subtitle: {
    textTransform: "initial",
  },
  formControl: {
    width: "100%",
  },
  formLabel: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
    marginRight: 16,
  },
  selectControl: {
    margin: "0 auto",
    cursor: "pointer",
  },
  radio: {
    color: "rgb(0, 0, 0, .75)",
  },
  radioTitle: {
    display: "block",
    fontSize: "16px",
  },
  radioDescription: {
    opacity: "0.54",
    fontSize: "12px",
  },
  actions: {
    justifyContent: "flex-start",
  },
  changeTitle: {
    display: "flex",
    marginTop: "27px",
  },
  userBlock: {
    display: "flex",
    alignItems: "center",
    minWidth: "48px",
    justifyContent: "center",
  },
  userIcon: {
    fontSize: "48px",
    marginRight: "10px",
    opacity: "0.54",
    "& span": {
      fontSize: "1em",
    },
  },
  arrow: {
    opacity: "0.54",
    fontWeight: "300",
  },
  userContent: {},
  userName: {
    display: "block",
    fontSize: "16px",
  },
  userPermission: {
    display: "block",
    opacity: "0.54",
    fontSize: "12px",
  },
  table: {
    marginTop: 30,
  },
  "@media (max-width:767px)": {
    // eslint-disable-line no-useless-computed-key
    table: {
      marginTop: 10,
    },
    cell: {
      padding: "0 10px",
      height: "36px",
      wordBreak: "break-all",
      minWidth: 60,
    },
    group: {
      display: "block!important",
    },
  },
};
