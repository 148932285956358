import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForever from "@material-ui/icons/DeleteForever";
import RegularButton from "../../../components/CustomButtons/Button";
import Button from "../../../components/CustomButtons/Button";
import DraftImg from "../../../assets/img/drafts_black_20dp.png";
import UnreadImg from "../../../assets/img/mark_as_unread_black_20dp.png";
import Filters from "./Filters";
import setComponentsId from "../../../helpers/setComponentsId";

const NotificationToolbar = ({
  checked,
  currentStatus,
  classes,
  dataSource,
  toggleDeleteModal,
  deleteModal,
  deleteAllNotifications,
  deleteNotifications,
  viewedStatus,
  setId,
  t,
  read,
  readList,
  unread,
  unreadList,
  setCheckedIsRead,
  handleChangeFilter,
  docCategory,
  chips,
  load,
}) => (
  <Toolbar className={classes.toolbar} disableGutters={true}>
    <RegularButton
      disabled={!unreadList.length}
      color="transparent"
      onClick={setCheckedIsRead}
      setId={(elementName) => setId(`set-is-read-${elementName}`)}
    >
      <img
        src={DraftImg}
        className={classes.setIsReadButton}
        alt={t("SET_IS_READ")}
      />
      {t("SET_IS_READ") + ` (${unreadList.length})`}
    </RegularButton>
    <RegularButton
      color="transparent"
      disabled={!readList.length}
      onClick={setCheckedIsRead.bind(this, false)}
      setId={(elementName) => setId(`set-is-not-read-${elementName}`)}
    >
      <img
        src={UnreadImg}
        className={classes.setIsReadButton}
        alt={t("SET_IS_NOT_READ")}
      />
      {t("SET_IS_NOT_READ") + ` (${readList.length})`}
    </RegularButton>
    <RegularButton
      disabled={!checked.length}
      color="transparent"
      onClick={deleteNotifications}
      setId={(elementName) => setId(`delete-${elementName}`)}
    >
      <DeleteIcon />
      {t("DELETE") + ` (${checked.length})`}
    </RegularButton>
    <Button
      color="transparent"
      onClick={toggleDeleteModal}
      setId={(elementName) => setId(`delete-${elementName}`)}
    >
      <DeleteForever />
      {t("DELETE_ALL")}
    </Button>
    {deleteModal && (
      <Dialog
        fullWidth={true}
        open={deleteModal}
        onClose={toggleDeleteModal}
        aria-labelledby={setId("dialog-title")}
        id={setId("")}
        className={classes.dialog}
      >
        <DialogTitle
          id={setId("dialog-title")}
          className={classes.dialogContentWrappers}
        >
          Увага!
        </DialogTitle>
        <DialogContent>
          Ви дійсно хочете видалити <b>всі</b> повідомлення? Їх <b>неможливо</b>{" "}
          буде відновити.
        </DialogContent>
        <DialogActions id={setId("actions")}>
          <Button
            onClick={toggleDeleteModal}
            color="transparent"
            id={setId("delete-all-button")}
            setId={(elementName) => setId(`save-${elementName}`)}
          >
            {t("CANCEL")}
          </Button>
          <Button
            onClick={deleteAllNotifications}
            color="danger"
            id={setId("delete-all-button")}
            setId={(elementName) => setId(`save-${elementName}`)}
          >
            {t("CONFIRM_DELETE_ALL")}
          </Button>
        </DialogActions>
      </Dialog>
    )}
    <Filters
      classes={classes}
      t={t}
      dataSource={dataSource}
      setId={setId}
      load={load}
      currentStatus={currentStatus}
      viewedStatus={viewedStatus}
      handleChangeFilter={handleChangeFilter}
      unread={unread}
      read={read}
    />
  </Toolbar>
);

NotificationToolbar.propTypes = {
  toggleDeleteModal: PropTypes.func.isRequired,
  deleteModal: PropTypes.bool.isRequired,
  read: PropTypes.number.isRequired,
  unread: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  deleteAllNotifications: PropTypes.func.isRequired,
  deleteNotifications: PropTypes.func.isRequired,
  setCheckedIsRead: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  // load: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,
};

NotificationToolbar.defaultProps = {
  setId: setComponentsId("notification-toolbar"),
};

export default NotificationToolbar;
