export const tableFields = {
  pagination: true,
  checkbox: null,
  tableFields: [
    {
      key: "appName",
      sort: false,
      title: "APP_NAME",
      classNames: ["cell", "shortCell"],
    },
    {
      key: "appVersion",
      sort: false,
      title: "APP_VERSION",
      classNames: ["cell", "shortCell"],
      grid: [1, 7, 1, 2],
    },
    {
      key: "deviceModel",
      sort: false,
      title: "DEVICE_MODEL",
      classNames: ["cell", "shortCell"],
    },
    {
      key: "deviceName",
      sort: false,
      title: "DEVICE_NAME",
      classNames: ["cell", "shortCell"],
    },
    {
      key: "osVersion",
      sort: false,
      title: "OS_VERSION",
      classNames: ["cell", "shortCell"],
    },
  ],
};
