import * as api from "../services/api";
import store from "../store";

export const CHECK_CABINET = "CHECK_CABINET";
export const CHECK_CABINET_DOWNLOAD = "CHECK_CABINET_DOWNLOAD";
export const PUT_CHECK_CABINET = "PUT_CHECK_CABINET";
export const FIND_USERS = "FIND_USERS";

const USERS_URL = "api/users";

export async function getCheckCabinet(code) {
  return await api.get(
    `${USERS_URL}/check_is_user?code=${code}`,
    CHECK_CABINET,
    store.dispatch
  );
}

export async function getCheckCabinetDownload(reqId, code, name) {
  return await api.get(
    `${USERS_URL}/check_is_user/download?reqId=${reqId}&ipn=${code}&name=${name}`,
    CHECK_CABINET_DOWNLOAD,
    store.dispatch
  );
}

export async function putCheckCabinet(reqId, deliveredAt) {
  return await api.put(
    `${USERS_URL}/check_is_user/${reqId}`,
    { deliveredAt },
    PUT_CHECK_CABINET,
    store.dispatch
  );
}

export async function getFindUsers(codeArray) {
  return await api.get(
    `${USERS_URL}/find-users?codes=${codeArray.join(",")}`,
    FIND_USERS,
    store.dispatch
  );
}
