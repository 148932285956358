import React from "react";
import RegisterChip from "./RegisterChip";

const PublicationStart = (props) => (
  <RegisterChip
    title="PUBLICATION_START"
    valueKey="receipt_date_start"
    valueType="string"
    {...props}
  />
);

export default PublicationStart;
