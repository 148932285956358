export default {
  HAVE_BENEFITS:
    "Я звільнений(-а) від сплати судового збору згідно {{link}}, або збір вже сплачено",
  HAVE_BENEFITS_LINK: "статті 5 Закону України «Про судовий збір»",
  PAYMENT_TITLE: "Найменування платежу",
  PAYMENT_SUM: "Вартість",
  PAYMENT_AMOUNT: "Разом",
  PAYMENT_USER_AMOUNT: "До сплати",
  PAY: "Сплатити",
  UPLOAD: "Завантажити документ",
  SELECT_BENEFIT: "Пільги",
  TRANSACTION_SUCCESS_PROCESSED: "Судовий збір сплачено",
  ALREADY_PAID:
    "Судовий збір сплачено (Необхідно завантажити квитанції в додатки)",
  USE_USER_AMOUNT: "Я хочу сплатити іншу суму",
  NO_SCAN_ERROR: "Додайте відсканований документ",
  NO_PRIVELEGE_ERROR: "Виберіть пільгу",
  NO_EMPTY_SUMM: "Cума не може дорівнювати нулю",
  NO_CASE_ERROR:
    "Суд не визначено, будь ласка, зверніться до технічної підтримки.",
  NO_FORM_ERROR:
    "Форма оплати не визначена, будь ласка, зверніться до технічної підтримки.",
  NO_PAY_ERROR:
    "Сума до сплати не визначена, будь ласка, зверніться до технічної підтримки або введіть іншу суму.",
  NO_PAYER_INFO_ERROR:
    "Дані користувача, що проводить оплату не визначено. Спробуйте, будь ласка, пізніше.",
  NO_CASE_SYSTEM_ERROR: "Не визначена сістема оплати",
  NO_PAY_OR_CASE_ERROR:
    "Не призначена сума, або у поточній справі не визначено суд",
  CREATED_FORM: "Cтворення форми оплати",
};
