export const docsFields = {
  pagination: true,
  checkbox: null,
  tableFields: [
    {
      key: "scanDoc",
      sort: false,
      title: "",
      classNames: [
        "cell",
        "verticalAlign",
        "checkboxCell",
        "onlyBigScreen",
        "attachIcon",
      ],
    },
    {
      key: "docFullName",
      sort: false,
      title: "DOCUMENT_NUMBER",
      classNames: ["cell", "longCell", "onlyMobile"],
      grid: [1, 7, 1, 2],
    },
    {
      key: "docNumber",
      sort: false,
      title: "DOCUMENT_NUMBER",
      classNames: ["cell", "shortCell", "width100", "onlyBigScreen"],
    },
    {
      key: "docDate",
      sort: true,
      title: "CREATE_DATE",
      classNames: ["cell", "shortCell", "width105", "onlyBigScreen"],
    },
    {
      key: "dateForce",
      sort: false,
      title: "DNZS_DATE_SHORT",
      headerHint: "DNZS_DATE",
      classNames: ["cell", "shortCell", "width120", "onlyBigScreen"],
    },
    {
      key: "description",
      sort: false,
      title: "DOCUMENT_NAME",
      classNames: ["cell", "longCell"],
      grid: [1, 7, 2, 3],
    },
  ],
  filters: ["docDate"],
};
