export default {
  flex: {
    display: "flex",
    width: "100%",
  },
  button: {
    // background: '#ececec'
  },
  children: {
    flex: 1,
    width: "calc(100% - 24px)",
  },
};
