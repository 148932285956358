import AutocompleteControl from "./AutocompleteControl";
import BooleanControl from "./BooleanControl";
import DateControl from "./DateControl";
import IntegerControl from "./IntegerControl";
import StringControl from "./StringControl";
import TextareaControl from "./TextareaControl";

export default {
  AutocompleteControl,
  BooleanControl,
  DateControl,
  IntegerControl,
  StringControl,
  TextareaControl,
};
