import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";
import { filterMinDate, filterMaxDate } from "../../../helpers/humanDateFormat";

const formatDate = (value, t) => {
  const splited = value.split(",");

  if (splited.length !== 2) {
    return "";
  }

  if (splited[0] === splited[1]) {
    return `${t("DOC_DATE")}: ${splited[0]}`;
  }

  const fromDate =
    splited[0] && splited[0] !== filterMinDate
      ? `${t("FROM_DOC_DATE")}: ${splited[0]}`
      : "";
  const toDate =
    splited[1] && splited[1] !== filterMaxDate
      ? `${t("TO_DOC_DATE")}: ${splited[1]}`
      : "";
  const connector = fromDate && toDate ? ", " : "";

  return `${fromDate}${connector}${toDate}`;
};

const DocDateFilterChip = ({ t, value, ...rest }) => (
  <Chip label={formatDate(value, t)} {...rest} />
);

DocDateFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

DocDateFilterChip.defaultProps = {
  value: "",
};

export default translate("ClaimList")(DocDateFilterChip);
