import { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Radio,
  FormControlLabel,
  FormControl,
  Tooltip,
  RadioGroup,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import cx from "classnames";
import SelectClaiment from "./SelectClaiment";
import UploadMultipleFiles from "../../UploadMultipleFiles";
import Hidden from "@material-ui/core/Hidden";

const OwnerMode = ({
  classes,
  t,
  setId,
  owners,
  ownersFilter,
  value: propValue,
  claimentSelectMode,
  openedTooltip,
  error,
  toggleSelectMode,
  toggleTooltip,
  setOwnerId,
  handleDeleteFile,
  allowAttachOrder,
  allowAttachFile,
  onDropAuthorization,
}) => {
  const buttons = [
    {
      label: "ELECTRONIC_AUTHORIZATION",
      title: "ELECTRONIC_AUTHORIZATION_HINT",
      value: "select",
    },
  ];
  if (allowAttachOrder) {
    buttons.push({
      label: "ELECTRONIC_ORDER",
      title: "ELECTRONIC_ORDER_HINT",
      value: "order",
    });
  }
  if (allowAttachFile) {
    buttons.push({
      label: "AUTHORIZATION_SCAN",
      title: "AUTHORIZATION_SCAN_HINT",
      value: "upload",
    });
  }

  return (
    <Fragment>
      <FormControl
        fullWidth
        component="fieldset"
        className={classes.selectControl}
      >
        <RadioGroup
          // row
          className={classes.group}
          value={claimentSelectMode}
          onChange={toggleSelectMode}
        >
          {buttons.map(({ label, title, value }) => (
            <FormControlLabel
              key={`radio-${value}`}
              value={value}
              className={cx(classes.flex, classes.tooltipRadio)}
              control={<Radio color="default" />}
              label={t(label, {
                tip: (
                  <>
                    <Hidden smDown>
                      <Tooltip
                        classes={{
                          tooltip: classes.toolTip,
                        }}
                        title={t(title)}
                      >
                        <HelpIcon className={classes.toolTipIcon} />
                      </Tooltip>
                    </Hidden>
                    <Hidden mdUp>
                      <Tooltip
                        classes={{
                          tooltip: classes.toolTip,
                        }}
                        title={t(title)}
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={value === openedTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={toggleTooltip("")}
                      >
                        <HelpIcon
                          className={classes.toolTipIcon}
                          onClick={toggleTooltip(value)}
                        />
                      </Tooltip>
                    </Hidden>
                  </>
                ),
              })}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {(claimentSelectMode === "select" || claimentSelectMode === "order") && (
        <SelectClaiment
          classes={classes}
          t={t}
          setId={setId}
          owners={owners}
          ownersFilter={ownersFilter}
          value={propValue}
          setOwnerId={setOwnerId}
          claimentSelectMode={claimentSelectMode}
        />
      )}
      {claimentSelectMode === "upload" && (
        <UploadMultipleFiles
          setId={setId}
          files={propValue && propValue.files}
          error={error}
          onDeleteFile={handleDeleteFile}
          onDrop={onDropAuthorization}
        />
      )}
    </Fragment>
  );
};

OwnerMode.propTypes = {
  classes: PropTypes.object.isRequired,
  allowAttachOrder: PropTypes.bool.isRequired,
  allowAttachFile: PropTypes.bool.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  claimentSelectMode: PropTypes.string.isRequired,
  openedTooltip: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  owners: PropTypes.array,
  ownersFilter: PropTypes.func,
  value: PropTypes.object,
  toggleSelectMode: PropTypes.func.isRequired,
  toggleTooltip: PropTypes.func.isRequired,
  setOwnerId: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  onDropAuthorization: PropTypes.func.isRequired,
};

OwnerMode.defaultProps = {
  owners: [],
  value: {},
};

export default OwnerMode;
