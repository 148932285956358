import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import setComponentsId from "../../helpers/setComponentsId";
import customInputStyle from "../../variables/styles/customInputStyle";
import components from "./components";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
  input: {
    display: "flex",
    height: 19,
  },
  singleValue: {
    fontSize: 16,
    marginTop: -2,
    marginBottom: -4,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px 0`,
    maxWidth: "100%",
    boxSizing: "border-box",
    padding: "4px 12px",
    "& span": {
      maxWidth: "calc(100% - 24px)",
      display: "block",
      boxSizing: "border-box",
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: 0,
    },
    "& svg": {
      margin: "0 0 0 4px",
    },
  },
  chipWrap: {
    display: "block",
    width: "100%",
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    // marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  ...customInputStyle,
});

class MultiSelect extends React.Component {
  handleMultiChange = (value) => {
    this.props.onChange({ target: { value: value.map(({ id }) => id) } });
  };

  handleSingleChange = (value) => {
    const { onChange, name } = this.props;
    onChange({ target: { value: value ? value.id : value, name } });
  };

  render() {
    const {
      classes,
      theme,
      label,
      multi,
      id,
      name,
      onInputChange,
      setId,
      selectProps,
      error,
    } = this.props;
    const options = (this.props.options || []).map((item) => ({
      ...item,
      value: item.value || item.id,
      label: item.name,
    }));
    const value = multi
      ? options.filter((item) => (this.props.value || []).includes(item.id))
      : options.find((item) => (this.props.value || "") === item.id) || null;
    const componentId = id || setId(name);
    const inputId = `${componentId}-input`;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    const shrink =
      value && (!!value.length || !!(value.label && value.label.length));

    const textFieldProps = Object.assign(
      { label },
      { InputLabelProps: Object.assign({ error }, shrink && { shrink }) },
      selectProps.textFieldProps
    );

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            {...this.props}
            {...this.props.selectProps}
            id={componentId}
            inputId={inputId}
            classes={classes}
            styles={selectStyles}
            textFieldProps={textFieldProps}
            options={options}
            components={components}
            value={value}
            onChange={multi ? this.handleMultiChange : this.handleSingleChange}
            onInputChange={onInputChange}
            placeholder={null}
            isMulti={multi}
            isClearable={!multi}
            menuPortalTarget={document.body}
          />
        </NoSsr>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func, //(newValue: string, actionMeta: InputActionMeta) => void;
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  multi: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  setId: PropTypes.func,
  wrapClassName: PropTypes.string,
  selectProps: PropTypes.object,
  error: PropTypes.bool,
};

MultiSelect.defaultProps = {
  onChange: () => null,
  label: "",
  value: null,
  options: [],
  multi: false,
  name: "select",
  id: "",
  setId: setComponentsId("select-with-search"),
  wrapClassName: "",
  selectProps: {},
  error: false,
};

export default withStyles(styles, { withTheme: true })(MultiSelect);
