/* eslint key-spacing: ['error', { 'mode': 'strict' }] */
/*
№     Old names                 New names
 -1    Видалення               'Видалення'
 1    Створення                'Чернетка'
 2    Підписання               'Підписання'
 3    Очікує на відправлення   'Відправлено'
 4    Відправлений             'Доставлено'
 5    Помилка відправлення     'Помилка обробки'
 6    Помилка отримання        'Помилка обробки'
 7    Прийнятий                'Доставлено'
 8    Відмовлено в реєстрації  'Відмовлено в реєстрації'
 9    Помилка реєстрації       'Помилка обробки'
 10    Очікує на реєстрацію    'Зареєстровано'
 11    Зареєстрований          'Зареєстровано'
 12    Зареєстрований          'Зареєстровано'
 14    Зареєстрований          'Зареєстровано'
 15    Зареєстрований          'Зареєстровано'
 16    Відкликана довіреність  'Помилка обробки'

SCOPED
'1',          'Чернетка'
'2',          'Підписання'
'3, 4',       'Відправлено'
'7,10',       'Доставлено'
'5,6,9,16',   'Помилка обробки'
'8',          'Відмовлено в реєстрації'
'11,12,14,15' 'Зареєстровано'
'18'          'Надіслано сторонам'
*/

export default {
  //  -1            'Видалення'
  "-1": {
    background: "#ED2901",
  },
  // '1',          'Чернетка'
  1: {
    background: "#7F8C8D", // #D9D9D9
  },
  // '2',          'Підписання'
  2: {
    background: "#F4BF06",
  },
  //  '3, 4',       'Відправлено'
  3: {
    background: "#498FFF",
  },
  4: {
    background: "#498FFF",
  },
  // '7,10',       'Доставлено'
  7: {
    background: "#3671BF",
  },
  10: {
    background: "#43B050",
  },
  // '5,6,9,16',   'Помилка обробки' '#740000',
  // 9,16 зараз 'Відправлено' '#498FFF'
  5: {
    background: "#ca0404",
  },
  6: {
    background: "#ca0404",
  },
  9: {
    background: "#ca0404",
  },
  16: {
    background: "#ca0404",
  },
  18: {
    background: "#FF8300",
  },
  // '8',          'Відмовлено в реєстрації'
  8: {
    background: "#ED2901",
  },
  // '11,12,14,15' 'Зареєстровано'
  11: {
    background: "#43B050",
  },
  12: {
    background: "#43B050",
  },
  14: {
    background: "#43B050",
  },
  15: {
    background: "#43B050",
  },

  22: {
    background: "#F0AD4E",
  },
  // 23: { // цвет для чипсы "На судочинство" в списке доверенностей
  //   background: "#F0AD4E",
  // },
  //  -1            'Видалення'
  "-401": {
    background: "#ED2901",
  },
  // '1',          'не підлягає виконанню'
  401: {
    background: "#7F8C8D",
  },
  // '2',          'не пред’явлений до виконання'
  402: {
    background: "#F4BF06",
  },
  //  '3',        'очікує прийняття до виконання'
  403: {
    background: "#3671BF",
  },
  //  '4',        'перебуває на виконанні'
  404: {
    background: "#498FFF",
  },
  //  '5',   ' виконання зупинено'
  405: {
    background: "#000",
  },
  //  '6'     'виконання припинено'
  406: {
    background: "#000",
  },
  // '7',       'виконання закінчено'
  407: {
    background: "#43B050",
  },
  408: {
    background: "#43B050",
  },
  409: {
    background: "#43B050",
  },
  410: {
    background: "#43B050",
  },
  411: {
    background: "#43B050",
  },
  412: {
    background: "#43B050",
  },
  413: {
    background: "#43B050",
  },
  414: {
    background: "#43B050",
  },
  415: {
    background: "#43B050",
  },
  416: {
    background: "#43B050",
  },
  417: {
    background: "#43B050",
  },
  418: {
    background: "#43B050",
  },
  419: {
    background: "#43B050",
  },
  420: {
    background: "#43B050",
  },
  421: {
    background: "#43B050",
  },
  422: {
    background: "#43B050",
  },
};
