import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { Button } from "../../../components";
import { Toolbar, Icon } from "@material-ui/core";
import { getStatusesForCat } from "../../../variables/claimStatuses";

import Filters from "./Filters";
import CreateButton from "./CreateButton";

const ClaimToolbar = ({
  t,
  classes,
  trash,
  checked,
  busy,
  dataSource,
  toggleTemplateDialog,
  toggleDeleteDialog,
  handleClaimRecover,
  load,
  setId,
  userInfo,
  docCategory,
  idsForDelete,
  approval,
  returnObject,
}) => (
  <Toolbar className={classes.toolbar} disableGutters={true}>
    {!trash && !approval && (
      <CreateButton
        onChange={toggleTemplateDialog}
        t={t}
        setId={setId}
        userInfo={userInfo}
        docCategory={docCategory}
      />
    )}
    {trash && !approval && (
      <Button
        color="yellow"
        variant="contained"
        disabled={!checked.length || busy}
        onClick={handleClaimRecover}
        id={setId("recover-button")}
        setId={(elmentName) => setId(`recover-${elmentName}`)}
      >
        {t("RECOVER")} ({checked.length})
      </Button>
    )}
    {!approval && (
      <Button
        color="transparent"
        variant="contained"
        disabled={!idsForDelete.length}
        onClick={toggleDeleteDialog}
        className={!idsForDelete.length ? classes.button_disabled : ""}
        setId={(elmentName) => setId(`delete-${elmentName}`)}
        id={setId("delete-button")}
      >
        <Icon>delete</Icon>
        {trash
          ? t("TOTAL_DELETE")
          : `${t("DELETE")}  ${
              idsForDelete.length > 1 ? `(${idsForDelete.length})` : ""
            }`}
      </Button>
    )}
    <Filters
      dataSource={dataSource}
      load={load}
      setId={setId}
      statusesLib={getStatusesForCat(docCategory)}
      docCategory={docCategory}
      trash={trash}
      approval={approval}
      returnObject={returnObject}
    />
  </Toolbar>
);

ClaimToolbar.propTypes = {
  trash: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  checked: PropTypes.array.isRequired,

  toggleTemplateDialog: PropTypes.func.isRequired,
  toggleDeleteDialog: PropTypes.func.isRequired,
  handleClaimRecover: PropTypes.func.isRequired,

  busy: PropTypes.bool.isRequired,
  dataSource: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  docCategory: PropTypes.number.isRequired,
  approval: PropTypes.bool.isRequired,
};

ClaimToolbar.defaultProps = {
  setId: setComponentsId("claim-toolbar"),
};

export default ClaimToolbar;
