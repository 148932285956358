import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import setComponentsId from "../../../helpers/setComponentsId";
import FilterInput from "../../../components/FilterInput";
import customInputStyle from "../../../variables/styles/customInputStyle";
import { sortStatuses } from "../../../variables/claimStatuses";
import DateRangePicker from "../../../components/CustomInput/DateRangePicker";
import StatusChip from "../../../components/StatusChip";

const Filters = ({
  classes,
  t,
  dataSource,
  load,
  setId,
  statusesLib,
  trash,
  docCategory,
  approval,
  returnObject,
}) => {
  const sortingStatuses = sortStatuses(t, statusesLib, docCategory);
  return (
    <FilterInput
      dataSource={dataSource}
      load={load}
      setId={setId}
      statuses={sortingStatuses}
      hideStatus={approval}
      returnObject={returnObject}
    >
      <TextField
        select
        name="state"
        label={t("DOCUMENT_STATUS")}
        margin="normal"
        id={setId("select-status")}
        style={{ display: trash || approval ? "none" : "inlineFlex" }}
        SelectProps={{
          id: setId("select-state-filter"),
          SelectDisplayProps: {
            id: setId("select-state-filter-wrapper"),
          },
        }}
        className={classNames(classes.margin, classes.textField, classes.flex)}
      >
        {sortingStatuses.map(({ id, name, value }, index) => (
          <MenuItem key={index} value={value} id={setId(`status-${id}`)}>
            <StatusChip status={id} statusText={name} tableChip={true} />
          </MenuItem>
        ))}
      </TextField>
      <DateRangePicker
        name="createdAt"
        label={t("DOCUMENT_STATUS")}
        setId={setId}
      />
    </FilterInput>
  );
};

Filters.propTypes = {
  trash: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
  statusesLib: PropTypes.object,
  docCategory: PropTypes.number.isRequired,
  approval: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  statusesLib: {},
  setId: setComponentsId("claim-filters"),
};

const styled = withStyles(customInputStyle)(Filters);
export default translate("ClaimList")(styled);
