// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

import setComponentsId from "../../helpers/setComponentsId";
import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import styles from "../../variables/styles/inputDocs";

import { requestVpStatuses } from "../../actions/executive.orders";
import { getExecMyRoles } from "./data/dictionaries";
import { setDefaultFilters } from "./helpers/setUpDataSource";

import DataSource from "../../helpers/dataSource_executiveOrders";

import StandartPageLayout from "../../components/StandartPageLayout";
import ExecutiveOrdersToolbar from "./components/ExecutiveOrdersToolbar";
import ExecutiveOrdersTable from "./components/ExecutiveOrdersTable";
import { PERMISSIONS_URL } from "../../variables/wikiUrls";

const ExecutiveOrdersList = (props) => {
  const [state, setState] = useState({
    blockScreen: false,
    subLoading: false,
    loading: false,
  });
  const dispatch = useDispatch();

  const dataSource = DataSource("api/executive/decisions");
  const [roles, setRoles] = useState([]);
  const [isMounting, setIsMounting] = useState(true);

  const startLoading = () =>
    setState({
      blockScreen: true,
      subLoading: true,
      loading: true,
    });

  const finishLoading = () => {
    setState({
      blockScreen: false,
      subLoading: false,
      loading: false,
    });
    if (window.amplitude) {
      window.amplitude.track("Перегляд сторінки: Документи ВП");
      console.log(
        "Amplitude: Відстеження перегляду сторінки /executive-decisions"
      );
    }
  };

  const clearDataSource = () => {
    dataSource.filters = {};
    dataSource.sort = {};
    dataSource.setSearch("");
    props.returnObject.removeReturnObject();
  };

  useEffect(() => {
    if (!props.role?.length || !props.userInfo?.userId) return;

    setRoles(getExecMyRoles(props.t));
    const vpStatuses = requestVpStatuses();
    dispatch({ type: "REQUEST_VP_STATUSES", vpStatuses });

    const index = props.history?.location?.state?.prevPath?.indexOf(
      "/executive-decisions/"
    );
    const substr = props.history?.location?.state?.prevPath?.substring(
      index + "/executive-decisions/"?.length,
      props.history?.location?.state?.prevPath?.length
    );

    if (isMounting && !substr) {
      clearDataSource();
      setDefaultFilters({
        dataSource,
        userId: props.userInfo?.userId,
      });
      load();
    } else {
      props.history.push("/executive-decisions");
      load();
    }

    setIsMounting(false);
  }, [props.role, props.userInfo]);

  useEffect(() => {
    function beforeunloadEventHandler() {
      props.returnObject.removeReturnObject();
    }
    window.addEventListener("beforeunload", beforeunloadEventHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeunloadEventHandler);
    };
  }, []);

  const createSortHandler = (property) => () => {
    let order = "desc";
    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }
    // переход сортировки c "createdAt desc" на "-createdAt"
    dataSource.sort = order === "desc" ? `-${property}` : property;
    dataSource.sort = { [property]: order };

    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
    dataSource.sort = { [property]: order };

    dataSource.load().then(() => finishLoading());
  };

  const load = () => {
    startLoading();
    dataSource.load().then(() => finishLoading());
  };

  const handleClick = (rowData) => async () => {
    setState({ blockScreen: true });
    const { history } = props;

    props.returnObject.setReturnObject({
      form: "executive-decisions",
      id: rowData.id,
      filters: dataSource.filters,
      sort: dataSource.sort,
      path: window.location.pathname + window.location.search,
      search: dataSource.search,
    });

    setState({ blockScreen: false });
    return history.push(`/executive-decisions/${rowData.id}`);
  };

  const pagination = (e, page) => {
    startLoading();
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource.load().then(() => finishLoading());
  };

  const changeCount = ({ target: { value } }) => {
    setState({ blockScreen: true, subLoading: true, loading: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load().then(() => finishLoading());
  };

  const { list, setId, t, courts, classes, history, returnObject } = props;
  const { blockScreen, subLoading, loading } = state;

  return (
    <StandartPageLayout
      blockScreen={blockScreen}
      cardTitle={t("HEADER2")}
      dataSource={dataSource}
      emptyPageTitle={t("EXECUTIVE_EMPTY_PAGE_TITLE")}
      emptyPageDescription={t("EXECUTIVE_EMPTY_PAGE_DESCRIPTION")}
      list={list}
      total={0}
      setId={setId}
      stepName={"executive-decisions"}
      subLoading={subLoading}
      loading={loading}
      wikiUrl={PERMISSIONS_URL}
      toolbar={
        <ExecutiveOrdersToolbar
          t={t}
          classes={classes}
          setId={(elmentName) => setId(`toolbar-${elmentName}`)}
          dataSource={dataSource}
          load={load}
          courts={courts}
          vpStatuses={props.vpStatuses}
          roles={roles}
          userId={props.userInfo?.userId}
          returnObject={props.returnObject}
        />
      }
    >
      <ExecutiveOrdersTable
        classes={classes}
        setId={(elmentName) => setId(`table-${elmentName}`)}
        t={t}
        courts={courts}
        createSortHandler={createSortHandler}
        changeCount={changeCount}
        checked={[]}
        dataSource={dataSource}
        handleClick={handleClick}
        history={history}
        list={list}
        total={0}
        onSelectAllClick={false}
        onCheckboxClick={false}
        pagination={pagination}
        returnObject={returnObject}
        meta={props.meta}
        vpStatuses={props.vpStatuses}
      />
    </StandartPageLayout>
  );
};

ExecutiveOrdersList.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  userInfo: PropTypes.object.isRequired,
  meta: PropTypes.object,
  role: PropTypes.string.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ExecutiveOrdersList.defaultProps = {
  list: null,
  meta: {},
  setId: setComponentsId("executive-decisions"),
};

const mapStateToProps = ({
  executiveOrders: { list, vpStatuses },
  usermeta: { userMetaData },
  dictionary: { courts },
  authorization: { info: userInfo, token, role },
}) => {
  const { meta } = userMetaData || {};
  return {
    courts,
    list,
    meta,
    token,
    userInfo,
    role,
    vpStatuses,
  };
};

const ExecutiveOrders = compose(
  withStyles(styles),
  translate("ExecutiveOrders"),
  ReturnAndLocateInTableHOC,
  withRouter
)(ExecutiveOrdersList);

export default connect(mapStateToProps)(ExecutiveOrders);
