import { compose } from "redux";
import { Link } from "react-router-dom";

import React, { Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { Button, SchemaForm } from "../../../components";
import Preloader from "../../../components/Preloader";
import EDSForm from "../../../components/EDSForm";

import PdfDocument from "../../../components/PDF";

import getTemplateFilters from "../../../helpers/getTemplateFilters";
import * as statuses from "../../../variables/claimStatuses";

import TextPreview from "../../../components/TextPreview";

import EncryptClaim from "../components/EncryptClaim";
import Actions from "./Actions";
import Stepper from "./stepper";
import Signing from "./Signing";
import TemplateNotFound from "./TemplateNotFound";
import handleChange from "../decorators/handleChange";
import handleSelectKey from "../decorators/handleSelectKey";
import { updateDocument } from "../decorators/updateDocument";
import {
  handleRequestPaymentForm,
  handleUpdatePaymentInfo,
} from "../decorators/updatePayment";

import {
  handleDeleteAttach,
  handleDownloadAttach,
  handleUploadAttach,
  requestAttachPreviews,
} from "../decorators/attaches";
import redirectes from "../../../helpers/documentRedirected";
import { ClaimState } from "../types";
import { copyClaim } from "../../../actions/claim";
import { ReturnAndLocateInTableHOC } from "../../../components/ReturnAndLocateInTableHOC";
import { withUser } from "../../../components/withUser";

export function renderForm(
  stepProps,
  jsonSchema,
  stepId,
  filters,
  required,
  owner
) {
  const {
    claim,
    claim: { digitalDocumentData, state },
    errors,
    init,
    match,
  } = this.props;
  const { stepId: paramsStepID } = match.params || {};
  const { setId } = this.props;
  const value = (digitalDocumentData || {})[stepId] || {};

  if (init || paramsStepID !== stepId) return null;

  return (
    <SchemaForm
      {...stepProps}
      stepId={stepId}
      document={claim}
      readOnly={state === 4}
      errors={errors}
      value={value}
      allowEmpty={!required}
      filters={{ ...stepProps.filters, ...filters }}
      actions={{
        updateDocument,
        uploadAttach: handleUploadAttach,
        deleteAttach: handleDeleteAttach,
        downloadAttach: handleDownloadAttach,
        requestPreview: requestAttachPreviews,
        updatePaymentInfo: handleUpdatePaymentInfo,
        requestPaymentForm: handleRequestPaymentForm,
        setBusy: this.setBusy,
      }}
      onChange={handleChange}
      setId={setId}
      owner={owner}
    />
  );
}

export function transformJsonSchema(jsonSchema) {
  const props = jsonSchema.properties;
  const keysForChange = [
    "representativeApplicant",
    "lawRepresentativeClaimant",
    "representativeDefendant",
    "representativeInterestedPerson",
    "debitor",
    "prosecutor",
    "investigator",
    "applicant",
    "complainant",
    "creditor",
    "representativeComplainant",
    "claimant",
    "defendant",
    "interestedPerson",
    "representativeClaimant",
    "representativeCreditor",
    "representativeDebitor",
  ];
  for (const key in props) {
    if (keysForChange.includes(key)) {
      const newProps = {};
      const oldProps = props[key].properties || {};

      if (oldProps.edrpou) {
        newProps.edrpou = oldProps.edrpou || {};
        delete oldProps.edrpou;
      }
      if (oldProps.ipn) {
        newProps.ipn = oldProps.ipn || {};
        delete oldProps.ipn;
      }
      for (const key in oldProps) {
        newProps[key] = oldProps[key];
      }
      jsonSchema.properties[key].properties = newProps;
    }
  }

  return jsonSchema;
}

export function renderWizard() {
  const {
    t,
    classes,
    setId,
    activeStepId,
    template,
    template: { jsonSchema },
    stepOrders,
    busy,
    claim: { owner, digitalDocumentData },
    permission,
    encryptionKey,
    newSigners,
    maxStep,
  } = this.props;

  if (
    jsonSchema &&
    jsonSchema.useEncryption &&
    !encryptionKey &&
    !(digitalDocumentData && typeof digitalDocumentData === "object")
  ) {
    return (
      <EncryptClaim
        t={t}
        classes={classes}
        setId={setId}
        permission={permission}
        onDecrypt={this.handleDecryptDocument}
      />
    );
  }

  const stepId = stepOrders[activeStepId];
  const stepProps = jsonSchema.properties[stepId];
  const required = jsonSchema.required && jsonSchema.required.includes(stepId);
  const showNewSignersDialog = (newSigners || []).filter(
    ({ encodeCert }) => !!encodeCert
  ).length;
  const { subDescription, description, nextDescription, control } =
    stepProps || {};
  const footnote = nextDescription || "";

  return (
    <div>
      <Stepper
        steps={stepOrders}
        activeStepId={activeStepId}
        properties={jsonSchema.properties}
        setId={(elmentName) => setId(`stepper-${elmentName}`)}
        busy={busy}
        t={t}
        maxStep={maxStep}
      />
      <Divider id={setId("divider")} />
      <Typography
        className={classes.stepHeader}
        variant="h5"
        component="h2"
        id={setId("step-header")}
      >
        {stepProps && (subDescription || description)}
      </Typography>
      <div className={classes.schemaForm} id={setId("schema-form-wrap")}>
        {stepProps &&
          this.renderForm(
            stepProps,
            transformJsonSchema(jsonSchema),
            // jsonSchema,
            stepId,
            getTemplateFilters(template),
            required,
            owner
          )}
        {required && (
          <FormHelperText id={setId("schema-form-helper")}>
            {t("REQUIRED_FIELDS")}
          </FormHelperText>
        )}
      </div>
      {footnote && (
        <Fragment>
          <Typography component="h2" id={setId(`${control}-description`)}>
            {footnote}
          </Typography>
          <br />
        </Fragment>
      )}
      <Actions {...this.props} />
      {!!showNewSignersDialog && (
        <Dialog open={!!showNewSignersDialog}>
          <DialogTitle>{t("ADDING_NEW_ENCRYPTED_PERMISSION")}</DialogTitle>
          <DialogContent>
            <EDSForm onSelectKey={this.handleAddEncryptedPermission} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export function renderSigning() {
  const {
    t,
    userId,
    setId,
    classes,
    globalPermissions,
    itemShareList,
    claim,
    permissions,
    template,
    showSignModal,
    pdf,
    doc,
    showSelectUsers,
    usersArray,
    usersToSend,
  } = this.props;

  const { jsonSchema } = template;

  return (
    <Signing
      t={t}
      claim={claim}
      jsonSchema={jsonSchema}
      showSignModal={showSignModal}
      showSelectUsers={showSelectUsers}
      usersArray={usersArray}
      usersToSend={usersToSend}
      pdf={pdf}
      classes={classes}
      removePdf={this.removePdf}
      toggleSignModal={this.toggleSignModal}
      toggleIsSendToUser={this.toggleIsSendToUser}
      commitDocument={this.commitDocument}
      backToEditClaim={this.backToEditClaim}
      handleSendToParties={this.handleSendToParties}
      handleSelectKey={handleSelectKey}
      doc={doc}
      permissions={permissions}
      globalPermissions={globalPermissions || []}
      itemShareList={itemShareList || []}
      userId={userId}
      setId={(elmentName) => setId(`signing-${elmentName}`)}
    />
  );
}

const RenderPdf = compose(
  ReturnAndLocateInTableHOC,
  withUser
)(({ setId, pdf, doc, t, classes, claim, returnObject, isLegal }) => (
  <>
    {!!(claim && claim.caseId && claim.proceedingId) && (
      <Link
        style={{ display: "inline" }}
        to={redirectes[6].getUrl(claim)}
        className={classes.buttonLink}
        onClick={() => sessionStorage.setItem("claim_documentId", claim.id)}
      >
        <Button color="yellow" variant="contained">
          {t(`REDIRECT_TO_CASE_SHORT`)}
        </Button>
      </Link>
    )}

    {!!claim && claim.state === ClaimState.Rejected && !isLegal && (
      <Button
        style={{ display: "inline" }}
        color="yellow"
        variant="contained"
        onClick={async () => {
          try {
            const { id } = await copyClaim(claim.id);
            returnObject.removeReturnObject();
            window.location.href = redirectes[11].getUrl(id);
          } catch (e) {}
        }}
      >
        {t(`RESEND_CLAIM`)}
      </Button>
    )}

    {/* mark2250 */}
    {claim?.state === 8 && claim?.infoText && (
      <div
        style={{
          marginTop: "8px",
          marginBottom: "4px",
          color: "#ca0603",
        }}
      >
        {claim?.infoText}
      </div>
    )}

    <PdfDocument
      pdf={pdf}
      doc={doc}
      setId={(elmentName) => setId(`pdf-${elmentName}`)}
    />
  </>
));

export function renderBody() {
  const {
    claim,
    template,
    pdf,
    doc,
    setId,
    init,
    error,
    classes,
    pdfError,
    t,
  } = this.props;

  if (claim && !template && error === "TEMPLATE_NOT_FOUND" && !pdf) {
    return <TemplateNotFound classes={classes} />;
  }

  if (!claim || (!template && !pdf && !pdfError) || init) {
    return <Preloader />;
  }

  if (pdf && !template) {
    return (
      <RenderPdf
        setId={setId}
        claim={claim}
        classes={classes}
        doc={doc}
        pdf={pdf}
        t={t}
      />
    );
  }

  if (pdfError) {
    return <TextPreview text={pdfError} />;
  }

  if ((!claim || !template) && error) {
    return null;
  }

  switch (claim.state) {
    case statuses.CREATING_STATUS:
      if (pdf) return this.renderSigning();
      return this.renderWizard();
    case statuses.SIGNING_STATUS:
      return this.renderSigning();
    case statuses.SENDED_TO_PARTIES:
      return this.renderSigning();
    default:
      if (pdf) {
        return (
          <RenderPdf
            setId={setId}
            claim={claim}
            classes={classes}
            doc={doc}
            pdf={pdf}
            t={t}
          />
        );
      }
      return null;
  }
}
