import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import { compose } from "redux";
import { translate } from "react-translate";
import customInputStyle from "../../variables/styles/customInputStyle";
import { Button } from "../../components";
import { withAdvocate } from "../withAdvocate";

const ShareDeleteDialog = ({
  t,
  openDeleteDialog,
  toggleDeleteDialog,
  handleDelete,
  setId,
  isAdvocate,
  classes,
}) => (
  <Dialog
    open={openDeleteDialog}
    onClose={toggleDeleteDialog}
    aria-labelledby={setId("delete-dialog-title")}
    aria-describedby={setId("delete-dialog-content")}
    id={setId("delete-dialog")}
    className={classes.dialog}
  >
    <DialogTitle
      id={setId("delete-dialog-title")}
      className={classes.dialogContentWrappers}
    >
      {t(isAdvocate ? "DELETE_DIALOG_SHARE_ORDER" : "DELETE_DIALOG_TITLE")}
    </DialogTitle>
    <DialogContent
      id={setId("delete-dialog-content")}
      className={classes.dialogContentWrappers}
    >
      <DialogContentText id={setId("delete-dialog-description")}>
        {t("DELETE_DIALOG_TEXT")}
      </DialogContentText>
    </DialogContent>
    <DialogActions
      id={setId("delete-dialog-actions")}
      className={classes.dialogContentWrappers}
    >
      <Button
        onClick={handleDelete}
        color="danger"
        setId={(elmentName) => setId(`delete-dialog-delete-${elmentName}`)}
      >
        {t("CANCEL_PERMISSION")}
      </Button>
      <Button
        onClick={toggleDeleteDialog}
        color="yellow"
        autoFocus={true}
        setId={(elmentName) => setId(`delete-dialog-close-${elmentName}`)}
      >
        {t("CLOSE")}
      </Button>
    </DialogActions>
  </Dialog>
);

ShareDeleteDialog.propTypes = {
  openDeleteDialog: PropTypes.bool.isRequired,
  toggleDeleteDialog: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isAdvocate: PropTypes.bool,
};

export default compose(
  translate("Share"),
  withStyles(customInputStyle),
  withAdvocate
)(ShareDeleteDialog);
