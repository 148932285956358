import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import cx from "classnames";
import humanDateFormat from "../../../helpers/humanDateFormat";
import { Button } from "../../../components";
import RolesBlock from "./RolesBlock";

const findCourtName = (courtId, courts) => {
  const foundCourt = Array.isArray(courts)
    ? courts.find((item) => item.id === courtId)
    : null;
  return foundCourt ? foundCourt.name : courtId;
};

const CaseDialog = ({
  t,
  openCaseDialog,
  toggleCaseDialog,
  courts,
  memberRoles,
  currentCase: {
    number,
    createdAt,
    courtId,
    caseMembers,
    caseJudges,
    criminalProcNum,
  },
  classes,
  setId,
  display,
}) => {
  const proceedings = Array.from(
    new Set(
      caseJudges.map(
        (item) =>
          `${item.procNumber ? item.procNumber : "б/н"},${item.courtId},${
            item.proceedingId
          }`
      )
    )
  );

  return (
    <Dialog
      open={openCaseDialog}
      fullWidth
      onClose={toggleCaseDialog(display, false)}
      maxWidth="md"
      id={setId("dialog-wrapper")}
      className={classes.dialog}
    >
      <DialogTitle
        id={setId("dialog-title")}
        className={classes.dialogContentWrappers}
      >
        {t("DIALOG_TITLE", {
          number,
          date: humanDateFormat(createdAt),
        })}
      </DialogTitle>
      <DialogContent
        id={setId("dialog-content")}
        className={classes.dialogContentWrappers}
      >
        <Table id={setId("dialog-table")}>
          <TableBody id={setId("dialog-table-body")}>
            <TableRow id={setId("dialog-table-row-1")}>
              <TableCell id={setId("dialog-table-row-1-cell-1")}>
                {t("COURT")}
              </TableCell>
              <TableCell id={setId("dialog-table-row-1-cell-2")}>
                {findCourtName(courtId, courts)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("COURT_COMPOSE")}</TableCell>
              <TableCell>
                {proceedings.map((item) => {
                  const parts = item.split(",");
                  const courtId = parseInt(parts[1]);
                  const judges = caseJudges
                    .filter((item) => item.proceedingId === parts[2])
                    .sort((a, b) => a.roleId - b.roleId);
                  const roles = Array.from(
                    new Set(judges.map((item) => item.roleDescription))
                  );
                  const judgeByRoles = [];
                  roles.forEach((role) => {
                    const judgeByRole = judges.filter(
                      (item) => item.roleDescription === role
                    );
                    const judgeNames = judgeByRole.map((judge) => judge.name);
                    judgeByRoles.push(`${role}: ${judgeNames.join(", ")};`);
                  });
                  return (
                    <>
                      <div key={item} className={classes.rolesWrap}>
                        <div style={{ fontWeight: "700" }}>
                          Провадження: {parts[0]},{" "}
                          {findCourtName(courtId, courts)}
                        </div>
                      </div>
                      {judgeByRoles.map((item) => {
                        return <div>{item}</div>;
                      })}
                    </>
                  );
                })}
              </TableCell>
            </TableRow>
            {[
              {
                members: caseMembers,
                roles: memberRoles,
                title: "PARTIES_TO_DISPUTE",
                name: "parties-to-dispute",
              },
            ].map(({ members, roles, title, name }) => (
              <RolesBlock
                key={name}
                members={members}
                roles={roles}
                title={title}
                setId={setId}
                classes={classes}
                name={name}
                t={t}
              />
            ))}
            {criminalProcNum && (
              <TableRow id={setId("dialog-table-row-4")}>
                <TableCell id={setId("dialog-table-row-4-cell-1")}>
                  {t("ERDR_NUMBER_HINT")}
                </TableCell>
                <TableCell id={setId("dialog-table-row-4-cell-2")}>
                  {criminalProcNum}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
        id={setId("dialog-actions")}
      >
        <Button
          color="white"
          onClick={toggleCaseDialog(display, false)}
          id={setId("close-button")}
          setId={(elementName) => setId(`close-${elementName}`)}
        >
          {t("CLOSE")}
        </Button>
        <Button
          color="yellow"
          id={setId("documents-list-button")}
          setId={(elementName) => setId(`documents-list-${elementName}`)}
          className={classes.rightButton}
          onClick={toggleCaseDialog("documentCaseList", true, false)}
        >
          {t("DOCUMENTS_BUTTON")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CaseDialog.propTypes = {
  currentList: PropTypes.array.isRequired,
  currentCase: PropTypes.object.isRequired,
  openCaseDialog: PropTypes.bool.isRequired,
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,

  toggleCaseDialog: PropTypes.func.isRequired,

  courts: PropTypes.array.isRequired,
  memberRoles: PropTypes.array.isRequired,
  judgeRoles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default CaseDialog;
