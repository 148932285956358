import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import search from "../reducers/search";
import datafetched from "../reducers/datafetched";

import appDialogReducer from "../reducers/appDialog";
import authorization from "../reducers/authorization";
import cases from "../reducers/cases";
import claim from "../reducers/claim";
import conferences from "../reducers/conferences";
import datasource from "../reducers/datasource";
import dictionary from "../reducers/dictionary";
import eds from "../reducers/eds";
import executive from "../reducers/executive";
import executiveOrders from "../reducers/executive.orders";
import inputdocs from "../reducers/inputdocs";
import judge from "../reducers/judge";
import legalEntityDataReducer from "./legal-entity-data";
import notifications from "../reducers/notifications";
import payment from "../reducers/payment";
import permissions from "../reducers/permissions";
import personalDataReducer from "./personal-data";
import proceeding from "../reducers/proceeding";
import stateRegisters from "./stateRegisters";
import usermeta from "../reducers/usermeta";

export default combineReducers({
  appDialog: appDialogReducer,
  authorization,
  cases,
  claim,
  conferences,
  datafetched,
  datasource,
  dictionary,
  eds,
  executive,
  executiveOrders,
  inputdocs,
  judge,
  legalEntityData: legalEntityDataReducer,
  notifications,
  payment,
  permissions,
  personalData: personalDataReducer,
  proceeding,
  router: routerReducer,
  search,
  stateRegisters,
  usermeta,
});
