import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@material-ui/core";

const getRoleName = (t, share, permission) => {
  const { createdBy, allowedBy } = share;
  let roleName = "";
  if (permission === "from") {
    roleName = t("CHANGE_PERMISSION_FROM");
  } else if (permission === "to" && createdBy === allowedBy) {
    roleName = t("CHANGE_PERMISSION_TO");
  } else if (permission === "to") {
    roleName = t("CHANGE_PERMISSION_RESHARED");
  }
  return roleName;
};

export const renderUserBlock = (
  changedShare,
  name,
  permission,
  t,
  classes,
  setId,
  allowedBy = ""
) => (
  <section className={classes.userBlock} id={setId(`user-block-${permission}`)}>
    <div
      className={classes.userIcon}
      id={setId(`user-block-${permission}-icon`)}
    >
      <Icon>account_circle</Icon>
    </div>
    <div
      className={classes.userContent}
      id={setId(`user-block-${permission}-content`)}
    >
      <span
        className={classes.userName}
        id={setId(`user-block-${permission}-name`)}
      >
        {name}
      </span>
      <span
        className={classes.userPermission}
        id={setId(`user-block-${permission}-permission`)}
      >
        {getRoleName(t, changedShare, permission)}
        &nbsp;
        {allowedBy}
      </span>
    </div>
  </section>
);

const ShareDialogFromTo = ({ changedShare, t, classes, setId }) => (
  <section className={classes.changeTitle} id={setId("change-title")}>
    {renderUserBlock(
      changedShare,
      changedShare.allowedByName,
      "from",
      t,
      classes,
      setId
    )}
    <section className={classes.userBlock} id={setId("change-arrow-wrap")}>
      <Icon className={classes.arrow} id={setId("change-arrow")}>
        arrow_right_alt
      </Icon>
    </section>
    {renderUserBlock(
      changedShare,
      changedShare.userName,
      "to",
      t,
      classes,
      setId
    )}
  </section>
);

ShareDialogFromTo.propTypes = {
  changedShare: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ShareDialogFromTo;
