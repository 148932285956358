import React from "react";
import PropTypes from "prop-types";
import { FormControl, Typography } from "@material-ui/core";

import { Preloader } from "../../../../components";
import Success from "./Success";
import Toggle from "./Toggle";
import Benefites from "./Benefites";
import PaymentForm from "./PaymentForm";

const PaymentLayout = ({
  classes,
  dictionary,
  t,
  filters,
  value,
  actions,
  error,

  useBenefits,
  openUploadModal,
  busy,

  setId,
  transaction,

  toggleMode,
  onOpenUploadModal,
  handleChangeBenefits,
  handleDelete,
  handleUpload,
  closeUploadModal,
  toggleUseUserAmount,
  handleChangeUserAmount,
  handleOpenPayWindow,
  otherAmount,
  newSum,
  noCaseError,
  document: doc,
  form,
}) => (
  <FormControl className={classes.formControl} id={setId("")}>
    {transaction && (
      <Success
        t={t}
        classes={classes}
        transaction={transaction}
        setId={(elementName) => setId(`success-${elementName}`)}
      />
    )}
    {!transaction && (
      <Toggle
        t={t}
        classes={classes}
        useBenefits={useBenefits}
        setId={(elementName) => setId(`toggle-${elementName}`)}
        toggleMode={toggleMode}
      />
    )}
    {(!dictionary || !dictionary.partTypes) && <Preloader />}
    {!transaction && useBenefits && dictionary && dictionary.benefitTypes && (
      <Benefites
        t={t}
        classes={classes}
        dictionary={dictionary}
        filters={filters}
        value={value}
        actions={actions}
        onOpenUploadModal={onOpenUploadModal}
        openUploadModal={openUploadModal}
        setId={(elementName) => setId(`benefites-${elementName}`)}
        handleChangeBenefits={handleChangeBenefits}
        handleDelete={handleDelete}
        handleUpload={handleUpload}
        closeUploadModal={closeUploadModal}
        doc={doc}
      />
    )}
    {!transaction && !useBenefits && dictionary && dictionary.partTypes && (
      <PaymentForm
        t={t}
        classes={classes}
        dictionary={dictionary}
        value={value}
        busy={busy}
        form={form}
        setId={(elementName) => setId(`form-${elementName}`)}
        toggleUseUserAmount={toggleUseUserAmount}
        handleChangeUserAmount={handleChangeUserAmount}
        handleOpenPayWindow={handleOpenPayWindow}
        otherAmount={otherAmount}
        newSum={newSum}
        noCaseError={noCaseError || error}
      />
    )}
    <Typography
      component="h2"
      id={setId("error")}
      className={error || noCaseError ? classes.error : ""}
    >
      {error && t(error)}
      {!useBenefits && !error && noCaseError}
      {!useBenefits &&
        !error &&
        !noCaseError &&
        (busy || !form) &&
        t("CREATED_FORM")}
    </Typography>
  </FormControl>
);

PaymentLayout.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object,
  setId: PropTypes.func.isRequired,
  useBenefits: PropTypes.bool,
  toggleMode: PropTypes.func.isRequired,
  noCaseError: PropTypes.string.isRequired,

  dictionary: PropTypes.object,
  filters: PropTypes.object,
  value: PropTypes.object,
  actions: PropTypes.object.isRequired,
  openUploadModal: PropTypes.bool.isRequired,

  onOpenUploadModal: PropTypes.func.isRequired,
  handleChangeBenefits: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  closeUploadModal: PropTypes.func.isRequired,

  busy: PropTypes.bool.isRequired,
  toggleUseUserAmount: PropTypes.func.isRequired,
  handleChangeUserAmount: PropTypes.func.isRequired,
  handleOpenPayWindow: PropTypes.func.isRequired,
  otherAmount: PropTypes.bool.isRequired,
  newSum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  document: PropTypes.object.isRequired,
  form: PropTypes.object,
  error: PropTypes.string.isRequired,
};

PaymentLayout.defaultProps = {
  filters: {},
  value: {},
  useBenefits: false,
  transaction: null,
  form: null,
  dictionary: null,
};

export default PaymentLayout;
