export const getRoleForArrStr = (array, t) => {
  const roles = array.map((item, index) => {
    return getRole(item, t, index === array?.length - 1);
  });
  const shortRoles = getRole(array?.[0], t, false) + "...";
  const titleRoles = roles.join(", ");

  return (
    <div
      style={{
        maxWidth: "80px",
        textWrap: "pretty",
        overflow: "hidden",
        textOverflow: "clip",
      }}
      title={titleRoles}
    >
      {shortRoles}
    </div>
  );
};

const getRole = (execMyRole, t, isLast) => {
  if (execMyRole === "debtor") return t("DEBTOR");
  if (execMyRole === "collector") return t("COLLECTOR");
  if (execMyRole === "payer") return t("PAYER");
  if (execMyRole === "debtorRepresentative")
    return `${t("REPRESENTATIVE")} ${t("DEBTOR")}a`;
  if (execMyRole === "collectorRepresentative")
    return `${t("REPRESENTATIVE")} ${t("COLLECTOR")}a`;
  if (execMyRole === "payerRepresentative")
    return `${t("REPRESENTATIVE")} ${t("PAYER")}a`;
  return "";
};
