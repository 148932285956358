import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core";
import renderHTML from "react-render-html";
import styles from "../../variables/styles/claimList";

import TemplateItem from "./TemplateItem";
import TemplatesDialog from "./TemplatesDialog";
import TemplatesCategory from "./TemplatesCategory";
import TemplatesSubCategory from "./TemplatesSubCategory";

import UNSAFE_componentWillMount, {
  setTemplates,
  propTypes,
  defaultProps,
  initialState,
} from "./init";

class CreateClaimDialog extends Component {
  state = { ...initialState };

  UNSAFE_componentWillMount = UNSAFE_componentWillMount.bind(this);
  UNSAFE_componentWillReceiveProps = setTemplates.bind(this);
  handleSearch = ({ target: { value } }) =>
    this.setState({ searchText: value });
  clearSearch = () => this.setState({ searchText: "" });

  toggleGroup = (id, open, groupIndex) => () => {
    const { openGroups } = this.state;
    if (open) {
      openGroups.splice(groupIndex, 1);
    } else {
      openGroups.push(id);
    }
    this.setState({ openGroups });
  };

  getFilteredTemplate = (templates) => {
    const { classes } = this.props;
    const { searchText } = this.state;
    if (searchText) {
      return templates.reduce((acc, template) => {
        const { name } = template;
        const foundIndex = name.toUpperCase().indexOf(searchText.toUpperCase());
        if (foundIndex >= 0) {
          const start = name.substring(0, foundIndex);
          const searchable = name.substring(
            foundIndex,
            foundIndex + searchText.length
          );
          const end = name.substring(
            foundIndex + searchText.length,
            name.length
          );
          const text = `${start}<span class="${classes.found}">${searchable}</span>${end}`;
          // const arr = template.name.split(searchText);
          // const text = arr.join(`<span class="${classes.found}">${searchText}</span>`);
          acc.push({ ...template, text: renderHTML(text) });
        }
        return acc;
      }, []);
    }
    return templates;
  };

  sortSubCat = (parentCat, allCategories) => {
    const filterCat = allCategories.filter(
      (cat) => cat.parentCategoryId === parentCat.categoryId
    );
    if (filterCat.length > 0) {
      parentCat.childCat = filterCat.map((cat) =>
        this.sortSubCat(cat, allCategories)
      );
    }
    return parentCat;
  };

  sortCat = (categories) => {
    const firstLevelCaterories = categories.filter(
      (cat) => cat.parentCategoryId === null
    );
    return firstLevelCaterories.map((cat) => this.sortSubCat(cat, categories));
  };

  renderItem = (template, catId) => {
    const { handleClaimCreate, t, ownership, classes } = this.props;
    const { searchText } = this.state;
    const { isAllowRepresenter, categoryId } = template;
    if (catId && categoryId !== catId) {
      return null;
    }
    const isOwner =
      !ownership || (!!ownership.claimant && !ownership.representativeClaimant);
    const disabledRepresenter = !isOwner && isAllowRepresenter === 0;
    return (
      <TemplateItem
        key={`${template.id}-template`}
        template={template}
        handleClaimCreate={handleClaimCreate}
        searchText={searchText}
        disabledRepresenter={disabledRepresenter}
        t={t}
        classes={classes}
      />
    );
  };

  renderSubcat = (cat, templates) => {
    const { classes } = this.props;
    const { searchText, openGroups } = this.state;
    let children = templates.map((template) =>
      this.renderItem(template, cat.categoryId)
    );
    const groupIndex = openGroups.findIndex(
      (groupId) => groupId === cat.categoryId
    );
    const open = groupIndex >= 0 || searchText.length > 0;
    if (cat.childCat && cat.childCat.length > 0) {
      children = cat.childCat
        .map((childCat) => this.renderSubcat(childCat, templates))
        .concat(children);
    }
    children = children.filter((item) => item !== null);
    if (!children.length) {
      return null;
    }
    return (
      <TemplatesSubCategory
        key={cat.categoryId}
        cat={cat}
        classes={classes}
        open={open}
        groupIndex={groupIndex}
        searchText={searchText}
        toggleGroup={this.toggleGroup}
      >
        {children}
      </TemplatesSubCategory>
    );
  };

  sortTemlate = () => {
    const {
      classes,
      handleClaimCreate,
      categories,
      setId: propSetId,
      templatePermissionFilter,
    } = this.props;
    const { searchText, openGroups } = this.state;
    let { unCatTemplates, templates } = this.state;

    // Filter by permission to justice, define in changeOwnership
    // of home/rynda/src/cabinet-front/src/pages/Claim/index.jsx
    if (
      Array.isArray(templatePermissionFilter) &&
      templatePermissionFilter.length > 0
    ) {
      const justiceIds = templatePermissionFilter
        .filter((value) => value.addClaimPermission)
        .map((value) => parseInt(value.targetId));
      templates = (templates || []).filter((value) =>
        justiceIds.includes(value.docTypeCourts[0].jurisdictionType)
      );
      unCatTemplates = (unCatTemplates || []).filter((value) =>
        justiceIds.includes(value.docTypeCourts[0].jurisdictionType)
      );
    }

    const setId = (elmentName) => propSetId(`content-${elmentName}`);
    if (searchText && searchText.length > 0) {
      templates = this.getFilteredTemplate(templates);
      unCatTemplates = this.getFilteredTemplate(unCatTemplates);
    }
    const sortingCat = this.sortCat(categories);
    const renderUnCatTemplate = unCatTemplates.map((template) =>
      this.renderItem(template, null)
    );
    const renderCat = sortingCat.reduce((acc, cat, index) => {
      const groupIndex = openGroups.findIndex(
        (groupId) => groupId === cat.categoryId
      );
      const open = groupIndex >= 0 || searchText.length > 0;
      const childCat =
        cat.childCat && cat.childCat.length > 0
          ? cat.childCat.map((item) => this.renderSubcat(item, templates))
          : [];
      const children = childCat
        .concat(
          templates.map((template) =>
            this.renderItem(template, cat.categoryId, handleClaimCreate)
          )
        )
        .filter((item) => item !== null);

      if (children.length > 0) {
        const element = (
          <TemplatesCategory
            key={cat.categoryId}
            cat={cat}
            classes={classes}
            open={open}
            groupIndex={groupIndex}
            index={index}
            setId={setId}
            searchText={searchText}
            toggleGroup={this.toggleGroup}
          >
            {children}
          </TemplatesCategory>
        );
        acc.push(element);
      }
      return acc;
    }, []);
    return renderCat.concat(renderUnCatTemplate);
  };

  render() {
    const {
      t,
      templates,
      classes,
      openCreateClaimDialog,
      toggleTemplateDialog,
      categories,
      setId,
      docCategory,
    } = this.props;
    if (!templates || !categories) {
      return null;
    }
    const { searchText, count } = this.state;

    return (
      <TemplatesDialog
        openCreateClaimDialog={openCreateClaimDialog}
        t={t}
        classes={classes}
        toggleTemplateDialog={toggleTemplateDialog}
        setId={setId}
        searchText={searchText}
        count={count}
        handleSearch={this.handleSearch}
        docCategory={docCategory}
      >
        {this.sortTemlate()}
      </TemplatesDialog>
    );
  }
}

CreateClaimDialog.propTypes = propTypes;
CreateClaimDialog.defaultProps = defaultProps;

const mapStateToProps = ({
  claim: { templates, categories },
  dictionary: { courts, jurisdiction_types: jurisdictionTypes },
}) => ({
  templates,
  categories,
  courts,
  jurisdictionTypes,
});

const styled = withStyles(styles)(CreateClaimDialog);
const translated = translate("ClaimList")(styled);

export default connect(mapStateToProps)(translated);
