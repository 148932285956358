import React from "react";
import { Table } from "../../../components";
import { tableFields } from "./tableFields";
import { DataSourceClass } from "../../../helpers/dataSource";
import { DeviceInfo } from "./types";

type TFun = (s: string, args?: Record<string, any>) => string;

export interface DocumentProcTableProps {
  t: TFun;
  setId: (s: string) => string;
  list: DeviceInfo[];
  dataSource: DataSourceClass;
  createSortHandler: () => void;
  pagination: () => void;
  changeCount: () => void;
}

export const getText = (item: DeviceInfo, key: string) => {
  const { deviceDescription } = item;

  if (key === "appName") {
    return item[key];
  }
  // @ts-ignore
  return deviceDescription && deviceDescription[key];
};

const DevicesTable = ({
  t,
  list,
  dataSource,
  createSortHandler,
  setId,
  pagination,
  changeCount,
}: DocumentProcTableProps) => {
  return (
    <Table
      fields={tableFields}
      getText={getText}
      setId={setId}
      dataSource={dataSource}
      createSortHandler={createSortHandler}
      pagination={pagination}
      changeCount={changeCount}
      list={list}
      onCheckItem={() => null}
      t={t}
      usePagination={false}
    />
  );
};

export default DevicesTable;
