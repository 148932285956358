import * as api from "../services/api";
import store from "../store";
import {
  ADD_ACTIVE_CLAIM,
  ADD_CLAIM_PERMISSIONS,
  ADD_ACTIVE_TEMPLATE,
  CLEAR_ACTIVE_CLAIM,
  SET_WIZARD_STATES,
  CLEAR_STATES,
} from "../reducers/claim";

const { dispatch } = store;

export const SEND_PARTIES = "SEND_PARTIES";
export const SIGN_DOCUMENT = "SIGN_DOCUMENT";
export const REQUEST_SIGN_DATA = "REQUEST_SIGN_DATA";
export const CLAIM_CREATED = "CLAIM_CREATED";
export const COMMIT_DOCUMENT = "COMMIT_DOCUMENT";
export const GENERATE_PDF = "GENERATE_PDF";
export const REQUEST_PDF = "REQUEST_PDF";
export const REQUEST_COPY_CLAIM = "REQUEST_COPY_CLAIM";
export const REQUEST_CLAIM = "REQUEST_CLAIM";
export const DELETE_CLAIM = "DELETE_CLAIM";
export const PERMANENT_DELETE_CLAIM = "PERMANENT_DELETE_CLAIM";
export const RECOVER_CLAIM = "RECOVER_CLAIM";
export const REQUEST_CLAIM_LIST = "REQUEST_CLAIM_LIST";
export const STORE_CLAIM_VALUES = "STORE_CLAIM_VALUES";
export const STORE_DECRYPTED_CLAIM_VALUES = "STORE_DECRYPTED_CLAIM_VALUES";
export const UPLOAD_DOCUMENT_ATTACH = "UPLOAD_DOCUMENT_ATTACH";
export const DELETE_DOCUMENT_ATTACH = "DELETE_DOCUMENT_ATTACH";
export const DOWNLOAD_DOCUMENT_ATTACH = "DOWNLOAD_DOCUMENT_ATTACH";
export const REQUEST_ATTACH_PREVIEW = "REQUEST_ATTACH_PREVIEW";
export const REQUEST_CLAIM_CREATE = "REQUEST_CLAIM_CREATE";
export const REQUEST_CLAIM_TEMPLATE = "REQUEST_CLAIM_TEMPLATE";
export const REQUEST_CLAIM_TEMPLATE_LIST = "REQUEST_CLAIM_TEMPLATE_LIST";
export const REQUEST_CLAIM_TEMPLATE_CATEGORIES =
  "REQUEST_CLAIM_TEMPLATE_CATEGORIES";
export const REQUEST_APPEAL_COURT = "REQUEST_APPEAL_COURT";
export const GET_EXECUTIVE_COUNT = "GET_EXECUTIVE_COUNT";
export const GET_APPROVAL_COUNT = "GET_APPROVAL_COUNT";
export const ADD_ENCODE_KEY = "ADD_ENCODE_KEY";

export const UPDATE_CLAIM_COURT = "UPDATE_CLAIM_COURT";
export const BACK_TO_EDIT_CLAIM = "BACK_TO_EDIT_CLAIM";

const DOCUMENTS_URL = "api/documents";
const TEMPLATES_URL = "api/templates";
const COURTS_URL = "api/courts";

const getAttachIdNotFoundError = (docId, attachId, attach) => {
  const {
    datafetched: { history },
    claim: { activeTemplate },
  } = store.getState() || { datafetched: {} };
  const error = new Error("ERROR: file id not found");
  error.cause = "Not found attachId";
  error.documentId = docId;
  error.attachId = attachId;
  error.attachLink = attach.link;
  error.template = activeTemplate;
  error.attach = attach;
  if (history && !!history.length) {
    history.forEach(({ type, method, payload, url, body }, i) => {
      if (
        (type || "").includes("SUCCESS") &&
        (url || "").includes(docId) &&
        payload.attaches &&
        payload.attaches.length
      ) {
        error[`request-${i}-url`] = url;
        error[`request-${i}-method`] = method;
        error[`request-${i}-payload`] = payload;
        error[`request-${i}-body`] = body;
        if (payload.attaches) {
          payload.attaches.forEach((item, index) => {
            error[`request-${i}-payload-attaches${index}`] = item;
          });
        }
        if (
          payload.digitalDocumentData.attaches &&
          Array.isArray(payload.digitalDocumentData.attaches)
        ) {
          payload.digitalDocumentData.attaches.forEach((item, index) => {
            error[`request-${i}-payload-digitalDocumentData-attaches${index}`] =
              item;
          });
        }
        if (
          payload.digitalDocumentData.attaches &&
          !Array.isArray(payload.digitalDocumentData.attaches)
        ) {
          error[`request-${i}-payload-digitalDocumentData-attaches`] =
            payload.digitalDocumentData.attaches;
        }
      }
    });
  }
  return error;
};

export function addEncodeKey(key, claimId) {
  return dispatch({
    type: ADD_ENCODE_KEY,
    payload: {
      key,
      claimId,
    },
  });
}

export async function addActiveClaim(claim) {
  return dispatch({
    type: ADD_ACTIVE_CLAIM,
    payload: claim,
  });
}

export function clearActiveClaim() {
  return dispatch({ type: CLEAR_ACTIVE_CLAIM });
}

export function addActiveTemplate(claimId, template) {
  return dispatch({
    type: ADD_ACTIVE_TEMPLATE,
    payload: {
      claimId,
      template,
    },
  });
}

export function addClaimPermissions(claimId, permissions) {
  return dispatch({
    type: ADD_CLAIM_PERMISSIONS,
    payload: {
      claimId,
      list: permissions,
    },
  });
}

export function requestClaimList(filter = "") {
  return api.get(`${DOCUMENTS_URL}/my${filter}`, REQUEST_CLAIM_LIST, dispatch);
}

export async function signDocument(id, signature) {
  return await api.post(
    `${DOCUMENTS_URL}/${id}/sign`,
    { signature },
    SIGN_DOCUMENT,
    dispatch
  );
}

export async function requestSignData(id) {
  return await api.get(
    `${DOCUMENTS_URL}/${id}/sign`,
    REQUEST_SIGN_DATA,
    dispatch
  );
}

export function commitDocument(id, data) {
  if (!id) {
    const error = new Error("ERROR: document id not found");
    error.cause = "Not found attachId";
    error.documentId = id;
    return new Promise((resolve, reject) => reject(error));
  }
  return api.post(
    `${DOCUMENTS_URL}/${id}/commit`,
    data,
    COMMIT_DOCUMENT,
    dispatch
  );
}

export async function sendParties(id, data) {
  return await api.post(
    `${DOCUMENTS_URL}/${id}/send-parties`,
    data,
    SEND_PARTIES,
    dispatch
  );
}

export async function updateClaimToDraft(id) {
  return await api.put(
    `${DOCUMENTS_URL}/${id}/draft`,
    {},
    BACK_TO_EDIT_CLAIM,
    dispatch
  );
}

export async function generatePdf(id, data = {}) {
  const result = await api.post(
    `${DOCUMENTS_URL}/${id}/pdf`,
    data,
    GENERATE_PDF,
    dispatch
  );

  return result;
}

export async function requestPdf(id) {
  return await api.get(
    `${DOCUMENTS_URL}/${id}/static_file`,
    REQUEST_PDF,
    dispatch
  );
}

export function copyClaim(id) {
  return api.post(
    `${DOCUMENTS_URL}/${id}/claim-copy`,
    {},
    REQUEST_COPY_CLAIM,
    dispatch
  );
}

export async function uploadDocumentAttach(id, file) {
  return await api.upload(
    `${DOCUMENTS_URL}/${id}/attaches`,
    file,
    {
      file_name: file.name || file.fileName,
      content_type: file.type || file.contentType,
      user_file_name: file.userFileName || file.name,
      name: file.userFileName || file.name,
    },
    UPLOAD_DOCUMENT_ATTACH,
    dispatch
  );
}

export function deleteDocumentAttach(id, attachId) {
  return api.del(
    `${DOCUMENTS_URL}/${id}/attaches/${attachId}`,
    DELETE_DOCUMENT_ATTACH,
    dispatch
  );
}

export function downloadDocumentAttach(id, attachId, attach) {
  if (!attachId) {
    const error = getAttachIdNotFoundError(id, attachId, attach);
    return new Promise((resolve, reject) => reject(error));
  }
  return api.get(
    `${DOCUMENTS_URL}/${id}/attaches/${attachId}`,
    DOWNLOAD_DOCUMENT_ATTACH,
    dispatch
  );
}

export function createClaim(template, data) {
  return api
    .post(
      `${TEMPLATES_URL}/${template}/start`,
      data,
      REQUEST_CLAIM_CREATE,
      dispatch
    )
    .then((claim) => {
      claim = {
        ...claim,
        digitalDocumentTemplateId: template,
      };
      if (!claim.message) {
        dispatch({
          type: CLAIM_CREATED,
          payload: claim,
        });
      }
      return claim;
    });
}

export function updateDecryptedData(claimId, documentData) {
  return api.put(
    `${DOCUMENTS_URL}/${claimId}/decrypted`,
    { documentData },
    STORE_DECRYPTED_CLAIM_VALUES,
    dispatch
  );
}

export function storeClaimValue(claimId, data) {
  return api.put(
    `${DOCUMENTS_URL}/${claimId}`,
    data,
    STORE_CLAIM_VALUES,
    dispatch
  );
}

export function requestClaim(claimId) {
  return api.get(`${DOCUMENTS_URL}/${claimId}`, REQUEST_CLAIM, dispatch);
}

export function deleteClaim(claimId) {
  return api.del(`${DOCUMENTS_URL}/${claimId}`, DELETE_CLAIM, dispatch);
}

export function permanentDeleteClaim(claimId) {
  return api.del(
    `${DOCUMENTS_URL}/${claimId}/permanent`,
    PERMANENT_DELETE_CLAIM,
    dispatch
  );
}

export function recoverClaim(claimId) {
  return api.post(
    `${DOCUMENTS_URL}/${claimId}/recover`,
    {},
    RECOVER_CLAIM,
    dispatch
  );
}

export function requestClaimTemplates() {
  return api.get(TEMPLATES_URL, REQUEST_CLAIM_TEMPLATE_LIST, dispatch);
}

export function requestClaimTemplatesCat() {
  return api.get(
    `${TEMPLATES_URL}/categories`,
    REQUEST_CLAIM_TEMPLATE_CATEGORIES,
    dispatch
  );
}

export function requestTemplate(templateId) {
  return api
    .get(`${TEMPLATES_URL}/${templateId}`, REQUEST_CLAIM_TEMPLATE, dispatch)
    .then((result) => {
      if (!result || result.message) {
        return result;
      }
      const { jsonSchema } = result;
      const schema = JSON.parse(jsonSchema);

      try {
        result = {
          ...result,
          jsonSchema: schema,
        };
      } catch (e) {
        result = {
          ...result,
          jsonSchema: {},
        };
      }
      return result;
    });
}

export function requestAttachPreview(claimId, attachId, attach) {
  if (!attachId) {
    const error = new Error("ERROR: file id not found");
    error.cause = "Not found attachId";
    error.documentId = claimId;
    error.attachId = attachId;
    if (attach) {
      error.attach = attach;
    }
    return new Promise((resolve, reject) => reject(error));
  }
  return api.get(
    `${DOCUMENTS_URL}/${claimId}/attaches/${attachId}/preview`,
    REQUEST_ATTACH_PREVIEW,
    dispatch
  );
}

export function requestAppealCourt(procId) {
  const result = api.get(
    `${COURTS_URL}/appeal_court?proceeding_id=${procId}`,
    REQUEST_APPEAL_COURT,
    dispatch
  );
  if (result instanceof Error) throw result;
  return result;
}

export const updateClaimCourt = (claimId, courtId) => (dispatcher) =>
  api.put(
    `${DOCUMENTS_URL}/${claimId}/court`,
    { courtId },
    UPDATE_CLAIM_COURT,
    dispatcher
  );

export const updateDocumentCourt = (claimId, courtId) =>
  api.put(
    `${DOCUMENTS_URL}/${claimId}/court`,
    { courtId },
    UPDATE_CLAIM_COURT,
    dispatch
  );

export function setWizardStates(states) {
  return dispatch({
    type: SET_WIZARD_STATES,
    payload: states,
  });
}

export function clearWizardStates() {
  return dispatch({ type: CLEAR_STATES });
}

export function getExecutiveCount() {
  return api.get(
    `${DOCUMENTS_URL}/my/count?filter%5Bcategory%5D=4`,
    GET_EXECUTIVE_COUNT,
    dispatch
  );
}

export function getApprovalCount() {
  return api.get(
    `${DOCUMENTS_URL}/for_sign/count?filter%5Bstate%5D=2`,
    GET_APPROVAL_COUNT,
    dispatch
  );
}
