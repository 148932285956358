import PropTypes from "prop-types";
import { Button } from "../../../components";

type Props = {
  t: (s: string) => string;
  setId: (s: string) => string;
  toggleDialog: (param: string) => void;
  classes: any;
  disabled: boolean;
};

const CreateEDRRecipeButton = ({
  t,
  classes,
  toggleDialog,
  setId,
  disabled,
}: Props) => {
  console.log("disabled: ", disabled);
  return (
    <>
      <Button
        color="yellow"
        onClick={toggleDialog("createEDRRecipe")}
        id={setId("toolbar-add-EDRRecipe-button")}
        setId={(elmentName: string) => setId(`toolbar-add-${elmentName}`)}
        className={classes.mobileButtonFullWidth}
        disabled={disabled}
      >
        {t("ADD_EDRRecipe")}
      </Button>
    </>
  );
};

CreateEDRRecipeButton.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

export default CreateEDRRecipeButton;
