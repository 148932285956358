import { IconButton } from "@material-ui/core";
import { OpenInNew, SaveAlt } from "@material-ui/icons";
import KeyPng from "../../../assets/img/key.svg";

export const ExecutiveAdditionalButtons = ({
  classes,
  t,
  documentInfo,
  checkKey,
  download,
  newPageUrl,
}) => {
  return (
    <div className={classes.buttons}>
      <IconButton
        color="inherit"
        onClick={checkKey}
        title={t("CHECK_ECP")}
        className={classes.menuButton}
        disabled={!documentInfo}
      >
        <img
          src={KeyPng}
          style={{ opacity: !documentInfo ? "0.25" : "1" }}
          alt={t("CHECK_ECP")}
          width={24}
          height={24}
        />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={download}
        title={t("SAVE_TO_DEVICE")}
        className={classes.menuButton}
        disabled={!documentInfo}
      >
        <SaveAlt />
      </IconButton>

      <IconButton
        color="inherit"
        className={classes.menuButton}
        onClick={() => window.open(newPageUrl, "_blank")}
        title={t("PREVIEW_LINK")}
        disabled={!documentInfo}
      >
        <OpenInNew />
      </IconButton>
    </div>
  );
};
