import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { translate } from "react-translate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  withStyles,
} from "@material-ui/core";

import React from "react";

// import JoditEditorWrapper from "./jodit-editor/JoditEditorWrapper";
// import QuillEditorWrapper from "./quill-editor/QuillEditorWrapper";

import FormHelperText from "@material-ui/core/FormHelperText";
import { Button } from "../../../components";
import styles from "../../../variables/styles/textArea";
import renderHTML from "react-render-html";

/* ... ... ... ... ... ... ... ... ... ... ... ... ... ...*/
/// old quill...
import ReactQuill, { Quill } from "react-quill";
import { quillFormats, quillModules } from "../../../variables/quillSettings";
import "../../../assets/css/quill.theme.css";
import { IndentStyle } from "./IndentStyle";

const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
Quill.register(IndentStyle, true);
/* ... ... ... ... ... ... ... ... ... ... ... ... ... ...*/

class Textarea extends React.Component {
  state = {
    showSample: false,
    value: this.props.value,
    selectedText: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, readOnly } = nextProps;
    if (readOnly && value !== this.state.value) {
      this.setState({ value });
    }
  }

  selectedText = () => {
    let range = {};
    let selectedText = "";
    if (window.getSelection) {
      range = window.getSelection();
    } else if (document.getSelection) {
      range = document.getSelection();
    } else if (document.selection) {
      range = document.selection.createRange().text;
    }
    if (range) {
      if (range.getRangeAt(0) && range.getRangeAt(0).cloneContents()) {
        const fragment = range.getRangeAt(0).cloneContents();
        const div = document.createElement("div");
        div.appendChild(fragment.cloneNode(true));
        selectedText = div.innerHTML;
      } else {
        selectedText = "" + range;
      }
    }
    this.setState({ selectedText });
  };

  toggleSampleDialog = () =>
    this.setState({ showSample: !this.state.showSample });

  handleTextChange = (content) => {
    const { onChange } = this.props;
    this.setState({ value: content }, () => onChange && onChange(content));
  };

  copySelected = () =>
    this.setState({
      value: `${this.props.value}${this.state.selectedText}`,
    });

  copyAll = () => {
    this.setState({ value: `${this.props.value}${this.props.hint}` });
  };

  render() {
    const {
      path,
      t,
      formControlProps,
      classes,
      sample,
      hint,
      errors,
      readOnly,
      setId,
    } = this.props;
    const { showSample, value, selectedText } = this.state;
    const id = path.replace(/\./g, "-");

    return (
      <FormControl
        {...formControlProps}
        error={!!errors}
        id={setId(id)}
        className={classes.wrap}
      >
        <div className={classes.sampleText} id={setId(`${id}-text`)}>
          {sample}
          &nbsp;
          {hint && (
            <span
              className={classes.link}
              onClick={this.toggleSampleDialog}
              id={setId(`${id}-open-dialog-button`)}
            >
              {t("SHOW_SAMPLE_DIALOG")}
            </span>
          )}
          {hint && (
            <Dialog
              open={showSample}
              onClose={this.toggleSampleDialog}
              aria-labelledby={setId("dialog-title")}
              aria-describedby={setId("dialog-content")}
              id={setId(`${id}-dialog`)}
              className={classes.dialog}
            >
              <DialogTitle
                className={classes.dialogContentWrappers}
                id={setId("dialog-title")}
              >
                {t("SAMPLE_EXPAND")}
              </DialogTitle>
              <DialogContent
                className={classes.dialogContentWrappers}
                id={setId("dialog-content")}
              >
                <div id={`${id}-hint`} onMouseUp={this.selectedText}>
                  {renderHTML(hint)}
                </div>
              </DialogContent>
              <DialogActions
                className={classes.dialogContentWrappers}
                id={setId(`${id}-dialog-actions`)}
              >
                {!selectedText && (
                  <Button
                    color="transparent"
                    onClick={this.copyAll}
                    id={setId(`${id}-copy-all-button`)}
                  >
                    {t("COPY_ALL")}
                  </Button>
                )}
                {selectedText && (
                  <Button
                    color="transparent"
                    onClick={this.copySelected}
                    id={setId(`${id}-copy-selected-button`)}
                  >
                    {t("COPY_SELECTED")}
                  </Button>
                )}
                <Button
                  color="yellow"
                  onClick={this.toggleSampleDialog}
                  id={setId(`${id}-close-button`)}
                >
                  {t("CLOSE")}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
        {readOnly && renderHTML(value)}

        {/* ... ... ... ... ... ... ... ... ... ... ... ... ... ...*/}
        {/* old quill editor */}
        {!readOnly && (
          <ReactQuill
            modules={quillModules}
            formats={quillFormats}
            value={value || ""}
            style={null}
            className={[classes.quill, errors && classes.quillErrored]
              .filter(Boolean)
              .join(" ")}
            onChange={this.handleTextChange}
            id={id}
          />
        )}
        {/* ... ... ... ... ... ... ... ... ... ... ... ... ... ...*/}

        {/* {!readOnly && (
          <JoditEditorWrapper
            value={value || ""}
            onChange={this.handleTextChange}
          />
          // <QuillEditorWrapper
          //   value={value}
          //   onChange={this.handleTextChange}
          //   className={[classes.quill, errors && classes.quillErrored]
          //     .filter(Boolean)
          //     .join(" ")}
          //   id
          // />
        )} */}
        {errors && (
          <FormHelperText id={`${id}-error`} className={classes.hint}>
            {errors}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

Textarea.propTypes = {
  onChange: PropTypes.func,
  sample: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formControlProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  readOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  hint: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  path: PropTypes.string.isRequired,
};

Textarea.defaultProps = {
  onChange: undefined,
  sample: "",
  formControlProps: {},
  errors: null,
  readOnly: false,
  setId: setComponentsId("schemaform-textarea"),
  value: "",
  hint: "",
};

const styled = withStyles(styles)(Textarea);
export default translate("Elements")(styled);
