import { Chip, withStyles } from "@material-ui/core";
import cx from "classnames";
import React from "react";
import { ChipsItem } from "./types";

type Props = {
  items: ChipsItem[];
  classes: any;
  value: any;
  onChanged: (value: any) => void;
  t: (s: string) => string;
};

const Chips = ({ items, classes, onChanged, value, t }: Props) => {
  return (
    <div className={"tabs"}>
      {items.map((tab) => {
        return (
          <Chip
            key={tab.key}
            label={t(tab.label)}
            onClick={() => onChanged(tab.value)}
            className={cx(
              classes.tab,
              tab.value === value && classes.activeTab
            )}
          />
        );
      })}
    </div>
  );
};

const styles = {
  tab: {
    margin: "27px 15px 0 0",
    background: "#E1E1E1",
    color: "#818181",
    display: "inline-flex",
  },
  activeTab: {
    "&, &:focus": {
      background: "#A8CFE8",
      color: "#818181",
    },
  },
} as const;

export default withStyles(styles)(Chips);
