import store from "../store";

const { dispatch, history, getState } = store;

export default () => {
  const {
    claim,
    cases: { activeCase, list: casesList },
    permissions: { list: globalPermissions, itemShareList },
    dictionary: { courts, memberRoles, jurisdiction_types: jurisdictionTypes },
    authorization: {
      info: { userId },
    },
    datafetched: { loading: dataIsLoading },
  } = getState();
  return {
    ...claim,
    casesList,
    activeCase,
    globalPermissions,
    itemShareList,
    courts,
    memberRoles,
    userId,
    dataIsLoading,
    dispatch,
    history,
    jurisdictionTypes,
  };
};
