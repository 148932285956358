export default {
  LABEL: "Дата",
  FORMAT_ERROR: "Введіть дату у форматі 01.01.2000",
  MAX_DATE_ERROR: "Дата повинна бути не пізніше {{date}}",
  MIN_DATE_ERROR: "Дата повинна бути не раніше {{date}}",
  FROM_DATE: "З дати",
  TO_DATE: "По дату",
  CORRECT_DATE: "За дату",
  CANCEL: "Закрити",
};
