/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

import { yellowColor, dangerColor } from "../../variables/styles";

export default {
  stepHeader: {
    marginTop: 27,
  },
  encryptedClaimTitle: {
    marginTop: 27,
    marginBottom: 27,
    textTransform: "initial",
  },
  ml20: {
    marginLeft: 20,
  },
  encryptForm: {
    "& > div > div": {
      padding: 20,
    },
  },
  iFrame: {
    width: "100%",
    minHeight: 640,
    border: 0,
  },
  flex: {
    flex: 1,
  },
  displayFlex: {
    display: "flex",
  },
  preloader: {
    color: "primary",
    margin: "18px auto",
    float: "right",
  },
  preloaderText: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  fileImage: {
    fontSize: 64,
    margin: "34px auto",
    display: "block",
  },
  schemaForm: {
    marginBottom: 12,
  },
  found: {
    backgroundColor: yellowColor,
  },
  groupWrap: {
    padding: "0 0 0 24px",
  },
  dialogContentWrappers: {
    // maxWidth: "100%",
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "24px 15px 20px",
    },
  },
  error: {
    color: dangerColor,
  },
  signingAvatar: {
    color: "#bdbdbd",
    fontSize: 48,
    "@media (max-width: 767px)": {
      fontSize: 40,
      alignSelf: "flex-start",
    },
  },
  signingNameAndStatus: {
    display: "flex",
    "& > span": {
      minWidth: 420,
      "@media (max-width: 767px)": {
        minWidth: "unset",
        width: "100%",
      },
    },
  },
  label: {
    marginLeft: 4,
    padding: 2,
  },
  success: {
    color: "#1aaa55",
  },
  warning: {
    color: "#fc9403",
  },
  smallButton: {
    margin: 0,
    padding: 0,
    minHeight: 26,
    minWidth: 26,
  },
  noPermError: {
    margin: "0 0 5px 27px",
  },
  dialog: {
    "& > :last-child": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        "& h3": {
          fontSize: "0.7rem",
        },
      },
      "@media (max-width:425px)": {
        // eslint-disable-line no-useless-computed-key
        margin: 0,
        "& > div": {
          margin: 15,
        },
      },
    },
  },
  noTemplateWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  noTemplate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "6rem",
    width: "6rem",
    padding: ".5rem",
    borderRadius: "50%",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  historyCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "14px 15px 10px",
    "@media (max-width:767px)": {
      padding: "24px 15px 20px",
    },
  },
  backButton: {
    marginRight: 4,
  },
};
