import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const RegisterChip = ({
  search,
  t,
  value,
  valueType,
  filter,
  arrayKey,
  valueKey,
  title,
  valueArray,
  onlyText,
  ...rest
}) => {
  let labelValue = "";
  const counts = (((search.list || {}).meta || {}).aggregations || {})[
    valueKey
  ];
  if (valueType === "array") {
    const array = valueArray || search[arrayKey];
    labelValue = Array.isArray(array)
      ? array
          .filter(filter)
          .map(({ name, id }) => {
            if (!Array.isArray(counts)) return name;
            const found = counts.find(
              ({ key: itemKey }) => `${itemKey}` === `${id}`
            );
            const { doc_count: count } = found || {};
            return `${name}${typeof count === "number" ? `(${count})` : ""}`;
          })
          .join(", ")
      : t("LOADING");
  } else if (valueType === "string") {
    labelValue = value
      ? `${value}${typeof counts === "number" ? `(${counts})` : ""}`
      : t("LOADING");
  }
  const text = `${title ? `${t(title)}: ` : ""}${labelValue}`;
  if (onlyText) return text;

  return <Chip label={text} title={text} {...rest} />;
};

RegisterChip.propTypes = {
  search: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  valueType: PropTypes.string,
  filter: PropTypes.func,
  arrayKey: PropTypes.string,
  valueKey: PropTypes.string,
  title: PropTypes.string,
  valueArray: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onlyText: PropTypes.bool,
};

RegisterChip.defaultProps = {
  value: [],
  valueType: "array",
  filter: (i) => i,
  arrayKey: "",
  valueKey: "",
  title: "",
  valueArray: null,
  onlyText: false,
};

const translated = translate("SearchPage")(RegisterChip);
export default connect(({ search }) => ({ search }))(translated);
