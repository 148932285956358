import React from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

import { StandardTextFieldProps } from "@material-ui/core/TextField";
import { BaseControlProps, formControlStyle } from "../types";
import { Formatter } from "../json-schema";
import InputMask from "../components/InputMask";

const styles = {
  ...formControlStyle,
};

interface StringControlProps extends BaseControlProps<StandardTextFieldProps> {}

interface StringControlState {
  value: string;
}

class StringControl extends React.Component<
  StringControlProps,
  StringControlState
> {
  static defaultProps: Partial<StringControlProps> = {};

  inputRef: any;

  constructor(props: StringControlProps) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      value: "",
    };
  }

  async componentWillUnmount() {
    const { onFinish, name, value } = this.props;
    onFinish && (await onFinish(name, value));
  }

  async componentDidUpdate() {
    const {
      value,
      schema: { type, format },
    } = this.props;
    if (this.inputRef.current && this.inputRef.current.value !== value) {
      if (value === null || value === undefined) {
        switch (this.jsonTypeToHtml(type as string, format)) {
          case "number":
            this.inputRef.current.value = 0;
            break;
          default:
            this.inputRef.current.value = "";
        }
      } else {
        this.inputRef.current.value = value;
      }
    }
  }

  handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, name, schema } = this.props;
    const value = Formatter.formatValue(event.target.value, schema);
    onChange && (await onChange(name, value));
  };

  jsonTypeToHtml(jsonType: string, format?: string): string | undefined {
    switch (jsonType) {
      case "number":
        return "number";
      case "string":
        return ["date", "date-time"].includes(format || "")
          ? undefined
          : format;
      default:
        return undefined;
    }
  }

  renderContent() {
    const { classes, context, schema } = this.props;

    let menuItems: any[] = [];

    if (schema.enum) {
      menuItems = Object.values(schema.enum).map((item, key) => (
        <MenuItem key={key} value={item} className={classes.menuItem}>
          {item}
        </MenuItem>
      ));
    } else if (schema.select) {
      const { items, source, label, value } = schema.select;

      if (items) {
        menuItems = items.map((item, index) => (
          <MenuItem
            key={item.key || index}
            value={item.value}
            className={classes.menuItem}
          >
            {item.label}
          </MenuItem>
        ));
      } else if (source && label && value) {
        const items: any[] = context[source];
        menuItems = items.map((item, index) => (
          <option key={item[value]} value={item[value]}>
            {item[label]}
          </option>
        ));
      }
    }

    menuItems.unshift([<option key="" value=""></option>]);

    return menuItems;
  }

  render() {
    const {
      classes,
      disabled,
      errors,
      error,
      hidden,
      name,
      path,
      readOnly,
      required,
      schema,
      setId,
      value,
    } = this.props;
    const id = setId(path || name);
    const inputType = this.jsonTypeToHtml(schema.type as string, schema.format);

    return (
      <TextField
        className={classes.formControl}
        disabled={disabled || readOnly}
        error={error || !!errors}
        helperText={!readOnly && (errors || schema.description)}
        id={id}
        InputProps={{
          readOnly,
          inputComponent: InputMask,
        }}
        // inputProps={{ mask, maxLength }}
        InputLabelProps={{
          shrink: !!value,
        }}
        fullWidth={true}
        label={schema.title}
        name={name}
        onChange={this.handleChange}
        placeholder={schema.title}
        required={required}
        inputRef={this.inputRef}
        select={!!schema.enum || !!schema.select}
        SelectProps={{
          native: true,
        }}
        style={hidden ? { display: "none" } : { display: "block" }}
        type={inputType}
        value={value}
        // defaultValue={value}
      >
        {this.renderContent()}
      </TextField>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(StringControl);
