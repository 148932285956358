import { ChangeEvent, FC, Fragment } from "react";
import {
  Radio,
  FormControlLabel,
  FormControl,
  Tooltip,
  RadioGroup,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import cx from "classnames";
import Hidden from "@material-ui/core/Hidden";

export interface SelectInstanceProps {
  classes: any;
  openedTooltip: string;
  setId: (val: string) => string;
  t: (val: string, ...args: any[]) => string;
  toggleTooltip: (value: string) => void;
  toggleSelectInstance: (event: ChangeEvent<{}>, value: any) => void;
  value: any;
}

const SelectInstance: FC<SelectInstanceProps> = ({
  classes,
  openedTooltip,
  setId,
  t,
  toggleTooltip,
  toggleSelectInstance,
  value,
}) => {
  const buttons = [
    {
      label: "SUBMIT_TO_FIRST_INSTANCE",
      title: "SUBMIT_TO_FIRST_INSTANCE_HINT",
      value: "first",
    },
    {
      label: "SUBMIT_TO_APEAL_INSTANCE",
      title: "SUBMIT_TO_APEAL_INSTANCE_HINT",
      value: "appeal",
    },
  ];

  return (
    <Fragment>
      <FormControl
        fullWidth
        // component="fieldset"
        className={classes.selectControl}
      >
        <RadioGroup
          // row
          className={classes.group}
          value={value.toFirstInstance ? "first" : "appeal"}
          onChange={toggleSelectInstance}
        >
          {buttons.map(({ label, title, value }) => (
            <FormControlLabel
              key={`radio-${value}`}
              value={value}
              className={cx(classes.flex, classes.tooltipRadio)}
              control={<Radio color="default" />}
              label={t(label, {
                tip: (
                  <>
                    <Hidden smDown>
                      <Tooltip
                        classes={{
                          tooltip: classes.toolTip,
                        }}
                        title={t(title)}
                      >
                        <HelpIcon className={classes.toolTipIcon} />
                      </Tooltip>
                    </Hidden>
                    <Hidden mdUp>
                      <Tooltip
                        classes={{
                          tooltip: classes.toolTip,
                        }}
                        title={t(title)}
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={value === openedTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        //@ts-ignore
                        onClose={toggleTooltip("")}
                      >
                        <HelpIcon
                          className={classes.toolTipIcon}
                          //@ts-ignore
                          onClick={toggleTooltip(value)}
                        />
                      </Tooltip>
                    </Hidden>
                  </>
                ),
              })}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Fragment>
  );
};

export default SelectInstance;
