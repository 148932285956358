import React from "react";
import PropTypes from "prop-types";
//import { withRouter } from "react-router-dom";
import { compose } from "redux";
//import openInNewWindowStyles from "../../../variables/styles/openInNewWindow";

import { Table } from "../../../components";
import statuses from "../../../variables/claimStatuses";
import StatusChip from "../../../components/StatusChip";
import redirectes from "../../../helpers/documentRedirected";
import { formatUserName } from "../../../helpers/userName";
import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";

import { ReturnAndLocateInTableHOC } from "../../../components/ReturnAndLocateInTableHOC";

const fields = {
  pagination: true,
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
  tableFields: [
    {
      key: "fullName",
      sort: true,
      title: "DOCUMENT_TITLE",
      style: "max-width: auto",
      classNames: ["cell", "longCellInputDocs", "onlyMobile"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "description",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCellInputDocs", "onlyBigScreen"],
    },
    {
      key: "state",
      sort: true,
      title: "DOCUMENT_STATUS",
      classNames: ["cell", "textCentered", "shortCell"],
      grid: [2, 7, 3, 4],
    },
    {
      key: "courtName",
      sort: true,
      title: "COURT_TITLE",
      classNames: ["cell", "onlyBigScreen"],
    },
    {
      key: "courtTitle",
      classNames: ["cell", "onlyMobile"],
      grid: [2, 7, 4, 5],
    },
    {
      key: "court",
      classNames: ["cell", "onlyMobile"],
      grid: [2, 7, 5, 6],
    },
    {
      key: "createdByName",
      sort: true,
      title: "OWNER",
      classNames: ["cell"],
      grid: [2, 7, 2, 3],
    },
    // {
    //   key: "userName",
    //   sort: true,
    //   title: "TO_NAME",
    //   classNames: ["cell", "onlyBigScreen"],
    // },
    {
      key: "createdAt",
      numeric: true,
      sort: true,
      title: "DOCUMENT_DATE",
      classNames: [
        "cell",
        "dateTimeCellInputDocs",
        "onlyBigScreen",
        "textLeft",
      ],
    },
    {
      key: "goToProcessing",
      title: "CASE_PROC_NUMBER",
      classNames: ["cell", "shortCell"],
    },
  ],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
  filters: ["createdAt"],
};

const getCourt = (item, courts) => {
  let { courtId } = item;
  let courtName = "";
  if (!courtId) {
    const { digitalDocumentData } = item;
    const data =
      digitalDocumentData && digitalDocumentData[0] === "{"
        ? JSON.parse(digitalDocumentData)
        : {};
    courtId = (data.court || {}).court;
  }
  if (courtId && courts) {
    const foundCourt = courts.find(({ id }) => id === courtId);
    if (foundCourt) {
      courtName = foundCourt.name;
    }
  }
  return courtName || "";
};

const InputDocsTable = ({
  classes,
  setId,
  t,
  changeCount,
  checked,
  dataSource,
  handleClick,
  list,
  onSelectAllClick,
  onCheckboxClick,
  createSortHandler,
  pagination,
  courts,
  history,
  returnObject,
}) => {
  const getText = (item, key) => {
    const {
      createdAt,
      createdByName,
      description,
      //userName,
      state,
      documentStateInfo,
    } = item;
    switch (key) {
      case "fullName":
        return t("FULL_NAME", {
          number: description,
          createdAt: humanDateTimeFormat(createdAt),
        });
      case "court":
      case "courtName":
        return getCourt(item, courts);
      case "courtTitle":
        return `${t("COURT_TITLE")} :`;
      case "createdAt":
        return humanDateTimeFormat(createdAt);
      case "state":
        return (
          <StatusChip
            setId={setId}
            status={state}
            tableChip={true}
            catStatuses={statuses}
            documentStateInfo={documentStateInfo}
          />
        );
      case "createdByName":
        return formatUserName(createdByName);
      // case "userName":
      //   return formatUserName(userName);
      case "goToProcessing":
        if (!(item && item.caseId && item.proceedingId)) return null;

        return (
          <div
            className={classes.link}
            title={t("REDIRECT_TO_CASE")}
            onClick={(event) => {
              event.stopPropagation();
              sessionStorage.setItem("inputdocs_documentId", item.id);
              returnObject.setReturnObject({
                form: "inputdocs",
                id: item.id,
                filters: dataSource.filters,
                sort: dataSource.sort,
                search: dataSource.search,
                path: window.location.pathname + window.location.search,
              });
              history.push(redirectes[6].getUrl(item));
            }}
          >
            {item.caseNumber || "б/н"} <br />
            {item.procNumber || "б/н"}
          </div>
        );
      default:
        return item[key];
    }
  };

  return (
    <Table
      fields={fields}
      getText={getText}
      setId={setId}
      createSortHandler={createSortHandler}
      dataSource={dataSource}
      onCheckItem={handleClick}
      list={list}
      needFullData={true}
      pagination={pagination}
      changeCount={changeCount}
      t={t}
      onSelectAllClick={onSelectAllClick}
      onCheckboxClick={onCheckboxClick}
      checked={checked}
    />
  );
};

InputDocsTable.propTypes = {
  changeCount: PropTypes.func.isRequired,
  checked: PropTypes.array,
  classes: PropTypes.object.isRequired,
  dataSource: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  onSelectAllClick: PropTypes.func.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  pagination: PropTypes.func.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InputDocsTable.defaultProps = {
  //trash: false,
  courts: null,
};

// export default InputDocsTable;

export default compose(
  //withRouter,
  ReturnAndLocateInTableHOC
  //withStyles(openInNewWindowStyles)
)(InputDocsTable);
