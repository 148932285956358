export const RETURN_OBJECT = "return_object";

export interface IReturnObjectProps {
  getReturnObject: () => IReturnObject | null;
  setReturnObject: (data: IReturnObject) => void;
  appendReturnObject: (data: IReturnObject) => void;
  popReturnObject: () => void;
  removeReturnObject: () => void;
  hasReturnObject: () => boolean;
  isForm: (form: IReturnForms) => boolean;
}

// export type IReturnForms = "claims" | "notifications" | "notificationDetails";
export type IReturnForms = string;

export interface IReturnObject {
  form: IReturnForms;
  id: string;
  path: string;
  filters: Record<string, any>;
  sort: Record<string, any>;
  search: any;
}
