import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Button } from "../../../components";

export const ExecutiveDialogError = ({ error, t, clearError }) => {
  return (
    <Dialog open={!!error}>
      <DialogTitle>{t("ERROR_TITLE")}</DialogTitle>
      <DialogContent>{error}</DialogContent>
      <Button
        id={"auth-dialog-individual-btn"}
        onClick={clearError}
        color="yellow"
      >
        {t("CLOSE_TEXT")}
      </Button>
    </Dialog>
  );
};
