import React from "react";
import ReactInputMask, {
  BeforeMaskedStateChangeStates,
  InputState,
} from "react-input-mask";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";

export interface InputMaskProps extends InputBaseComponentProps {
  /**
   * Mask string. Format characters are:
   * * `9`: `0-9`
   * * `a`: `A-Z, a-z`
   * * `\*`: `A-Z, a-z, 0-9`
   *
   * Any character can be escaped with backslash, which usually will appear as double backslash in JS strings.
   * For example, German phone mask with unremoveable prefix +49 will look like `mask="+4\\9 99 999 99"` or `mask={"+4\\\\9 99 999 99"}`
   */
  mask?: string | Array<string | RegExp>;
  /**
   * Character to cover unfilled editable parts of mask. Default character is "_". If set to null, unfilled parts will be empty, like in ordinary input.
   */
  maskPlaceholder?: string | null | undefined;
  /**
   * Show mask even in empty input without focus.
   */
  alwaysShowMask?: boolean | undefined;
  /**
   * Use inputRef instead of ref if you need input node to manage focus, selection, etc.
   */
  ref?: React.Ref<HTMLInputElement> | undefined;
  /**
   * In case you need to implement more complex masking behavior, you can provide
   * beforeMaskedStateChange function to change masked value and cursor position
   * before it will be applied to the input.
   *
   * * previousState: Input state before change. Only defined on change event.
   * * currentState: Current raw input state. Not defined during component render.
   * * nextState: Input state with applied mask. Contains value and selection fields.
   */
  beforeMaskedStateChange?(states: BeforeMaskedStateChangeStates): InputState;
}

const InputMask: React.FC<InputMaskProps> = (props) => {
  return (
    //@ts-ignore
    <ReactInputMask
      {...props}
      mask=""
      // onChange={handleChange}
      // value={value}
      // inputRef={props.inputProps?.ref}
    />
  );
};

export default InputMask;
