import setComponentsId from "../../helpers/setComponentsId";

import RegistryList from "../../pages/Registry";
import RegistryDocument from "../../pages/Registry/RegistryDocument";

import createRoutes from "../../helpers/createRoutes";

export const appRoutes = [
  {
    path: "/search",
    publicRoute: true,
    component: RegistryList,
    setId: setComponentsId("registry-list"),
  },
  {
    path: "/search/:documentId",
    publicRoute: true,
    component: RegistryDocument,
    setId: setComponentsId("registry-list"),
  },
  {
    redirect: true,
    path: "/",
    to: "/search",
  },
];

export default createRoutes(appRoutes);
