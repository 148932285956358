import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";

import classNames from "classnames";
import objectPath from "object-path";

import { SavedDateFormulas } from "../../../helpers/savedFilterDates/saved.date.formulas";
import setComponentsId from "../../../helpers/setComponentsId";
import FilterInput from "../../../components/FilterInput";
import customInputStyle from "../../../variables/styles/customInputStyle";
import { sortStatuses } from "../../../variables/executiveStatuses";
import DateRangePicker from "../../../components/CustomInput/DateRangePicker_executiveDocs";
import StatusChip from "../decorators/index";
import humanDateFormat from "../../../helpers/humanDateFormat";
import FromToNumericInput from "../../../components/CustomInput/FromToNumericInput";

// classes - из параметров которые приходят в Filters

const Filters = ({
  setId,
  t,
  dataSource,
  load,
  statuses,
  courts,
  roles,
  forms,
  classes,
  userId,
  returnObject,
}) => {
  const initialState = {
    statusId: "",
    execMyRole: "",
    collectorIssuanceType: "",
    createdAt: "",
    dateForce: "",
    updatedAt: "",
    paymentInfoSumm: "",
    searchValue: "",
    sort: "",
  };

  const sortingStatuses = sortStatuses(t, statuses);
  const [localValues, setLocalValues] = useState(initialState);
  const [values, setValues] = useState(initialState);

  const [isSaveFilter, setIsSaveFilter] = useState(false);

  useEffect(() => {
    const filters = {
      ...localValues,
      statusId: objectPath.get(dataSource.filters, "statusId") || "",
      execMyRole: objectPath.get(dataSource.filters, "execMyRole") || "",
      collectorIssuanceType:
        objectPath.get(dataSource.filters, "collectorIssuanceType") || "",
      createdAt: objectPath.get(dataSource.filters, "createdAt") || "",
      dateForce: objectPath.get(dataSource.filters, "dateForce") || "",
      updatedAt: objectPath.get(dataSource.filters, "updatedAt") || "",
      paymentInfoSumm:
        objectPath.get(dataSource.filters, "paymentInfoSumm") || "",
      searchValue: localValues.searchValue || dataSource?.search || "",
    };
    setLocalValues({ ...filters });
    setValues({ ...filters });
  }, []);

  const clearValue = (filterName) => {
    setLocalValues({ ...localValues, [filterName]: "" });
  };

  const onChange = ({ target: { value, name: filterName } }) => {
    setLocalValues({ ...localValues, [filterName]: value });
  };

  const onIsSaveFilterChange = () => {
    setIsSaveFilter(!isSaveFilter);
  };

  const onCloseFilterCallback = () => {
    setLocalValues({ ...values, searchValue: localValues.searchValue });
  };

  const onClearCallback = () => {
    setLocalValues(initialState);
    setValues(initialState);

    let allUserFilter = localStorage.getItem("executiveDocsFilter");
    if (allUserFilter) {
      allUserFilter = JSON.parse(allUserFilter);
    }
    localStorage.setItem(
      "executiveDocsFilter",
      JSON.stringify({
        ...allUserFilter,
        [userId]: initialState,
      })
    );

    for (let filter in initialState) {
      if (filter === "searchValue") {
        dataSource.setSearch("");
        continue;
      } else if (filter === "sort") {
        dataSource.sort = {};
        continue;
      } else if (initialState[filter])
        dataSource.setFilter(filter, initialState[filter]);
      else delete dataSource.filters?.[filter];

      const { aggs } = dataSource;
      aggs[filter] = !!initialState[filter] || undefined;
      dataSource.setValue("aggs", aggs);
    }
  };

  const saveSearchValueCallback = (value) => {
    setLocalValues({ ...localValues, searchValue: value });
  };

  const onSearchCallback = (noSaving = false) => {
    if (noSaving) {
      setLocalValues({ ...values });
      return;
    }
    for (let filter in localValues) {
      if (filter === "searchValue") {
        dataSource.setSearch(localValues.searchValue);
        continue;
      } else if (localValues[filter]) {
        dataSource.setFilter(filter, localValues[filter]);
        continue;
      } else {
        delete dataSource.filters?.[filter];
      }
      const { aggs } = dataSource;
      aggs[filter] = !!localValues[filter] || undefined;
      dataSource.setValue("aggs", aggs);
    }
    setLocalValues({ ...localValues });

    if (isSaveFilter) {
      let allUserFilter = localStorage.getItem("executiveDocsFilter");
      if (allUserFilter) {
        allUserFilter = JSON.parse(allUserFilter);
      }

      const dateInfo = {
        createdAt: {},
        updatedAt: {},
        dateForce: {},
      };

      if (localValues.createdAt) {
        const dateStr = localValues.createdAt.split(",");
        const savedDate = new SavedDateFormulas(dateStr?.[0], dateStr?.[1]);
        dateInfo.createdAt = {
          createdAtKDMD: savedDate.getKDMD(),
          createdAtKDDP: savedDate.getKDDP(),
          createdAtKDDZ: savedDate.getKDDZ(),
        };
      }
      if (localValues.updatedAt) {
        const dateStr = localValues.updatedAt.split(",");
        const savedDate = new SavedDateFormulas(dateStr?.[0], dateStr?.[1]);
        dateInfo.updatedAt = {
          updatedAtKDMD: savedDate.getKDMD(),
          updatedAtKDDP: savedDate.getKDDP(),
          updatedAtKDDZ: savedDate.getKDDZ(),
        };
      }
      if (localValues.dateForce) {
        const dateStr = localValues.dateForce.split(",");
        const savedDate = new SavedDateFormulas(dateStr?.[0], dateStr?.[1]);
        dateInfo.dateForce = {
          dateForceKDMD: savedDate.getKDMD(),
          dateForceKDDP: savedDate.getKDDP(),
          dateForceKDDZ: savedDate.getKDDZ(),
        };
      }

      localStorage.setItem(
        "executiveDocsFilter",
        JSON.stringify({
          ...allUserFilter,
          [userId]: {
            ...localValues,
            ...dateInfo.createdAt,
            ...dateInfo.updatedAt,
            ...dateInfo.dateForce,
            changeFilterDate: humanDateFormat(Date.now(), {
              format: "YYYY-MM-DD",
            }),
            searchValue: localValues.searchValue,
            sort: JSON.stringify(dataSource.sort),
          },
        })
      );
      setIsSaveFilter(false);
    }
  };

  return (
    <FilterInput
      dataSource={dataSource}
      load={load}
      setId={setId}
      statuses={sortingStatuses}
      courts={courts}
      roles={roles}
      forms={forms}
      isExecutiveDocs
      onSearchCallback={onSearchCallback}
      onClearCallback={onClearCallback}
      saveSearchValueCallback={saveSearchValueCallback}
      filterSearchValue={localValues.searchValue}
      onCloseFilterCallback={onCloseFilterCallback}
      returnObject={returnObject}
    >
      {/* ФІЛЬТР ПО СТАТУСУ В РЕЄСТРІ ВД */}
      <TextField
        value={localValues.statusId}
        onChange={onChange.bind(this)}
        select={true}
        name="statusId"
        label={t("DOCUMENT_STATUS")}
        margin="normal"
        id={setId("select-status")}
        style={{ display: "inlineFlex" }}
        className={classNames(classes.margin, classes.textField, classes.flex)}
        SelectProps={{
          id: setId("select-statusId-filter"),
          SelectDisplayProps: {
            id: setId("select-statusId-filter-wrapper"),
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle"
              onClick={() => clearValue("statusId")}
            >
              <Icon style={{ fontSize: "16px" }}>close</Icon>
            </IconButton>
          ),
        }}
      >
        {sortingStatuses.map(({ id, name, value }, index) => (
          <MenuItem key={index} value={value} id={setId(`status-${id}`)}>
            <StatusChip status={id} statusText={name} tableChip={true} />
          </MenuItem>
        ))}
      </TextField>

      {/* ФІЛЬТР ПО МОЇЙ РОЛІ */}
      <TextField
        value={localValues.execMyRole}
        onChange={onChange.bind(this)}
        key={setId("role-filter")}
        select={true}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => clearValue("execMyRole")}>
              <Icon style={{ fontSize: "16px" }}>close</Icon>
            </IconButton>
          ),
        }}
        name="execMyRole"
        label={t("DOCUMENT_ROLE")}
        margin="dense"
        id={setId("role-filter")}
        className={classNames(classes.margin, classes.textField, classes.flex)}
        SelectProps={{
          id: setId("select-role-filter"),
          SelectDisplayProps: {
            id: setId("select-role-filter-wrapper"),
          },
        }}
      >
        {Array.isArray(roles) &&
          roles.map((item, index) => (
            <MenuItem
              key={item.key + "_" + index}
              value={item.value}
              id={setId(`role-filter-${index + 1}`)}
            >
              {item.name}
            </MenuItem>
          ))}
      </TextField>

      {/* ФІЛЬТР ПО ФОРМІ ВИДАЧІ */}
      <TextField
        value={localValues.collectorIssuanceType}
        onChange={onChange.bind(this)}
        displayEmpty
        key={setId("form-filter")}
        select={true}
        name="collectorIssuanceType"
        label={t("DOCUMENT_FORM")}
        margin="dense"
        id={setId("form-filter")}
        className={classNames(classes.margin, classes.textField, classes.flex)}
        SelectProps={{
          id: setId("select-form-filter"),
          SelectDisplayProps: {
            id: setId("select-form-filter-wrapper"),
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => clearValue("collectorIssuanceType")}>
              <Icon style={{ fontSize: "16px" }}>close</Icon>
            </IconButton>
          ),
        }}
      >
        {Array.isArray(forms) &&
          forms.map((item, index) => (
            <MenuItem
              key={item.key + "_" + index}
              value={Number(item.value)}
              id={setId(`form-filter-${index + 1}`)}
            >
              {item.name}
            </MenuItem>
          ))}
      </TextField>

      {/* ФІЛЬТРИ ПО ДАТАХ */}
      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "-22px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("DOCUMENT_CREATED_AT")}
        </div>

        <IconButton onClick={() => clearValue("createdAt")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>
      <DateRangePicker
        value={localValues.createdAt}
        onChange={onChange.bind(this)}
        name="createdAt"
        label={t("DOCUMENT_CREATED_AT")}
        setId={setId}
      />

      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "-22px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("DNZS_DATE")}
        </div>

        <IconButton onClick={() => clearValue("dateForce")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>

      <DateRangePicker
        value={localValues.dateForce}
        onChange={onChange.bind(this)}
        name="dateForce"
        label={t("DNZS_DATE")}
        setId={setId}
      />

      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "-22px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("CHANGE_DATE")}
        </div>

        <IconButton onClick={() => clearValue("updatedAt")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>

      <DateRangePicker
        value={localValues.updatedAt}
        onChange={onChange.bind(this)}
        name="updatedAt"
        label={t("CHANGE_DATE")}
        setId={setId}
      />

      <Divider style={{ marginTop: "15px" }} />
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginBottom: "-5px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {t("PAYMENT_INFO_SUMM")}
        </div>

        <IconButton onClick={() => clearValue("paymentInfoSumm")}>
          <Icon style={{ fontSize: "16px" }}>close</Icon>
        </IconButton>
      </div>

      <FromToNumericInput
        key={setId("paymentInfoSumm-filter")}
        name="paymentInfoSumm"
        label={t("PAYMENT_INFO_SUMM")}
        value={localValues.paymentInfoSumm}
        id={setId("paymentInfoSumm-filter")}
        classes={classes}
        onChange={onChange}
        // className={classNames(classes.margin, classes.textField, classes.flex)}
      />

      <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
        <Checkbox
          className={classes.checkbox}
          checked={isSaveFilter}
          onChange={onIsSaveFilterChange}
          color="default"
          id={setId("cell-checkbox")}
        />
        Зробити фільтром за умовчанням
      </div>
    </FilterInput>
  );
};

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
  statuses: PropTypes.object,
};

Filters.defaultProps = {
  statuses: {},
  setId: setComponentsId("executive-filters"),
};

const styled = withStyles(customInputStyle)(Filters);
export default translate("ExecutiveDocs")(styled);
