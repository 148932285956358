import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { fourteenYearsAgo } from "../../../helpers/humanDateFormat";
import CustomDatePicker from "../../../components/CustomInput/CustomDatePicker";

import PhoneInput from "./PhoneInput";
import EmailInput from "./EmailInput";
import { RenderControl } from "./RenderControl";
import { PHONE_MASK } from "../../../components/RenderControl";

const fields = {
  isLegal: [
    {
      key: "companyName",
      name: "company_name",
      label: "COMPANY_NAME_INPUT_LABEL",
      disabled: true,
    },
    {
      key: "email",
      Component: EmailInput,
      changed: "email",
    },
    {
      key: "phone",
      Component: PhoneInput,
      changed: "phone",
    },
    {
      key: "edrpou",
      label: "EDRPOU_INPUT_LABEL",
      disabled: true,
    },
    {
      key: "legalEntityDateRegistration",
      Component: CustomDatePicker,
      changed: "date",
      label: "REGISTRATION_INPUT_LABEL",
    },
    {
      key: "coatsuCode",
      label: "CODE_COATDU_LABEL",
      disabled: true,
      helperText: "CODE_COATDU_HELPER",
    },
    {
      key: "address",
      label: "LEGAL_ADDRESS",
      helperText: "ADDRESS_HELPER",
      maxLength: 250,
    },
  ],
  legalEntityAgent: [
    {
      key: "name",
      label: "COMPANY_NAME_INPUT_LABEL",
      disabled: true,
    },
    {
      key: "shortName",
      label: "SHORT_NAME",
      disabled: true,
    },
    {
      key: "email",
      Component: EmailInput,
      changed: "email",
    },
    {
      key: "phone",
      label: "PHONE_INPUT_LABEL",
      mask: PHONE_MASK,
    },
    {
      key: "edrpou",
      label: "EDRPOU_INPUT_LABEL",
      disabled: true,
    },
    {
      key: "legalEntityRegDate",
      changed: "date",
      disabled: true,
      label: "REGISTRATION_INPUT_LABEL",
    },
    {
      key: "legalEntityRegNumber",
      disabled: true,
      label: "REGISTRATION_NUMBER_LABEL",
    },
    {
      key: "coatsuCode",
      label: "CODE_COATDU_LABEL",
      disabled: true,
      helperText: "CODE_COATDU_HELPER",
    },
    {
      key: "address",
      label: "LEGAL_ADDRESS",
      helperText: "ADDRESS_HELPER",
      maxLength: 250,
    },
  ],
  notIsLegal: [
    {
      key: "last_name",
      label: "LAST_NAME_INPUT_LABEL",
      disabled: true,
      capitalizedFirstLeter: true,
    },
    {
      key: "first_name",
      label: "FIRST_NAME_INPUT_LABEL",
      disabled: true,
      capitalizedFirstLeter: true,
    },
    {
      key: "middle_name",
      label: "MIDDLE_NAME_INPUT_LABEL",
      disabled: true,
      capitalizedFirstLeter: true,
    },
    {
      key: "CEOName",
      label: "CEO_NAME_INPUT_LABEL",
      disabled: true,
      notForIE: true,
      capitalizedFirstLeter: true,
    },
    {
      key: "companyName",
      label: "COMPANY_NAME",
      disabled: true,
      notForIE: true,
      relatedField: "CEOName",
    },
    {
      key: "email",
      Component: EmailInput,
      changed: "email",
    },
    {
      key: "phone",
      Component: PhoneInput,
      changed: "phone",
    },
    {
      key: "ipn",
      label: "IPN_INPUT_LABEL",
      disabled: true,
    },
    {
      key: "birthday",
      Component: CustomDatePicker,
      changed: "date",
      label: "BIRTHDAY_INPUT_LABEL",
      maxDate: fourteenYearsAgo("YYYY-MM-DD"),
    },
    {
      key: "coatsuCode",
      label: "CODE_COATDU_LABEL",
      disabled: true,
      helperText: "CODE_COATDU_HELPER",
    },
    {
      key: "address",
      label: "ADDRESS",
      helperText: "ADDRESS_HELPER",
      maxLength: 250,
    },
    {
      key: "isIndividualEntrepreneur",
      label: "INDIVIDUAL_ENTREPRENEUR",
    },
    { key: "isAdvocate" },
  ],
};

const ProfileLayout = ({
  t,
  classes,
  values,
  setId,
  role,
  handleChange,
  handleChangePhone,
  handleChangeDate,
  checkboxChange,
  personalData,
  readOnly,
  handleInterfaceChanged,
}) => {
  return (
    <Fragment>
      {fields[role].map(
        RenderControl({
          t,
          classes,
          setId,
          handleChangePhone,
          handleChangeDate,
          handleChange,
          checkboxChange,
          values,
          readOnly,
          personalData,
          handleInterfaceChanged,
        })
      )}
    </Fragment>
  );
};

ProfileLayout.propTypes = {
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  personalData: PropTypes.object.isRequired,
  handleInterfaceChanged: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default ProfileLayout;
