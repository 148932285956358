import React from "react";
import { withStyles, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import cx from "classnames";

import buttonStyle from "../../variables/styles/buttonStyle";

const RegularButton = ({ ...props }) => {
  const {
    className,
    classes,
    color,
    round,
    children,
    fullWidth,
    disabled,
    setId,
    size,
    variant,
    ...rest
  } = props;
  const btnClasses = cx({
    [classes.button]: true,
    [className]: className,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
  });
  return (
    <Button
      id={setId("button")}
      {...rest}
      className={btnClasses}
      disabled={!!disabled}
      size={size ? size : ""}
      variant={variant ? variant : ""}
    >
      {children}
    </Button>
  );
};

RegularButton.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "yellow",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "simple",
    "transparent",
    "black",
  ]),
  size: PropTypes.string,
  variant: PropTypes.string,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

RegularButton.defaultProps = {
  children: "",
  className: "",
  color: "yellow",
  round: false,
  fullWidth: false,
  disabled: false,
  size: "",
  variant: "",
  setId: setComponentsId("regular"),
};

export default withStyles(buttonStyle)(RegularButton);
