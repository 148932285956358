import ReactDOM from "react-dom";

import "./assets/css/material-dashboard-react.css";
import "./assets/css/material-dashboard-react-elements.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// import {hotjar} from 'react-hotjar';
// import * as Sentry from '@sentry/browser';

// import config from 'config.json';
import App from "./App";

import * as serviceWorker from "./serviceWorker";
// @ts-ignore
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// import packageJson from './../package.json';

// const {hotjar: hotjarConfig, sentryDns, application: {environment}} = config;
// const {name, version} = packageJson;

// hotjar.initialize(hotjarConfig.id, hotjarConfig.sv);
// Sentry.init({
//     dsn: sentryDns,
//     environment,
//     release: `${name}@${version}`// ,
// beforeSend(event) {
//   // Check if it is an exception, if so, show the report dialog
//   if (event.exception && environment !== 'production') {
//     Sentry.showReportDialog();
//   }
//   return event;
// }
// });

ReactDOM.render(App, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
