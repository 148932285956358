import { Fragment } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import {
  Radio,
  FormControlLabel,
  FormControl,
  Typography,
  Checkbox,
  FormGroup,
} from "@material-ui/core";

const PermissionsRadioGroup = ({
  classes,
  t,
  setId,
  value: { claimant, representativeClaimant, asIndividualEntrepreneur },
  disabledPersonally,
  showIndividualEntrepreneurChoose,
  showRepresentativeChoose,
  toggleMode,
  toggleIndividualEntrepreneur,
}) => {
  const isOwner = claimant && !representativeClaimant && !disabledPersonally;

  if (
    (!showRepresentativeChoose && !isOwner) ||
    (showRepresentativeChoose && isOwner)
  ) {
    toggleMode();
  }

  return (
    <Fragment>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {t("SELECT_PERMISSION_TYPE")}
      </Typography>
      <FormControl
        component="fieldset"
        className={classes.formControl}
        id={setId("radio-wrap")}
      >
        <FormGroup row={true}>
          {!showRepresentativeChoose && (
            <FormControlLabel
              label={t("PERMISSION_OWNER")}
              control={
                <Radio
                  className={classes.radio}
                  checked={isOwner}
                  color="default"
                  id={setId("radio-owner-true")}
                  onChange={toggleMode}
                  disabled={disabledPersonally}
                />
              }
            />
          )}
          {showIndividualEntrepreneurChoose && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={asIndividualEntrepreneur}
                  disabled={!isOwner}
                  onChange={toggleIndividualEntrepreneur}
                  color="default"
                />
              }
              label={t("AS_INDIVIDUAL_ENTREPRENEUR")}
            />
          )}
          {!showRepresentativeChoose && (
            <div style={{ marginTop: 5 }}>
              Для подачі заяви як представник змініть роль на "Представник"
            </div>
          )}
        </FormGroup>
        {showRepresentativeChoose && (
          <FormControlLabel
            label={t("PERMISSION_NOT_OWNER")}
            control={
              <Radio
                className={classes.radio}
                checked={!isOwner}
                color="default"
                id={setId("radio-owner-false")}
                onChange={toggleMode}
                disabled={disabledPersonally}
              />
            }
          />
        )}
        {showRepresentativeChoose && (
          <div style={{ marginTop: 5 }}>
            Для подачі заяви особисто змініть роль на "Фізична особа"
          </div>
        )}
      </FormControl>
    </Fragment>
  );
};

PermissionsRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  value: PropTypes.object,
  disabledPersonally: PropTypes.bool,
  showIndividualEntrepreneurChoose: PropTypes.bool,
  showRepresentativeChoose: PropTypes.bool,
  toggleMode: PropTypes.func.isRequired,
  toggleIndividualEntrepreneur: PropTypes.func.isRequired,
};

PermissionsRadioGroup.defaultProps = {
  setId: setComponentsId("permissions-dialog"),
  value: {},
  disabledPersonally: false,
};

export default PermissionsRadioGroup;
