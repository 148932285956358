import { ChangeEvent, FC, useState } from "react";
import { compose } from "redux";
import { withStyles, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TextFieldDummy from "../../../components/CustomInput/TextFieldDummy";
// import InputMask, { Props as ElementInputProps } from "react-input-mask";

import { StandardTextFieldProps } from "@material-ui/core/TextField";
import { BaseControlProps, formControlStyle } from "../types";

// interface MaskedProps extends ElementInputProps {
//   ref: any;
// }

// const Masked = (props: MaskedProps) => (
//   <InputMask {...props} maskChar=" " inputRef={props.ref} />
// );

const styles = (theme: any) => ({
  ...formControlStyle,
});

interface IntegerControlProps
  extends BaseControlProps<StandardTextFieldProps> {}

const IntegerControl: FC<IntegerControlProps> = ({
  classes,
  errors,
  disabled,
  hidden,
  name,
  onChange,
  readOnly,
  required,
  schema,
  setId,
  t,
  value,
}) => {
  const [_value, setValue] = useState(value);

  const {
    description,
    // exclusiveMaximum,
    // exclusiveMinimum,
    // maximum,
    // minimum,
    // examples,
    // pattern,
    title,
  } = schema;

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    // event.preventDefault();
    let value = parseInt(event.target.value, 10);
    // if(exclusiveMinimum && (!value || value <= exclusiveMinimum)) {
    //   value = exclusiveMinimum + 1;
    // } else if(minimum && (!value || value < minimum)) {
    //   value = minimum;
    // } else if(exclusiveMaximum && value >= exclusiveMaximum) {
    //   value = exclusiveMaximum - 1;
    // } else if(maximum && value > maximum) {
    //   value = maximum;
    // }
    onChange && (await onChange(name, value));
    setValue(value);
  };

  const Component = readOnly ? TextFieldDummy : TextField;
  return (
    <FormControl
      className={classes.formControl}
      disabled={disabled || readOnly}
      id={`${setId(name)}-wrapper`}
      fullWidth={true}
      required={required}
      style={hidden ? { display: "none" } : { display: "block" }}
    >
      <Component
        error={!!errors}
        fullWidth={false}
        helperText={!readOnly && (errors || description)}
        id={setId("input")}
        InputLabelProps={{
          shrink: !!value,
        }}
        InputProps={{
          readOnly: readOnly,
          // inputComponent: InputMask
        }}
        //  inputProps={}
        label={(title || "") + (required ? "*" : "")}
        onChange={handleChange}
        placeholder={title}
        required={required}
        type="number"
        value={_value + ""}
      />
    </FormControl>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(IntegerControl);
