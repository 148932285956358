export const InfoBlockWrapper = ({ classes, title, button, children }) => {
  return (
    <div className={classes.infoblock}>
      <div className={`${classes.title} ${classes.buttontitle}`}>
        {title}
        {button}
      </div>
      {children}
    </div>
  );
};

export const infoBlock = (classes, title, rows, t) => {
  return (
    <div className={classes.infoblock}>
      <div className={classes.title}>{title}</div>
      <table>
        <tbody>
          {rows
            .filter((item) => !!item[1])
            .map((item) => (
              <tr>
                <td className={classes.stylesInfoBlockTitle}>{item[0]}</td>
                <td
                  className={
                    t &&
                    item?.[0] === t("VD_STATE") &&
                    item?.[0] === t("VP_STATE")
                      ? classes.stylesWithoutPaddingInfoBlockData
                      : classes.stylesInfoBlockData
                  }
                >
                  {item[1]}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
