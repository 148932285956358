import React, { Fragment } from "react";
import { connect } from "react-redux";

import routes from "./routes";
import Header from "./components/Header";

const Search = ({ auth, loading }) => (
  <Fragment>
    <Header auth={auth} loading={loading} />
    {routes}
  </Fragment>
);

const mapStateToProps = ({ authorization: auth, search: { loading } }) => ({
  auth,
  loading,
});

export default connect(mapStateToProps)(Search);
