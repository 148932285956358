import { render } from "react-dom";

export function handlePrintHistory(reactComponent) {
  try {
    const frame = document.createElement("iframe");
    frame.style.display = "none";
    frame.title = "Картка руху документу";

    window.document.body.appendChild(frame);
    const _window = frame.contentWindow;

    _window.onload = () => {
      _window.document.title = "Картка руху документу";
      _window.document.body.appendChild(_window.document.createElement("div"));
      render(reactComponent, _window.document.body.children[0]);
      _window.print();
    };
    setTimeout(() => window.document.body.removeChild(frame), 1000);
  } catch (err) {
    console.log("print err: ", err);
  }
}

export const getHtmlTemplate = (header, rows) => `
    <!DOCTYPE html>
    <html>
      <head>
        ${STYLES}
      </head>
      <body>
        <h3>${header}</h3>
        <table>
          ${rows}
        </table>
      </body>
    </html>
    `;

export const getRows = (array) => {
  let rows = array
    .map((rowData) => {
      return rowData?.[0] && rowData?.[1]
        ? `
        <tr>
          <td>${rowData[0]}</td>
          <td>${rowData[1]}</td>
        </tr>`
        : null;
    })
    .filter((row) => row !== null)
    .join("");

  // console.log(rows);

  // array.forEach((el) => {
  //   if (el?.[0] && el?.[1]) {
  //     rows += `<tr>
  //               <td>${el[0]}</td>
  //               <td>${el[1]}</td>
  //               </tr>`;
  //   }
  // });

  return rows;
};

const STYLES = `<style>
  html, body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: .9rem;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  td:first-of-type {
    font-weight: bold;
  }
  @media (max-width: 350px) {
    table {
      font-size: .7rem;
    }
  }
</style>`;
