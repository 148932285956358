import React from "react";
import PropTypes from "prop-types";
import { Toolbar } from "@material-ui/core";

import Filters from "./Filters";
import setComponentsId from "../../../helpers/setComponentsId";
import { getStatusesForCat } from "../../../variables/claimStatuses";

const InputDocsToolbar = ({
  classes,
  setId,
  t,
  dataSource,
  docCategory,
  load,
}) => (
  <Toolbar className={classes.toolbar} disableGutters={true}>
    <Filters
      t={t}
      classes={classes}
      setId={setId}
      dataSource={dataSource}
      statuses={getStatusesForCat(docCategory)}
      load={load}
      // courtTypes={courtTypes}
      // justiceTypes={justiceTypes}
      // courts={courts}
    />
  </Toolbar>
);

InputDocsToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  docCategory: PropTypes.number.isRequired,
  load: PropTypes.func.isRequired,
};

InputDocsToolbar.defaultProps = {
  setId: setComponentsId("inputdocs-toolbar"),
};

export default InputDocsToolbar;
