import React from "react";
import PropTypes from "prop-types";

import config from "../../config";

const { gtmKey } = config();

const script = (id) => `
    (function(w,d,s,l,i){
        var f=d.getElementById(s);
        f.async=true;
        f.src='https://www.googletagmanager.com/gtag/js?id='+i;
        w[l]=[];
        function gtag(){w[l].push(arguments);}
        gtag('js', new Date());
        gtag('config', i);
    })(window,document,'gaRequest','dataLayer','${id}');
`;

class GoogleTagManager extends React.Component {
  componentDidMount() {
    const gtmScriptNode = document.getElementById("react-google-tag-script");
    eval(gtmScriptNode.textContent); // eslint-disable-line no-eval
  }

  render() {
    return (
      <div>
        <div id={"react-google-tag-script"}>
          <script
            dangerouslySetInnerHTML={{
              // eslint-disable-line react/no-danger
              __html: script(gtmKey),
            }}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}

GoogleTagManager.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GoogleTagManager;
