import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { translate } from "../../helpers/translate";
import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import { IReturnObjectProps } from "../../components/ReturnAndLocateInTableHOC/types";

import StandartPageLayout from "../../components/StandartPageLayout";

import styles from "../../variables/styles/StateRegisters";
import RegisterTable from "./components/RegisterTable";
import { StateRegister } from "../../actions/stateRegisters";
import { registerDataSource } from "../../reducers/stateRegisters";

export interface StateRegisterListProps {
  classes: any;
  setId: (s: string) => string;
  t: (s: string, args?: Record<string, any>) => string;
  history: any;
  loading: boolean;
  match: any;
  page: string;
  registerList: StateRegister[];
  returnObject: IReturnObjectProps;
}

export interface StateRegisterListState {
  error?: string;
}

class StateRegisterList extends React.Component<
  StateRegisterListProps,
  StateRegisterListState
> {
  static initialState: StateRegisterListState = {
    error: "",
  };

  constructor(props: StateRegisterListProps) {
    super(props);
    this.state = StateRegisterList.initialState;
  }

  async componentDidMount() {
    const { page, returnObject } = this.props;
    returnObject.isForm(page) && returnObject.removeReturnObject();
    if (this.props.registerList?.length === 0) registerDataSource.load();
  }

  createRequest = (registerId: number) => {};

  handleRowSelect = (registerId: number) => {
    const { page, history, returnObject } = this.props;

    returnObject.appendReturnObject({
      form: page,
      id: registerId.toString(),
      filters: {},
      sort: {},
      path: window.location.pathname + window.location.search,
      search: null,
    });

    history.push(`/state_registers/${registerId}`);
  };

  render(): React.ReactNode {
    const { classes, loading, registerList, t, setId } = this.props;
    // if (window.amplitude) {
    //   window.amplitude.track("Перегляд сторінки: Державні реєстри");
    //   console.log("Amplitude: Відстеження перегляду сторінки /state_registers");
    // }
    return (
      <StandartPageLayout
        loading={loading}
        list={registerList || []}
        dataSource={registerDataSource}
        blockScreen={loading}
        cardTitle={t("MAIN_TITLE")}
        setId={setId}
      >
        <RegisterTable
          t={t}
          classes={classes}
          items={registerList}
          onRowSelect={this.handleRowSelect}
          onButtonClick={this.createRequest}
        />
      </StandartPageLayout>
    );
  }
}

function mapStateToProps({ stateRegisters: { loading, registerList } }: any) {
  return {
    loading,
    registerList,
  };
}

export default translate("StateRegisters")(
  compose(
    connect(mapStateToProps),
    withRouter,
    ReturnAndLocateInTableHOC,
    withStyles(styles as any)
  )(StateRegisterList)
);
