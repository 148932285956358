import { FC, Fragment, useEffect, useState } from "react";
import { compose } from "redux";
import { translate } from "../../helpers/translate";
import { withStyles } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";

import { checkCertificate } from "../../actions/user";
import {
  signManifestV1,
  signManifestV2,
} from "../../services/eds/helpers/signManifest";
import setComponentsId from "../../helpers/setComponentsId";

import { Button } from "..";
import {
  SchemaWizardProps,
  SchemaWizardState,
  schemaWizardStyles,
  SignMethod,
  WizardStatus,
} from "./types";
import { SchemaFunctions as functions } from "./helpers/SchemaFunctions";
import WizardStepper from "./components/WizardStepper";
import { deleteEmptyValues } from "../FormFromSchema/helpers";

import Signing from "./components/Signing";

const SchemaWizard: FC<SchemaWizardProps> = ({
  classes,
  context,
  finishButtonText,
  errors,
  //Callback before back to edit
  onBackToEdit,
  //Callback before wizard close
  onClose,
  //Callback on data finalize
  onDataCommit,
  //Callback after created new data
  onDataCreate,
  //Callback after created new data
  onDataFinish,
  //Callback after created new data
  onDataLoad, //Callback on data commit error
  onErrorCommit,
  //Callback on sign error
  onErrorSign,
  //Callback on create or update form context
  onFormContext,
  //Callback on close wizard
  onGetDataToSign,
  onGetSavedSign,
  onStepCheck,
  //Callback after data apdated
  onStepSave,
  //Callback after data updated
  onStepUpdate,
  returnTo,
  returnToText,
  setId = setComponentsId("schema-wizard"),
  schema,
  signMethod,
  signPreview,
  t,
  user,
  wizardData,
}) => {
  const [state, setState] = useState<SchemaWizardState>({
    activeStepId: 0,
    allSteps: [],
    data: { activeStep: "" },
    dataSaving: false,
    formContext: {},
    nextStepId: 0,
    noErrorCheck: false,
    stepData: null,
    stepSaveQueue: new Set<string>(),
    //@ts-ignore здесь ошибка в properties
    stepSchema: { properties: {} },
  });
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showSignModal, setShowSignModal] = useState(false);
  const [wizardStatus, setWizardStatus] = useState(WizardStatus.EDIT);

  useEffect(() => {
    initData()
      .then((newState) => {
        setState({ ...state, ...newState });
        setIsMounted(true);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isMounted) {
      const { data } = state;
      // const functions = SchemaFunctions(schema, state);
      functions(schema, state)
        .stepInit(data?.activeStep, false)
        .then(() => {
          setLoading(false);
        });
    }
  }, [isMounted]);

  const initData = async (): Promise<Partial<SchemaWizardState>> => {
    // setState({ ...state, loading: true });

    const { onCreate, properties, stepOrders } = schema;
    let { allSteps, data, formContext } = state;

    // eslint-disable-next-line no-eval
    allSteps = eval(stepOrders)().map((key: string) => {
      return { key, label: properties[key].title };
    });
    formContext = await formContextFromProps();

    data = Object.assign({}, wizardData);

    let activeStep = allSteps[0].key;
    let activeStepId = 0;
    let onDataReady: (data: any) => Promise<void>;

    if (!wizardData) {
      //Create new wizard data
      onDataReady = async () => {
        if (onCreate) {
          // eslint-disable-next-line no-eval
          await eval(onCreate)(functions(schema, state));
        }
        if (onDataCreate) await onDataCreate(data);
      };
    } else {
      //Load wizard data
      activeStepId = allSteps.findIndex((item) => item.key === data.activeStep);
      if (activeStepId === -1) activeStepId = 0;
      activeStep = allSteps[activeStepId].key;
      onDataReady = async (data: any) => {
        if (onDataLoad) await onDataLoad(data);
      };
    }
    data.activeStep = activeStep;

    onDataReady && (await onDataReady(data));

    return {
      activeStepId,
      allSteps,
      data,
      formContext,
      stepData: data[activeStep],
      stepSchema: properties[activeStep],
    };
  };

  const formContextFromProps = async (): Promise<any> => {
    let formContext: any = { components: context.components };
    Object.keys(schema.definitions || {}).forEach((key) => {
      formContext[key] =
        context[key] || (context as any)[key] || (state as any)[key];
    });

    formContext.functions = formContext.functions || {};
    const fnProps: any = functions(schema, state);
    for (const key in fnProps) {
      formContext.functions[key] = fnProps[key];
    }

    if (onFormContext) formContext = await onFormContext(formContext);
    return formContext;
  };

  const updateFormContext = async (): Promise<any> => {
    let { formContext } = state;
    formContext.components = context.components;
    Object.keys(schema.definitions || {}).forEach((key) => {
      formContext[key] =
        context[key] || (context as any)[key] || (state as any)[key];
    });

    formContext.functions = formContext.functions || {};
    const fnProps: any = functions(schema, state);
    for (const key in fnProps) {
      formContext.functions[key] = fnProps[key];
    }

    if (onFormContext) formContext = await onFormContext(formContext);
    return formContext;
  };

  const toggleSignModal = () => {
    setShowSignModal(!showSignModal);
  };

  const dataCommit = async (data: any, signature?: any) => {
    try {
      onDataCommit && (await onDataCommit(data, signature));
    } catch (error) {
      try {
        onErrorCommit && (await onErrorCommit(error));
      } finally {
        setError((error as Error).message);
      }
    }
  };

  const showError = async (error: any) => {
    setError(typeof error === "string" ? error : error.message);
    setTimeout(hideError, 20_000);
  };

  const hideError = () => {
    setError("");
  };

  const handleStepError = async (error: any) => {
    setState({ ...state, nextStepId: state.activeStepId, dataSaving: false });
    await showError(error);
  };

  const handleStepChange = async (nextStepId: number) => {
    setState({
      ...state,
      nextStepId,
      dataSaving: true,
      noErrorCheck: nextStepId < state.activeStepId,
    });
  };

  const handleStepSave = async (
    // nextStepId: number,
    activeStep: string,
    activeStepData: Record<string, any>
  ) => {
    setLoading(true);

    try {
      await updateFormContext();
      const { onStepFinish, onFinish } = schema;
      const { data, nextStepId, stepSaveQueue } = state;

      // setState({ nextStepId });

      data.activeStep = activeStep;
      data[activeStep] = deleteEmptyValues(activeStepData);
      stepSaveQueue.add(activeStep);

      if (nextStepId < allSteps.length) {
        onStepFinish &&
          // eslint-disable-next-line no-eval
          (await eval(onStepFinish)(functions(schema, state), activeStep));
      } else {
        // eslint-disable-next-line no-eval
        onFinish && (await eval(onFinish)(functions(schema, state)));
      }

      // await handleStepSave(activeStep);
      if (onStepSave) {
        try {
          for (const stepName of Array.from(stepSaveQueue)) {
            await onStepSave(stepName, data[stepName], activeStep);
          }
        } catch (error) {
          showError(error);
        }
      }
      stepSaveQueue.clear();
      // setState({...state, data});

      await stepSaveEnd();
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    }
  };

  const stepSaveEnd = async () => {
    const { properties } = schema;
    const { data, nextStepId } = state;

    if (nextStepId < allSteps.length) {
      const stepKey = allSteps[nextStepId].key;

      const stepInit = functions(schema, state).stepInit;
      await stepInit(stepKey, false);

      setState({
        ...state,
        activeStepId: nextStepId,
        dataSaving: false,
        stepSchema: properties[stepKey],
        stepData: data[stepKey],
      });
      setLoading(false);
    } else {
      onDataFinish && (await onDataFinish(data));
      setState({ ...state, dataSaving: false });
      setLoading(false);
      setWizardStatus(WizardStatus.SIGN);
    }
  };

  const handleStepUpdate = async (stepKey: string, stepValues: any) => {
    const { data } = state;
    try {
      data[stepKey] = stepValues;
      onStepUpdate && (await onStepUpdate(stepKey, data[stepKey]));
      setState({
        ...state,
        data,
        stepData: data[stepKey],
      });
    } catch (error: any) {
      showError(error);
    }
  };

  const handleBackToEdit = async () => {
    setLoading(true);

    const { data } = state;
    const stepKey = allSteps[activeStepId].key;
    const stepData = data[stepKey];
    setState({ ...state, stepData });
    onBackToEdit && (await onBackToEdit());
    setWizardStatus(WizardStatus.EDIT);

    setLoading(false);
  };

  const handleSelectKey = async (
    encodedKey: any,
    signer: any,
    resetPrivateKey: any,
    onDataToSign: any
  ): Promise<void> => {
    const { getDataToSign } = schema;
    const { data } = state;

    try {
      const certBuffer = await signer.execute(
        "GetCertificate",
        encodedKey.issuer,
        encodedKey.serial
      );
      const certBase64 = await signer.execute("Base64Encode", certBuffer);
      await checkCertificate({ data: certBase64 });

      const signData = await onGetDataToSign(
        data,
        // eslint-disable-next-line no-eval
        getDataToSign ? eval(getDataToSign) : undefined
      );

      let _signMethod, dataToSign;
      if (Array.isArray(signData)) {
        _signMethod = signMethod || SignMethod.HASH;
        dataToSign = signData;
      } else {
        _signMethod = signData.signMethod || SignMethod.DATA;
        dataToSign = signData.dataToSign;
      }

      let signature;
      // try {
      if (_signMethod === SignMethod.HASH) {
        const savedSignatures = onGetSavedSign
          ? await onGetSavedSign(dataToSign)
          : undefined;
        signature = await signManifestV1(dataToSign, signer, savedSignatures);
      } else {
        signature = await signManifestV2(dataToSign, signer);
      }
      await dataCommit(data, signature);

      setShowSignModal(false);
    } catch (error: any) {
      try {
        onErrorSign && (await onErrorSign(error));
      } finally {
        setShowSignModal(false);
        setError(error.message);
      }
    } finally {
      resetPrivateKey();
    }
  };

  const {
    activeStepId,
    allSteps,
    dataSaving,
    formContext,
    noErrorCheck,
    stepData,
    stepSchema,
  } = state;

  return isMounted ? (
    <Fragment>
      {wizardStatus === WizardStatus.EDIT && (
        <WizardStepper
          classes={classes}
          setId={(elmentName) => setId(`stepper-${elmentName}`)}
          t={t}
          activeStepId={activeStepId}
          allSteps={allSteps}
          context={formContext}
          dataSaving={dataSaving}
          finishButtonText={finishButtonText || t("FINISH")}
          loading={loading}
          noErrorCheck={noErrorCheck}
          onError={handleStepError}
          onStepChange={handleStepChange}
          onStepCheck={onStepCheck}
          onStepSave={handleStepSave}
          onStepUpdate={handleStepUpdate}
          // onValueChange={this.handleValueChange}
          stepData={stepData}
          stepSchema={stepSchema}
        />
      )}
      {!loading && wizardStatus === WizardStatus.SIGN && (
        <Signing
          t={t}
          classes={classes}
          setId={(elmentName) => setId(`signing-${elmentName}`)}
          onSelectKey={handleSelectKey}
          onBackToEdit={handleBackToEdit}
          onToggleSignModal={toggleSignModal}
          preview={signPreview}
          showSignModal={showSignModal}
        />
      )}
      <Snackbar
        id={setId("error")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={!!error}
        message={<span id="message-id">{error}</span>}
        action={[
          <Button
            key="close-error"
            color="yellow"
            size="small"
            onClick={hideError}
            // className={classes.smallButton}
          >
            OK
          </Button>,
        ]}
      />
    </Fragment>
  ) : null;
  // }
};

export default translate("SchemaWizard")(
  compose(withStyles(schemaWizardStyles))(SchemaWizard)
);
