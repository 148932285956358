import { BaseItem } from "../../../../components/RenderControl";

export function hideFields<T>(fields: BaseItem<T>[], keys: Array<keyof T>) {
  return fields.map((item) => {
    if (keys.indexOf(item.name) === -1) {
      return item;
    }
    return {
      ...item,
      visible: false,
    };
  });
}

export function showFields<T>(fields: BaseItem<T>[], keys: Array<keyof T>) {
  return fields.map((item) => {
    if (keys.indexOf(item.name) === -1) {
      return item;
    }
    return {
      ...item,
      visible: true,
    };
  });
}
