import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Menu } from "@material-ui/icons";
import {
  withStyles,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
} from "@material-ui/core";

import headerStyle from "../../variables/styles/headerStyle";
import setComponentsId from "../../helpers/setComponentsId";
import HeaderLinks from "./HeaderLinks";
import { SystemMessage } from "./SystemMessage";
import { requestSystemMessage } from "../../actions";

const setId = setComponentsId("header");

const Header = ({ classes, handleDrawerToggle }) => {
  const [systemMessage, setSystemMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const res = await requestSystemMessage();

      if (res && res.text) {
        setSystemMessage(res.text);
      }
    };

    fetchData();
  }, []);

  return (
    <AppBar className={classes.appBar} position="static" id={setId("")}>
      <Toolbar id={setId("toolbar")}>
        <div className={classes.flex} id={setId("links-wrap")}>
          <SystemMessage text={systemMessage} />
        </div>
        <Hidden smDown={true} implementation="css">
          <HeaderLinks
            setId={(elementsName) => setId(`links-hidden-${elementsName}`)}
          />
        </Hidden>

        <Hidden mdUp={true}>
          <IconButton
            variant="contained"
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle.bind(this)}
            id={setId("open-icon")}
          >
            <Menu id={setId("menu")} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  // color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(headerStyle)(translate("Header")(Header));
