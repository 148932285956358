import React from "react";
import PropTypes from "prop-types";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Panel = ({
  expanded,
  panelNumber,
  children,
  toggleExpanded,
  setId,
  classes,
  title,
}) => (
  <ExpansionPanel
    expanded={expanded === panelNumber}
    onChange={toggleExpanded}
    id={setId("")}
  >
    <ExpansionPanelSummary id={setId("open")} expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading} id={setId("title")}>
        {title}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails
      className={classes.panelContent}
      id={setId("content")}
    >
      {children}
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

Panel.propTypes = {
  expanded: PropTypes.number.isRequired,
  panelNumber: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
};

export default Panel;
