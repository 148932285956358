import React from "react";
import RegisterChip from "./RegisterChip";

const AdjudicationEnd = (props) => (
  <RegisterChip
    title="ADJUCATION_START"
    valueKey="adjudication_date_start"
    valueType="string"
    {...props}
  />
);

export default AdjudicationEnd;
