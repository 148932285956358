import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Toolbar, withStyles } from "@material-ui/core";

import DownshiftMultiple from "../../components/DownshiftMultiple";
import { Button } from "../../components";
import styles from "../../variables/styles/templateBuilder";

import { parseTemplate, strignifyTemplate } from "../../helpers/searchTemplate";

class TemplateBuilder extends React.Component {
  state = {
    parsedSearch: parseTemplate(this.props.dataSource.search || ""),
  };

  handleClear = () => {
    const { dataSource } = this.props;
    dataSource.search = "";
    this.setState({ parsedSearch: parseTemplate(dataSource.search) });
  };

  handleSearch = () => {
    const { dataSource, load } = this.props;
    const { parsedSearch } = this.state;
    dataSource.search = strignifyTemplate(parsedSearch);
    load && load();
  };

  handleChange = (type) => (selectedItem) => {
    const { parsedSearch } = this.state;
    this.setState({
      parsedSearch: {
        ...parsedSearch,
        [type]: selectedItem,
      },
    });
  };

  render() {
    const { t, classes } = this.props;
    const { parsedSearch } = this.state;
    return (
      <Fragment>
        <DownshiftMultiple
          onChange={this.handleChange("all")}
          selectedItem={parsedSearch.all}
          classes={{ inputRoot: classes.input }}
          fullWidth={true}
          label={t("ALL_WORDS_OR_PHRASES")}
          placeholder={t("ALL_WORDS_OR_PHRASES_PLACEHOLDER")}
          margin="normal"
        />
        <DownshiftMultiple
          onChange={this.handleChange("any")}
          selectedItem={parsedSearch.any}
          classes={{ inputRoot: classes.input }}
          fullWidth={true}
          label={t("ANY_WORD_OR_PHRASE")}
          placeholder={t("ANY_WORD_OR_PHRASE_PLACEHOLDER")}
          margin="normal"
        />
        <DownshiftMultiple
          onChange={this.handleChange("noOne")}
          selectedItem={parsedSearch.noOne}
          classes={{ inputRoot: classes.input }}
          fullWidth={true}
          label={t("NO_ONE_WORD_OR_PHRASE")}
          placeholder={t("NO_ONE_WORD_OR_PHRASE_PLACEHOLDER")}
          margin="normal"
        />
        <Toolbar className={classes.toolbar}>
          <div className={classes.flex} />
          <Button color="transparent" onClick={this.handleClear}>
            {t("CLEAR")}
          </Button>
          <Button onClick={this.handleSearch}>{t("SEARCH")}</Button>
        </Toolbar>
      </Fragment>
    );
  }
}

TemplateBuilder.propTypes = {
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  load: PropTypes.func,
};

TemplateBuilder.defaultProps = {
  load: undefined,
};

const styled = withStyles(styles)(TemplateBuilder);
export default translate("SearchPage")(styled);
