import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import cx from "classnames";
import { withStyles } from "@material-ui/core";

import typographyStyle from "../../variables/styles/typographyStyle.jsx";

const Success = ({ classes, children, setId, ...rest }) => (
  <a
    {...rest}
    className={cx(classes.defaultFontStyle, classes.successText)}
    id={setId("")}
  >
    {children}
  </a>
);

Success.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  setId: PropTypes.func,
};

Success.defaultProps = {
  children: <span />,
  setId: setComponentsId("typography-success"),
};

export default withStyles(typographyStyle)(Success);
