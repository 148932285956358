import AttachList from "../../Attach/AttachList";
import PdfDocument from "../../PDF";
import { FC } from "react";
import { AttachInfo, Document } from "../types";
import {
  requestAttachPreview,
  downloadDocumentAttach,
} from "../../../actions/claim";

export interface DocumentPreviewProps {
  pdf: any;
  doc: any;
  document: Document;
  setId: (s: string) => string;
}

const DocumentPreview: FC<DocumentPreviewProps> = ({
  pdf,
  doc,
  document,
  setId,
}) => {
  const { attaches } = document;

  const handleRequestPreview = (attach: AttachInfo) => async () => {
    return requestAttachPreview(document?.id, attach.attachId, attach);
  };

  const handleDownload = (attach: AttachInfo) => async () => {
    return await downloadDocumentAttach(document?.id, attach.attachId, attach);
  };

  return (
    <div>
      <PdfDocument
        pdf={pdf}
        doc={doc}
        setId={(elementName) => setId(`pdf-dopcument-${elementName}`)}
      />
      {attaches && (
        <AttachList
          attaches={attaches}
          handleDownload={handleDownload}
          requestPreview={handleRequestPreview}
          needTitle
          viewDocument={document}
        />
      )}
    </div>
  );
};

export default DocumentPreview;
