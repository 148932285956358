export default {
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    minHeight: 64,
    display: "flex",
    position: "relative",
    alignItems: "center",
    paddingRight: 24,
    "@media (max-width:700px)": {
      // eslint-disable-line no-useless-computed-key
      display: "block",
    },
  },
  buttonLink: {
    "&, &:hover": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  dialogContentWrappers: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "24px 15px 20px",
    },
  },
  historyCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "14px 15px 10px",
    "@media (max-width:767px)": {
      padding: "24px 15px 20px",
    },
  },
};
