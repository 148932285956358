import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CourtIdFilterChip = ({ t, value, courts, ...rest }) => (
  <Chip
    label={`${t("CASE_COURT")}: ${
      (courts || []).find(({ id }) => id === value).name
    }`}
    {...rest}
  />
);

CourtIdFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CourtIdFilterChip.defaultProps = {
  value: "",
  courts: [],
};

const translated = translate("ClaimList")(CourtIdFilterChip);
export default connect(({ dictionary: { courts } }) => ({ courts }))(
  translated
);
