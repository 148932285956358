import DataSource from "../../../helpers/dataSource";

import {
  caseDataSource,
  caseDocDataSource,
  procDocDataSource,
  myProcDocDataSource,
  myCaseDocDataSource,
  myMemberRolesDataSource,
} from "../../../reducers/cases";

const procDataSource = DataSource("api/proceedings/cases");
caseDocDataSource.setValue("storeName", "caseDocuments");
procDocDataSource.setValue("storeName", "procDocuments");
myProcDocDataSource.setValue("storeName", "myProcDocuments");
myCaseDocDataSource.setValue("storeName", "myCaseDocuments");

myMemberRolesDataSource.setValue("storeName", "myMemberRoles");
myMemberRolesDataSource.setValue("count", 500);

const getSourceParams = (source) =>
  Object.keys(source).reduce((acc, key) => {
    if (typeof source[key] !== "function") {
      acc[key] = source[key];
    }
    return acc;
  }, {});

export default {
  caseDataSource,
  procDataSource,
  caseDocDataSource,
  procDocDataSource,
  getSourceParams,
  myProcDocDataSource,
  myMemberRolesDataSource,
  myCaseDocDataSource,
};
