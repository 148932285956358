export const permissions = [
  { name: "allowShare", textId: 4 },
  { name: "allowCommit", textId: 3 },
  { name: "allowEdit", textId: 2 },
  { name: "allowRead", textId: 1 },
];

export const showPermissions = [
  { name: "allowShare", textId: 4 },
  { name: "allowCommit", textId: 3 },
  { name: "allowEdit", textId: 2 },
  { name: "allowRead", textId: 1 },
  { name: "allowAsvp", textId: 5 },
];

const getPermission = (share) =>
  permissions.find(
    (item) => item.name !== "allowShare" && share[item.name] === 1
  ) || permissions[3];

const getShowPermission = (share) =>
  permissions.find(
    (item) => item.name !== "allowShare" && share[item.name] === 1
  ) || permissions[3];

export const getPermTableText = (t, share) => {
  return `${t(`ABBR_PERMISSION_${getShowPermission(share).textId}`)}${
    share.allowShare === 1 ? t("ABBR_PERMISSION_4") : ""
  }${share.allowAsvp === 1 ? t("ABBR_PERMISSION_5") : ""}`;
};

export const getPermText = (t, share) => {
  return `${t(`ABBR_PERMISSION_${getPermission(share).textId}`)}${
    share.allowShare === 1 ? t("ABBR_PERMISSION_4") : ""
  }${share.allowAsvp === 1 ? t("ABBR_PERMISSION_5") : ""}`;
};

export default getPermission;
