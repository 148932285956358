import React from "react";
import PropTypes from "prop-types";
import RegisterChip from "./RegisterChip";

const JusticeKind = ({ value, ...rest }) => {
  const filter = (kind) => value.includes(kind.id);

  return (
    <RegisterChip
      value={value}
      filter={filter}
      arrayKey="justiceKinds"
      valueKey="justice_kind"
      title="JUSTICE_KIND"
      {...rest}
    />
  );
};

JusticeKind.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

JusticeKind.defaultProps = {
  value: [],
};

export default JusticeKind;
