export default {
  EDIT_USER_HEADER: "Профіль користувача",
  EDIT_USER_HEADER_HINT: "Редагувати профіль",
  SAVE_BUTTON: "Зберегти зміни",
  SAVE_SETTINGS_BUTTON: "Зберегти налаштування",
  SAVE_DOCS: "Зберегти",
  SAVED_DOCS: "Документи завантажено",
  UPLOAD_DOCS: "Завантажити документи",
  DOC_TITLE: "Назва",
  DOC_TYPE: "Тип",
  DOC_CHECKBOX: "Автоматично додавати документ до заяви",
  NAME_HELPER: 'наприклад: "Копія паспорта"',
  FILE_TITLE_DOC: "Назва документу",
  CHECK_LEGAL_ENTITY_BUTTON: "Перевірити в ЄДР",
  LAST_NAME_INPUT_LABEL: "Прізвище",
  FIRST_NAME_INPUT_LABEL: "Ім'я",
  MIDDLE_NAME_INPUT_LABEL: "По-батькові",
  CEO_NAME_INPUT_LABEL: "Посада",
  COMPANY_NAME: "Назва організації",
  EMAIL_INPUT_LABEL: "Email",
  PHONE_INPUT_LABEL: "Телефон",
  BIRTHDAY_INPUT_LABEL: "Дата народження",
  REGISTRATION_INPUT_LABEL: "Дата державної реєстрації",
  REGISTRATION_NUMBER_LABEL: "Номер державної реєстрації",
  BIRTHDAY_INPUT_ERROR: "Введіть дату у форматі 01.01.2000",
  IPN_INPUT_LABEL: "РНОКПП (раніше ІПН) або серія та номер паспорта",
  EMAIL_EMPTY_ERROR: "Введіть email у форматі test@email.com",
  PHONE_EMPTY_ERROR: "Введіть номер телефону у форматі 380XXXXXXXX",
  PROFILE_SAVED: "Профіль збережено",
  SAVED: "Зміни збережено",
  TWO_FACTOR_AUTH_ENABLE: "Увімкнути двоетапну перевірку при вході",
  TWO_FACTOR_AUTH_DISABLE: "Вимкнути двоетапну перевірку при вході",
  EMAIL_ERROR: "Некоректна адреса Email",
  NOT_CHANGE_EMAIL_ERROR: "Ви ввели ту саму адресу",
  DUPLICATE_EMAIL_ERROR:
    "Ця електронна адреса  вже зареєстрована за їншим аккаунтом",
  EMAIL_DIALOG_TITLE: "Зміна адреси Email",
  EMAIL_DIALOG_TEXT:
    "Введіть нову адресу. На неї вам буде відправлено лист з кодом підтвердження:",
  TEXT_WAIT_FOR_CODE:
    "Будь ласка, зачекайте на лист та введіть код у поле нижче:",
  SEND_MAIL: "Надіслати лист",
  VALIDATION_FALSE: "Код не пройшов перевірку",
  CODE_INPUT_LABEL: "Код підтвердження",
  CODE_COATDU_LABEL: "Код КОАТУУ",
  CODE_COATDU_HELPER:
    "Код населеного пункту, Класифікатор Об'єктів Адміністративно-Територіального Устрою України (КОАТУУ)",
  VERIFY_CODE: "Підтвердити",
  RESEND_CODE: "Надіслати лист ще раз",
  SEND_CODE: "Надіслати лист",
  COMPANY_NAME_INPUT_LABEL: "Юридична особа",
  SHORT_NAME: "Коротка назва",
  EDRPOU_INPUT_LABEL: "код РНОКПП/ЄДРПОУ",
  PROFILE_PANEL_TITLE: "Профіль",
  DOCUMENT_PANEL_TITLE: "Мої документи",
  PASSPORT_TITLE: "Паспорт",
  PASSPORT_SERIAL: "Серія",
  PASSPORT_NUMBER: "Номер документу*",
  PASSPORT_NUMBER_HELPER: "Обов'язкове поле, від 6 до 9 цифр",
  REGISTRY_NUMBER:
    "Унікальний номер запису в Єдиному державному демографічному реєстрі (запис №)",
  ISSUE_DATE: "Дата видачі*",
  ISSUE_DATE_HELPER: "Обов'язкове поле",
  ISSUE_BY: "Орган, що видав*",
  ISSUE_BY_HELPER: "Обов'язкове поле",
  VALID_DATE: "Дійсний до",
  UPLOAD: "Завантажити відсканований документ",
  DOWNLOAD_ERROR: "На даний момент немає можливості скачати документ",
  INDIVIDUAL_ENTREPRENEUR: "Фізична особа–підприємець",
  PHONE_VALIDATION_NEEDED: "Ви не підтвердили ваш телефон. {{actions}}",
  VALIDATE_PHONE: "Підтвердити",
  VALIDATE_PHONE_TITLE: "Підтвердження номера телефона.",
  VALIDATE_PHONE_MESSAGE:
    'На ваш телефон {{phone}} вислано код. Для підтвердження введіть його у поле нижче і натисніть кнопку "Підтвердити"',
  CANCEL: "Відміна",
  VALIDATE: "Підтвердити",
  VALIDATE_PHONE_SUCCESS: "Перевірка номеру успішна.",
  CLOSE: "Закрити",
  ADDRESS: "Адреса",
  LEGAL_ADDRESS: "Адреса фактичного місцезнаходження",
  REQUIRED_FIELDS: "* - заповнювати обов'язково",
  ADDRESS_HELPER:
    "Введіть область, район, місто (село, селище), вул (бульв. провул, узвіз тощо), буд, кв, поштовий індекс.",
  SELF_INFORMATION: "Особисті дані",
  INTERFACE: "Налаштування",
  MY_PROFILE_DOCS: "Мої документи",
  MOBILE_DEVICES: "Мобільні пристрої",
  SHOW_ATTACHES_PREVIEW: "Відображати превью вкладень за замовчуванням",
  DONT_GET_MESSAGE_AS_MEMBER: "Не отримувати повідомлення як учасник справи",
  DONT_GET_MESSAGE_AS_REPRESENTATIVE:
    "Не отримувати повідомлення як представник",
  GET_MESSAGE_ONLY_PROCEDURAL_DOCUMENTS:
    "Отримувати повідомлення тільки по процесуальним документам",
  GET_DOCUMENTS_AS_ATTACHS_AT_EMAIL_DOCUMENTS:
    "Отримувати документи як вкладення в email повідомленнях",
  IS_ADVOCATE: "Адвокат",
  CERTIFICATE_NUMBER: "Номер свідоцтва*",
  CHECK: "Перевірити",
  CHECKED: "Перевірено",
  CERTIFICATE_HAS_BEEN_SUSPENDED: "Діяльність сертифіката припинена",
  NO_INFORMATION_WAS_FOUND_FOR_THE_CERTIFICATE:
    "Інформації за вказаним сертифікатом не знайдено",
  NEED_ACCEPT_ADVOCATE:
    "Збереження неможливо. Будь ласка, підтвердіть ліцензію адвоката",
  NEED_ACCEPT_EMAIL: "Збереження неможливо. Будь ласка, підтвердіть email",
  NEED_ACCEPT_PHONE: "Збереження неможливо. Будь ласка, введіть номер телефону",
  ADVOCATE_CERT_VALIDATION: "Валідація свідоцтва адвоката за реєстром НААУ",
  APP_NAME: "Назва додатку",
  APP_VERSION: "Версія додатку",
  DEVICE_MODEL: "Модель пристрою",
  DEVICE_NAME: "Назва пристрою",
  OS_VERSION: "Назва та версія операційної системи",
};
