import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GridListTile, GridListTileBar, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress";
import setComponentsId from "../../helpers/setComponentsId";

import attachesStyles from "../../variables/styles/attaches";
import attachesWizardStep from "../../variables/styles/attachesWizardStep";

import getAttachStates from "../../helpers/getAttachStates";
import getAttachName from "../../helpers/getAttachName";
import downloadBase64Attach from "../../helpers/downloadBase64Attach";

import PreviewDialog from "./PreviewDialog";
import PreviewButton from "./PreviewButton";

const Attach = (props) => {
  const [state, setState] = useState({
    busy: false,
    preview: "",
    attempts: 0,
    doc: null,
    itIsImage: false,
    itIsPDF: false,
    itIsBinary: false,
    openDialog: false,
    itIsGoogleViewDoc: false,
    itIsVideo: false,
    unknownFormat: false,
    showPreview: false,
    type: "",
    timerId: null,
    url: "",
    itIsHTML: false,
    file: null,
    format: "",
    text: "",
  });

  const {
    classes,
    handleDelete,
    name,
    fileName,
    userFileName,
    style,
    setId,
    downloadCallback,
  } = props;
  const { busy } = state;
  const [isDownload, setIsDownload] = useState(false);

  const toggleDialog = () => {
    setState({ ...state, openDialog: !state.openDialog });
  };

  const setDoc = () => {
    getAttachStates(props, state, setState);
  };

  useEffect(() => {
    if (state.openDialog) {
      setDoc();
    }
  }, [state.openDialog]);

  useEffect(() => {
    if (isDownload && (state.file || state.doc || state.preview)) {
      const { file } = state;
      const { name: propsName, fileName, userFileName } = props;
      try {
        downloadBase64Attach(
          {
            propsName,
            fileName,
            userFileName,
          },
          file
        );
      } catch (err) {
        console.log("Download error: ", err);
      }
      setIsDownload(false);
      downloadCallback(false);
    }
  }, [isDownload, state.file]);

  const handleDownload = () => {
    downloadCallback(true);
    if (!state.file && !state.doc && !state.preview) {
      setDoc();
    }
    setIsDownload(true);
  };

  return (
    <Fragment>
      <GridListTile
        className={classes.gridItem}
        style={style}
        id={setId("list-tile")}
      >
        {busy && (
          <LinearProgress
            className={classes.downloadProgress}
            id={setId("linear-progress")}
          />
        )}
        <PreviewButton
          {...props}
          {...state}
          toggleDialog={toggleDialog}
          handleDownload={handleDownload}
        ></PreviewButton>
        <GridListTileBar
          title={getAttachName({
            userFileName,
            name,
            fileName,
          })}
          id={setId("list-tile-button")}
          actionIcon={
            handleDelete && (
              <IconButton onClick={handleDelete} id={setId("delete-button")}>
                <Icon className={classes.deleteAttachBtn}>close</Icon>
              </IconButton>
            )
          }
        />
      </GridListTile>
      <PreviewDialog
        {...state}
        setId={(elementName) => setId(`preview-dialog-${elementName}`)}
        name={getAttachName({
          userFileName,
          name,
          fileName,
        })}
        file={state.file}
        doc={state.doc}
        format={state.format}
        openDialog={state.openDialog}
        setDoc={() => setDoc()}
        toggleDialog={toggleDialog}
        handleDownload={handleDownload}
      />
    </Fragment>
  );
};

Attach.propTypes = {
  setId: PropTypes.func,
  requestPreview: PropTypes.func,
  fileName: PropTypes.string,
  handleDownload: PropTypes.func,
  classes: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
  name: PropTypes.string,
  style: PropTypes.object,
  alwaysPreview: PropTypes.bool,
  url: PropTypes.string,
  contentType: PropTypes.string,
  userFileName: PropTypes.string,
};

Attach.defaultProps = {
  setId: setComponentsId("attach"),
  requestPreview: undefined,
  fileName: "",
  handleDownload: undefined,
  name: "",
  style: {},
  handleDelete: undefined,
  alwaysPreview: true,
  url: "",
  contentType: "",
  userFileName: "",
};

export default withStyles({
  ...attachesStyles,
  ...attachesWizardStep,
})(Attach);
