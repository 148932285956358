import { FC, useEffect, useState } from "react";
import { compose } from "redux";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Chip,
  FormHelperText,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";

import { translate } from "../../helpers/translate";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";

import {
  BaseControlProps,
  ControlSchema,
  defaultT,
  FormSchema,
  FormValues,
  SchemaFormProps,
  schemaFormStyles,
  TabSetItem,
} from "./types";
import StringControl from "./controls/StringControl";
import formControls from "./controls";
import forms from "./forms";
import { isEmptyValue, SchemaDataBuilder } from "./helpers";

interface SchemaFormState {
  loading: boolean;
  path: string;
  tabId?: number;
  data: FormData;
  error?: string;
  schema: FormSchema;
}

const clearValues = (values: any) => {
  for (const key in values) {
    values[key] = undefined;
  }
};

const setValue = (values: any, key: string, value: any) => {
  values[key] = value;
};

const SchemaForm: FC<SchemaFormProps> = ({
  classes,
  context,
  data,
  dataSaving,
  name,
  noErrorCheck,
  onClose,
  onDataCheck,
  onDataSave,
  onDataUpdate,
  onError,
  onValueChange,
  onValueFinish,
  setId,
  schema,
  t,
}) => {
  const [state, setState] = useState<SchemaFormState>({
    loading: true,
    path: "",
    data: (schema.type === "array" ? [] : {}) as FormData,
    schema: schema,
  });

  const [isMounted, setIsMounted] = useState(false);
  const [errors, setErrors] = useState<Map<string, string> | null>(null);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      getValuesFromProps().then((newValues) => {
        const tabId = getTabIdFromProps(newValues);
        setState({
          ...state,
          // error,
          loading: false,
          path: name,
          tabId,
          schema,
          data: newValues,
        });
      });
    }
  }, [isMounted]);

  useEffect(() => {
    const { path, data } = state;

    if (dataSaving && path === name) {
      if (noErrorCheck) {
        onDataSave && onDataSave(path, data);
      } else {
        checkDataErrors().then((errors) => {
          if (errors) {
            setErrors(errors);
          } else {
            onDataSave && onDataSave(path, data);
          }
        });
      }
    }
  }, [dataSaving]);

  useEffect(() => {
    if (errors) {
      onError &&
        onError(
          "Не всі обов'язкові реквізити заповнені або не відповідають вимогам."
        );
    }
  }, [errors]);

  const getTabIdFromProps = (values: any): number | undefined => {
    const { tabs } = schema;
    if (!tabs) return undefined;

    const tab = tabs.find((tab) => {
      let isFind = true;
      tab.set.forEach((set) => {
        isFind = (isFind && values && values[set.key] === set.value) || false;
      });
      return isFind;
    });
    return tab?.id || 1;
  };

  const getValue = (key: string, schema: ControlSchema): any => {
    const { data, tabId } = state;
    if (!tabId) return (data as any)[key];
    return schema.tabsIds?.includes(tabId) && (data as any)[key];
  };

  const getValuesFromProps = async (
    cleanBefore: boolean = false
  ): Promise<any> => {
    const builder = new SchemaDataBuilder(
      schema,
      cleanBefore ? undefined : data,
      {
        definitions: context,
      }
    );
    return await builder.build();
  };

  const checkDataOfObject = async (
    values: FormValues,
    schema: FormSchema,
    tabId?: number
  ): Promise<Map<string, string>> => {
    const { required, properties } = schema;
    const errors = new Map<string, string>();

    if (!values) return errors;

    //Check required fields
    (required || []).forEach((key) => {
      const inTab = tabId
        ? properties && properties[key]?.tabsIds?.includes(tabId)
        : true;

      if (inTab && isEmptyValue(values[key])) {
        errors.set(
          key,
          `${(properties && properties[key]?.title) || key} - ${t("REQUIRED")}`
        );
      }
    });

    return errors;
  };

  const checkDataOfArray = async (
    data: FormValues[],
    schema: FormSchema
  ): Promise<Map<string, string>> => {
    const { /*items,*/ minItems } = schema;
    const errors = new Map<string, string>();

    if (minItems && data.length < minItems) {
      errors.set("", "Масив містить елементів менше мінімальної кількості");
    }

    return errors;
  };

  const checkDataErrors = async (): Promise<Map<string, string> | null> => {
    // console.log("SchemaForm.checkDataErrors", this.state);
    const { tabId, data, schema } = state;

    if (!data) return null;
    const errors =
      schema.type === "array"
        ? await checkDataOfArray(data as unknown as FormValues[], schema)
        : await checkDataOfObject(data, schema, tabId);

    if (onDataCheck) await onDataCheck(name, data, errors);

    return errors.size > 0 ? errors : null;
  };

  const handleChildDataSave = async (
    name: string,
    data: FormData,
    errors?: Map<string, string>
  ) => {
    setState({ ...state, data });
    onDataSave && (await onDataSave(name, data));
  };

  const handleChildDataUpdate = async (
    name: string,
    data: FormData,
    errors?: Map<string, string>
  ) => {
    setState({ ...state, data });
    onDataUpdate && (await onDataUpdate(name, data));
  };

  const handleChildValueChange = async (
    formName: string,
    valueKey: string,
    value: any
  ): Promise<void> => {
    await handleValueChange(valueKey, value);
  };

  const handleTabClick = async (tabId: any) => {
    const { tabs } = schema;
    const { data } = state;
    const tab = (tabs || [])[tabId - 1];
    if (tab) {
      for (const item of tab.set) {
        (data as any)[item.key] = item.value;
      }
    }
    setState({ ...state, tabId, data });
  };

  const handleValueChange = async (key: string, value: any) => {
    let { data } = state;
    const { onChangeValue } = schema;
    (data as any)[key] = value;
    if (onChangeValue) {
      // eslint-disable-next-line no-eval
      eval(onChangeValue)(
        // SchemaForm
        {
          clearValues,
          setValue,
        },
        key,
        value,
        data
      );
    }
    onValueChange && (await onValueChange(name, key, value));
    errors && errors.delete(key);
    setState({ ...state, data });
  };

  const handleValueFinish = async (key: string, value: any) => {
    const { data } = state;
    onValueFinish && (await onValueFinish(name, key, value));
    setState({ ...state, data });
  };

  const renderForm = () => {
    let componentName: string = schema.control || "";

    componentName += ".form";
    componentName = componentName
      .split(".")
      .map(capitalizeFirstLetter)
      .join("");

    const FormComponent =
      (context.components ? context.components[componentName] : undefined) ||
      forms[componentName];

    const { data } = state;

    return FormComponent ? (
      <FormComponent
        context={context}
        data={data}
        name={name}
        onError={onError}
        onValueChange={handleChildValueChange}
        onDataSave={handleChildDataSave}
        onDataUpdate={handleChildDataUpdate}
        schema={schema}
        setId={setId}
        t={t}
      />
    ) : (
      <div>{`Invalid component "${componentName}"`}</div>
    );
  };

  const renderFormControl = (
    name: string,
    schema: ControlSchema,
    options: Partial<BaseControlProps<any>>
  ) => {
    let componentName: string | undefined = schema.control;

    if (!componentName) {
      componentName =
        schema.type === "string" && schema.format
          ? schema.format
          : (schema.type as string);
    }
    componentName += ".control";
    componentName = componentName
      .split(".")
      .map(capitalizeFirstLetter)
      .join("");

    // @ts-ignore
    const FormComponent = formControls[componentName] || StringControl;

    const error =
      errors && (options.error || (options.required && !options.value));

    return (
      <FormComponent
        context={context}
        hidden={
          !!schema.hidden ||
          (typeof options.tabId === "number"
            ? !schema.tabsIds?.includes(options.tabId)
            : false)
        }
        name={name}
        error={error}
        errors={options.errors}
        onChange={options.onChange}
        onFinish={options.onFinish}
        path={options.path}
        readOnly={!!schema.readOnly}
        required={options.required}
        schema={schema}
        setId={setId}
        t={t}
        value={options.value}
      />
    );
  };

  // render() {
  const { path, tabId, error } = state;
  const { description, properties, tabs, title } = schema;
  const hasRequired = (schema.required || []).length > 0;

  return isMounted ? (
    <div id={setId(name)}>
      {!error && name === path && (
        <Card className={classes.schemaForm}>
          <CardHeader
            id={setId(`${name}-header`)}
            title={title}
            subheader={description}
          />
          <CardContent id={setId(`${name}-form`)}>
            {tabs && (
              <section id={setId(`${name}-form-tabs`)}>
                {tabs.map((tab) => {
                  const { title, id } = tab;
                  const key = `${name}-form-tabs-${id}`;
                  const isActiveTab = tab.id === tabId;
                  const isClickable = !!tab.set.find(
                    (item: TabSetItem): boolean =>
                      !properties[item.key].readOnly
                  );
                  return (
                    <Chip
                      clickable={isClickable}
                      id={setId(`${name}-form-chip-${key}`)}
                      key={id}
                      label={title}
                      onClick={async (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        isClickable && (await handleTabClick(id));
                      }}
                      className={cx(
                        classes.tab,
                        isActiveTab && classes.activeTab
                      )}
                    />
                  );
                })}
              </section>
            )}
            {schema.control && renderForm()}
            {!schema.control && (
              <Typography id={setId(`${name}-form-content`)}>
                {Object.entries(properties || [])
                  .filter((entry) => entry[1].format !== "function")
                  .map((entry: Record<string, any>) =>
                    renderFormControl(entry[0], entry[1], {
                      error: errors?.has(entry[0]),
                      errors: errors?.get(entry[0]),
                      onChange: handleValueChange,
                      onFinish: handleValueFinish,
                      required: (schema.required || []).includes(entry[0]),
                      path: `${path}-${entry[0]}`,
                      t: t || defaultT,
                      tabId,
                      value: getValue(entry[0], entry[1]),
                    })
                  )}
              </Typography>
            )}
            {hasRequired && (
              <FormHelperText error={!!errors}>
                {`${t("REQUIRED_FIELDS")}`}
              </FormHelperText>
            )}
          </CardContent>
        </Card>
      )}
      {error && (
        <div>
          <Typography>{t("STEP_LOADING_ERROR")}</Typography>
          <Typography>{error}</Typography>
        </div>
      )}
    </div>
  ) : null;
};

export default translate("SchemaForm")(
  compose(withStyles(schemaFormStyles))(SchemaForm)
);
