import diff from "deep-diff";
import { setWizardStates } from "../../../actions/claim";

export function componentDidUpdate() {
  window.scrollTo(0, 0);
}

export function UNSAFE_componentWillReceiveProps({
  match,
  activeStepId,
  stepOrders,
  busy,
  init,
}) {
  if (
    !init &&
    match.params &&
    stepOrders[activeStepId] &&
    match.params.stepId !== stepOrders[activeStepId]
  ) {
    setWizardStates({
      activeStepId: stepOrders.indexOf(match.params.stepId),
      busy: false,
    });
  } else if (activeStepId !== this.props.activeStepId && busy) {
    setWizardStates({ busy: false });
  }
}

export function shouldComponentUpdate({
  wizardStates,
  wizardStates: { newSigners },
  globalPermissions,
  dataIsLoading,
}) {
  return (
    !!diff(this.props.wizardStates, wizardStates) ||
    !!diff(this.props.globalPermissions, globalPermissions) ||
    dataIsLoading !== this.props.dataIsLoading ||
    (newSigners || []).length
  );
}
