import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { DeviceInfo } from "./types";
import DevicesTable from "./DevicesTable";
import setComponentsId from "../../../helpers/setComponentsId";
import AppStoreImg from "../../../assets/img/get_in_apple.png";
import GooglePlayImg from "../../../assets/img/get_in_google.png";
import { Hidden } from "@material-ui/core";
import QRCodeApple from "../../../assets/img/qrcode_apps.apple.com.png";
import QRCodeGoogle from "../../../assets/img/qrcode_play.google.com.png";
import { DataSourceClass } from "../../../helpers/dataSource";

interface Props {
  t: (s: string) => string;
  classes: any;
  dataSource: DataSourceClass;
  devices: DeviceInfo[];
}

const DevicesLayout: React.FC<Props> = ({
  t,
  devices,
  classes,
  dataSource,
}) => {
  return (
    <div className={classes.devicesLayoutContainer}>
      <DevicesTable
        t={t}
        list={devices}
        changeCount={() => null}
        createSortHandler={() => null}
        // @ts-ignore
        dataSource={dataSource}
        pagination={() => null}
        setId={setComponentsId("devices-table")}
      />
      <div className={classes.linksContainer}>
        <div className={classes.linkWrapper}>
          <Hidden smDown implementation="css">
            <img
              className={classes.qrcode}
              src={QRCodeApple}
              alt="download in apple store"
            />
          </Hidden>
          <a
            className={classes.link}
            href="https://apps.apple.com/ua/app/%D1%94%D1%81%D1%83%D0%B4/id1578245779?l=uk"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <img height={45} src={AppStoreImg} alt="download in apple store" />
          </a>
        </div>
        <div className={classes.linkWrapper}>
          <Hidden smDown implementation="css">
            <img
              className={classes.qrcode}
              src={QRCodeGoogle}
              alt="download in apple store"
            />
          </Hidden>
          <a
            className={classes.link}
            href="https://play.google.com/store/apps/details?id=com.floor12apps.ecourt&gl=UA"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <img
              height={45}
              src={GooglePlayImg}
              alt="download in google market"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  devicesLayoutContainer: {
    display: "flex",
    flexDirection: "column",
  },
  linksContainer: {
    display: "flex",
    paddingTop: 16,
    justifyContent: "center",
  },
  linkWrapper: {
    marginRight: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {},
  qrcode: {
    height: 175,
    width: 175,
    paddingBottom: 8,
  },
} as const;

export default compose(withStyles(styles))(DevicesLayout);
