import { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";

import cx from "classnames";
import { IAuth } from "../../../types/auth.interface";
import { Button } from "../../../components";
import humanDateFormat from "../../../helpers/humanDateFormat";

interface Props {
  toggleDialog: (val: string) => any;
  classes: any;
  t: (val: string) => any;
  setId: (val: string) => any;
  auth?: IAuth;
  openDeleteDialog: boolean;
  changedShare: any;
  handleDelete_Edr_Recipe: (id: string, deleteChildren?: boolean) => void;
}

const EDRRecipeConfirmDialog: FC<Props> = ({
  toggleDialog,
  classes,
  t,
  setId,
  auth,
  openDeleteDialog,
  changedShare,
  handleDelete_Edr_Recipe,
}) => {
  return (
    <>
      <Dialog
        open={openDeleteDialog}
        fullWidth={true}
        onClose={toggleDialog("confirmEDRRecipe")}
        id={setId("wrapper")}
        className={cx(classes.dialog, classes.fullWidth)}
      >
        <DialogContent
          className={cx(classes.content, classes.dialogContentWrappers)}
        >
          <Typography
            variant="subtitle1"
            className={classes.heading}
          ></Typography>
          <Typography variant="subtitle1" className={classes.body1}>
            <p>
              Буде анульовано існуючу виписку з ЄДР від{" "}
              {changedShare?.allowedByName}{" "}
              {humanDateFormat(changedShare?.updatedAt)} на{" "}
              {changedShare?.userName}.
            </p>
            Також можливо анулювати всі довіреності, видані шляхом передоручення
            вказаної виписки.
          </Typography>
        </DialogContent>
        <DialogActions
          className={cx(classes.actions, classes.dialogContentWrappers)}
        >
          <Button
            onClick={toggleDialog("confirmEDRRecipe")}
            color="transparent"
            mr={2}
          >
            {t("CANCEL_ACTION")}
          </Button>
          <Button
            onClick={() => handleDelete_Edr_Recipe(changedShare?.id, false)}
            color="yellow"
          >
            {t("REJECT_EDR_RECIPE")}
          </Button>
          <Button
            onClick={() => handleDelete_Edr_Recipe(changedShare?.id, true)}
            color="danger"
          >
            {t("REJECT_EDR_RECIPE_WITH_SHARES")}
          </Button>
          {/* <Button onClick={toggleDialog("addShare")} color="yellow">
            {t("CONTINUE")}
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EDRRecipeConfirmDialog;
