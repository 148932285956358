import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import { translate } from "react-translate";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Button } from "../../components";

const getTitle = (t, checked, trash) => {
  if (trash && checked.length === 1) return t("DELETE_PERMANENT_CLAIM_TITLE");
  if (trash)
    return t("DELETE_PERMANENT_CLAIMS_TITLE", { count: checked.length });
  if (checked.length === 1) return t("DELETE_CLAIM_TITLE");
  return t("DELETE_CLAIMS_TITLE", { count: checked.length });
};

const getText = (t, checked, trash) => {
  if (trash) return t("CAN_NOT_UNDONE");
  if (checked.length === 1) return t("DELETE_CLAIM_TEXT");
  return t("DELETE_CLAIMS_TEXT");
};

const DeleteClaimDialog = ({
  t,
  openDeleteClaimDialog,
  busy,
  toggleDeleteDialog,
  handleClaimDelete,
  trash,
  setId,
  classes,
  checked,
}) => (
  <Dialog
    open={openDeleteClaimDialog}
    onClose={toggleDeleteDialog}
    aria-labelledby={setId("title")}
    aria-describedby={setId("content-text")}
    id={setId("")}
    className={classes.dialog}
  >
    <DialogTitle id={setId("title")} className={classes.dialogContentWrappers}>
      {getTitle(t, checked, trash)}
    </DialogTitle>
    <DialogContent
      id={setId("content")}
      className={classes.dialogContentWrappers}
    >
      <DialogContentText id={setId("content-text")}>
        {getText(t, checked, trash)}
      </DialogContentText>
    </DialogContent>
    <DialogActions
      id={setId("actions")}
      className={classes.dialogContentWrappers}
    >
      <Button
        onClick={handleClaimDelete}
        color="danger"
        disabled={busy}
        id={setId("delete-button")}
        setId={(elmentName) => setId(`delete-${elmentName}`)}
      >
        {trash ? t("TOTAL_DELETE") : t("DELETE")}
      </Button>
      <Button
        onClick={toggleDeleteDialog}
        color="yellow"
        disabled={busy}
        autoFocus={true}
        id={setId("cancel-button")}
        setId={(elmentName) => setId(`cancel-${elmentName}`)}
      >
        {t("CANCEL")}
      </Button>
    </DialogActions>
  </Dialog>
);

DeleteClaimDialog.propTypes = {
  trash: PropTypes.bool.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  openDeleteClaimDialog: PropTypes.bool.isRequired,
  handleClaimDelete: PropTypes.func.isRequired,
  toggleDeleteDialog: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  checked: PropTypes.array.isRequired,
};

DeleteClaimDialog.defaultProps = {
  setId: setComponentsId("delete-claim-dialog"),
};

export default translate("ClaimList")(DeleteClaimDialog);
