import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import config from "../../config";

const { statisticLink } = config();

class Statistics extends Component {
  form = null;

  componentDidMount() {
    const { form } = this;
    if (form) form.submit();
  }

  render() {
    const { auth } = this.props;
    const params = {
      NQUser: auth.info && auth.info.ipn,
      NQPassword: auth.info && auth.info.userId,
      Locale: "",
      accessMode: "",
    };
    return (
      <form
        ref={(ref) => {
          this.form = ref;
        }}
        style={{ display: "none" }}
        action={statisticLink}
        method="POST"
      >
        {Object.keys(params).map((key) => (
          <input type="hidden" name={key} key={key} value={params[key]} />
        ))}
      </form>
    );
  }
}

Statistics.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ authorization: auth }) => ({ auth });

export default connect(mapStateToProps)(Statistics);
