import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  withStyles,
  Toolbar,
  IconButton,
  ButtonBase,
  Divider,
} from "@material-ui/core";

import { FilterList } from "@material-ui/icons";

import SplitPane from "react-split-pane";

import DataTable from "../../components/DataTable";
import FilterInput from "../../components/FilterInput";

import waiter from "../../helpers/waitForAction";
import setComponentsId from "../../helpers/setComponentsId";
import {
  getSplitPosition,
  setSplitPosition,
} from "../../helpers/splitPosition";
import searchStyle from "../../variables/styles/searchStyle";

import { isLoggedInCompletely } from "../../actions/auth";
import { requestDictionary, checkRole } from "../../actions/search";

import cx from "classnames";

import "../../assets/css/search-page.css";

import FilterMenu from "./components/FilterMenu";
import FilterFields from "./components/FilterFields";
import Document from "./components/Document";

import updateFilters from "./decorators/updateFilters";

import tableColumns from "./variables/tableColumns";

const setId = setComponentsId("search");
const SEARCH_INTERVAL = 1000;

class RegistryList extends React.Component {
  state = { filtersOpen: false, selected: null, loading: true };

  scrollBarRef = null;

  updateFilters = updateFilters.bind(this);

  UNSAFE_componentWillMount = () => {
    const havePermission = checkRole();
    if (havePermission) {
      requestDictionary().then(() => {
        const {
          dataSource,
          history: {
            location: { search },
          },
        } = this.props;
        dataSource.deserialize(search);
        this.setURL();
        dataSource.handleChange = this.dataSourceChange.bind(this);
        dataSource.load().then(() => this.setState({ loading: false }));
      });
    }
  };

  UNSAFE_componentWillReceiveProps({
    history: {
      location: { search },
    },
    auth: { role },
  }) {
    const {
      dataSource,
      auth: { role: prevRole },
    } = this.props;
    if (role !== prevRole && role !== "judge") {
      checkRole();
    }
    if (
      (search || "?is_not_deleted=1&start=0&count=20").slice(1) !==
      dataSource.getQueryString()
    ) {
      dataSource.deserialize(search);
      dataSource.load();
    }
  }

  dataSourceChange(filterName, filterValue) {
    this.updateFilters(filterName, filterValue);
    this.setURL();
  }

  setURL() {
    const {
      history: {
        location: { search },
      },
      dataSource,
    } = this.props;
    if (
      (search || "?is_not_deleted=1&start=0&count=20").slice(1) !==
      dataSource.getQueryString()
    ) {
      this.props.history.push(`/search?${dataSource.getQueryString()}`);
    }
  }

  handleFilterOpen = () => {
    const { filtersOpen } = this.state;
    setSplitPosition("registryMainSplitPos")(!filtersOpen ? 420 : 48);
    this.setState({ filtersOpen: !filtersOpen });
  };
  handleCloseDocument = () => this.setState({ selected: null });

  handleClick(item) {
    const { selected } = this.state;
    if (selected && selected.id === item.id) {
      return;
    }
    this.setState({ selected: item }, () => {
      if (this.scrollBarRef && this.scrollBarRef._container) {
        // eslint-disable-line no-underscore-dangle
        this.scrollBarRef._container.scrollTop = 0; // eslint-disable-line no-underscore-dangle
      }
    });
  }

  clearFilters = () => {
    const { dataSource } = this.props;
    dataSource.reset();
    this.load(true);
  };

  load = (force = false) => {
    const { dataSource, load: propLoad } = this.props;
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    waiter.addAction(
      "dataSourceSearch",
      propLoad || dataSource.load,
      SEARCH_INTERVAL
    );
    force && waiter.run("dataSourceSearch");
    this.forceUpdate();
  };

  render() {
    const { t, classes, list, dataSource } = this.props;

    const { filtersOpen, selected } = this.state;
    const loading = this.props.loading || this.state.loading;

    const showClearFiltersBtn =
      Object.values(dataSource.filters).filter(Boolean).length > 0;
    const size = filtersOpen
      ? getSplitPosition("registryMainSplitPos", 480)
      : 48;
    return (
      <SplitPane
        split="vertical"
        size={size}
        minSize={filtersOpen ? 420 : 48}
        onChange={setSplitPosition("registryMainSplitPos")}
        primary={"first"}
      >
        <main className={classes.content}>
          <Toolbar className={classes.dateRange}>
            {"test"}
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleFilterOpen}
              className={cx(
                classes.menuButton,
                this.state.open && classes.hide
              )}
            >
              <FilterList />
            </IconButton>
            <div className={classes.grow} />
            {showClearFiltersBtn && (
              <ButtonBase
                className={classes.clearFiltersBtn}
                onClick={this.clearFilters}
              >
                {t("CLEAR_ALL_FILTERS")}
              </ButtonBase>
            )}
          </Toolbar>
          <Divider />
          {filtersOpen && (
            <PerfectScrollbar className={classes.withToolbar}>
              <main className={classes.content}>
                <FilterFields
                  setId={setId}
                  dataSource={dataSource}
                  load={this.load}
                  loading={loading}
                />
              </main>
            </PerfectScrollbar>
          )}
        </main>
        <main className={classes.content}>
          <SplitPane
            allowResize={!!selected}
            split="vertical"
            size={selected ? getSplitPosition("registrySplitPos") : "100%"}
            minSize={48}
            onChange={setSplitPosition("registrySplitPos")}
          >
            <main className={classes.content}>
              <Toolbar>
                <FilterInput
                  setId={setId}
                  chips="registry"
                  templates={true}
                  dataSource={dataSource}
                  load={() => this.load(true)}
                  loading={loading}
                  list={list}
                />
                {isLoggedInCompletely() && (
                  <FilterMenu
                    dataSource={dataSource}
                    load={this.load}
                    list={list}
                  />
                )}
              </Toolbar>
              <Divider />
              {(list || loading) && (
                <PerfectScrollbar className={classes.withToolbar}>
                  <DataTable
                    t={t}
                    list={list}
                    loading={loading}
                    columns={tableColumns}
                    selected={selected && selected.id}
                    dataSource={dataSource}
                    handleClick={this.handleClick.bind(this)}
                  />
                </PerfectScrollbar>
              )}
            </main>
            {selected && (
              <Document
                documentId={selected.id}
                highlight={selected.highlight}
                paper={false}
                closeDocument={this.handleCloseDocument}
              />
            )}
            {!selected && <div />}
          </SplitPane>
        </main>
      </SplitPane>
    );
  }
}

RegistryList.propTypes = {
  dataSource: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool,
  load: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  auth: PropTypes.object.isRequired,
};

RegistryList.defaultProps = {
  list: null,
  load: null,
  loading: false,
};

const mapStateToProps = ({
  authorization: auth,
  search: { list, loading, dataSource, courts },
}) => ({
  auth,
  courts,
  list,
  loading,
  dataSource,
});

const translated = translate("SearchPage")(RegistryList);
const styled = withStyles(searchStyle)(translated);
export default connect(mapStateToProps)(styled);
