import React from "react";
import PropTypes from "prop-types";

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import cx from "classnames";

import Checkbox from "@material-ui/core/Checkbox";

const Head = ({
  setId,
  classes,
  ownerList,
  checked,
  onSelectAllClick,
  dataSource,
  createSortHandler,
  t,

  needAllCheckbox,
  checkbox,
  getClasses,
  selectAllCheckbox,
  havePermissionsList,
  tableFields,
}) => (
  <TableHead
    id={setId("table-head")}
    className={selectAllCheckbox ? classes.mobileBlock : classes.onlyBigScreen}
  >
    <TableRow id={setId("table-head-row")} className={classes.mobileGrid}>
      {selectAllCheckbox && ownerList ? (
        <TableCell
          padding="checkbox"
          id={setId("table-head-checkbox")}
          className={
            selectAllCheckbox || checkbox
              ? getClasses(classes, selectAllCheckbox || checkbox)
              : ""
          }
        >
          {needAllCheckbox && (
            <Checkbox
              className={classes.checkbox}
              indeterminate={
                checked?.length > 0 &&
                checked?.length < havePermissionsList?.length
              }
              checked={checked?.length > 0}
              onChange={onSelectAllClick}
              disabled={!havePermissionsList?.length}
              color="default"
              id={setId("cell-checkbox")}
            />
          )}
          <div className={classes.onlyMobileScreen}>
            {(needAllCheckbox || selectAllCheckbox) &&
              (selectAllCheckbox?.title
                ? t(selectAllCheckbox.title)
                : t("SELECT_ALL"))}
            {/* {selectAllCheckbox && t(selectAllCheckbox.title)} */}
          </div>
        </TableCell>
      ) : checkbox ? (
        <TableCell
          padding="checkbox"
          id={setId("table-head-checkbox")}
          className={
            selectAllCheckbox || checkbox
              ? getClasses(classes, selectAllCheckbox || checkbox)
              : ""
          }
        />
      ) : (
        ""
      )}
      {tableFields.map((item) => (
        <TableCell
          id={setId(`table-header-cell-${item.key}`)}
          key={`table-header-cell-${item.key}`}
          className={cx(
            getClasses(classes, item),
            classes.textLeft,
            classes.onlyBigScreen
          )}
          title={t(item.headerHint || item.title)}
        >
          {dataSource.sort && item.sort && (
            <TableSortLabel
              id={setId(`table-header-cell-sort-${item.key}`)}
              active={item.key in dataSource.sort}
              direction={dataSource.sort[item.key]}
              onClick={createSortHandler(item.key)}
            >
              {dataSource.sort && item.title ? (
                <span
                  className={cx(
                    getClasses(classes, item),
                    classes.onlyBigScreen,
                    classes.sortTitle,
                    item.key in dataSource.sort && classes.sortTitleEnabled,
                    item.key in dataSource.sort &&
                      item.classNames.map(
                        (cl) => classes[`${cl}-sortTitleEnabled`]
                      )
                  )}
                >
                  {t(item.title)}
                </span>
              ) : (
                ""
              )}
            </TableSortLabel>
          )}
          {!item.sort && item.title ? t(item.title) : ""}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

Head.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object,
  ownerList: PropTypes.bool,
  onSelectAllClick: PropTypes.func,

  createSortHandler: PropTypes.func,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,

  needAllCheckbox: PropTypes.bool.isRequired,
  checkbox: PropTypes.object,
  getClasses: PropTypes.func.isRequired,
  selectAllCheckbox: PropTypes.object,
  havePermissionsList: PropTypes.array.isRequired,
  tableFields: PropTypes.array.isRequired,
};

Head.defaultProps = {
  dataSource: {},
  checked: "",
  checkbox: null,
  selectAllCheckbox: null,
  createSortHandler: undefined,
  ownerList: true,
  onSelectAllClick: undefined,
};

export default Head;
