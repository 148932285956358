const LMK = 0;
const MMK = 1;

/**
 * @param {MouseEvent} e
 */
export const handleESITSClick = async (e) => {
  let target = "_blank";
  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  e.preventDefault();
  const host = "https://wiki-ccs.court.gov.ua/w/Головна_сторінка";
  window.open(host, target);
};
