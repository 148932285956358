import React from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { Icon, IconButton } from "@material-ui/core";
import ReturnAndLocateInTableHOC from "../ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import { IReturnObjectProps } from "../../components/ReturnAndLocateInTableHOC/types";
import { Button } from "../";

export interface ReturnButtonProps extends RouteComponentProps<any> {
  defaultPath?: string;
  iconButton?: boolean;
  onClick?: () => void;
  returnObject: IReturnObjectProps;
  t: (s: string) => string;
}

const ReturnButton: React.FC<ReturnButtonProps> = ({
  defaultPath,
  history,
  iconButton = true,
  location,
  // match,
  onClick,
  returnObject,
  // staticContext,
  t,
}) => {
  const returnData = returnObject.getReturnObject();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else if (returnData || defaultPath) {
      const path = returnData?.path || defaultPath || "";
      history.push(path);
    } else {
      history.goBack();
    }
  };

  if (
    (!returnData && !defaultPath && !onClick) ||
    location.pathname === returnData?.path ||
    location.pathname === "/notifications"
  )
    return <div />;

  if (iconButton) {
    return (
      <Link
        to={{
          //pathname: returnData?.path || defaultPath || "",
          state: { prevPath: location.pathname },
        }}
      >
        <IconButton color="inherit" onClick={handleOnClick}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </Link>
    );
  }

  return (
    <Button
      color="transparent"
      variant="contained"
      // className={classes.mobileButtonFullWidth}
      onClick={handleOnClick}
    >
      <Icon>arrow_back</Icon>&nbsp;&nbsp;
      {t("BACK")}
    </Button>
  );
};
export default ReturnAndLocateInTableHOC(withRouter(ReturnButton));
