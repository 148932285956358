import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { IconButton, withStyles } from "@material-ui/core";

import { Search } from "@material-ui/icons";

import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import StringElement from "../../../components/SchemaForm/elements/StringElement";
import formElements from "./index";

const getDescription = (
  key,
  required,
  description,
  t,
  readOnly,
  signer,
  sample
) => {
  const name = `USER_${key.toUpperCase()}`;
  const translated = t(name);
  const text =
    description || (translated === `Elements.${name}` ? "" : translated);
  let helperText = "";
  if (!!signer && (key === "name" || key === "ipn")) {
    helperText = sample ? `${sample} (${t("USE_SEARCH")})` : t("USE_SEARCH");
  }
  const req =
    !readOnly && required && Array.isArray(required) && required.includes(key)
      ? "*"
      : "";
  return { descriptionText: `${text}${req}`, helperText };
};

const styles = {
  displayFlex: {
    display: "flex",
    maxWidth: 700,
  },
  flex: {
    flex: 1,
  },
  buttonContainer: {
    padding: "15px 0",
  },
};

const getComponent = (control) => {
  if (!control) {
    return StringElement;
  }

  const componentName = control.split(".").map(capitalizeFirstLetter).join("");
  return formElements[componentName] || null;
};

const Element = ({
  handleOpenSelectUserDialog,
  name,
  index,
  t,
  classes,
  formControlProps,
  value,
  signer,
  path,
  handleChange,
  ...props
}) => {
  const properties = props.properties || {};
  const required = props.required || [];
  const errors = props.errors || {};
  const values = typeof value === "object" ? value || {} : {};

  const { control, readonly, description, sample, type } = properties[name];
  if (!control && type === "boolean") return null;
  const Component = getComponent(control, type);
  const { descriptionText, helperText } = getDescription(
    name,
    required,
    description,
    t,
    readonly,
    signer,
    sample
  );
  const id = `${path}-${name}`.replace(/\./g, "-");

  const renderElement = () => (
    <Component
      {...properties[name]}
      id={id}
      path={id}
      name={id}
      readOnly={readonly || (!!signer && (name === "name" || name === "ipn"))}
      formControlProps={formControlProps}
      description={descriptionText}
      value={values[name] || ""}
      sample={!readonly && sample}
      onChange={handleChange(name)}
      errors={errors[name]}
      helperText={helperText}
    />
  );

  if (!index && signer) {
    return (
      <div className={classes.displayFlex} key={name}>
        <div className={classes.flex}>{renderElement()}</div>
        <div className={classes.buttonContainer}>
          <IconButton
            size="small"
            className={classes.search}
            id={"user-search-button"}
            onClick={handleOpenSelectUserDialog}
          >
            <Search />
          </IconButton>
        </div>
      </div>
    );
  }

  return renderElement();
};

Element.propTypes = {
  tabs: PropTypes.array,
  properties: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  formControlProps: PropTypes.object,
  owner: PropTypes.string,
  signer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  document: PropTypes.object,
};

Element.defaultProps = {
  tabs: null,
  properties: null,
  value: null,
  onChange: undefined,
  errors: null,
  required: false,
  formControlProps: {},
  owner: "",
  signer: null,
  document: null,
};

const translated = translate("Elements")(Element);
export default withStyles(styles)(translated);
