/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

// ##############################
// // // Button styles
// #############################

import customInputStyle from "../../variables/styles/customInputStyle";
import attachesWizardStep from "../../variables/styles/attachesWizardStep";

export default {
  ...attachesWizardStep,
  ...customInputStyle,
  updatePaymentProcess: {
    position: "absolute",
    marginLeft: -74,
    marginTop: 18,
  },
  amountText: {
    // float: 'right',
    margin: "10px 0",
    fontWeight: 900,
    padding: "10px 22px",
  },
  amount: {
    fontWeight: 900,
    textAlign: "right",
    fontSize: 16,
  },
  paperErrorHint: {
    color: "red",
  },
  "@media (max-width:767px)": {
    // eslint-disable-line no-useless-computed-key
    cell: {
      padding: "5px",
    },
    amount: {
      maxWidth: 120,
      fontSize: 12,
    },
  },
};
