import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const Placeholder = ({ selectProps, children, innerProps }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.placeholder}
    {...innerProps}
  >
    {children}
  </Typography>
);

Placeholder.propTypes = {
  selectProps: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

Placeholder.defaultProps = {
  innerProps: {},
  children: "",
};

export default Placeholder;
