import state from "./StateFilterChip";
import statusId from "./StatusIdFilterChip";
import execMyRole from "./ExecMyRoleFilterChip";
import createdAt from "./CreatedAtFilterChip";
import docDate from "./DocDateFilterChip";
import dateForce from "./DateForceFilterChip";
import courtId from "./CourtIdFilterChip";
import courts from "./CourtListFilterChip";
import regions from "./RegionListFilterChip";
import courtType from "./CourtTypeFilterChip";
import justiceType from "./JusticeTypeFilterChip";
import justiceKind from "./JusticeKindFilterChip";
import judge from "./JudgeFilterChip";
import caseNumber from "./CaseNumberFilterChip";
import documentId from "./DocumentIdFilterChip";
import proceedingsNumber from "./ProceedingsNumberFilterChip";
import judgmentCode from "./JudgmentCodeFilterChip";
import updatedAt from "./UpdatedAtFilterChip";
import paymentInfoSumm from "./PaymentInfoSummFilterChip";

import CaseMemberNameFilterChip from "./CaseMemberNameFilterChip";
import registry from "./registry";
import MyCaseMemberStatusChip from "./MyCaseMemberStatusChip";
import CriminalProcNumFilterChip from "./CriminalProcNumFilterChip";
import CollectorIssuanceType from "./CollectorIssuanceTypeChip";

export default {
  // statuses
  state,
  status: state,
  statusId,
  vpStateId: statusId,
  execMyRole,
  myRole: execMyRole,
  // money
  paymentInfoSumm,
  sumUah: paymentInfoSumm,
  // dates
  createdAt,
  adjudication_date: createdAt,
  updatedAt,
  dateForce,
  docAcceptDate: dateForce,
  docDate,
  // other
  courtId,
  regions,
  court_region_code: regions,
  courts,
  court_code: courts,
  courtType,
  justiceType,
  justice_kind: justiceKind,
  judgment_code: judgmentCode,
  judge,
  case_num: caseNumber,
  doc_id: documentId,
  proceedingsNumber,
  registry,
  caseMemberName: CaseMemberNameFilterChip,
  caseMemberRoleId: MyCaseMemberStatusChip,
  criminalProcNum: CriminalProcNumFilterChip,
  collectorIssuanceType: CollectorIssuanceType,
};
