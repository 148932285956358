import PropTypes from "prop-types";

import { Table } from "../../../components";
import StatusChip from "../../../components/StatusChip";
import { getPermTableText } from "../../../helpers/permission";
import { formatUserName } from "../../../helpers/userName";
import humanDateFormat, {
  humanDateTimeFormat,
} from "../../../helpers/humanDateFormat";
import { PermissionType } from "./OrderDialog/types";

const fields = {
  pagination: true,
  checkbox: {
    classNames: ["cell", "verticalAlign", "shortCell", "checkboxCell"],
    grid: [1, 2, 1, 7],
  },
  tableFields: [
    {
      key: "typeId",
      sort: false,
      title: "FORM",
      classNames: ["cell"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "allowedByName",
      sort: false,
      title: "FROM",
      classNames: ["cell"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "userName",
      sort: false,
      title: "TO",
      classNames: ["cell"],
      grid: [2, 7, 3, 4],
    },
    {
      key: "permission",
      sort: false,
      title: "PERMISSION_TITLE",
      classNames: ["cell"],
      grid: [2, 7, 4, 5],
    },
    {
      key: "state",
      sort: false,
      title: "TYPE",
      classNames: ["cell", "textCentered", "mediumCell"],
      grid: [2, 7, 5, 6],
    },
    {
      key: "createdAt",
      sort: false,
      title: "DOCUMENT_CREATING_DATE",
      classNames: ["cell", "shortCell"],
      grid: [2, 7, 7, 8],
    },
    {
      key: "cancelDate",
      sort: false,
      title: "DOCUMENT_CANCELING_DATE",
      classNames: ["cell", "shortCell"],
      grid: [2, 7, 8, 9],
    },
  ],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
};

const getStatus = (t, targetType) => {
  switch (targetType) {
    case 1:
      return {
        status: 22,
        statusText: t("PROC_STATE"),
      };
    case 2:
      return {
        status: 22,
        statusText: t("CASE_STATE"),
      };
    case 3:
      return {
        status: 22,
        statusText: t("COURT_STATE"),
      };
    default:
      return {
        status: 11,
        statusText: t("GLOBAL_STATE"),
      };
  }
};

const getStateChip = (t, setId, { targetType } = {}) => {
  const { statusText, status } = getStatus(t, targetType);
  return (
    <StatusChip
      setId={setId}
      status={status}
      statusText={statusText}
      tableChip={true}
    />
  );
};

// Реалізувати можливість анулювати довіреність представником, тобто особою кому видана довіреність.
// Для цього в списку довіреностей повинні бути доступні всі чек - бокси (зараз вони доступні тільки на тих довіреностях, де користувач є довірителем.
// old condition: const allowDelete = (item) => item.isOwner && item.isActive;
const allowDelete = (item) => item.isActive;

const ShareTable = ({
  t,
  checked,
  createSortHandler,
  onCheckItem,
  handleClick,
  setId,
  classes,
  currentUserId,
  noNeedAllCheckbox,
  list,
  meta,
  dataSource,
  pagination,
  changeCount,
}) => {
  const getText = (item, key) => {
    const { createdAt } = item;

    switch (key) {
      case "typeId":
        switch (item[key]) {
          case PermissionType.EDRRecipe:
            return t("EDRRecipe");
          case PermissionType.Order:
            return t("ORDER");
          default:
            return t("SHARE");
        }
      case "permission":
        return getPermTableText(t, item);
      case "createdAt":
        return humanDateFormat(createdAt);
      case "cancelDate":
        return humanDateTimeFormat(item.cancelDate);
      case "allowedByName":
        if (item.isAlloweder) {
          return t("FROM_ME");
        }
        return formatUserName(item[key]);
      case "userName":
        if (item.userId === currentUserId) {
          return t("TO_ME");
        }
        return formatUserName(item[key]);
      case "state":
        return getStateChip(t, setId, item);
      default:
        return item[key];
    }
  };

  const calcDataRowClass = ({ rowData }) => {
    return rowData.isActive ? "" : classes.shareDisabled;
  };

  return (
    <Table
      fields={fields}
      checked={checked}
      getText={getText}
      setId={setId}
      dataSource={dataSource}
      onCheckItem={handleClick}
      createSortHandler={createSortHandler}
      onCheckboxClick={onCheckItem}
      pagination={pagination}
      changeCount={changeCount}
      list={list}
      meta={meta}
      t={t}
      labelRowsPerPage="COUNT_SHARES"
      labelDisplayedRows="DISPLAYED_SHARES"
      noNeedAllCheckbox={noNeedAllCheckbox}
      needFullData
      isOwner={allowDelete}
      // ownerList={ownerList.length > 0}
      // щоб завжди показувалися чекбокси, навіть якщо всі анальовані
      ownerList={true}
      delLabel={t("ADD_TO_DELETE")}
      calcDataRowClass={calcDataRowClass}
    />
  );
};

ShareTable.propTypes = {
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  checked: PropTypes.array.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  ownerList: PropTypes.any.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default ShareTable;
