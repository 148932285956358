export default {
  input: {
    marginBottom: 20,
  },
  toolbar: {
    padding: 0,
  },
  flex: {
    flex: 1,
  },
};
