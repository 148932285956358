import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles, Snackbar } from "@material-ui/core";

import { RegularCard, Preloader } from "../../components";
import { requestUserMeta } from "../../actions/usermeta";
// import { getAuthMode } from 'actions/user';

import customInputStyle from "../../variables/styles/customInputStyle";
import PageCardTitle from "../../components/PageCardTitle";

import PassportLayout from "./components/PassportLayout";
import Panel from "./components/Panel";

class MyDocuments extends Component {
  state = {
    expanded: 0,
    error: "",
  };

  // UNSAFE_componentWillMount() {
  //     const { userInfo } = this.props;
  //     if (!userInfo) getAuthMode();
  // }

  componentWillUnmount() {
    requestUserMeta();
  }

  toggleExpanded = (panel) => () => {
    let { expanded } = this.state;
    if (expanded === panel) {
      expanded = panel === 1 ? 0 : 1;
    } else {
      expanded = panel;
    }
    this.setState({ expanded });
  };

  setError = (error) => this.setState({ error });

  renderContent = () => {
    const { setId, t, userMetaData, classes, userInfo } = this.props;
    const { expanded, error } = this.state;

    if (!userMetaData) return <Preloader />;

    return (
      <Fragment>
        {error && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            message={<span>{error}</span>}
            id={setId("error")}
          />
        )}
        {!userInfo.isLegal && (
          <Panel
            expanded={expanded}
            toggleExpanded={this.toggleExpanded(0)}
            panelNumber={0}
            setId={(elementName) => setId(`panel-wrap-0-${elementName}`)}
            classes={classes}
            title={t("PASSPORT_TITLE")}
          >
            <PassportLayout
              {...this.props}
              setError={this.setError}
              userMetaData={userMetaData.meta || {}}
            />
          </Panel>
        )}
      </Fragment>
    );
  };

  render() {
    const { t, setId } = this.props;

    return (
      <RegularCard
        headerColor="primary"
        cardTitle={
          <PageCardTitle
            text={t("DOCUMENT_PANEL_TITLE")}
            stepName={"myDocuments"}
            setId={setId}
          />
        }
        content={this.renderContent()}
        setId={setId}
      />
    );
  }
}

MyDocuments.propTypes = {
  userMetaData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userInfo: PropTypes.object,
};

MyDocuments.defaultProps = {
  userMetaData: null,
  userInfo: null,
};

const styled = withStyles(customInputStyle)(MyDocuments);
const translated = translate("UserProfile")(styled);

const mapStateToProps = ({
  usermeta: { userMetaData },
  authorization: { info: userInfo },
}) => ({
  userMetaData,
  userInfo,
});

// decorate and export
export default connect(mapStateToProps)(translated);
