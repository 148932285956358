import { Component, Fragment, ReactElement } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Button } from "../../../components";
import EDSForm from "../../../components/EDSForm";

export interface SigningProps {
  classes: any;
  setId: (s: string) => string;
  t: (key: string, params?: any[]) => string;
  onSelectKey: (
    encodedKey: any,
    signer: {
      execute: (fnName: string, args1?: any, arg2?: any) => Promise<any>;
    },
    resetPrivateKey: () => void,
    onDataToSign?: any
  ) => Promise<void>;
  onBackToEdit: () => void;
  onToggleSignModal: () => void;
  preview: ReactElement;
  showSignModal: boolean;
}

interface SigningState {}

class Signing extends Component<SigningProps, SigningState> {
  render() {
    const {
      classes,
      setId,
      t,
      onSelectKey,
      onBackToEdit,
      preview,
      showSignModal,
      onToggleSignModal,
    } = this.props;

    return (
      <Fragment>
        {preview}
        <div style={{ justifyContent: "space-between" }}>
          <Button
            color="transparent"
            onClick={onBackToEdit}
            id={setId("remove-pdf-button")}
            setId={(elmentName: string) => setId(`remove-pdf-${elmentName}`)}
          >
            {t("CONTINUE_EDITING")}
          </Button>
          <Button color="yellow" onClick={onToggleSignModal}>
            {t("SIGN_AND_SEND")}
          </Button>
        </div>
        {showSignModal && (
          <Dialog
            open={showSignModal}
            onClose={onToggleSignModal}
            id={setId("dialog")}
            className={classes.dialog}
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
          >
            <DialogTitle
              id={setId("dialog-title")}
              className={classes.dialogContentWrappers}
            >
              {t("SIGNING_DOCUMENT")}
            </DialogTitle>
            <DialogContent
              id={setId("dialog-content")}
              className={classes.dialogContentWrappers}
            >
              <EDSForm onSelectKey={onSelectKey} />
            </DialogContent>
          </Dialog>
        )}
      </Fragment>
    );
  }
}

export default Signing;
