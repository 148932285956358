import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core/styles";

import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";
import { debounce } from "./debounce";

import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";

import { Button } from "../../components";

import emptyValues from "../../variables/emptyValues";
import formElements from "./elements";

const styles = {
  serializeItem: {
    padding: 15,
    paddingTop: 10,
    marginBottom: 30,
  },
  removeItemBtn: {
    float: "right",
    margin: "-10px -16px",
  },
  errorPaper: {
    boxShadow:
      "0px 1px 3px 0px rgba(255, 0, 0, 1), 0px 1px 1px 0px rgba(255, 0, 0, 1), 0px 2px 1px -1px rgba(255, 0, 0, 1)",
  },
  paperErrorHint: {
    margin: "-22px 0 10px",
    color: "red",
  },
};

class SchemaForm extends Component {
  constructor() {
    super();
    this.state = {
      value: null,
      element: null,
      stepId: null,
    };
  }
  debouncedValue = debounce(
    (element, value, stepId) => this.setState({ element, value, stepId }),
    10000
  );

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      const { onChange } = this.props;
      onChange && onChange({ [this.state.element]: this.state.value });
    }
  }

  handleChange = (element, stepId) => (value) => {
    // if (element === "text" && stepId === "form") {
    //  this.debouncedValue(element, value, stepId);
    // } else {
    const { onChange } = this.props;
    onChange && onChange({ [element]: value });
    // }
  };

  handleAddItem = () => {
    const { onChange, value, items } = this.props;
    const key = Object.keys(value).length;
    onChange && onChange({ [key]: emptyValues[items.type] });
  };

  handleDeleteItem = (index) => () => {
    const { onChange } = this.props;
    onChange && onChange({ [index]: undefined });
  };

  renderFormElement(element, value, filters, path) {
    const { document: doc, owner } = this.props;
    const name = element.control || element.type + ".element";
    const componentName = name.split(".").map(capitalizeFirstLetter).join("");

    const ElementComponent = formElements[componentName];
    const id = (path || "").replace(/\./g, "-");

    if (!ElementComponent) {
      return (
        <div key={id} id={id}>
          {componentName} не налаштований
        </div>
      );
    }
    if (element.hidden) {
      return null;
    }

    return (
      <ElementComponent
        document={doc}
        path={[element.stepId, path].join(".")}
        key={id}
        filters={filters}
        onChange={this.handleChange(path, element.stepId)}
        formControlProps={{ fullWidth: true }}
        setId={(elId) => `schema-form-${elId}`}
        {...element}
        readOnly={element.reeadOnly || element.readonly}
        value={value}
        classes={{}}
        owner={owner}
      />
    );
  }

  render() {
    const {
      t,
      classes,
      type,
      actions,
      properties,
      items,
      addItem,
      value,
      filters,
      control,
      errors,
      readOnly,
      required,
      allowEmpty,
      stepId,
    } = this.props;
    if (control) {
      return this.renderFormElement(this.props, value, filters, 0);
    }

    if (type === "object" && properties) {
      return Object.keys(properties).map((element) =>
        this.renderFormElement(
          {
            ...properties[element],
            stepId,
            actions,
            errors: errors && errors[element],
            readOnly:
              readOnly ||
              properties[element].readOnly ||
              properties[element].readonly,
            required: Array.isArray(required) && required.includes(element),
          },
          (value || {})[element],
          filters,
          element
        )
      );
    }

    if (type === "array" && items) {
      const serializeValues =
        Object.values(value).length || allowEmpty
          ? Object.values(value)
          : [emptyValues[items.type]];

      const addItemText = (addItem && addItem.text) || t("ADD_ITEM");

      return (
        <div>
          {serializeValues.map((values, index) => {
            const hasError =
              errors && !!errors[index] && typeof errors[index] === "string";
            return (
              <div key={index} id={`schema-form-${index}-paper-wrap`}>
                <Paper
                  className={
                    classes.serializeItem +
                    (hasError ? " " + classes.errorPaper : "")
                  }
                  elevation={1}
                  id={`schema-form-${index}-paper`}
                >
                  <IconButton
                    className={classes.removeItemBtn}
                    onClick={this.handleDeleteItem(index)}
                    id={`schema-form-${index}-close-button`}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                  <SchemaForm
                    {...this.props}
                    {...items}
                    errors={errors && errors[index]}
                    value={values}
                    filters={filters}
                    onChange={this.handleChange(index)}
                    id={`schema-form-${index}`}
                  />
                </Paper>
                {hasError && (
                  <FormHelperText
                    className={classes.paperErrorHint}
                    id={`schema-form-${index}-error`}
                  >
                    {errors[index]}
                  </FormHelperText>
                )}
              </div>
            );
          })}
          <Button
            color="yellow"
            onClick={this.handleAddItem}
            disabled={readOnly}
            id={"schema-form-add-element-paper"}
            setId={(elementName) =>
              `schema-form-add-element-paper-${elementName}`
            }
          >
            {addItemText}
          </Button>
        </div>
      );
    }

    return this.renderFormElement(this.props, value, filters, 0);
  }
}

SchemaForm.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  items: PropTypes.object,
  properties: PropTypes.object,
  t: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  formControlProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  addItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  filters: PropTypes.object,
  control: PropTypes.string,
  readOnly: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  document: PropTypes.object,
  stepId: PropTypes.string,
  actions: PropTypes.object.isRequired,
  owner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

SchemaForm.defaultProps = {
  onChange: undefined,
  addItem: undefined,
  value: null,
  items: null,
  properties: null,
  errors: null,
  required: true,
  formControlProps: {},
  filters: null,
  control: "",
  readOnly: false,
  allowEmpty: false,
  document: null,
  stepId: "",
  owner: null,
  error: "",
};
const styled = withStyles(styles)(SchemaForm);
export default translate("ClaimWizard")(styled);
