import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles, FormControl } from "@material-ui/core";

import { requestRegions } from "../../../actions/dictionary";
import customInputStyle from "../../../variables/styles/customInputStyle";
import { formatUserName } from "../../../helpers/userName";

import TextField from "../../../components/TextField";

const executivesExample = [
  { id: 1, name: "Виконавець 1", regionId: 10 },
  { id: 2, name: "Виконавець 2", regionId: 10 },
  { id: 3, name: "Виконавець 3", regionId: 27 },
  { id: 4, name: "Виконавець 4", regionId: 27 },
  { id: 5, name: "Виконавець 5", regionId: 27 },
  { id: 6, name: "Виконавець 6", regionId: 21 },
  { id: 7, name: "Железняк Антон Анатольевич", regionId: 21 },
  { id: 8, name: "Железняк АНТОН", regionId: 21 },
];

const getRegionByValue = (props, state = {}, executive = "") => {
  const executives = props.executives || executivesExample;
  const value = state.executive || props.value || executive;
  let regionId = "";
  if (value && executives) {
    const foundRegion = executives.find((item) => item.id === value);
    regionId = foundRegion ? foundRegion.regionId || "" : "";
  }
  return regionId;
};

class SelectExecutive extends React.Component {
  state = {
    regionId: getRegionByValue(this.props),
    executive: this.props.value || "",
  };

  componentDidMount() {
    const { regions } = this.props;
    if (!regions) requestRegions();
  }

  getOptions = () => {
    const executives = this.props.executives || executivesExample;
    const { regions } = this.props;
    const { regionId } = this.state;
    let filterExecutives = executives;
    if (regionId) {
      filterExecutives = executives.filter(
        (item) => item.regionId === regionId
      );
    }
    return {
      executives: filterExecutives.map((item) => ({
        ...item,
        name: formatUserName(item.name),
      })),
      regions: regions.filter(
        (item) => !!executives.find((i) => item.id === i.regionId)
      ),
    };
  };

  handleChange = ({ target }) => {
    const { onChange } = this.props;
    const { name, value } = target;
    this.setState({ [name]: value });

    if (name === "executive") {
      this.setState({
        regionId: getRegionByValue(this.props, this.state, value),
        executive: value,
      });
      onChange(value);
    }

    if (name === "regionId") {
      this.setState({
        regionId: value,
        executive: "",
      });
      onChange("");
    }
  };

  renderField = (idName, label, props, required = false) => {
    const { t, classes, readOnly, setId } = this.props;
    return (
      <TextField
        id={setId(idName)}
        select={true}
        dummy={!!readOnly}
        label={t(label) + (required ? "*" : "")}
        onChange={this.handleChange}
        margin="normal"
        selectProps={{
          textFieldProps: { margin: "normal" },
          id: setId(`select-${idName}`),
          SelectDisplayProps: {
            id: setId(`select-${idName}-wrapper`),
          },
          MenuProps: { className: classes.menu },
        }}
        {...props}
      />
    );
  };

  render() {
    const { setId, formControlProps, classes } = this.props;
    const { regionId, executive } = this.state;
    const { regions, executives } = this.getOptions();
    const regionProps = {
      value: regionId,
      options: regions,
      name: "regionId",
    };
    const executiveProps = {
      value: executive,
      options: executives,
      name: "executive",
    };
    return (
      <FormControl
        id={setId("executive-form")}
        {...formControlProps}
        className={classes.formControl}
      >
        {this.renderField("region", "SELECT_REGION", regionProps)}
        {this.renderField("executive", "SELECT_EXECUTIVE", executiveProps)}
      </FormControl>
    );
  }
}

SelectExecutive.propTypes = {
  regions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  executives: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setId: PropTypes.func.isRequired,
  formControlProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SelectExecutive.defaultProps = {
  regions: null,
  executives: null,
  formControlProps: {},
  value: null,
  readOnly: false,
};

const styled = withStyles(customInputStyle)(SelectExecutive);
const translated = translate("Elements")(styled);

const mapStateToProps = ({ dictionary: { regions, executives } }) => ({
  regions,
  executives,
});

// decorate and export
export default connect(mapStateToProps)(translated);
