import DataSource from "../../../pages/Case/decorators/dataSource";
import {
  PermissionTargetType,
  PermissionType,
} from "../../Share/components/OrderDialog/types";

const { myProcDocDataSource, myCaseDocDataSource } = DataSource;

function checkOrderForCause(caseNumber, permission) {
  //показываем только ордера выданные по текущему делу
  const meta = JSON.parse(permission.meta);
  const isOrder = permission.typeId === PermissionType.Order;
  return isOrder && meta.caseNumber === caseNumber;
}

export default ({
  list,
  userId,
  caseDocuments,
  checked,
  display,
  procDocuments,
  onCheckItem,
  onCheckboxClick,
  onViewDocument,
  proceedingsList,
  onCheckProcItem,
  userMetaData,
  owners,
  activeCase,
  procChecked,
  isLegal,
  casesCourts,
  procCourts,
  myProcDocTitle,
  myCaseDocTitle,
  itemShareList,
  onProcCheckboxClick,
  myProcDocuments,
  myCaseDocuments,
  changedShare,
  activeProc,
}) => {
  const favorites =
    userMetaData &&
    userMetaData.meta &&
    userMetaData.meta.favorites &&
    Array.isArray(userMetaData.meta.favorites)
      ? userMetaData.meta.favorites
      : [];
  const itemShares = Array.isArray(itemShareList) ? itemShareList : [];

  const addOwner = (docList) => {
    if (!Array.isArray(docList)) return [];
    const arr = docList.map((item) => {
      const foundOwner = Array.isArray(owners)
        ? owners.find((owner) => owner.allowedBy === item.owner)
        : null;
      item.resharing = itemShares.some(
        (share) =>
          share.targetId === item.id &&
          userId === share.createdBy &&
          share.isActive
      );
      if (foundOwner) item.owner = foundOwner.allowedByName || item.owner;
      return item;
    });
    if (docList && docList.meta) arr.meta = docList.meta;
    return arr;
  };
  const proc =
    activeProc || proceedingsList.find(({ id }) => id === procChecked);
  let currentList = Array.isArray(list) ? addOwner(list) : [];
  let onCheck = onCheckItem;
  let targetType = PermissionTargetType.CAUSE;
  let targetId = checked || "";
  let currentShares = [];
  let handleCheckboxClick = onCheckboxClick;
  let currentChecked = checked;
  let showCreateButton = false;
  let enableShareButton = false;
  let filterOwners = [];
  let foundInMembers = null;
  let docList = null;
  let docDataSource = null;
  let showMyDocList = false;
  let docFilter = "case";
  let courtsList = procCourts;
  let docDisplay = "myCaseDocuments";
  let docTitle = myCaseDocTitle;

  if (activeCase) {
    switch (display) {
      case "procList":
        onCheck = onCheckProcItem;
        currentList = addOwner(proceedingsList);
        handleCheckboxClick = onProcCheckboxClick;
        currentChecked = procChecked;
        targetType = 1;
        targetId = procChecked;
        break;
      case "documentProcList":
        currentList = addOwner(procDocuments);
        onCheck = onViewDocument;
        docDataSource = myProcDocDataSource;
        docList = myProcDocuments;
        docFilter = "proceeding";
        docDisplay = "myProcDocuments";
        docTitle = myProcDocTitle;
        targetId = procChecked;
        break;
      case "documentCaseList":
        currentList = addOwner(caseDocuments);
        onCheck = onViewDocument;
        docDataSource = myCaseDocDataSource;
        docList = myCaseDocuments;
        courtsList = casesCourts;
        break;
      case "documentView":
        currentList = procDocuments;
        break;
      case "caseList":
      default:
        break;
    }
    const { caseMembers, number: activeCaseNumber } = activeCase;

    foundInMembers = Array.isArray(caseMembers)
      ? caseMembers.find((item) => item.userId === userId)
      : null;

    filterOwners = (owners || []).filter((item) => {
      // проверяем что есть такой член дела который выдал нам право
      // додано: перевірка для довіреності
      const hasCaseMember =
        item.allowedByName &&
        (caseMembers || []).some((member) => {
          const isShare = item.typeId === 0 || item.typeId === 2;
          return isShare && member.userId === item.allowedBy;
        });

      // додано: перевірка для ордера
      // ордер адвоката по номеру дела
      const orderWithCase = checkOrderForCause(activeCaseNumber, item);

      // общие доверенности и доверенности по текущему делу/производству
      const byTargetId =
        (item.targetType === 0 && !item.targetId) ||
        (item.targetType === 1 && item.targetId === procChecked) ||
        (item.targetType === 2 && item.targetId === checked) ||
        (item.targetType === 3 &&
          activeProc &&
          parseInt(item.targetId) === activeProc.jurisdictionTypeId);
      // есть право редактировать
      const allowEdit = item.allowEdit === 1;
      const isUserPermission = item.userId === userId;

      return (
        isUserPermission &&
        byTargetId &&
        allowEdit &&
        (hasCaseMember || orderWithCase)
      );
    });

    showCreateButton = !!(
      !isLegal &&
      (foundInMembers ||
        filterOwners.some((item) => {
          // const sameUser = item.userId === userId;
          // const hasPermission = (permissions || []).some(per => per.id === item.id);
          // return sameUser && hasPermission;
          // return (
          //   sameUser || (permissions || []).some((per) => per.id === item.id)
          // );
          return item.userId === userId && item.addClaimPermission;
        }))
    );

    // выдал не сам себе
    filterOwners = filterOwners.filter((item) => item.allowedBy !== userId);

    currentShares = itemShares.reduce((acc, item) => {
      const itisDouble = !!acc.find((share) => (share || {}).id === item.id);
      if (
        !itisDouble &&
        item.targetId === targetId &&
        (userId === item.createdBy || item.userId === userId)
      ) {
        item.dialog =
          userId === item.createdBy ? "changePermission" : "addReshare";
        if ((item.userId === userId && item.allowShare === 0) || !item.isActive)
          item.dialog = "viewOnly";
        acc.push(item);
      }
      return acc;
    }, []);
    if (
      changedShare &&
      !currentShares.find((item) => item.id === changedShare.id)
    ) {
      currentShares.push(changedShare);
    }

    enableShareButton = !!(
      targetId &&
      (foundInMembers || !!currentShares.length)
    );
    showMyDocList = !!(
      (display === "documentProcList" || display === "documentCaseList") &&
      docDataSource &&
      Array.isArray(docList) &&
      (!!docList.length || docDataSource.withSearch)
    );
  }

  return {
    favorites,
    currentList,
    onCheck,
    targetType,
    handleCheckboxClick,
    currentChecked,
    showCreateButton,
    enableShareButton,
    showMyDocList,
    docFilter,
    courtsList,
    docDisplay,
    docTitle,
    currentShares,
    targetId,
    filterOwners,
    foundInMembers,
    addOwner,
    docDataSource,
    docList,
    activeProc: proc,
    activeCase,
  };
};
