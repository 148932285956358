import React from "react";
import { Advocate } from "./types";
import { useSelector } from "react-redux";
import {
  advocateSelector,
  isAdvocateSelector,
} from "../../selectors/personalData";

type Props = {
  isAdvocate: boolean;
  advocateChecked: boolean;
  advocateLicense: string | null;
  advocateSettings: Advocate | null;
};

const withAdvocate = <T extends Props>(
  WrappedComponent: React.ComponentType<T>
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function ComponentWithAdvocate(props: Omit<T, keyof Props>) {
    const isAdvocate = useSelector(isAdvocateSelector);
    const advocate: Advocate | null = useSelector(advocateSelector);

    return (
      <WrappedComponent
        {...(props as T)}
        isAdvocate={isAdvocate}
        advocateChecked={advocate && advocate.isChecked}
        advocateLicense={advocate && advocate.certnum}
        advocate={advocate}
      />
    );
  }

  ComponentWithAdvocate.displayName = `withAdvocate(${displayName})`;

  return ComponentWithAdvocate;
};

export default withAdvocate;
