import normalizeClaim from "../../../helpers/normalizeClaim";
import {
  signDocument,
  requestSignData,
  setWizardStates,
  updateClaimToDraft,
} from "../../../actions/claim";
import getWizardProps from "../../../helpers/getWizardProps";
import { signManifestVersions } from "../../../services/eds/helpers/signManifest";
import { getFindUsers } from "../../../actions/check-cabinet";
import { handleCommitDocument } from "./updateDocument";
import getSigners from "../helpers/getSigners";
import { SENDED_TO_PARTIES } from "../../../variables/claimStatuses";
import { checkCertificate } from "../../../actions/user";

function getParticipantsForCheck(partArray) {
  const result = [];
  partArray.forEach((part) => {
    if (part) {
      if (Array.isArray(part)) {
        part.forEach((e) => {
          if (e.ipn || e.edrpou) result.push(e);
        });
      } else {
        if (part.ipn || part.edrpou) result.push(part);
      }
    }
  });
  return result;
}

export default async function handleSelectKey(
  encodedKey,
  signer,
  resetPrivateKey,
  onDataToSign
) {
  let {
    claim,
    template: {
      jsonSchema: { properties },
    },
  } = getWizardProps();
  const claimPrevState = claim.state;
  const id = claim.id;
  let signDataFetched = false;

  setWizardStates({ blockScreen: true });

  try {
    const certBuffer = await signer.execute(
      "GetCertificate",
      encodedKey.issuer,
      encodedKey.serial
    );

    const certBase64 = await signer.execute("Base64Encode", certBuffer);
    await checkCertificate({ data: certBase64 });

    const signData = await requestSignData(id);

    let { signVersion, dataToSign } = signData;

    if (Array.isArray(signData)) {
      dataToSign = signData;
      signVersion = 1;
    }
    signDataFetched = true;

    const savedSignatures = onDataToSign
      ? await onDataToSign(dataToSign)
      : undefined;

    const signManifestFunc = signManifestVersions[signVersion];
    if (!signManifestFunc) {
      throw new Error("Sign version unsupported");
    }
    const signature = await signManifestFunc(
      dataToSign,
      signer,
      savedSignatures
    );
    // const signature = dataToSign;
    resetPrivateKey();

    claim = await signDocument(id, signature);
    if (claim instanceof Error) throw claim;
    normalizeClaim(claim);

    const { signatures, digitalDocumentData } = claim;
    const signers = getSigners(digitalDocumentData, properties);
    const unsigned = signers.filter(
      (user) =>
        !signatures.find((sign) => sign.userId === user.id && sign.createdAt)
    );

    if (unsigned.length) {
      setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
        blockScreen: false,
        showSignModal: false,
      });
      return await handleCommitDocument();
    }

    if (claimPrevState === SENDED_TO_PARTIES) {
      setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
      });
      return await handleCommitDocument();
    }

    const roles = [
      digitalDocumentData?.claimant, // позивач
      digitalDocumentData?.debitor,
      digitalDocumentData?.defendant, // відповідач
      digitalDocumentData?.interestedPerson,
      digitalDocumentData?.otherPerson,
      digitalDocumentData?.representativeDebitor,
      digitalDocumentData?.representativeDefendant,
      digitalDocumentData?.representativeInterestedPerson,
      digitalDocumentData?.representativeClaimant,

      digitalDocumentData?.applicant,
      digitalDocumentData?.representativeApplicant,
      digitalDocumentData?.complainant,
      digitalDocumentData?.representativeComplainant,
    ];

    const usersArray = getParticipantsForCheck(
      roles.filter((item) =>
        Array.isArray(item)
          ? !!item.find((el) => el?.id !== claim?.createdBy)
          : item?.id !== claim?.createdBy
      )
    );

    if (usersArray.length === 0) {
      setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
      });
      return await handleCommitDocument();
    }

    //usersToSend - массив с найденными пользователями(те у кого есть кабинет)
    //usersArray - массив с НЕ найденными пользователями, данные берутся с инпуто
    const codeArray = usersArray.map((user) => user.ipn || user.edrpou);
    const uniquiCodeArray = codeArray.filter((item, pos) => {
      return codeArray.indexOf(item) === pos;
    });

    const usersToSend = await getFindUsers(uniquiCodeArray);
    if (usersToSend instanceof Error) throw usersToSend;

    const foundUser = usersToSend.find((item) => !!item.isFound);
    if (foundUser) {
      usersToSend.forEach((user) => {
        if (!user.isFound) {
          const sourceUser = usersArray.find(
            (item) => (item.ipn || item.edrpou) === user.code
          );
          user.isLegal = !!sourceUser.isLegal;
          user.name = sourceUser.name || sourceUser.companyName;
          user.isToSend = false;
        } else {
          user.isToSend = true;
        }
      });
      setWizardStates({
        blockScreen: false,
        claim,
        showSelectUsers: true,
        showSignModal: false,
        usersToSend,
        usersArray,
      });
    } else {
      setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
      });
      return await handleCommitDocument();
    }
  } catch (error) {
    if (signDataFetched) {
      try {
        claim = await updateClaimToDraft(id);
        normalizeClaim(claim);
      } catch (error) {
        console.log(error);
      }
    }
    setWizardStates({
      claim,
      origin: JSON.parse(JSON.stringify(claim)),
      error: error.message,
      blockScreen: false,
      showSignModal: false,
    });
  }
}
