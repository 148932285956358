import React from "react";
import { Attachment } from "@material-ui/icons";
import humanDateFormat from "../../../../helpers/humanDateFormat";
import { Table } from "../../../../components";
import { DataSourceClass } from "../../../../helpers/dataSource";
import { myProcDocuments } from "./myProcDocsFields";
import { formatUserName } from "../../../../helpers/userName";
import StatusChip from "../../../../components/StatusChip";
import { useCourtName } from "./useCourtName";
import DocNumberLink from "./DocNumberLink";

interface MyProcDocumentTableProps {
  t: (s: string, args?: Record<string, any>) => string;
  setId: (s: string) => string;
  list: Record<string, any>[];
  templates: Record<string, any>[];
  dataSource: DataSourceClass;
  createSortHandler: () => void;
  pagination: () => void;
  changeCount: () => void;
  checked: string;
  onSelectAllClick: () => void;
  onCheckboxClick: (id: string) => () => void;
  onCellClick: (id: string, data: Record<string, any>) => () => void;
}

const MyProcDocumentTable = ({
  t,
  list,
  dataSource,
  createSortHandler,
  checked = "",
  onCellClick,
  setId,
  pagination,
  changeCount,
  templates,
  onSelectAllClick,
  onCheckboxClick,
}: MyProcDocumentTableProps) => {
  const getCourtName = useCourtName(t);

  const getText = (item: Record<string, any>, key: string) => {
    const {
      number,
      createdAt,
      scanDocumentLink,
      digitalDocumentStaticFile,
      // digitalDocumentTemplateId,
      documentStateInfo,
      ownerName,
      owner,
      state,
      category,
      description,
    } = item;

    const foundDocLink = !!(scanDocumentLink || digitalDocumentStaticFile);

    switch (key) {
      case "scanDoc":
        return foundDocLink ? <Attachment /> : "";
      case "templateName":
        return description ? description : "б/н"; // фикс отображения Назви в Документах Користувача, в розділі "Мої справи"
      // case "templateName": {
      //   const template = templates.find(
      //     ({ id }) => id === digitalDocumentTemplateId
      //   );
      //   return (template || {}).name || "";
      // }
      case "myProcDocFullName":
        return t("MY_PROC_DOC_FULL_NAME", {
          number: number || t("WITHOUT_NUMBER"),
          createdAt: humanDateFormat(createdAt),
        });
      case "docNumber":
        return <DocNumberLink docNumber={number} t={t} category={category} />;
      case "docDate":
        return humanDateFormat(item[key] || item["createdAt"]);
      case "ownerName":
        return ownerName ? formatUserName(ownerName) : owner || "";
      case "courtName": {
        const template = templates.find(
          ({ id }) => id === item.digitalDocumentTemplateId
        );
        return (
          getCourtName(item.courtId, template && template.causeActionType) ||
          t("NUMBER")
        );
      }
      case "state":
        return (
          <StatusChip
            // @ts-ignore
            setId={setId}
            status={state}
            tableChip
            documentStateInfo={documentStateInfo}
          />
        );
      default:
        return item[key];
    }
  };

  return (
    <Table
      fields={myProcDocuments}
      checked={checked}
      getText={getText}
      setId={setId}
      dataSource={dataSource}
      onCheckItem={onCellClick}
      onSelectAllClick={onSelectAllClick}
      onCheckboxClick={onCheckboxClick}
      createSortHandler={createSortHandler}
      pagination={pagination}
      changeCount={changeCount}
      list={list}
      t={t}
      labelRowsPerPage="COUNT_CASES"
      labelDisplayedRows="DISPLAYED_CASES"
    />
  );
};

export default MyProcDocumentTable;
