import { docsFields } from "./docsFields";
import { myProcDocuments } from "./myProcDocsFields";

export default {
  judgeList: {
    pagination: true,
    checkbox: {
      classNames: ["cell", "verticalAlign", "checkboxCell"],
      grid: [1, 2, 1, 3],
    },
    tableFields: [
      {
        key: "fullName",
        sort: false,
        title: "CASE_NUMBER",
        classNames: ["cell", "onlyMobile"],
        grid: [2, 7, 1, 2],
      },
      {
        key: "caseNumber",
        sort: true,
        title: "CASE_NUMBER",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "courtName",
        sort: true,
        title: "CASE_COURT",
        classNames: ["cell", "longCell"],
        grid: [2, 7, 2, 3],
      },
      {
        key: "createdAt",
        sort: true,
        title: "CASE_CREATING_DATE",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "updatedAt",
        sort: true,
        title: "CASE_UPDATING_DATE",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
    ],
    filters: ["courtId", "courtType", "justiceType", "createdAt"],
  },
  caseList: {
    pagination: true,
    checkbox: {
      classNames: ["cell", "verticalAlign", "checkboxCell"],
      grid: [1, 2, 1, 5],
    },
    tableFields: [
      {
        key: "fullName",
        sort: false,
        title: "CASE_NUMBER",
        classNames: ["cell", "longCell", "onlyMobile"],
        grid: [2, 7, 1, 2],
      },
      {
        key: "caseNumber",
        sort: true,
        title: "CASE_NUMBER",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "criminalProcNum",
        sort: true,
        title: "ERDR_NUMBER",
        headerHint: "ERDR_NUMBER_HINT",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "courtName",
        sort: true,
        title: "CASE_COURT",
        classNames: ["cell", "longCell"],
        grid: [2, 7, 4, 5],
      },
      {
        key: "roleName",
        sort: false,
        title: "CASE_ROLE",
        classNames: ["cell", "onlyBigScreen"],
      },
      {
        key: "roleNameTitle",
        sort: false,
        title: "CASE_ROLE",
        classNames: ["cell", "onlyMobile"],
        grid: [2, 7, 2, 3],
      },
      {
        key: "roleNameText",
        sort: false,
        title: "",
        classNames: ["cell", "onlyMobile"],
        grid: [2, 7, 3, 4],
      },
      {
        key: "docDateFirst",
        sort: true,
        title: "FIRST_DOC_DATE",
        classNames: ["cell", "textCentered", "onlyBigScreen"],
      },
      {
        key: "docDateLast",
        sort: true,
        title: "LAST_DOC_DATE",
        classNames: ["cell", "textCentered", "onlyBigScreen"],
      },
    ],
    filters: [
      "courtId",
      "courtType",
      "justiceType",
      "createdAt",
      "caseMemberRoleId",
      "caseMemberName",
      "criminalProcNum",
    ],
  },
  procList: {
    pagination: true,
    checkbox: {
      classNames: ["cell", "verticalAlign", "checkboxCell"],
      grid: [1, 2, 1, 3],
    },
    tableFields: [
      {
        key: "procFullName",
        sort: false,
        title: "DOCUMENT_PROCCEDING",
        classNames: ["cell", "longCell", "onlyMobile"],
        grid: [2, 7, 1, 2],
      },
      {
        key: "number",
        sort: false,
        title: "DOCUMENT_PROCCEDING",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "courtName",
        sort: false,
        title: "CASE_COURT",
        classNames: ["cell"],
        grid: [2, 7, 3, 4],
      },
      {
        key: "description",
        sort: false,
        title: "PROC_DISCRIPTION",
        classNames: ["cell"],
        grid: [2, 7, 2, 3],
      },
      {
        key: "status",
        sort: true,
        title: "CASE_STATUS",
        classNames: ["cell", "textCentered", "shortCell"],
        grid: [2, 7, 4, 5],
      },
      {
        key: "docDateFirst",
        sort: true,
        title: "FIRST_DOC_DATE",
        classNames: ["cell", "textCentered", "onlyBigScreen"],
      },
      {
        key: "docDateLast",
        sort: true,
        title: "LAST_DOC_DATE",
        classNames: ["cell", "textCentered", "onlyBigScreen"],
      },
    ],
    filters: ["status", "courtId", "courtType", "justiceType", "createdAt"],
  },
  documentProcList: docsFields,
  documentCaseList: {
    ...docsFields,
    tableFields: [
      ...docsFields.tableFields,
      {
        key: "courtName",
        sort: true,
        title: "CASE_COURT",
        classNames: ["cell"],
        grid: [1, 7, 4, 5],
      },
    ],
  },
  myProcDocuments,
  myCaseDocuments: {
    pagination: true,
    checkbox: {
      classNames: ["cell", "verticalAlign", "checkboxCell"],
      grid: [1, 2, 1, 5],
    },
    tableFields: [
      {
        key: "number",
        sort: false,
        title: "DOCUMENT_NUMBER",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "myCaseDocFullName",
        sort: true,
        title: "DOCUMENT_NAME",
        classNames: ["cell", "longCell", "onlyMobile"],
        grid: [2, 7, 1, 2],
      },
      {
        key: "templateName",
        sort: false,
        title: "DOCUMENT_NAME",
        classNames: ["cell", "longCell", "onlyBigScreen"],
      },
      {
        key: "ownerName",
        sort: false,
        title: "OWNER",
        classNames: ["cell"],
        grid: [2, 7, 2, 3],
      },
      {
        key: "createdAt",
        sort: true,
        title: "CREATE_DATE",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
        grid: [5, 7, 2, 3],
      },
      {
        key: "state",
        sort: true,
        title: "STATUS",
        classNames: ["cell", "textCentered", "shortCell"],
        grid: [2, 7, 3, 4],
      },
      {
        key: "courtName",
        sort: true,
        title: "CASE_COURT",
        classNames: ["cell", "longCell"],
        grid: [2, 7, 4, 5],
      },
    ],
    filters: [
      "claimStatus",
      "courtId",
      "courtType",
      "justiceType",
      "createdAt",
    ],
    selectAllCheckbox: {
      classNames: ["cell", "verticalAlign", "checkboxCell"],
      grid: [1, 2, 1, 2],
    },
  },
  caseNotProcDoc: {
    pagination: false,
    checkbox: null,
    tableFields: [
      {
        key: "docFullName",
        sort: false,
        title: "DOCUMENT_NUMBER",
        classNames: ["cell", "longCell", "onlyMobile"],
        grid: [1, 7, 1, 2],
      },
      {
        key: "number",
        sort: false,
        title: "DOCUMENT_NUMBER",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "description",
        sort: false,
        title: "DOCUMENT_NAME",
        classNames: ["cell", "longCell"],
        grid: [1, 7, 2, 3],
      },
      {
        key: "owner",
        sort: false,
        title: "OWNER",
        classNames: ["cell"],
        grid: [1, 7, 3, 4],
      },
      {
        key: "createdAt",
        sort: false,
        title: "CREATE_DATE",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "updatedAt",
        sort: false,
        title: "CASE_UPDATING_DATE",
        classNames: ["cell", "shortCell", "onlyBigScreen"],
      },
      {
        key: "courtName",
        sort: false,
        title: "CASE_COURT",
        classNames: ["cell"],
        grid: [1, 7, 4, 5],
      },
    ],
    filters: [],
  },
};
