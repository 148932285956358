import momentJS from "moment";
import EvaluateError from "./EvaluateError";
/* eslint-disable no-eval */
export default (func, ...params) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const moment = momentJS;
    return eval(func)(...params);
  } catch (e) {
    return new EvaluateError(e.message, func, params);
  }
};
