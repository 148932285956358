export default (data, props) => {
  let signers = [];
  Object.keys(props).forEach((propertyName) => {
    const prop = props[propertyName];
    if (!prop.signer || !data[propertyName]) {
      return;
    }

    signers = signers.concat(data[propertyName]);
  });

  return signers
    .filter(Boolean)
    .map((signer) => signer.id)
    .filter(Boolean)
    .filter((signer, index, self) => self.indexOf(signer) === index)
    .map((id) => signers.find((signer) => signer.id === id));
};
