import { isEmptyValue } from "./isEmptyValue";

export function deleteEmptyValues(obj: any): any {
  if (typeof obj === "object") {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        const child = deleteEmptyValues(obj[key]);
        let isEmpty = true;
        for (const objKey in child) {
          if (!isEmptyValue(child[objKey])) {
            isEmpty = false;
            break;
          }
        }
        if (isEmpty) delete obj[key];
      } else if (isEmptyValue(obj[key])) {
        delete obj[key];
      }
    }
  }
  return obj;
}
