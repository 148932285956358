import React, { Fragment } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";
import { CustomInput } from "../../../components";
import { Button } from "../../../components";

import { translate } from "react-translate";

const CheckCabinetLayout = ({
  t,
  code,
  onChangeCode,
  onClickCheck,
  onClearCode,
  error,
}) => {
  return (
    <Fragment>
      <CustomInput
        labelText={t("ENTER_CODE")}
        formControlProps={{ fullWidth: true }}
        error={!!error}
        success={!error}
        helperText={error || t("CODE_DESCRIPTION")}
        showErrors={true}
        onClear={onClearCode}
        setId={setComponentsId("custom-input")}
        inputProps={{
          value: code,
          onChange: onChangeCode,
          error: !!error,
        }}
      />
      <Button
        color="yellow"
        onClick={onClickCheck}
        setId={setComponentsId("check-cabinet-btn")}
      >
        {t("SEND_REQUEST")}
      </Button>
    </Fragment>
  );
};

CheckCabinetLayout.propTypes = {
  error: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onClickCheck: PropTypes.func.isRequired,
  onClearCode: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
};

export default translate("CheckCabinet")(CheckCabinetLayout);
