export default {
  CONFERENCES_LIST: "Конференції",
  MY_REQUESTS: "Мої заяви",
  FORM_GENERATOR: "Форм генератор",
  CASE_LIST: "Мої справи",
  STATE_REGISTERS: "Державні реєстри",
  JUDGE_CASE_LIST: "Справи судді",
  CLAIM_LIST: "Заяви",
  SHARE_LIST: "Довіреності",
  SHARE_LIST_AND_ORDERS: "Довіреності/Ордери",
  CLAIM_TRASH_LIST: "Кошик",
  CHECK_CABINET: "Наявність кабінету",
  DOCUMENTS_OF_SIDES: "Документи сторін",
  EXECUTIVE_DOCS: "Виконавчі документи",
  EXECUTIVE_ORDERS: "Документи ВП",
  VIDEO_CONNECTION: "Відеозв'язок",
  EDRSR: "ЄДРСР",
  INVOLVE_SPECIALIST: "Залучення фахівця",
  NOTIFICATIONS: "Повідомлення",
  COURT_SESSION_PROTOCOL: "Протокол засідання",
  STATISTICS: "Статистика",
  EXECUTIVE: "Виконавчі документи",
  ISPRO: "Кадровий облік",
  ACCOUNTANT: "Бухгалтерський облік",
  REGISTRY: "Реєстр судових рішень",
  FEMIDA: "Феміда",
  FAVORITES: "Вибране",
  POLICE_LIST: "Клопотання слідчого",
  NABU_LIST: "Клопотання прокурора",
  APPROVAL_DOCUMENT_LIST: "Погодження",
  ESITS_KNOWLEDGE_BASE: "База знань ЄСІТС",
};
