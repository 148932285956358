import { useState } from "react";
import { Button } from "../../../components";
import { PERSON_TYPE_CODES } from "../constants/person.type.codes";
import humanDateFormat from "../../../helpers/humanDateFormat";
import {
  InfoBlockWrapper,
  InfoBlock as infoBlock,
} from "../layout/layout.components";

export const DebtorInfo = ({ classes, debtors, t }) => {
  const [isOpenDebtors, setIsOpenDebtors] = useState(false);

  return (
    <InfoBlockWrapper
      classes={classes}
      title={t("DEBTOR")}
      button={
        debtors?.length > 1 && (
          <Button
            onClick={() => setIsOpenDebtors(!isOpenDebtors)}
            variant="text"
            color="transparent"
            autoFocus={true}
            size="small"
          >
            {t(!isOpenDebtors ? "SHOW_ALL" : "HIDE_ALL")}
          </Button>
        )
      }
    >
      <div>
        {debtors?.length && isOpenDebtors
          ? debtors.map((debtor) => {
              return infoBlock(classes, "", [
                PERSON_TYPE_CODES.isPerson.includes(debtor?.personTypeCode)
                  ? [t("PERSON_NAME_LABEL"), debtor?.name]
                  : [t("ORG_NAME_LABEL"), debtor?.name],
                PERSON_TYPE_CODES.isPerson.includes(debtor?.personTypeCode)
                  ? [t("PERSON_CODE_LABEL"), debtor?.code]
                  : [t("ORG_CODE_LABEL"), debtor?.code],
                PERSON_TYPE_CODES.isPerson.includes(debtor?.personTypeCode)
                  ? [t("BIRTH_DATE"), humanDateFormat(debtor?.birthDate)]
                  : [t("REGISTRY_DATE"), humanDateFormat(debtor?.birthDate)],
                [t("PERSON_TYPE_NAME"), debtor?.personTypeName],
                [
                  t("IS_ACTIVE_LABEL"),
                  debtor?.isActive ? t("ACTIVE_STATE") : t("NOT_ACTIVE_STATE"),
                ],
                [
                  t("ADDRESS_LABEL"),
                  `${debtor?.postIndex || ""} ${debtor?.address || ""}`.trim(),
                ],
                [t("PHONE_LABEL"), debtor?.phone],
              ]);
            })
          : debtors?.length > 0 &&
            infoBlock(classes, "", [
              PERSON_TYPE_CODES.isPerson.includes(debtors?.[0]?.personTypeCode)
                ? [t("PERSON_NAME_LABEL"), debtors?.[0]?.name]
                : [t("ORG_NAME_LABEL"), debtors?.[0]?.name],
              PERSON_TYPE_CODES.isPerson.includes(debtors?.[0]?.personTypeCode)
                ? [t("PERSON_CODE_LABEL"), debtors?.[0]?.code]
                : [t("ORG_CODE_LABEL"), debtors?.[0]?.code],
              PERSON_TYPE_CODES.isPerson.includes(debtors?.[0]?.personTypeCode)
                ? [t("BIRTH_DATE"), humanDateFormat(debtors?.[0]?.birthDate)]
                : [
                    t("REGISTRY_DATE"),
                    humanDateFormat(debtors?.[0]?.birthDate),
                  ],
              [t("PERSON_TYPE_NAME"), debtors?.[0]?.personTypeName],
              [
                t("IS_ACTIVE_LABEL"),
                debtors?.[0]?.isActive
                  ? t("ACTIVE_STATE")
                  : t("NOT_ACTIVE_STATE"),
              ],
              [
                t("ADDRESS_LABEL"),
                `${debtors?.[0]?.postIndex || ""} ${
                  debtors?.[0]?.address || ""
                }`.trim(),
              ],
              [t("PHONE_LABEL"), debtors?.[0]?.phone],
            ])}
      </div>
    </InfoBlockWrapper>
  );
};
