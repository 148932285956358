export default {
  sign: "Підпис",
  stamp: "Печатка",
  ACSK_SERVER: "Сервер АЦСК", // 'Сервер кваліфікованого надавача електронних довірчих послуг / АЦСК',
  ACSK_SERVER_HINT:
    "Виберіть кваліфікованого надавача електронних довірчих послуг / акредитований центр сертифікації ключів",
  SELECT_SERVER:
    "Виберіть кваліфікованого надавача електронних довірчих послуг / акредитований центр сертифікації ключів",
  KEY: "Ключ",
  KEY_HINT: "Виберіть файл особистого ключа на своєму комп'ютері",
  SELECT_KEY: "Виберіть файл особистого ключа на своєму комп'ютері",
  PASSWORD: "Пароль",
  PASSWORD_HINT: "Введіть пароль від особистого ключа",
  FILL_PASSWORD: "Введіть пароль від особистого ключа",
  SIGN: "Продовжити",
  SIGNING_DATA_ERROR: "Помилка",
  CLOSE_DIALOG: "Закрити",
  FILE_KEY_SIGN_METHOD: "Файловий ключ",
  HARDWARE_KEY_SIGN_METHOD: "Апаратний ключ",
  HARDWARE_KEY_SIGN_METHOD_NOT_SUPPORTED_MOBILE:
    "Апаратний ключ не підтримується в мобільній версії",
  HARDWARE_KEY_SIGN_METHOD_NOT_SUPPORTED_BROWSER:
    "Апаратний ключ не підтримується вашим браузером",
  SELECT_KM_TYPE: "Тип пристрою",
  SELECT_KM_DEVICE: "Пристрій",
  TYPE_HINT: "Виберіть тип пристрою",
  DEVICE_HINT: "Виберіть пристрій",
  SELECT_TYPE: "Виберіть тип пристрою",
  SELECT_DEVICE: "Виберіть пристрій",
  PROXY_SETTING: "Налаштування проксі сервера",
  PROXY_SETTINGS_ENABLE: "Використовувати проксі сервер",
  PROXY_SETTINGS_ADDRESS: "Адреса проксі",
  PROXY_SETTINGS_PORT: "Порт",
  PROXY_SETTINGS_ANONYMOUS: "Анонімно",
  PROXY_SETTINGS_USER: "Користувач",
  PROXY_SETTINGS_PASSWORD: "Пароль",
  ACSKServer: "Кваліфікований надавач електронних довірчих послуг",
  ACSKServerHint:
    "Виберіть центр сертифікацій, який видав вам електронний підпис",
  SelectServer:
    "Виберіть центр сертифікацій, який видав вам електронний підпис",
  Key: "Файл ключа",
  KeyHint: "Виберіть файл особистого ключа на своєму комп'ютері",
  SelectedKey: "Ключ",
  SelectedKeyHint: "Оберіть ключ, печатку або підпис",
  SelectKey: "Виберіть файл особистого ключа на своєму комп'ютері",
  Password: "Пароль",
  PasswordHint: "Введіть пароль від особистого ключа",
  FillPassword: "Введіть пароль від особистого ключа",
  Sign: "Підписати",
  SigningDataError: "Помилка",
  Cancel: "Скасувати",
  CloseDialog: "Закрити",
  FileKeySignMethod: "Файловий ключ",
  HardwareKeySignMethod: "Апаратний ключ",
  IdGovWidget: "ID.GOV.UA",
  HardwareKeySignMethodNotSupportedMobile:
    "Апаратний ключ не підтримується в мобільній версії",
  HardwareKeySignMethodNotSupportedBrowser:
    "Апаратний ключ не підтримується вашим браузером",
  SelectKmType: "Тип пристрою",
  SelectKmDevice: "Пристрій",
  TypeHint: "Виберіть тип пристрою",
  DeviceHint: "Виберіть пристрій",
  SelectType: "Виберіть тип пристрою",
  SelectDevice: "Виберіть пристрій",
  ProxySettings: "Проксі",
  ProxySettingsTitle: "Налаштування проксі сервера",
  ProxySettingsEnable: "Використовувати проксі сервер",
  ProxySettingsAddress: "Адреса проксі",
  ProxySettingsPort: "Порт",
  ProxySettingsAnonymous: "Анонімно",
  ProxySettingsUser: "Користувач",
  ProxySettingsPassword: "Пароль",
  CantDetectACSK:
    "Нажаль, ми не змогли автоматично визначити центр сертифікацї вашого ключа",
  ACSKAutoDetect: "Визначити автоматично",
  DropFiles: "Перетягніть сюди файл ключа або {{link}}",
  UploadFiles: "оберіть його на своєму носієві",
  DropFilesLimits: "Підтримуються формати: .jks, .pfx, .pk8, .zs2, .dat",
  DropedFile: "Файл ключа:",
  DropNewFile: "Завантажити інший файл",
  Refresh: "Оновити",
  CHECK_CERTIFICATE_ERROR:
    "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено",
  INVALID_SIGN_METHOD: "Не вірний метод підписання",
};
