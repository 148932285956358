/* 
  ... ... С Т А Т У С И ... ... .
  -6 CANT_ISSUE_ECOURT Не може бути подано в електронній формі
  -5 CANT_ISSUE_TO_COLLECTOR Не може бути вручено стягувачу в електронній формі
  -4 NOT_FOR_ASVP Не підлягає подачі до АСВП
  -3 NOT_ENFORCEABLE Не підлягає виконанню
  -2 NOT_ISSUED_TO_DEBTOR Не вручено Боржнику
  -1 CANT_ISSUE_TO_DEBTOR Не може бути вручено боржнику в електронній формі
  ... ... ... ... ... ... ... ...
  0 CAME_FROM_COURT Надійшов з суду
  1	EXECUTE_IMMEDIATE	Негайне виконання => Повторна подача
  2	TO_EXECUTE_AUTO	Автоматична подача
  3	TO_EXECUTE	До виконання
  4	NO_DATE_FORCE	Не набрав законної сили
  5	MISSED_DEADLINE	Пропущено строк пред’явлення
  6	PAYMENT_TERM	Строк добровільної сплати
  7	PAID	Сплачено
  8	SENDING	Відправка до АСВП
  9	SENDING_ERROR	Помилка відправки до АСВП
  10	RECEPTION_ERROR	Помилка прийому до АСВП
  11	FAULT	Заява відхилена
  12	WAITING_PROCESSING	На обробці
  13	WAITING	Очікує прийняття
  14	REGISTERED	Зареєстровано
  15	ACCEPTED	Прийнятий до виконання
  16	DIRECTED	Направлений за належністю
  17	REFERRED	Переданий до підприємства
  18	TRANSFERRED	Переданий до ліквідаційної комісії
  19	UNACCEPTED	Повернений без прийняття
  20	REJECTED	Повернений без виконання
  21	EXECUTED	Виконаний
  22	CANCELED	Постанову скасовано
  23	RETCREDITOR	Повернений стягувачу
  24	RETURNED	Повернений до органу, що видав
*/

export default {
  [-1]: {
    background: "#d1bc1f",
  },
  [-2]: {
    background: "#E0908D",
  },
  [-3]: {
    background: "#C7495C",
  },
  [-4]: {
    background: "#C7495C",
  },
  [-5]: {
    background: "#E0908D",
  },
  [-6]: {
    background: "#C7495C",
  },
  0: {
    background: "#d9d9d9",
  },
  1: {
    background: "#d1bc1f",
  },
  2: {
    background: "#d1bc1f",
  },
  3: {
    background: "#d1bc1f",
  },
  4: {
    background: "#d9d9d9",
  },
  5: {
    background: "#ffc000",
  },
  6: {
    background: "#85a62d",
  },
  7: {
    background: "#00b050",
  },
  8: {
    background: "#00b0f0",
  },
  9: {
    background: "#ff0000",
  },
  10: {
    background: "#ff0000",
  },
  11: {
    background: "#ff0000",
  },
  12: {
    background: "#0070c0",
  },
  13: {
    background: "#0070c0",
  },
  14: {
    background: "#92d050",
  },
  15: {
    background: "#92d050",
  },
  16: {
    background: "#92d050",
  },
  17: {
    background: "#92d050",
  },
  18: {
    background: "#92d050",
  },
  19: {
    background: "#ffc000",
  },
  20: {
    background: "#ffc000",
  },
  21: {
    background: "#00b050",
  },
  22: {
    background: "#ffc000",
  },
  23: {
    background: "#ffc000",
  },
  24: {
    background: "#ffc000",
  },
  26: {
    background: "#ff0000",
  },
  27: {
    background: "#00b0f0",
  },
  28: {
    background: "#ff0000",
  },
};
