import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import RegisterChip from "./RegisterChip";

const InstanceCode = ({ t, value, ...rest }) => {
  const valueArray = value.map((id) => ({ id, name: t("INSTANCE" + id) }));

  return (
    <RegisterChip
      value={value}
      valueKey="instance_code"
      title="INSTANCE"
      valueArray={valueArray}
      {...rest}
    />
  );
};

InstanceCode.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  t: PropTypes.func.isRequired,
};

InstanceCode.defaultProps = {
  value: [],
};

export default translate("SearchPage")(InstanceCode);
