export default {
  IS_USER: "Зареєстровано Електронний кабінет",
  CASE_LIST: "Мої справи",
  CASE_LIST2: "Список судових справ",
  JUDGE_CASE_LIST: "Справи судді",
  JUDGE_CASE_LIST2: "Список судових справ",
  PROCEEDING_LIST: "Провадження",
  PROC_LIST: "Провадження по справі",
  PROC_LIST2: "Провадження по справі №: {{number}} від {{date}}",
  DOC_LIST: "Документи по справі",
  DOC_LIST1: "Документи, що надійшли від суду",
  DOC: "Документ",
  DOC_TITLE1:
    "Документи користувача по провадженню № {{proc}} до справи №: {{number}} від {{date}}",
  DOC_TITLE2: "Документи користувача по справі №: {{number}} від {{date}}",
  DOC_LIST2:
    "Документи по провадженню № {{proc}} до справи №: {{number}} від {{date}}",
  DOC_LIST3: "Документи по справі №: {{number}} від {{date}}",
  DOC_TITLE: "Документ",
  DOC_TITLE3:
    'Документ "{{doc}}" по провадженню № {{proc}} по справі №: {{number}} від {{date}}',
  SHARE_SUBTITLE: "до справи №: {{number}} від {{date}}",
  SHARE_SUBTITLE2:
    "до провадження № {{proc}} по справі №: {{number}} від {{date}}",
  CASE_NUMBER: "Номер справи",
  CASE_COURT: "Суд",
  CASE_TYPE: "Тип",
  CASE_ROLE: "Мій процесуальний статус у справі",
  CASE_STATUS: "Статус",
  OWNER: "Заявник/Представник",
  CREATE_NEW: "Створити заяву",
  CREATE_NEW_DESICION: "Створити рішення",
  FIRST_DOC_DATE: "Дата першого документа",
  LAST_DOC_DATE: "Дата останнього документа",
  CASE_CREATING_DATE: "Дата реєстрації першого документа по справі",
  CASE_UPDATING_DATE: "Дата надходження останнього документа по провадженню",
  PROC_CREATING_DATE: "Дата реєстрації першого документа по провадженню",
  PROC_UPDATING_DATE: "Дата надходження останнього документа по провадженню",
  CASE_INFO_BUTTON: "Інформація про справу",
  FAVORITES_BUTTON: "додати до вибраного",
  CASES_LINK: "перейти до справ",
  UN_FAVORITES_BUTTON: "видалити з вибраного",
  DIALOG_TITLE: "Справа №: {{number}} від {{date}}",
  COURT: "Суд",
  COURT_COMPOSE: "Склад суду",
  PARTIES_TO_DISPUTE: "Сторони спору",
  SUBJECT_OF_SUIT: "Предмет позову",
  STAGE_CONS: "Стадія розгляду",
  CLOSE: "Закрити",
  DOCUMENTS_BUTTON: "Документи по справі",
  DOCUMENTS_TITLE: "Документи по справі №: {{number}} від {{date}}",
  PROC_DISCRIPTION: "Пояснення",
  BACK: "Назад",
  BACK_TO_CASE: "Назад до списку справ",
  BACK_TO_PROC: "Назад до списку проваджень",
  BACK_TO_DOCUMENTS: "Назад до списку документів",
  DOCUMENT_NUMBER: "Номер документа",
  DOCUMENT_PROCCEDING: "Номер провадження",
  CREATE_DATE: "Дата",
  DOCUMENT_NAME: "Назва",
  ATTACHES: "Додатки",
  STATIC_FILE: "Сформований документ",
  SCAN_FILE: "Відсканований документ",
  PAYMENT_ERROR:
    "Для можливості скачати відео або аудіо запис судового засідання необхідно сплатити судовий збір.",
  DOWNLOAD_ERROR: "На даний момент немає можливості скачати документ",
  DISPLAYED_CASES: "з {{from}} по {{to}} із {{total}}",
  COUNT_CASES: "Відображати:",
  TOTAL: "Всього: {{total}}",
  NOT_PROC_DOC:
    "Перелік документів по справі, які не відносяться до перерахованих вище проваджень",
  PROC_DOC: "Документи користувача",
  FULL_NAME: "Cправа №: {{number}} від {{createdAt}}",
  CASE_NAME_WITH_MEMBERS: "{{fullName}}, сторони спору: {{members}}",
  PROC_FULL_NAME: "Провадження №: {{number}} від {{createdAt}}",
  DOC_FULL_NAME: "Документ №: {{number}} від {{createdAt}}",
  MY_PROC_DOC_FULL_NAME: "{{description}} №: {{number}} від {{createdAt}}",
  NUMBER: "не призначено",
  WITHOUT_NUMBER: "б/н",
  COURT_NAME_ERROR:
    "Подача у {{courtName}} буде можлива після підключення його до Електронного Суду",
  APPEAL_COURT_NAME_ERROR:
    "Подача апеляцій у {{courtName}} буде можлива після підключення його до Електронного Суду",
  CASSATION_COURT_NAME_ERROR:
    "Подача коссацій у {{courtName}} буде можлива після підключення його до Електронного Суду",
  APPEAL_COURT_NOT_FOUND: "Апеляційний суд не визначено",
  COURT_769: "Київський апеляційний",
  BOOKMARK: "Закладки",
  NEW_BOOKMARK: "Нова закладка",
  ADD_NEW_BOOKMARK: "Зробити закладку",
  NEW_BOOKMARK_LABEL: "Назва нової закладки",
  SELECT_BOOKMARK: "Вибрати закладку",
  DELETE_BOOKMARK: "Видалити закладку",
  REMEMBER_STATE: "Запам'ятовувати стан при виході",
  FROM: "Від",
  EDIT_CLAIM: "Редагувати заяву",
  STATUS: "Статус",
  DELETE: "Видалити",
  DISPLAYED_ROWS: "з {{from}} по {{to}} із {{total}}",
  ROWS_PER_PAGE: "Показувати на сторінці",
  COURT_TYPE: "Інстанція",
  FAVORITES: "Вибране",
  FAVORITES_EMPTY_PAGE_TITLE: "Ви не додали жодної справи до вибраного",
  FAVORITES_EMPTY_PAGE_DESCRIPTION:
    "Для швидкого доступу до справ, ви можете додавати їх до вибраного. Для цього, перейдіть до переліку справ та відмітьте вибрані справи зірочкою",
  CASE_EMPTY_PAGE_TITLE: "Справ немає",
  CASE_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись ваші судові справи та справи, до яких вам надано доступ",
  REPRESENTATIVE_COMPLAINANT: "Представник скаржника",
  REPRESENTATIVE: "{{permissionName}} (Представник)",
  NOT_FOUND_ROLE: "Не визначено",
  NOT_FOUND: "За вашим запитом нічого не знайдено",
  "Invalid JSON schema.": "Шаблон пошкоджений",
  UNKNOWN_ERROR: "Щось пішло не так: {{error}}. Видалити з вибраного?",
  // NEED_ACCESS: 'Ви більше не маєте доступу до справи.',
  NEED_ACCESS: "Ви не маєте доступу до справи з поточною роллю.",
  NEED_DOCUMENT_ACCESS: "Ви більше не маєте доступу до документу ",
  DELETED_DOC: "або документ був вилучений",
  DELETE_FROM_FAVORITES: " Видалити з вибраного?",
  YES: "TAK",
  NO: "НІ",
  ADD_SHARE: "Створити довіреність",
  ADD_ORDER: "Створити ордер",
  CHANGE_SHARE: "управління довіреностями",
  EDIT_SHARE: "Редагувати довіреність на {{userName}}",
  READONLY_SHARE: "Переглянути довіреність на {{userName}}",
  VIEW_SHARE_FROM: "Переглянути довіреність від {{userName}}",
  VIEW_SHARE_TO: "Переглянути довіреність видану {{userName}}",
  RESHARE_SHARE: "Передоручити довіреність від {{userName}}",
  UNKNOWN_SHARE_ERROR: "Щось пішло не так: {{error}}",
  SIGN_CERTIFICATE_PEM_NOT_MATCH_USERS_PEM:
    "Сертифікат не співпадає з сертифікатом користувача.",
  TO_PROTEST: "Оскаржити",
  CLOSED_ROLES_LIST: "{{users}} і ще {{total}}",
  CLOSE_ROLES: "сховати",
  OPEN_ROLES: "показати всіх",
  DNZS_DATE: "Дата набрання законної сили",
  DNZS_DATE_SHORT: "ДНЗС",
  ERDR_NUMBER: "Номер в ЄРДР",
  ERDR_NUMBER_HINT: "Номер кримінального провадження",
  CHECK_CERTIFICATE_ERROR:
    "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено",
  DOWNLOAD_ZIP: "Скачати провадження в ZIP",
  SELECT_ALL: "Вибрати все",
  SELECT_NOTES: "Вибрати чернетки",
};
