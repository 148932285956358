import { blackColor, lightGrayColor } from "../../variables/styles";

export default {
  flexWrap: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    paddingBottom: 0,
    fontSize: "1.3125rem",
  },
  formControl: {
    marginTop: "17px",
    width: "100%",
  },
  radio: {
    color: blackColor,
  },
  radioTitle: {
    display: "block",
    fontSize: "16px",
    marginTop: "20px",
  },
  radioDescription: {
    opacity: "0.54",
    fontSize: "12px",
  },
  checkbox: {
    color: blackColor,
  },
  checkboxLabel: {
    width: "100%",
    "&::before": {
      content: '""',
      borderTop: `1px solid ${lightGrayColor}`,
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  actions: {
    justifyContent: "flex-start",
  },
  changeTitle: {
    display: "flex",
    margin: "27px 0",
  },
  chainOfResharingsTitle: {
    flexDirection: "column",
    alignItems: "flex-start",
    margin: 0,
  },
  chainOfResharingsBlock: {
    margin: "7px 0",
  },
  downArrow: {
    transform: "rotate(90deg)",
  },
  userBlock: {
    display: "flex",
    alignItems: "center",
    minWidth: "48px",
    justifyContent: "center",
    "@media (max-width:767px)": {
      flexWrap: "wrap",
      justifyContent: "left",
    },
  },
  userIcon: {
    fontSize: "48px",
    marginRight: "10px",
    opacity: "0.54",
    "& span": {
      fontSize: "1em",
    },
  },
  arrow: {
    opacity: "0.54",
    fontWeight: "300",
  },
  userContent: {},
  userName: {
    display: "block",
    fontSize: "16px",
  },
  userPermission: {
    display: "block",
    opacity: "0.54",
    fontSize: "12px",
  },
};
