import edsService from "../../../services/eds";
import { addEncodeKey, setWizardStates } from "../../../actions/claim";
import {
  addPermission,
  requestDocumentPermissionsList,
} from "../../../actions/permissions";

import promiseChain from "../../../helpers/promiseChain";
import keyToUint8Array from "../../../helpers/keyToUint8Array";

const { TextDecoder /* , TextEncoder */ } = window;

export function handleDecryptDocument(encryptionKey) {
  const {
    claim,
    claim: { digitalDocumentData },
  } = this.props;
  const { signer } = edsService;

  addEncodeKey(encryptionKey, claim.id);
  if (!digitalDocumentData) {
    return setWizardStates({ encryptionKey });
  }

  return promiseChain([
    () =>
      signer.execute(
        "UnprotectDataByPassword",
        digitalDocumentData,
        encryptionKey,
        false
      ),
    (unprotectedData) => keyToUint8Array(unprotectedData),
    (unprotectedData) => new TextDecoder("utf-8").decode(unprotectedData),
    (unprotectedData) => JSON.parse(unprotectedData),
    (unprotectedData) =>
      setWizardStates({
        encryptionKey,
        claim: {
          ...claim,
          digitalDocumentData: unprotectedData,
        },
      }),
  ]);
}

export function handleAddEncryptedPermission(
  encryptedKey,
  signer,
  resetPrivateKey
) {
  const { claim, newSigners, encryptionKey } = this.props;

  return promiseChain([
    ...newSigners
      .filter(({ encodeCert }) => !!encodeCert)
      .map(
        ({ encodeCert, id }) =>
          () =>
            promiseChain([
              () => signer.execute("SaveCertificate", encodeCert),
              () => signer.execute("ParseCertificate", encodeCert),
              (crt) =>
                signer.execute(
                  "EnvelopDataEx",
                  [crt.issuer],
                  [crt.serial],
                  false,
                  encryptionKey,
                  true
                ),
              (targetKey) =>
                promiseChain([
                  () =>
                    addPermission({
                      userId: id,
                      permissionType: "allowCommit",
                      targetId: claim.id,
                      targetKey,
                    }),
                  () => requestDocumentPermissionsList(claim.id),
                  (permissions) => setWizardStates({ permissions }),
                ]),
            ])
      ),
    resetPrivateKey,
    () => setWizardStates({ newSigners: null }),
  ]);
}
