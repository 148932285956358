export const PERSON_TYPE_CODES = {
  isPerson: ["PHYSICAL_PHYSICAL", "PHYSICAL_BUSINESSMAN"],
  isLegal: [
    "LEGAL_LEGAL",
    "LEGAL_FORBID",
    "LEGAL_COMMUNAL",
    "LEGAL_COMPANY",
    "LEGAL_LOCAL",
    "LEGAL_STATE",
    "LEGAL_BANK",
    "LEGAL_BANK_INTERIM",
    "STATE_STATE",
  ],
};
