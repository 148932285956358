import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import { Button } from "../../../../components";

const renderRow = (part, id, dictionary, setId, classes) => {
  const partType = (dictionary.partTypes || [])
    .filter((type) => type.id === part.type)
    .shift();
  return (
    <TableRow key={id} id={setId(`row-${id}`)}>
      <TableCell
        padding="checkbox"
        id={setId(`check-${id}`)}
        className={classes.cell}
      >
        {id + 1}
      </TableCell>
      <TableCell id={setId(`name-${id}`)} className={classes.cell}>
        {partType.name}
      </TableCell>
      <TableCell align="right" id={setId(`sum-${id}`)} className={classes.cell}>
        {part.sum}
      </TableCell>
    </TableRow>
  );
};

const PaymentForm = ({
  t,
  classes,
  dictionary,
  value: { paymentParts, totalSum },
  busy,
  setId,
  toggleUseUserAmount,
  handleChangeUserAmount,
  handleOpenPayWindow,
  otherAmount,
  newSum,
  noCaseError,
  form,
}) => (
  <Fragment>
    <Paper className={classes.root} elevation={1} id={setId("")}>
      <Table className={classes.table} id={setId("table")}>
        <TableHead id={setId("table-head")}>
          <TableRow id={setId("table-head-row")}>
            <TableCell
              padding="checkbox"
              style={{ width: 10 }}
              id={setId("table-head-check")}
              className={classes.cell}
            />
            <TableCell id={setId("table-head-title")} className={classes.cell}>
              {t("PAYMENT_TITLE")}
            </TableCell>
            <TableCell
              align="left"
              id={setId("table-head-sum")}
              className={classes.cell}
            >
              {t("PAYMENT_SUM")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody id={setId("table-body")}>
          {(paymentParts || []).map((part, id) =>
            renderRow(part, id, dictionary, setId, classes)
          )}
        </TableBody>
      </Table>
    </Paper>

    <Table className={classes.table} id={setId("table-2")}>
      <TableBody id={setId("table-2-body")}>
        <TableRow id={setId("table-2-row-1")}>
          <TableCell id={setId("table-2-cell-1")} className={classes.cell}>
            <Typography
              className={classes.amountText}
              component="h2"
              id={setId("table-2-cell-1-title")}
            >
              {t("PAYMENT_AMOUNT")}:
            </Typography>
          </TableCell>
          <TableCell
            align="right"
            id={setId("table-2-cell-2")}
            className={classes.cell}
          >
            <Typography
              className={classes.amount}
              component="h2"
              id={setId("table-2-cell-2-title")}
            >
              {totalSum || ""}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow id={setId("table-2-row-2")}>
          <TableCell
            id={setId("table-2-row-2-cell-1")}
            className={classes.cell}
          >
            <FormControlLabel
              id={setId("switch-label")}
              label={t("USE_USER_AMOUNT")}
              control={
                <Switch
                  checked={otherAmount}
                  onChange={toggleUseUserAmount}
                  color="primary"
                />
              }
            />
          </TableCell>
          <TableCell
            align="right"
            id={setId("table-2-row-2-cell-2")}
            className={classes.cell}
          >
            {otherAmount && (
              <TextField
                name="type"
                onChange={handleChangeUserAmount}
                margin="normal"
                value={newSum}
                inputProps={{
                  className: cx(classes.amount, +newSum === 0 && classes.error),
                }}
                id={setId("amount-input")}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow id={setId("table-2-row-3")}>
          <TableCell
            id={setId("table-2-row-3-cell-1")}
            className={classes.cell}
          >
            <Typography
              className={classes.amountText}
              component="h2"
              id={setId("table-2-row-3-cell-1-title")}
            >
              {t("PAYMENT_USER_AMOUNT")}:
            </Typography>
          </TableCell>
          <TableCell
            align="right"
            id={setId("table-2-row-3-cell-2")}
            className={classes.cell}
          >
            <Typography
              className={cx(classes.amount, +newSum === 0 && classes.error)}
              component="h2"
              id={setId("table-2-row-3-cell-2-title")}
            >
              {newSum}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <div id={setId("actions")} title={noCaseError}>
      <Button
        color="yellow"
        disabled={busy || !!noCaseError || !form}
        onClick={handleOpenPayWindow}
        setId={(elementName) => setId(`pay-${elementName}`)}
      >
        {t("PAY")}
      </Button>
    </div>
  </Fragment>
);

PaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dictionary: PropTypes.object.isRequired,
  value: PropTypes.object,
  busy: PropTypes.bool.isRequired,
  noCaseError: PropTypes.string.isRequired,
  form: PropTypes.object,

  toggleUseUserAmount: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  handleChangeUserAmount: PropTypes.func.isRequired,
  handleOpenPayWindow: PropTypes.func.isRequired,
  otherAmount: PropTypes.bool.isRequired,
  newSum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

PaymentForm.defaultProps = {
  value: {},
  form: null,
};

export default PaymentForm;
