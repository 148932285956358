import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import AttachList from "../../../components/Attach/AttachList";

import {
  FormControl,
  TextField,
  Snackbar,
  FormHelperText,
} from "@material-ui/core";
import {
  addUserMeta,
  uploadDocument,
  downloadScanFile,
} from "../../../actions/usermeta";

import cx from "classnames";
import { today } from "../../../helpers/humanDateFormat";

import { Button } from "../../../components";
import SelectFilesDialog from "../../../components/SelectFilesDialog";
import CustomDatePicker from "../../../components/CustomInput/CustomDatePicker";

const validateDocFields = {
  passport_number: /^\d{6,9}$/i,
  issue_date: { test: (text = "") => text.length === 10 },
  issue_by: { test: (text = "") => !!text.length },
};

class Passport extends Component {
  state = {
    documents: this.props.userMetaData.passport || {},
    errors: {},
    scanDocDialogOpen: false,
    saving: false,
    showNotification: false,
  };

  toggleDialog = () =>
    this.setState({ scanDocDialogOpen: !this.state.scanDocDialogOpen });

  onChange = ({ target: { name, value } }) => {
    const { documents, errors } = this.state;
    if (value && validateDocFields[name]) {
      errors[name] = !validateDocFields[name].test(value);
    }
    this.setState({ documents: { ...documents, [name]: value }, errors });
  };

  onChangeDate = (name) => (date) => {
    const { documents, errors } = this.state;
    if (validateDocFields[name]) {
      errors[name] = !validateDocFields[name].test(date);
    }
    this.setState({ documents: { ...documents, [name]: date }, errors });
  };

  handleSave = () => {
    const { documents, errors } = this.state;
    const { userMetaData } = this.props;
    Object.keys(validateDocFields).forEach((key) => {
      errors[key] = !validateDocFields[key].test(documents[key]);
    });
    this.setState({ errors });
    const errorChecking = Object.keys(errors).find((key) => !!errors[key]);
    if (!errorChecking) {
      this.setState({ saving: true }, () =>
        addUserMeta({
          meta: { ...userMetaData, passport: documents },
        }).then(() =>
          this.setState({ saving: false, showNotification: true }, () => {
            setTimeout(() => this.setState({ showNotification: false }), 1000);
          })
        )
      );
    }
  };

  onUpload = (doc) => {
    const { documents } = this.state;
    documents.scanDocs = documents.scanDocs || [];
    documents.scanDocs.push(doc);
    this.setState({ documents, scanDocDialogOpen: false }, this.handleSave());
  };

  handleDeleteFile = (index) => () => {
    const { documents } = this.state;
    documents.scanDocs.splice(index, 1);
    this.setState({ documents }, this.handleSave());
  };

  handleDownload = (file) => () =>
    downloadScanFile(file.fileLink).then((data) => {
      if (!data.message) {
        this.props.setError("");
        return data;
      }
      return this.props.setError(this.props.t("DOWNLOAD_ERROR"));
    });

  render() {
    const { t, classes, setId } = this.props;
    const { documents, errors, scanDocDialogOpen, saving, showNotification } =
      this.state;
    return (
      <Fragment>
        <FormControl
          id={setId("serial-number-wrap")}
          className={cx(classes.formControl, classes.formMultiControl)}
        >
          <TextField
            id={setId("serial")}
            name="passport_serial"
            label={t("PASSPORT_SERIAL")}
            value={documents.passport_serial || ""}
            onChange={this.onChange}
          />
          <TextField
            id={setId("number")}
            className={classes.passportNumber}
            name="passport_number"
            label={t("PASSPORT_NUMBER")}
            helperText={t("PASSPORT_NUMBER_HELPER")}
            value={documents.passport_number || ""}
            error={errors.passport_number}
            onChange={this.onChange}
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          className={classes.formControl}
          margin="dense"
          id={setId("issue-by-wrap")}
        >
          <TextField
            id={setId("issue-by")}
            name="issue_by"
            label={t("ISSUE_BY")}
            helperText={t("ISSUE_BY_HELPER")}
            error={errors.issue_by}
            value={documents.issue_by || ""}
            onChange={this.onChange}
            margin="dense"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          className={classes.formControl}
          margin="dense"
          id={setId("issue-date-wrap")}
        >
          <CustomDatePicker
            label={t("ISSUE_DATE")}
            helperText={t("ISSUE_DATE_HELPER")}
            margin="dense"
            incomingFormat="DD/MM/YYYY"
            onChange={this.onChangeDate("issue_date")}
            error={errors.issue_date}
            date={documents.issue_date}
            id={setId("issue-date")}
            minDate="01/01/1900"
            maxDate={today().format("DD/MM/YYYY")}
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          className={classes.formControl}
          margin="dense"
          id={setId("registry-number-wrap")}
        >
          <TextField
            id={setId("registry-number")}
            name="registry_number"
            label={t("REGISTRY_NUMBER")}
            value={documents.registry_number || ""}
            error={errors.registry_number}
            onChange={this.onChange}
            margin="dense"
          />
        </FormControl>
        <FormControl
          id={setId("valid-date-wrap")}
          fullWidth={true}
          className={classes.formControl}
          margin="dense"
        >
          <CustomDatePicker
            label={t("VALID_DATE")}
            margin="dense"
            incomingFormat="DD/MM/YYYY"
            onChange={this.onChangeDate("valid_date")}
            error={errors.valid_date}
            date={documents.valid_date}
            id={setId("valid_date")}
            minDate="01/01/2000"
            maxDate="01/01/3000"
            required={false}
          />
        </FormControl>
        <FormHelperText id={setId("schema-form-helper")}>
          {t("REQUIRED_FIELDS")}
        </FormHelperText>
        <SelectFilesDialog
          open={scanDocDialogOpen}
          uploadAction={uploadDocument}
          onUpload={this.onUpload}
          handleClose={this.toggleDialog}
          fileName="doc"
          setId={(elementName) => setId(`select-files-dialog-${elementName}`)}
        />
        <AttachList
          handleDelete={this.handleDeleteFile}
          handleDownload={this.handleDownload}
          requestPreview={this.handleDownload}
          attaches={documents.scanDocs || []}
        />

        <Button
          color="yellow"
          onClick={this.toggleDialog}
          disabled={saving}
          id={setId("upload-button")}
          style={{ whiteSpace: "normal" }}
          setId={(elmentName) => setId(`upload-${elmentName}`)}
        >
          {t("UPLOAD")}
        </Button>
        <Button
          id={setId("save-button")}
          setId={(elmentName) => setId(`save-${elmentName}`)}
          disabled={saving}
          color="yellow"
          onClick={this.handleSave}
        >
          {t("SAVE_BUTTON")}
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showNotification}
          message={t("SAVED")}
        />
      </Fragment>
    );
  }
}

Passport.propTypes = {
  userMetaData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

Passport.defaultProps = {
  userMetaData: null,
};

export default Passport;
