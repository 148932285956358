import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import pdfStyles from "../../variables/styles/pdfDocument";
import attachStyles from "../../variables/styles/attaches";

const URL = window.URL || window.webkitURL;
const styles = { ...pdfStyles, ...attachStyles };

class HTMLPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
    };
  }

  componentDidMount() {
    const { file, url } = this.props;

    if (file) {
      if (
        typeof file === "string" &&
        file.startsWith("data:application/octet-stream;base64,")
      ) {
        const base64Content = file.split(",")[1];
        const binaryContent = atob(base64Content);

        const byteArray = Uint8Array.from(binaryContent, (char) =>
          char.charCodeAt(0)
        );

        const decoder = new TextDecoder("utf-8");
        const decodedHtml = decoder.decode(byteArray);

        const blob = new Blob([decodedHtml], {
          type: "text/html; charset=UTF-8",
        });
        const src = URL.createObjectURL(blob);
        this.setState({ src });
      } else if (file instanceof Blob || file instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const htmlContent = reader.result;
          const blob = new Blob([htmlContent], {
            type: "text/html; charset=UTF-8",
          });
          const src = URL.createObjectURL(blob);
          this.setState({ src });
        };
        reader.readAsText(file);
      } else {
        console.error("Отриманий об'єкт не є Blob, File або base64.");
      }
    } else if (url) {
      this.setState({ src: url });
    }
  }

  render() {
    const { fileName, classes } = this.props;
    const { src } = this.state;

    return (
      <div className={classes.htmlWrap}>
        <div className={classes.htmlScrollBox}>
          <div className={classes.htmlBox}>
            {src && (
              <iframe
                title={fileName}
                src={src}
                className={classes.htmlFrame}
                style={{ width: "100%", height: "600px", border: "none" }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

HTMLPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  fileName: PropTypes.string,
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  url: PropTypes.string,
};

HTMLPreview.defaultProps = {
  fileName: "Документ",
  url: "",
};

const styled = withStyles(styles)(HTMLPreview);

export default styled;
