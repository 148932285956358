import React, { Fragment } from "react";
import { IconButton } from "@material-ui/core";

import { OpenInNew, SaveAlt } from "@material-ui/icons";
import { BlockScreen } from "../../../components";
import PdfDocument from "../../../components/PDF";
import OpenInNewWindow from "../../../components/OpenInNewWindow";
import { pdfRequest } from "../../../actions/document";
import blobToBase64 from "../../../helpers/blobToBase64";
import { downloadStaticArchive } from "../../../actions/cases";
import { StateRegisterRequest } from "../../../actions/stateRegisters";
import downloadBase64Attach from "../../../helpers/downloadBase64Attach";
import getFileUrl from "../../../helpers/getFileUrl";

export interface ResponseViewProps {
  classes: any;
  docId?: string;
  register: any;
  request?: StateRegisterRequest;
  setId: (s: string) => string;
  t: (s: string) => string;
}

export interface ResponseViewState {
  doc?: any;
  pdf?: any;
  loading: boolean;
  url: any;
}

class ResponseView extends React.Component<
  ResponseViewProps,
  ResponseViewState
> {
  constructor(props: ResponseViewProps) {
    super(props);
    this.state = {
      doc: undefined,
      pdf: undefined,
      loading: false,
      url: undefined,
    };
  }

  async componentDidMount() {
    // console.log("ResponseView.componentDidMount");
    await this.pdfLoad();
  }

  async componentDidUpdate() {
    // console.log("ResponseView.componentDidUpdate");
  }

  private async pdfLoad() {
    const { docId } = this.props;
    let doc, pdf;
    if (docId) {
      try {
        doc = await pdfRequest(docId);
        pdf = await blobToBase64(doc);
      } catch (error) {
        console.log(error);
      }
    }
    const url = await getFileUrl(doc, "pdf", docId);
    this.setState({
      doc,
      pdf,
      url,
    });
  }

  download = async () => {
    this.setState({
      loading: true,
    });

    const { docId, register, request } = this.props;
    const { doc } = this.state;
    const name =
      `Запит до ${register.code} справа ${request?.caseNumber} від ${request?.reqDate} ${request?.id}`.replace(
        /[\\/,|]/g,
        "_"
      );
    const format = "application/pdf";

    try {
      const blob = await downloadStaticArchive(docId);
      const { document } = window;
      const URL = window.URL || window.webkitURL;
      const element = document.createElement("a");
      element.setAttribute("href", URL.createObjectURL(blob));
      element.setAttribute("download", name + ".zip");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      downloadBase64Attach(
        {
          userFileName: null,
          propsName: null,
          fileName: name + ".pdf",
          description: null,
          scanDocumentName: null,
          contentType: format,
        },
        doc
      );
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { setId, classes, t } = this.props;
    const { doc, pdf, url } = this.state;

    return (
      <Fragment>
        <IconButton
          color="inherit"
          onClick={this.download}
          title={t("SAVE_TO_DEVICE")}
          className={classes.menuButton}
        >
          <SaveAlt />
        </IconButton>
        <OpenInNewWindow
          url={url}
          className={classes.buttonLink}
          title={t("PREVIEW_LINK")}
        >
          <IconButton color="inherit" className={classes.menuButton}>
            <OpenInNew />
          </IconButton>
        </OpenInNewWindow>
        <PdfDocument
          pdf={pdf}
          doc={doc}
          setId={(elementName: string) => setId(`pdf-dopcument-${elementName}`)}
        />
        <BlockScreen open={this.state.loading} />
      </Fragment>
    );
  }
}
export default ResponseView;
