import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";

import { Icon, withStyles } from "@material-ui/core";
import { Button } from "../../components";

import styles from "../../variables/styles/pdfDocument";

const DOCPreview = ({ fileName, setId, docUrl, classes, handleDownload }) => (
  <div id={setId("wrap")}>
    <iframe
      src={`https://docs.google.com/viewer?url=${docUrl}&embedded=true&a=bi`}
      frameBorder={0}
      title={fileName}
    />
    {handleDownload && (
      <Button
        color="yellow"
        className={classes.pdfDownload}
        onClick={handleDownload}
        setId={(elementName) => setId(`download-${elementName}`)}
      >
        <Icon>save_alt</Icon>
      </Button>
    )}
  </div>
);

DOCPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  docUrl: PropTypes.string,
  setId: PropTypes.func,
  handleDownload: PropTypes.func,
  fileName: PropTypes.string,
};

DOCPreview.defaultProps = {
  setId: setComponentsId("img-preview"),
  docUrl: "",
  handleDownload: undefined,
  fileName: "Документ",
};

// decorate and export
export default withStyles(styles)(DOCPreview);
