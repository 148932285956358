export default {
  HEADER: "Єдиний Державний реєстр судових рішень",
  DOCUMENT_ID: "Номер рішення",
  CASE_NUMBER: "Номер судової справи",
  JUDGMENT_CODE: "Форма судового рішення",
  REGIONS: "Регіон",
  ADJUDICATION_DATE: "Дата ухвалення",
  DNZS_DATE: "Дата набрання законної сили",
  RECEIPT_DATE: "Дата надходження",
  COURT_CODE: "Код суду",
  COURT_NAME: "Найменування суду",
  JUDGE: "П.І.Б. судді",
  CASE_NUM: "Реєстраційний номер рішення",
  PROCEEDING_NUMBER: "Номер провадження",
  AUTHORIZE: "Увійти до кабінету",
  CATEGORIES_FILTER_GROUP: "Категорії справ",
  COURT_FILTER_GROUP: "Суди",
  CLAIM_FILTER_GROUP: "Справи",
  ADJUDICATION_FILTER_GROUP: "Судові рішення",
  DISPLAYED_ROWS: "з {{from}} по {{to}} із {{total}}",
  ROWS_PER_PAGE: "Показувати на сторінці",
  INSTANCE: "Інстанція",
  INSTANCE2: "Перша",
  INSTANCE3: "Апеляційна",
  INSTANCE4: "Касаційна",
  VALIDITY: "Чинність",
  VALIDITY_YES: "ТАК",
  VALIDITY_NO: "Ні",
  VALIDITY_PARTLY: "Частково",
  ADD_FILTER: "Додати фільтр",
  FILTER_NAME: "Назва",
  JUDGEMENT_FORMS: "Форма судового рішення",
  JUSTICE_KIND: "Форма судочинства",
  ADJUDICATION: "Період ухвалення",
  PUBLICATION: "Період надходження",
  MY_FILTERS: "Мої фільтри",
  CLEAR_ALL_FILTERS: "очистити фільтри",
  ERROR_LOADING_DOCUMENT: "Помилка завантаження документа. Спробуйте пізніше",
  TO_MAIN_PAGE: "електронний суд",
  LOADING: "завантаження...",
  ALL_WORDS_OR_PHRASES: "Усі слова або фрази",
  ALL_WORDS_OR_PHRASES_PLACEHOLDER: "Вкажіть слова або фрази",
  ANY_WORD_OR_PHRASE: "Хоча б одне слово або фраза",
  ANY_WORD_OR_PHRASE_PLACEHOLDER: "Вкажіть слова або фрази",
  NO_ONE_WORD_OR_PHRASE: "Жодного слова або фрази",
  NO_ONE_WORD_OR_PHRASE_PLACEHOLDER: "Вкажіть слова або фрази",
  SEARCH: "Пошук",
  CLEAR: "Очистити",
  ADJUCATION_END: "Дата ухвалення по",
  ADJUCATION_START: "Дата ухвалення від",
  PUBLICATION_END: "Дата надходження по",
  PUBLICATION_START: "Дата надходження від",
  DATE_START: "Дата від",
  DATE_END: "Дата по",
};
