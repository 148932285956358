import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CourtTypeFilterChip = ({ t, value, courtTypes, ...rest }) => (
  <Chip
    label={`${t("COURT_TYPE")}: ${
      (courtTypes || []).find(({ id }) => id === value).name
    }`}
    {...rest}
  />
);

CourtTypeFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  courtTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CourtTypeFilterChip.defaultProps = {
  value: "",
  courtTypes: [],
};

const translated = translate("ClaimList")(CourtTypeFilterChip);
export default connect(({ dictionary: { courtTypes } }) => ({ courtTypes }))(
  translated
);
