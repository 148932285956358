import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import customInputStyle from "../../../variables/styles/customInputStyle";
import DateRangePicker from "../../../components/CustomInput/DateRangePicker";
import FilterInput from "../../../components/FilterInput";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core/styles";

const Filters = ({
  classes,
  currentStatus,
  dataSource,
  handleChangeFilter,
  load,
  // loadData,
  // list,
  read,
  viewedStatus,
  unread,
  t,
  //statuses,
  setId,
  //display,
}) => (
  <FilterInput
    classes={classes}
    dataSource={dataSource}
    load={load}
    setId={setId}
  >
    <TextField
      select={true}
      name="is_read" // state is_read
      label={t("NOTE_VIEWED")}
      value={currentStatus}
      id={setId("select-status")}
      onChange={handleChangeFilter}
      SelectProps={{
        id: setId("select-state-filter"),
        SelectDisplayProps: {
          id: setId("select-state-filter-wrapper"),
        },
      }}
      className={classNames(classes.margin, classes.textField, classes.flex)} // classes.typeFilter, classes.flex
    >
      {viewedStatus.map(({ name, value, title }) => (
        <MenuItem key={name} value={value} id={setId(`status-${name}`)}>
          {title &&
            t(title, {
              unread,
              read,
              total: unread + read,
            })}
        </MenuItem>
      ))}
    </TextField>
    <DateRangePicker
      name="createdAt"
      label={t("DOCUMENT_STATUS")}
      setId={setId}
    />
  </FilterInput>
);

Filters.propTypes = {
  read: PropTypes.number.isRequired,
  unread: PropTypes.number.isRequired,
  viewedStatus: PropTypes.array.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  // load: PropTypes.func.isRequired,
  // statuses: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const styled = withStyles(customInputStyle)(Filters);
export default translate("Notifications")(styled);
