export default {
  HEADER: "Заява",
  BACK: "Повернутись",
  NEXT: "Наступний крок",
  FORM_A_STATEMENT: "Сформувати заяву",
  FINISH: "Підписати та відправити",
  FINISH_TO_COURT: "Підписати та відправити до суду",
  SIGN_DOCUMENT: "Підписати",
  REQUIRED: "Обов'язкове поле",
  CREATE_DOCUMENT: "Сформувати заяву",
  CREATE_DECISION: "Сформувати рішення",
  CLAIM_SAVED: "Заяву збережено.",
  DECISION_SAVED: "Рішення збережено.",
  SAVING_PROCESS: "Збереження...",
  ALL_DATA_SAVED:
    "Дані автоматично збережено. Ви можете повернутись до створення документу в будь-який момент.",
  LIST_BACK_LINK: "До списку заяв",
  EXECUTIVE_BACK_LINK: "Повернутись до списку виконавчих документів",
  C_EXECUTIVE_BACK_LINK: "Повернутись до виконавчого документа",
  CASE_BACK_LINK: "До списку документів по провадженню",
  POLICE_BACK_LINK: "До списку клопотань",
  ENCRYPTED_DOCUMENT: "🔒 Цей документ зашифровано",
  ATTACHES: "Додатки",
  SELECT_USERS: "Надіслати копію заяви сторонам",
  SKIP_SEND_TO_USERS: "Пропустити та відправити до суду",
  SEND_TO_USERS: "Надіслати сторонам",
  SIGNING_DOCUMENT: "Підписання документа",
  CONTINUE_EDITING: "Повернутись",
  REQUIRED_FIELDS: "* - заповнювати обов'язково",
  ADD_ITEM: "Додати",
  DELETE_ITEM: "Видалити",
  LIST_COMMITED_TEXT:
    "Заява успішно відправлена і буде опрацьована найближчим часом.",
  C_EXECUTIVE_COMMITED_TEXT:
    "Заява успішно відправлена і буде опрацьована найближчим часом.",
  EXECUTIVE_COMMITED_TEXT:
    "Документ успішно відправлен і буде опрацьован найближчим часом.",
  CASE_COMMITED_TEXT:
    "Рішення успішно відправлено і буде опрацьовано найближчим часом.",
  POLICE_COMMITED_TEXT:
    "Клопотання успішно відправлено і буде опрацьовано найближчим часом.",
  LIST_COMMITED: "Заяву відправлено.",
  C_EXECUTIVE_COMMITED: "Заяву відправлено.",
  EXECUTIVE_COMMITED: "Документ відправлено.",
  CASE_COMMITED: "Рішення відправлено.",
  CHECK_ECP: "Перевірка КЕП",
  SAVE_TO_DEVICE: "Зберегти файл та електронний підпис архівом",
  POLICE_COMMITED: "Клопотання відправлено.",
  REDIRECT_TO_LIST: "Повернутись до списку заяв",
  REDIRECT_TO_EXECUTIVE: "Повернутись до списку виконавчих документів",
  REDIRECT_TO_C_EXECUTIVE: "Повернутись до виконавчого документа",
  REDIRECT_TO_CASE: "Повернутись до списку документів по провадженню",
  REDIRECT_TO_CASE_SHORT: "Перейти до провадження",
  RESEND_CLAIM: "Надіслати заяву повторно",
  REDIRECT_TO_POLICE: "Повернутись до списку клопотань",
  SERVER_ERROR: "Помилка на сервері",
  CANT_SIGN_DOCUMENT: "Сервер АЦСК не відповідає",
  FAILED_TO_FETCH: "не вдалось передати дані",
  VALIDATION_ERROR: "дані не пройшли перевірку",
  SIGNED_CONTENT_NOT_MATCH_NEEDED:
    "Підписані дані не співпадають з оригіналом.",
  CANT_FIND_DOCUMENT_OR_USER_DONT_HAVE_NEEDED_ACCESS:
    "Документ не знайдено, або немає прав для перегляду.",
  SIGN_CERTIFICATE_PEM_NOT_MATCH_USERS_PEM:
    "Сертифікат не співпадає з сертифікатом користувача.",
  CANT_UPDATE_DOCUMENT: "Документ не може бути збереженим.",
  UNKNOWN_ERROR: "Сталася помилка, спробуйте пізніше.",
  TEMPLATE_NOT_FOUND:
    "Ця заява більше недоступна, спробуйте знайти та створити схожу за змістом",
  FILEREADER_ERROR:
    "Сталася помилка при формуванні документу. Спробуйте будь ласка сформувати заяву через деякий час.",
  DOCUMENT_IS_DELETED: "Цей документ було видалено.",
  DOCUMENT_IS_SIGNED: "Цей документ вже підписаний.",
  UPLOAD_ERROR:
    'Під час завантаження сталася помилка. Будь ласка, додайте відсутні файли на кроці "Додатки"',
  CLOSE: "Закрити",
  ERROR_TITLE: "Сталась помилка",
  SAVE: "Зберегти",
  APPEAL_COURT_NOT_FOUND: "Апеляційний суд не визначено",
  SIGNED: "підписано",
  WAITING_FOR_SIGN: "Очікує на підписання",
  CANT_ADD_STATIC_FILE: "Неможливо додати статичний файл",
  LOADING: "Дочекайтеся збереження даних",
  ENCRYPTED_CLAIM: "Цей документ шифрований",
  UNENCRYPT_CLAIM: "Розшифрувати",
  NO_ACCESS: "Ви не маєте прав для перегляду шифрованого документу",
  NO_PERM: "Ви не маєте право підпису",
  ADDING_NEW_ENCRYPTED_PERMISSION:
    "Для надання доступу іншим особам використайте персональний ключ",
  CANT_UPDATE_DOCUMENT_BECAUSE_IT_ALREADY_UPDATED:
    "Користувач {{user}} зараз редагує ці дані. Ваші зміни не будуть збережені",
  NOT_GET_COURTID: "Суд не визначено, зверніться до технічної підтримки",
  SEND_TO_ASVP: "Надіслати до АСВП",
  PAYMENT_DESCRIPTION:
    "З 1 березня при поданні до суду процесуальних документів в електронній формі застосовуватиметься коефіцієнт 0,8 для пониження відповідного розміру ставки судового збору.",
  ERROR_UPLOADING_FILE: "Виникла помилка при завантаженні файлу",
  NO_SCAN_ERROR: "Додайте відсканований документ",
  NO_PRIVELEGE_ERROR: "Виберіть пільгу",
  NO_EMPTY_SUMM: "Cума не може дорівнювати нулю",
  NO_CASE_ERROR:
    "Суд не визначено, будь ласка, зверніться до технічної підтримки.",
  NO_FORM_ERROR:
    "Форма оплати не визначена, будь ласка, зверніться до технічної підтримки.",
  NO_PAY_ERROR:
    "Сума до сплати не визначена, будь ласка, зверніться до технічної підтримки або введіть іншу суму.",
  NO_PAYER_INFO_ERROR:
    "Дані користувача, що проводить оплату не визначено. Спробуйте, будь ласка, пізніше.",
  NO_CASE_SYSTEM_ERROR: "Не визначена сістема оплати",
  NO_PAY_OR_CASE_ERROR:
    "Не призначена сума, або у поточній справі не визначено суд",
  EXECUTIVE_DOCUMENT: "Виконавчий документ",
  CHECK_CERTIFICATE_ERROR:
    "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено",
  СLAIM_HISTORY_CART: "Картка руху заяви",
  NAME: "Назва:",
  COURT: "Суд:",
  CASE_NUMBER: "Номер справи:",
  PROCEEDINGS_NUMBER: "Номер провадження:",
  STATE: "Стан:",
  DATE_OF_STATUS_CHANGE: "Дата зміни стану:",
  DATE_OF_SIGNING_THE_KEP: "Дата підписання КЕП:",
  DATE_SENT_TO_THE_SIDES: "Дата надсилання сторонам:",
  RECEIVED_SIDES: "Сторони, яким надіслано:",
  DATE_SENT_TO_COURT: "Дата надсилання до суду:",
  DATE_OF_DELIVERY_TO_COURT: "Дата доставки до суду:",
  DATE_OF_REGISTRATION_IN_COURT: "Дата реєстрації в суді:",
  PREVIEW_LINK: "Відкрити у новому вікні",
};
