import { ExecStage, ExecCategory } from "../../../variables/executiveStatuses";
import { Button } from "../../../components";
import { useEffect, useState } from "react";

export const ExecutiveActionButtons = ({
  classes,
  t,
  setComponentsId,
  text,
  activeExecutive,
  permissions,
  goToExecPayment,
  openPreviewASVP,
  handleResendExec,
  handleSubmitExec,
}) => {
  const isDeleted = activeExecutive?.isDeleted;
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);

  //Debtor & Payer functions
  const showPayBtn =
    isDeleted === 0 &&
    ["debtor", "payer", "debtorRepresentative", "payerRepresentative"].includes(
      activeExecutive?.execMyRole
    ) &&
    [ExecCategory.PENALTY, ExecCategory.COURT_FEE].includes(
      activeExecutive?.execCategoryId
    ) &&
    activeExecutive?.isStateCollector &&
    !activeExecutive?.statusIsFinal &&
    [1, 2, 3].includes(activeExecutive?.statusStageId) &&
    (activeExecutive?.paymentInfo?.summ || 0) > 0;

  const showPreviewClaimASVPBtn =
    ["collector", "collectorRepresentative"].includes(
      activeExecutive?.execMyRole
    ) && [4, 5, 6].includes(activeExecutive?.statusStageId);

  //Submit permissions
  const showResendBtn =
    ([ExecStage.ASVP_REJECTED].includes(activeExecutive?.statusStageId) ||
      activeExecutive?.statusId === 9) &&
    (activeExecutive?.execMyRole === "collector" ||
      (activeExecutive?.execMyRole === "collectorRepresentative" &&
        permissions?.length > 0));
  useEffect(() => {
    if (!activeExecutive) return;

    const showSubmitBtnCondition =
      activeExecutive?.isDeleted === 0 &&
      activeExecutive?.execCategoryId !== 1 &&
      ([ExecStage.AUTO_APPLICATION, ExecStage.MANUAL_APPLICATION].includes(
        activeExecutive?.statusStageId
      ) ||
        activeExecutive?.statusId === -1) &&
      (activeExecutive?.execMyRole === "collector" ||
        (activeExecutive?.execMyRole === "collectorRepresentative" &&
          permissions?.length > 0));

    setShowSubmitBtn(showSubmitBtnCondition);
  }, [activeExecutive, permissions]);

  return (
    <div className={classes.buttons}>
      {/* Сплатити */}
      {showPayBtn && (
        <Button
          color="yellow"
          onClick={goToExecPayment}
          setId={setComponentsId("executive-payment-btn")}
        >
          {t("PAY")}
        </Button>
      )}
      {/* Заява до АСВП */}
      {showPreviewClaimASVPBtn && (
        <Button
          color="yellow"
          onClick={openPreviewASVP}
          setId={setComponentsId("executive-preview-claim-ASVP-btn")}
        >
          {t("PREVIEW_CLAIM_TO_ASVP")}
        </Button>
      )}
      {/* Переподати */}
      {showResendBtn && (
        <Button
          color="yellow"
          onClick={handleResendExec}
          setId={setComponentsId("executive-resend-btn")}
        >
          {t("RESEND_FOR_EXECUTIVE")}
        </Button>
      )}
      {/* Подати на виконання */}
      {showSubmitBtn && text !== t("EMPTY_FILE") && (
        <Button
          color="yellow"
          onClick={handleSubmitExec}
          setId={setComponentsId("executive-submit-btn")}
        >
          {t("SUBMIT_FOR_EXECUTIVE")}
        </Button>
      )}
    </div>
  );
};
