import { Component } from "react";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";

import { StandardTextFieldProps } from "@material-ui/core/TextField";
import { BaseControlProps, formControlStyle } from "../types";

import CustomDatePicker from "../../../components/CustomInput/CustomDatePicker";
import TextFieldDummy from "../../../components/CustomInput/TextFieldDummy";

import humanDateFormat from "../../../helpers/humanDateFormat";

import StringControl from "./StringControl";

const styles = (theme: any) => ({
  ...formControlStyle,
});

const dateFormat = "DD.MM.YYYY";

interface DateControlProps extends BaseControlProps<StandardTextFieldProps> {}

interface DateControlState {}

class DateControl extends Component<DateControlProps, DateControlState> {
  static defaultProps: Partial<DateControlProps> = {};

  constructor(props: DateControlProps) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    const { onFinish, name, value } = this.props;
    onFinish && onFinish(name, value);
  }

  handleChange = (value: string) => {
    const { onChange, name } = this.props;

    const parts: any[] = value.split(".");
    if (parts.length === 3) {
      const date = new Date([parts[2], parts[1], parts[0]].join("-"));
      value = date.toISOString().split("T")[0];
    }
    onChange && onChange(name, value === "" ? undefined : value);
  };

  render() {
    const {
      classes,
      disabled,
      errors,
      // error,
      hidden,
      name,
      readOnly,
      required,
      schema,
      setId,
      // t,
      value,
    } = this.props;

    let minDate = "";
    let maxDate = "";

    const formatedValue = value
      ? humanDateFormat(value, { format: dateFormat })
      : "";

    if (readOnly) {
      return (
        <StringControl {...this.props} value={formatedValue}></StringControl>
      );
    }

    const Component = readOnly ? TextFieldDummy : CustomDatePicker;
    return (
      <FormControl
        disabled={disabled}
        id={`${setId(name)}-wrapper`}
        className={classes.formControl}
        fullWidth={true}
        required={required}
        style={hidden ? { display: "none" } : { display: "block" }}
      >
        <Component
          date={formatedValue}
          error={!!errors}
          fullWidth={true}
          helperText={!readOnly && (errors || schema.description)}
          id={setId("name")}
          incomingFormat={dateFormat}
          InputProps={{
            readOnly: schema.readOnly,
            // inputComponent: InputMask
          }}
          // InputProps={InputProps}
          label={(schema.title || "") + (required ? "*" : "")}
          onChange={this.handleChange}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          placeholder={schema.title}
          required={required}
          value={formatedValue}
        />
      </FormControl>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(DateControl);
