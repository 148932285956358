import { useSelector } from "react-redux";
import { courtsSelector } from "../../../../selectors/dictionary";

export function useCourtName(t: any) {
  const courts = useSelector(courtsSelector);

  return (courtId: number, courtTypeId: number) => {
    let court = (courts || []).find((data) => data.id === courtId);

    if (!court) return "";
    let err = "";

    if (!court.isEnabled) {
      switch (courtTypeId) {
        case 0:
          err = "COURT_NAME_ERROR";
          break;
        case 1:
          err = "APPEAL_COURT_NAME_ERROR";
          break;
        case 2:
          err = "CASSATION_COURT_NAME_ERROR";
          break;
        default:
          break;
      }
    }
    return err ? t(err, { courtName: court.name }) : court.name;
  };
}
