import * as api from "../services/api";
import store from "../store";

export const SEND_PARTIES = "SEND_PARTIES";
export const SIGN_DOCUMENT = "SIGN_DOCUMENT";
export const REQUEST_SIGN_DATA = "REQUEST_SIGN_DATA";
export const CLAIM_CREATED = "CLAIM_CREATED";
export const COMMIT_DOCUMENT = "COMMIT_DOCUMENT";
export const GENERATE_PDF = "GENERATE_PDF";
export const REQUEST_PDF = "REQUEST_PDF";
export const REQUEST_COPY_CLAIM = "REQUEST_COPY_CLAIM";
export const REQUEST_CLAIM = "REQUEST_CLAIM";
export const DELETE_CLAIM = "DELETE_CLAIM";
export const PERMANENT_DELETE_CLAIM = "PERMANENT_DELETE_CLAIM";
export const RECOVER_CLAIM = "RECOVER_CLAIM";
export const REQUEST_CLAIM_LIST = "REQUEST_CLAIM_LIST";
export const STORE_CLAIM_VALUES = "STORE_CLAIM_VALUES";
export const STORE_DECRYPTED_CLAIM_VALUES = "STORE_DECRYPTED_CLAIM_VALUES";
export const UPLOAD_DOCUMENT_ATTACH = "UPLOAD_DOCUMENT_ATTACH";
export const DELETE_DOCUMENT_ATTACH = "DELETE_DOCUMENT_ATTACH";
export const DOWNLOAD_DOCUMENT_ATTACH = "DOWNLOAD_DOCUMENT_ATTACH";
export const REQUEST_ATTACH_PREVIEW = "REQUEST_ATTACH_PREVIEW";
export const REQUEST_CLAIM_CREATE = "REQUEST_CLAIM_CREATE";
export const REQUEST_CLAIM_TEMPLATE = "REQUEST_CLAIM_TEMPLATE";
export const REQUEST_CLAIM_TEMPLATE_LIST = "REQUEST_CLAIM_TEMPLATE_LIST";
export const REQUEST_CLAIM_TEMPLATE_CATEGORIES =
  "REQUEST_CLAIM_TEMPLATE_CATEGORIES";
export const REQUEST_APPEAL_COURT = "REQUEST_APPEAL_COURT";
export const GET_EXECUTIVE_COUNT = "GET_EXECUTIVE_COUNT";
export const GET_APPROVAL_COUNT = "GET_APPROVAL_COUNT";
export const ADD_ENCODE_KEY = "ADD_ENCODE_KEY";

export const UPDATE_CLAIM_COURT = "UPDATE_CLAIM_COURT";
export const BACK_TO_EDIT_CLAIM = "BACK_TO_EDIT_CLAIM";
export const GET_CREATED_CLAIM = "GET_CREATED_CLAIM";

export const DOCUMENTS_URL = "api/documents";
export const TEMPLATES_URL = "api/templates";

export async function documentRequest(
  documentId: string | undefined
): Promise<any> {
  return await api.get(
    `${DOCUMENTS_URL}/${documentId}`,
    REQUEST_CLAIM,
    store.dispatch
  );
}

export async function documentCreate(
  templateId: number | undefined,
  data: any
): Promise<any> {
  let result = await api.post(
    `${TEMPLATES_URL}/${templateId}/start`,
    data,
    REQUEST_CLAIM_CREATE,
    store.dispatch
  );

  result = {
    ...result,
    digitalDocumentTemplateId: templateId,
  };
  if (!result.message) {
    store.dispatch({
      type: CLAIM_CREATED,
      payload: result,
    });
  }
  return result;
}

export async function documentCommit(id: string, data: any): Promise<any> {
  if (!id) {
    const error: any = new Error("ERROR: document id not found");
    error.cause = "Not found attachId";
    error.documentId = id;
    throw error;
  }
  return await api.post(
    `${DOCUMENTS_URL}/${id}/commit`,
    data,
    COMMIT_DOCUMENT,
    store.dispatch
  );
}

export async function documentGetDraft(id: string) {
  return await api.get(
    `${DOCUMENTS_URL}/${id}/draft`,
    GET_CREATED_CLAIM,
    store.dispatch
  );
}

export async function documentSetDraft(id: string) {
  return await api.put(
    `${DOCUMENTS_URL}/${id}/draft`,
    {},
    BACK_TO_EDIT_CLAIM,
    store.dispatch
  );
}

export async function documentUpdateCourt(
  docId: string,
  courtId: number
): Promise<any> {
  return await api.put(
    `${DOCUMENTS_URL}/${docId}/court`,
    { courtId },
    UPDATE_CLAIM_COURT,
    store.dispatch
  );
}

export async function documentUpdateData(
  docId: string,
  data: any
): Promise<any> {
  return await api.put(
    `${DOCUMENTS_URL}/${docId}/object`,
    data,
    STORE_CLAIM_VALUES,
    store.dispatch
  );
}

export async function pdfGenerate(id: string, data: any = {}): Promise<any> {
  return await api.post(
    `${DOCUMENTS_URL}/${id}/pdf`,
    data,
    GENERATE_PDF,
    store.dispatch
  );
}

export async function pdfRequest(id: string): Promise<any> {
  return await api.get(
    `${DOCUMENTS_URL}/${id}/static_file`,
    REQUEST_PDF,
    store.dispatch
  );
}

export async function signDocument(id: string, signature: any): Promise<any> {
  return await api.post(
    `${DOCUMENTS_URL}/${id}/sign`,
    { signature },
    SIGN_DOCUMENT,
    store.dispatch
  );
}

export async function signRequestData(id: string): Promise<any> {
  return await api.get(
    `${DOCUMENTS_URL}/${id}/sign`,
    REQUEST_SIGN_DATA,
    store.dispatch
  );
}

export async function templateRequest(
  templateId: number | undefined
): Promise<any> {
  let result = await api.get(
    `${TEMPLATES_URL}/${templateId}`,
    REQUEST_CLAIM_TEMPLATE,
    store.dispatch
  );

  if (!result || result.message) {
    return result;
  }
  const { jsonSchema } = result;
  try {
    result = {
      ...result,
      jsonSchema: JSON.parse(jsonSchema),
    };
  } catch (e) {
    result = {
      ...result,
      jsonSchema: {},
    };
  }
  return result;
}
