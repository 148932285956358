import { blobToTextEncoded } from "./blobToText";
import formatFile from "./formatFile";
import getFormat from "./getAttachFormat";
import getFileUrl from "./getFileUrl";
import { docSignCheck } from "../actions/cases";
import stringToBlob from "./stringToBlob";
import blobToBase64 from "./blobToBase64";

const getFileInfo = async (file: Blob, string = "") => {
  const text = string || (await blobToTextEncoded("Windows-1251")(file));
  const formatingFile = await formatFile(file, text);
  const format = getFormat(formatingFile, text);
  const url = await getFileUrl(formatingFile, format, text);
  return {
    format,
    type: file.type,
    url,
    text,
    file: formatingFile,
  };
};

const checkECP = async (documentId: string) => {
  const file = await docSignCheck(documentId);
  if (file && typeof file === "object" && (file.message || !file.size)) {
    throw new Error(file.message || "404 File not found");
  }

  if (!file) return;

  if (typeof file === "string") {
    const blob = stringToBlob(file);
    const fileInfo = await getFileInfo(blob, file);
    return { ...fileInfo };
  }

  if (typeof file === "object") {
    const base64 = await blobToBase64(file);
    const fileInfo = await getFileInfo(file);
    return {
      doc: base64,
      preview: base64,
      ...fileInfo,
    };
  }
};

export default checkECP;
