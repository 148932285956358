import config from "../../config";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { IconButton, withStyles, Paper } from "@material-ui/core";

import {
  Assignment,
  Close,
  Fullscreen,
  OpenInNew,
  SaveAlt,
  Timeline,
} from "@material-ui/icons";

import { translate } from "react-translate";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Preview from "../../components/Attach/Preview";
import AttachList from "../../components/Attach/AttachList";
import OpenInNewWindow from "../../components/OpenInNewWindow";

import {
  downloadScanFile,
  downloadStaticFile,
  requestDocumentHistory,
  saveFile,
  downloadScanArchive,
  downloadStaticArchive,
} from "../../actions/cases";
import { BlockScreen, Button } from "../../components";

import downloadBase64Attach from "../../helpers/downloadBase64Attach";
import blobToBase64 from "../../helpers/blobToBase64";
import { blobToTextEncoded } from "../../helpers/blobToText";
import getFormat from "../../helpers/getAttachFormat";
// import stringToBlob from "../../helpers/stringToBlob";
import formatFile from "../../helpers/formatFile";
import formatSource from "../../helpers/formatSource";
import getFileUrl from "../../helpers/getFileUrl";
import styles from "../../variables/styles/scanDocument";

import HistoryCardDialog from "../../widgets/HistoryCardDialog";
import PreviewDialog from "../Attach/PreviewDialog";

import setComponentsId from "../../helpers/setComponentsId";
import KeyPng from "../../assets/img/key.svg";
import { ClaimState } from "../../pages/Claim/types";
import { copyClaim } from "../../actions/claim";
import redirectes from "../../helpers/documentRedirected";
import { compose } from "redux";
import { withUser } from "../withUser";
import { ReturnAndLocateInTableHOC } from "../ReturnAndLocateInTableHOC";
// import { default as historyFields } from "../DocumentHistoryModal/items";
import checkECP from "../../helpers/checkECP";
import getDocumentFileName from "../../helpers/getDocumentFileName";
import { requestPaymentForm } from "../../actions/payment";
// import promiseChain from "../../helpers/promiseChain";
import getUrlParams from "../../helpers/getUrlParams";
import humanDateFormat, {
  humanDateTimeFormat,
} from "../../helpers/humanDateFormat";
// import { togglePermissionDialog } from "../../pages/Case/decorators/toggleDialogs";

const blankFormats = ["pdf", "video", "audio", "image", "html"];
// const HIDDEN_FOR_NOT_PAYED = ["video","audio", "compressed"];
const HIDDEN_FOR_NOT_PAYED = [
  "audio",
  "compressed",
  "octet-stream",
  "rar",
  "video",
  "zip",
];

const initialState = {
  formatSource: null,
  doc: null,
  text: null,
  source: null,
  url: "",
  format: "",
  errorText: "",
  docSignPDF: null,
  loading: false,
  docHistory: null,
  historyRows: null,
  historyTitle: "",
  isOpenHistory: false,

  openPermissionDialog: false,
};

const getCharsetFromMeta = (text) => {
  const charsetMatch = text.match(
    /<meta\s+charset=["']?([^"'>\s]+)["']?\s*\/?>/i
  );
  return charsetMatch ? charsetMatch[1] : null;
};

const addAttributes = (str) => {
  return str.replace(
    /<meta\s+charset=["']?([^"'>\s]+)["']?\s*\/?>/i,
    '<meta http-equiv="Content-Type" content="text/html; charset=$1">'
  );
};

class ScanDocument extends React.Component {
  state = { ...initialState };

  getDateStr = (date) => {
    return date && humanDateTimeFormat(date);
  };

  getSimpleDateStr = (date) => {
    return date && humanDateFormat(date);
  };

  getCourt = (viewDocument, courts) => {
    let { courtId } = viewDocument;
    let courtName = "";
    if (courtId && courts) {
      const foundCourt = courts.find(({ id }) => id === courtId);
      if (foundCourt) {
        courtName = foundCourt.name;
      }
    }
    return courtName || "";
  };

  createHistoryData = (data = {}, isDocument) => {
    const { viewDocument, courts, t } = this.props;
    const {
      docState,
      createdAt,
      // updatedAt,
      changeStateDate,
      signDate,
      sendDate,
      deliveryDate,
      regDate,
      dateForce,
      sentPartyDate,
      sentPartyTo,
    } = data;

    let tableData = [
      [t("NAME"), viewDocument?.description],
      [t("COURT"), this.getCourt(viewDocument, courts)],
      [t("CASE_NUMBER_CARD"), viewDocument?.caseNumber],
      [t("PROCEEDINGS_NUMBER"), viewDocument?.procNumber],
      [t("STATE"), docState],
    ];

    if (!isDocument) {
      tableData.push(
        [t("DATE_OF_STATUS_CHANGE"), this.getDateStr(changeStateDate)],
        [t("DATE_OF_SIGNING_THE_KEP"), this.getDateStr(signDate)],
        [t("DATE_SENT_TO_THE_SIDES"), this.getDateStr(sentPartyDate)],
        [t("RECEIVED_SIDES"), sentPartyTo],
        [t("DATE_SENT_TO_COURT"), this.getDateStr(sendDate)],
        [t("DATE_OF_DELIVERY_TO_COURT"), this.getDateStr(deliveryDate)],
        [t("DATE_OF_REGISTRATION_IN_COURT"), this.getDateStr(regDate)]
      );
    } else {
      tableData.push(
        [t("REG_IN_COURT_DATE"), this.getSimpleDateStr(viewDocument?.docDate)],
        [t("DOCUMENT_CREATING_DATE_OR_DELIVERY"), this.getDateStr(createdAt)],
        [t("DNZS_DATE2"), this.getSimpleDateStr(dateForce)]
      );
    }

    return tableData;
  };

  showHistory = async () => {
    const { t } = this.props;
    const throwError = (msg) => {
      throw new Error(msg);
    };

    try {
      this.setLoading(true);
      const resp = await requestDocumentHistory(this.documentId);

      if (resp instanceof Error) {
        throwError(resp.message);
      }

      // 11 - document, other - claims
      const tableData = this.createHistoryData(resp, resp?.docStateId === 11);
      this.setState({
        historyRows: tableData,
        historyTitle:
          resp?.docStateId === 11
            ? t("DOCUMENT_HISTORY")
            : resp?.docStateId === 18
            ? t("DOCUMENT_HISTORY_FROM_USER")
            : t("СLAIM_HISTORY_CART"),
      });
      this.setState({ isOpenHistory: true });
    } catch (e) {
      console.log(e.message);
    } finally {
      this.setLoading(false);
    }
  };
  /// ---

  load = async () => {
    const { viewDocument, t, savedDoc } = this.props;
    const { scanDocumentLink, digitalDocumentStaticFile, id } =
      viewDocument || {};

    this.setState({ ...initialState });

    if (scanDocumentLink || digitalDocumentStaticFile) {
      const downloadAction = scanDocumentLink
        ? downloadScanFile
        : downloadStaticFile;
      let { file } = savedDoc;

      if (!file || savedDoc.docId !== id) file = await downloadAction(id);
      saveFile(file);

      if (file && file.size && !file.message) {
        let [doc, text, docFile] = await Promise.all([
          blobToBase64(file),
          blobToTextEncoded("windows-1251")(file),
          file,
        ]);
        const charset = getCharsetFromMeta(text);

        text = charset === "windows-1251" ? addAttributes(text) : text;

        const source = await formatSource(docFile, text);
        const formatedSource = await formatFile(source, text);
        const format = await getFormat(formatedSource, text);
        const url = await getFileUrl(formatedSource, format, text);

        this.setState({
          doc,
          text,
          source,
          formatedSource,
          format,
          url,
        });
      } else {
        this.setState({
          ...initialState,
          text: !file.size ? t("EMPTY_FILE") : (file || {}).message || "",
          format: "text",
          source: null,
        });
      }
    } else if (viewDocument) {
      this.setState({
        ...initialState,
        text: t("EMPTY_LINKS"),
        format: "text",
        source: null,
      });
    }
  };

  componentDidMount = () => {
    const { viewDocument } = this.props;
    if (viewDocument) {
      this.load();
    }
  };

  componentDidUpdate(prevProps) {
    const { viewDocument } = this.props;
    if (viewDocument && prevProps.viewDocument.id !== viewDocument.id) {
      this.setState({ doc: null, text: "", source: null }, () => {
        this.load();
      });
    }
  }

  get documentId() {
    return this.props.viewDocument?.id;
  }

  download = async () => {
    this.setState({
      loading: true,
    });
    try {
      const { viewDocument } = this.props;
      const downloadArchive = viewDocument.digitalDocumentStaticFile
        ? downloadStaticArchive
        : downloadScanArchive;
      const blob = await downloadArchive(this.documentId);

      const name = getDocumentFileName(viewDocument);
      const { document } = window;
      const URL = window.URL || window.webkitURL;

      const element = document.createElement("a");
      element.setAttribute("href", URL.createObjectURL(blob));
      element.setAttribute("download", name);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch (error) {
      const { source, format } = this.state;
      const { name } = this.props;
      downloadBase64Attach(
        {
          docId: this.documentId,
          fileName: name,
          contentType: format,
        },
        source
      );
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  setLoading = (loading) => this.setState({ loading });

  checkECP = async () => {
    try {
      this.setLoading(true);
      const checked = await checkECP(this.documentId);
      this.setState({
        docSignPDF: checked,
      });
    } catch (e) {
      this.showError(e.message || "404 File not found");
    } finally {
      this.setLoading(false);
    }
  };

  showСourtFee = () => {
    this.setState({
      courtFeeModal: true,
    });
  };

  closeСourtFee = () => {
    this.setState({
      courtFeeModal: false,
    });
  };

  previewTextPart = () => {
    this.setState({
      docSignPDF: {
        text: this.props.viewDocument.scanDocumentText,
        name: "Звіт про автоматизований розподіл",
        format: "text",
      },
    });
  };

  getAttaches = () => {
    return this.props.viewDocument?.attaches || [];
  };

  // showError = (errorText) =>
  //   this.setState({ errorText }, () => {
  //     setTimeout(this.hideError, 3000);
  //   });

  // hideError = () => this.setState({ errorText: "" });

  goToCourtFeePayment = async (options) => {
    const { t } = this.props;
    // const { errorText } = this.state;

    try {
      const formData = await requestPaymentForm(this.documentId, options);
      if (formData instanceof Error) throw formData;

      const form = document.createElement("form");
      form.setAttribute("method", formData.method);
      form.setAttribute("action", formData.url);

      const parsed = getUrlParams(formData.body);

      Object.keys(parsed).forEach((key) => {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "string");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", parsed[key]);
        form.appendChild(hiddenField);
      });

      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "string");
      hiddenField.setAttribute("name", "back_url");
      hiddenField.setAttribute("value", form.baseURI);
      form.appendChild(hiddenField);

      const btn = document.createElement("input");
      btn.value = "продовжити";
      btn.setAttribute("type", "submit");
      form.appendChild(btn);

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      this.setState({ errorText: `${t("PAYMENT_ERROR")} ${error.message} ` });
    }
  };

  // goToCourtFeePayment = (options) => {
  //   const { t } = this.props;
  //   const { errorText } = this.state;
  //   // const paymentForm = requestPaymentForm(this.documentId, options);
  //   promiseChain([
  //     () => requestPaymentForm(this.documentId, options),
  //     (formData) => {
  //       if (formData.message) {
  //         this.setState({ errorText: t("PAYMENT_ERROR") });
  //         return errorText;
  //       }

  //       console.log(`PAYMENT: `, formData);
  //       const form = document.createElement("form");
  //       form.setAttribute("method", formData.method);
  //       form.setAttribute("action", formData.url);

  //       const parsed = getUrlParams(formData.body);

  //       Object.keys(parsed).map((key) => {
  //         const hiddenField = document.createElement("input");
  //         hiddenField.setAttribute("type", "string");
  //         hiddenField.setAttribute("name", key);
  //         hiddenField.setAttribute("value", parsed[key]);

  //         return form.appendChild(hiddenField);
  //       });

  //       const btn = document.createElement("input");
  //       btn.value = "продовжити";
  //       btn.setAttribute("type", "submit");
  //       form.appendChild(btn);

  //       document.body.appendChild(form);
  //       form.submit();
  //     },
  //   ]);
  // };

  render() {
    const {
      classes,
      close,
      tools,
      onViewAttaches,
      downloadAttach,
      previewAttach,
      attaches,
      disableToolbar,
      t,
      redirectTo,
      viewDocument,
      isLegal,
      returnObject,
      role,
      // activeProc,
      // togglePermissionDialog,
    } = this.props;
    const { formatedSource, format, url, text } = this.state;

    // const showCheckECP = !!viewDocument.scanDocumentLink;

    const showDownloadBtn =
      (!!viewDocument.scanDocumentLink ||
        !!viewDocument.digitalDocumentStaticFile) &&
      text !== t("EMPTY_FILE");

    // кнопка оскаржити для мобильной версии и компонента
    // const getLocalPath = window.location.pathname.slice(1, 9);
    // getLocalPath.slice(0, 9);
    // /document/
    // const showProtestBTNMob = getLocalPath === "document" ? true : false;

    const showPaymentBtn =
      !config().hideVkzPayment &&
      viewDocument.extActionType === 1 &&
      role !== "judge" &&
      viewDocument.extState !== 1 &&
      viewDocument.attaches.find((attach) => {
        for (const hideType of HIDDEN_FOR_NOT_PAYED) {
          if (attach.contentType.includes(hideType)) return true;
        }
        return false;
      });
    // viewDocument.attaches.length !== 0 &&
    // role !== "judge"
    // ? true
    // : false;

    const messageStyle = {
      backgroundColor: "#FFE358",
      color: "#000000",
    };

    const textMessageStyle = {
      padding: "5px 10px 5px 10px",
      margin: 0,
    };
    const isRedictToClaims = true;
    return (
      <Fragment>
        {(onViewAttaches || close || tools || formatedSource || redirectTo) && (
          <div className={classes.toolbar}>
            {tools}
            <div className={classes.grow}>
              {redirectTo && isRedictToClaims && (
                <Link
                  to={redirectTo.getUrl(viewDocument)}
                  className={classes.buttonLink}
                >
                  <Button color="yellow" variant="contained">
                    {t(`REDIRECT_TO_${redirectTo.title}`)}
                  </Button>
                </Link>
              )}
              {/* Кнопка "Сплатити" для мобильной версии */}
              {onViewAttaches ||
                close ||
                (showPaymentBtn && (
                  <div style={{ display: "flex" }}>
                    <Button
                      color="yellow"
                      variant="contained"
                      onClick={() =>
                        this.goToCourtFeePayment({ courtFeeId: 42 })
                      }
                      id={setComponentsId("payment-button-mobile")}
                      setId={(elmentName) =>
                        setComponentsId(`create - ${elmentName}`)
                      }
                    >
                      {t("PAY")}
                    </Button>
                  </div>
                ))}

              {/* Кнопка "Сплатити" для десктопной версии */}
              {onViewAttaches && showPaymentBtn && (
                <div style={{ marginRight: "3px" }}>
                  <Button
                    title={t("PAY_TITLE_FOR_BTN")}
                    color="yellow"
                    setId={setComponentsId("payment-button")}
                    onClick={() => this.goToCourtFeePayment({ courtFeeId: 42 })}
                  >
                    {t("PAY")}
                  </Button>
                </div>
              )}

              {!!viewDocument &&
                viewDocument.state === ClaimState.Rejected &&
                !isLegal && (
                  <Button
                    color="yellow"
                    variant="contained"
                    onClick={async () => {
                      try {
                        const { id } = await copyClaim(viewDocument.id);
                        returnObject.removeReturnObject();
                        window.location.href = redirectes[11].getUrl(id);
                      } catch (e) {}
                    }}
                  >
                    {t(`RESEND_CLAIM`)}
                  </Button>
                )}
            </div>
            {formatedSource && (
              <IconButton
                color="inherit"
                onClick={this.showHistory}
                title={t("DOCUMENT_HISTORY")}
                className={classes.menuButton}
              >
                <Timeline />
              </IconButton>
            )}
            {formatedSource && showDownloadBtn && (
              <IconButton
                color="inherit"
                onClick={this.checkECP}
                title={t("CHECK_ECP")}
                className={classes.menuButton}
              >
                <img src={KeyPng} alt={t("CHECK_ECP")} width={24} height={24} />
              </IconButton>
            )}
            {this.props.viewDocument.scanDocumentText &&
              this.props.viewDocument.state !== 18 && (
                <IconButton
                  color="inherit"
                  onClick={this.previewTextPart}
                  title={"Звіт про автоматизований розподіл"}
                  className={classes.menuButton}
                >
                  <Assignment />
                </IconButton>
              )}
            {formatedSource &&
              showDownloadBtn && ( //showDownloadBtn
                <IconButton
                  color="inherit"
                  onClick={this.download}
                  title={t("SAVE_TO_DEVICE")}
                  className={classes.menuButton}
                >
                  <SaveAlt />
                </IconButton>
              )}
            {formatedSource && blankFormats.includes(format) && url && (
              <OpenInNewWindow
                url={url}
                className={classes.buttonLink}
                title={t("PREVIEW_LINK")}
              >
                <IconButton color="inherit" className={classes.menuButton}>
                  <OpenInNew />
                </IconButton>
              </OpenInNewWindow>
            )}
            {onViewAttaches && (
              <IconButton
                color="inherit"
                onClick={onViewAttaches()}
                className={classes.menuButton}
                title={t("FULL_SCREEN")}
              >
                <Fullscreen />
              </IconButton>
            )}
            {close && (
              <IconButton
                color="inherit"
                onClick={close}
                title={t("CLOSE")}
                className={classes.menuButton}
              >
                <Close />
              </IconButton>
            )}
          </div>
        )}
        {/* mark2250 */}
        {viewDocument?.state === 8 && viewDocument?.infoText && (
          <div
            style={{
              marginTop: "8px",
              marginBottom: "4px",
              color: "#ca0603",
            }}
          >
            {viewDocument?.infoText}
          </div>
        )}
        <Preview
          {...this.state}
          {...this.props}
          download={disableToolbar ? null : this.props.download}
          source={formatedSource}
        />
        {/* Показ сообщения для мобильной версии */}
        {onViewAttaches ||
          close ||
          (showPaymentBtn && (
            <div style={messageStyle}>
              <p style={textMessageStyle}>{t("TEXT_FOR_PAYMENT")}</p>
            </div>
          ))}
        {/* Показ сообщения для десктопной версии */}
        {onViewAttaches && showPaymentBtn && (
          <Paper
            className={classes.paper}
            square={true}
            style={{ padding: "10px 10px 10px 10px" }}
          >
            <div style={messageStyle}>
              <p style={textMessageStyle}>{t("TEXT_FOR_PAYMENT")}</p>
            </div>
          </Paper>
        )}

        {downloadAttach && attaches && (
          <AttachList
            attaches={this.getAttaches()}
            handleDownload={downloadAttach}
            requestPreview={previewAttach}
            needTitle
            viewDocument={viewDocument}
          />
        )}
        {this.state?.isOpenHistory && this.state?.historyRows ? (
          <HistoryCardDialog
            t={t}
            classes={classes}
            openDialog={this.state?.isOpenHistory}
            toggleDialog={() => this.setState({ isOpenHistory: false })}
            title={this?.state?.historyTitle}
            historyRows={this?.state?.historyRows}
            setId={(name) => setComponentsId(`HistoryCardDialog - ${name} `)}
          />
        ) : (
          this.state?.docSignPDF && (
            <PreviewDialog
              openDialog={!!this.state.docSignPDF}
              toggleDialog={() => this.setState({ docSignPDF: null })}
              setId={(name) => this.props.setId(`PreviewDialog - ${name} `)}
              printHistory={null}
              {...(this.state.docSignPDF || {})}
            />
          )
        )}
        {/* }
        {(this?.state?.docSignPDF?.format === "pdf" ||
          this?.state?.docSignPDF.format === "text") &&
          !!this?.state?.docSignPDF && (
            <PreviewDialog
              openDialog={!!this.state.docSignPDF}
              toggleDialog={() => this.setState({ docSignPDF: null })}
              setId={(name) => this.props.setId(`PreviewDialog - ${name} `)}
              printHistory={null}
              {...(this.state.docSignPDF || {})}
            />
          )} */}
        <BlockScreen open={this.state.loading} />
      </Fragment>
    );
  }
}

ScanDocument.propTypes = {
  // activeProc: PropTypes.object,
  attaches: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  close: PropTypes.func,
  downloadAttach: PropTypes.func,
  disableToolbar: PropTypes.bool,
  name: PropTypes.string,
  onViewAttaches: PropTypes.func,
  previewAttach: PropTypes.func,
  redirectTo: PropTypes.object,
  savedDoc: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tools: PropTypes.node,
  // togglePermissionDialog: PropTypes.func.isRequired,
  viewDocument: PropTypes.object,
};

ScanDocument.defaultProps = {
  name: "Документ",
  setId: setComponentsId("scan-document"),
  close: undefined,
  tools: "",
  onViewAttaches: undefined,
  previewAttach: undefined,
  downloadAttach: undefined,
  attaches: null,
  viewDocument: null,
  redirectTo: null,
  disableToolbar: false,
};

const mapStateToProps = ({
  dictionary: { courts },
  cases: { savedDoc },
  authorization: { role },
}) => ({
  courts,
  savedDoc,
  role,
});

export default compose(
  connect(mapStateToProps),
  translate("Elements"),
  withStyles(styles),
  withUser,
  ReturnAndLocateInTableHOC
)(ScanDocument);
