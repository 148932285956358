import md5 from "js-md5";

import waiter from "../../../helpers/waitForAction";
import getClaimDelta from "../../../helpers/getClaimDelta";
import getWizardProps from "../../../helpers/getWizardProps";

import { storeClaimValue, setWizardStates } from "../../../actions/claim";
import { updatePaymentInfo } from "../../../actions/payment";
import edsService from "../../../services/eds";
import promiseChain from "../../../helpers/promiseChain";
import normalizeClaim from "../../../helpers/normalizeClaim";

import changeRules from "./updateDocumentRules";
import loadClaim from "./loadClaim";
import updateSignValues from "./updateDocumentRules/updateSignValues";

const STORE_INTERVAL = 2000;

const { TextEncoder } = window;

const getClaim = (result, itsUpdatePayment) => {
  const { claim } = getWizardProps();
  claim.attaches = result.attaches;
  claim.courtId = result.courtId;
  if (itsUpdatePayment && result && result.digitalDocumentData) {
    const {
      digitalDocumentData: { payment },
    } = result;
    claim.digitalDocumentData.payment = {
      ...claim.digitalDocumentData.payment,
      totalSum: payment.totalSum,
      paymentParts: payment.paymentParts,
    };
  }
  return claim;
};

const getDeltaProperties = async (claim, origin, template, encryptionKey) => {
  const {
    jsonSchema,
    jsonSchema: { useEncryption },
  } = template;
  if (!claim || !origin) {
    return Promise.resolve(null);
  }

  if (useEncryption) {
    const unprotectedData = JSON.stringify(claim.digitalDocumentData);
    const encodedData = new TextEncoder("utf-8").encode(unprotectedData);
    return promiseChain([
      () =>
        edsService.signer.execute(
          "ProtectDataByPassword",
          encodedData,
          encryptionKey,
          true
        ),
      (encryptedData) => ({
        encryptedData,
        ...(origin && origin.digitalDocumentData
          ? {
              previousEncryptedDataHash: md5(origin.digitalDocumentData),
            }
          : {}),
      }),
    ]);
  }
  let properties = getClaimDelta(claim, origin, jsonSchema);
  // if (docCategory === 6) {
  //   const historyOfChange = (
  //     claim.digitalDocumentData.historyOfChange || []
  //   ).concat(properties);
  //   const { digitalDocumentData } = claim;
  //   claim = {
  //     ...claim,
  //     digitalDocumentData: { ...digitalDocumentData, historyOfChange },
  //   };
  //   properties = getClaimDelta(claim, origin, jsonSchema);
  // }

  if (!properties.length) {
    return Promise.resolve(null);
  }

  return Promise.resolve({ properties });
};

export default function handleChange(
  value,
  force = false,
  itsUpdatePayment = false
) {
  const changeValue = itsUpdatePayment ? updatePaymentInfo : storeClaimValue;

  const {
    encryptionKey,
    template,
    claim: propClaim,
    activeStepId,
    stepOrders,
    init,
    busy,
    errors,
  } = getWizardProps();
  if (!busy || errors) setWizardStates({ busy: true, errors: null });
  const { id: claimId } = propClaim || {};
  if (!claimId || init) return handleChange(value, force, itsUpdatePayment);

  return promiseChain(
    [
      ...changeRules.map((rule) =>
        rule.bind(this, value, stepOrders[activeStepId])
      ),
      (claim) => {
        setWizardStates({ claim });
        // console.log("claim: ", claim);
        return new Promise((resolve, reject) =>
          waiter.addAction(
            claimId,
            () =>
              promiseChain([
                () =>
                  getDeltaProperties(
                    claim,
                    getWizardProps().origin,
                    template,
                    encryptionKey
                  ),
                (properties) => {
                  if (properties) {
                    return changeValue(claimId, properties);
                  }
                  return resolve(claim);
                },
                (result) => {
                  if (result instanceof Error) {
                    return reject(result.message);
                  }
                  return resolve(result);
                },
              ]).catch(reject),
            STORE_INTERVAL
          )
        );
      },
      normalizeClaim,
      (result) =>
        setWizardStates({
          origin: JSON.parse(JSON.stringify(result)),
          claim: getClaim(result, itsUpdatePayment),
          busy: false,
          claimSaved: true,
          saving: false,
        }),
      updateSignValues,
    ],
    getWizardProps().claim
  ).catch((error) =>
    promiseChain([
      () => loadClaim(claimId),
      (claim) => setWizardStates({ error, claim }),
    ])
  );
}
