import Signer from "./signer";
import HardwareSigner from "./hardwareSigner";
// import CAs from "./CAs.json";

import config from "../../config";
const { eds } = config();

const testACSK = {
  issuerCNs: ['Тестовий ЦСК АТ "ІІТ"'],
  address: "ca.iit.com.ua",
  ocspAccessPointAddress: "ca.iit.com.ua/services/ocsp/",
  ocspAccessPointPort: "80",
  cmpAddress: "ca.iit.com.ua",
  tspAddress: "ca.iit.com.ua",
  tspAddressPort: "80",
  directAccess: true,
};

class EDSService {
  /**
   * Constructor of electron digital signature service
   */
  serverList = undefined;

  constructor() {
    this.hardwareSigner = new HardwareSigner();
    this.signer = new Signer();
    // this.signer.init();
    setTimeout(
      () =>
        this.hardwareSigner.init().catch(() => {
          // nothing to do
        }),
      2000
    );
  }

  getSigner = () =>
    [this.hardwareSigner, this.signer]
      .filter((signer) => signer.inited)
      .shift();

  getServerList = async () => {
    if (!this.serverList) {
      try {
        const casResponse = await fetch(eds.casPath);
        const cas = await casResponse.json();
        this.serverList = eds.useTestACSK ? cas.concat(testACSK) : cas;
      } catch (err) {
        alert(err.message + " certifications");
      }
    }
    return this.serverList;
  };
}

const service = new EDSService();

// export const serverList = eds.useTestACSK ? CAs.concat(testACSK) : CAs;

export default service;
