import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../../components/StatusChip";
import { executiveStatuses } from "../../variables/claimStatuses";

const ExecuteStatus = ({ eddStatuses, documentStateInfo, setId }) => {
  let state = 1;
  if (eddStatuses && eddStatuses[0]) {
    state =
      eddStatuses.sort(
        (a, b) => new Date(a.statusDate) > new Date(b.statusDate)
      )[0].status || 1;
  }
  const catState = 400 + state;
  return (
    <StatusChip
      setId={setId}
      status={state}
      catState={catState}
      tableChip={true}
      catStatuses={executiveStatuses}
      documentStateInfo={documentStateInfo}
    />
  );
};

ExecuteStatus.propTypes = {
  eddStatuses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  documentStateInfo: PropTypes.string,
  setId: PropTypes.func.isRequired,
};

ExecuteStatus.defaultProps = {
  eddStatuses: null,
  documentStateInfo: "",
};

export default ExecuteStatus;
