import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import StatusChip from "../../ExecutiveOrders/decorators/index";
import { translate } from "react-translate";
import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";
import humanDateFormat from "../../../helpers/humanDateFormat";

import {
  vpStatusesSelector,
  vdStatusesSelector,
} from "../../../reducers/executive.orders";
import {
  requestVpStatuses,
  requestVdStatuses,
} from "../../../actions/executive.orders";

import { InfoBlock as infoBlock } from "../layout/layout.components";
import { CreditorInfo } from "./CreditorInfo";
import { DebtorInfo } from "./DebtorInfo";

const ExecutiveInfo = ({ activeDoc, t, classes, setId }) => {
  const vpStatuses = useSelector(vpStatusesSelector);
  const vdStatuses = useSelector(vdStatusesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!vpStatuses) {
      const vpStatuses = requestVpStatuses();
      dispatch({ type: "REQUEST_VP_STATUSES", vpStatuses });
    }
    if (!vdStatuses) {
      const vdStatuses = requestVdStatuses();
      dispatch({ type: "REQUEST_VD_STATUSES", vdStatuses });
    }
  }, []);

  const { debtor, payer } = activeDoc || {};

  const creditors = activeDoc?.vpMembers?.filter(
    (item) => item.memberTypeCode === "collector"
  );

  const debtors = activeDoc?.vpMembers?.filter(
    (item) => item.memberTypeCode === "debtor"
  );

  const statusButton = ({ key, value }) => {
    const statuses = key === "vpStateId" ? vpStatuses : vdStatuses;
    const status = statuses?.length && statuses.find((_) => _?.id === value);

    return (
      <StatusChip
        t={t}
        setId={setId}
        status={status}
        statusName={status?.name ?? t("UNKNOWN_STATE")}
        statusColor={status?.color}
        tableChip={true}
        catStatuses={statuses}
      />
    );
  };

  return (
    <Fragment>
      <div className={classes.container}>
        {infoBlock(classes, `${t("INFO_ABOUT_DOC_CARD")}`, [
          [
            t("DOC_ACCEPT_DATE_CARD"),
            humanDateTimeFormat(activeDoc?.createdAt),
          ],
          [t("DOC_NUM_CANCELED_CARD"), activeDoc?.docNumCanceled],
        ])}

        {creditors?.length > 0 && (
          <CreditorInfo classes={classes} t={t} creditors={creditors} />
        )}

        {debtors?.length > 0 && (
          <DebtorInfo classes={classes} t={t} debtors={debtors} />
        )}

        {payer &&
          infoBlock(classes, t("PAYER"), [
            [payer?.isLegal ? t("NAME_LABEL") : t("PIB_LABEL"), payer?.name],
            [
              payer?.isLegal ? t("EDRPOU_LABEL") : t("RNOCPP_LABEL"),
              payer?.code,
            ],
            [t("ADDRESS_LABEL"), payer?.address],
            [t("EMAIL_LABEL"), debtor?.email],
            [t("PHONE_LABEL"), payer?.phone],
          ])}

        {activeDoc &&
          infoBlock(
            classes,
            `${t("VP_TITLE_CARD")}`,
            [
              [t("VP_NUM_LABEL"), activeDoc?.vpNum],
              [
                t("VP_STATE"),
                activeDoc?.vpStateId &&
                  statusButton({
                    key: "vpStateId",
                    value: activeDoc?.vpStateId,
                  }),
              ],
              [t("VP_START_DATE"), humanDateFormat(activeDoc?.vpStartDate)],
              [t("ORG_NAME"), activeDoc?.orgName],
              [t("EXECUTOR"), activeDoc?.executor],
              [t("EXECUTOR_PHONE"), activeDoc?.executorPhone],
            ],
            t
          )}

        {activeDoc &&
          infoBlock(
            classes,
            `${t("VD_TITLE_CARD")}`,
            [
              [t("VD_NUM"), activeDoc?.vdNum],
              [t("VD_DATE"), humanDateFormat(activeDoc?.vdDate)],
              [
                t("VD_STATE"),
                activeDoc?.vdStateId &&
                  statusButton({
                    key: "vdStateId",
                    value: activeDoc?.vdStateId,
                  }),
              ],
              [t("VP_START_DATE"), humanDateFormat(activeDoc?.vpEndDate)],
              [t("VP_END_REASON"), activeDoc?.vpEndReason],
              [t("SUM"), Math.round(Number(activeDoc?.sum) * 100) / 100],
              [t("SUM_CURRENCY"), activeDoc?.sumCurrency],
              [t("SUM_UAH"), Math.round(Number(activeDoc?.sumUah) * 100) / 100],
              [t("VD_CASE_NUMBER"), activeDoc?.vdCaseNumber],
            ],
            t
          )}
      </div>
    </Fragment>
  );
};

ExecutiveInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  activeDoc: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  CheckCabinet: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  onClickDownload: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default translate("ExecutiveOrders")(ExecutiveInfo);
