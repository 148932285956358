import React, { Fragment } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";
import renderHTML from "react-render-html";
import PerfectScrollbar from "react-perfect-scrollbar";

import { withStyles, Toolbar, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Close, Launch, Print } from "@material-ui/icons";

import cx from "classnames";
import { Preloader } from "../../../components";

import { blobToTextEncoded } from "../../../helpers/blobToText";
import promiseChain from "../../../helpers/promiseChain";
import humanDateFormat from "../../../helpers/humanDateFormat";

// import rtfToHTML from '../../../thirdparty/@iarna/rtf-to-html';
import rtfToHTML from "@iarna/rtf-to-html";

import {
  loadDocument,
  loadDocumentInfo,
  requestDictionary,
} from "../../../actions/search";
import styles from "../../../variables/styles/searchStyle";

const { btoa } = window;

class Document extends React.Component {
  state = { doc: null, info: null, error: null, loading: false };

  loadDocument = (documentId) =>
    Promise.all([
      promiseChain(
        [
          () => this.setState({ loading: true }),
          requestDictionary,
          () => documentId,
          loadDocument,
          blobToTextEncoded(),
          (doc) =>
            new Promise((resolve) => {
              if (/<[a-z][\s\S]*>/i.test(doc)) {
                return resolve(doc);
              }

              return rtfToHTML.fromString(doc, (err, html) => {
                if (err) {
                  return resolve(doc);
                }
                return resolve(html);
              });
            }),
        ],
        documentId
      ),
      this.props.paper ? loadDocumentInfo(documentId) : [],
    ])
      .then(([doc, [info]]) => this.setState({ doc, info, loading: false }))
      .catch((e) => this.setState({ error: e.message, loading: false }));

  UNSAFE_componentWillMount() {
    const { documentId } = this.props || {};
    this.loadDocument(documentId);
  }

  UNSAFE_componentWillReceiveProps({ documentId }) {
    if (this.props.documentId === documentId) {
      return;
    }
    this.loadDocument(documentId);
  }

  getContent() {
    const { doc } = this.state;
    const { highlight } = this.props;

    if (!doc) {
      return "";
    }

    let result = doc;

    if (highlight) {
      []
        .concat(
          ...[]
            .concat(...Object.values(highlight))
            .map((text) => text.match(/<em>(.*?)<\/em>/gi))
        )
        .filter(Boolean)
        .map((text) => text.replace("<em>", "").replace("</em>", ""))
        .filter((value, index, self) => self.indexOf(value) === index)
        .forEach((match) => {
          result = result.replace(new RegExp(match, "g"), `<em>${match}</em>`);
        });

      return result;
    }
    const findEndsDoubleHTML = result.match(/(<\/html>)/gi);
    if (findEndsDoubleHTML.length > 1) {
      const indexOfEndFirstHTML = result.indexOf(findEndsDoubleHTML[0]);
      const firstHTML = result.substring(0, indexOfEndFirstHTML);
      const isOracleTitle = firstHTML.includes("oracle");
      if (isOracleTitle) {
        const findStartMasterHTML = result.lastIndexOf(
          result.match(/(<html)/gi)[0]
        );
        result = result.substring(findStartMasterHTML, result.length - 1);
      }
    }

    result = result.replace(/(<a)/gi, '<a target="_blank"');

    return result;
  }

  renderInfoBlock = (className = "") => {
    const { t, judgmentForms, justiceKinds } = this.props;
    const { info } = this.state;
    const foundJusticeKind =
      ((justiceKinds || []).find((item) => item.id === info.justice_kind) || {})
        .name || "";
    const foundJudgmentForm =
      (
        (judgmentForms || []).find((item) => item.id === info.judgment_code) ||
        {}
      ).name || "";
    return (
      <div className={className}>
        <span>
          {t("CASE_NUMBER")}: <b>{info.case_num}</b>;{" "}
        </span>
        <span>
          {t("CASE_NUM")}: <b>{info.id}</b>;<br />
        </span>
        {foundJusticeKind && (
          <span>
            {t("JUSTICE_KIND")}: <b>{foundJusticeKind}</b>;{" "}
          </span>
        )}
        {foundJudgmentForm && (
          <span>
            {t("JUDGEMENT_FORMS")}: <b>{foundJudgmentForm}</b>;
          </span>
        )}
        <span>
          <br />
          {t("RECEIPT_DATE")}: <b>{humanDateFormat(info.receipt_date)}</b>;{" "}
        </span>
        <span>
          {t("ADJUDICATION_DATE")}:{" "}
          <b>{humanDateFormat(info.adjudication_date)}</b>;
        </span>
        {info.date_dnzs && (
          <span>
            <br />
            {t("DNZS_DATE")}: <b>{humanDateFormat(info.date_dnzs)}</b>;
          </span>
        )}
      </div>
    );
  };

  handlePrint = () => {
    const { t, paper } = this.props;
    const printContent = `
            <title>${t("HEADER")}</title>
            ${paper ? renderToString(this.renderInfoBlock()) : ""}
            <hr>
            ${this.getContent()}
        `;
    const wnd = window.open(t("HEADER"), "", "_blank");
    wnd.document.write(printContent);
    wnd.print();
    wnd.close();
  };

  renderBody = () => {
    const { t, classes, paper } = this.props;
    const { loading, error, info } = this.state;
    return (
      <Fragment>
        {info &&
          paper &&
          this.renderInfoBlock(cx(classes.page, paper && classes.paper))}
        <div
          className={cx({
            [classes.page]: true,
            [classes.paper]: paper,
          })}
        >
          {loading && <Preloader />}
          {!loading && error && t("ERROR_LOADING_DOCUMENT")}
          {!loading && !error && (
            <Fragment>
              {paper && (
                <IconButton
                  className={classes.printButton}
                  onClick={this.handlePrint}
                >
                  <Print className={classes.printIcon} />
                </IconButton>
              )}
              {renderHTML(this.getContent())}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  };

  render() {
    const { classes, paper, documentId, closeDocument } = this.props;
    const { loading, error } = this.state;
    if (!paper) {
      return (
        <main className={classes.content}>
          <Toolbar
            classes={{
              root: classes.documentHeader,
            }}
          >
            <div className={classes.grow} />
            {!loading && !error && (
              <Fragment>
                <IconButton
                  color="inherit"
                  className={classes.menuButton}
                  onClick={this.handlePrint}
                >
                  <Print />
                </IconButton>
                <IconButton
                  color="inherit"
                  href={`/search/${btoa(documentId)}`}
                  target="_blank"
                  className={classes.menuButton}
                >
                  <Launch />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={closeDocument}
                  className={classes.menuButton}
                >
                  <Close />
                </IconButton>
              </Fragment>
            )}
          </Toolbar>
          <Divider />
          <PerfectScrollbar
            ref={(ref) => {
              this.scrollBarRef = ref;
            }}
            className={classes.withToolbar}
          >
            {this.renderBody()}
          </PerfectScrollbar>
        </main>
      );
    }
    return this.renderBody();
  }
}

Document.propTypes = {
  paper: PropTypes.bool,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  documentId: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  judgmentForms: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  justiceKinds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  closeDocument: PropTypes.func,
};

Document.defaultProps = {
  paper: true,
  highlight: false,
  judgmentForms: null,
  justiceKinds: null,
  closeDocument: () => null,
};

const mapStateToProps = ({
  search: { causeCategories, judgmentForms, justiceKinds },
}) => ({
  causeCategories,
  judgmentForms,
  justiceKinds,
});

const translated = translate("SearchPage")(Document);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps)(styled);
