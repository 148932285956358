import { FC } from "react";

import {
  MobileStepper,
  Stepper,
  Step,
  StepLabel,
  Hidden,
} from "@material-ui/core";

import { Button } from "../../../components";
import ProgressLine from "../../Preloader/ProgressLine";
import SchemaForm, { FormSchema } from "../../FormFromSchema";

export interface WizardStepperProps {
  activeStepId: number;
  allSteps: { key: string; label: string }[];
  classes: any;
  context: {
    [key: string]: any;
  };
  finishButtonText: string;
  loading?: boolean;
  noErrorCheck?: boolean;
  onError: (error: any) => Promise<void>;
  onStepChange: (nextStepId: number) => Promise<void>;
  onStepCheck?: (
    stepKey: string,
    values: any,
    errors?: Map<string, string>
  ) => Promise<void>;
  onStepSave: (
    activeStep: string,
    activeStepData: Record<string, any>
  ) => Promise<void>;
  onStepUpdate?: (
    stepKey: string,
    values: any,
    errors?: Map<string, string>
  ) => Promise<void>;
  onValueChange?: (
    formName: string,
    valueKey: string,
    value: any
  ) => Promise<void>;
  setId: (s: string) => string;
  stepData: {
    [key: string]: any;
  };
  dataSaving: boolean;
  stepSchema: FormSchema;
  t: (key: string, params?: any[]) => string;
}

const WizardStepper: FC<WizardStepperProps> = ({
  activeStepId,
  allSteps,
  classes,
  context,
  finishButtonText,
  loading,
  noErrorCheck,
  onError,
  onStepChange,
  onStepCheck,
  onStepSave,
  onStepUpdate,
  onValueChange,
  setId,
  stepData,
  dataSaving,
  stepSchema,
  t,
}) => {
  const handleBack = async () => {
    await onStepChange(activeStepId - 1);
  };

  const handleNext = async () => {
    await onStepChange(activeStepId + 1);
  };

  const handleDataSave = async (name: string, values: any) => {
    await onStepSave(name, values);
  };

  const handleError = async (error: string) => {
    onError && (await onError(error));
  };

  return (
    <div className={classes.root} id={setId("")}>
      <Hidden smDown={true} implementation="css">
        <Stepper activeStep={activeStepId} alternativeLabel>
          {allSteps.map((entry) => (
            <Step
              id={setId(entry.key)}
              key={entry.key}
              className={classes.step}
            >
              <StepLabel className={classes.stepTitle}>{entry.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {!loading && (
          <SchemaForm
            setId={setId}
            t={t}
            context={context}
            data={stepData}
            dataSaving={dataSaving}
            name={allSteps[activeStepId].key}
            noErrorCheck={noErrorCheck}
            onDataCheck={onStepCheck}
            onDataSave={handleDataSave}
            onDataUpdate={onStepUpdate}
            onError={handleError}
            onValueChange={onValueChange}
            schema={stepSchema}
          />
        )}
        <ProgressLine loading={loading} />
        <div className={classes.buttonPanel}>
          {activeStepId > 0 && (
            <Button
              color="transparent"
              disabled={activeStepId === 0 || loading}
              onClick={handleBack}
              className={classes.backButton}
            >
              {t("BACK")}
            </Button>
          )}
          <Button
            color="yellow"
            className={classes.nextButton}
            disabled={loading}
            onClick={handleNext}
            variant="contained"
          >
            {activeStepId === allSteps.length - 1
              ? finishButtonText || t("FINISH_STEPPER")
              : t("NEXT")}
          </Button>
        </div>
      </Hidden>
      <Hidden mdUp={true}>
        <MobileStepper
          variant="dots"
          steps={allSteps.length}
          position="static"
          activeStep={activeStepId}
          className={classes.mobileStepper}
          nextButton={
            <Button
              className={classes.nextButton}
              disabled={loading}
              onClick={handleNext}
              variant="contained"
            >
              {activeStepId === allSteps.length - 1
                ? finishButtonText || t("FINISH_STEPPER")
                : t("NEXT")}
            </Button>
          }
          backButton={
            <Button
              className={classes.backButton}
              disabled={loading || activeStepId === 0}
              onClick={handleBack}
            >
              {t("BACK")}
            </Button>
          }
        />
        <ProgressLine loading={loading} />
        {!loading && (
          <SchemaForm
            setId={setId}
            t={t}
            context={context}
            data={stepData}
            dataSaving={dataSaving}
            name={allSteps[activeStepId].key}
            onDataCheck={onStepCheck}
            onDataSave={handleDataSave}
            onDataUpdate={onStepUpdate}
            onError={handleError}
            onValueChange={onValueChange}
            schema={stepSchema}
          />
        )}
      </Hidden>
    </div>
  );
};

export default WizardStepper;
// export default compose(withStyles(styles))(WizardStepper);
