import { Fragment } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";
import StandartPageLayout from "../../../components/StandartPageLayout";

import { LinearProgress } from "@material-ui/core";

import CreateClaimDialog from "../../../components/CreateClaimDialog";
import PermissionsDialog from "../../../components/PermissionsDialog";
import DeleteClaimDialog from "../../../components/DeleteClaimDialog";
import ClaimToolbar from "./ClaimToolbar";
import ClaimTable from "./ClaimTable";
import CreateButton from "./CreateButton";
import { CLAIM_WIKI_URL } from "../../../variables/wikiUrls";

const ClaimLayout = ({
  t,
  classes,
  trash,
  highlightClaim,
  checked,
  error,
  loading,
  openCreateClaimDialog,
  openDeleteClaimDialog,
  closeDialog,
  busy,
  list,
  docCategory,
  toggleTemplateDialog,
  toggleDeleteDialog,
  handleClaimRecover,
  onSelectAllClick,
  createSortHandler,
  getTemplate,
  onCheckItem,
  handleClick,
  handleClaimCreate,
  handleClaimDelete,
  dataSource,
  pagination,
  changeCount,
  openPermissionDialog,
  owners,
  ownership,
  changeOwnership,
  togglePermissionDialog,
  load,
  setId,
  templates,
  templatePermissionFilter,
  blockScreen,
  title,
  courts,
  userInfo,
  rootComponent,
  idsForDelete,
  approval,
  returnObject,
}) => {
  const createDialogs = (
    <Fragment>
      <CreateClaimDialog
        openCreateClaimDialog={openCreateClaimDialog}
        handleClaimCreate={handleClaimCreate}
        toggleTemplateDialog={toggleTemplateDialog}
        setId={(elmentName) => setId(`create-dialog-${elmentName}`)}
        docCategory={docCategory}
        ownership={ownership}
        templatePermissionFilter={templatePermissionFilter}
      />
      <PermissionsDialog
        open={openPermissionDialog}
        togglePermissionDialog={togglePermissionDialog}
        busy={busy}
        owners={owners}
        value={ownership}
        onChange={changeOwnership}
        closeDialog={closeDialog}
        setId={(elmentName) => setId(`permissions-dialog-${elmentName}`)}
      />
    </Fragment>
  );
  return (
    <StandartPageLayout
      list={list}
      trash={trash}
      loading={loading}
      subLoading={!templates}
      blockScreen={blockScreen}
      dataSource={dataSource}
      emptyPageTitle={t(`${rootComponent.toUpperCase()}_EMPTY_PAGE_TITLE`)}
      emptyPageDescription={t(
        `${rootComponent.toUpperCase()}_EMPTY_PAGE_DESCRIPTION`
      )}
      trashEmptyPageTitle={t("TRASH_EMPTY_PAGE_TITLE")}
      trashEmptyPageDescription={t("TRASH_EMPTY_PAGE_DESCRIPTION")}
      cardTitle={t(title)}
      cardSubtitle={t(title + "2")}
      stepName={trash ? "trash" : rootComponent}
      setId={setId}
      error={error}
      wikiUrl={CLAIM_WIKI_URL}
      toolbar={
        <ClaimToolbar
          t={t}
          classes={classes}
          trash={trash}
          checked={checked}
          idsForDelete={idsForDelete}
          busy={busy}
          dataSource={dataSource}
          toggleTemplateDialog={togglePermissionDialog}
          toggleDeleteDialog={toggleDeleteDialog}
          handleClaimRecover={handleClaimRecover}
          load={load}
          setId={(elmentName) => setId(`toolbar-${elmentName}`)}
          userInfo={userInfo}
          docCategory={docCategory}
          approval={approval}
          returnObject={returnObject}
        />
      }
      createButton={
        <CreateButton
          onChange={togglePermissionDialog}
          t={t}
          setId={setId}
          userInfo={userInfo}
          docCategory={docCategory}
        />
      }
      createDialog={createDialogs}
    >
      <div className={classes.scroll} id={setId("wrap")}>
        {loading && <LinearProgress className={classes.absolutePosition} />}
        <ClaimTable
          list={list || []}
          dataSource={dataSource}
          t={t}
          highlightClaim={highlightClaim}
          checked={checked}
          onSelectAllClick={onSelectAllClick}
          createSortHandler={createSortHandler}
          getTemplate={getTemplate}
          onCheckItem={onCheckItem}
          handleClick={handleClick}
          pagination={pagination}
          changeCount={changeCount}
          setId={(elmentName) => setId(`table-${elmentName}`)}
          courts={courts}
          docCategory={docCategory}
          approval={approval}
          trash={trash}
        />
        {loading && <LinearProgress className={classes.absolutePosition} />}
        <DeleteClaimDialog
          checked={idsForDelete}
          t={t}
          openDeleteClaimDialog={openDeleteClaimDialog}
          classes={classes}
          busy={busy}
          toggleDeleteDialog={toggleDeleteDialog}
          handleClaimDelete={handleClaimDelete}
          trash={trash}
          setId={(elmentName) => setId(`delete-dialog-${elmentName}`)}
        />
      </div>
      {createDialogs}
    </StandartPageLayout>
  );
};

ClaimLayout.propTypes = {
  docCategory: PropTypes.number,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  trash: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  highlightClaim: PropTypes.bool,
  checked: PropTypes.array.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool,

  openCreateClaimDialog: PropTypes.bool.isRequired,
  openDeleteClaimDialog: PropTypes.bool.isRequired,
  openPermissionDialog: PropTypes.bool.isRequired,
  togglePermissionDialog: PropTypes.func.isRequired,
  handleClaimCreate: PropTypes.func.isRequired,
  handleClaimDelete: PropTypes.func.isRequired,
  toggleTemplateDialog: PropTypes.func.isRequired,
  toggleDeleteDialog: PropTypes.func.isRequired,
  handleClaimRecover: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  changeOwnership: PropTypes.func.isRequired,

  closeDialog: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  getTemplate: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  pagination: PropTypes.func.isRequired,
  changeCount: PropTypes.func.isRequired,
  owners: PropTypes.array,
  ownership: PropTypes.object.isRequired,
  load: PropTypes.func.isRequired,
  templates: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  blockScreen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  userInfo: PropTypes.object.isRequired,
  rootComponent: PropTypes.string.isRequired,
  approval: PropTypes.bool,
};

ClaimLayout.defaultProps = {
  docCategory: 1,
  highlightClaim: false,
  owners: [],
  error: false,
  setId: setComponentsId("claim-layout"),
  loading: false,
  templates: null,
  list: null,
  courts: null,
  approval: false,
};

export default ClaimLayout;
