import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";
import { filterMinDate, filterMaxDate } from "../../../helpers/humanDateFormat";

const formatDate = (value, t, filter) => {
  const splited = value.split(",");

  if (splited.length !== 2) {
    return "";
  }

  if (splited[0] === splited[1]) {
    return `${
      filter === "docAcceptDate" ? t("DOC_ACCEPT_DATE") : t("DNZS_DATE")
    }: ${splited[0]}`;
  }

  const fromDate =
    splited[0] && splited[0] !== filterMinDate
      ? `${
          filter === "docAcceptDate"
            ? t("FROM_DOC_ACCEPT_DATE")
            : t("FROM_DNZS_DATE")
        }: ${splited[0]}`
      : "";
  const toDate =
    splited[1] && splited[1] !== filterMaxDate
      ? `${
          filter === "docAcceptDate"
            ? t("TO_DOC_ACCEPT_DATE")
            : t("TO_DNZS_DATE")
        }: ${splited[1]}`
      : "";
  const connector = fromDate && toDate ? ", " : "";

  return `${fromDate}${connector}${toDate}`;
};

const DateForceFilterChip = ({ t, value, filter = "dateForce", ...rest }) => {
  console.log("filter: ", filter);
  return <Chip label={formatDate(value, t, filter)} {...rest} />;
};

DateForceFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

DateForceFilterChip.defaultProps = {
  value: "",
};

export default translate("ClaimList")(DateForceFilterChip);
