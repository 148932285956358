import setComponentsId from "../../helpers/setComponentsId";

import ClaimList from "../../pages/Claim";
import ApprovalList from "../../pages/Claim/approvalList";
import ClaimWizard from "../../pages/Claim/claimWizard";

import IndexPage from "../../pages";
import LoadingPage from "../../components/Preloader";
import CaseList from "../../pages/Case";
import Share from "../../pages/Share";
import NotificationList from "../../pages/Notifications";
import NotificationDetails from "../../pages/Notifications/notificationDetails";

import UserProfile from "../../pages/UserProfile";
import MyDocuments from "../../pages/MyDocuments";
import TestPage from "../../pages/TestPage";
import Statistics from "../../pages/Statistics";
import Favorites from "../../pages/Favorites";
import CheckCabinet from "../../pages/CheckCabinet";
import DocumentView from "../../pages/DocumentView";

import createRoutes from "../../helpers/createRoutes";
import claimCategories from "../../variables/claimCategories";
import InputDocsList from "../../pages/InputDocs";
import StateRegisters, {
  RegisterReqList,
  RegisterReqView,
} from "../../pages/StateRegisters";

import config from "../../config";

import {
  List,
  // VideoCall,
  Message,
  // Gavel,
  // Work,
  Delete,
  Folder,
  SupervisorAccount,
  Assessment,
  FindInPage, // - Реєстр судових рішень
  Star,
  Videocam,
  Person,
  FilterNone,
  HelpOutline,
} from "@material-ui/icons";

import InputDocsIcon from "./icons/InputDocsIcon";
import ExecutiveDocView from "../../pages/ExecutiveDocView";
import ExecutiveOrderView from "../../pages/ExecutiveOrderView";
import ExecutiveDocsList from "../../pages/ExecutiveDocs/index";
import ExecutiveOrders from "../../pages/ExecutiveOrders/index";

import ExecDocsIcon from "./icons/ExecDocsIcon";

import LogoJudgeCase from "./LogoJudgeCase";
import LogoApprovalDocument from "./LogoApprovalDocument";
import { handleVKZClick, handleEDRSRClick } from "../../pages/VideoConnect";
import { handleESITSClick } from "../../pages/EsitsBase";
// import InvolveSpecialist from "../../pages/InvolveSpecialist";
// import InvolveSpecialistIcon from "../../pages/InvolveSpecialist/Icon";

const { ApprovalRoles } = config(); // registerRoles - Реєстр судових рішень || EVDroles, RegisterEVDRoles,

export const appRoutes = [
  {
    path: "/",
    component: IndexPage,
    accessRoles: [
      "representative",
      "individual",
      "secretary",
      "humanResources",
      "legalEntityAgent",
      "judge",
    ],
    setId: setComponentsId("index-page"),
  },
  {
    path: "/notifications",
    title: "NOTIFICATIONS",
    navbar: true,
    icon: Message,
    component: NotificationList,
    accessRoles: [
      "representative",
      "individual",
      "secretary",
      "humanResources",
      "legalEntityAgent",
      "judge",
    ],
    setId: setComponentsId("notifications-list"),
    showingList: "noteList",
    showingListFilter: (item) => !item.viewed,
  },
  {
    path: "/notifications/:messageId",
    component: NotificationDetails,
    accessRoles: [
      "representative",
      "individual",
      "secretary",
      "legalEntityAgent",
      "judge",
    ],
    setId: setComponentsId("notifications-details"),
  },
  {
    path: "/favorites",
    title: "FAVORITES",
    navbar: true,
    icon: Star,
    component: Favorites,
    hideStatus: -1,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("favorites"),
  },
  {
    path: "/judge_cases/:caseId?/:proceedingId?/:documentId?",
    title: "JUDGE_CASE_LIST",
    navbar: true,
    icon: LogoJudgeCase,
    component: CaseList,
    hideStatus: -1,
    accessRoles: ["judge"],
    setId: setComponentsId("judge-cases"),
    itIsJudge: true,
    page: "judge_cases",
  },
  {
    path: "/cases/:caseId?/:proceedingId?/:documentId?",
    title: "CASE_LIST",
    navbar: true,
    icon: Folder,
    component: CaseList,
    hideStatus: -1,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("cases"),
    itIsJudge: false,
    page: "cases",
  },
  {
    path: "/claims",
    title: "CLAIM_LIST",
    navbar: true,
    icon: List,
    component: ClaimList,
    filters: { category: claimCategories.claims },
    hideStatus: -1,
    rootComponent: "claims",
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("claims"),
  },
  {
    path: "/claims/:claimId/:stepId?",
    rootComponent: "claims",
    component: ClaimWizard,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("claim-wizard"),
  },
  {
    path: "/inputdocs",
    title: "DOCUMENTS_OF_SIDES",
    navbar: true,
    icon: InputDocsIcon,
    component: InputDocsList,
    hideStatus: -1,
    rootComponent: "inputdocs",
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("input-docs"),
  },
  {
    path: "/executive",
    title: "EXECUTIVE_DOCS",
    navbar: true,
    icon: ExecDocsIcon,
    component: ExecutiveDocsList,
    hideStatus: -1,
    rootComponent: "executive",
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("executive-docs"),
  },
  {
    path: "/executive-decisions",
    title: "EXECUTIVE_ORDERS",
    navbar: true,
    icon: FilterNone,
    component: ExecutiveOrders,
    hideStatus: -1,
    rootComponent: "executive-decisions",
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("decisions"),
  },
  {
    path: "/executive/:id",
    component: ExecutiveDocView,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("executive-document-view"),
  },
  {
    path: "/executive-decisions/:id",
    component: ExecutiveOrderView,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("decisions-document-view"),
  },
  {
    path: "/debtor_claims/:claimId/:stepId?",
    rootComponent: "claims",
    component: ClaimWizard,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("debtorClaims-wizard"),
  },
  {
    path: "/cases_claims/:claimId/:stepId?",
    rootComponent: "cases_claims",
    component: ClaimWizard,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("casesClaim-wizard"),
  },
  {
    path: "/share",
    title: "SHARE_LIST",
    navbar: true,
    icon: SupervisorAccount,
    component: Share,
    accessRoles: [
      "representative",
      "individual",
      "police",
      "nabu",
      "accountant",
      "executiveDocumentsRegister",
      "legalEntityAgent",
    ],
    setId: setComponentsId("share"),
  },
  {
    path: "/statistics",
    external: true,
    navbar: true,
    icon: Assessment,
    title: "STATISTICS",
    // accessRoles: ['representative', 'individual', 'legalEntityAgent'],
    accessRoles: [],
    component: Statistics,
    setId: setComponentsId("statistics"),
  },
  {
    path: "/approval/:claimId/:stepId?",
    rootComponent: "approval",
    component: ClaimWizard,
    accessRoles: ApprovalRoles || [],
    setId: setComponentsId("approval-wizard"),
  },
  {
    path: "/approval",
    title: "APPROVAL_DOCUMENT_LIST",
    rootComponent: "approval",
    navbar: true,
    icon: LogoApprovalDocument,
    component: ApprovalList,
    hideStatus: -1,
    navBarFilter: "approvalCount",
    accessRoles: ApprovalRoles || [],
    forSign: true,
    setId: setComponentsId("approval"),
  },
  {
    path: "/trash",
    title: "CLAIM_TRASH_LIST",
    navbar: true,
    icon: Delete,
    component: ClaimList,
    hideStatus: 1,
    filters: { state: -1 },
    trash: true,
    rootComponent: "trash",
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("trash"),
  },
  {
    path: "/vkz",
    title: "VIDEO_CONNECTION",
    navbar: true,
    icon: Videocam,
    external: true,
    handler: () => handleVKZClick,
    component: () => null,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("redirect-vkz"),
  },
  {
    path: "/edrsr",
    title: "EDRSR",
    navbar: config().edrsrRedirectUrl ? true : false,
    icon: Videocam,
    external: true,
    handler: config().edrsrRedirectUrl ? () => handleEDRSRClick : () => null,
    component: () => null,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("redirect-edrsr"),
  },
  {
    path: "/check_cabinet",
    title: "CHECK_CABINET",
    navbar: true,
    icon: Person,
    component: CheckCabinet,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("check-cabinet"),
  },
  {
    path: "/state_registers",
    title: "STATE_REGISTERS",
    navbar: true,
    icon: FindInPage,
    component: StateRegisters,
    accessRoles: ["judge"],
    setId: setComponentsId("state-registers"),
    page: "state_registers",
  },
  {
    path: "/state_registers/:registerId?",
    rootComponent: "state_registers",
    component: RegisterReqList,
    accessRoles: ["judge"],
    setId: setComponentsId("state-register-req-list"),
    page: "state_register_req_list",
  },
  {
    path: "/state_registers/request/:requestId?",
    rootComponent: "state_registers",
    component: RegisterReqView,
    accessRoles: ["judge"],
    setId: setComponentsId("state-registers-request"),
    page: "state_register_reguest",
  },
  // {
  //   path: "/involve-specialist",
  //   title: "INVOLVE_SPECIALIST",
  //   navbar: false,
  //   component: InvolveSpecialist,
  //   icon: InvolveSpecialistIcon,
  //   accessRoles: ["representative", "individual", "legalEntityAgent"],
  //   setId: setComponentsId("involve-specialist"),
  // },
  {
    path: "/trash/:claimId",
    component: ClaimWizard,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("trash-wizard"),
  },
  {
    path: "/loading",
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    component: LoadingPage,
  },
  {
    path: "/profile",
    component: UserProfile,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("user-profile"),
  },
  {
    path: "/company-profile",
    component: UserProfile,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("user-profile"),
  },
  {
    path: "/my-documents",
    component: MyDocuments,
    accessRoles: ["representative", "individual", "legalEntityAgent"],
    setId: setComponentsId("my-documents"),
  },
  {
    path: "/document/:claimId",
    component: DocumentView,
    accessRoles: ["representative", "individual", "legalEntityAgent", "judge"],
    setId: setComponentsId("document-view"),
  },
  {
    path: "/test",
    component: TestPage,
    accessRoles: [
      "representative",
      "individual",
      "secretary",
      "legalEntityAgent",
    ],
    setId: setComponentsId("test"),
  },
  {
    path: "/esits",
    title: "ESITS_KNOWLEDGE_BASE",
    navbar: true,
    icon: HelpOutline,
    external: true,
    handler: () => handleESITSClick,
    component: () => null,
    accessRoles: [
      "representative",
      "individual",
      "secretary",
      "humanResources",
      "legalEntityAgent",
      "judge",
    ],
    setId: setComponentsId("esits-knowledge-base"),
  },
];

export default createRoutes(appRoutes);
