import { detect } from "detect-browser";

const { name: browserName } = detect();

export default (file, format, text) =>
  new Promise((resolve) => {
    const URL = window.URL || window.webkitURL;
    let url = file && file.size ? URL.createObjectURL(file) : "";
    if (format === "html" && browserName === "safari" && file.size) {
      url = `data:text/html;charset=utf-8,${encodeURI(text)}`;
    }
    return resolve(url);
  });
