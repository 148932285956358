import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import Select from "../../../components/Select";
import * as api from "../../../services/api";
import { CertByType } from "../../../components/withAdvocate";

type Props = {
  onChange: (id?: number) => void;
  classes: any;
  value: number | null;
};

const loadRa = (): Promise<{ id: number; title: string }[]> => {
  return api.get("api/advocates/ra", "", () => {});
};

type Option = {
  id: string;
  name: string;
  type: CertByType;
  dictionaryID: number;
};

function createID(id: number, type: CertByType) {
  return type + id;
}

const byType: CertByType = "RA";

function RaDictionaryControl({ onChange, value, classes }: Props) {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!byType) return;

    loadRa().then((ra) => {
      let result: Option[] = ra.map((item) => ({
        name: item.title,
        type: byType,
        dictionaryID: item.id,
        id: createID(item.id, byType),
      }));

      setOptions(result);
    });
  }, [byType]);

  return (
    <div className={classes.wrapper}>
      <Select
        multi={false}
        value={value && createID(value, byType)}
        onChange={({ target: { value } }) => {
          const data = options.find((data) => data.id === value);

          if (!data) return onChange();

          onChange(data.dictionaryID);
        }}
        options={options}
        selectProps={{
          textFieldProps: { label: "Рада адвокатів*" },
        }}
      />
    </div>
  );
}

const styles = {
  wrapper: {
    marginBottom: 8,
  },
} as const;

export default withStyles(styles)(RaDictionaryControl);
