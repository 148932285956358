import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import FilterInput from "../../../components/FilterInput";
import { sortStatuses } from "../../../variables/executiveStatuses";
import DateRangePicker from "../../../components/CustomInput/DateRangePicker";
import StatusChip from "./StatusChip";

// classes - из параметров которые приходят в Filters

const Filters = ({ setId, t, dataSource, load, statuses, classes }: any) => {
  const sortingStatuses = sortStatuses(t, statuses);
  return (
    <FilterInput
      dataSource={dataSource}
      load={load}
      setId={setId}
      statuses={sortingStatuses}
    >
      <TextField
        select
        name="statusId"
        label={t("DOCUMENT_STATUS")}
        margin="normal"
        id={setId("select-status")}
        style={{ display: "inlineFlex" }}
        SelectProps={{
          id: setId("select-statusId-filter"),
          SelectDisplayProps: {
            id: setId("select-statusId-filter-wrapper"),
          },
        }}
        className={classNames(classes.margin, classes.textField, classes.flex)}
      >
        {sortingStatuses.map(({ id, name, value }, index) => (
          <MenuItem key={index} value={value} id={setId(`status-${id}`)}>
            <StatusChip status={id} statusText={name} tableChip={true} />
          </MenuItem>
        ))}
      </TextField>
      <DateRangePicker
        name="updatedAt" // updatedAt createdAt
        label={t("DOCUMENT_STATUS")}
        setId={setId}
      />
    </FilterInput>
  );
};

export default Filters;
