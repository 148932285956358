const initialState = {
  loading: false,
  history: [],
};

const getHistory = (history, request) => {
  if (history.length >= 30) {
    history.splice(1, 1);
  }
  if (request.url) {
    history.push(request);
  }
  return history;
};

export default (state = initialState, request) => ({
  ...state,
  loading: request.type.includes("_LOADING"),
  history: getHistory(state.history, request),
});
