import * as api from "../services/api";

import { Advocate, CertByType } from "../components/withAdvocate";

type CheckLicenseArg = {
  surname: string;
  firstname: string;
  middlename: string;
  certnum: string;
  certbytype: CertByType;
  certbyid: number;
  raid: number;
};

export async function checkLicense(
  arg: CheckLicenseArg
): Promise<Advocate | null> {
  if (!arg.certnum) return null;

  const check = (arg: CheckLicenseArg) =>
    api.post("api/advocates/check", arg, "", () => {});

  const search = (args: {
    firstname: string;
    middlename: string;
    surname: string;
    certnum: string;
    raid: number;
  }) => {
    let query = Object.entries(args).reduce((acc, [key, value], i) => {
      acc += `${key}=${value}`;
      if (i !== Object.entries(args).length - 1) acc += "&";
      return acc;
    }, "");

    return api.get(`api/advocates/search?${query}`, "", () => {});
  };

  const [{ advocate, occupation }, { advocates = [] }] = await Promise.all([
    check(arg),
    search(arg),
  ]);

  const [foundAdvocate = {} as Advocate] = advocates;

  return {
    certbyid: arg.certbyid,
    certbytype: arg.certbytype,
    raid: arg.raid,
    advocate: !!advocate,
    certnum: foundAdvocate.certnum,
    occupation: !!occupation,
    certat: foundAdvocate.certat,
    id: foundAdvocate.id,
    regat: foundAdvocate.regat,
    regnum: foundAdvocate.regnum,
    raname: foundAdvocate.racalc,
    certbyname: foundAdvocate.certcalc,
  };
}
