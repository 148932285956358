import { yellowColor, lightGrayColor, primaryColor } from "../styles";

export default {
  shareDisabled: {
    opacity: 0.5,
  },
  actions: {
    justifyContent: "flex-start",
  },
  flex: {
    flex: 1,
  },
  statusImage: {
    marginTop: 10,
    marginLeft: 10,
  },
  stepHeader: {
    borderBottom: `solid 1px ${lightGrayColor}`,
    marginBottom: 27,
  },
  toolbar: {
    boxSizing: "content-box",
    width: "100%",
    // padding: "0 12px",
    padding: "0",
    margin: "0 auto",
    display: "flex",
    flexWrap: "wrap",
    // "& > *": {
    //   marginRight: 10,
    // },
  },
  row: {
    cursor: "pointer",
  },
  scroll: {
    overflow: "auto",
    paddingBottom: "24px",
  },
  found: {
    backgroundColor: yellowColor,
  },
  groupWrap: {
    padding: "0 0 0 24px",
  },
  link: {
    color: primaryColor,
  },
  groupSummary: {
    borderRadius: "4px",
    "& div": {
      whiteSpace: "normal",
    },
  },
  groupTitle: {
    backgroundColor: lightGrayColor,
    borderRadius: "4px",
  },
  subCatIcon: {
    width: "2em",
  },
  subCatText: {
    width: "calc(100% - 2em)",
  },
  rightButton: {
    margin: "0 24px 0 10px",
  },
  docWrapper: {
    overflow: "auto",
    padding: "24px",
  },
  dialogFooter: {
    padding: "24px",
    borderTop: `1px solid ${lightGrayColor}`,
  },
  mobileButtonFullWidth: {
    padding: "12px 10px",
  },
  // Mobile
  "@media (max-width:1650px)": {
    progress: {
      marginTop: "8px",
    },
    // eslint-disable-line no-useless-computed-key
    modalMobile: {
      width: 100,
    },
    textCentered: {
      textAlign: "left",
    },
    contentWrap: {
      "& div": {
        alignItems: "normal",
      },
    },
    groupWrap: {
      padding: 5,
    },
    groupTitle: {
      alignItems: "normal",
    },
    subCatText: {
      margin: 0,
      padding: 0,
    },
    toolbar: {
      "& > button": {
        maxWidth: "calc(50% - 15px)",
      },
    },
    mobileButtonFullWidth: {
      padding: "12px 30px",
      "& .material-icons": {
        fontSize: 19,
      },
    },
  },
  "@media (max-width:400px)": {
    // eslint-disable-line no-useless-computed-key
    toolbar: {
      "& > button": {
        maxWidth: "none",
      },
    },
  },
  dialog: {
    "& > :last-child": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        "& h3": {
          fontSize: "0.7rem",
        },
      },
      "@media (max-width:425px)": {
        // eslint-disable-line no-useless-computed-key
        margin: 0,
        "& > div": {
          margin: 15,
        },
      },
    },
  },
  dialogContentWrappers: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: 15,
    },
  },
  absolutePosition: {
    position: "absolute",
    width: "100%",
  },
  relativePosition: {
    position: "relative",
  },
  subtitleInitialCase: {
    textTransform: "initial",
  },
  listWithSplitPaneWrap: {
    "& .Resizer.vertical": {
      backgroundImage: "linear-gradient(#000, #fff)",
      height: 150,
    },
  },
  disabledItem: {
    opacity: 0.6,
    cursor: "not-allowed",
  },
  videoFrame: {
    width: "100%",
    maxWidth: "640px",
    height: "370px",
    padding: "20px 12px",
    boxSizing: "border-box",
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      height: "315px",
    },
  },
  downloadBtn: {
    marginRight: "20px",
    "@media (max-width:767px)": {
      marginRight: "10px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
};
