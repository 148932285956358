/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

import customInputStyle from "./customInputStyle";

export default {
  ...customInputStyle,
  sampleText: {
    color: "#6D727C",
  },
  wrap: {
    display: "block",
  },
  sample: {
    width: "100%",
    maxWidth: "calc(100% - 30px)",
    marginBottom: 0,
    marginTop: 20,
  },
  fileImage: {
    margin: "46px auto",
    display: "block",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundSize: "cover",
    overflow: "hidden",
  },
  downloadBtn: {
    width: "100%",
    height: 132,
    position: "relative",
  },
  previewBtn: {
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
    },
  },
  downloadProgress: {
    position: "absolute",
    width: "100%",
  },
  gridItem: {
    "& > div": {
      height: "100%",
      border: "#aaa 1px solid",
    },
  },
  touchBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};
