const checkUndefined = (string) =>
  !!string && typeof string === "string" && string !== "undefined";

export default ({ userFileName, name, fileName, file = {} }) => {
  let attachName = "Документ";
  if (checkUndefined(userFileName)) {
    attachName = userFileName;
  } else if (checkUndefined(name)) {
    attachName = name;
  } else if (checkUndefined(fileName)) {
    attachName = fileName;
  } else if (checkUndefined(file.name)) {
    attachName = file.name;
  }
  if (attachName.includes("screeningSharp2358")) {
    attachName = attachName.replace(/screeningSharp2358/g, "#");
  }
  return attachName;
};
