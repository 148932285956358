import {
  requestPermissionsList,
  deletePermission,
  addPermission,
  editPermission,
} from "../../../actions/permissions";
import blobToBase64 from "../../../helpers/blobToBase64";
import promiseChain from "../../../helpers/promiseChain";
import {
  requestSignData,
  signDocument,
  commitDocument,
} from "../../../actions/claim";

import { signManifestVersions } from "../../../services/eds/helpers/signManifest";
import { checkCertificate } from "../../../actions/user";

const checkError = (resp) => resp instanceof Error;

export function setPdf(doc, data, shareDocId, itIsUpdate) {
  this.setState({ loading: true, itIsUpdate, blockScreen: true });
  const changedShare = { ...data, documentId: shareDocId };
  if (checkError(doc)) {
    return this.setState({
      error: doc.message,
      pdf: null,
      doc: null,
      loading: false,
      blockScreen: false,
    });
  }
  return blobToBase64(doc).then((pdf) =>
    this.setState(
      {
        error: "",
        pdf,
        doc,
        showSignModal: false,
        display: "signing",
        shareDocId,
      },
      () =>
        requestPermissionsList().then(() =>
          this.setState({
            loading: false,
            dialog: "",
            changedShare,
            blockScreen: false,
          })
        )
    )
  );
}

export function toggleSignModal() {
  const { shareDocId, showSignModal } = this.state;
  return requestSignData(shareDocId).then((manifest) =>
    this.setState({ manifest, showSignModal: !showSignModal })
  );
}

export function setPermission(resp) {
  if (checkError(resp)) {
    const { message: error } = resp;
    const { t } = this.props;
    const errorLabel =
      error &&
      error
        .match(/[\w\s]+/g)
        .join("")
        .split(" ")
        .join("_")
        .toUpperCase();
    const errorTranslate = t(errorLabel);
    const errorText = errorTranslate.includes("CaseList")
      ? t("UNKNOWN_SHARE_ERROR", { error })
      : errorTranslate;
    return this.setState({
      signError: errorText,
      showSignModal: false,
      blockScreen: false,
    });
  }
  return this.setState(
    { error: "", signError: "", blockScreen: false, changedShare: resp },
    () => requestPermissionsList().then(() => this.backToPrevDisplay())
  );
}

export function handleSelectKey(encodedKey, signer, resetPrivateKey) {
  const { shareDocId, changedShare, itIsUpdate, manifest } = this.state;
  this.setState({ blockScreen: true });

  return promiseChain([
    () =>
      signer.execute("GetCertificate", encodedKey.issuer, encodedKey.serial),
    (certBuffer) => signer.execute("Base64Encode", certBuffer),
    (certBase64) => checkCertificate({ data: certBase64 }),
    async () => {
      let { signVersion, dataToSign } = manifest || {};

      if (Array.isArray(manifest)) {
        dataToSign = manifest;
        signVersion = 1;
      }

      const signManifestFunc = signManifestVersions[signVersion];
      if (!signManifestFunc) {
        throw new Error("Sign version unsupported");
      }
      return signManifestFunc(dataToSign, signer);
    },
    (signature) =>
      promiseChain([
        resetPrivateKey,
        () => signDocument(shareDocId, signature),
        (resp) => (checkError(resp) ? resp : commitDocument(shareDocId, {})),
        (resp) => {
          if (checkError(resp)) return resp;
          return itIsUpdate
            ? editPermission(changedShare.id, changedShare)
            : addPermission(changedShare);
        },
        (resp) => this.setPermission(resp),
      ]),
  ]).catch((e) => this.setPermission(e));
}

export function handleShareDelete() {
  const { sharesChecked } = this.state;

  return this.setState({ blockScreen: true }, () =>
    Promise.all(sharesChecked.map(deletePermission))
      .then((results) => {
        if (results.filter((result) => checkError(result)).length) {
          setTimeout(() => this.setState({ error: "" }), 3000);
        }
        return Promise.resolve();
      })
      .then(() => {
        this.setState({ sharesChecked: [], dialog: "" }, () =>
          requestPermissionsList().then(() =>
            this.setState({ blockScreen: false })
          )
        );
      })
  );
}

export function onCheckShare(id) {
  return () => {
    const { sharesChecked } = this.state;
    if (sharesChecked.includes(id)) {
      sharesChecked.splice(sharesChecked.indexOf(id), 1);
    } else {
      sharesChecked.push(id);
    }
    this.setState({ sharesChecked });
  };
}

export function handleShareClick(share) {
  return () => this.setState({ dialog: "viewOnly", changedShare: share });
}

export function expandedShare(share) {
  const { changedShare } = this.state;
  return () =>
    this.setState({
      changedShare: (share || {}).id === (changedShare || {}).id ? null : share,
      dialog: share.dialog,
    });
}

export function toggleDialog(dialog, load = false) {
  return () => {
    const close = dialog === this.state.dialog || !dialog;
    this.setState({ blockScreen: !this.state.dialog });
    if (close) {
      this.setState({ dialog: "", changedShare: null });
    } else {
      requestPermissionsList().then(() => {
        const { itemShareList } = this.props;
        let { changedShare } = this.state;
        if (dialog === "delete") {
          changedShare = null;
        } else if (Array.isArray(itemShareList) && changedShare) {
          const found =
            itemShareList.find(({ id }) => id === changedShare.id) || {};
          changedShare = { ...changedShare, ...found };
        }
        this.setState({ dialog, changedShare, blockScreen: false });
      });
    }
    if (load) {
      this.load();
    }
  };
}
