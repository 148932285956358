import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";

const Menu = ({ innerProps, selectProps, children }) => (
  <Paper square={true} className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

Menu.propTypes = {
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

Menu.defaultProps = {
  innerProps: {},
  children: "",
};

export default Menu;
