import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";
import setComponentsId from "../../helpers/setComponentsId";

import statsCardStyle from "../../variables/styles/statsCardStyle";

const StatsCard = ({
  classes,
  title,
  description,
  statLink,
  small,
  statText,
  statIconColor,
  iconColor,
  icon,
  statIcon,
  setId,
}) => (
  <Card className={classes.card} id={setId("")}>
    <CardHeader
      id={setId("header")}
      classes={{
        root: cx(classes.cardHeader, classes[`${iconColor}CardHeader`]),
        avatar: classes.cardAvatar,
      }}
      avatar={<icon className={classes.cardIcon} id={setId("header-icon")} />}
    />
    <CardContent className={classes.cardContent} id={setId("content")}>
      <Typography
        component="p"
        className={classes.cardCategory}
        id={setId("title")}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        className={classes.cardTitle}
        id={setId("description")}
      >
        {description}
        &nbsp;
        {small && (
          <small
            className={classes.cardTitleSmall}
            id={setId("subdescription")}
          >
            {small}
          </small>
        )}
      </Typography>
    </CardContent>
    <CardActions className={classes.cardActions} id={setId("actions")}>
      <div className={classes.cardStats} id={setId("stats")}>
        <statIcon
          className={cx(
            classes.cardStatsIcon,
            classes[`${statIconColor}CardStatsIcon`]
          )}
          id={setId("stats-icon")}
        />
        &nbsp;
        {statLink && (
          <a
            href={statLink.href}
            className={classes.cardStatsLink}
            id={setId("stats-link")}
          >
            {statLink.text}
          </a>
        )}
        {!statLink && statText && (
          <span id={setId("stats-text")}>{statText}</span>
        )}
      </div>
    </CardActions>
  </Card>
);

StatsCard.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.func.isRequired,
  iconColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
  title: PropTypes.node,
  description: PropTypes.node,
  small: PropTypes.node,
  statIcon: PropTypes.func.isRequired,
  statIconColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  statLink: PropTypes.object,
  statText: PropTypes.node,
};

StatsCard.defaultProps = {
  iconColor: "purple",
  statIconColor: "gray",
  description: "",
  title: "",
  small: "",
  statLink: null,
  statText: "",
  setId: setComponentsId("stats-card"),
};

export default withStyles(statsCardStyle)(StatsCard);
