import PropTypes from "prop-types";
import { DialogActions } from "@material-ui/core";
import cx from "classnames";

import { Button, BlockScreen } from "../../components";

const ShareDialogActions = ({
  classes,
  setId,
  onClose,
  toggleDialog,
  display,
  busy,
  t,
  duplicateShare,
  changePermission,
  error,
  foundUser,
  addPermission,
  showCloseButton,
  showSubmitButton,
  showChangeSubmitButton,
  isСhanged,
}) => (
  <DialogActions
    className={cx(
      classes.actions,
      classes.dialogContentWrappers,
      classes.mobilePadding
    )}
    id={setId("action")}
    title={busy ? t("LOADING") : ""}
  >
    <BlockScreen open={busy} transparentBackground={true} />
    <Button
      id={setId("cancel-button")}
      onClick={onClose(toggleDialog(display))}
      color="transparent"
      disabled={busy}
      setId={(elmentName) => setId(`cancel-${elmentName}`)}
    >
      {showCloseButton ? t("CLOSE") : t("CANCEL")}
    </Button>
    {showSubmitButton && !duplicateShare && (
      <Button
        onClick={addPermission}
        color="yellow"
        id={setId("add-button")}
        setId={(elmentName) => setId(`add-${elmentName}`)}
        disabled={busy || !!error || !foundUser}
      >
        {t("SUBMIT")}
      </Button>
    )}
    {showChangeSubmitButton &&
      !duplicateShare &&
      display !== "changePermission" && (
        <Button
          onClick={changePermission}
          color="yellow"
          id={setId("change-button")}
          setId={(elmentName) => setId(`change-${elmentName}`)}
          disabled={
            !isСhanged ||
            busy ||
            !!error ||
            (display === "addShare" && !foundUser)
          }
        >
          {t("CHANGE_SUBMIT")}
        </Button>
      )}
  </DialogActions>
);

ShareDialogActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  duplicateShare: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  changePermission: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  addPermission: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  showSubmitButton: PropTypes.bool.isRequired,
  showChangeSubmitButton: PropTypes.bool.isRequired,
  isСhanged: PropTypes.bool.isRequired,
  foundUser: PropTypes.object,
};

ShareDialogActions.defaultProps = {
  duplicateShare: null,
  foundUser: null,
};

export default ShareDialogActions;
