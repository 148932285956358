import { ReactElement } from "react";
import { FormContext, FormSchema } from "../FormFromSchema";
import { JsonSchema } from "../FormFromSchema/json-schema";
import { createStyles, WithStyles } from "@material-ui/core/styles";
import rawWizardStyles from "../../variables/styles/schemaWizard";

export const defaultT = (key: string, params?: any[]): string => key;

export interface WizardSchema {
  definitions?: {
    [key: string]: Partial<JsonSchema>;
  };
  getDataToSign?: string;
  getPreviewData?: string;
  getPreviewSchema?: string;
  onCreate?: string;
  onStepFinish?: string;
  onStepUpdate?: string;
  onFinish?: string;
  properties: {
    [key: string]: FormSchema;
  };
  required?: string[];
  stepOrders: string;
  templateType?: string;
  templateVersion?: string;
  title?: string;
  type: string;
}

export const EmptyWizardSchema: WizardSchema = {
  properties: {},
  stepOrders: "()=>[]",
  type: "object",
};

export interface WizardData {
  activeStep: string;
  [key: string]: any;
}

export enum WizardStatus {
  EDIT,
  SIGN,
  VIEW,
}

export enum SignMethod {
  HASH = 1,
  DATA = 2,
}

export interface SignData {
  signMethod: SignMethod;
  dataToSign: any | any[];
}

export const schemaWizardStyles = createStyles(rawWizardStyles);

export interface SchemaWizardProps
  extends WithStyles<typeof schemaWizardStyles> {
  context: any;
  finishButtonText?: string;
  errors?: Map<string, string>;
  //Callback before back to edit
  onBackToEdit?: () => Promise<void>;
  //Callback before wizard close
  onClose?: (wizardData: any) => Promise<void>;
  //Callback on data finalize
  onDataCommit?: (wizardData: any, signature?: any) => Promise<void>;
  //Callback after created new data
  onDataCreate?: (wizardData: any) => Promise<void>;
  //Callback after created new data
  onDataFinish?: (wizardData: any | any[]) => Promise<void>;
  //Callback after created new data
  onDataLoad?: (wizardData: any) => Promise<void>;
  //Callback on data commit error
  onErrorCommit?: (error: any) => Promise<void>;
  //Callback on sign error
  onErrorSign?: (error: any) => Promise<void>;
  //Callback on create or update form context
  onFormContext?: (context: FormContext) => Promise<FormContext>;
  //Callback on close wizard
  onGetDataToSign: (
    data: any,
    getDataFn?: (data: any) => any
  ) => Promise<SignData | any[]>;
  onGetSavedSign?: (dataToSign: any[]) => Promise<any[]>;
  onStepCheck?: (
    stepKey: string,
    values: any,
    errors?: Map<string, string>
  ) => Promise<void>;
  //Callback after data apdated
  onStepSave?: (
    stepKey: string,
    values: any | any[],
    activeStep?: string
  ) => Promise<void>;
  //Callback after data updated
  onStepUpdate?: (stepKey: string, stepData: any) => Promise<void>;
  returnTo?: string;
  returnToText?: string;
  setId: (s: string) => string;
  schema: WizardSchema;
  signMethod?: SignMethod;
  signPreview: ReactElement;
  t: (key: string, params?: any[]) => string;
  user: any;
  wizardData?: WizardData;
}

export interface SchemaWizardState {
  activeStepId: number;
  allSteps: { key: string; label: string }[];
  data: WizardData;
  dataSaving: boolean;
  formContext: any;
  nextStepId: number;
  noErrorCheck: boolean;
  stepData: any;
  stepSaveQueue: Set<string>;
  stepSchema: FormSchema;
}
