import { render } from "react-dom";

export function handlePrintHistory(reactComponent) {
  try {
    const frame = document.createElement("iframe");
    frame.style.display = "none";
    frame.title = "Картка руху документу";

    window.document.body.appendChild(frame);
    const _window = frame.contentWindow;

    _window.onload = () => {
      _window.document.title = "Картка руху документу";
      _window.document.body.appendChild(_window.document.createElement("div"));
      render(reactComponent, _window.document.body.children[0]);
      _window.print();
    };
    setTimeout(() => window.document.body.removeChild(frame), 1000);
  } catch (err) {
    console.log("print err: ", err);
  }
}
