import React, { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles, Snackbar } from "@material-ui/core";
import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import { IReturnObjectProps } from "../../components/ReturnAndLocateInTableHOC/types";
import { translate } from "../../helpers/translate";

import { BlockScreen, Button, RegularCard } from "../../components";
import Preloader from "../../components/Preloader";
import ReturnButton from "../../components/ReturnButton";
// import VideoManual from "../../components/videoManual/VideoManual";

import Wizard, { SignMethod, WizardData } from "../../components/SchemaWizard";
import RequestPreview from "./components/RequestPreview";
import ResponseView from "./components/ResponseView";

import actions, {
  StateRegister,
  StateRegisterRequest,
} from "../../actions/stateRegisters";
import { registerDataSource } from "../../reducers/stateRegisters";
import { isEmptyValue } from "../../components/FormFromSchema/helpers";

import styles from "../../variables/styles/StateRegisters";

interface RolePosition {
  id: string; //ід з довідника суддів
  orgId: string; //ід суду з довідника суддів
  position: string; //назва посади
}

export interface RegisterReqViewProps {
  classes: any;
  setId: (s: string) => string;
  t: (s: string, args?: Record<string, any>) => string;
  activeRegister?: StateRegister;
  dataSource: any;
  dictionary: any;
  display: string;
  history: any;
  list: any[];
  loading: boolean;
  match: any;
  registerList: StateRegister[];
  returnObject: IReturnObjectProps;
  roleInfo: {
    permissions?: string[];
    positions?: RolePosition[];
    role: string;
  };
  userInfo: any;
}

export interface RegisterReqViewState {
  context: {
    case?: any;
    court?: any;
  };
  activeRequest?: StateRegisterRequest;
  activeTemplate?: any;
  registerId?: number;
  blockScreen: boolean;
  error?: string;
}

interface CustomError extends Error {
  status: number;
}

class RegisterReqView extends React.Component<
  RegisterReqViewProps,
  RegisterReqViewState
> {
  static initialState: RegisterReqViewState = {
    context: {},
    activeRequest: undefined,
    activeTemplate: undefined,
    blockScreen: false,
    error: "",
    registerId: undefined,
  };

  constructor(props: RegisterReqViewProps) {
    super(props);
    this.state = RegisterReqView.initialState;
  }

  async componentDidMount() {
    const {
      history: {
        location: { search },
      },
      match: {
        params: { requestId },
      },
      registerList,
    } = this.props;

    if (registerList.length === 0) await registerDataSource.load();

    let registerId, activeRequest;
    if (requestId === "create") {
      registerId = parseInt(
        new URLSearchParams(search).get("registerId") || "-1"
      );
    } else {
      activeRequest = await actions.getRegisterReq(requestId);
      if (typeof activeRequest?.reqObject === "string")
        activeRequest.reqObject = JSON.parse(activeRequest.reqObject);
      registerId = activeRequest?.registerId;
    }
    this.setState({ activeRequest, registerId });
  }

  async componentWillUnmount() {}

  private get register() {
    return this.props.registerList.find(
      (item) => item.id === this.state.registerId
    );
  }

  private get pageTitle(): string {
    const reg = this.register;
    return reg ? `Запит до ${reg.code}: ${reg.name}` : "";
  }

  showError = (error: any) => {
    this.setState(
      { error: typeof error === "string" ? error : error.message },
      () => {
        setTimeout(this.hideError, 20_000);
      }
    );
  };

  hideError = () => {
    this.setState({ error: "" });
  };

  handleBackToEdit = async () => {
    let { activeRequest } = this.state;
    try {
      if (!activeRequest) throw new Error("Not active request");
      // activeRequest.statusId = 0;
      activeRequest = await actions.patchRegisterReq(activeRequest.id, {
        request: null,
        reqSign: null,
      });
      this.setState({ activeRequest });
    } catch (error) {
      this.showError(error);
    }
  };

  handleDataCommit = async (data: WizardData, signature: any) => {
    let { activeRequest } = this.state;
    if (!activeRequest) throw new Error("Not active request");
    activeRequest = await actions.patchRegisterReq(activeRequest.id, {
      reqObject: data,
      reqSign: Buffer.from(signature).toString("base64"),
      request: data.request,
    });
    this.setState({ activeRequest }, async () => await this.requestSend());
  };

  handleDataCreate = async (data: WizardData) => {
    console.log("Registers onDataCreate:", data);
  };

  handleDataFinish = async (data: WizardData) => {
    let { activeRequest } = this.state;
    if (!activeRequest) throw new Error("Not active request");

    if (activeRequest.registerId === 1) {
      if (data.userData?.birthDate) {
        data.request.date_birth = new Date(data.userData?.birthDate)
          .toLocaleDateString("uk-UA")
          .replaceAll(".", "");
      }

      if (data.userDocData) {
        const { docType, seriesDoc, numberDoc } = data.userDocData;
        if (docType || seriesDoc || numberDoc) {
          data.request.documents = [
            {
              type_doc: docType,
              series_doc: seriesDoc,
              number_doc: numberDoc,
            },
          ];
        }
      }
      await this.handleStepSave("request", data.request);
    }
  };

  handleGetDataToSign = async (
    wizardData: WizardData,
    getDataFn?: (data: any) => any
  ) => {
    const dataToSign = getDataFn
      ? getDataFn(wizardData)
      : JSON.stringify(wizardData?.request) || "";
    return {
      signMethod: SignMethod.DATA,
      dataToSign,
    };
  };

  requestSend = async () => {
    const { t } = this.props;
    let { activeRequest } = this.state;
    this.setState({ blockScreen: true }, async () => {
      try {
        if (!activeRequest) throw new Error("Not active request");
        activeRequest = await actions.sendRegisterReq(activeRequest.id);
        this.setState({ activeRequest, blockScreen: false });
      } catch (error) {
        if ((error as CustomError).status === 504) {
          this.showError(t("REQUEST_ERROR_504"));
        } else {
          this.showError(t("REQUEST_ERROR"));
        }
        this.setState({ blockScreen: false });
      }
    });
  };

  requestResult = async () => {
    const { t } = this.props;
    let { activeRequest } = this.state;
    this.setState({ blockScreen: true }, async () => {
      try {
        if (!activeRequest) throw new Error("Not active request");
        activeRequest = await actions.registerRequestResult(activeRequest.id);
        this.setState({ activeRequest, blockScreen: false });
        if (activeRequest.statusId === 2) {
          this.showError(t("GET_RESPONSE_ERROR"));
        }
      } catch (error) {
        if ((error as CustomError).status === 504) {
          this.showError(t("REQUEST_ERROR_504"));
        } else {
          this.showError(t("GET_RESPONSE_ERROR"));
        }
        this.setState({ blockScreen: false });
      }
    });
  };

  handleStepCheck = async (stepName: string, values: any, errors?: any) => {
    const { context } = this.state;
    let { activeRequest } = this.state;
    const {
      dictionary: { courts },
      roleInfo: { positions },
      t,
    } = this.props;

    if (stepName === "attributes") {
      errors = errors || new Map();
      if (errors.size > 0) return;

      try {
        const judgeCourts = courts.filter(
          (court: any) =>
            !!positions?.find((pos) => pos.orgId === court.id.toString())
        );

        let court = judgeCourts.find(
          (court: any) => court.opts?.stateRegisters?.noCheckCase
        );

        if (court) {
          context.case = {
            caseNumber: values.caseNumber,
            courtId: court.id,
          };
        } else {
          if (!context.case || context.case.number !== values.caseNumber)
            context.case = await actions.getCaseForRegisterReq(
              values.caseNumber
            );

          if (context.case) {
            court = judgeCourts.find((court: any) =>
              context.case.caseJudges.find(
                (judge: any) => court.id === judge.courtId
              )
            );
          }
        }

        if (court) {
          context.court = court;
        } else {
          errors.set("caseNumber", t("NO_ACCESS_TO_CASE"));
          return;
        }

        if (!activeRequest) {
          const { registerId } = this.state;
          if (!registerId) throw new Error("Не вибрано реєстр");
          activeRequest = await actions.createRegisterReq(registerId, {
            caseId: context.case.id,
            courtId: context.court.id,
          });
          this.setState({ activeRequest });
        }
      } catch (error) {
        errors.set("caseNumber", t("REQUEST_ERROR"));
      }
    }
    return;
  };

  handleFormContext = async (context: any) => {
    const { activeRequest } = this.state;
    context.activeRequest = activeRequest;
    if (!context.court || context.court.id !== activeRequest?.courtId) {
      context.court = this.props.dictionary.courts.find(
        (item: any) => item.id === activeRequest?.courtId
      );
    }
    return context;
  };

  handleStepSave = async (
    stepName: string,
    values: any,
    activeStep?: string
  ) => {
    let { activeRequest, context } = this.state;
    if (!activeRequest) throw new Error("Не вибрано запит");

    const reqObject: WizardData = {
      activeStep: activeStep || stepName,
    };
    reqObject[stepName] = values;

    Object.getOwnPropertyNames(reqObject[stepName]).forEach((propName) => {
      if (isEmptyValue(reqObject[stepName][propName])) {
        delete reqObject[stepName][propName];
      } else {
        let value = reqObject[stepName][propName];
        if (typeof value === "string") {
          reqObject[stepName][propName] = value.trim();
        }
      }
    });

    activeRequest = await actions.patchRegisterReq(activeRequest.id, {
      caseId: activeRequest.caseId || null,
      courtId: activeRequest.courtId,
      reqObject,
    });

    if (!context.court || context.court.id !== activeRequest.courtId) {
      context.court = this.props.dictionary.courts.find(
        (item: any) => item.id === activeRequest?.courtId
      );
    }

    this.setState({ activeRequest, context });
  };

  async loadTemplate() {
    if (this.register) {
      const activeTemplate = await actions.templateRequest(
        this.register.templateId
      );
      this.setState({ activeTemplate });
    }
  }

  render(): React.ReactNode {
    const { classes, loading, setId, t, userInfo } = this.props;
    const { activeRequest, activeTemplate, blockScreen, error, context } =
      this.state;

    const showPreview = [1, 2, 4].includes(activeRequest?.statusId || -1);
    const showResponse = [3].includes(activeRequest?.statusId || -1);
    const showWizard = !showPreview && !showResponse;

    let previewData, previewSchema;
    if (showPreview || showWizard) {
      if (!activeTemplate) {
        this.loadTemplate();
        return <Preloader />;
      }

      const schema = activeTemplate?.jsonSchema;
      const { getPreviewData, getPreviewSchema, properties } = schema;

      const data = activeRequest?.reqObject || {};

      previewData = getPreviewData
        ? // eslint-disable-next-line no-eval
          eval(getPreviewData)(data)
        : data.request;
      previewSchema = getPreviewSchema
        ? // eslint-disable-next-line no-eval
          eval(getPreviewSchema)(schema)
        : properties.request;
    }

    const preview = (showWizard || showPreview) && (
      <RequestPreview
        classes={classes}
        setId={setId}
        t={t}
        data={previewData}
        schema={previewSchema}
        onError={(error) => this.showError(error)}
      />
    );

    return (
      <RegularCard
        cardTitle={
          <div id={setId("title")}>
            <ReturnButton t={t} />
            <span>{this.pageTitle}</span>
            {/* <VideoManual /> */}
          </div>
        }
        headerColor="primary"
        setId={setId}
        content={
          <Fragment>
            {showWizard && (
              <Wizard
                context={{
                  ...context,
                  user: userInfo,
                }}
                // errors={wizardErrors}
                finishButtonText={t("CREATE_PREVIEW")}
                // onDataCommit={ }
                onDataCreate={this.handleDataCreate}
                onDataCommit={this.handleDataCommit}
                onDataFinish={this.handleDataFinish}
                // onDataLoad={this.handleDataLoad}
                onFormContext={this.handleFormContext}
                onGetDataToSign={this.handleGetDataToSign}
                onStepCheck={this.handleStepCheck}
                onStepSave={this.handleStepSave}
                // onClose={ }
                // returnTo={ }
                // returnToText={ }
                // setId={ }
                schema={activeTemplate.jsonSchema}
                // t={ }
                // signMethod={SignMethod.DATA}
                signPreview={preview}
                user={userInfo}
                wizardData={activeRequest?.reqObject}
              />
            )}
            {showPreview && preview}
            {showResponse && (
              <ResponseView
                classes={classes}
                docId={activeRequest?.docId}
                register={this.register}
                request={activeRequest}
                setId={setId}
                t={t}
              />
            )}
          </Fragment>
        }
        footer={
          showPreview && (
            <div style={{ justifyContent: "space-between" }}>
              {[1, 4].includes(activeRequest?.statusId || -1) && (
                <Button
                  color="transparent"
                  onClick={this.handleBackToEdit}
                  id={setId("remove-pdf-button")}
                  setId={(elmentName: string) =>
                    setId(`remove-pdf-${elmentName}`)
                  }
                >
                  {t("BACK_TO_EDIT")}
                </Button>
              )}
              {activeRequest?.statusId === 1 && (
                <Button color="yellow" onClick={this.requestSend}>
                  {t("REQUEST_SEND")}
                </Button>
              )}
              {activeRequest?.statusId === 2 && (
                <Button color="yellow" onClick={this.requestResult}>
                  {t("REQUEST_GET_RESPONSE")}
                </Button>
              )}
              {activeRequest?.statusId === 4 && (
                <Button color="yellow" onClick={this.requestSend}>
                  {t("REQUEST_RESEND")}
                </Button>
              )}
            </div>
          )
        }
      >
        {loading && <Preloader />}
        <BlockScreen open={blockScreen} />
        <Snackbar
          id={setId("error")}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={!!error}
          message={<span id="message-id">{error}</span>}
          action={[
            <Button
              key="close-error"
              color="yellow"
              size="small"
              onClick={this.hideError}
              className={classes.smallButton}
            >
              OK
            </Button>,
          ]}
        />
      </RegularCard>
    );
  }
}

function mapStateToProps({
  authorization: { info, roleInfo },
  dictionary,
  stateRegisters: {
    activeRegister,
    dataSource,
    display,
    list,
    loading,
    registerList,
  },
}: any) {
  return {
    activeRegister,
    dataSource,
    dictionary,
    display,
    list,
    loading,
    registerList,
    userInfo: info,
    roleInfo,
  };
}

export default translate("StateRegisters")(
  compose(
    connect(mapStateToProps),
    withRouter,
    ReturnAndLocateInTableHOC,
    withStyles(styles as any)
  )(RegisterReqView)
);
