import React from "react";
import PropTypes from "prop-types";
import RegisterChip from "./RegisterChip";

const CourtList = ({ value, ...rest }) => {
  const filter = (court) => value.includes(court.id);

  return (
    <RegisterChip
      value={value}
      filter={filter}
      arrayKey="courts"
      valueKey="court_code"
      title="COURT_NAME"
      {...rest}
    />
  );
};

CourtList.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CourtList.defaultProps = {
  value: [],
};

export default CourtList;
