import config from "../config";
import store from "../store";

export default (fieldName) => {
  const roles = config()[fieldName];
  const { authorization } = store.getState() || {};
  const {
    info: { courtIdUserScopes },
  } = authorization && authorization.info ? authorization : { info: {} };
  let show = !!roles;
  if (Array.isArray(roles) && Array.isArray(courtIdUserScopes)) {
    show = roles.filter((role) => courtIdUserScopes.includes(role)).length > 0;
  }
  return show;
};
