import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const JusticeKindFilterChip = ({ t, value, justiceKinds, ...rest }) => (
  <Chip
    label={`${t("JUSTICE_KIND")}: ${(justiceKinds || [])
      .filter((form) => value.includes(form.id))
      .map((form) => form.name)
      .join(", ")}`}
    {...rest}
  />
);

JusticeKindFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  justiceKinds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

JusticeKindFilterChip.defaultProps = {
  value: "",
  justiceKinds: [],
};

const translated = translate("SearchPage")(JusticeKindFilterChip);
export default connect(({ dictionary: { justiceKinds } }) => ({
  justiceKinds,
}))(translated);
