import * as api from "../services/api";
import store from "../store";
import { routerActions } from "react-router-redux";

import config from "../config";
import storage from "../helpers/storage";

import promiseChain from "../helpers/promiseChain";

const { dispatch } = store;

const SET_DATA_SOURCE = "SEARCH/SET_DATA_SOURCE";
const REQUEST_REGISTRY_DOCUMENT = "SEARCH/REQUEST_REGISTRY_DOCUMENT";
const REQUEST_REGISTRY_DOCUMENT_INFO = "SEARCH/REQUEST_REGISTRY_DOCUMENT_INFO";

const { application } = config();

const BASE_API_URL =
  application.name === "cabinet" ? "proxy/registry/api" : "api";

const DOCUMENTS_URL = `${BASE_API_URL}/file`;
const REGIONS_URL = `${BASE_API_URL}/regions`;
const COURTS_URL = `${BASE_API_URL}/courts`;
const JUDGEMENT_FORMS_URL = `${BASE_API_URL}/judgment_forms`;
const JUSTICE_KINDS_URL = `${BASE_API_URL}/justice_kinds`;
const CAUSE_CATEGORIES_URL = `${BASE_API_URL}/cause_categories`;

const REQUEST_REGIONS = "SEARCH/REQUEST_REGIONS";
const REQUEST_COURTS = "SEARCH/REQUEST_COURTS";
const REQUEST_JUDGEMENT_FORMS = "SEARCH/REQUEST_JUDGEMENT_FORMS";
const REQUEST_JUSTICE_KINDS = "SEARCH/REQUEST_JUSTICE_KINDS";
const REQUEST_CAUSE_CATEGORIES = "SEARCH/REQUEST_CAUSE_CATEGORIES";

export function setDataSource(dataSource) {
  return dispatch({ type: SET_DATA_SOURCE, payload: dataSource });
}

export function loadDocument(documentId) {
  return api.get(
    `${DOCUMENTS_URL}/${documentId}`,
    REQUEST_REGISTRY_DOCUMENT,
    dispatch
  );
}

export function loadDocumentInfo(documentId) {
  return api.get(
    `${BASE_API_URL}/search?filter%5Bcase_num%5D=&filter%5Bdoc_id%5D=${documentId}`,
    REQUEST_REGISTRY_DOCUMENT_INFO,
    dispatch
  );
}

export function requestRegions() {
  return api.get(REGIONS_URL, REQUEST_REGIONS, dispatch);
}

export function requestCourts() {
  return api.get(COURTS_URL, REQUEST_COURTS, dispatch);
}

export function requestJudgmentForms() {
  return api.get(JUDGEMENT_FORMS_URL, REQUEST_JUDGEMENT_FORMS, dispatch);
}

export function requestJusticeKinds() {
  return api.get(JUSTICE_KINDS_URL, REQUEST_JUSTICE_KINDS, dispatch);
}

export function requestCauseCategories() {
  return api.get(CAUSE_CATEGORIES_URL, REQUEST_CAUSE_CATEGORIES, dispatch);
}

export const checkRole = () => {
  const role = storage.getItem("role");
  const token = storage.getItem("token");
  if (token && role && role !== "judge") {
    dispatch(routerActions.replace("/"));
    return false;
  }
  return true;
};

export const requestDictionary = () =>
  promiseChain([
    requestRegions,
    requestCourts,
    requestJudgmentForms,
    requestJusticeKinds,
    requestCauseCategories,
  ]);
