import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import cx from "classnames";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Button } from "../../components";
import setComponentsId from "../../helpers/setComponentsId";
import styles from "../../variables/styles/attachesWizardStep";
import { testFileName, normalizeFileName } from "../../helpers/testFile";
import UploadMultipleFiles from "./UploadMultipleFiles";

const DEFAULT_ACCEPT = `image/*,audio/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/x-zip-compressed,application/zip`;

const UploadMultipleFilesDialog = ({
  classes,
  t,
  setId,
  onSave,
  maxSize,
  accept,
  noDefaultAccept,
  onClose,
  onFilesAdded,
  onRenameFile,
  onDelete,
  open,
  files,
}) => {
  // console.log("UploadMultipleFilesDialog accept: ", accept);
  const [error, setError] = useState("");

  const onDrop = useCallback((addedFiles, rejected) => {
    if (!addedFiles.length) {
      return setError(t("ERROR_TYPE_FILE"));
    }
    let error = "";
    const accepted = [];

    addedFiles.forEach((file) => {
      // console.log("addedFiles file: ", file);
      const localFileName = normalizeFileName(file.name);
      // console.log("addedFiles localFileName: ", localFileName);
      error = testFileName(localFileName, t) || "";
      if (error) {
        console.log(
          `UploadMultipleFilesDialog, error ${error} of filename: ${localFileName}`
        );
      }
      if (!error) {
        const myNewFile = new File([file], localFileName, {
          type: file.type,
        });
        accepted.push(myNewFile);
      }
    });

    rejected.forEach((file) => {
      const localFileName = normalizeFileName(file.name);
      // console.log("localFileName file: ", file);
      // console.log("rejected localFileName: ", localFileName);
      error = testFileName(localFileName, t) || "";
      if (error) {
        console.log(
          `UploadMultipleFilesDialog, error ${error} of filename: ${localFileName}`
        );
      }
    });

    onFilesAdded(accepted);
    setError(error);
  }, []);

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={() => {
        onClose();
        setError("");
      }}
      aria-labelledby={setId("dialog-title")}
      id={setId("")}
      className={classes.dialog}
    >
      <DialogTitle
        id={setId("dialog-title")}
        className={classes.dialogContentWrappers}
      >
        {t("UPLOAD_FILES")}
      </DialogTitle>
      <UploadMultipleFiles
        files={files}
        onDeleteFile={onDelete}
        onRenameFile={onRenameFile}
        onDrop={onDrop}
        maxSize={maxSize}
        accept={noDefaultAccept ? accept : DEFAULT_ACCEPT}
        error={error}
        setId={(elementName) => setId(`-content-${elementName}`)}
      />
      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
        id={setId("actions")}
      >
        <Button
          disabled={!files.length || !!error}
          onClick={() => onSave()}
          color="yellow"
          id={setId("save-button")}
          setId={(elementName) => setId(`save-${elementName}`)}
        >
          {t("SAVE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadMultipleFilesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  noDefaultAccept: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
  onRenameFile: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
};

UploadMultipleFilesDialog.defaultProps = {
  setId: setComponentsId("upload-files-dialog"),
};

const styled = withStyles(styles)(UploadMultipleFilesDialog);
export default translate("Elements")(styled);
