/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

const boxShadow =
  "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)";

export default {
  gridItem: {
    "& > div": {
      border: "#aaa 1px solid",
    },
  },
  deleteAttachBtn: {
    color: "#ffffff",
  },
  title: {
    paddingTop: 7,
  },
  previewWrap: {
    boxShadow,
    background: "#aaa",
    borderRadius: 4,
  },
  errorWrapper: {
    color: "#ed2900",
    background: "#fff",
    borderRadius: 4,
    minWidth: "500px",
    maxWidth: "100%",
    "@media (max-width:575px)": {
      minWidth: "250px",
    },
  },
  previewScrollBox: {
    padding: "10px 27px 0",
    overflow: "auto",
  },
  previewBox: {
    width: "calc(100% - 7px)",
    maxWidth: 500,
    height: 604,
    margin: "0 auto",
  },
  previewFrame: {
    width: "100%",
    height: 584,
    background: "#fff",
    boxShadow,
  },
  previewTextFrame: {
    width: "100%",
    minHeight: 584,
    background: "#fff",
    boxShadow,
  },
  previewActions: {
    backgroundColor: "rgb(50, 50, 50)",
    padding: "0 27px",
  },
  previewText: {
    padding: 15,
    whiteSpace: "pre-wrap",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  mediaBox: {
    width: "600px",
    maxWidth: "100%",
  },
  videoFrame: {
    width: 552,
    height: 264,
    maxWidth: "100%",
  },
  htmlWrap: {
    boxShadow,
    background: "#aaa",
    borderRadius: 4,
    width: "100%",
  },
  htmlScrollBox: {
    padding: "10px 3px 0",
    // overflow: "auto",
    width: "calc(100% - 7px)",
  },
  htmlBox: {
    width: "100%",
    height: 604,
    margin: "0 auto",
  },
  htmlFrame: {
    width: "calc(100% - 3px)",
    // height: 600,
    height: "100%",
    background: "#fff",
    boxShadow,
  },
  htmlActions: {
    backgroundColor: "rgb(50, 50, 50)",
  },
};
