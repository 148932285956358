import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { translate } from "react-translate";
import { connect } from "react-redux";
import { withStyles, Tooltip, Chip } from "@material-ui/core";
import cx from "classnames";
import styles from "../../../variables/styles/executiveStatusChip";

const StatusChip = ({
  catState,
  status,
  classes,
  tableChip,
  statusText,
  statusName,
  statusColor,
  t,
  setId,
  documentStateInfo,
  statuses,
  catStatuses,
  placement,
  style: statusStyle,
}) => {
  const styleStatus = catState > 0 ? catState : status;
  // if (!styleStatus) {
  //   return null;
  // }
  const currentStatuses = catStatuses || statuses;
  const chipClasses = cx(
    classes.chip,
    classes[styleStatus],
    tableChip && classes.tableChip
  );
  const style = statusColor
    ? { ...statusStyle, background: statusColor }
    : // : classes[styleStatus]
      // ? {}
      { color: "#000", background: "#e0e0e0" };
  const chipLabel = statusName || statusText || t(currentStatuses[status]);

  let title = chipLabel;
  if (documentStateInfo) {
    title = (
      <span>
        {chipLabel}
        <br />
      </span>
    );
  }

  return (
    <Tooltip
      title={title}
      PopperProps={{ className: classes.tooltip }}
      placement={placement}
    >
      <Chip
        label={chipLabel}
        className={chipClasses}
        id={setId("chip")}
        style={style}
      />
    </Tooltip>
  );
};

StatusChip.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tableChip: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  statuses: PropTypes.object,
  catStatuses: PropTypes.object,
  catState: PropTypes.number,
  statusText: PropTypes.string,
  placement: PropTypes.string,
  documentStateInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

StatusChip.defaultProps = {
  documentStateInfo: null,
  statuses: null,
  catStatuses: null,
  setId: setComponentsId("claim-status"),
  statusText: "",
  catState: 0,
  placement: "bottom",
};

const styled = withStyles(styles)(StatusChip);
const translated = translate("ExecutiveDocs")(styled);

function mapStateToProps(state) {
  return state.claim;
}

// decorate and export
export default connect(mapStateToProps)(translated);
