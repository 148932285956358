import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";

const MultiValue = ({ removeProps, selectProps, children, isFocused }) => (
  <div
    className={selectProps.classes.chipWrap}
    title={typeof children === "string" ? children : ""}
  >
    <Chip
      tabIndex={-1}
      label={children}
      className={cx(
        selectProps.classes.chip,
        isFocused && selectProps.classes.chipFocused
      )}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  </div>
);

MultiValue.propTypes = {
  removeProps: PropTypes.object,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

MultiValue.defaultProps = {
  removeProps: {},
  isFocused: false,
  children: "",
};

export default MultiValue;
