import React from "react";
import PropTypes from "prop-types";
import RegisterChip from "./RegisterChip";

const CauseCategoryList = ({ value, ...rest }) => {
  const filter = (category) => value.includes(category.csc_id);

  return (
    <RegisterChip
      value={value}
      filter={filter}
      arrayKey="causeCategories"
      valueKey="cause_category"
      title="CATEGORIES_FILTER_GROUP"
      {...rest}
    />
  );
};

CauseCategoryList.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CauseCategoryList.defaultProps = {
  value: [],
};

export default CauseCategoryList;
