import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CourtListFilterChip = ({ t, value, courts, ...rest }) => (
  <Chip
    label={`${t("CASE_COURT")}: ${(courts || [])
      .filter(({ id }) => value.includes(id))
      .map((court) => court.name)
      .join(", ")}`}
    {...rest}
  />
);

CourtListFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CourtListFilterChip.defaultProps = {
  value: "",
  courts: [],
};

const translated = translate("ClaimList")(CourtListFilterChip);
export default connect(({ dictionary: { courts } }) => ({ courts }))(
  translated
);
