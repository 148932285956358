import { Link } from "react-router-dom";
import humanDateFormat from "../../../helpers/humanDateFormat";
import { EXECUTIVE_DOCS_URL } from "../../../variables/wikiUrls";
import PageCardTitle from "../../../components/PageCardTitle";

export const ExecutiveCardTitle = ({ classes, setId, activeExecutive }) => {
  const date = humanDateFormat(activeExecutive?.docDate);
  const caseNumber = activeExecutive?.caseNumber;
  const caseId = activeExecutive?.caseId;
  const docNumber = activeExecutive?.docNumber;
  const docDescr = activeExecutive?.docDescr;
  const procNumber = activeExecutive?.procNumber;
  const proceedingId = activeExecutive?.proceedingId;

  const getDocNumber = () => {
    return (
      <>
        № ${docNumber}
        &#160;
      </>
    );
  };
  const getDate = () => {
    return <>від {date}&#160;</>;
  };
  const getCaseLink = () => {
    return (
      <>
        по справі&#160;
        <Link className={classes.underline} to={`/cases/case=${caseId}`}>
          {caseNumber}
        </Link>
        ,&#160;
      </>
    );
  };

  const getProceedingLink = () => {
    return (
      <span>
        провадження&#160;
        <Link
          className={classes.underline}
          to={`/cases/case=${caseId}/proceeding=${proceedingId}`}
        >
          {procNumber}
        </Link>
        &#160;
      </span>
    );
  };

  return (
    <PageCardTitle
      noBackArrow
      text={
        <div className={classes.linksTitle}>
          {docDescr} {docNumber ? getDocNumber() : ""}
          {date ? getDate() : ""}
          {caseId && caseNumber ? getCaseLink() : ""}
          {proceedingId && procNumber ? getProceedingLink() : ""}
        </div>
      }
      stepName={"pageDocumentView"}
      setId={setId}
      placement="right"
      noSpace={true}
      wikiUrl={EXECUTIVE_DOCS_URL}
    />
  );
};
