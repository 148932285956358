import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";

import Chip from "@material-ui/core/Chip";

const Status = ({ classes, status: { name, id }, setId, tableChip }) => (
  <Chip
    label={name}
    className={cx(classes.chip, classes[id], tableChip && classes.tableChip)}
    title={name}
    id={setId(`status-chip-${id}`)}
  />
);

Status.propTypes = {
  setId: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  tableChip: PropTypes.bool,
};

Status.defaultProps = {
  tableChip: false,
};

const styles = {
  "-1": {
    background: "#f44336",
  },
  1: {
    background: "#F0AD4E",
  },
  2: {
    background: "#F0AD4E",
  },
  3: {
    background: "#3b8300",
  },
  4: {
    background: "#F0AD4E",
  },
  5: {
    background: "#F0AD4E",
  },
  6: {
    background: "#3b8300",
  },
  7: {
    background: "#3b8300",
  },
  8: {
    background: "#3b8300",
  },
  9: {
    background: "#0059aa",
  },
  10: {
    background: "#5CB85C",
  },
  11: {
    background: "#F0AD4E",
  },
  12: {
    background: "#5CB85C",
  },
  13: {
    background: "#0059aa",
  },
  14: {
    background: "#5CB85C",
  },
  15: {
    background: "#3b8300",
  },
  16: {
    background: "#3b8300",
  },
  17: {
    background: "#3b8300",
  },
  18: {
    background: "#3b8300",
  },
  19: {
    background: "#f44336",
  },
  20: {
    background: "#F0AD4E",
  },
  21: {
    background: "#5CB85C",
  },
  22: {
    background: "#0059aa",
  },
  23: {
    background: "#3b8300",
  },
  24: {
    background: "#3b8300",
  },
  25: {
    background: "#5CB85C",
  },
  26: {
    background: "#3b8300",
  },
  27: {
    background: "#5CB85C",
  },
  28: {
    background: "#3b8300",
  },
  29: {
    background: "#5CB85C",
  },
  30: {
    background: "#3b8300",
  },
  31: {
    background: "#3b8300",
  },
  32: {
    background: "#5CB85C",
  },
  33: {
    background: "#5CB85C",
  },
  chip: {
    margin: "0 8px",
    height: 24,
    color: "#fff",
    cursor: "pointer",
  },

  tableChip: {
    justifyContent: "flex-start",
    maxWidth: "255px",

    "@media (max-width:1500px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "calc(100% - 8px)",
    },

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100%",
      margin: 0,
    },

    "& > span": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
    },
  },
};

export default withStyles(styles)(Status);
