import customInputStyle from "../../variables/styles/customInputStyle";
import attachesWizardStep from "../../variables/styles/attachesWizardStep";

export default {
  ...customInputStyle,
  ...attachesWizardStep,
  hint: {
    marginBottom: 8,
    color: "rgba(0, 0, 0, 0.8)",
    lineHeight: "1.5em",
  },
  link: {
    color: "#1b69b6",
    cursor: "pointer",
  },
  quill: {
    "& .ql-container": {
      background: "#aaaaaa",
      padding: "20px 0",
    },
    "& .ql-editor": {
      minHeight: 900,
      maxWidth: 900,
      margin: "auto",
      background: "#ffffff",
      boxShadow: "2px 2px 12px rgba(0,0,0,0.5)",
    },
  },
};
