import { setWizardStates } from "../../../actions/claim";
import { routerActions } from "react-router-redux";
import getWizardProps from "../../../helpers/getWizardProps";
import validateStep from "./validateStep";

export function goToStep(step) {
  const {
    rootComponent,
    claim: { id },
    dispatch,
  } = getWizardProps();
  dispatch(routerActions.replace(`/${rootComponent}/${id}/${step}`));
}

export function handleNext() {
  const { activeStepId, stepOrders } = getWizardProps();

  const errors = validateStep(activeStepId);
  if (!Object.keys(errors).length) {
    setWizardStates({ maxStep: activeStepId + 1, errors });
    goToStep(stepOrders[activeStepId + 1]);
  } else {
    setWizardStates({ errors });
  }
}

export function handleBack() {
  const { activeStepId, stepOrders } = getWizardProps();
  setWizardStates({ errors: null });

  goToStep(stepOrders[activeStepId - 1]);
}

export function handleStep(step, index) {
  return () => {
    const { activeStepId } = getWizardProps();
    const errors = index > activeStepId ? validateStep(activeStepId) : {};
    if (Object.keys(errors).length > 0) {
      setWizardStates({ errors });
    } else {
      goToStep(step);
    }
  };
}
