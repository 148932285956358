/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

// import {warningColor} from 'variables/styles.jsx';

export default {
  heading: {
    wordWrap: "break-word",
  },
  removeFileBtn: {
    float: "right",
    marginRight: -20,
    marginTop: -20,
  },
  flex: {
    flex: 1,
  },
  card: {
    marginTop: 20,
  },
  cardContent: {
    padding: 24,
  },
  dropZone: {
    width: "calc(100% - 4px)",
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.54)",
    borderStyle: "dashed",
    borderRadius: 5,
    marginTop: 20,
    padding: "8px 0",
    textAlign: "center",
  },
  dropZoneActive: {
    background: "#cdd7e3",
  },
  sample: {
    width: "100%",
    maxWidth: "calc(100% - 30px)",
    marginBottom: 0,
    marginTop: 20,
  },
  fileImage: {
    fontSize: 64,
    margin: "34px auto",
    display: "block",
  },
  gridItem: {
    "& > div": {
      border: "#aaa 1px solid",
    },
  },
  content: {
    padding: "0 24px",
    "& > div": {
      margin: 0,
    },
  },
  actions: {
    margin: "0 24px 8px",
  },
  errorToolTip: {
    maxWidth: 400,
    // color: warningColor,
    fontSize: 16,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: 165,
      fontSize: "14px",
      maxHeight: 280,
      overflow: "auto",
    },
  },
};
