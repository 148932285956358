import { useState } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import { HelpOutline } from "@material-ui/icons";
import { translate } from "react-translate";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import styles from "../../variables/styles/videoManual";

const VideoManual = ({ t, classes, setId, title, videoUrl, wikiUrl }) => {
  const [openVideo, setOpenVideo] = useState(false);

  const toggleVideoDialog = () => setOpenVideo(!openVideo);

  const renderBody = () => {
    return (
      <div
        className={classes.videoLink}
        onClick={toggleVideoDialog}
        id={setId("video-link")}
      >
        <Icon className={classes.videoIcon}>videocam</Icon>
        <span className={classes.videoLinkText}>&nbsp; {t("VIDEO_LINK")}</span>
        <Dialog
          open={openVideo}
          aria-labelledby={setId("video-dialog-title")}
          aria-describedby={setId("video-dialog-content")}
          onClose={toggleVideoDialog}
          id={setId("video-dialog")}
          className={classes.dialog}
          fullWidth={true}
        >
          <DialogTitle
            id={setId("video-dialog-title")}
            className={classes.dialogContentWrappers}
          >
            {t("VIDEO_LINK")}
          </DialogTitle>
          <DialogContent
            id={setId("video-dialog-content")}
            className={classes.dialogContentWrappers}
          >
            {videoUrl && (
              <iframe
                id={setId("video-dialog-iframe")}
                className={classes.videoFrame}
                title="video"
                src={`https://www.youtube.com/embed/${videoUrl}`}
                frameBorder="0"
                allowFullScreen={true}
              />
            )}
            {!videoUrl && "Інструкція розробляється."}
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  if (title) {
    return (
      <>
        <div id={setId("wideo-wrap")} className={classes.flexWrap}>
          <span id={setId("title")}>{title}</span>
          {videoUrl ? renderBody() : ""}
          {wikiUrl && (
            <Icon
              color="inherit"
              className={classes.wikiIcon}
              onClick={() => wikiUrl && window.open(wikiUrl)}
            >
              <HelpOutline />
            </Icon>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {videoUrl && renderBody()}
      {wikiUrl && (
        <Icon
          color="inherit"
          className={classes.wikiIcon}
          onClick={() => wikiUrl && window.open(wikiUrl)}
        >
          <HelpOutline />
        </Icon>
      )}
    </>
  );
};

VideoManual.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  videoUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

VideoManual.defaultProps = {
  videoUrl: "",
  setId: setComponentsId("video-manual"),
  title: "",
};

const styled = withStyles(styles)(VideoManual);
const translated = translate("VideoManual")(styled);

export default translated;
