import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import React from "react";

import CustomDatePicker from "../../../components/CustomInput/CustomDatePicker";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import AdvocateControl from "../InterfaceLayout/AdvocateControl";
import InputMask from "react-input-mask";

const Masked = (props) => (
  <InputMask {...props} maskChar="" inputRef={props.ref} />
);

export const RenderControl =
  ({
    t,
    classes,
    setId,
    handleChangePhone,
    handleChangeDate,
    handleChange,
    checkboxChange,
    values,
    readOnly,
    personalData,
    handleInterfaceChanged,
  }) =>
  ({
    key,
    name,
    Component,
    changed,
    label,
    mask,
    maxDate,
    disabled,
    helperText,
    relatedField,
    notForIE,
    maxLength,
    capitalizedFirstLeter,
  }) => {
    if (key === "isIndividualEntrepreneur") {
      return (
        <FormControlLabel
          key={key}
          control={
            <Checkbox
              checked={values[key]}
              onChange={checkboxChange}
              name={key}
              disabled={disabled || readOnly}
              color="primary"
            />
          }
          label={t(label)}
        />
      );
    }

    if (key === "isAdvocate") {
      return (
        <AdvocateControl
          advocate={personalData.advocate}
          isAdvocate={personalData.isAdvocate}
          handleInterfaceChanged={handleInterfaceChanged}
          firstName={values["first_name"]}
          lastName={values["last_name"]}
          middleName={values["middle_name"]}
          t={t}
        />
      );
    }

    if (
      disabled &&
      (!values[key] ||
        (relatedField && !values[relatedField]) ||
        (notForIE && values.isIndividualEntrepreneur))
    ) {
      return null;
    }

    const value =
      capitalizedFirstLeter && values[key]
        ? capitalizeFirstLetter(values[key])
        : values[key] || "";

    const changeFun = (() => {
      switch (true) {
        case changed === "phone" && !disabled:
          return handleChangePhone;
        case changed === "date" && !disabled:
          return handleChangeDate(key);
        case changed === "checkbox" && !disabled:
          return checkboxChange;
        default:
          return handleChange;
      }
    })();

    return (
      <FormControl
        fullWidth={true}
        className={classes.formControl}
        margin="dense"
        id={setId(`${name || key}-wrap`)}
        key={key}
      >
        {Component && changed !== "date" && (
          <Component
            value={value}
            onChange={changeFun}
            keyName={key}
            setId={setId}
            t={t}
            label={label}
            disabled={disabled || readOnly}
            isLegal={values["isLegal"]}
          />
        )}
        {changed === "date" && (
          <CustomDatePicker
            label={t(label)}
            margin="dense"
            incomingFormat="YYYY-MM-DDTHH:mm:ssZ"
            onChange={changeFun}
            date={value}
            id={setId(key)}
            // minDate="01/01/1900"
            maxDate={maxDate}
            disabled={disabled || readOnly}
          />
        )}
        {!changed && (
          <TextField
            disabled={disabled || readOnly}
            name={name || key}
            label={t(label)}
            value={value}
            onChange={disabled || readOnly ? undefined : changeFun}
            margin="dense"
            id={setId(name || key)}
            helperText={helperText ? t(helperText) : ""}
            // inputProps={{
            //   mask,
            //   maxLength
            // }}
            // InputProps={{
            //   inputComponent: Masked
            // }}
            inputProps={{
              mask,
              maxLength,
              inputComponent: Masked,
            }}
          />
        )}
      </FormControl>
    );
  };
