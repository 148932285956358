import React from "react";
import PropTypes from "prop-types";
import { Switch, FormControlLabel } from "@material-ui/core";

const Toggle = ({ t, classes, useBenefits, setId, toggleMode }) => (
  <FormControlLabel
    id={setId("")}
    label={t("HAVE_BENEFITS", {
      link: (
        <a
          href="http://zakon2.rada.gov.ua/laws/show/3674-17"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          id={setId("link")}
        >
          {t("HAVE_BENEFITS_LINK")}
        </a>
      ),
    })}
    control={
      <Switch checked={useBenefits} onChange={toggleMode} color="primary" />
    }
  />
);

Toggle.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  useBenefits: PropTypes.bool.isRequired,
  setId: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired,
};

export default Toggle;
