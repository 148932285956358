const currentFields = [
  "userDefinedTotalSum",
  "benefits",
  "useBenefits",
  "amountToPay",
  "errors",
];

export default function (newValue, itsFirstUpdate = false) {
  this.setState({ form: null });
  const { useBenefits, newSum, otherAmount } = this.state;
  const {
    onChange,
    actions: { setBusy, updatePaymentInfo },
  } = this.props;
  setBusy(true);
  const { benefits = {} } = newValue;
  const { confirmationFiles = [], type } = benefits;
  let error = "";
  if (useBenefits && !confirmationFiles.length) {
    error = "NO_SCAN_ERROR";
  } else if (useBenefits && !type) {
    error = "NO_PRIVELEGE_ERROR";
  } else if (
    (newSum === "0," || +newSum === 0) &&
    !useBenefits &&
    otherAmount
  ) {
    error = "NO_EMPTY_SUMM";
  }
  newValue.errors = error ? { error } : undefined;
  this.setState({ error });
  const clearValue = currentFields.reduce((acc, key) => {
    if (newValue[key] !== undefined || key === "errors") {
      acc[key] = newValue[key];
    }
    return acc;
  }, {});
  const changeAction = !useBenefits && !error ? updatePaymentInfo : onChange;
  changeAction(clearValue).then(() => {
    if (error !== this.state.error) {
      const { value } = this.props;
      return this.onChange(value);
    } else if (error) {
      return this.setState({ busy: false }, () => setBusy(false));
    }
    setBusy(false);
    return this.updatePaymentForm(false, itsFirstUpdate);
  });
}
