export const CASE_WIKI_URL =
  "https://wiki-ccs.court.gov.ua/w/Перегляд_матеріалів_справи_в_Електронному_суді";

export const CLAIM_WIKI_URL =
  "https://wiki-ccs.court.gov.ua/w/Як_створити_та_подати_заяву_через_Електронний_суд";

export const INPUT_DOCS_URL =
  "https://wiki-ccs.court.gov.ua/w/Send_doc_to_participants";

export const EXECUTIVE_DOCS_URL =
  "https://wiki-ccs.court.gov.ua/w/Виконавчі_документи";

export const PERMISSIONS_URL =
  "https://wiki-ccs.court.gov.ua/w/Документи_виконавчого_провадження";

export const CHECK_CABINET_URL =
  "https://wiki-ccs.court.gov.ua/w/Перевірка_наявності_кабінету_користувача";
