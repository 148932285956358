import { MapSchema, MapAtributeSchema } from "../json-schema";
import { ControlSchema, FormSchema, FormContext } from "../types";
import { Formatter } from "../json-schema";
import { validateDataToSheme } from "./validateDataToSheme";

export class SchemaDataBuilder {
  private result?: any;

  constructor(
    private readonly schema: FormSchema,
    formData: any | undefined | null,
    private context: FormContext
  ) {
    this.result = formData;
  }

  protected setDefault(key: string, schema: ControlSchema) {
    const { format } = schema;
    // if (schema.jsFunction) {
    //   const self: any = this;
    //   const fn = self[schema.jsFunction];
    //   defaultValue = fn(defaultValue, schema);
    // }
    let value: any;
    // if (format === "function") {
    if (format === "function" && typeof this.result[key] !== "function") {
      // if (!schema.value) return null;
      const fnStr = schema.value
        .replace("(", "(self")
        .replace(",)", ")")
        .replace(/this/g, "self");
      // eslint-disable-next-line no-eval
      value = eval(fnStr);
    } else {
      value = this.result[key];
      value =
        ["undefined", "null"].includes(typeof value) || value === ""
          ? schema.default
          : value;
      if (typeof value === "string" && value.startsWith("()=>")) {
        // eslint-disable-next-line no-eval
        value = eval(value)();
      } else if (schema.type === "string" && !schema.format)
        value = Formatter.formatValue(value, schema);
    }
    this.result[key] = value;
  }

  protected getDataFromRef(map: MapSchema): any {
    if (!map.$ref || !map.$ref.startsWith("#/")) return null;

    let data: any;
    const keys = map.$ref.replace("#/", "").split("/");
    keys.forEach((key, index) => {
      data = index === 0 ? this.context[key] : data[key];
    });

    if (map.$filter) {
      const params = map.$filter.split("&").map((value) => value.split("="));

      if (params.length > 0 && Array.isArray(data)) {
        data = data.filter((item) => {
          let isFound = true;
          params.forEach((p) => {
            // Key in arrray item for check
            const itemKey = p[0];
            // Path to filter value
            const pathParts = p[1].split("/");
            let filterValue: any;
            switch (pathParts[0]) {
              // if starts with "./" then get value from current form data
              case ".":
                filterValue = this.result;
                break;
              // if starts with "#/" then get value from context
              case "#":
                filterValue = this.context;
                break;
              // if starts with "/" then get value from context properties
              case "":
                filterValue = this.context.properties;
                break;
              // else get direct value
              default:
                filterValue = pathParts[0];
            }

            if (filterValue !== pathParts[0]) {
              pathParts.slice(1).forEach((key) => {
                filterValue = filterValue && filterValue[key];
              });
            }
            if (item[itemKey]?.toString() !== filterValue?.toString())
              isFound = false;
          });
          return isFound;
        });
      }
    }
    if (this.schema.type === "array") {
      data = Array.isArray(data) ? data : [data];
    } else {
      data = Array.isArray(data) ? data[0] : data;
    }

    return data;
  }

  protected getValueFromMap(
    valueMap: MapAtributeSchema,
    data: any,
    targetSchema: any
  ): any {
    if (typeof valueMap === "object" && valueMap.$ref) {
      let ref: string = valueMap.$ref;
      let value: any;
      let params = "";
      //Get value from data attributes
      if (ref.startsWith("/")) {
        const partsAndParams = ref.substring(1).split("?");
        const refParts = partsAndParams[0].split("/");
        params = partsAndParams[1];
        value = data || {};
        refParts.forEach((key) => {
          value = (value || {})[key];
        });
      }
      if (typeof value === "function") {
        const fnParams: any[] = [];
        (params?.split("&") || []).forEach((item) => {
          const entry = item.split("=");
          if (entry[0]) {
            const paramValue =
              entry[1] && entry[1].startsWith("this.")
                ? targetSchema[entry[1].replace("this.", "")]
                : entry[1];
            fnParams.push(paramValue);
          }
        });
        return value(data, ...fnParams);
      }
      return value;
    } else return valueMap;
  }

  protected initFromMaps(): any {
    this.result = this.schema.type === "array" ? [] : {};

    const maps = (this.schema.allOf || []).filter(
      (map) => !!map.$ref && !!(map as MapSchema).$map
    ) as MapSchema[];

    maps.forEach((map) => {
      const mapData = this.getDataFromRef(map);
      Object.entries(map.$map || {}).forEach((v) => {
        const keyParts = v[0].split(".");
        const key = keyParts.pop() || "";

        let obj = this.result;
        for (const key of keyParts) {
          if (!obj[key]) obj[key] = {};
          obj = obj[key];
        }

        obj[key] = this.getValueFromMap(
          v[1],
          mapData,
          this.schema.properties ? this.schema.properties[v[0]] : undefined
        );
      });
    });
    return this.result;
  }

  onDataInit = async (fn: string) => {
    if (!fn.startsWith("async")) fn = `async ${fn}`;
    // eslint-disable-next-line no-eval
    await eval(fn)(this.context.definitions?.functions, this.result);
  };

  async build(): Promise<any> {
    const doInit = !validateDataToSheme(this.result, this.schema);
    if (doInit) {
      this.initFromMaps();
    }
    Object.entries(this.schema.properties || []).forEach((value) => {
      this.setDefault(value[0], value[1]);
    });
    if (doInit && this.schema.onDataUpdate) {
      await this.onDataInit(this.schema.onDataUpdate);
    }
    return this.result;
  }
}
