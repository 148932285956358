import moment from "moment";
import { filterFormat } from "../humanDateFormat";

const dayMls = 1000 * 3600 * 24;

export const getFormattedDate = (date, format) => {};

export const getCurrentDate = () => {
  return moment();
};

export class SavedDateFormulas {
  currentDate = null;
  dateFrom = null;
  dateTo = null;

  KDMD = 0;
  KDDP = 0;
  KDDZ = 0;

  getKDMD() {
    return this.KDMD;
  }
  getKDDP() {
    return this.KDDP;
  }
  getKDDZ() {
    return this.KDDZ;
  }

  constructor(dateFrom, dateTo) {
    this.currentDate = new Date();
    this.dateFrom = new Date(dateFrom);
    this.dateTo = new Date(dateTo);
    this.KDMD = this.calcKDMD();
    this.KDDP = this.calcKDDP();
    this.KDDZ = this.calcKDDZ();
  }

  calcKDMD = () => {
    return this.dateFrom && this.dateTo && this.dateFrom !== this.dateTo
      ? Math.floor((this.dateTo.getTime() - this.dateFrom.getTime()) / dayMls)
      : 0;
  };

  calcKDDP = () => {
    return this.dateTo
      ? Math.floor(
          (this.currentDate.getTime() - this.dateTo.getTime()) / dayMls
        )
      : 0;
  };

  calcKDDZ = () => {
    return this.dateFrom
      ? Math.floor(
          (this.currentDate.getTime() - this.dateFrom.getTime()) / dayMls
        )
      : 0;
  };
}

export const getActualFilterDate = (createdDate, KDMD, KDDP, KDDZ) => {
  const dateSplit = createdDate?.split(",");
  const dateFrom = dateSplit?.[0];
  const dateTo = dateSplit?.[1];

  let filter = "";

  if (!dateFrom && dateTo) {
    const newDateTo = moment().subtract(KDDP || 0, "days");
    filter = [null, newDateTo.format(filterFormat)].join(",");
  } else if (dateFrom && !dateTo) {
    const newDateFrom = moment().subtract(KDDZ || 0, "days");
    filter = [newDateFrom.format(filterFormat), null].join(",");
  } else if (dateFrom && dateTo) {
    const newDateTo = moment().subtract(KDDP, "days");
    const newDateFrom = moment(newDateTo).subtract(KDMD, "days");
    filter = [
      newDateFrom.format(filterFormat),
      newDateTo.format(filterFormat),
    ].join(",");
  }

  return filter;
};
