import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CollectorIssuanceType = ({ t, value, list, ...rest }) => (
  <Chip
    label={`${t("DOCUMENT_FORM")}: ${
      (
        (list || []).find(
          (item) => item.value === value || item.id === value
        ) || {}
      ).name || ""
    }`}
    {...rest}
  />
);

CollectorIssuanceType.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CollectorIssuanceType.defaultProps = {
  value: "",
  list: [],
};

export default translate("ClaimList")(CollectorIssuanceType);
