import { FormSchema } from "../types";

const validateArray = function (data: any, schema: FormSchema): boolean {
  if (!schema.items || !Array.isArray(data)) return false;

  schema = schema.items;
  for (const item of data) {
    switch (schema.type) {
      case "array":
        if (!validateArray(item, schema)) return false;
        break;
      case "object":
        if (!validateObject(item, schema)) return false;
        break;
      default:
        return true;
    }
  }
  return true;
};

const validateObject = function (data: any, schema: FormSchema): boolean {
  if (Array.isArray(data)) return false;
  if (
    typeof schema.additionalProperties === "boolean" &&
    schema.additionalProperties
  )
    return true;

  const { properties } = schema;
  for (const key in data) {
    if (!properties[key]) return false;
  }
  return true;
};

export const validateDataToSheme = (data: any, schema: FormSchema): boolean => {
  if (!data || !schema) return false;

  switch (schema.type) {
    case "array":
      return validateArray(data, schema);
    case "object":
      return validateObject(data, schema);
    default:
      return true;
  }
};

export default validateDataToSheme;
