export const shareState = {
  checked: [],
  busy: false,
  blockScreen: false,
  loading: false,
  subLoading: false,
  display: "",
  changedShare: null,
  pdf: null,
  doc: null,
  error: "",
  showSignModal: false,
  manifest: null,
  data: {},
  prevDisplay: "",
  itIsUpdate: false,
  signError: "",
  shareSubTitle: "",
  link: "",
  shareError: "",
  orderDialog: {},
};
