import {
  requestBenefitTypes,
  requestPartTypes,
  requestTransactionTypes,
} from "../../../../actions/payment";

export default function () {
  const {
    dictionary,
    document: doc,
    value,
    actions: { setBusy },
  } = this.props;
  const actions = [];
  setBusy(true);

  if (!dictionary || !dictionary.benefitTypes) {
    actions.push(requestBenefitTypes);
  }

  if (!dictionary || !dictionary.partTypes) {
    actions.push(requestPartTypes);
  }

  if (!dictionary || !dictionary.transactionTypes) {
    actions.push(requestTransactionTypes);
  }

  Promise.all(actions.map((action) => action())).then(() => {
    if (!doc || !doc.id) {
      return;
    }
    this.onChange(value, true);
  });
}
