import getUrlParams from "../../../../helpers/getUrlParams";
import { replacePoint, valueToString } from "../Payment.jsx";

export default function () {
  const {
    newSum,
    form: { body, url },
  } = this.state;
  const {
    value,
    value: { totalSum },
  } = this.props;
  const { otherAmount } = this.state;
  const sum = otherAmount
    ? valueToString(value, newSum)
    : replacePoint(totalSum);
  if (sum !== newSum && newSum !== "0") {
    this.setState({ busy: true, newSum: sum }, () =>
      this.updatePaymentForm(true)
    );
  } else if (body) {
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", url);

    const parsed = getUrlParams(body);

    Object.keys(parsed).map((key) => {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "string");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", parsed[key]);

      return form.appendChild(hiddenField);
    });

    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "string");
    hiddenField.setAttribute("name", "back_url");
    hiddenField.setAttribute("value", form.baseURI);
    form.appendChild(hiddenField);

    const btn = document.createElement("input");
    btn.value = "продовжити";
    btn.setAttribute("type", "submit");
    form.appendChild(btn);

    document.body.appendChild(form);
    form.submit();
  }
}
