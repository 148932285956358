import * as api from "../services/api";
import store from "../store";

const { dispatch } = store;

export const REQUEST_USER_META = "REQUEST_USER_META";
export const ADD_USER_META = "ADD_USER_META";
export const EDIT_USER_META = "EDIT_USER_META";
export const DELETE_USER_META = "DELETE_USER_META";
export const UPLOAD_USER_META_DOCUMENT = "UPLOAD_USER_META_DOCUMENT";
export const REQUEST_SCAN_FILE = "REQUEST_SCAN_FILE";

const USER_META_URL = "api/user_meta";
const USERS_URL = "api/users";

export function requestUserMeta() {
  return api.get(USER_META_URL, REQUEST_USER_META, dispatch);
}

export function addUserMeta(data) {
  return api.post(USER_META_URL, data, ADD_USER_META, dispatch);
}

export function editUserMeta(data) {
  return api.put(USER_META_URL, data, EDIT_USER_META, dispatch);
}

export function deleteUserMeta() {
  return api.del(USER_META_URL, DELETE_USER_META, dispatch);
}

export function uploadDocument(file) {
  return api.upload(
    `${USERS_URL}/file`,
    file,
    { content_type: file.type },
    UPLOAD_USER_META_DOCUMENT,
    dispatch
  );
}

export function downloadScanFile(fileLink) {
  return api.get(
    `${USERS_URL}/file?file_link=${fileLink}`,
    REQUEST_SCAN_FILE,
    dispatch
  );
}
