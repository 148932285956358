import React from "react";
import PropTypes from "prop-types";

import { Icon, Card, CardHeader } from "@material-ui/core";

import { humanDateTimeFormat } from "../../../../helpers/humanDateFormat";

const Success = ({ t, classes, transaction, setId }) => (
  <Card className={classes.root} elevation={1} id={setId("card")}>
    <CardHeader
      id={setId("card-header")}
      avatar={<Icon color="action">check</Icon>}
      title={t("TRANSACTION_SUCCESS_PROCESSED")}
      subheader={humanDateTimeFormat(transaction.transactionDate)}
    />
  </Card>
);

Success.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
};

export default Success;
