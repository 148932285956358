export default {
  CASE_PROC_NUMBER: "Справа № / Провадження №",
  COURT_TITLE: "Суд до якого направляється заява",
  COUNT_CASES: "з {{from}} по {{to}} із {{total}}",
  DOCUMENT_STATUS: "Статус",
  DISPLAYED_CASES: "Відображати:",
  DOCUMENT_TITLE: "Назва",
  DOCUMENT_DATE: "Дата",
  FULL_NAME: "{{number}} від {{createdAt}}",
  HEADER: "Документи сторін",
  HEADER2:
    "Документи (позовні заяви) інших сторін, надіслані перед відправкою до суду",
  INPUTDOCS_EMPTY_PAGE_TITLE: "Документи відсутні",
  INPUTDOCS_EMPTY_PAGE_DESCRIPTION:
    "Тут буде відображено документи (позовні заяви), надіслані іншими сторонами судового процесу перед відправкою до суду",
  OWNER: "Від кого",
  TO_NAME: "Кому",
  REDIRECT_TO_CASE: "Перейти до провадження",
  SELECT_ALL: "Вибрати все",
};
