import DataSource from "../helpers/dataSource";
// import store from "../store";

// const REQUEST_API_INPUTDOCS = "DATA_SOURCE/REQUEST_API_INPUTDOCS/MY_SUCCESS";
const initialState = {
  list: null,
};

const dataSource = DataSource("api/inputdocs/my");

export default (state = initialState, action) => {
  state = dataSource.reducer()(state, action);

  switch (action.type) {
    default:
      return state;
  }
};
