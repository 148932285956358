import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { timeout, TimeoutError } from "promise-timeout";

import {
  Button,
  withStyles,
  FormControl,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Hidden,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import MobileDetect from "mobile-detect";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FileInputField from "../../components/CustomInput/FileInputField";
import { readAsUint8Array } from "../../helpers/readFileList";
import edsService /*, { serverList }*/ from "../../services/eds";
import config from "../../config";
import ProxySettings from "./ProxySettings";

const { allowStamps, useProxySettings, showServerList } =
  (config() && config().eds) || {};
const md = new MobileDetect(window.navigator.userAgent);

const styles = {
  content: {
    padding: "0 !important",
    marginBottom: 40,
  },
  grow: {
    flexGrow: 1,
  },
  menuItem: {
    "@media screen and (max-width: 767px)": {
      display: "block",
      fontSize: "12px",
      overflow: "hidden",
      whiteSpace: "unset",
      minHeight: "unset",
      textOverflow: "ellipsis",
    },
  },
  actions: {
    justifyContent: "flex-start",
  },
};

const serviceMessages = [
  "Виникла помилка при доступі до носія ключової інформації",
  "Виникла помилка при відкритті особистого ключа (невірний пароль чи ключ пошкоджений)",
  "Сертифікат не чинний за строком дії або закінчився строк дії відповідного особистого ключа",
];

class FileKeySignForm extends React.Component {
  input = null;

  state = {
    server: 0,
    password: null,
    key: null,
    keys: {},
    selectedKey: null,
    busy: false,
    errors: {},
    signingError: null,
    showServerSelect: showServerList,
    showErrorDialog: false,
    itsMobile: !!md.mobile(),
    serverList: undefined,
  };

  async componentDidMount() {
    this.setState({
      serverList: await edsService.getServerList(),
    });
  }

  handleChange =
    (name) =>
    ({ target }) => {
      const { errors } = this.state;
      delete errors[name];

      this.setState({ [name]: target.value, errors });
    };

  handleKeyChange = (key) => {
    if (!key) {
      return;
    }

    const { errors } = this.state;
    delete errors.key;
    this.setState({ key, errors, busy: true });
    this.enumKeys(key);
    this.setState({ busy: false });
  };

  enumKeys = async (key) => {
    const signer = edsService.getSigner();
    const keyAsUint8Array = await readAsUint8Array(key);

    const keys = {};

    const enumFunc = async (index) => {
      try {
        const existsKey = await signer.execute(
          "EnumJKSPrivateKeys",
          keyAsUint8Array,
          index
        );
        if (existsKey) {
          const privateKey = await signer.execute(
            "GetJKSPrivateKey",
            keyAsUint8Array,
            existsKey
          );

          for (let c = 0; c < privateKey.certificates.length; c++) {
            try {
              // eslint-disable-next-line no-await-in-loop
              await signer.execute(
                "SaveCertificate",
                privateKey.certificates[c]
              );
            } catch (e) {
              // console.log('SaveCertificate', e);
              // nothign to do
            }
          }

          const certificates = await Promise.all(
            privateKey.certificates.map((cert) =>
              signer.execute("ParseCertificate", cert)
            )
          );

          const certificate = certificates.find(
            ({ keyUsage, subjDRFOCode }) => {
              const keyUsageCheck = keyUsage === "Протоколи розподілу ключів";
              return allowStamps
                ? keyUsageCheck
                : subjDRFOCode && keyUsageCheck;
            }
          );

          if (certificate) {
            keys[existsKey] = certificate;
          }
          await enumFunc(index + 1);
        }
      } catch (e) {
        // console.log('error', e);
        // Nothing to do
      }
    };

    await enumFunc(0);
    this.setState({ keys, selectedKey: Object.keys(keys)[0] });
  };

  tryToSubmit = ({ key }) => key === "Enter" && this.handleSelectKey();

  handleClose = () => this.setState({ showErrorDialog: false });

  getSignCertificate = async (key, password) => {
    const { selectedKey } = this.state;
    const signer = edsService.getSigner();

    if (!selectedKey) {
      const privateKey = await signer.execute("ReadPrivateKey", key, password);
      return privateKey;
    }

    const privatKeyContainer = await signer.execute(
      "GetJKSPrivateKey",
      key,
      selectedKey
    );
    return signer.execute(
      "ReadPrivateKey",
      privatKeyContainer.privateKey,
      password
    );
  };

  readKeyOnServer = async (
    keyAsUint8Array,
    password,
    serverIndex,
    iterate = true
  ) => {
    // const acskServer = serverList[serverIndex];
    const acskServer = (await edsService.getServerList())[serverIndex];
    if (!acskServer) {
      return null;
    }

    if (!acskServer) {
      return null;
    }

    const signer = edsService.getSigner();

    try {
      await signer.execute("setServer", acskServer);
      const privateKey = await timeout(
        this.getSignCertificate(keyAsUint8Array, password),
        5000
      );
      return privateKey;
    } catch (e) {
      if (!iterate || serviceMessages.includes(e.message)) {
        throw e;
      }
      return this.readKeyOnServer(
        keyAsUint8Array,
        password,
        serverIndex + 1,
        iterate
      );
    }
  };

  readPrivateKey = async () => {
    const signer = edsService.getSigner();
    const { server, key, password } = this.state;
    const keyAsUint8Array = await readAsUint8Array(key);

    let privateKey = await this.readKeyOnServer(
      keyAsUint8Array,
      password,
      server && server - 1,
      !server
    );

    if (!privateKey) {
      await signer.execute("SetUseCMP", false);
      privateKey = await this.getSignCertificate(keyAsUint8Array, password);
    }

    return privateKey;
  };

  handleSelectKey = async () => {
    const { t, onSelectKey } = this.props;
    if (!onSelectKey) {
      return;
    }
    const errors = this.validate();

    if (Object.keys(errors).length) {
      this.setState({ errors });
      return;
    }

    this.setState({ busy: true });

    try {
      const privateKey = await this.readPrivateKey();

      if (privateKey === null) {
        this.setState({
          busy: false,
          errors: { server: t("CantDetectACSK") },
          showServerSelect: true,
        });
        // return;
        throw new Error("Помилка підписання запиту, не можливо зчитати ключ");
      }

      const signer = edsService.getSigner();

      await onSelectKey(privateKey, signer, () =>
        signer.execute("ResetPrivateKey")
      );
    } catch (e) {
      console.log("Sign claim error: ", e);
      this.setState({
        signingError:
          e instanceof TimeoutError || e.message === "CHECK_CERTIFICATE_ERROR"
            ? t(e.message)
            : e.message,
        showErrorDialog: true,
      });
    }

    this.setState({ busy: false });
  };

  validate() {
    const { t } = this.props;
    const { server, key, password } = this.state;
    const errors = {};
    if (server === null) {
      errors.server = t("SelectServer");
    }

    if (!key) {
      errors.key = t("SelectKey");
    }

    if (!password) {
      errors.password = t("FillPassword");
    }

    return errors;
  }

  render() {
    const { t, classes, setId, onClose } = this.props;
    const {
      server,
      key,
      password,
      busy,
      errors,
      signingError,
      showErrorDialog,
      keys,
      selectedKey,
      showServerSelect,
      showPassword,
      itsMobile,
      serverList,
    } = this.state;

    return (
      <>
        <DialogContent className={classes.content}>
          <FormControl fullWidth={true} id={setId("form")}>
            {errors.server || showServerSelect ? (
              <TextField
                id={setId("server")}
                select={true}
                label={t("ACSKServer")}
                value={server || 0}
                error={!!errors.server}
                onChange={this.handleChange("server")}
                margin="normal"
                disabled={busy}
                helperText={errors.server}
                SelectProps={{
                  MenuProps: { className: classes.menu },
                }}
              >
                <MenuItem
                  value={0}
                  id={setId(`server-autodetect`)}
                  className={classes.menuItem}
                >
                  {t("ACSKAutoDetect")}
                </MenuItem>
                {serverList &&
                  serverList.map((option, index) => {
                    const name = option.issuerCNs[0];
                    return (
                      <MenuItem
                        key={index}
                        value={index + 1}
                        id={setId(`server-${name}`)}
                        className={classes.menuItem}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            ) : null}
            <FileInputField
              t={t}
              id={setId("file")}
              label={t("Key")}
              error={!!errors.key}
              value={key}
              margin="normal"
              disabled={busy}
              helperText={errors.key}
              accept={itsMobile ? null : ".dat,.pfx,.pk8,.zs2,.jks"}
              onChange={this.handleKeyChange}
            />
            {Object.keys(keys).length > 1 ? (
              <TextField
                id={setId("key")}
                select={true}
                label={t("SelectedKey")}
                value={selectedKey}
                onChange={this.handleChange("selectedKey")}
                margin="normal"
                disabled={busy}
                SelectProps={{
                  MenuProps: { className: classes.menu },
                }}
              >
                {Object.keys(keys).map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    id={setId(`server-${option}`)}
                    className={classes.menuItem}
                  >
                    {t(option)}
                    {keys[option] ? ` (${keys[option].subjCN})` : null}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
            <TextField
              id={setId("password")}
              label={t("Password")}
              value={password || ""}
              error={!!errors.password}
              onKeyPress={this.tryToSubmit}
              onChange={this.handleChange("password")}
              margin="normal"
              type={showPassword ? "text" : "password"}
              disabled={busy}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        this.setState({
                          showPassword: !showPassword,
                        })
                      }
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {useProxySettings ? (
            <ProxySettings signer={edsService.getSigner()} busy={busy} />
          ) : null}
        </DialogContent>
        {busy ? <LinearProgress /> : null}
        <DialogActions className={classes.actions}>
          {onClose ? (
            <Hidden smDown={true} implementation="css">
              <Button
                size="large"
                onClick={onClose}
                disabled={busy}
                id={setId("cancel-button")}
                setId={(elementName) => setId(`cancel-${elementName}`)}
              >
                {t("Cancel")}
              </Button>
            </Hidden>
          ) : null}
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={this.handleSelectKey}
            disabled={busy}
            id={setId("sign-button")}
            setId={(elementName) => setId(`sign-${elementName}`)}
          >
            {t("Sign")}
          </Button>
        </DialogActions>
        <Dialog
          open={showErrorDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id={setId("dialog")}
          className={classes.dialog}
        >
          <DialogTitle
            id={setId("dialog alert-dialog-title")}
            className={classes.dialogContentWrappers}
          >
            {t("SigningDataError")}
          </DialogTitle>
          <DialogContent className={classes.dialogContentWrappers}>
            <DialogContentText id={setId("dialog alert-dialog-description")}>
              {signingError}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogContentWrappers}>
            <Button
              color="primary"
              onClick={this.handleClose}
              // autoFocus={true}
              id={setId("close-button")}
              setId={(elementName) => setId(`close-${elementName}`)}
            >
              {t("CloseDialog")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

FileKeySignForm.propTypes = {
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSelectKey: PropTypes.func.isRequired,
  showServerList: PropTypes.bool,
};

FileKeySignForm.defaultProps = {
  showServerList,
};

const styled = withStyles(styles)(FileKeySignForm);
export default translate("SignForm")(styled);
