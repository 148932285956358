export const myProcDocuments = {
  pagination: true,
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 5],
  },
  tableFields: [
    {
      key: "docNumber",
      sort: false,
      title: "DOCUMENT_NUMBER",
      classNames: ["cell", "shortCell", "onlyBigScreen"],
    },
    {
      key: "myCaseDocFullName",
      sort: true,
      title: "DOCUMENT_NAME",
      classNames: ["cell", "longCell", "onlyMobile"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "templateName",
      sort: false,
      title: "DOCUMENT_NAME",
      classNames: ["cell", "longCell", "onlyBigScreen"],
    },
    {
      key: "ownerName",
      sort: false,
      title: "OWNER",
      classNames: ["cell"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "docDate",
      sort: true,
      title: "CREATE_DATE",
      classNames: ["cell", "shortCell", "onlyBigScreen"],
      grid: [5, 7, 2, 3],
    },
    {
      key: "state",
      sort: true,
      title: "STATUS",
      classNames: ["cell", "textCentered", "shortCell"],
      grid: [2, 7, 3, 4],
    },
    {
      key: "courtName",
      sort: true,
      title: "CASE_COURT",
      classNames: ["cell", "longCell"],
      grid: [2, 7, 4, 5],
    },
  ],
  filters: ["claimStatus", "courtId", "courtType", "justiceType", "docDate"],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
    title: "SELECT_NOTES",
  },
};
