import NoOptionsMessage from "./NoOptionsMessage.jsx";
import Option from "./Option.jsx";
import Placeholder from "./Placeholder.jsx";
import ValueContainer from "./ValueContainer.jsx";
import Control from "./Control.jsx";
import MultiValue from "./MultiValue.jsx";
import Menu from "./Menu.jsx";
import SingleValue from "./SingleValue.jsx";

export default {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
  SingleValue,
};
