import PropTypes from "prop-types";
import { FormControl } from "@material-ui/core";
import cx from "classnames";
import { Button } from "../../../../components";
import TextField from "../../../../components/TextField";
import SelectFilesDialog from "../../../../components/SelectFilesDialog";

import AttachList from "../../../../components/Attach/AttachList";

const Benefites = ({
  t,
  classes,
  dictionary,
  filters,
  value,
  actions,
  openUploadModal,
  setId,
  handleChangeBenefits,
  handleDelete,
  handleUpload,
  closeUploadModal,
  onOpenUploadModal,
}) => {
  const benefits = value.benefits || {};
  const selectBenefits = [
    {
      id: "paid",
      name: t("ALREADY_PAID"),
      isActive: true,
    },
  ].concat(
    dictionary.benefitTypes.filter((type) =>
      (filters.benefitTypes || []).includes(type.id)
    )
  );
  let { confirmationFiles } = benefits;
  if (confirmationFiles && !Array.isArray(confirmationFiles)) {
    confirmationFiles = Object.values(confirmationFiles);
  }

  return (
    <FormControl className={classes.formControl} id={setId("")}>
      <TextField
        name="type"
        select={true}
        label={t("SELECT_BENEFIT")}
        onChange={handleChangeBenefits}
        margin="normal"
        value={benefits.type || ""}
        options={selectBenefits}
        id={setId("benefits")}
        selectProps={{
          textFieldProps: { margin: "normal" },
          id: setId("select-benefits"),
          SelectDisplayProps: {
            id: setId("select-benefits-wrapper"),
          },
          MenuProps: {
            className: cx(classes.menuItem, classes.benefits),
          },
        }}
      />
      <div id={setId("actions")}>
        <Button
          color="yellow"
          onClick={onOpenUploadModal}
          setId={(elementName) => setId(`open-dialog-${elementName}`)}
        >
          {t("UPLOAD")}
        </Button>
      </div>
      {confirmationFiles && !!confirmationFiles.length && (
        <AttachList
          attaches={confirmationFiles.filter(Boolean)}
          handleDelete={handleDelete}
          handleDownload={actions.downloadAttach}
          requestPreview={actions.requestPreview}
          setId={setId}
        />
      )}
      <SelectFilesDialog
        open={openUploadModal}
        uploadAction={actions.uploadAttach}
        onUpload={handleUpload}
        handleClose={closeUploadModal}
        fileName="payment"
        setId={setId}
        claim={true}
      />
    </FormControl>
  );
};

Benefites.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dictionary: PropTypes.object.isRequired,
  filters: PropTypes.object,
  value: PropTypes.object,
  actions: PropTypes.object.isRequired,
  openUploadModal: PropTypes.bool.isRequired,

  onOpenUploadModal: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  handleChangeBenefits: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  closeUploadModal: PropTypes.func.isRequired,
};

Benefites.defaultProps = {
  filters: {},
  value: {},
};

export default Benefites;
