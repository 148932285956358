import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { withStyles, Typography, Snackbar, Toolbar } from "@material-ui/core";
import cx from "classnames";
import { RegularCard, BlockScreen } from "../../components";
import Preloader from "../../components/Preloader";
import PageCardTitle from "../../components/PageCardTitle";
import EmptyPage from "../../components/EmptyPage";
import WarningDialog from "../../components/WarningDialog";
import videoUrls from "../../variables/videoUrls";
import styles from "../../variables/styles/claimList";
import setComponentsId from "../../helpers/setComponentsId";
import { Button } from "../../components";

const renderVideoFrame = (setId, classes, stepName) => {
  const videoUrl = videoUrls[stepName];
  if (!videoUrl) return null;
  return (
    <iframe
      id={setId("video")}
      className={classes.videoFrame}
      title="video"
      src={`https://www.youtube.com/embed/${videoUrl}`}
      frameBorder="0"
      allowFullScreen={true}
    />
  );
};

const StandartPageLayout = ({
  list,
  trash,
  loading,
  subLoading,
  blockScreen,
  dataSource,
  emptyPageTitle,
  emptyPageDescription,
  trashEmptyPageTitle,
  trashEmptyPageDescription,
  cardTitle,
  cardSubtitle,
  stepName,
  createButton,
  classes,
  setId,
  children,
  error,
  hideError,
  toolbar,
  createDialog,
  t,
  onlyDialog,
  warningText,
  isLastComponent,
  dialogs,
  wikiUrl,
}) => {
  if (loading || list === null) {
    return <BlockScreen open={true} />;
  }

  const itIsEmptyPage =
    (!dataSource.withSearch && !dataSource.filters && list.length === 0) ||
    (!dataSource.withSearch &&
      list.meta &&
      list.meta.pagination &&
      list.meta.pagination.total === 0 &&
      !dataSource.filters);

  const itIsTrashEmptyPage = itIsEmptyPage && trash;
  const notFound =
    list.length === 0 && (dataSource.withSearch || dataSource.filters);

  if (itIsTrashEmptyPage) {
    return (
      <EmptyPage
        title={trashEmptyPageTitle}
        description={trashEmptyPageDescription}
      >
        {warningText && <WarningDialog warningText={warningText} />}
        {renderVideoFrame(setId, classes, stepName)}
      </EmptyPage>
    );
  }

  if (onlyDialog && itIsEmptyPage) {
    return (
      <Fragment>
        {createDialog}
        {warningText && <WarningDialog warningText={warningText} />}
      </Fragment>
    );
  }

  if (itIsEmptyPage) {
    return (
      <EmptyPage
        title={emptyPageTitle || cardTitle}
        description={emptyPageDescription || cardSubtitle}
      >
        {(createButton || createDialog) && (
          <Toolbar className={classes.toolbar}>
            {createButton}
            {createDialog}
          </Toolbar>
        )}
        {warningText && <WarningDialog warningText={warningText} />}
        {renderVideoFrame(setId, classes, stepName)}
      </EmptyPage>
    );
  }

  return (
    <RegularCard
      isLastComponent={isLastComponent}
      headerColor="primary"
      setId={setId}
      cardTitle={
        <PageCardTitle
          text={cardTitle}
          stepName={stepName}
          setId={setId}
          wikiUrl={wikiUrl}
        />
      }
      cardSubtitle={cardSubtitle}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!error}
        message={error}
        id={setId("error")}
        action={
          hideError
            ? [
                <Button
                  key="close-error"
                  color="yellow"
                  size="small"
                  onClick={hideError}
                  className={classes.smallButton}
                >
                  OK
                </Button>,
              ]
            : []
        }
      />
      {subLoading && <Preloader />}
      {!subLoading && toolbar}
      {!subLoading &&
        !notFound &&
        (!onlyDialog || (onlyDialog && !dialogs)) && (
          <Fragment>
            {children}
            {dialogs}
          </Fragment>
        )}
      {!subLoading && notFound && (
        <div className={cx(classes.scroll, classes.toolbar)} id={setId("wrap")}>
          <Typography
            className={classes.title}
            variant="h6"
            gutterBottom={true}
          >
            {t("NOT_FOUND")}
          </Typography>
          {createDialog}
        </div>
      )}
      {onlyDialog && dialogs}
      <BlockScreen open={blockScreen} />
      {warningText && <WarningDialog warningText={warningText} />}
    </RegularCard>
  );
};

StandartPageLayout.propTypes = {
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  trash: PropTypes.bool,
  loading: PropTypes.bool,
  subLoading: PropTypes.bool,
  blockScreen: PropTypes.bool,
  dataSource: PropTypes.object,
  emptyPageTitle: PropTypes.string,
  emptyPageDescription: PropTypes.string,
  trashEmptyPageTitle: PropTypes.string,
  trashEmptyPageDescription: PropTypes.string,
  cardTitle: PropTypes.string,
  cardSubtitle: PropTypes.string,
  stepName: PropTypes.string,
  createButton: PropTypes.node,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideError: PropTypes.func,
  toolbar: PropTypes.node,
  createDialog: PropTypes.node,
  onlyDialog: PropTypes.bool,
  warningText: PropTypes.string,
  isLastComponent: PropTypes.bool,
  dialogs: PropTypes.node,
};

StandartPageLayout.defaultProps = {
  list: null,
  trash: false,
  loading: false,
  subLoading: false,
  blockScreen: false,
  dataSource: {},
  emptyPageTitle: "",
  emptyPageDescription: "",
  trashEmptyPageTitle: "",
  trashEmptyPageDescription: "",
  cardTitle: "",
  cardSubtitle: "",
  stepName: "",
  createButton: "",
  setId: setComponentsId("standart-page"),
  children: "",
  error: "",
  toolbar: "",
  createDialog: "",
  onlyDialog: false,
  warningText: "",
  isLastComponent: true,
  dialogs: "",
};

const styled = withStyles(styles)(StandartPageLayout);
const translated = translate("ClaimList")(styled);

export default translated;
