import evaluate from "../../../helpers/evaluate";
import { routerActions } from "react-router-redux";
import getWizardProps from "../../../helpers/getWizardProps";
import redirectes from "../../../helpers/documentRedirected";
import {
  requestTemplate,
  clearActiveClaim,
  setWizardStates,
} from "../../../actions/claim";
import * as statuses from "../../../variables/claimStatuses";
import validateStep from "./validateStep";

export default async function requestTemp(match, rootComponent, data) {
  const props = getWizardProps();
  let { activeTemplate: template } = props;
  const claim = props.claim || data || {};
  const {
    /*  attaches,
        owner, */
    digitalDocumentData,
    digitalDocumentTemplateId,
    state,
  } = claim;
  const { dispatch, memberRoles, activeClaim } = props;
  const needTemplate =
    state === statuses.CREATING_STATUS || state === statuses.SIGNING_STATUS;
  if (needTemplate && !digitalDocumentTemplateId) {
    return setWizardStates({
      error: "TEMPLATE_NOT_FOUND",
      blockScreen: false,
      loading: false,
      busy: false,
      init: false,
    });
  }
  if (
    (!template || template.id !== digitalDocumentTemplateId) &&
    digitalDocumentTemplateId
  ) {
    template = await requestTemplate(digitalDocumentTemplateId);

    if (template.message || template.isActive === false) {
      return setWizardStates({
        error: "TEMPLATE_NOT_FOUND",
        blockScreen: false,
        loading: false,
        busy: false,
        init: false,
      });
    }

    if (template?.jsonSchema?.properties?.form?.properties?.text?.maxLength) {
      template.jsonSchema.properties.form.properties.text.maxLength = 1000000000;
    }

    if (template?.jsonSchema?.properties?.attaches?.maxSize) {
      template.jsonSchema.properties.attaches.maxSize = 5368709120;
    }

    // console.log("template: ", template);

    setWizardStates({ template });
  }

  if (template?.jsonSchema?.properties?.attaches?.maxSize) {
    template.jsonSchema.properties.attaches.maxSize = 5368709120;
  }

  if (digitalDocumentData && digitalDocumentData.uploadError) {
    setWizardStates({ error: "UPLOAD_ERROR" });
  }

  const { stepId } = match.params || {};
  const {
    jsonSchema,
    jsonSchema: { properties: templateProps },
  } = template;
  const stepOrders = evaluate(jsonSchema.stepOrders, { allStepsData: null });

  // if (template && category !== 5 && !courtId) {
  //   const newCourtId = await getCourtId(template);
  //   const court = (digitalDocumentData || {}).court || {};
  //   if (newCourtId && newCourtId !== court.court) {
  //     await saveCourt(claimId, newCourtId, court);
  //   }
  // }

  template.jsonSchema.properties = Object.keys(templateProps).reduce(
    (accumulator, propKey) => {
      let { properties, items } = templateProps[propKey];
      const values = (digitalDocumentData || {})[propKey];
      if (properties) {
        properties = Object.keys(properties).reduce((acc, key) => {
          const item = properties[key];
          const possibleToEdit =
            item.value &&
            (!values || !values[key] || values[key + "userDefined"]);
          acc[key] = possibleToEdit ? { ...item, readonly: false } : item;
          return acc;
        }, {});
      }
      if (items) {
        items = Object.keys(items).reduce((acc, key) => {
          const item = items[key];
          if (item.personType && item.personType.enum) {
            item.personType.enum = item.personType.enum.map((el) => {
              const found =
                Array.isArray(memberRoles) &&
                memberRoles.find(({ name }) => name === el);
              if (found) {
                return found.description;
              }
              return el;
            });
          }
          acc[key] = item;
          return acc;
        }, {});
        if (items.description)
          templateProps[propKey].subDescription = items.description;
      }
      accumulator[propKey] = Object.assign(
        templateProps[propKey],
        properties && { properties },
        items && { items }
      );
      return accumulator;
    },
    {}
  );

  let maxStep = 0;
  if (claim.state === statuses.CREATING_STATUS) {
    try {
      stepOrders.forEach((orderStepName, orderStepId) => {
        const errors = validateStep(orderStepId);

        maxStep = orderStepId;
        if (Object.keys(errors).length > 0) {
          throw new Error();
        }
      });
    } catch (e) {
      // Nothing to do. Error is for break
    }
  }
  const activeStep =
    stepId || (digitalDocumentData || {}).activeStep || stepOrders[0];
  const activeStepId = stepOrders.indexOf(activeStep);

  if (stepId !== activeStep && claim.state === statuses.CREATING_STATUS) {
    dispatch(
      routerActions.replace(`/${rootComponent}/${claim.id}/${activeStep}`)
    );
  }

  if (activeClaim) {
    clearActiveClaim();
  }
  const redirectTo = redirectes[claim.category] || redirectes["1"];

  return setWizardStates({
    redirectTo,
    template,
    stepOrders,
    activeStepId,
    maxStep,
    busy: false,
    blockScreen: false,
    init: false,
  });
}
