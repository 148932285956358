import React from "react";

import { Button } from "../../../components";

type Props = {
  t: (s: string) => string;
  setId: (s: string) => string;
  onClick: () => void;
  classes: any;
};

const CreateOrderButton = ({ t, classes, onClick, setId }: Props) => (
  <Button
    color="yellow"
    onClick={onClick}
    id={setId("toolbar-add--order-button")}
    setId={(elmentName: string) => setId(`toolbar-add-${elmentName}`)}
    className={classes.mobileButtonFullWidth}
  >
    {t("ADD_ORDER")}
  </Button>
);

export default CreateOrderButton;
