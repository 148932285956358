import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import cx from "classnames";
import { withStyles } from "@material-ui/core";

import typographyStyle from "../../variables/styles/typographyStyle.jsx";

const Danger = ({ classes, children, setId, ...rest }) => (
  <div
    {...rest}
    className={cx(classes.defaultFontStyle, classes.dangerText)}
    id={setId("")}
  >
    {children}
  </div>
);

Danger.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  setId: PropTypes.func,
};

Danger.defaultProps = {
  children: <span />,
  setId: setComponentsId("typography-danger"),
};

export default withStyles(typographyStyle)(Danger);
