import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
  Typography,
  Snackbar,
} from "@material-ui/core";

import { Button, BlockScreen } from "../../components";
import EDSForm from "../../components/EDSForm";
import claimStyles from "../../variables/styles/claimWizard";
import shareDialogStyles from "../../variables/styles/shareDialog";
import customInputStyle from "../../variables/styles/customInputStyle";
import cx from "classnames";

import PdfDocument from "../../components/PDF";

const styles = { ...customInputStyle, ...shareDialogStyles, ...claimStyles };

const ShareSubscribeDialog = ({
  t,
  showSignModal,
  pdf,
  backToPrevDisplay,
  toggleSignModal,
  handleSelectKey,
  doc,
  setId,
  classes,
  loading,
  signError,
  closeSnackbar,
}) => (
  <Fragment>
    <PdfDocument
      pdf={pdf}
      doc={doc}
      setId={(elementName) => setId(`pdf-dopcument-${elementName}`)}
    />
    <Typography component="p" />
    {signError && (
      <Snackbar
        id={setId("error")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        message={<span id="message-id">{signError}</span>}
        action={[
          <Button
            key="close-error"
            color="yellow"
            size="small"
            onClick={closeSnackbar}
            className={classes.smallButton}
          >
            OK
          </Button>,
        ]}
      />
    )}
    <BlockScreen open={loading} transparentBackground={true} />
    <Button
      color="transparent"
      onClick={backToPrevDisplay}
      id={setId("remove-pdf-button")}
      disabled={loading}
      setId={(elmentName) => setId(`remove-pdf-${elmentName}`)}
    >
      {t("CONTINUE_EDITING")}
    </Button>

    <Button
      color="yellow"
      onClick={toggleSignModal}
      id={setId("finish-button")}
      disabled={loading}
      setId={(elmentName) => setId(`finish-${elmentName}`)}
    >
      {t("FINISH")}
    </Button>

    <Dialog
      open={showSignModal}
      onClose={toggleSignModal}
      aria-labelledby={setId("dialog-title")}
      id={setId("dialog")}
      className={cx(classes.dialog, classes.fullWidth)}
    >
      <DialogTitle
        id={setId("dialog-title")}
        className={classes.dialogContentWrappers}
      >
        {t("SIGNING_DOCUMENT")}
      </DialogTitle>
      <DialogContent
        id={setId("dialog-content")}
        className={classes.dialogContentWrappers}
      >
        <EDSForm onSelectKey={handleSelectKey} />
      </DialogContent>
    </Dialog>
  </Fragment>
);

const styled = withStyles(styles)(ShareSubscribeDialog);
const translated = translate("ClaimWizard")(styled);

ShareSubscribeDialog.propTypes = {
  showSignModal: PropTypes.bool.isRequired,
  pdf: PropTypes.string,
  backToPrevDisplay: PropTypes.func.isRequired,
  toggleSignModal: PropTypes.func.isRequired,
  handleSelectKey: PropTypes.func.isRequired,
  doc: PropTypes.object,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  signError: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

ShareSubscribeDialog.defaultProps = {
  pdf: null,
  doc: null,
};

export default translated;
