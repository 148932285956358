// -1  DELETED  Видалено
// 0  DRAFT  Чернетка
// 1  SIGNING  Підписання
// 2  SEND  Надіслано
// 3  SUCCESS  Отримано відповідь
// 4  ERROR  Помилка
// 5  ERROR_INSIDE Внутрішння помилка

export const DELETED = -1;
export const DRAFT = 0;
export const SIGNING = 1;
export const SEND = 2;
export const SUCCESS = 3;
export const ERROR = 4;
export const ERROR_INSIDE = 5;

const statuses = {
  [DELETED]: "DELETED",
  [DRAFT]: "DRAFT",
  [SIGNING]: "SIGNING",
  [SEND]: "SEND",
  [SUCCESS]: "SUCCESS",
  [ERROR]: "ERROR",
  [ERROR_INSIDE]: "ERROR_INSIDE",
};

const stagesStatuses = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
};

const stateRegStagesStatuses = {
  1: "1",
  2: "0,2,3,4,5,6,9,16,7,10,8,11,12,14,15,18",
};

export const sortStatuses = (t, statusesLib, docCategory = 1) => {
  return Object.keys(statusesLib)
    .reduce((acc, id) => {
      const stages =
        docCategory === 4 ? stateRegStagesStatuses : stagesStatuses;
      if (+id !== -1 && stages[id]) {
        acc.push({
          id,
          textId: statusesLib[id],
          name: t(statusesLib[id]),
          value: stages[id],
        });
      }
      return acc;
    }, [])
    .sort((status, nextStatus) => (status.name < nextStatus.name ? -1 : 1));
};

export const stateRegistersStatuses = {
  [DELETED]: "DELETED",
  [DRAFT]: "DRAFT",
  [SIGNING]: "SIGNING",
  [SEND]: "SEND",
  [SUCCESS]: "SUCCESS",
  [ERROR]: "ERROR",
  [ERROR_INSIDE]: "ERROR_INSIDE",
};

export const stateRegStatuses = {
  [DELETED]: "DELETED",
  [DRAFT]: "DRAFT",
  [SIGNING]: "SIGNING",
  [SEND]: "SEND",
  [SUCCESS]: "SUCCESS",
  [ERROR]: "ERROR",
  [ERROR_INSIDE]: "ERROR_INSIDE",
};

export const getStatusesForCat = (catId) => {
  switch (catId) {
    default:
      return stateRegStatuses;
  }
};

export default statuses;
