import { SchemaWizardState, WizardSchema } from "../types";
import { SchemaDataBuilder } from "../../FormFromSchema";
import { deleteEmptyValues } from "../../FormFromSchema/helpers";
import { Formatter } from "./../../FormFromSchema/json-schema/formatter";
import * as api from "../../../services/api";
import Handlebars from "handlebars";
import handlebars_iff from "./handlebars_iff";

export const SchemaFunctions = (
  schema: WizardSchema,
  state: SchemaWizardState
) => {
  // const api: any = API;
  const { allSteps, data, formContext, stepSaveQueue } = state;

  const formatText = (self: any, template: string): string => {
    if (template) {
      Handlebars.registerHelper("humenDate", function (value: string) {
        return Formatter.formatDate(value);
      });

      Handlebars.registerHelper("humenDateTime", function (value: string) {
        return Formatter.formatDateTime(value);
      });

      Handlebars.registerHelper("iff", handlebars_iff);

      const compiledTemplate = Handlebars.compile(template);
      return compiledTemplate(self.data);
    }
    return "";
  };

  const stepInit = async (stepName: string, forceUpdate: boolean = false) => {
    const { properties } = schema;

    // if (!forceUpdate && !data) return;
    const stepSchema = properties[stepName];
    const builder = new SchemaDataBuilder(
      stepSchema,
      forceUpdate ? undefined : data[stepName],
      {
        definitions: formContext,
        properties: data,
      }
    );
    let stepData = await builder.build();
    stepData = deleteEmptyValues(stepData);

    data[stepName] = stepData;
    if (!allSteps.find((item) => item.key === stepName)) {
      stepSaveQueue.add(stepName);
    }
  };

  const setStepData = async (stepName: string, stepData: any) => {
    data[stepName] = deleteEmptyValues(stepData);
    if (!allSteps.find((item) => item.key === stepName)) {
      stepSaveQueue.add(stepName);
    }
  };

  const toQuery = (data: any) => {
    const attr = deleteEmptyValues(data);
    return Object.entries(attr).reduce((acc, [key, value], i) => {
      acc += `${key}=${value}`;
      if (i !== Object.entries(attr).length - 1) acc += "&";
      return acc;
    }, "");
  };

  return {
    api,
    data,
    formatText,
    setStepData,
    stepInit,
    toQuery,
  };
};
