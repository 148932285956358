import React, { useEffect } from "react";
import { compose } from "redux";
import DevicesLayout from "./DevicesLayout";
import { DeviceInfo } from "./types";
import { withBlockScreen } from "../../../components/BlockScreen";
import { InjectedProps } from "../../../components/BlockScreen/withBlockScreen";
import DataSource from "../../../helpers/dataSource";
import { useSelector } from "react-redux";
import { devicesSelector } from "../../../selectors/personalData";

interface Props extends InjectedProps {
  t: (s: string) => string;
}

const dataSource = DataSource("api/consumers/devices/my");

const Devices: React.FC<Props> = ({ t, showBlockScreen }) => {
  const devices: DeviceInfo[] = useSelector(devicesSelector);

  useEffect(() => {
    dataSource
      .load()
      .then(() => {
        showBlockScreen(false);
      })
      .catch((e: Error) => console.error(e));
  }, []);

  return <DevicesLayout t={t} devices={devices} dataSource={dataSource} />;
};

export default compose(withBlockScreen(true))(Devices);
