import React, { useEffect, useState } from "react";
import { requestPdf } from "../../../actions/claim";
import stringToBlob from "../../../helpers/stringToBlob";
import { blobToTextEncoded } from "../../../helpers/blobToText";
import formatFile from "../../../helpers/formatFile";
import getFormat from "../../../helpers/getAttachFormat";
import getFileUrl from "../../../helpers/getFileUrl";
import blobToBase64 from "../../../helpers/blobToBase64";

type WithPDFProps = { documentId?: string; allowCommit?: number };

const withPDF = <P extends WithPDFProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  type State = {
    pdf: string;
    doc: null | Blob;
    docBase64: string;
  };

  const initState: State = {
    pdf: "",
    doc: null,
    docBase64: "",
  };

  function WithPDFHoc(props: P) {
    const [state, setState] = useState<State>(initState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (!props.documentId || props.allowCommit !== 1)
        return setState(initState);

      setLoading(true);
      (async () => {
        let file = await requestPdf(props.documentId);
        if (
          !!file.size &&
          !file.message &&
          (typeof file === "string" || typeof file === "object")
        ) {
          if (typeof file === "string") {
            file = stringToBlob(file);
          }
          const [text, docBase64, source] = await Promise.all([
            blobToTextEncoded("Windows-1251")(file),
            blobToBase64(file),
            file,
          ]);
          const formatSource = await formatFile(source, text);
          const format = await getFormat(formatSource, text);
          const url = await getFileUrl(formatSource, format, text);
          setState({ pdf: url, doc: formatSource, docBase64 });
        } else {
          setState(initState);
        }
        setLoading(false);
      })();
    }, [props.documentId]);

    return (
      <WrappedComponent pdfLoading={loading} {...state} {...(props as P)} />
    );
  }

  WithPDFHoc.displayName = `WithPDFHoc(${displayName})`;

  return WithPDFHoc;
};

export default withPDF;
