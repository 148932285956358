export const fields = {
  pagination: true,
  tableFields: [
    {
      key: "vpNum",
      sort: true,
      numeric: true,
      title: "VP_NUM",
      classNames: ["cell", "textRight", "shortCell", "onlyBigScreen", "w100"],
    },
    {
      key: "docNum",
      sort: true,
      numeric: true,
      title: "DOC_NUM",
      classNames: ["cell", "textRight", "shortCell", "onlyBigScreen", "w100"],
    },
    {
      key: "docAcceptDate",
      sort: true,
      title: "DOC_ACCEPT_DATE",
      classNames: ["cell", "shortCell", "customCellDocDateExecutiveDocs"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "docName",
      title: "DOC_NAME",
      classNames: ["cell", "customCellDocNameExecutiveDocs"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "vpStateId",
      title: "VP_STATE_ID",
      classNames: ["cell", "textCentered"],
      grid: [2, 7, 6, 7],
    },
    {
      key: "createdAt",
      numeric: true,
      sort: true,
      title: "CREATED_AT",
      classNames: [
        "cell",
        "shortCell",
        "customCellDocDateExecutiveDocs",
        "onlyBigScreen",
      ],
    },
    {
      key: "sumUah",
      title: "SUM_UAH",
      numeric: true,

      classNames: ["cell", "textRight", "shortCell", "w50"],
      grid: [2, 7, 5, 6],
    },
    {
      key: "orgName",
      sort: true,
      title: "ORG_NAME",
      classNames: ["cell", "shortCell", "onlyBigScreen"],
    },
    {
      key: "myRole",
      title: "USER_ROLE",
      classNames: [
        "cell",
        "shortCell",
        "textLeft",
        "customCellCourtNameExecutiveDocs",
        "onlyBigScreen",
      ],
    },
    {
      key: "executiveId",
      title: "EXECUTIVE_ID",
      classNames: ["cell", "shortCell", "textCenter", "onlyBigScreen"],
    },
    // {
    //   key: "vdStatementId",
    //   title: "VD_STATEMENT_ID",
    //   classNames: ["cell", "textCentered", "shortCell"],
    //   grid: [2, 7, 5, 6],
    // },
    // {
    //   key: "vpId",
    //   title: "VP_ID",
    //   classNames: ["cell", "textCentered", "shortCell"],
    //   grid: [2, 7, 5, 6],
    // },
  ],
  filters: ["vpStateId", "createdAt", "docAcceptDate", "myRole", "sumUah"],
};
