import React, { Component } from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MessageIcon from "@material-ui/icons/Message";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { addUserMeta, requestUserMeta } from "../../actions/usermeta";
import {
  setIsDeleteAllMessages,
  setIsDeleteMessage,
  setIsNotReadMessage,
  setIsRead,
} from "../../actions/notifications";
import { BlockScreen, Table } from "../../components";
import StandartPageLayout from "../../components/StandartPageLayout";
import NotificationToolbar from "./components/NotificationToolbar";
import { humanDateTimeFormat } from "../../helpers/humanDateFormat";
import DataSource from "../../helpers/dataSource";
import promiseChain from "../../helpers/promiseChain";

import { fields, getField } from "./data/fields.data";
import { styles } from "./data/styles.data";

import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";

const dataSource = DataSource("api/messages/my");

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockScreen: false,
      subLoading: false,
      checked: [],
      deleteModal: false,
      fields: getField(),
    };
  }

  componentDidMount() {
    dataSource.load();

    const { unread, read } = (this.props.list || {}).meta || {
      unread: 0,
      read: 0,
    };

    if (this.props.returnObject.isForm("notifications")) {
      let returnObject = this.props.returnObject.getReturnObject();
      if (
        this.props.list?.length &&
        this.props.list.some(
          (item) => String(item.messageId) === String(returnObject.id)
        )
      ) {
        this.onCheckboxClick(returnObject.id)();
      }
      this.props.returnObject.removeReturnObject();
    }

    dataSource.setValue("withSearch", !!(unread + read));
  }

  handleClick = (message) => async () => {
    this.setState({ blockScreen: true });
    const { messageId } = message;
    const { history, viewed, meta, list } = this.props;
    const needUseUserMeta = !(
      (list || {}).meta && "unread" in (list || {}).meta
    );
    if (needUseUserMeta) {
      viewed.push(messageId);

      await setIsRead([messageId], message, false);
      await addUserMeta({
        meta: {
          ...meta,
          viewedNotification: viewed,
        },
      });
      await requestUserMeta();
      //await dataSource.load();
    } else {
      await setIsRead([messageId], message);
      //!message.isRead && (await dataSource.load());
    }

    this.props.returnObject.setReturnObject({
      form: "notifications",
      id: messageId,
      filters: dataSource.filters,
      sort: dataSource.sort,
      path: window.location.pathname + window.location.search,
    });

    this.setState({ blockScreen: false });
    history.push(`/notifications/${messageId}`);
  };

  handleChangeFilter = ({ target: { value } }) => {
    this.setState({ subLoading: true }, () => {
      if (value === "") {
        delete dataSource.filters.is_read;
      } else {
        dataSource.setFilter("is_read", value);
      }
      return dataSource.load().then(() => this.setState({ subLoading: false }));
    });
  };

  pagination = (e, page) => {
    this.setState({ blockScreen: true });
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource.load().then(() => this.setState({ blockScreen: false }));
  };

  changeCount = ({ target: { value } }) => {
    this.setState({ blockScreen: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load().then(() => this.setState({ blockScreen: false }));
  };

  onSelectAllClick = () => {
    const { checked } = this.state;
    const { list } = this.props;
    if (!checked.length) {
      return this.setState({
        checked: list.map((item) => item.id),
      });
    }

    return this.setState({ checked: [] });
  };

  onCheckboxClick = (id) => () => {
    const { checked } = this.state;
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
      return this.setState({ checked });
    }
    this.setState({ checked: [...checked, id] });
    return false;
  };

  /**
   * Устанавливает статус прочен или нет.
   * @param {boolean} isRead - если false то нужно пометить не прочтенным
   */
  setCheckedIsRead = (isRead = true) =>
    this.setState({ blockScreen: true }, () =>
      promiseChain([
        () =>
          isRead
            ? setIsRead(this.state.checked)
            : setIsNotReadMessage(this.state.checked),
        () => dataSource.load(),
        () =>
          this.setState({
            blockScreen: false,
            checked: [],
          }),
      ])
    );

  /**
   * Удалить все нотификейшены
   */
  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  deleteAllNotifications = () =>
    this.setState({ blockScreen: true }, () =>
      promiseChain([
        () => setIsDeleteAllMessages(),
        () => dataSource.load(),
        () =>
          this.setState({
            blockScreen: false,
            checked: [],
            deleteModal: false,
          }),
      ])
    );

  /**
   * Удалить выбранные нотификейшены
   */
  deleteNotifications = () =>
    this.setState({ blockScreen: true }, () =>
      promiseChain([
        () => setIsDeleteMessage(this.state.checked),
        () => dataSource.load(),
        () =>
          this.setState({
            blockScreen: false,
            checked: [],
          }),
      ])
    );

  renderBody() {
    const { t, classes, list, setId } = this.props;
    const { checked } = this.state;
    if (!Array.isArray(list) && typeof list === "string") {
      return renderHTML(list);
    }

    const getText = (item, key) => {
      const { createdAt, titleMessage, messageId } = item;
      const title =
        typeof titleMessage === "string"
          ? renderHTML(titleMessage)
          : titleMessage;
      switch (key) {
        case "fullName":
          return (
            <span>
              {title}
              {t("FULL_NAME", {
                createdAt: humanDateTimeFormat(createdAt),
              })}
            </span>
          );
        case "icon":
          return (
            <MessageIcon
              className={classes.statusImage}
              id={setId(`table-row-icon-${messageId}`)}
            />
          );
        case "titleMessage":
          return title;
        case "createdAt":
          return humanDateTimeFormat(createdAt);
        default:
          return item[key];
      }
    };
    return (
      <Table
        fields={fields}
        getText={getText}
        setId={setId}
        dataSource={dataSource}
        onCheckItem={this.handleClick}
        list={list}
        needFullData={true}
        pagination={this.pagination}
        changeCount={this.changeCount}
        t={t}
        onSelectAllClick={this.onSelectAllClick}
        onCheckboxClick={this.onCheckboxClick}
        checked={checked}
      />
    );
  }

  render() {
    const { t, list, setId, classes } = this.props;
    const { blockScreen, subLoading, deleteModal, checked } = this.state;
    const meta = list?.meta;
    const unread = meta?.unread;
    const read = meta?.read;

    const viewedStatus = [
      {
        name: t("SHOW_ALL", { total: unread + read }), // total: unread
        value: "",
        title: "SHOW_ALL",
      },
      {
        name: t("SHOW_NOT_VIEWED", { unread }),
        value: "0",
        title: "SHOW_NOT_VIEWED",
      },
      {
        name: t("SHOW_VIEWED", { read }),
        value: "1",
        title: "SHOW_VIEWED",
      },
    ];

    const currentStatus = dataSource.filters.is_read || "";
    const unreadList =
      list && list?.length
        ? list.filter((item) => checked.includes(item.id) && !item.isRead)
        : [];
    const readList =
      list && list?.length
        ? list.filter((item) => checked.includes(item.id) && item.isRead)
        : [];

    return (
      <StandartPageLayout
        list={list}
        dataSource={dataSource}
        emptyPageTitle={t("NOTIFICATIONS_EMPTY_PAGE_TITLE")}
        emptyPageDescription={t("NOTIFICATIONS_EMPTY_PAGE_DESCRIPTION")}
        cardTitle={t("HEADER")}
        cardSubtitle={t("HEADER2")}
        stepName={"notifications"}
        setId={setId}
        subLoading={subLoading}
        toolbar={
          <NotificationToolbar
            t={t}
            classes={classes}
            checked={checked}
            currentStatus={currentStatus}
            dataSource={dataSource}
            list={list}
            read={read}
            readList={readList}
            unread={unread}
            deleteModal={deleteModal}
            unreadList={unreadList}
            viewedStatus={viewedStatus}
            setId={setId}
            setCheckedIsRead={this.setCheckedIsRead}
            toggleDeleteModal={this.toggleDeleteModal}
            deleteAllNotifications={this.deleteAllNotifications}
            deleteNotifications={this.deleteNotifications}
            handleChangeFilter={this.handleChangeFilter}
          />
        }
      >
        {list && list.meta && this.renderBody()}
        {/* {list && this.renderBody()} */}
        <BlockScreen open={blockScreen} />
      </StandartPageLayout>
    );
  }
}

NotificationList.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  t: PropTypes.func.isRequired,
  viewed: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
};

NotificationList.defaultProps = {
  list: null,
  meta: {},
};

const Notifications = compose(
  withStyles(styles),
  translate("Notifications"),
  ReturnAndLocateInTableHOC,
  withRouter
)(NotificationList);

const mapStateToProps = ({
  notifications: { list },
  usermeta: { userMetaData },
}) => {
  let viewed = [];
  const { meta } = userMetaData || {};
  if (userMetaData && meta && meta.viewedNotification) {
    ({
      meta: { viewedNotification: viewed },
    } = userMetaData);
  }
  return {
    list,
    viewed,
    meta,
  };
};

// decorate and export
export default connect(mapStateToProps)(Notifications);
