import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import cx from "classnames";

import { handlePrintHistory, getRows, getHtmlTemplate } from "./helpers";

import { Button } from "../../components";
import customInputStyle from "../../variables/styles/customInputStyle";

import HTMLPreview from "../../components/HTMLPreview";
import styles from "../../variables/styles/PreviewDialog";
import DialogTable from "./DialogTable";

const PreviewDialog = ({
  t,
  classes,
  openDialog,
  toggleDialog,
  title,
  historyRows,
  setId,
}) => {
  const [docSignPDF, setDocSignPDF] = useState(null);

  const format = docSignPDF?.format;
  const file = docSignPDF?.file;
  const name = docSignPDF?.name;

  useEffect(() => {
    let rows = getRows(historyRows);
    let template = getHtmlTemplate(title, rows);
    setDocSignPDF({
      file: new Blob([template], {
        type: "text/html",
      }),
      name: "",
      format: "html",
    });
  }, []);

  const printHistory = () => {
    if (!historyRows) return;

    let printTemplate = <DialogTable header={title} tableData={historyRows} />;

    return handlePrintHistory(printTemplate);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={toggleDialog}
      aria-labelledby={setId("title")}
      id={setId("")}
      className={cx(classes.dialog, classes.smWidth)}
    >
      <DialogTitle
        id={setId("title")}
        className={classes.dialogContentWrappers}
      >
        <div className={classes.flexBox}>
          <span className={classes.dialogTitle}>{name}</span>
          {format === "html" && (
            <IconButton
              color="inherit"
              className={classes.menuButton}
              title={t("PRINT")}
              onClick={printHistory}
            >
              <Print />
            </IconButton>
          )}
        </div>
      </DialogTitle>
      <DialogContent
        className={cx(classes.content, classes.dialogContentWrappers)}
        id={setId("content")}
      >
        {format === "html" && (
          <div className={classes.htmlPreviewWrapper}>
            <HTMLPreview
              setId={(elementName) => setId(`html-doc-preview-${elementName}`)}
              fileName={name}
              file={file}
              modal={true}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions
        className={cx(
          classes.actions,
          classes.dialogContentWrappers,
          classes.historyCardActions
        )}
        id={setId("history-actions")}
      >
        <Button
          color="yellow"
          onClick={toggleDialog}
          setId={(elementName) => setId(`close-btn-${elementName}`)}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreviewDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
  historyRows: PropTypes.array,
};

PreviewDialog.defaultProps = {
  openDialog: null,
  toggleDialog: () => null,
  title: "Документ",
  historyRows: [],
};

const styled = withStyles({ ...customInputStyle, ...styles })(PreviewDialog);

export default translate("Elements")(styled);
