import React from "react";
// @ts-nocheck
import { IconButton, Typography, withStyles } from "@material-ui/core";
import { Item } from "./items";
import RenderControl from "../../../../components/RenderControl/RenderControl";
import humanDateFormat from "../../../../helpers/humanDateFormat";
import { RenderControlValueChanged } from "../../../../components/RenderControl";
import { DisplayPreviewDialog } from "../../../../components/ShareDialog/types";
import KeyPng from "../../../../assets/img/key.svg";
import openInNewWindowStyles from "../../../../variables/styles/openInNewWindow";
import PreviewDialog from "../../../../components/Attach/PreviewDialog";
import { PreviewDialogType } from "./types";

type Props = {
  classes: any;
  pdf: any;
  createdAt?: string;
  cancelDate?: string;
  fields: Item[];
  onValueChanged: RenderControlValueChanged;
  formData: Record<string, any>;
  errors: Record<string, any>;
  t: (s: string) => string;
  togglePreviewDialog: (s: DisplayPreviewDialog) => () => void;
  orderPreview: PreviewDialogType;
  readOnly: boolean;
};

const OrderDialogLayout: React.FC<Props> = ({
  cancelDate,
  createdAt,
  pdf,
  classes,
  t,
  fields,
  onValueChanged,
  togglePreviewDialog,
  formData,
  errors,
  orderPreview,
  readOnly,
}) => {
  return (
    <div className={classes.wrapper}>
      {!!createdAt && !!pdf && (
        <Typography component="h1" className={classes.flexBox}>
          <span>
            {/* @ts-ignore */}
            {t("ORDER_GIVEN_DATE", {
              date: humanDateFormat(createdAt),
            })}
          </span>
          &nbsp;
          {pdf && (
            <>
              <span
                className={classes.link}
                onClick={togglePreviewDialog(DisplayPreviewDialog.ORDER)}
              >
                {t("ORDER_PREVIEW_LINK")}
              </span>
              <IconButton
                color="inherit"
                onClick={togglePreviewDialog(DisplayPreviewDialog.ECP)}
                title={t("CHECK_ECP")}
                className={classes.menuButton}
              >
                <img src={KeyPng} alt={t("CHECK_ECP")} width={24} height={24} />
              </IconButton>
              <PreviewDialog
                format={"pdf"}
                file={orderPreview.blob}
                url={orderPreview.url}
                doc={orderPreview.base64}
                openDialog={orderPreview.opened}
                toggleDialog={togglePreviewDialog(DisplayPreviewDialog.NONE)}
                setId={(name: string) => `PreviewDialog-${name}`}
              />
            </>
          )}
        </Typography>
      )}

      {!!cancelDate && (
        <Typography component="h1" className={classes.flexBox}>
          <span>
            {/* @ts-ignore */}
            {t("ORDER_CANCEL_DATE", {
              date: humanDateFormat(cancelDate),
            })}
          </span>
          &nbsp;
        </Typography>
      )}

      {fields.map((field) => {
        if (!field.visible) return null;

        if (!formData.name) {
          formData.name = formData.companyName;
        }

        // formData.caseNumberVisibility
        // if (formData.label === "CREATING_NEW_ORDER_ATTENTION_MSG") {
        //   return <div style={{ position: "inherit", bottom: "92px", marginLeft: "25px", fontSize: "14px", marginTop: "-111px" }}
        //   >
        //     {t("CREATING_NEW_ORDER_ATTENTION_MSG")}
        //   </div>
        // }

        return (
          <RenderControl
            key={field.name}
            {...field}
            t={t}
            onValueChanged={onValueChanged}
            value={formData[field.name]}
            errorMessage={errors[field.name]}
          />
        );
      })}
      {!readOnly && formData.caseNumberVisibility && (
        <div
          style={{
            position: "static",
            marginLeft: "15px",
            fontSize: "14px",
            color: "rgb(255 0 0)",
          }}
        >
          {t("CREATING_NEW_ORDER_ATTENTION_MSG")}
        </div>
      )}
    </div>
  );
};

const styles = {
  ...openInNewWindowStyles,
  wrapper: {
    padding: "0 25px",
  },
} as const;

export default withStyles(styles)(React.memo(OrderDialogLayout));
