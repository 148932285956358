import DataSource from "../helpers/dataSource";
import store from "../store";
import ApiException from "../services/api/ApiException";

export const SAVE_ACTIVE_CASE = "SAVE_ACTIVE_CASE";
export const CLEAR_CASES = "CLEAR_CASES";
export const RESIZE = "RESIZE";
export const SAVE_FILE = "SAVE_FILE";

const REQUEST_СASE_DOCUMENTS_SUCCESS = "REQUEST_СASE_DOCUMENTS_SUCCESS";
const REQUEST_DOCUMENT_SUCCESS = "REQUEST_DOCUMENT_SUCCESS";
const REQUEST_STATIC_FILE_SUCCESS = "REQUEST_STATIC_FILE_SUCCESS";
const REQUEST_SCAN_FILE_SUCCESS = "REQUEST_SCAN_FILE_SUCCESS";
const REQUEST_ACTIVE_CASE_SUCCESS = "REQUEST_ACTIVE_CASE_SUCCESS";
const REQUEST_CASES_COURTS_SUCCESS = "REQUEST_CASES_COURTS_SUCCESS";
const REQUEST_PROCEEDINGS_COURTS_SUCCESS = "REQUEST_PROCEEDINGS_COURTS_SUCCESS";
const REQUEST_JUDGE_CASES_COURTS_SUCCESS = "REQUEST_JUDGE_CASES_COURTS_SUCCESS";

const initialState = {
  list: null,
  caseDocuments: [],
  currentDocument: null,
  staticFile: null,
  scanFile: null,
  activeCase: {},
  procCourts: [],
  casesCourts: [],
  size: "100%",
  savedDoc: { file: null, docId: "" },
};

const getFilteredCourts = (filterArr) => {
  const {
    dictionary: { courts },
  } = store.getState();
  return (courts || []).filter(({ id }) =>
    (filterArr || []).some((item) => item === id)
  );
};

const getCourts = (filterArr) => {
  const fiteredCourts = getFilteredCourts(filterArr);
  return { procCourts: fiteredCourts, casesCourts: fiteredCourts };
};

export const caseDataSource = DataSource("api/cases");
export const caseDocDataSource = DataSource("api/documents/case", "case");
export const procDocDataSource = DataSource("api/documents/case", "proc");
export const myProcDocDataSource = DataSource("api/documents/my", "proc");
export const myCaseDocDataSource = DataSource("api/documents/my", "case");
export const myMemberRolesDataSource = DataSource("api/cases/my_member_roles");

const checkEmptyFileError = ({ payload: file, url, method, body }) => {
  if (!file.size) {
    const error = new Error("File is empty");
    error.file = file;
    ApiException(error, url, method, body);
  }
};

export default (state = initialState, action) => {
  [
    caseDataSource,
    caseDocDataSource,
    procDocDataSource,
    myProcDocDataSource,
    myCaseDocDataSource,
    myMemberRolesDataSource,
  ].forEach((dataSource) => {
    state = dataSource.reducer()(state, action);
  });
  const { type, payload } = action;
  const { list } = state;
  const {
    dictionary: { courts },
  } = store ? store.getState() : { dictionary: { courts: [] } };

  switch (type) {
    case REQUEST_СASE_DOCUMENTS_SUCCESS:
      return !Array.isArray(payload)
        ? state
        : { ...state, caseDocuments: payload };
    case REQUEST_DOCUMENT_SUCCESS:
      checkEmptyFileError(action);
      return { ...state, currentDocument: payload };
    case REQUEST_STATIC_FILE_SUCCESS:
      checkEmptyFileError(action);
      return { ...state, staticFile: payload };
    case REQUEST_SCAN_FILE_SUCCESS:
      checkEmptyFileError(action);
      return { ...state, scanFile: payload };
    case SAVE_ACTIVE_CASE:
    case REQUEST_ACTIVE_CASE_SUCCESS:
      if (list) {
        const found = list.find((item) => item.id === payload.id);
        if (found) {
          const foundIndex = list.indexOf(found);
          list[foundIndex] = payload;
        }
      }
      return { ...state, activeCase: payload, list };
    case REQUEST_CASES_COURTS_SUCCESS:
    case "REQUEST_CASES_COURTS_FAIL":
      if (!Array.isArray(payload) || !payload.length) {
        return { ...state, casesCourts: courts || [] };
      }
      return { ...state, casesCourts: getFilteredCourts(payload) };
    case REQUEST_PROCEEDINGS_COURTS_SUCCESS:
    case "REQUEST_PROCEEDINGS_COURTS_FAIL":
      if (!Array.isArray(payload) || !payload.length) {
        return { ...state, procCourts: courts || [] };
      }
      return { ...state, procCourts: getFilteredCourts(payload) };
    case REQUEST_JUDGE_CASES_COURTS_SUCCESS:
    case "REQUEST_JUDGE_CASES_COURTS_FAIL":
      if (!Array.isArray(payload) || !payload.length) {
        return { ...state, ...getCourts(courts) };
      }
      return { ...state, ...getCourts(payload) };
    case CLEAR_CASES:
      return { ...state, list: null };
    case RESIZE:
      return { ...state, size: payload };
    case SAVE_FILE:
      return { ...state, savedDoc: payload };
    default:
      return state;
  }
};
