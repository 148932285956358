import { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Document, Page } from "react-pdf";
import {
  Icon,
  Paper,
  Typography,
  LinearProgress,
  withStyles,
} from "@material-ui/core";
import setComponentsId from "../../helpers/setComponentsId";
import downloadBase64Attach from "../../helpers/downloadBase64Attach";
import Preloader from "../../components/Preloader";
import { Button } from "../../components";

import styles from "../../variables/styles/pdfDocument";

const PrevNavigationButton = ({ handlePrevClick, page }) => {
  if (page === 1) {
    return null;
  }
  return (
    <Button
      variant="contained"
      color="yellow"
      title={"Попередня сторінка"}
      onClick={handlePrevClick}
      disabled={page === 1}
    >
      <Icon>arrow_back</Icon>
    </Button>
  );
};

PrevNavigationButton.propTypes = {
  handlePrevClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

const NextNavigationButton = ({ handleNextClick, page, pages }) => {
  if (page === pages) return null;
  return (
    <Button
      variant="contained"
      color="yellow"
      title={"Наступна сторінка"}
      onClick={handleNextClick}
      disabled={page === pages}
    >
      <Icon>arrow_forward</Icon>
    </Button>
  );
};

NextNavigationButton.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number,
};

NextNavigationButton.defaultProps = {
  pages: null,
};

const NavigationPageLabel = withStyles(styles)(({ classes, page, pages }) => (
  <Typography
    variant="h6"
    className={cx(
      classes.pageLabel,
      page === pages && classes.pageLabelWithoutButtons
    )}
  >
    {page} / {pages}
  </Typography>
));

class PdfDocument extends Component {
  state = {
    scale: null,
    pageNumber: 1,
    page: 1,
    isFileLoading: false,
  };
  wrap = null;

  componentDidMount() {
    const {
      wrap,
      props: { modal },
    } = this;
    let scale = modal ? 0.9 : 1;
    if (!modal && wrap && wrap.offsetWidth < 618) {
      if (wrap.offsetWidth < 625) {
        scale = (wrap.offsetWidth - 30) / 595;
      }
    }
    this.setScale(scale);
  }

  setScale = (scale) => this.setState({ scale });

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ pageNumber: numPages });
  };

  downloadPdf = async () => {
    try {
      this.setState({
        isFileLoading: true,
      });
      const { doc, fileName } = this.props;
      await downloadBase64Attach(
        {
          fileName,
          contentType: "pdf",
        },
        doc
      );
    } catch (error) {
      if (error instanceof Error) {
        return alert(error.serverMessage + " " + error.details);
      }
    } finally {
      this.setState({
        isFileLoading: false,
      });
    }
  };

  handlePrevClick = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleNextClick = () => {
    this.setState({ page: this.state.page + 1 });
  };

  render() {
    const { classes, pdf, setId, hideDownload } = this.props;
    const { scale, isFileLoading } = this.state;

    if (!pdf) {
      return <Preloader />;
    }

    return (
      <div
        ref={(c) => {
          this.wrap = c;
        }}
        className={classes.pdfWrap}
      >
        <Paper className={classes.pdfDocument} id={setId("")}>
          {scale && (
            <>
              <Document
                file={pdf}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={<Preloader />}
              >
                <Page
                  className={classes.pdfPage}
                  pageNumber={this.state.page}
                  scale={scale}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
              <div className={classes.pdfNavigationWrapper}>
                <PrevNavigationButton
                  page={this.state.page}
                  handlePrevClick={this.handlePrevClick}
                />
                <NavigationPageLabel
                  page={this.state.page}
                  pages={this.state.pageNumber}
                />
                <NextNavigationButton
                  page={this.state.page}
                  pages={this.state.pageNumber}
                  handleNextClick={this.handleNextClick}
                />
              </div>
            </>
          )}
          {!hideDownload && (
            <Button
              variant="contained"
              color="yellow"
              title={"Завантажити"}
              className={classes.pdfDownload}
              onClick={this.downloadPdf}
              id={setId("download-button")}
              setId={(elementName) => setId(`download-${elementName}`)}
            >
              <Icon>save_alt</Icon>
            </Button>
          )}
        </Paper>
        {isFileLoading && <LinearProgress className={classes.progress} />}
      </div>
    );
  }
}

PdfDocument.propTypes = {
  classes: PropTypes.object.isRequired,
  pdf: PropTypes.string,
  setId: PropTypes.func,
  doc: PropTypes.object,
  fileName: PropTypes.string,
  modal: PropTypes.bool,
  hideDownload: PropTypes.bool,
};

PdfDocument.defaultProps = {
  setId: setComponentsId("pdf-document"),
  pdf: "",
  doc: null,
  fileName: "Заява",
  modal: false,
  hideDownload: false,
};

// decorate and export
export default withStyles(styles)(PdfDocument);
