import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import { List, ListItem, withStyles } from "@material-ui/core";

import footerStyle from "../../variables/styles/footerStyle";

const Footer = ({ classes, setId }) => (
  <footer className={classes.footer} id={setId("")}>
    <div className={classes.container} id={setId("container")}>
      <div className={classes.left} id={setId("left-panel")}>
        <List className={classes.list} id={setId("list")}>
          <ListItem className={classes.inlineBlock} id={setId("home")}>
            <a href="#home" className={classes.block} id={setId("home-link")}>
              Home
            </a>
          </ListItem>
          <ListItem className={classes.inlineBlock} id={setId("company")}>
            <a
              href="#company"
              className={classes.block}
              id={setId("company-link")}
            >
              Company
            </a>
          </ListItem>
          <ListItem className={classes.inlineBlock} id={setId("portfolio")}>
            <a
              href="#portfolio"
              className={classes.block}
              id={setId("portfolio-link")}
            >
              Portfolio
            </a>
          </ListItem>
          <ListItem className={classes.inlineBlock} id={setId("blog")}>
            <a href="#blog" className={classes.block} id={setId("blog-link")}>
              Blog
            </a>
          </ListItem>
        </List>
      </div>
      <p className={classes.right} id={setId("tm")}>
        <span>
          &copy; {1900 + new Date().getYear()}&nbsp;
          <a
            href="http://www.creative-tim.com"
            className={classes.a}
            id={setId("tm-link")}
          >
            Creative Tim
          </a>
          , made with love for a better web
        </span>
      </p>
    </div>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
};

Footer.defaultProps = {
  setId: setComponentsId("footer"),
};

export default withStyles(footerStyle)(Footer);
