import {
  OrderAllowedBy,
  OrderIssueredByType,
  SaveOrderBodyMeta,
} from "./types";
import {
  BaseItem,
  IPN_REGEXP,
  EDRPOU_REGEXP,
  EMAIL_REGEXP,
  PHONE_MASK,
  PHONE_REGEXP,
  RadioItemOptions,
  TextItemOptions,
} from "../../../../components/RenderControl";

export type OrderDialogFormData = Omit<SaveOrderBodyMeta, "allowedBy"> &
  OrderAllowedBy;

export type Item = BaseItem<OrderDialogFormData>;

const baseFields: Item[] = [
  {
    type: "string",
    name: "address",
    required: true,
    visible: true,
    label: "ANY_ADDRESS",
    helperText: "ANY_ADDRESS_SAMPLE",
    options: { maxLen: 250 } as TextItemOptions,
  },
  {
    type: "string",
    visible: true,
    name: "phone",
    label: "USER_PHONE",
    helperText: "USER_PHONE_SAMPLE",
    options: {
      maxLen: 250,
      regexp: PHONE_REGEXP,
      mask: PHONE_MASK,
    } as TextItemOptions,
  },
  {
    type: "string",
    name: "email",
    visible: true,
    label: "USER_EMAIL",
    helperText: "USER_EMAIL_SAMPLE",
    options: { maxLen: 250, regexp: EMAIL_REGEXP } as TextItemOptions,
  },
  {
    type: "string",
    visible: true,
    name: "contractNumber",
    required: true,
    label: "CONTRACT_NUMBER",
    helperText: "CONTRACT_NUMBER_SAMPLE",
    options: { maxLen: 250 } as TextItemOptions,
  },
  {
    type: "date",
    visible: true,
    name: "contractDate",
    required: true,
    label: "CONTRACT_DATE",
    helperText: "CONTRACT_DATE_SAMPLE",
  },
  {
    type: "string",
    name: "orderNumber",
    visible: true,
    required: true,
    label: "ORDER_NUMBER",
    helperText: "ORDER_NUMBER_SAMPLE",
    options: { maxLen: 250 } as TextItemOptions,
  },
  {
    type: "date",
    name: "orderDate",
    visible: true,
    required: true,
    label: "ORDER_DATE",
    helperText: "ORDER_DATE_SAMPLE",
  },
  {
    type: "radio",
    name: "issueredByType",
    label: "GIVEN_BY",
    visible: true,
    options: {
      items: [
        {
          label: "BY_ADVOCATE",
          value: OrderIssueredByType.ADVOCATE,
          key: "byAdvocate",
        },
        {
          label: "BY_ADVOCATES_OFFICE",
          value: OrderIssueredByType.OFFICE,
          key: "byOffice",
        },
      ],
    } as RadioItemOptions,
  },
  {
    type: "string",
    required: true,
    visible: false,
    name: "issueredByName",
    label: "ISSUERED_BY_NAME",
    helperText: "ISSUERED_BY_NAME_HINT",
    options: { maxLen: 250 } as TextItemOptions,
  },
  {
    type: "string",
    required: true,
    visible: false,
    name: "issueredByAddress",
    label: "ISSUERED_ADDRESS",
    helperText: "ISSUERED_ADDRESS_HINT",
    options: { maxLen: 250 } as TextItemOptions,
  },
  {
    type: "string",
    visible: false,
    required: true,
    name: "caseNumber",
    label: "CASE_NUMBER",
    options: { maxLen: 250 } as TextItemOptions,
  },
  // {
  //   type: 'string',
  //   visible: true,
  //   name: 'workAddress',
  //   label: 'WORK_ADDRESS',
  //   helperText: 'WORK_ADDRESS_SAMPLE',
  //   options: {maxLen: 250} as TextItemOptions
  // },
];

export const getNotLegalFields = (): Item[] => {
  return [
    {
      type: "string",
      visible: true,
      name: "name",
      required: true,
      label: "USER_NAME",
      helperText: "USER_NAME_SAMPLE",
      options: { maxLen: 250 } as TextItemOptions,
    },
    {
      type: "string",
      visible: true,
      name: "ipn",
      required: true,
      label: "IPN",
      helperText: "IPN_SAMPLE",
      options: { maxLen: 250, regexp: IPN_REGEXP } as TextItemOptions,
    },
    ...baseFields,
  ];
};

export const getLegalFields = (): Item[] => {
  return [
    {
      type: "string",
      visible: true,
      name: "companyName",
      required: true,
      label: "COMPANY_NAME",
      helperText: "COMPANY_NAME_SAMPLE",
      options: { maxLen: 250 } as TextItemOptions,
    },
    {
      type: "string",
      visible: true,
      name: "edrpou",
      required: true,
      label: "EDRPOU",
      helperText: "EDRPOU_SAMPLE",
      options: { maxLen: 250, regexp: EDRPOU_REGEXP } as TextItemOptions,
    },
    ...baseFields,
  ];
};
