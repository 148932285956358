import React, { Component } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";
import { translate } from "react-translate";
import cn from "classnames";
import { FormControl } from "@material-ui/core";
import Checkbox from "./Checkbox";

class CheckboxesForm extends Component {
  state = { error: "" };

  getError = (key, checked, childError = "") => {
    const {
      item: { maxSelected, minSelected, tree },
      t,
    } = this.props;
    const data = this.props.data || {};
    let error = "";

    if (key) {
      data[key] = checked;
    }

    const getKeys = (arr) =>
      arr.reduce((acc, item) => {
        if (item.key) acc.push(item.key);
        if (item.tree) acc = acc.concat(getKeys(item.tree));
        return acc;
      }, []);
    const keys = getKeys(tree);
    const checkedLength = keys.filter((checkKey) => !!data[checkKey]).length;

    if (checkedLength > maxSelected) {
      error = t("MAX_SELECTED_ERROR", { count: maxSelected });
    } else if (checkedLength < minSelected) {
      error = t("MIN_SELECTED_ERROR", { count: minSelected });
    }
    this.setState({ error });

    return error || childError;
  };

  onChange = (key, checked, error) => {
    const { onChange, index } = this.props;
    onChange(key, checked, this.getError(key, checked, error), index);
  };

  onCheck = (key, checked) => this.onChange(key, checked);

  renderTreeItem = (itemData, index) => {
    const { classes, formControlProps, i, t, setId, data, showErrors } =
      this.props;
    return (
      <FormControl
        {...formControlProps}
        className={cn(classes.formControl, classes.wrapper)}
        key={`${itemData.key || "form"}-${index}`}
        id={setId(`form-control-${index}`)}
      >
        {itemData.key && (
          <Checkbox
            onChange={this.onCheck}
            checked={data[itemData.key] === 1}
            itemKey={itemData.key}
            checkboxLabel={itemData.checkbox}
            classes={classes}
            setId={setId}
            index={index}
          />
        )}
        {!itemData.key && (
          <CheckboxesForm
            item={itemData}
            classes={classes}
            formControlProps={formControlProps}
            onChange={this.onChange}
            key={`${itemData.label}-${i}`}
            showErrors={showErrors}
            t={t}
            data={data}
            itsChildren={true}
            setId={(elementName) => setId(`next-level-${elementName}`)}
          />
        )}
      </FormControl>
    );
  };

  render() {
    const { item, classes, showErrors, setId, itsChildren } = this.props;
    const { error } = this.state;

    return (
      <div
        id={setId("")}
        className={cn(
          classes.checkboxFormWrapper,
          !itsChildren && classes.parentWrapper
        )}
      >
        <p
          id={setId("label")}
          className={cn(classes.checkboxFormLabel, classes.wrapper)}
        >
          {item.label}
        </p>
        {error && showErrors && (
          <p className={classes.labelRootError} id={setId("error")}>
            {error}
          </p>
        )}
        {item.tree && item.tree.map(this.renderTreeItem)}
      </div>
    );
  }
}

CheckboxesForm.propTypes = {
  onChange: PropTypes.func,
  item: PropTypes.object.isRequired,
  data: PropTypes.object,
  formControlProps: PropTypes.object,
  i: PropTypes.number,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
  index: PropTypes.number,
  itsChildren: PropTypes.bool,
};

CheckboxesForm.defaultProps = {
  i: 0,
  onChange: undefined,
  data: {},
  formControlProps: {},
  showErrors: false,
  setId: setComponentsId("checkboxes-form"),
  index: null,
  itsChildren: false,
};

export default translate("Elements")(CheckboxesForm);
