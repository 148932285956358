// import {
//     REQUEST_USER_SETTINGS,
//     UPDATE_USER_SETTINGS,
//     SET_USER_SETTINGS,
// } from "../actions/user";
import DataSource from "../helpers/dataSource";

const REQUEST_USER_SETTINGS = "REQUEST_USER_SETTINGS";
const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
const SET_USER_SETTINGS = "SET_USER_SETTINGS";

/**
 * @type {PersonalDataState}
 */
const initialState = {
  //если showAttachesPreview false то во вложениях отображает таблицу вместо превью
  showAttachesPreview: false,
  notSendIndividual: false,
  notSendRepresantative: false,
  onlyProcedural: false,
  emailAttachments: false,
  isAdvocate: false,
  advocate: {
    isChecked: false, // Дані адвоката перевірено
    certnum: "", // Номер свідоцтва
    advocate: false, // существует ли запись адвоката с такими данными в ЕРАУ
    occupation: false, // приостановлена ли деятельность у данного адвоката
  },
  devices: [],
};

/**
 * @param {string} str
 */
function parseSettings(str) {
  try {
    if (typeof str === "string") {
      return JSON.parse(str);
    }
    return str;
  } catch (e) {
    return {};
  }
}

const myDevicesDataSource = DataSource("api/consumers/devices/my");
myDevicesDataSource.setValue("storeName", "devices");

export default (state = initialState, { type, payload }) => {
  [myDevicesDataSource].forEach((dataSource) => {
    state = dataSource.reducer()(state, {
      type,
      payload,
    });
  });

  switch (type) {
    case myDevicesDataSource.getActionName() + "_SUCCESS": {
      return {
        ...state,
        devices: payload.map((info) => {
          return {
            ...info,
            deviceDescription: parseSettings(info.deviceDescription) || {},
          };
        }),
      };
    }
    case SET_USER_SETTINGS:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_USER_SETTINGS + "_SUCCESS":
    case REQUEST_USER_SETTINGS + "_SUCCESS": {
      const data = parseSettings(payload.settings);
      return {
        ...state,
        ...data,
        advocate: {
          ...state.advocate,
          ...(data.advocate || {}),
        },
      };
    }
    default:
      return state;
  }
};
