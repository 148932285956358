export const styles = {
  contentContainer: {
    padding: "0 24px",
    display: "flex",
    flexDirection: "column",
  },
  dialogRoot: {
    maxWidth: 700,
  },
  item: {
    marginBottom: 8,
  },
} as const;
