import React from "react";
import PropTypes from "prop-types";
import { Tooltip, withStyles } from "@material-ui/core";
import styles from "../../variables/styles/tooltip";

const DefaultTooltip = ({ title, children, classes }) => (
  <Tooltip title={title} classes={{ tooltip: classes.tooltip }}>
    {children}
  </Tooltip>
);

DefaultTooltip.propTypes = {
  title: PropTypes.node,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

DefaultTooltip.defaultProps = {
  title: "",
  children: "",
};

export default withStyles(styles)(DefaultTooltip);
