import React from "react";
import PropTypes from "prop-types";

import { translate } from "react-translate";

import { searchUser } from "../../actions/user";

import getUserShortName from "../../helpers/getUserShortName";
import promiseChain from "../../helpers/promiseChain";
import waiter from "../../helpers/waitForAction";

import {
  Avatar,
  LinearProgress,
  withStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import styles from "../../variables/styles/selectUserDialog";

import { Person } from "@material-ui/icons";

class SelectUserDialog extends React.Component {
  state = { options: [], busy: false, value: "" };
  handleClose = () => this.props.onClose(null);

  handleListItemClick =
    ({ userId, ...value }) =>
    () => {
      const { onUserSelect } = this.props;
      const { value: ipn } = this.state;
      onUserSelect &&
        onUserSelect({
          userId,
          id: userId,
          ipn,
          ...value,
        });
    };

  handleSearch = ({ target: { value } }) => {
    const filters = this.props.filters || { isLegal: false };
    this.setState({ value });

    if (!value) {
      return this.setState({ options: [] });
    }

    return waiter.addAction(
      "searchUser",
      () =>
        promiseChain([
          () => this.setState({ busy: true }),
          () => searchUser({ code: value }),
          (data) =>
            data.users.filter(
              (user) =>
                Object.keys(filters)
                  .map((key) => filters[key] !== user[key])
                  .filter(Boolean).length === 0
            ),
          (users) => this.setState({ options: users, busy: false }),
        ]).catch(() => this.setState({ busy: false })),
      1000
    );
  };

  render() {
    const { t, setId, classes, handleClose, ...other } = this.props;
    const { options, busy, value } = this.state;
    other.onUserSelect = undefined;

    return (
      <Dialog
        fullWidth={true}
        onClose={handleClose}
        {...other}
        id={setId("")}
        className={classes.dialog}
      >
        <DialogTitle
          id={setId("title")}
          className={classes.dialogContentWrappers}
        >
          {t("FIND_USER")}
        </DialogTitle>
        {busy && <LinearProgress />}
        <List id={setId("list")}>
          <ListItem id={setId("search")}>
            <TextField
              className={classes.search}
              id={setId("search-input")}
              label={t("INN")}
              type="search"
              margin="none"
              onChange={this.handleSearch}
              value={value}
            />
          </ListItem>
          {options.map((user) => (
            <ListItem
              button={true}
              onClick={this.handleListItemClick(user)}
              key={user.userId}
              id={setId(user.userId)}
            >
              <ListItemAvatar id={setId(`avatar-wrap-${user.userId}`)}>
                <Avatar
                  className={classes.avatar}
                  id={setId(`avatar-${user.userId}`)}
                  src={user.avaUrl}
                >
                  {user.avaUrl ? (
                    ""
                  ) : (
                    <Person id={setId(`avatar-placeholder-${user.userId}`)} />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={getUserShortName(user)}
                id={setId(`name-${user.userId}`)}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }
}

SelectUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,
  onUserSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  filters: PropTypes.object,
};

SelectUserDialog.defaultProps = {
  setId: (id) => `select-user-dialog-${id}`,
  filters: null,
  handleClose: undefined,
};

const styled = withStyles(styles)(SelectUserDialog);

export default translate("SelectUserDialog")(styled);
