import {
  requestPdf,
  clearActiveClaim,
  setWizardStates,
  addActiveClaim,
  clearWizardStates,
} from "../../../actions/claim";
import { requestDocumentPermissionsList } from "../../../actions/permissions";
import { routerActions } from "react-router-redux";

import blobToBase64 from "../../../helpers/blobToBase64";
import getWizardProps from "../../../helpers/getWizardProps";
import * as statuses from "../../../variables/claimStatuses";
import claimCategories from "../../../variables/claimCategories";
import loadClaim from "./loadClaim";
import requestTemplate from "./requestTemplate";

const asyncLoadClaim = async (claimId) => loadClaim(claimId);

export default async function initComponent(match, rootComponent) {
  const {
    claimPermissions,
    activeClaim,
    userId,
    encryptKeys,
    claim: propsClaim,
    dispatch,
  } = getWizardProps();
  const { claimId, stepId } = match.params || {};
  let needPdf = false;

  let claim = propsClaim || activeClaim;
  let permissions = (claimPermissions || {}).list || [];

  if (!claim || claim.id !== claimId) {
    clearWizardStates();
    claim = await asyncLoadClaim(claimId);
    needPdf = !!claim && !!claim.digitalDocumentStaticFile;
    if (claim.category !== 4) {
      permissions = await requestDocumentPermissionsList(claim.id);
    }
    setWizardStates({
      claim,
      origin: JSON.parse(JSON.stringify(claim)),
      rootComponent,
    });
  } else {
    if (!propsClaim && claim.state === statuses.CREATING_STATUS) {
      claim = await asyncLoadClaim(claimId);
      setWizardStates({
        claim,
        origin: JSON.parse(JSON.stringify(claim)),
      });
      if (claim.category !== 4) {
        permissions = await requestDocumentPermissionsList(claim.id);
      }
    }
    setWizardStates({
      claimCommited: false,
      showSignModal: false,
      manifest: null,
      claimSaved: false,
      maxStep: 0,
    });
  }

  if (claim instanceof Error) {
    return setWizardStates({ error: claim.message }).then(clearActiveClaim);
  }

  if (claim.state === statuses.CREATING_STATUS) {
    setWizardStates({ pdf: null });
    needPdf = false;
  }

  if (!claim.id) {
    return dispatch(routerActions.replace(`/${rootComponent}`));
  }

  const { digitalDocumentTemplateId } = claim;

  if (!digitalDocumentTemplateId && claim.state !== statuses.CREATING_STATUS) {
    setWizardStates({ blockScreen: false });
    addActiveClaim(claim);
    return dispatch(routerActions.replace(`/document/${claim.id}`));
  }

  if (
    claim.category !== claimCategories[rootComponent] &&
    claim.state !== statuses.SIGNING_STATUS
  ) {
    if (
      (claim.digitalDocumentStaticFile || claim.scanDocumentLink) &&
      claim.state !== statuses.CREATING_STATUS
    ) {
      return dispatch(routerActions.replace(`/document/${claim.id}`));
    }
    const validRootComponent = Object.keys(claimCategories).find(
      (key) => claimCategories[key] === claim.category
    );
    return dispatch(
      routerActions.replace(
        `/${validRootComponent}/${claim.id}${stepId ? `/${stepId}` : ""}`
      )
    );
  }

  if (claim.state !== statuses.CREATING_STATUS && stepId) {
    return dispatch(routerActions.replace(`/${rootComponent}/${claim.id}`));
  }

  if (claim.state === statuses.DELETING_STATUS && rootComponent !== "trash") {
    return dispatch(routerActions.replace(`/${rootComponent}`));
  }
  const permission = Array.isArray(permissions)
    ? permissions.find((per) => userId === per.userId)
    : {};

  setWizardStates({
    blockScreen: true,
    claim,
    permission,
    permissions,
    encryptionKey: encryptKeys[claim.id],
  });
  if (needPdf) {
    const doc = await requestPdf(claim.id);
    if (doc instanceof Error && claim.state !== statuses.CREATING_STATUS) {
      setWizardStates({
        pdf: null,
        doc: null,
        pdfError: doc.message,
      });
      await requestTemplate(match, rootComponent);
    } else if (doc instanceof Error) {
      setWizardStates({ pdf: null, doc: null });
      await requestTemplate(match, rootComponent);
    }
    const pdf = await blobToBase64(doc);
    setWizardStates({ pdf, doc, pdfError: "" });
    await requestTemplate(match, rootComponent);
  } else {
    await requestTemplate(match, rootComponent, claim);
  }
}
