// @ts-nocheck
import React from "react";

import { Table } from "../../../components";
// import StatusChip from "./StatusChip";

import StatusChip from "./StatusChip";

import statuses from "../../../variables/stateRegistersStatuses";

import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";

export interface RequestTableProps {
  handleClick: (requestId: string) => void;
  pagination: any;
  changeCount: any;
  createSortHandler: any;
  classes: any;
  dataSource: any;
  list: any[];
  // status: any;
  // tableChip: boolean;
  // catStatuses: any;
  t: (s: string, args?: Record<string, any>) => string;
  setId: (s: string) => string;
  // statusId: any;
}

const fields = {
  pagination: true,
  tableFields: [
    {
      key: "reqNumber",
      // sort: true,
      title: "REQ_NUMBER",
      style: "max-width: auto",
      classNames: ["cell", "longCellStateRegisters"],
      // grid: [2, 7, 1, 2],
    },
    {
      key: "updatedAt",
      // sort: true,
      title: "DATE",
      style: "max-width: auto",
      classNames: ["cell", "longCellStateRegisters"],
      // grid: [2, 7, 3, 4],
    },
    {
      key: "caseNumber",
      // sort: true,
      title: "CASE_NUMBER",
      style: "max-width: auto",
      classNames: ["cell", "longCellStateRegisters"],
    },
    {
      key: "text",
      // sort: true,
      title: "TEXT",
      classNames: ["cell", "textCentered", "shortCell"],
      // grid: [2, 7, 5, 6],
    },
    {
      key: "statusId",
      // sort: true,
      title: "DOCUMENT_STATUS",
      classNames: ["cell", "textCentered", "shortCell"],
    },
  ],
  filters: ["updatedAt"],
};

class RequestTable extends React.Component<RequestTableProps> {
  static defaultProps = {
    list: [],
  };

  getText = (item: any, key: string) => {
    const { setId, classes, t } = this.props;
    const { updatedAt, caseNumber, text, statusId } = item;
    switch (key) {
      case "updatedAt":
        return humanDateTimeFormat(updatedAt);
      case "caseNumber":
        return caseNumber;
      case "text":
        return text;
      case "statusId":
        return (
          <StatusChip
            t={t}
            setId={setId}
            classes={classes}
            status={statusId}
            tableChip={true}
            statuses={statuses}
          />
        );
      default:
        return item[key];
    }
  };

  render() {
    const {
      t,
      setId,
      classes,
      dataSource,
      handleClick,
      pagination,
      changeCount,
      createSortHandler,
      list,
    } = this.props;
    return (
      <Table
        classes={classes}
        fields={fields}
        list={list}
        dataSource={dataSource}
        getText={this.getText}
        t={t}
        setId={setId}
        pagination={pagination}
        changeCount={changeCount}
        createSortHandler={createSortHandler}
        onCheckItem={handleClick}
      />
    );
  }
}

export default RequestTable;
