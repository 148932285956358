/**
 * @param {{personalData: PersonalDataState}} state
 * @return {boolean}
 */
export const showAttachesPreviewSelector = (state) =>
  state.personalData.showAttachesPreview;

/**
 * @param {{personalData: PersonalDataState}} state
 * @return {boolean}
 */
export const isAdvocateSelector = (state) => state.personalData.isAdvocate;

/**
 * @param {{personalData: PersonalDataState}} state
 * @return {PersonalDataState}
 */
export const personalDataSelector = (state) => state.personalData;

/**
 * @param {{personalData: PersonalDataState}} state
 * @return {Advocate}
 */
export const advocateSelector = (state) => state.personalData.advocate;

/**
 * @param {{personalData: PersonalDataState}} state
 * @return {DeviceInfo[]}
 */
export const devicesSelector = (state) => state.personalData.devices;

/**
 * @param {{legalEntityData: PersonalDataState}} state
 * @return {PersonalDataState}
 */
export const legalEntityDataStateSelector = (state) => state.legalEntityData;
