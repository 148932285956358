import PropTypes from "prop-types";
import { translate } from "react-translate";
import ButtonBase from "@material-ui/core/ButtonBase";
import { getFormatIcon, getFormat } from "../../helpers/getAttachFormat";

const PreviewButton = ({
  toggleDialog,
  busy,
  classes,
  setId,
  t,
  handleDownload,
  format,
  contentType,
  mimeType,
}) => {
  const SHOW_FORMATS = ["pdf", "video", "audio", "html", "image"];
  const NOT_SHOW_FORMATS = [
    "binary",
    "googleViewDoc",
    "zip",
    "text",
    "unknown",
  ];

  const PREVIEW_FORMAT = getFormat(contentType || mimeType);
  const PREVIEW_ICON = getFormatIcon(contentType || mimeType);

  return (
    <div className={classes.previewBtn}>
      <ButtonBase
        disabled={busy}
        className={classes.downloadBtn}
        onClick={
          SHOW_FORMATS.includes(PREVIEW_FORMAT) &&
          !NOT_SHOW_FORMATS.includes(PREVIEW_FORMAT)
            ? toggleDialog
            : handleDownload
        }
        id={setId("button-base")}
        title={
          format === "unknown" || format === "binary"
            ? `${t("UNKNOWN_FORMAT")} ${
                format === "binary" ? t("DOWNLOAD_ONLY") : t("NOT_SUPPORTED")
              }`
            : t("PREVIEW")
        }
      >
        {PREVIEW_ICON}
      </ButtonBase>
    </div>
  );
};

PreviewButton.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  mimeType: PropTypes.string,
};

export default translate("ClaimList")(PreviewButton);
