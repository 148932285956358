import { DisplayPreviewDialog } from "../../../../components/ShareDialog/types";

interface BaseMeta {
  address: string;
  phone: string;
  email: string;
  isLegal: boolean;
}

export interface LegalPart extends BaseMeta {
  companyName: string;
  edrpou: string;
}

export interface NotLegalPart extends BaseMeta {
  name: string;
  ipn: string;
}

export enum PermissionType {
  Default = 0,
  Order = 1,
  EDRRecipe = 2,
}

export enum PermissionTargetType {
  UNDEFINED = 0,
  CAUSE = 2,
}

export enum OrderIssueredByType {
  ADVOCATE = 0,
  OFFICE = 1,
}

export type OrderAllowedBy = NotLegalPart & LegalPart;

export type SaveOrderBodyMeta = {
  allowedBy: OrderAllowedBy;
  orderNumber: string;
  orderDate: string;
  issueredByType: OrderIssueredByType;
  issueredByName: string;
  issueredByAddress: string;
  contractDate: string;
  contractNumber: string;
  caseNumber?: string;
  workAddress?: string;
};

export interface SaveOrderBody {
  typeId: PermissionType.Order;
  allowShare: false;
  permissionType: "allowCommit";
  userId: string;
  targetType: PermissionTargetType; //якщо не задано номер справи, то не передається або 0, якщо задано - 2
  meta: SaveOrderBodyMeta;
}

export type PreviewDialogType = {
  opened: boolean;
  url: string;
  base64: string;
  display: DisplayPreviewDialog;
  blob: null | Blob;
};
