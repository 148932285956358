/**
 * Service for working with electron digital signature (EDS)
 */
import EDSWorker from "worker-loader!./worker";
import config from "../../config";
import customPassword from "../../helpers/customPassword";
import EdsException from "./EdsException";
// import cas from "./CAs.json";
const cas = undefined;

const { eds } = config();

export default class Signer {
  inited = true;

  proxySettings = {
    useProxy: false,
    anonymous: true,
    address: "",
    port: "3128",
    user: "",
    password: "",
    savePassword: false,
  };

  constructor() {
    // const proxySettings = localStorage.getItem("proxySettings");
    const proxySettings = null;

    if (proxySettings !== null) {
      this.proxySettings = JSON.parse(proxySettings);
    }

    this.worker = new EDSWorker();
    this.execute("init", {
      cas,
      proxySettings: this.proxySettings,
      edsServiceUrl: (eds || {}).signDataUrl,
    }).catch(() => null);
  }

  send = (message) => this.worker.postMessage(message);

  execute(...rest) {
    const commandData = Array.prototype.slice.call(rest);
    const cmd = commandData.shift();
    return new Promise((resolve, reject) => {
      const commandId = customPassword();
      const messageListener = ({ data: result }) => {
        if (result.commandId !== commandId) {
          return;
        }

        this.worker.removeEventListener("message", messageListener, true);
        const { error, data: resultData } = result;

        if (error) {
          reject(new EdsException(error, resultData, "file"));
        } else {
          resolve(resultData);
        }
      };
      this.worker.addEventListener("message", messageListener, false);
      this.send({ cmd, commandId, commandData });
    });
  }
}
