import PropTypes from "prop-types";
import { translate } from "react-translate";

import { withStyles } from "@material-ui/core";
import pdfStyles from "../../variables/styles/pdfDocument";
import attachStyles from "../../variables/styles/attaches";

const styles = {
  ...pdfStyles,
  ...attachStyles,
};

const getText = (text, t) => {
  if (
    text === "404 File not found" ||
    text === "Can't download scan document." ||
    text.includes("Invalid basic header") ||
    text.includes("ot found")
  ) {
    return (
      <span>
        {t("FILE_NOT_FOUND_1")}
        <br />
        {t("FILE_NOT_FOUND_2")}
      </span>
    );
  }
  if (text === "Can't find document or user don't have needed access.") {
    return t("NEED_ACCESS");
  }
  return text;
};

const TextPreview = ({ text, isError, classes, t }) => {
  const data = getText(text, t);

  return (
    <>
      {!isError ? (
        <div className={classes.previewWrap}>
          <div className={classes.previewScrollBox}>
            <div className={classes.previewBox}>
              <div id={"printable-data"} className={classes.previewTextFrame}>
                <pre className={classes.previewText}>{data}</pre>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.errorWrapper}>{text}</div>
      )}
    </>
  );
};

TextPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  t: PropTypes.func.isRequired,
};

TextPreview.defaultProps = {
  text: "",
};

// decorate and export
export default translate("Attach")(withStyles(styles)(TextPreview));
