import React from "react";
// import { useState, useEffect } from 'react';
// import { requestSystemMessage } from '../../actions';
import renderHTML from "react-render-html";
import "./components/Carousel/Carousel.css";
//import { Carousel } from './components/Carousel/Carousel';

const systemDivMessageStyle = {
  backgroundColor: "#FFE358",
  color: "#000000",
  margin: "5px",
};

const systemPMessageStyle = {
  padding: "5px 10px 5px 10px",
  margin: 0,
};

export const SystemMessage = ({ text }) => {
  return (
    // <Carousel>
    //   {/* <div className="div-msg-style" >
    //     <p className="p-msg-style">{renderHTML(text)}TEST MSG FOR HEADER</p>
    //   </div> */}
    //   <div
    //     className="item item-1"
    //     id="first-item">
    //     Item 1
    //   </div>
    //   <div
    //     className="item item-2"
    //     id="second-item">
    //     Item 2
    //   </div>
    //   <div
    //     className="item item-3"
    //     id="third-item">
    //     Item 3
    //   </div>
    // </Carousel>

    // <Carousel>
    //   <Carousel.Page>
    //     <div
    //       className="item item-1"
    //       id="first-item">
    //       Item 1
    //     </div>
    //   </Carousel.Page>
    //   <Carousel.Page>
    //     <div
    //       className="item item-2"
    //       id="second-item">
    //       Item 2
    //     </div>
    //   </Carousel.Page>
    //   <Carousel.Page>
    //     <div
    //       className="item item-3"
    //       id="third-item">
    //       Item 3
    //     </div>
    //   </Carousel.Page>

    // </Carousel>

    <div style={text ? systemDivMessageStyle : {}}>
      <p style={text ? systemPMessageStyle : {}}>{renderHTML(text)}</p>
    </div>
  );
};
