import React from "react";
import PropTypes from "prop-types";
import { Dialog, Slide, Typography } from "@material-ui/core";
import { translate } from "react-translate";
import { Button } from "../../../components";
import EDSForm from "../../../components/EDSForm";

import setComponentsId from "../../../helpers/setComponentsId";
import keyToUint8Array from "../../../helpers/keyToUint8Array";

const { TextDecoder } = window;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class EncryptClaim extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSelectKey = async (encodedKey, signer, resetPrivateKey) => {
    const { permission, onDecrypt } = this.props;
    if (!permission || !onDecrypt) {
      return resetPrivateKey();
    }

    const key = await signer.execute("DevelopData", permission.targetKey);
    await resetPrivateKey();

    const keyData = key.data ? keyToUint8Array(key.data) : key;
    return onDecrypt(new TextDecoder("utf-8").decode(keyData), signer);
  };

  render() {
    const { t, classes, setId, permission } = this.props;

    if (permission === null) {
      return (
        <Typography
          className={classes.encryptedClaimTitle}
          variant="h6"
          id={setId("encrypted-title")}
        >
          {t("NO_ACCESS")}
        </Typography>
      );
    }

    return (
      <div>
        <Typography
          className={classes.encryptedClaimTitle}
          variant="h6"
          id={setId("encrypted-title")}
        >
          {t("ENCRYPTED_CLAIM")}
        </Typography>
        <Button onClick={this.handleOpen} color="yellow">
          {t("UNENCRYPT_CLAIM")}
        </Button>
        <Dialog
          className={classes.encryptForm}
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          scroll="body"
        >
          <EDSForm onSelectKey={this.handleSelectKey} />
        </Dialog>
      </div>
    );
  }
}

EncryptClaim.propTypes = {
  onDecrypt: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  permission: PropTypes.object,
  setId: PropTypes.func,
};

EncryptClaim.defaultProps = {
  setId: setComponentsId("claim-wizard"),
  permission: null,
};
const translated = translate("ClaimWizard")(EncryptClaim);

export default translated;
