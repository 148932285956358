import {
  primaryColor,
  grayColor,
  blackColor,
  lightYellowColor,
} from "../../variables/styles";

export default {
  tableWrapper: {
    maxWidth: "100%",
    overflowX: "auto",
  },
  table: {
    //overflow: "hidden",
  },
  borderRight: {
    paddingRight: "5px",
    // borderRight: "1px dotted #eeeee0",
  },
  notViewed: {
    background: lightYellowColor,
  },
  onlyMobileScreen: {
    display: "none",
  },
  icon: {
    verticalAlign: "middle",
    color: grayColor,
  },
  checkbox: {
    color: blackColor,
  },
  hasHighlight: {
    "& > td": {
      borderBottom: "none",
    },
  },
  highlight: {
    background: grayColor,
  },
  flex: {
    flex: 1,
  },
  subTitle: {
    padding: "10px 5px 27px",
    marginLeft: "-5px",
    display: "flex",
    justifyContent: "space-between",
  },
  row: {
    cursor: "pointer",
    // "&:nth-child(odd)": {
    //   background: "rgba(0, 0, 0, 0.015)",
    // },
    "& em": {
      background: "#fcd700",
      fontStyle: "inherit",
    },
  },
  link: {
    color: primaryColor,
  },
  textCentered: {
    textAlign: "center",
  },
  absolutePosition: {
    position: "absolute",
  },
  w100: {
    width: "100px",
  },
  w50: {
    width: "50px",
  },
  cell: {
    textAlign: "left",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    padding: "4px 12px 4px 12px",
    whiteSpace: "nowrap",
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100px",
    },
  },
  checkboxCell: {
    width: 70,
  },
  totalCount: {
    paddingLeft: 12,
    whiteSpace: "wrap",
  },
  shortCell: {
    maxWidth: "80px",
    "& > div": {
      maxWidth: "calc(100% - 15px)",
    },
  },
  dateTimeCell: {
    //longCellInputDocs dateTimeCellInputDocs
    width: 110,
  },
  dateTimeCellInputDocs: {
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "81px",
    },
  },
  longCell: {
    maxWidth: "300px",
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100px",
    },
  },
  longCellInputDocs: {
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100px",
    },
  },

  // стили для таблицы "Запит до ЕДДР"

  longCellStateRegisters: {
    // eslint-disable-line no-useless-computed-key
    maxWidth: "30px",
  },

  // стили для таблицы "Виконавчі документи"
  longCellExecutiveDocs: {
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100px",
    },
  },
  shortCellExecutiveDocs: {
    "@media (max-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "46px",
    },
  },
  customCellDocNameExecutiveDocs: {
    "@media (max-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "45px",
    },
  },
  customCellCourtNameExecutiveDocs: {
    "@media (max-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "46px",
    },
  },
  customCellPenaltyExecutiveDocs: {
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "60px",
    },
  },
  customCellDocTitleExecutiveDocs: {
    "@media (max-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "46px",
    },
  },
  customCellDocDateExecutiveDocs: {
    "@media (max-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "60px",
    },
  },

  mediumCell: {
    maxWidth: "200px",
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100px",
    },
  },
  verticalAlign: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      display: "flex",
      alignItems: "center",
    },
  },
  pagination: {
    paddingRight: 4,
    "& div": {
      fontSize: "0.75rem",
    },
    "@media (max-width:408px)": {
      // eslint-disable-line no-useless-computed-key
      position: "relative",
    },
  },
  paginationSelect: {
    fontSize: 12,
  },
  onlyMobile: {
    display: "none",
  },
  textRight: {
    textAlign: "right",
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      textAlign: "left",
    },
    "&.textLeft": {
      textAlign: "left",
    },
  },
  textCenter: {
    textAlign: "center",
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      textAlign: "left",
    },
    "&.textLeft": {
      textAlign: "left",
    },
  },
  textLeft: {
    textAlign: "left",
  },
  smallCell: {
    maxWidth: 36,
  },
  centerCell: {
    maxWidth: "none",
    "@media (max-width:1300px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "none",
    },
  },
  selected: {
    background: "#aaaaaa",
  },
  card: {
    margin: 4,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    },
  },
  // Mobile
  "@media (max-width:767px)": {
    // eslint-disable-line no-useless-computed-key
    pagination: {
      left: "0",
      padding: "4px 0",
      "& *": {
        // fontSize: "0.75rem",
      },
    },
    pagSelect: {
      width: 55,
    },
    pagButton: {
      width: 30,
    },
    checkbox: {
      height: "auto",
      width: "auto",
    },
    cell: {
      maxWidth: "none",
      width: "auto",
      border: 0,
      cursor: "pointer",
      paddingLeft: "10px",
      boxSizing: "borderBox",
    },
    mobileBlock: {
      display: "block",
      marginTop: 20,
    },
    mobileGrid: {
      display: "grid",
      // gridTemplateColumns: "16.667% 16.667% 16.667% 16.667% 16.667% 16.667%",
      gridTemplateColumns: "auto 1fr 16.667% 16.667% 16.667% auto",
      border: `1px solid ${grayColor}`,
      height: "auto",
      padding: "10px",
      // margin: "3px 12px",
      margin: "3px",
      marginBottom: 15,
    },
    // grid
    columnStart1: {
      gridColumnStart: 1,
      paddingLeft: 0,
    },
    columnStart2: {
      gridColumnStart: 2,
    },
    columnStart3: {
      gridColumnStart: 3,
    },
    columnStart4: {
      gridColumnStart: 4,
    },
    columnStart5: {
      gridColumnStart: 5,
    },
    columnStart6: {
      gridColumnStart: 6,
    },
    columnEnd2: {
      gridColumnEnd: 2,
    },
    columnEnd3: {
      gridColumnEnd: 3,
    },
    columnEnd4: {
      gridColumnEnd: 4,
    },
    columnEnd5: {
      gridColumnEnd: 5,
    },
    columnEnd6: {
      gridColumnEnd: 6,
    },
    columnEnd7: {
      gridColumnEnd: 7,
    },
    rowStart1: {
      gridRowStart: 1,
    },
    rowStart2: {
      gridRowStart: 2,
    },
    rowStart3: {
      gridRowStart: 3,
    },
    rowStart4: {
      gridRowStart: 4,
    },
    rowStart5: {
      gridRowStart: 5,
    },
    rowStart6: {
      gridRowStart: 6,
    },
    rowEnd2: {
      gridRowEnd: 2,
    },
    rowEnd3: {
      gridRowEnd: 3,
    },
    rowEnd4: {
      gridRowEnd: 4,
    },
    rowEnd5: {
      gridRowEnd: 5,
    },
    rowEnd6: {
      gridRowEnd: 6,
    },
    onlyMobile: {
      display: "block",
    },
    onlyMobileText: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    onlyBigScreen: {
      display: "none",
    },
    onlyMobileScreen: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      zIndex: "999",
      left: "92px",
    },
    textCentered: {
      textAlign: "left",
    },
  },
  "@media (max-width:400px)": {
    // eslint-disable-line no-useless-computed-key
    checkboxCell: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  sortTitle: {
    padding: 0,
  },
  sortTitleEnabled: {
    padding: 0,
  },
  hidden: {
    opacity: 0,
  },
  width100: {
    width: 100,
  },
  width120: {
    width: 120,
  },
  width105: {
    width: 105,
  },
  "width105-sortTitleEnabled": {
    width: 88,
  },
  onlyFullName: {
    padding: "16px 0",
  },
  attachIcon: {
    padding: "12px 12px 0 24px",
    width: 48,
    maxWidth: 48,
    "& svg": {
      transform: "rotate(270deg)",
      height: 36,
    },
  },
  // for executive docs main page with table
  isDeleted: {
    textDecoration: "line-through",
  },
  relative: {
    position: "relative",
  },
  throughLine: {
    height: "1px",
    width: "100%",
    background: "#fff",
    position: "absolute",
    zIndex: "2",
    top: "50%",
  },
};
