import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
// @ts-ignore
import cx from "classnames";
import { BlockScreen, Button } from "../../../components";
import TextField from "@material-ui/core/TextField";
import AuthorityDictionaryControl from "./AuthorityDictionaryControl";
import CheckIcon from "@material-ui/icons/Check";
import { PersonalDataChangedHandler } from "./types";
import RaDictionaryControl from "./RaDictionaryControl";
import { checkLicense } from "../../../actions/advocate";
import { Advocate, CertByType } from "../../../components/withAdvocate";

type Props = {
  handleInterfaceChanged: PersonalDataChangedHandler;
  classes: any;
  t: (s: string) => string;
  firstName: string;
  lastName: string;
  middleName: string;
  advocate: Advocate;
  closeDialog: () => void;
};

type CertBy = Pick<Partial<Advocate>, "certbyid" | "certbytype">;

function AdvocateEditDialog({
  classes,
  t,
  advocate,
  firstName,
  lastName,
  middleName,
  closeDialog,
  handleInterfaceChanged,
}: Props) {
  const [license, setLicense] = useState(advocate.certnum);
  const [raid, setRaid] = useState<number | null>(advocate.raid);
  const [certby, setCertBy] = useState<CertBy>({
    certbyid: advocate.certbyid,
    certbytype: advocate.certbytype,
  });
  const [licenseError, setLicenseError] = useState("");
  const [loading, setLoading] = useState(false);

  const unCheck = () => {
    if (advocate.isChecked) {
      handleInterfaceChanged("advocate", {
        ...advocate,
        isChecked: false,
      });
    }
  };

  const handleCertByChange = (
    args: { id: number; type: CertByType } | undefined
  ) => {
    if (!args) {
      setCertBy({
        certbyid: undefined,
        certbytype: undefined,
      });
      unCheck();
      return;
    }
    const { id, type } = args;
    setCertBy({
      certbyid: id,
      certbytype: type,
    });
    unCheck();
  };

  const handleRaIdChange = (id?: number) => {
    setRaid(id || null);
    unCheck();
  };

  const handleCheck = () => {
    setLoading(true);
    checkLicense({
      firstname: firstName,
      surname: lastName,
      middlename: middleName,
      raid: raid!,
      certbyid: certby.certbyid!,
      certbytype: certby.certbytype!,
      certnum: license,
    })
      .then((response) => {
        if (!response) return;
        if (!response.advocate) {
          return setLicenseError(
            t("NO_INFORMATION_WAS_FOUND_FOR_THE_CERTIFICATE")
          );
        }

        if (response.occupation) {
          return setLicenseError(t("CERTIFICATE_HAS_BEEN_SUSPENDED"));
        }

        handleInterfaceChanged("advocate", {
          isChecked: true,
          ...response,
        });
        setLicenseError("");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      fullWidth={true}
      open
      onClose={closeDialog}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogContentWrappers}>
        {t("ADVOCATE_CERT_VALIDATION")}
      </DialogTitle>

      <div className={classes.contentContainer}>
        <TextField
          className={classes.item}
          label={t("CERTIFICATE_NUMBER")}
          value={license}
          onChange={({ target: { value } }) => {
            setLicense(value);
            setLicenseError("");
            unCheck();
          }}
          error={!!licenseError}
          helperText={licenseError}
        />
        <RaDictionaryControl onChange={handleRaIdChange} value={raid} />
        <AuthorityDictionaryControl
          onChange={handleCertByChange}
          byType={certby.certbytype}
          value={certby.certbyid}
        />
      </div>

      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
      >
        <Button
          variant={"contained"}
          color="yellow"
          disabled={
            !license || !raid || !certby.certbytype || advocate.isChecked
          }
          size={"small"}
          onClick={handleCheck}
        >
          {advocate.isChecked ? (
            <>
              {t("CHECKED")}
              <CheckIcon />
            </>
          ) : (
            t("CHECK")
          )}
        </Button>
        <Button
          variant={"contained"}
          size={"small"}
          color="transparent"
          onClick={closeDialog}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
      <BlockScreen open={loading} />
    </Dialog>
  );
}

const styles = {
  contentContainer: {
    padding: "0 24px",
    display: "flex",
    flexDirection: "column",
  },
  item: {
    marginBottom: 8,
  },
} as const;

export default withStyles(styles)(AdvocateEditDialog);
