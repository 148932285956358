import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IconButton, Checkbox, FormControlLabel } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";

const MyDocsTable = ({ handleChange, removeFile, localFiles, setId, t }) => {
  const getDocTypeFormat = (contentType) => {
    if (contentType.startsWith("audio")) {
      return "Аудіо";
    } else if (contentType.startsWith("video")) {
      return "Відео";
    } else if (contentType.startsWith("image")) {
      return "Зображення";
    } else {
      switch (contentType) {
        case "application/pdf":
          return "PDF документ";
        case "application/octet-stream":
          return "binary";
        case "application/msword":
        case "application/ogg":
        case "officedocument.wordprocessingml.document":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "rtf":
        case "application/rtf":
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "googleViewDoc";
        case "application/zip":
        case "application/x-zip-compressed":
          return "Архів ZIP";
        case "application/gzip":
          return "Архів GZIP";
        case "application/x-rar-compressed":
          return "Архів RAR";
        case "application/x-tar":
          return "Архів TAR";
        case "text/html":
        case "html":
        case "text/xml":
        case "text/xhtml":
        case "text/XHTML":
        case "XHTML":
        case "xhtml":
        case "xml":
          return "html";
        default:
          return "Документ";
      }
    }
  };

  return (
    <div>
      <TableHead>
        <TableRow>
          <TableCell align="left">{t("DOC_TITLE")}</TableCell>
          <TableCell align="center">{t("DOC_TYPE")}</TableCell>
          <TableCell align="right" />
          <TableCell align="center">{t("DOC_CHECKBOX")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {localFiles.map((row, index) => (
          <TableRow
            key={`${row.fileHash}${index}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="center">
              {getDocTypeFormat(row.contentType)}
            </TableCell>
            <TableCell align="center">
              {
                <Fragment>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      removeFile(row.fileLink);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Fragment>
              }
            </TableCell>
            <TableCell align="center">
              <FormControlLabel
                control={
                  <Checkbox
                    id={setId(`doc-checkbox-${index}`)}
                    checked={row.isChecked}
                    onChange={handleChange}
                    color="primary"
                  />
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </div>
  );
};

MyDocsTable.propTypes = {
  handleChange: PropTypes.func.isRequired,
  localFiles: PropTypes.array.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default MyDocsTable;
