function getInfo(authorization: any): UserInfo | undefined {
  return authorization && authorization.info;
}

export type UserInfo = {
  userId: string;
  isLegal: boolean;
};

export const userIdSelector = ({
  authorization: auth,
}: // @ts-ignore
any): string | undefined => getInfo(auth) && getInfo(auth).userId;
export const userInfoSelector = ({
  authorization: auth,
}: any): UserInfo | undefined => getInfo(auth);
