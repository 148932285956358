import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const JudgmentCodeFilterChip = ({ t, value, judgmentForms, ...rest }) => (
  <Chip
    label={`${t("JUDGEMENT_FORMS")}: ${(judgmentForms || [])
      .filter((form) => value.includes(form.id))
      .map((form) => form.name)
      .join(", ")}`}
    {...rest}
  />
);

JudgmentCodeFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  judgmentForms: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

JudgmentCodeFilterChip.defaultProps = {
  value: "",
  judgmentForms: [],
};

const translated = translate("SearchPage")(JudgmentCodeFilterChip);
export default connect(({ dictionary: { judgmentForms } }) => ({
  judgmentForms,
}))(translated);
