import getWizardProps from "../../../../helpers/getWizardProps";

export default function updateArrayValue(value, stepId, claim) {
  const {
    template: { jsonSchema },
  } = getWizardProps();
  const stepProps = jsonSchema.properties[stepId];

  const { digitalDocumentData } = claim;

  let stepData = (digitalDocumentData || {})[stepId] || {};

  if (stepProps.type !== "array") {
    return claim;
  }

  if (Array.isArray(value)) {
    stepData = value;
  } else {
    Object.keys(value).forEach((index) => {
      if (
        stepProps.items.type === "object" &&
        value[index] &&
        Object.keys(value[index]).length &&
        stepId !== "attaches"
      ) {
        return Object.keys(value[index])
          .filter((key) => {
            return (
              !stepProps.items.properties[key] ||
              !stepProps.items.properties[key].readonly
            );
          })
          .forEach((key) => {
            value[index][key] =
              value[index][key] || typeof value[index][key] === "number"
                ? value[index][key]
                : undefined;
            stepData[index] = {
              ...stepData[index],
              [key]: value[index][key],
            };
          });
      }

      stepData[index] = value[index];
    });
    stepData = Object.values(stepData).filter((val) => val !== undefined);
  }

  return Promise.resolve({
    ...claim,
    digitalDocumentData: { ...digitalDocumentData, [stepId]: stepData },
  });
}
