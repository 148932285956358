import * as api from "../services/api";
import store from "../store";

export const GET_LEGAL_ENTITY = "GET_LEGAL_ENTITY";
export const PUT_LEGAL_ENTITY = "PUT_LEGAL_ENTITY";
export const GET_LEGAL_SETTINGS = "GET_LEGAL_SETTINGS";
export const PUT_LEGAL_SETTINGS = "PUT_LEGAL_SETTINGS";
export const SET_LEGAL_SETTINGS = "SET_LEGAL_SETTINGS";
const CHECK_LEGAL_ENTITY = "CHECK_LEGAL_ENTITY";

// const {dispatch} = store;
const USERS_URL = "api/users";

export function getLegalEntity() {
  const {
    info: { edrpou },
  } = store.getState().authorization;
  return api.get(
    `${USERS_URL}/legal-entity/${edrpou}`,
    GET_LEGAL_ENTITY,
    store.dispatch
  );
}

export function putLegalEntity(legalEntityInfo) {
  const {
    info: { edrpou },
  } = store.getState().authorization;
  return api.put(
    `${USERS_URL}/legal-entity/${edrpou}`,
    legalEntityInfo,
    PUT_LEGAL_ENTITY,
    store.dispatch
  );
}

export function checkLegalEntityUserRole(edrpou) {
  return api.post(
    `${USERS_URL}/legal-entity/${edrpou}/check-user-role`,
    {},
    CHECK_LEGAL_ENTITY,
    store.dispatch
  );
}

export function getLegalEntitySettings() {
  const {
    info: { edrpou },
  } = store.getState().authorization;
  return api.get(
    `${USERS_URL}/legal-entity-settings/${edrpou}`,
    GET_LEGAL_SETTINGS,
    store.dispatch
  );
}

export function putLegalEntitySettings(settings) {
  const {
    info: { edrpou },
  } = store.getState().authorization;
  return api.put(
    `${USERS_URL}/legal-entity-settings/${edrpou}`,
    { settings },
    PUT_LEGAL_SETTINGS,
    store.dispatch
  );
}

export function setLegalEntitySettings(name, value) {
  const legalEntityData = {
    ...store.getState().legalEntityData,
    [name]: value,
  };
  return store.dispatch({
    type: SET_LEGAL_SETTINGS,
    payload: legalEntityData,
  });
}
