import { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import PropTypes from "prop-types";
import { translate } from "react-translate";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import { PrintOutlined, OpenInNew, SaveAlt, Print } from "@material-ui/icons";
import print from "print-js";
import cx from "classnames";

import { handlePrintHistory } from "./modules/handlePrintHistory";

import { Button } from "../../components";
import customInputStyle from "../../variables/styles/customInputStyle";

import PdfDocument from "../../components/PDF";
import IMGPreview from "../../components/IMG";
// import DOCPreview from "../../components/DOC";
import UnknownFormat from "../../components/UnknownFormat";
import HTMLPreview from "../../components/HTMLPreview";
import TextPreview from "../../components/TextPreview";
import Media from "../../components/Media";
import styles from "../../variables/styles/PreviewDialog";
const blankFormats = ["pdf", "video", "audio", "image"];

const handleDownloadText = (text) => () => {
  let pom = document.createElement("a");
  pom.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  pom.setAttribute("download", "download.txt");

  if (document.createEvent) {
    let event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
};

const handlePrintText = () => print("printable-data", "html");

const PreviewDialog = ({
  t,
  classes,
  setId,
  name,
  toggleDialog,
  handleDownload,
  url,
  preview,
  doc,
  openDialog,
  file,
  format,
  text,
  printHistory,
  isError,
  pdfZipDownloadCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  useEffect(() => {
    if (openDialog && !isError) {
      setLoading(true);
    }
  }, [openDialog]);

  useEffect(() => {
    if (file || preview || isError) {
      setLoading(false);
    }
  }, [file, preview, isError]);

  const downloadZipPdf = async () => {
    try {
      setIsFileLoading(true);
      if (pdfZipDownloadCallback) {
        await pdfZipDownloadCallback();
      }
    } catch (error) {
      if (error instanceof Error) {
        return alert(error.serverMessage + " " + error.details);
      }
    } finally {
      setIsFileLoading(false);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={toggleDialog}
      aria-labelledby={setId("title")}
      id={setId("")}
      className={cx(classes.dialog, classes.smWidth)}
    >
      <DialogTitle
        id={setId("title")}
        className={classes.dialogContentWrappers}
      >
        <div className={classes.flexBox}>
          <span className={classes.dialogTitle}>{name}</span>
          {format === "pdf" && url && (
            <IconButton
              color="inherit"
              title={t("PRINT")}
              onClick={() => {
                print({
                  printable: url,
                  type: "pdf",
                });
              }}
            >
              <PrintOutlined />
            </IconButton>
          )}
          {format === "pdf" && pdfZipDownloadCallback && (
            <IconButton
              color="inherit"
              onClick={downloadZipPdf}
              title={t("SAVE_TO_DEVICE")}
              className={classes.menuButton}
            >
              <SaveAlt />
            </IconButton>
          )}
          {file && blankFormats.includes(format) && url && (
            <IconButton
              color="inherit"
              title={t("PREVIEW_LINK")}
              onClick={() => window.open(url, "_blank")}
            >
              <OpenInNew />
            </IconButton>
          )}

          {format === "text" && !isError && text && (
            <IconButton
              color="inherit"
              onClick={handleDownloadText(text)}
              title={t("SAVE_TO_DEVICE")}
              className={classes.menuButton}
            >
              <SaveAlt />
            </IconButton>
          )}

          {format === "html" && (
            <IconButton
              color="inherit"
              className={classes.menuButton}
              title={t("PRINT")}
              onClick={() => handlePrintHistory(printHistory)}
            >
              <Print />
            </IconButton>
          )}

          {format === "text" && !isError && text && (
            <IconButton
              color="inherit"
              className={classes.menuButton}
              title={t("PRINT")}
              onClick={handlePrintText}
            >
              <Print />
            </IconButton>
          )}
        </div>
      </DialogTitle>
      {isFileLoading && <LinearProgress className={classes.progress} />}

      <DialogContent
        className={cx(classes.content, classes.dialogContentWrappers)}
        id={setId("content")}
      >
        {loading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress
              className={classes.fileImage}
              id={setId("circular-progress")}
            />
          </div>
        )}

        <>
          {(format === "video" || format === "audio") && (
            <Media
              setId={(elementName) => setId(`media-preview-${elementName}`)}
              handleDownload={handleDownload}
              format={format}
              name={name}
              url={url}
            />
          )}
          {format === "pdf" && (
            <PdfDocument
              pdf={doc}
              setId={(elementName) => setId(`pdf-preview-${elementName}`)}
              doc={file}
              fileName={name}
              modal={true}
            />
          )}
          {format === "image" && (
            <IMGPreview
              setId={(elementName) => setId(`img-preview-${elementName}`)}
              imageUrl={preview}
              fileName={name}
              handleDownload={handleDownload}
            />
          )}
          {/* {format === "googleViewDoc" && (
            <DOCPreview
              setId={(elementName) => setId(`doc-preview-${elementName}`)}
              docUrl={url}
              fileName={name}
              handleDownload={handleDownload}
            />
          )} */}
          {format === "html" && (
            <div className={classes.htmlPreviewWrapper}>
              <HTMLPreview
                setId={(elementName) => setId(`doc-preview-${elementName}`)}
                fileName={name}
                handleDownload={handleDownload}
                file={file}
                url={url}
                modal={true}
              />
            </div>
          )}
          {(format === "unknown" || format === "binary") && (
            <UnknownFormat
              itIsBinary={format === "binary"}
              setId={(elementName) => setId(`unknown-preview-${elementName}`)}
              handleDownload={handleDownload}
            />
          )}
          {format === "text" && <TextPreview text={text} isError />}
        </>
        {/* )} */}
      </DialogContent>

      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
        id={setId("actions")}
      >
        <Button
          color="yellow"
          onClick={toggleDialog}
          setId={(elementName) => setId(`close-${elementName}`)}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreviewDialog.propTypes = {
  printHistory: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  doc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  name: PropTypes.string,
  preview: PropTypes.string.isRequired,
  handleDownload: PropTypes.func.isRequired,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string.isRequired,
};

PreviewDialog.defaultProps = {
  doc: null,
  file: null,
  name: "Документ",
};

const styled = withStyles({ ...customInputStyle, ...styles })(PreviewDialog);

export default translate("Elements")(styled);
