import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { setAuthError } from "../../actions/auth";

class AuthDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      code: "",
      open: true,
    };
  }

  formatErrorMessages(auth) {
    if (!auth || !auth.info) {
      return "";
    }
    const { t } = this.props;
    const agentType = auth.info.isLegalEntityHead ? "керівник" : "представник";
    let message = t(auth.authError, {
      companyName: auth.info.companyName,
      agentType,
    });

    if (auth.authError === "E_ORG_NOT_REGISTERED") {
      message += auth.info.isLegalEntityHead
        ? t("REGISTER_COMPANY_HEAD", { companyName: auth.info.companyName })
        : t("REGISTER_COMPANY_AGENT");
    }
    return message;
  }

  render() {
    //const { open } = this.state;
    const {
      authInfo,
      //t,
      handleLogout,
      handleLegalEntityAgentClick,
      handleIndividualClick,
      handleRegCompany,
      handleRegProfile,
      open,
    } = this.props;

    return (
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">{"Увага!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.formatErrorMessages(authInfo)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id={"auth-dialog-logout-btn"}
            onClick={() => {
              handleLogout();
            }}
            color="transparent"
          >
            Вийти
          </Button>
          {authInfo && authInfo.authError !== "E_USER_NOT_REGISTERED" && (
            <Button
              id={"auth-dialog-individual-btn"}
              onClick={() => {
                setAuthError(null);
                handleIndividualClick();
              }}
              color="yellow"
            >
              Продовжити як "Фізична особа"
            </Button>
          )}
          {authInfo &&
            authInfo.authError === "E_USER_NOT_REGISTERED" &&
            authInfo?.info?.legalEntityId && (
              <Button
                id={"auth-dialog-legalEntityAgent-btn"}
                onClick={() => {
                  setAuthError(null);
                  handleLegalEntityAgentClick();
                }}
                color="yellow"
              >
                Продовжити як "Представник організації"
              </Button>
            )}
          {authInfo &&
            authInfo.info &&
            authInfo.info.isLegalEntityHead &&
            authInfo.authError !== "E_USER_NOT_REGISTERED" && (
              <Button
                id={"auth-dialog-regLegalEntityAgent-btn"}
                onClick={() => {
                  setAuthError(null);
                  handleRegCompany();
                }}
                color="yellow"
              >
                Зареєструвати
              </Button>
            )}
          {authInfo && authInfo.authError === "E_USER_NOT_REGISTERED" && (
            <Button
              id={"auth-dialog-regIndividual-btn"}
              onClick={() => {
                setAuthError(null);
                handleRegProfile();
              }}
              color="yellow"
            >
              Зареєструватись
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
AuthDialog.propTypes = {
  t: PropTypes.func.isRequired,
  authInfo: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleLegalEntityAgentClick: PropTypes.func.isRequired,
  handleIndividualClick: PropTypes.func.isRequired,
  handleRegProfile: PropTypes.func.isRequired,
  handleRegCompany: PropTypes.func.isRequired,
  // testDialog: PropTypes.bool.isRequired,
  // history: PropTypes.object.isRequired,
  // text: PropTypes.string.isRequired,
};
export default AuthDialog;
