import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../helpers/setComponentsId";
import {
  withStyles,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Edit, Close, Check } from "@material-ui/icons";

import tasksStyle from "../../variables/styles/tasksStyle.jsx";

class Tasks extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
  };
  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };
  render() {
    const { classes, tasksIndexes, tasks, setId } = this.props;
    return (
      <Table className={classes.table} id={setId("table")}>
        <TableBody id={setId("table-body")}>
          {tasksIndexes.map((value) => (
            <TableRow
              key={value}
              className={classes.tableRow}
              id={setId(`table-row-${value}`)}
            >
              <TableCell
                className={classes.tableCell}
                id={setId(`table-row-${value}-cell-checkbox`)}
              >
                <Checkbox
                  checked={this.state.checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  id={setId(`table-row-${value}-checkbox`)}
                  color="default"
                  onClick={this.handleToggle(value)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                  }}
                />
              </TableCell>
              <TableCell
                className={classes.tableCell}
                id={setId(`table-row-${value}-cell-value`)}
              >
                {tasks[value]}
              </TableCell>
              <TableCell
                className={classes.tableActions}
                id={setId(`table-row-${value}-cell-actions`)}
              >
                <Tooltip
                  title="Edit Task"
                  placement="top"
                  id={setId(`table-row-${value}-edit-tooltip`)}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                    id={setId(`table-row-${value}-edit-button`)}
                  >
                    <Edit
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Remove"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                  id={setId(`table-row-${value}-close-tooltip`)}
                >
                  <IconButton
                    aria-label="Close"
                    className={classes.tableActionButton}
                    id={setId(`table-row-${value}-close-button`)}
                  >
                    <Close
                      className={
                        classes.tableActionButtonIcon + " " + classes.close
                      }
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

Tasks.propTypes = {
  classes: PropTypes.object.isRequired,
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node),
  checkedIndexes: PropTypes.array,
  setId: PropTypes.func,
};

Tasks.defaultProps = {
  tasksIndexes: [],
  tasks: [],
  checkedIndexes: [],
  setId: setComponentsId("tasks"),
};

export default withStyles(tasksStyle)(Tasks);
