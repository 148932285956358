import * as api from "../services/api";
import store from "../store";

export const REQUEST_PERSONAL_DATA = "REQUEST_PERSONAL_DATA";
export const PUT_PERSONAL_DATA = "PUT_PERSONAL_DATA";
export const PUT_MY_DOC = "PUT_MY_DOC";
export const PERSONAL_DATA_UPDATE = "PERSONAL_DATA/UPDATE";
export const REQUEST_MY_DOCS = "REQUEST_MY_DOCS";
export const UPLOAD_MY_DOCS = "UPLOAD_MY_DOCS";
export const GET_DOC_LINK = "GET_DOC_LINK";
export const GET_DOC_PREVIEW = "GET_DOC_PREVIEW";

const USERS_URL = "api/users";

export function loadPersonalData() {
  const state = store.getState();
  const userId = state.authorization.info && state.authorization.info.userId;
  return api.get(
    `api/users/${userId}/settings`,
    REQUEST_PERSONAL_DATA,
    store.dispatch
  );
}

export async function addMyDoc(file) {
  const result = await api.upload(
    `${USERS_URL}/file`,
    file,
    {
      file_name: file.name,
      content_type: file.type,
      user_file_name: file.userFileName || file.name,
      name: file.userFileName || file.name,
    },
    UPLOAD_MY_DOCS,
    store.dispatch
  );
  return result;
}

export function requestDocsMeta() {
  return api.get(`${USERS_URL}/files`, REQUEST_MY_DOCS, store.dispatch);
}

export function previewMyDoc(fileLink) {
  return api.get(
    `${USERS_URL}/file?file_link=${fileLink}`,
    GET_DOC_PREVIEW,
    store.dispatch
  );
}

export async function putMyDoc(file) {
  return api.put(
    `${USERS_URL}/file?file_link=${file.fileLink}`,
    file,
    PUT_MY_DOC,
    store.dispatch
  );
}

export function delMyDoc(fileLink) {
  return api.del(
    `${USERS_URL}/file?file_link=${fileLink}`,
    GET_DOC_LINK,
    store.dispatch
  );
}

/**
 * @param {PersonalDataState} personalData
 */
export const updatePersonData = async (personalData) => {
  const {
    info: { userId },
  } = store.getState().authorization;
  await api.put(
    `api/users/${userId}/settings`,
    { settings: personalData },
    PUT_PERSONAL_DATA,
    store.dispatch
  );

  return store.dispatch({
    type: PERSONAL_DATA_UPDATE,
    payload: personalData,
  });
};
