import React from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core";

import {
  requestRegions,
  requestCourts,
  requestCourtTypes,
} from "../../../actions/dictionary";
import { updateClaimCourt } from "../../../actions/claim";
import customInputStyle from "../../../variables/styles/customInputStyle";

import getAllCourts from "../../../helpers/getAllCourts";
import {
  renderField,
  renderRegionSelect,
  renderCourtTypeSelect,
  renderCourtSelect,
  renderCourtChilds,
  render,
} from "./SelectCourtRenders.jsx";

const DEFAULT_REGION = 1;
const DEFAULT_COURT_TYPE = 1;

const initialState = {
  regionId: "",
  courtTypeId: "",
  subCourt: "",
  activeCourt: "",
  jurisdictionToCourt: null,
};

class SelectCourt extends React.Component {
  state = initialState;

  componentDidMount() {
    const { regions, courts, courtTypes, value } = this.props;
    if (!regions) requestRegions();
    if (!courts) requestCourts();
    if (!courtTypes) requestCourtTypes();
    if (!value) {
      this.clearActiveCourt();
      const allCourts = getAllCourts(this.props);
      if (allCourts.length === 1) {
        this.handleChange({
          target: { name: "activeCourt", value: allCourts[0].id },
        });
      }
    } else {
      this.showActiveCourt(value);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { activeCourt } = state;
    const { courts, value } = props;
    if (!value || value === activeCourt) {
      return;
    }
    const filterCourt =
      courts.filter((court) => court.id === value).shift() || {};
    const { regionId, courtTypeId } = filterCourt;
    return {
      ...state,
      regionId,
      courtTypeId,
      activeCourt: value,
      jurisdictionToCourt: null,
    };
  }

  checkCourtExists = () => {
    const { onChange, value } = this.props;
    const { regionId, courtTypeId } = this.state;
    const courts = this.getCourts(regionId, courtTypeId);
    const filterCourt = courts.filter((court) => court.id === value).shift();
    if (!filterCourt) {
      onChange && onChange("");
    }
  };

  handleChange = ({ target }) => {
    const { onChange, courts, actions, document: doc, stepId } = this.props;
    const { name, value } = target;
    let singleCourt = null;

    if (!value) {
      this.setState({
        [name]: "",
        activeCourt: "",
        subCourt: "",
        jurisdictionToCourt: null,
      });
      return onChange(value);
    }

    if (name === "subCourt") {
      this.setState({ [name]: value });
      onChange && onChange(value);
      return stepId === "court" && actions.updateClaimCourt(doc.id, value);
    }

    if (name === "activeCourt") {
      singleCourt = courts.find(({ id }) => id === value);
    }

    const stateData = { ...this.state, [name]: value };

    if (name === "regionId") {
      stateData.courtTypeId = "";
    }

    let { regionId, courtTypeId } = stateData;

    const types = this.getTypes(regionId);
    if (types.length === 1 && !!types[0]) {
      stateData.courtTypeId = types[0].id;
    }

    if (!singleCourt) {
      const gettingCourts = this.getCourts(regionId, courtTypeId);
      if (gettingCourts.length === 1) {
        singleCourt = gettingCourts.shift();
      }
    }

    if (singleCourt) {
      regionId = singleCourt.regionId || 1;
      courtTypeId = singleCourt.courtTypeId || 2;
      if (singleCourt.jurisdictionTo) {
        const jurisdictionToCourt = courts.find(
          (court) => court.id === singleCourt.jurisdictionTo
        );
        if (jurisdictionToCourt) {
          return this.setState({
            subCourt: "",
            activeCourt: "",
            jurisdictionToCourt,
          });
        }
      }

      this.setState({
        regionId,
        courtTypeId,
        activeCourt: singleCourt.id,
        jurisdictionToCourt: null,
      });
      onChange && onChange(singleCourt.id);
      return (
        stepId === "court" && actions.updateClaimCourt(doc.id, singleCourt.id)
      );
    }

    return this.setState(stateData, this.checkCourtExists);
  };

  getRegions() {
    const { regions } = this.props;
    return Array.isArray(regions)
      ? regions.filter(
          (region) =>
            getAllCourts(this.props)
              .map((court) => court.regionId === region.id)
              .filter(Boolean).length > 0
        )
      : [];
  }

  getTypes(regionId) {
    const courts = this.getCourts(regionId);

    const { courtTypes } = this.props;
    return Array.isArray(courtTypes)
      ? courtTypes.filter(
          (type) =>
            courts.filter((court) => court.courtTypeId === type.id).length > 0
        )
      : [];
  }

  getCourts(regionId, courtTypeId) {
    return getAllCourts(this.props).filter((court) => {
      let acceptRegion = true;
      let acceptType = true;
      if (regionId && regionId !== DEFAULT_REGION) {
        acceptRegion = court.regionId === regionId;
      }

      if (courtTypeId && courtTypeId !== DEFAULT_COURT_TYPE) {
        acceptType = court.courtTypeId === courtTypeId;
      }

      return acceptRegion && acceptType;
    });
  }

  showActiveCourt(value) {
    const { regionId, courtTypeId } = this.state;
    const court = this.getCourts(regionId, courtTypeId).find(
      ({ id }) => id === value
    );

    let subCourt = "";
    let activeCourt = value;

    if (court && court.parentId !== null) {
      subCourt = value;
      activeCourt = court.parentId;
    }

    this.setState({ subCourt, activeCourt });
  }

  clearActiveCourt = () => this.setState({ subCourt: "", activeCourt: "" });

  renderField = renderField.bind(this);
  renderRegionSelect = renderRegionSelect.bind(this);
  renderCourtTypeSelect = renderCourtTypeSelect.bind(this);
  renderCourtSelect = renderCourtSelect.bind(this);
  renderCourtChilds = renderCourtChilds.bind(this);
  render = render.bind(this);
}

SelectCourt.propTypes = {
  regions: PropTypes.array,
  courts: PropTypes.array,
  courtTypes: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
  actions: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  stepId: PropTypes.string.isRequired,
};

SelectCourt.defaultProps = {
  regions: null,
  courts: null,
  courtTypes: null,
  value: null,
  onChange: undefined,
};

const styled = withStyles(customInputStyle)(SelectCourt);
const translated = translate("Elements")(styled);

const mapStateToProps = ({ dictionary: { regions, courts, courtTypes } }) => ({
  regions,
  courts,
  courtTypes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateClaimCourt: bindActionCreators(updateClaimCourt, dispatch),
  },
});

// decorate and export
export default connect(mapStateToProps, mapDispatchToProps)(translated);
