import React from "react";
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";
import setComponentsId from "../../helpers/setComponentsId";

import profileCardStyle from "../../variables/styles/profileCardStyle";

const ProfileCard = ({
  classes,
  subtitle,
  title,
  description,
  footer,
  avatar,
  setId,
}) => (
  <Card className={classes.card} id={setId("")}>
    <CardHeader
      id={setId("header")}
      classes={{
        root: classes.cardHeader,
        avatar: classes.cardAvatar,
      }}
      avatar={
        <img
          src={avatar}
          alt="..."
          className={classes.img}
          id={setId("avatar")}
        />
      }
    />
    <CardContent className={classes.textAlign} id={setId("content")}>
      {subtitle && (
        <Typography
          component="h6"
          className={classes.cardSubtitle}
          id={setId("subtitle")}
        >
          {subtitle}
        </Typography>
      )}
      {title && (
        <Typography
          component="h4"
          className={classes.cardTitle}
          id={setId("title")}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          component="p"
          className={classes.cardDescription}
          id={setId("description")}
        >
          {description}
        </Typography>
      )}
    </CardContent>
    <CardActions
      className={cx(classes.textAlign, classes.cardActions)}
      id={setId("actions")}
    >
      {footer}
    </CardActions>
  </Card>
);

ProfileCard.propTypes = {
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  footer: PropTypes.node,
  avatar: PropTypes.string,
};

ProfileCard.defaultProps = {
  subtitle: "",
  description: "",
  title: "",
  footer: "",
  avatar: "",
  setId: setComponentsId("profile-card"),
};

export default withStyles(profileCardStyle)(ProfileCard);
