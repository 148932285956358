import React from "react";
import Document from "./components/Document";

const { atob } = window;

class RegistryDocument extends React.Component {
  render() {
    let {
      match: {
        params: { documentId },
      },
    } = this.props;

    try {
      documentId = atob(documentId);
    } catch (e) {
      // Nothing to do
    }

    return <Document paper={true} documentId={documentId} />;
  }
}

export default RegistryDocument;
