import { JsonSchema, StringSchema } from "../../../types/json-schema";

export class Formatter {
  static formatDate(value: string): string {
    if (!value) return "не визначено";
    const date = new Date(value);
    const d = date.getDate();
    const m = date.getMonth() + 1;
    return `${d < 10 ? "0" : ""}${d}.${
      m < 10 ? "0" : ""
    }${m}.${date.getFullYear()}`;
  }

  static formatDateTime(value: string) {
    if (!value) return "не визначено";
    const date = new Date(value);
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const h = date.getHours();
    const mi = date.getMinutes();
    return `${d < 10 ? "0" : ""}${d}.${
      m < 10 ? "0" : ""
    }${m}.${date.getFullYear()} ${h < 10 ? "0" : ""}${h}:${
      mi < 10 ? "0" : ""
    }${mi}`;
  }

  static formatStringDefault(value: string, schema: StringSchema): string {
    if (!value) return value;
    if (schema.maxLength) value = value.substring(0, schema.maxLength);
    return value;
  }

  static formatString(value: string, schema: StringSchema): string {
    switch (schema.format) {
      case "date":
        return this.formatDate(value);
      case "date-time":
        return this.formatDateTime(value);
      default:
        return this.formatStringDefault(value, schema);
    }
  }

  static formatValue(value: any, schema: JsonSchema): any {
    if (typeof value === "undefined") return value;
    switch (schema.type) {
      case "string":
        return Formatter.formatString(value, schema);
      default:
        return value;
    }
  }
}
