import React from "react";
import PropTypes from "prop-types";

const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

ValueContainer.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

ValueContainer.defaultProps = {
  children: "",
};

export default ValueContainer;
