import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DialogContentText } from "@material-ui/core";
import { CustomInput } from "../../components";

const ShareDialogChoiseUser = ({
  classes,
  t,
  setId,
  error,
  clearINN,
  INN,
  onChangeINN,
  foundUser,
}) => (
  <Fragment>
    <CustomInput
      labelText={t("INN")}
      id={setId("inn-input")}
      formControlProps={{ fullWidth: true }}
      error={!!error}
      onClear={clearINN}
      helperText={error || t("DESCRIPTION")}
      showErrors={true}
      success={!error}
      inputProps={{
        value: INN,
        onChange: onChangeINN,
        error: !!error,
      }}
    />
    {foundUser && (
      <DialogContentText
        className={classes.contentText}
        id={setId("content-text")}
      >
        {foundUser.name}
      </DialogContentText>
    )}
  </Fragment>
);

ShareDialogChoiseUser.propTypes = {
  error: PropTypes.string.isRequired,
  INN: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  foundUser: PropTypes.object,
  clearINN: PropTypes.func.isRequired,
  onChangeINN: PropTypes.func.isRequired,
};

ShareDialogChoiseUser.defaultProps = {
  foundUser: null,
};

export default ShareDialogChoiseUser;
