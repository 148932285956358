import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";

const Option = ({ innerRef, isFocused, isSelected, children, innerProps }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
      whiteSpace: "normal",
      minHeight: 25,
      height: "auto",
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

Option.propTypes = {
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
};

Option.defaultProps = {
  innerRef: () => null,
  innerProps: {},
  isFocused: false,
  isSelected: false,
  children: "",
};

export default Option;
