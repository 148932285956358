import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { translate } from "react-translate";
import { connect } from "react-redux";

import {
  MobileStepper,
  Stepper,
  Step,
  StepButton,
  withStyles,
  Hidden,
} from "@material-ui/core";
import { handleStep } from "../decorators/stepActions";

const styles = {
  mobileStepper: {
    flexGrow: 1,
    "& > div": {
      margin: "0 auto 13px",
    },
  },
  step: {
    boxSizing: "border-box",
    wordWrap: "break-word",
    padding: "0 4px",
    "& span": {
      maxWidth: "100%",
    },
  },
  stepText: {
    display: "inline-block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};

const getText = (classes, description, stepId) =>
  description.split(" ").map((word) => (
    <span className={classes.stepText} key={`${stepId}-${word}`}>
      {word}&nbsp;
    </span>
  ));

const ClaimStepper = ({
  classes,
  activeStepId,
  steps,
  properties,
  setId,
  busy,
  t,
  maxStep,
}) => {
  const actualSteps = steps.filter((stepId) => properties[stepId]);
  return (
    <div id={setId("")}>
      <Hidden smDown={true} implementation="css">
        <Stepper alternativeLabel={true} activeStep={activeStepId}>
          {actualSteps.map((stepId, index) => (
            <Step
              key={stepId}
              title={busy ? t("LOADING") : properties[stepId].description}
              className={classes.step}
              style={{ maxWidth: `${100 / actualSteps.length}%` }}
            >
              <StepButton
                onClick={handleStep(stepId, index)}
                disabled={busy || index > maxStep}
              >
                {getText(classes, properties[stepId].description, stepId)}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <Hidden mdUp={true}>
        <MobileStepper
          variant="dots"
          steps={actualSteps.length}
          position="static"
          activeStep={activeStepId}
          className={classes.mobileStepper}
        />
      </Hidden>
    </div>
  );
};

ClaimStepper.propTypes = {
  activeStepId: PropTypes.number.isRequired,
  steps: PropTypes.array,
  properties: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  busy: PropTypes.bool,
  t: PropTypes.func.isRequired,
  maxStep: PropTypes.number.isRequired,
};

ClaimStepper.defaultProps = {
  steps: [],
  properties: null,
  setId: setComponentsId("claim-stepper"),
  busy: false,
};

const styled = withStyles(styles)(ClaimStepper);
const translated = translate("ClaimWizard")(styled);

function mapStateToProps(state) {
  return { templates: state.claim.templates };
}

// decorate and export
export default connect(mapStateToProps)(translated);
