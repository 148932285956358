import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { FormControl, withStyles } from "@material-ui/core";

import setComponentsId from "../../../helpers/setComponentsId";
import { BlockScreen, Button } from "../../../components";
import AttachList from "../../../components/Attach/AttachList";

import styles from "../../../variables/styles/attachesWizardStep";
import { UploadMultipleFilesDialog } from "../../UploadMultipleFiles";

class SelectFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // признак что модалка открыта
      openUploadModal: false,
      // признак того что идет сохранение на беке
      saving: false,
      //локальные файлы - до сохранения на беке
      localFiles: [],
    };
  }

  componentDidMount() {
    const { value, onChange } = this.props;
    onChange(value);
  }

  openUploadModal = () => this.setState({ openUploadModal: true });
  closeUploadModal = () =>
    this.setState({
      openUploadModal: false,
      saving: false,
      localFiles: [],
    });
  savingStart = () => this.setState({ saving: true });
  savingEnd = () => this.setState({ saving: false });

  onSave = async () => {
    const { value, onChange } = this.props;
    if (!onChange) {
      return;
    }

    //запускаем лоадер
    this.savingStart();

    /**
     * Выполняется сохранение текущих локальных файлов.
     * acceptedFiles - массив модифицированных объектов файлов которые успешно сохранились на беке
     * rejectedFiles - массива с объектами типа Files, которые почему-то не сохранились
     */
    const { acceptedFiles, rejectedFiles } =
      (await this.props.actions.uploadAttach(this.state.localFiles)) || {};

    // если нет сохраненных файлов - просто закрыть лоадер
    if (!acceptedFiles.length) {
      return this.savingEnd();
    }

    // вызывается обновление всей заявы - прикрепление файлов в к документу
    onChange(
      [
        ...Object.values(value),
        ...acceptedFiles.map((file) => ({
          attachId: file.attachId,
          fileLink: file.uploadedFileLink || file.fileLink || file.link,
          name: file.name,
          fileName: file.name,
          size: file.size,
          mimeType: file.mimeType,
          contentType: file.contentType,
          userFileName: file.name,
        })),
      ],
      true
    );

    // если есть отклоненные файлы, просто оставим их в попапе
    if (rejectedFiles.length) {
      return this.setState({
        localFiles: rejectedFiles,
        saving: false,
      });
    }

    this.closeUploadModal();
  };

  handleDeleteLocalFiles = (index) => {
    this.setState(({ localFiles }) => {
      return {
        localFiles: localFiles.filter((_, i) => i !== index),
      };
    });
  };

  handleAddedLocalFiles = (addedFiles) => {
    this.setState(({ localFiles }) => {
      return {
        localFiles: localFiles.concat(addedFiles),
      };
    });
  };

  handleRenameFile = (index, newName) => {
    this.setState(({ localFiles }) => {
      return {
        localFiles: localFiles.map((file, i) => {
          if (i === index) {
            return new File([file], newName, {
              type: file.type,
              lastModified: file.lastModified,
            });
          }
          return file;
        }),
      };
    });
  };

  handleDelete = (index) => () => {
    let { value } = this.props;
    const { onChange, actions } = this.props;
    const { deleteAttach } = actions || {};
    value = Object.values(value);

    const { attachId } = value.splice(index, 1).shift();

    if (attachId && deleteAttach) {
      deleteAttach(attachId);
    }
    onChange(value, true);
  };

  render() {
    const {
      t,
      classes,
      value,
      sample,
      actions,
      accept,
      maxSize,
      addItem,
      setId,
    } = this.props;
    const { openUploadModal } = this.state;

    return (
      <FormControl className={classes.formControl} id={setId("wrap")}>
        <div className={classes.sampleText} id={setId("text")}>
          {sample}
        </div>
        <div id={setId("actions")}>
          <Button
            onClick={this.openUploadModal}
            color="yellow"
            setId={(elementName) => setId(`upload-${elementName}`)}
          >
            {addItem ? addItem.text : t("UPLOAD")}
          </Button>
        </div>
        <AttachList
          handleDelete={this.handleDelete}
          handleDownload={actions.downloadAttach}
          requestPreview={actions.requestPreview}
          attaches={Object.values(value)}
          setId={(elementName) => setId(`-attach-list-${elementName}`)}
        />
        <UploadMultipleFilesDialog
          accept={accept}
          maxSize={maxSize}
          open={openUploadModal}
          files={this.state.localFiles}
          onSave={this.onSave}
          onClose={this.closeUploadModal}
          onDelete={this.handleDeleteLocalFiles}
          onFilesAdded={this.handleAddedLocalFiles}
          onRenameFile={this.handleRenameFile}
          setId={(elementName) => setId(`-dialog-${elementName}`)}
        />
        <BlockScreen open={this.state.saving} />
      </FormControl>
    );
  }
}

SelectFiles.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  sample: PropTypes.string,
  accept: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  actions: PropTypes.object.isRequired,
  maxSize: PropTypes.number,
  addItem: PropTypes.object,
  setId: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

SelectFiles.defaultProps = {
  setId: setComponentsId("select-files"),
  sample: "",
  accept: "",
  maxSize: null,
  value: null,
  addItem: null,
};

const styled = withStyles(styles)(SelectFiles);
export default translate("Elements")(styled);
