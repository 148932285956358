import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";
import setComponentsId from "../../helpers/setComponentsId";

import chartCardStyle from "../../variables/styles/chartCardStyle";

const ChartCard = ({
  classes,
  chartColor,
  statIconColor,
  chart,
  title,
  text,
  statLink,
  statText,
  statIcon,
  setId,
}) => (
  <Card className={classes.card} id={setId("")}>
    <CardHeader
      className={cx(classes.cardHeader, classes[`${chartColor}CardHeader`])}
      subheader={chart}
      id={setId("header")}
    />
    <CardContent className={classes.cardContent} id={setId("content")}>
      <Typography
        variant="h6"
        component="h4"
        className={classes.cardTitle}
        id={setId("title")}
      >
        {title}
      </Typography>
      <Typography
        component="p"
        className={classes.cardCategory}
        id={setId("text")}
      >
        {text}
      </Typography>
    </CardContent>
    <CardActions className={classes.cardActions} id={setId("action")}>
      <div className={classes.cardStats} id={setId("stats")}>
        <statIcon
          className={cx(
            classes.cardStatsIcon,
            classes[`${statIconColor}CardStatsIcon`]
          )}
          id={setId("icon")}
        />
        &nbsp;
        {statLink && (
          <a
            href={statLink.href}
            className={classes.cardStatsLink}
            id={setId("link")}
          >
            {statLink.text}
          </a>
        )}
        {statLink && statText}
      </div>
    </CardActions>
  </Card>
);

ChartCard.propTypes = {
  classes: PropTypes.object.isRequired,
  chart: PropTypes.object.isRequired,
  title: PropTypes.node,
  text: PropTypes.node,
  statIcon: PropTypes.func.isRequired,
  statIconColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  chartColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
  statLink: PropTypes.object,
  statText: PropTypes.node,
  setId: PropTypes.func,
};

ChartCard.defaultProps = {
  statIconColor: "gray",
  chartColor: "purple",
  title: "",
  text: "",
  statLink: null,
  statText: "",
  setId: setComponentsId("chart-card"),
};

export default withStyles(chartCardStyle)(ChartCard);
