export default {
  TITLE: "Увімкнення двоетапної перевірки",
  TEXT: "Якщо ви ввімкнете двоетапну перевірку ви додатково отримуватимете смс з унікальним кодом на свій телефон при кожному вході в систему.",
  TEXT_WAIT_FOR_CODE:
    "Будь ласка, зачекайте на смс та введіть код у поле нижче:",
  CONFIRM_TEXT: "Для увімкнення вам буде надіслано смс з кодом на номер:",
  SEND_CODE: "Надіслати СМС",
  VERIFY_CODE: "Підтвердити",
  CODE_INPUT_LABEL: "Код підтвердження",
  VALIDATION_FALSE: "Код не пройшов перевірку",
  RESEND_CODE: "Надіслати смс ще раз",
};
