import React from "react";
import { Attachment } from "@material-ui/icons";
import humanDateFormat from "../../../../helpers/humanDateFormat";
import { Table } from "../../../../components";
import { docsFields } from "./docsFields";
import { DataSourceClass } from "../../../../helpers/dataSource";
import DocNumberLink from "./DocNumberLink";

export interface DocumentProcTableProps {
  t: (s: string, args?: Record<string, any>) => string;
  setId: (s: string) => string;
  list: Record<string, any>[];
  dataSource: DataSourceClass;
  createSortHandler: () => void;
  pagination: () => void;
  changeCount: () => void;
  checked: string;
  onCellClick: (id: string, data: Record<string, any>) => () => void;
}

export const documentProcTableGetText = (
  t: any,
  item: Record<string, any>,
  key: string
) => {
  const {
    number,
    createdAt,
    scanDocumentLink,
    digitalDocumentStaticFile,
    category,
  } = item;

  const foundDocLink = !!(scanDocumentLink || digitalDocumentStaticFile);

  switch (key) {
    case "scanDoc":
      return foundDocLink ? <Attachment /> : "";
    case "docFullName":
      return t("MY_PROC_DOC_FULL_NAME", {
        number: number || t("WITHOUT_NUMBER"),
        createdAt: humanDateFormat(createdAt),
      });
    case "docNumber":
      return <DocNumberLink docNumber={number} t={t} category={category} />;
    case "docDate":
      return humanDateFormat(item[key] || item["updatedAt"]);
    case "dateForce":
      return humanDateFormat(item[key]);

    default:
      return item[key];
  }
};

const DocumentProcTable = ({
  t,
  list,
  dataSource,
  createSortHandler,
  checked = "",
  onCellClick,
  setId,
  pagination,
  changeCount,
}: DocumentProcTableProps) => {
  return (
    <Table
      fields={docsFields}
      checked={checked}
      getText={documentProcTableGetText.bind(null, t)}
      setId={setId}
      dataSource={dataSource}
      onCheckItem={onCellClick}
      createSortHandler={createSortHandler}
      pagination={pagination}
      changeCount={changeCount}
      list={list}
      t={t}
      labelRowsPerPage="COUNT_CASES"
      labelDisplayedRows="DISPLAYED_CASES"
    />
  );
};

export default DocumentProcTable;
