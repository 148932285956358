import DataSource from "../helpers/dataSource";

const initialState = {
  list: [],
};

const dataSource = DataSource("api/proceedings/cases");

export default (state = initialState, action) => {
  state = dataSource.reducer()(state, action);

  switch (action.type) {
    default:
      return state;
  }
};
