import React from "react";
import OpenInNewWindow from "../../../../components/OpenInNewWindow";

function DocNumberLink({ category, docNumber, t }) {
  if (category === 8 && docNumber) {
    return (
      <OpenInNewWindow
        url={"https://reyestr.court.gov.ua/Review/" + docNumber}
        title={"Перехід до Єдиного державного реєстру судових рішень"}
      >
        {docNumber}
      </OpenInNewWindow>
    );
  } else {
    return <div title={docNumber}> {docNumber || t("WITHOUT_NUMBER")}</div>;
  }
}

export default DocNumberLink;
