import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
// import { history } from "../../../store";

import { Typography } from "@material-ui/core";
import { Button } from "../../../components";
import AttachList from "../../../components/Attach/AttachList";
import ScanDocument from "../../../components/ScanDocument";

class DocumentsView extends Component {
  downLoadStaticFile = (fileData) => () =>
    this.props.handleDownload("staticFile", fileData, 0);

  downLoadScanFile = (fileData) => () =>
    this.props.handleDownload("scanFile", fileData, 0);

  downLoadAttachmentFile = (fileData) => () =>
    this.props.handleDownload("attachmentFile", fileData, this.props.data.id);

  previewAttachmentFile = (fileData) => () =>
    this.props.handleDownload(
      "previewAttachmentFile",
      fileData,
      this.props.data.id
    );

  // redirectToClaim = (id) => () => history.push(`/cases_сlaims/${id}`);
  render() {
    const {
      classes,
      data,
      data: {
        digitalDocumentStaticFile,
        attaches,
        description,
        id,
        digitalDocumentTemplateId,
        scanDocumentLink,
        state,
      },
      togglePermissionDialog,
      page,
      t,
      activeProc,
      showCreateButton,
    } = this.props;
    const setId = (elementName) =>
      this.props.setId(`documents-view-${elementName}`);
    const staticFile = [
      {
        attachId: id,
        contentType: "application/pdf",
        fileName: description,
      },
    ];
    const isFullyOpened = true;

    return (
      <div className={classes.docWrapper} id={setId("wrapper")}>
        {scanDocumentLink && digitalDocumentStaticFile && (
          <Fragment>
            <Typography variant="h5" component="p" id={setId("attaches")}>
              {t("STATIC_FILE")}
            </Typography>
            <AttachList
              alwaysPreview={true}
              attaches={staticFile}
              handleDownload={this.downLoadStaticFile}
              noShowAttachPreview={this.props.noShowAttachPreview}
              requestPreview={this.downLoadStaticFile}
              setId={(elementName) => setId(`static-preview-${elementName}`)}
            />
          </Fragment>
        )}
        {digitalDocumentTemplateId && state === 1 && (
          <Button
            onClick={this.redirectToClaim(id)}
            id={setId("edit-claim-button")}
            setId={(elementName) => setId(`static-download-${elementName}`)}
          >
            {t("EDIT_CLAIM")}
          </Button>
        )}
        <ScanDocument
          attaches={attaches}
          viewDocument={data}
          downloadAttach={this.downLoadAttachmentFile}
          previewAttach={this.previewAttachmentFile}
          isFullyOpened={isFullyOpened}
          tools={
            showCreateButton &&
            page === "cases" &&
            (data || {}).category === 8 &&
            (activeProc || {}).procCourtTypeId !== 4 && (
              <Button
                color="yellow"
                variant="contained"
                onClick={togglePermissionDialog([1, 2])}
                id={setId("create-button")}
                setId={(elmentName) => setId(`create-${elmentName}`)}
              >
                {t("TO_PROTEST")}
              </Button>
            )
          }
        />
      </div>
    );
  }
}

DocumentsView.propTypes = {
  data: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  togglePermissionDialog: PropTypes.func.isRequired,
  activeProc: PropTypes.object.isRequired,
  showCreateButton: PropTypes.bool.isRequired,
};

// decorate and export
export default translate("CaseList")(DocumentsView);
