import React from "react";
import PropTypes from "prop-types";

import { Snackbar, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { deleteSnackbarMessage } from "../../actions/notifications";

class SnackbarMessages extends React.Component {
  handleClose = (snackbar) => () => {
    (snackbar.handleClose || deleteSnackbarMessage)(snackbar);
  };

  render() {
    const { list } = this.props;

    if (!list.length) {
      return null;
    }

    return list.map((snackbar) => (
      <Snackbar
        key={snackbar.id}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={true}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose(snackbar)}
          >
            <Close />
          </IconButton>,
        ]}
        {...snackbar}
      />
    ));
  }
}

SnackbarMessages.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

SnackbarMessages.defaultProps = {
  list: [],
};

export default SnackbarMessages;
