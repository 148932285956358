import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { requestUserMeta, addUserMeta } from "../../actions/usermeta";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { Button } from "../../components";

import cx from "classnames";
import customInputStyle from "../../variables/styles/customInputStyle";
import selectFilesDialogStyle from "../../variables/styles/selectFilesDialogStyle";

const getOpenState = ({ userMetaData, warningName }) =>
  !userMetaData ||
  !userMetaData.meta ||
  !userMetaData.meta.dontShowWarnings ||
  !userMetaData.meta.dontShowWarnings.includes(warningName);

class WarningDialog extends Component {
  state = { open: false, dontShow: false };

  UNSAFE_componentWillMount() {
    const { userMetaData } = this.props;
    if (!userMetaData) {
      requestUserMeta().then(() =>
        this.setState({
          open: getOpenState(this.props),
        })
      );
    } else {
      this.setState({ open: getOpenState(this.props) });
    }
  }

  handleClose = () =>
    this.setState(
      { open: false },
      () => this.state.dontShow && this.dontShowMore()
    );

  checkDontShow = ({ target: { checked } }) =>
    this.setState({ dontShow: checked });

  dontShowMore = () => {
    const { userMetaData, warningName } = this.props;
    const { meta } = userMetaData || { meta: {} };
    const dontShowWarnings = (meta || {}).dontShowWarnings || [];
    dontShowWarnings.push(warningName);
    this.setState({ open: false }, () =>
      addUserMeta({ meta: { ...meta, dontShowWarnings } }).then(() =>
        requestUserMeta()
      )
    );
  };

  render() {
    const { open, dontShow } = this.state;
    const { classes, t, warningName } = this.props;

    return (
      <Dialog
        fullWidth={true}
        open={open}
        onClose={this.handleClose}
        className={classes.dialog}
      >
        <DialogContent
          className={cx(classes.content, classes.dialogContentWrappers)}
        >
          <Typography variant="subtitle1" className={classes.heading}>
            {t(warningName.toUpperCase())}
          </Typography>
        </DialogContent>
        <DialogActions
          className={cx(classes.actions, classes.dialogContentWrappers)}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShow}
                onChange={this.checkDontShow}
                color="primary"
              />
            }
            label={t("DO_NOT_SHOW_MORE")}
          />
          <Button onClick={this.handleClose} color="yellow">
            {t("CLOSE")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

WarningDialog.propTypes = {
  warningName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  userMetaData: PropTypes.object,
};

WarningDialog.defaultProps = {
  userMetaData: null,
};

const styled = withStyles({
  ...customInputStyle,
  ...selectFilesDialogStyle,
})(WarningDialog);
const translated = translate("Warning")(styled);

const mapStateToProps = ({ usermeta: { userMetaData } }) => ({ userMetaData });

export default connect(mapStateToProps)(translated);
