import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles, FormControl, Typography } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import customInputStyle from "../../variables/styles/customInputStyle";
import setComponentsId from "../../helpers/setComponentsId";

import cx from "classnames";

class TextFieldDummy extends Component {
  getValue() {
    const { select, children, options } = this.props;
    let { value } = this.props;
    const selected =
      children &&
      React.Children.toArray(children)
        .filter((child) => child.props.value === value)
        .shift();
    if (!selected.props && options && Array.isArray(options)) {
      value =
        options.find(
          (item) =>
            item.id === value || item.value === value || item.name === value
        ).name || "";
    }
    switch (true) {
      case select:
        return (selected.props || {}).children || value;
      default:
        return value;
    }
  }

  render() {
    const { classes, label, helperText, formControlProps, setId, error, id } =
      this.props;

    const marginTop = cx({
      [classes.marginTop]: label,
    });

    return (
      <FormControl
        {...formControlProps}
        margin="normal"
        id={id ? `${id}-form` : setId("form")}
      >
        {label && (
          <FormHelperText
            className={cx(classes.dummyLabel, {
              [classes.error]: error,
            })}
            id={id ? `${id}-label` : setId("label")}
          >
            {label}
          </FormHelperText>
        )}
        <Typography
          id={id}
          className={cx(classes.dummy, {
            [classes.error]: error,
          })}
          classes={{ root: marginTop }}
          component="h2"
        >
          {this.getValue()}
        </Typography>
        {helperText && (
          <FormHelperText id={id ? `${id}-helper` : setId("helper")}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

TextFieldDummy.propTypes = {
  value: PropTypes.node,
  select: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  formControlProps: PropTypes.object,
  setId: PropTypes.func,
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

TextFieldDummy.defaultProps = {
  value: "",
  select: false,
  children: "",
  label: "",
  helperText: "",
  formControlProps: {},
  setId: setComponentsId("text-field-dummy"),
  id: "",
  error: "",
  options: null,
};

export default withStyles(customInputStyle)(TextFieldDummy);
