import courtsList from "./CourtList";
import judge from "../JudgeFilterChip";
import regionsList from "./RegionList";
import justiceKind from "./JusticeKind";
import judgmentCode from "./JudgmentCode";
import documentId from "../DocumentIdFilterChip";
import caseNumber from "../CaseNumberFilterChip";
import causeCategoryList from "./CauseCategoryList";
import instanceCode from "./instanceCode";

import PublicationStart from "./PublicationStart";
import PublicationEnd from "./PublicationEnd";
import AdjudicationStart from "./AdjudicationStart";
import AdjudicationEnd from "./AdjudicationEnd";

export default {
  judge,
  doc_id: documentId,
  case_num: caseNumber,
  court_code: courtsList,
  justice_kind: justiceKind,
  judgment_code: judgmentCode,
  region_code: regionsList,
  instance_code: instanceCode,
  cause_category: causeCategoryList,
  receipt_date_start: PublicationStart,
  receipt_date_end: PublicationEnd,
  adjudication_date_start: AdjudicationStart,
  adjudication_date_end: AdjudicationEnd,
};
