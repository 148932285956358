import executiveStatusColors from "../../variables/styles/executiveStatusColors";

export default {
  ...executiveStatusColors,
  chip: {
    margin: "0 8px",
    height: 24,
    color: "#fff",
    cursor: "inherit",

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100%",
      margin: 0,
    },
  },

  tableChip: {
    justifyContent: "flex-start",
    maxWidth: "255px",

    "@media (max-width:1500px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "calc(100% - 8px)",
    },

    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "100%",
      margin: 0,
    },

    "& > span": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
    },
  },
  tooltip: {
    // width: 280,
    zIndex: 1301,
  },
};
