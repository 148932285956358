// import promiseChain from "../../../helpers/promiseChain";

// export const signManifestV1 = async (manifest, signer) => {
//   if (Array.isArray(manifest)) {
//     const [first, ...rest] = manifest;
//     return Promise.all([
//       signer.execute("SignData", first, true),
//       ...rest.map((element) => signer.execute("SignHash", element)),
//     ]);
//   }

//   return signer.execute("SignData", manifest, true);
// };

export const signManifestV1 = async (manifest, signer, savedSignatures) => {
  if (Array.isArray(manifest)) {
    const result = [""];
    savedSignatures = savedSignatures || [];
    savedSignatures.forEach((item) => result.push(item));
    const startIdx = savedSignatures.length + 1;
    for (let i = startIdx; i < manifest.length; i++) {
      const sign = await signer.execute("SignHash", manifest[i]);
      result.push(sign);
    }
    return result;
  }

  return await signer.execute("SignData", manifest, true);
};

export const signManifestV2 = async (manifest, signer) => {
  if (Array.isArray(manifest)) {
    const [first, ...rest] = manifest;
    return Promise.all([
      signer.execute("SignData", first, true),
      ...rest.map((element) => signer.execute("SignData", element, true)),
    ]);
  }

  return signer.execute("SignData", manifest, true);
};

export const signManifestVersions = {
  1: signManifestV1,
  2: signManifestV2,
};

export default signManifestV1;
