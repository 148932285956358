import React from "react";
import PropTypes from "prop-types";
import { Toolbar } from "@material-ui/core";

import Filters from "../components/Filters";
import setComponentsId from "../../../helpers/setComponentsId";
import { getStatusesForCat } from "../../../variables/executiveStatuses";

const ExecutiveDocsToolbar = ({
  classes,
  setId,
  t,
  dataSource,
  docCategory,
  load,
  courts,
  roles,
  forms,
  userId,
  returnObject,
}) => (
  <Toolbar className={classes.toolbar} disableGutters={true}>
    <Filters
      t={t}
      classes={classes}
      setId={setId}
      dataSource={dataSource}
      statuses={getStatusesForCat(docCategory)}
      load={load}
      roles={roles}
      forms={forms}
      courts={courts}
      userId={userId}
      returnObject={returnObject}

      // courtTypes={courtTypes}
      // justiceTypes={justiceTypes}
    />
  </Toolbar>
);

ExecutiveDocsToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  docCategory: PropTypes.number.isRequired,
  load: PropTypes.func.isRequired,
};

ExecutiveDocsToolbar.defaultProps = {
  setId: setComponentsId("executivedocs-toolbar"),
};

export default ExecutiveDocsToolbar;
