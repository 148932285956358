import { Component, Children, cloneElement } from "react";
import PropTypes from "prop-types";

import { translate } from "react-translate";

import { withStyles, FormControl } from "@material-ui/core";

import { Button } from "../../components";
import styles from "../../variables/styles/filterInput";

class ExecutiveFilterForm extends Component {
  updateChildProps = (child) => {
    if (typeof child !== "object" || !child) {
      return child;
    }

    const newProps = {};

    if (child.type.Naked && child.type.Naked.name === "Checkbox") {
      newProps.checked = newProps.value;
    }

    if (child.type.Naked && child.type.Naked.name === "Checkbox") {
      newProps.checked = newProps.value;
    }

    if (child.props && child.props.children) {
      newProps.children = Children.map(
        child.props.children,
        this.updateChildProps
      );
    }

    if (child.props && child.props.control) {
      newProps.control = this.updateChildProps(child.props.control);
    }

    return cloneElement(child, newProps);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.load();
  };

  onSearchBtnClick() {
    if (this.props.onSearchCallback) {
      this.props.onSearchCallback();
    }
  }

  onClearBtnClick(e) {
    e.preventDefault();
    if (this.props.onClearCallback) {
      this.props.onClearCallback();
      // this.props.load();
    }
  }

  render() {
    const { classes, setId, children, autoload, t } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <FormControl
          fullWidth={true}
          className={classes.formControl}
          id={setId("popover-form")}
        >
          {Children.map(children, this.updateChildProps)}
        </FormControl>
        {!autoload && (
          <div
            id={setId("popover-action")}
            style={{ display: "flex", flexWrap: "wrap", gap: "7px" }}
          >
            <Button
              className={classes.searchBtn}
              color="secondary"
              onClick={this.onClearBtnClick.bind(this)}
              setId={(elementName) => setId(`submit-${elementName}`)}
              type="submit"
            >
              {t("CLEAT_FILTER")}
            </Button>
            <Button
              className={classes.searchBtn}
              color="yellow"
              onClick={this.onSearchBtnClick.bind(this)}
              setId={(elementName) => setId(`submit-${elementName}`)}
              type="submit"
            >
              {t("SEARCH")}
            </Button>
          </div>
        )}
      </form>
    );
  }
}

ExecutiveFilterForm.propTypes = {
  load: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  children: PropTypes.node,
  autoload: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

ExecutiveFilterForm.defaultProps = {
  autoload: false,
  children: "",
};

const styled = withStyles(styles)(ExecutiveFilterForm);
export default translate("ClaimList")(styled);
