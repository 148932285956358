import React from "react";
import PropTypes from "prop-types";

import { TableBody, TableCell, TableRow, Icon } from "@material-ui/core";
import cx from "classnames";

import Checkbox from "@material-ui/core/Checkbox";

const rowSelected = (checked, id) => {
  if (Array.isArray(checked)) {
    return checked.includes(id);
  }
  return String(checked) === String(id);
};

const Body = ({
  setId,
  classes,
  ownerList,
  checked,
  t,
  highlightClaim,
  dontHaveDelPerLabel,
  delLabel,
  onCheckboxClick,
  favorites,
  getText,
  onCheckItem,
  needFullData,

  itIsFavorite,
  checkbox,
  getClasses,
  tableFields,
  calcDataRowClass,
  listWithPermissions,
}) => {
  return (
    <TableBody id={setId("table-body")} className={classes.mobileBlock}>
      {listWithPermissions &&
        listWithPermissions.map((data, index) => {
          if (data.typeId === 1) {
            // this is an order
            const allowedBy = JSON.parse(data.meta)?.allowedBy;
            if (allowedBy) {
              const { companyName, name } = allowedBy;
              data.allowedByName = companyName ? companyName : name;
            }
          }

          return (
            <TableRow
              key={`row-${index}${data.id || JSON.stringify(data)}`}
              hover={true}
              id={setId(`row-${index}`)}
              selected={rowSelected(checked, data.id)}
              className={cx(
                classes.row,
                classes.mobileGrid,
                highlightClaim === data.id && classes.highlight,
                "viewed" in data && !data.viewed && classes.notViewed,
                "isRead" in data && !data.isRead && classes.notViewed,
                data.isDeleted ? classes.isDeleted : "",
                calcDataRowClass({
                  rowIndex: index,
                  rowData: data,
                  selected: rowSelected(checked, data.id),
                })
              )}
            >
              {checkbox && ownerList && (
                <TableCell
                  padding="checkbox"
                  id={setId("table-checkbox")}
                  className={getClasses(classes, checkbox)}
                  title={!data.havePermissions ? dontHaveDelPerLabel : delLabel}
                >
                  <Checkbox
                    checked={checked.includes(data.id)}
                    color="default"
                    className={classes.checkbox}
                    onClick={onCheckboxClick(data.id)}
                    id={setId("checkbox")}
                    disabled={!data.havePermissions}
                  />
                  {!!favorites.length && (
                    <Icon
                      className={cx(
                        classes.icon,
                        checked.includes(data.id) && classes.checkbox,
                        !itIsFavorite(data) && classes.hidden
                      )}
                    >
                      star
                    </Icon>
                  )}
                  {data.resharing && (
                    <Icon
                      className={cx(
                        classes.icon,
                        checked.includes(data.id) && classes.checkbox
                      )}
                    >
                      supervisor_account
                    </Icon>
                  )}
                  {"isRead" in data && (
                    <Icon
                      className={cx(
                        classes.icon,
                        !data.isRead && classes.checkbox
                      )}
                    >
                      message
                    </Icon>
                  )}
                </TableCell>
              )}
              {tableFields.map((item) => {
                const text = getText(data, item.key);
                return (
                  <TableCell
                    onClick={onCheckItem(
                      needFullData ? data : data.id || data.attachId,
                      data
                    )}
                    id={setId(`table-cell-${item.key}`)}
                    key={`table-cell-${item.key}`}
                    className={`${getClasses(classes, item, ownerList)} ${
                      classes.relative
                    }`}
                    title={
                      text && typeof text === "string"
                        ? getText(data, item.key)
                        : ""
                    }
                    align={data.numeric ? "right" : "inherit"}
                  >
                    {((!item.classNames.includes("onlyMobile") &&
                      !item.classNames.includes("onlyBigScreen")) ||
                      (item.classNames.includes("onlyMobile") &&
                        !getText(data, item.key))) &&
                      item.title &&
                      item.title !== "PREVIEW" && (
                        <span
                          className={cx(
                            classes.onlyMobileText,
                            classes.onlyMobile
                          )}
                        >
                          {`${t(item.title)}: `}
                        </span>
                      )}
                    {data.isDeleted && text?.props?.status ? (
                      <div className={classes.throughLine} />
                    ) : (
                      ""
                    )}
                    {text}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
    </TableBody>
  );
};

Body.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  getText: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,

  highlightClaim: PropTypes.bool,
  needFullData: PropTypes.bool,
  ownerList: PropTypes.bool,
  favorites: PropTypes.array,
  dontHaveDelPerLabel: PropTypes.string,
  delLabel: PropTypes.string,
  itIsFavorite: PropTypes.func.isRequired,
  checkbox: PropTypes.object,
  getClasses: PropTypes.func.isRequired,
  tableFields: PropTypes.array.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  listWithPermissions: PropTypes.array.isRequired,
  calcDataRowClass: PropTypes.func.isRequired,
};

Body.defaultProps = {
  checked: "",
  highlightClaim: false,
  needFullData: false,
  ownerList: true,
  favorites: [],
  dontHaveDelPerLabel: "",
  delLabel: "Вибрати",
  checkbox: null,
  calcDataRowClass: ({ rowIndex, rowData, selected }) => {
    return "";
  },
};

export default Body;
