/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";
import { translate } from "react-translate";
import { withStyles, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TextFieldDummy from "../../../components/CustomInput/TextFieldDummy";
import InputMask from "react-input-mask";

import customInputStyle from "../../../variables/styles/customInputStyle";

const Masked = (props) => (
  <InputMask {...props} maskChar=" " inputRef={props.ref} />
);

Masked.propTypes = {
  ref: PropTypes.node,
};

Masked.defaultProps = {
  ref: undefined,
};

class IntegerElement extends React.Component {
  state = { value: this.props.value };

  componentDidMount() {
    const { value, onChange } = this.props;
    onChange(value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, readOnly } = nextProps;
    if (readOnly && value !== this.state.value) {
      this.setState({ value });
    }
  }

  handleChange = ({ target: { value } }) => {
    const { onChange } = this.props;
    onChange && onChange(parseInt(value, 10));
    this.setState({ value });
  };

  render() {
    const {
      classes,
      sample,
      errors,
      description,
      formControlProps,
      readOnly,
      InputProps,
      SelectProps,
      mask,
      required,
      setId,
    } = this.props;
    const { value } = this.state;

    const Component = readOnly ? TextFieldDummy : TextField;
    return (
      <FormControl
        {...formControlProps}
        className={classes.formControl}
        id={setId("form-control")}
      >
        <Component
          label={(description || "") + (required ? "*" : "")}
          value={parseInt(value || "", 10) + ""}
          onChange={this.handleChange}
          helperText={!readOnly && (sample || errors)}
          error={!!errors}
          InputProps={{ ...InputProps, inputComponent: Masked }}
          inputProps={{ mask }}
          SelectProps={SelectProps}
          type="number"
          id={setId("input")}
        />
      </FormControl>
    );
  }
}

IntegerElement.propTypes = {
  onChange: PropTypes.func,
  sample: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formControlProps: PropTypes.object,
  description: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  readOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  SelectProps: PropTypes.object,
  mask: PropTypes.string,
  required: PropTypes.bool,
  ref: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IntegerElement.defaultProps = {
  onChange: undefined,
  sample: "",
  formControlProps: {},
  errors: null,
  description: "",
  readOnly: false,
  InputProps: {},
  SelectProps: {},
  mask: "",
  required: false,
  setId: setComponentsId("integer"),
  ref: undefined,
  value: "",
};

const styled = withStyles(customInputStyle)(IntegerElement);
export default translate("Elements")(styled);
