import * as api from "../services/api";
import { post } from "../services/api/new.api";

import store from "../store";
import {
  SAVE_ACTIVE_CASE,
  CLEAR_CASES,
  RESIZE,
  SAVE_FILE,
} from "../reducers/cases";

const { dispatch } = store;

export const REQUEST_СASE_DOCUMENTS = "REQUEST_СASE_DOCUMENTS";
export const REQUEST_DOCUMENT = "REQUEST_DOCUMENT";
export const REQUEST_STATIC_FILE = "REQUEST_STATIC_FILE";
export const REQUEST_STATIC_ARCHIVE = "REQUEST_STATIC_ARCHIVE";
export const REQUEST_SCAN_FILE = "REQUEST_SCAN_FILE";
export const REQUEST_SCAN_ARCHIVE = "REQUEST_SCAN_ARCHIVE";
export const REQUEST_ACTIVE_CASE = "REQUEST_ACTIVE_CASE";
export const REQUEST_CASE_PROCEEDINGS = "REQUEST_CASE_PROCEEDINGS";
export const REQUEST_CASES_COURTS = "REQUEST_CASES_COURTS";
export const REQUEST_PROCEEDINGS_COURTS = "REQUEST_PROCEEDINGS_COURTS";
export const REQUEST_JUDGE_CASES_COURTS = "REQUEST_JUDGE_CASES_COURTS";
export const REQUEST_ACTIVE_PROC = "REQUEST_ACTIVE_PROC";
export const REQUEST_DOWNLOAD_ZIP = "REQUEST_DOWNLOAD_ZIP";
export const REQUEST_SIGN_CHECK = "REQUEST_SIGN_CHECK";
export const REQUEST_DOCUMENT_HISTORY = "REQUEST_DOCUMENT_HISTORY";

const DOCUMENTS_URL = "api/documents";

export function requestDownloadZip(id) {
  return post(
    `api/proceedings/${id}/files/packages`,
    {},
    REQUEST_DOWNLOAD_ZIP,
    dispatch
  );
}

export function requestCaseDocuments(id) {
  return api.get(
    `${DOCUMENTS_URL}/case?case_id=${id}`,
    REQUEST_СASE_DOCUMENTS,
    dispatch
  );
}

export function requestDocument(id) {
  return api.get(`${DOCUMENTS_URL}/${id}`, REQUEST_DOCUMENT, dispatch);
}

export function downloadStaticFile(id) {
  return api.get(
    `${DOCUMENTS_URL}/${id}/static_file`,
    REQUEST_STATIC_FILE,
    dispatch
  );
}

export function downloadStaticArchive(id) {
  return api.get(
    `${DOCUMENTS_URL}/${id}/static_file?includeSignatures=true&includeAttachments=true`,
    REQUEST_STATIC_ARCHIVE,
    dispatch
  );
}

export function docSignCheck(id) {
  return api.get(
    `${DOCUMENTS_URL}/${id}/sign_check`,
    REQUEST_SIGN_CHECK,
    dispatch
  );
}

export function downloadScanFile(id) {
  return api.get(
    `${DOCUMENTS_URL}/${id}/scan_file`,
    REQUEST_SCAN_FILE,
    dispatch
  );
}

export function downloadScanArchive(id) {
  return api.get(
    `${DOCUMENTS_URL}/${id}/scan_file?includeSignatures=true&includeAttachments=true`,
    REQUEST_SCAN_ARCHIVE,
    dispatch
  );
}

export function saveActiveCase(activeCase) {
  return dispatch({ type: SAVE_ACTIVE_CASE, payload: activeCase });
}

export function requestActiveCase(id) {
  return api.get(`api/cases/${id}`, REQUEST_ACTIVE_CASE, dispatch);
}

export function requestActiveProc(id) {
  return api.get(`api/proceedings/${id}`, REQUEST_ACTIVE_PROC, dispatch);
}

export function requestCaseProceedings(id) {
  return api.get(
    `api/proceedings/cases/${id}`,
    REQUEST_CASE_PROCEEDINGS,
    dispatch
  );
}

export function requestProceedingsCourts() {
  return api.get(
    "api/proceedings/courts",
    REQUEST_PROCEEDINGS_COURTS,
    dispatch
  );
}

export function requestCasesCourts() {
  return api.get("api/cases/courts", REQUEST_CASES_COURTS, dispatch);
}

export function requestJudgeCasesCourts() {
  return api.get(
    "api/cases/courts_for_judge",
    REQUEST_JUDGE_CASES_COURTS,
    dispatch
  );
}

/**
 * @param {string} id
 * @returns {Promise<DocumentHistory>}
 */
export function requestDocumentHistory(id) {
  return api.get(
    `${DOCUMENTS_URL}/${id}/history`,
    REQUEST_DOCUMENT_HISTORY,
    dispatch
  );
}

export function clearCases() {
  return dispatch({ type: CLEAR_CASES });
}

export function reSize(size) {
  return dispatch({ type: RESIZE, payload: size });
}

export function saveFile(file, docId) {
  return dispatch({ type: SAVE_FILE, payload: { file, docId } });
}
