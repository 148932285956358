import React from "react";
import { Provider } from "react-redux";
import { TranslatorProvider } from "react-translate";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import { Router, Redirect, Route, Switch } from "react-router-dom";

import routes from "./routes";
import store, { history } from "./store";
import translation from "./variables/translations/ua";
import { themeStyles } from "./variables/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import Auth from "./components/Auth";
import config from "./config";
import ApplicationDialog from "./components/ApplicationDialog/ApplicationDialog";

const { application, amplitude } = config();

function createRoute({ redirect, ...props }, key) {
  const RouteComponent = redirect ? Redirect : Route;
  return <RouteComponent {...props} key={key} />;
}

const router = (
  <Router history={history}>
    <Switch>{routes[application.name || "cabinet"].map(createRoute)}</Switch>
  </Router>
);

const theme = createMuiTheme(themeStyles);

const auth = (
  <TranslatorProvider translations={translation}>
    <Auth>
      {router}
      <ApplicationDialog />
    </Auth>
  </TranslatorProvider>
);

const provider = <Provider store={store}>{auth}</Provider>;

const App = (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {provider}
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

function loadAmplitude(amplitude) {
  const apiKey = amplitude.apiKey;
  const options = amplitude.options;
  const script = document.createElement("script");
  if (options.serverZone === "EU") {
    script.src = `https://cdn.eu.amplitude.com/script/${apiKey}.js`;
  } else {
    script.src = `https://cdn.amplitude.com/script/${apiKey}.js`;
  }
  script.async = true;

  script.onload = () => {
    console.log("Amplitude SDK завантажено");

    // Ініціалізуємо Amplitude з плагіном Session Replay та вашим API ключем
    window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
    window.amplitude.init(apiKey, options);
    console.log("Amplitude ініціалізовано з API ключем:", apiKey);
  };

  script.onerror = (event) => {
    console.error("Не вдалося завантажити Amplitude SDK", event);
  };

  document.head.appendChild(script);
}

if (amplitude) {
  loadAmplitude(amplitude);
} else {
  console.log("Amplitude API ключ не заданий, SDK не завантажується");
}
export default App;
