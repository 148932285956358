export default {
  claims: 1,
  executive: 4,
  cases_claims: 6,
  c_claims: 5, // collector claims
  share: 7,
  decision: 8,
  police: 9,
  nabu: 10,
  bank: null,
};
