import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const ExecMyRoleFilterChip = ({ t, value, list, ...rest }) => (
  <Chip
    label={`${t("DOCUMENT_ROLE")}: ${
      ((list || []).find((item) => item.value === value) || {}).name || ""
    }`}
    {...rest}
  />
);

ExecMyRoleFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ExecMyRoleFilterChip.defaultProps = {
  value: "",
  list: [],
};

export default translate("ExecutiveDocs")(ExecMyRoleFilterChip);
