import normalizeClaim from "../../../helpers/normalizeClaim";
import keyToUint8Array from "../../../helpers/keyToUint8Array";
import getWizardProps from "../../../helpers/getWizardProps";

import edsService from "../../../services/eds";

import { requestClaim, setWizardStates } from "../../../actions/claim";
import promiseChain from "../../../helpers/promiseChain";

const { TextDecoder /* , TextEncoder */ } = window;

export default function loadClaim(claimId) {
  return promiseChain(
    [
      requestClaim,
      normalizeClaim,
      (result) =>
        promiseChain([
          () =>
            setWizardStates({
              origin: JSON.parse(JSON.stringify(result)),
            }),
          () => {
            const { encryptKeys } = getWizardProps();
            const { signer } = edsService;
            const encryptionKey = encryptKeys[result.id];
            return encryptionKey && result.digitalDocumentData
              ? promiseChain([
                  () =>
                    signer.execute(
                      "UnprotectDataByPassword",
                      result.digitalDocumentData,
                      encryptionKey,
                      false
                    ),
                  (digitalDocumentData) => keyToUint8Array(digitalDocumentData),
                  (digitalDocumentData) =>
                    new TextDecoder("utf-8").decode(digitalDocumentData),
                  (unprotectedData) => JSON.parse(unprotectedData),
                ])
              : result.digitalDocumentData;
          },
          (digitalDocumentData) => ({
            ...result,
            digitalDocumentData,
          }),
        ]),
    ],
    claimId
  );
}
