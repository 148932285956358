export const formatMoney = (
  sum: number | string,
  currency?: string | undefined
) => {
  return Number(sum) <= 0
    ? ""
    : Number.isInteger(Number(sum))
    ? `${Number(sum)}`
    : `${Number(sum).toFixed(2)}`;
};
