import React from "react";
import PropTypes from "prop-types";

import { ButtonBase, withStyles } from "@material-ui/core";
import styles from "../../variables/styles/filterCleaner";

import { Clear } from "@material-ui/icons";

class FilterCleaner extends React.Component {
  handleClick = () => {
    const { dataSource } = this.props;
    const { aggs } = dataSource;

    this.getFilterNames().forEach((filterName) => {
      dataSource.setFilter(filterName, undefined);
      aggs[filterName] = undefined;
    });
    dataSource.setValue("aggs", aggs);

    dataSource.load();
  };

  getFilterNames = () => {
    const { name } = this.props;
    return Array.isArray(name) ? name : [name];
  };

  hasFilters = () => {
    const { dataSource } = this.props;
    return (
      this.getFilterNames()
        .map((filterName) => dataSource.filters[filterName])
        .filter(Boolean).length > 0
    );
  };

  render() {
    const { classes, children } = this.props;

    if (!this.hasFilters()) {
      return children;
    }

    return (
      <div className={classes.flex}>
        <div className={classes.children}>{children}</div>
        <ButtonBase onClick={this.handleClick} className={classes.button}>
          <Clear />
        </ButtonBase>
      </div>
    );
  }
}

FilterCleaner.propTypes = {
  dataSource: PropTypes.object.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

FilterCleaner.defaultProps = {
  children: "",
  name: "",
};

export default withStyles(styles)(FilterCleaner);
