import * as api from "../services/api";
import store from "../store";

const PING = "PING";
const REQUEST_SYSTEM_MESSAGE = "REQUEST_SYSTEM_MESSAGE";
const SEARCH = "SEARCH";
const TEST_URL = "api/test";
const SYSTEM_MESSAGE_URL = "api/news/message";

export function ping() {
  const { dispatch } = store;
  return api.get(`${TEST_URL}/ping`, PING, dispatch);
}

export function requestSystemMessage() {
  const { dispatch } = store;
  return api.get(SYSTEM_MESSAGE_URL, REQUEST_SYSTEM_MESSAGE, dispatch);
}

export function search(url, searchStr, action = undefined) {
  return api.get(
    `${url}?search=${searchStr}`,
    action || SEARCH,
    store.dispatch
  );
}

export async function searchAsync(url, searchStr, action = undefined) {
  return await api.get(
    `${url}?search=${searchStr}`,
    action || SEARCH,
    store.dispatch
  );
}

export function addDataSource(key, dataSource) {
  // const { dispatch } = store;
  // return dispatch({ type: "ADD_DATA_SOURCE", payload: dataSource, key });
  return (
    store &&
    store.dispatch({
      type: "ADD_DATA_SOURCE",
      payload: dataSource,
      key,
    })
  );
}
