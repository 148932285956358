import localizeError from "../../../variables/translations/ajv/ua";
import normalizeErrors from "../../../helpers/normalizeErrors";
import getWizardProps from "../../../helpers/getWizardProps";

import Ajv from "ajv";

const ajv = new Ajv({ ownProperties: true, allErrors: true, strict: false });

const legalFields = ["companyName", "edrpou"];
const unLegalFields = ["name", "ipn"];

export default function validateStep(step) {
  const { t, template, stepOrders, claim } = getWizardProps();

  const { jsonSchema } = template;
  const stepId = stepOrders[step];
  const stepProps = jsonSchema.properties[stepId];

  // костыль для сброса параметра валидация моб телефона в форме создания заявы claims/:id/applicant || claims/:id/claimant, role: "representative"
  if (stepProps.type === "array" && stepProps.items?.properties?.phone) {
    stepProps.items.properties.phone.pattern = "";
  } else if (stepProps.properties?.phone) {
    stepProps.properties.phone.pattern = "";
  }

  const required = jsonSchema.required && jsonSchema.required.includes(stepId);

  const { digitalDocumentData } = claim;
  let values = (digitalDocumentData || {})[stepId];

  if (values === undefined) {
    if (!required) {
      return {};
    }
    values = {};
  }

  let errors = {};
  const validator = ajv.compile(stepProps);
  validator(values);
  const validateErrors = validator.errors || [];
  if (stepProps.properties && values) {
    Object.keys(stepProps.properties).forEach((key) => {
      const item = stepProps.properties[key];
      const { htmlMaxLength, maxLength, htmlMinLength, minLength, control } =
        item || {};
      const value = values[key];
      const max = htmlMaxLength || maxLength;
      const min = htmlMinLength || minLength;
      if (control === "textArea" && typeof value === "string" && (max || min)) {
        const clearValue = value.replace(/<\/?[^>]+>/g, "").trim();
        if (max && clearValue.length > max) {
          validateErrors.push({
            keyword: "maxLength",
            params: { limit: max },
            dataPath: key,
          });
        }
        if (min && clearValue.length < min) {
          validateErrors.push({
            keyword: "minLength",
            params: { limit: min },
            dataPath: key,
          });
        }
      }
    });
  }
  errors =
    validateErrors.length > 0
      ? normalizeErrors(validateErrors.map(localizeError))
      : {};

  if (values.errors) {
    errors = { ...errors, ...values.errors };
  }

  stepProps.required &&
    stepProps.required.map((req) => {
      if (stepProps.control && stepProps.control === "select.user") {
        const { tabs, properties: props } = stepProps;
        const { tabId, isLegal } = values || {};
        const filterFields = isLegal ? unLegalFields : legalFields;
        const keys = Object.keys(props);
        let fields = keys.filter(
          (key) => !filterFields.find((id) => id === key)
        );
        if (tabs && tabId) {
          fields = keys.filter((key) =>
            props[key].tabsIds
              ? !!props[key].tabsIds.find((id) => id === tabId)
              : true
          );
        }
        const found = fields.find((item) => item === req);
        if (found) {
          errors = Object.keys(errors).reduce((acc, key) => {
            const foundInFields = fields.find((field) => field === key);
            if (foundInFields) acc[key] = errors[key];
            return acc;
          }, {});
        } else delete errors[req];
      } else if (!values[req]) {
        errors[req] = t("REQUIRED");
      }
      return req;
    });

  // TODO: It is hack. Please remove it as quickly as possible
  stepProps.items &&
    stepProps.items.required &&
    stepProps.items.required.map((req) => {
      if (
        stepProps.items.control &&
        stepProps.items.control === "select.user"
      ) {
        const { tabs, properties: props } = stepProps.items;
        Object.values(values || []).forEach((value, valueIndex) => {
          const { tabId, isLegal } = value || {};
          const filterFields = isLegal ? unLegalFields : legalFields;
          const keys = Object.keys(props);
          let fields = keys.filter(
            (key) => !filterFields.find((id) => id === key)
          );
          if (tabs && tabId) {
            fields = keys.filter((key) =>
              props[key].tabsIds
                ? !!props[key].tabsIds.find((id) => id === tabId)
                : true
            );
          }
          const found = fields.find((item) => item === req);
          if (found) {
            errors[valueIndex] =
              errors[valueIndex] &&
              Object.keys(errors[valueIndex]).reduce((acc, key) => {
                const foundInFields = fields.find((field) => field === key);
                if (foundInFields) acc[key] = errors[valueIndex][key];
                return acc;
              }, {});
          } else if (errors[valueIndex] && errors[valueIndex][req]) {
            delete errors[valueIndex][req];
          }

          if (errors[valueIndex] && !Object.keys(errors[valueIndex]).length) {
            delete errors[valueIndex];
          }
        });
      }
      return req;
    });
  if (required && !Object.values(values).length) {
    errors[0] = t("REQUIRED");
  }

  if (!required && !Array.isArray(values) && !values.errors) {
    Object.keys(errors).forEach((key) => {
      const filteredValues = Object.keys(values).filter(
        (val) => typeof values[val] === "string"
      );
      if (!filteredValues.length) delete errors[key];
    });
  }

  if ("properties" in stepProps) {
    Object.keys(stepProps.properties).forEach((key) => {
      const { control } = stepProps.properties[key];
      if (control === "checkboxesTree" && values[key] && values[key].error) {
        const getErrors = Object.values(values[key].error).filter(Boolean);
        if (getErrors.length > 0) {
          errors[key] = values[key].error;
        }
      }
    });
  }

  const result = {};

  Object.keys(errors)
    .filter((key) => errors[key])
    .forEach((key) => {
      result[key] = errors[key];
    });

  return result;
}
