import {
  ApplicationDialogState,
  ButtonType,
  CLOSE_APP_DIALOG,
  OPEN_APP_DIALOG,
} from "../reducers/appDialogTypes";

type OpenDialogArgs = Omit<ApplicationDialogState, "open" | "resolveDialog">;

export const openAppDialog = (args: OpenDialogArgs) => (dispatch: any) => {
  return new Promise((resolve) => {
    dispatch({
      type: OPEN_APP_DIALOG,
      payload: {
        ...args,
        resolveDialog: resolve,
      },
    });
  });
};

export const closeAppDialog = () => (dispatch: any, getState: any) => {
  return dispatch({
    type: CLOSE_APP_DIALOG,
  });
};

export const checkLegalEntityAgentRole =
  (role: string) => (dispatch: any, getState: any) => {
    const {
      authorization: { info },
    } = getState() || { authorization: {} };
    const { isLegalEntityHead, legalEntityId, companyName } = info || {};

    if (!info || role !== "legalEntityAgent" || legalEntityId) {
      return Promise.resolve(ButtonType.OK);
    }

    const text = isLegalEntityHead
      ? `Профіль ${companyName} не знайдено в системі. Бажаєте зареєструвати?`
      : `Профіль ${companyName} не знайдено в системі. Тільки перша особа може зареєструвати профіль.`;

    const buttons = isLegalEntityHead
      ? [
          {
            text: "Зареєструвати",
            value: ButtonType.YES,
            color: "yellow" as const,
          },
          {
            text: "Ні",
            value: ButtonType.CANCEL,
            color: "transparent" as const,
          },
        ]
      : [
          {
            text: "OK",
            value: ButtonType.CANCEL,
            color: "transparent" as const,
          },
        ];

    return dispatch(openAppDialog({ title: "Увага", text, buttons }));
  };
