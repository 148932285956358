import React, { Component } from "react";
import { compose } from "redux";

import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../../variables/quillSettings";
// @ts-ignore
// import renderHTML from "react-render-html";

import "../../../assets/css/quill.theme.css";
// import FormHelperText from "@material-ui/core/FormHelperText";

import { createStyles, withStyles } from "@material-ui/core/styles";
import { FormControl, FormHelperText } from "@material-ui/core";

import { StandardTextFieldProps } from "@material-ui/core/TextField";
import { BaseControlProps } from "../types";

import rawStyles from "../../../variables/styles/textArea";
// @ts-ignore
export const styles = createStyles(rawStyles);

interface TextareaControlProps
  extends BaseControlProps<StandardTextFieldProps> {}

interface TextareaControlState {
  value: string;
}

class TextareaControl extends Component<
  TextareaControlProps,
  TextareaControlState
> {
  static defaultProps = {};

  constructor(props: TextareaControlProps) {
    super(props);
    this.state = {
      value: "",
    };
  }

  handleTextChange = (content: string) => {
    const { onChange, name } = this.props;
    if (!content.replace(/<\/?[^>]+>/g, "")) {
      content = "";
    }
    onChange && onChange(name, content);
  };

  render() {
    const {
      classes,
      // disabled,
      errors,
      // error,
      // hidden,
      name,
      path,
      readOnly,
      // required,
      schema,
      setId,
      value,
    } = this.props;
    const id = setId(path || name);

    return (
      <FormControl
        // {...formControlProps}
        error={!!errors}
        id={setId(id)}
        className={classes.formControl}
      >
        {schema.description ||
          (errors && (
            <FormHelperText>
              {!readOnly && (errors || schema.description)}
            </FormHelperText>
          ))}
        <ReactQuill
          modules={quillModules}
          formats={quillFormats}
          value={value || ""}
          // @ts-ignore
          style={null}
          // @ts-ignore
          className={[classes.quill, errors && classes.quillErrored]
            .filter(Boolean)
            .join(" ")}
          onChange={this.handleTextChange}
          id={id}
        />
      </FormControl>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(
  TextareaControl
);
