import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Timeline } from "@material-ui/icons";
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";
import { CLAIM_WIKI_URL } from "../../../variables/wikiUrls";
// import { setWizardStates } from "../../../actions/claim";
// import checkECP from "../../../helpers/checkECP";
// import KeyPng from "../../../assets/img/key.svg";
// import downloadBase64Attach from "../../../helpers/downloadBase64Attach";
import {
  // downloadScanFile,
  // downloadStaticFile,
  // saveFile,
  requestDocumentHistory,
  downloadScanArchive,
  downloadStaticArchive,
} from "../../../actions/cases";

import {
  handleDownloadAttach,
  requestAttachPreviews,
} from "../decorators/attaches";

import getDocumentFileName from "../../../helpers/getDocumentFileName";
import { getShortNameFromString } from "../../../helpers/getUserShortName";
import setComponentsId from "../../../helpers/setComponentsId";

import Attaches from "./attaches";
import { RegularCard, Button, BlockScreen } from "../../../components";
import PageCardTitle from "../../../components/PageCardTitle";
import HistoryCardDialog from "../../../widgets/HistoryCardDialog";

const getErrorText = (error, t, updatedByName, digitalDocumentData) => {
  if (error === "UPLOAD_ERROR") {
    const { uploadError } = digitalDocumentData || {};
    return t("UPLOAD_ERROR", { count: uploadError });
  }
  if (error && `${error}`.includes("FileReader")) return t("FILEREADER_ERROR");
  if (error && `${error}`.includes("Sign certificate PEM"))
    return t("SIGN_CERTIFICATE_PEM_NOT_MATCH_USERS_PEM");
  if (error && `${error}`.includes("isSign")) return t("DOCUMENT_IS_SIGNED");
  if (error && `${error}`.includes("isDeleted"))
    return t("DOCUMENT_IS_DELETED");
  const errorLabel =
    error &&
    `${error}`
      .match(/[\w\s]+/g)
      .join("")
      .split(" ")
      .join("_")
      .toUpperCase();
  const errorTranslate = t(errorLabel, {
    user: getShortNameFromString(updatedByName),
  });
  return errorTranslate.includes("ClaimWizard")
    ? t("UNKNOWN_ERROR")
    : errorTranslate;
};

const ClaimWizardLayout = ({
  t,
  setId,
  // docSignPDF,
  classes,
  claim,
  courts,
  template,
  claimCommited,
  error,
  stepOrders,
  activeStepId,
  pdf,
  blockScreen,
  courtNotFound,
  redirect,
  children,
  closeCourtNotFound,
  closeSnackbar,
  closeCommitModal,
  redirectTo,
}) => {
  /// ---
  /// history
  const [loading, setLoading] = useState(false);
  const [historyRows, setHistoryRows] = useState(null);
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const getDateStr = (date) => {
    return date && humanDateTimeFormat(date);
  };

  const getCourt = (viewDocument, courts) => {
    let { courtId } = viewDocument || claim;
    let courtName = "";
    if (courtId && courts) {
      const foundCourt = courts.find(({ id }) => id === courtId);
      if (foundCourt) {
        courtName = foundCourt.name;
      }
    }
    return courtName || "";
  };

  const createHistoryData = (data = {}) => {
    const {
      docState,
      changeStateDate,
      signDate,
      sentPartyDate,
      sentPartyTo,
      sendDate,
      deliveryDate,
      regDate,
    } = data;

    let tableData = [
      [t("NAME"), claim?.description],
      [t("COURT"), getCourt(claim, courts)],
      [t("CASE_NUMBER"), claim?.caseNumber],
      [t("PROCEEDINGS_NUMBER"), claim?.procNumber],
      [t("STATE"), docState],
      [t("DATE_OF_STATUS_CHANGE"), getDateStr(changeStateDate)],
      [t("DATE_OF_SIGNING_THE_KEP"), getDateStr(signDate)],
      [t("DATE_SENT_TO_THE_SIDES"), getDateStr(sentPartyDate)],
      [t("RECEIVED_SIDES"), sentPartyTo],
      [t("DATE_SENT_TO_COURT"), getDateStr(sendDate)],
      [t("DATE_OF_DELIVERY_TO_COURT"), getDateStr(deliveryDate)],
      [t("DATE_OF_REGISTRATION_IN_COURT"), getDateStr(regDate)],
    ];

    return tableData;
  };

  const showHistory = async () => {
    const throwError = (msg) => {
      throw new Error(msg);
    };

    try {
      setLoading(true);
      const resp = await requestDocumentHistory(claim?.id);

      if (resp instanceof Error) {
        throwError(resp.message);
      }

      const tableData = createHistoryData(resp);
      setHistoryRows(tableData);
      setIsOpenHistory(true);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };
  /// ---

  const { updatedByName, documentStateInfo, digitalDocumentData } = claim || {};
  let stepName = template ? stepOrders[activeStepId] : "claims";
  let videourl = "";

  if (
    template &&
    template.jsonSchema &&
    template.jsonSchema.properties &&
    template.jsonSchema.properties[stepName]
  ) {
    ({ videourl } = template.jsonSchema.properties[stepName] || {});
  }

  if (claim && claim.category === 6 && stepName === "form") stepName = "form2";

  let title = template ? template.name : "";

  if (claim && !template) {
    title = claim.category === 4 ? t("EXECUTIVE_DOCUMENT") : t("HEADER");
  }

  const download = async () => {
    // setWizardStates({
    //   loading: true,
    // });
    try {
      const downloadArchive = claim.digitalDocumentStaticFile
        ? downloadStaticArchive
        : downloadScanArchive;
      const blob = await downloadArchive(claim?.id);
      // const blob = await downloadScanArchive(claim?.id);
      if (blob.status === 500) {
        return alert(blob.serverMessage + ", " + blob.details);
      }

      const name = getDocumentFileName(claim);
      const { document } = window;
      const URL = window.URL || window.webkitURL;

      const element = document.createElement("a");
      element.setAttribute("href", URL.createObjectURL(blob));
      element.setAttribute("download", name);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch (error) {
      if (error instanceof Error) {
        return alert(error.serverMessage + " " + error.details);
      }
      // const { source, format } = this.state;
      // const { name } = this.props;
      // downloadBase64Attach(
      //   {
      //     docId: claim?.id,
      //     fileName: name,
      //     contentType: format,
      //   },
      //   source
      // );
    } finally {
      // setWizardStates({
      //   loading: false,
      // });
    }
  };

  return (
    <Fragment>
      {error && (
        <Snackbar
          id={setId("error")}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          message={
            <span id="message-id" name={error}>
              {getErrorText(error, t, updatedByName, digitalDocumentData)}
            </span>
          }
          action={[
            <Button
              key="close-error"
              color="yellow"
              size="small"
              onClick={closeSnackbar(error)}
              className={classes.smallButton}
            >
              OK
            </Button>,
          ]}
        />
      )}
      {courtNotFound && (
        <Dialog
          className={classes.dialog}
          open={courtNotFound}
          id={setId("court-not-found-dialog")}
          onClose={closeCourtNotFound}
        >
          <DialogTitle
            className={cx(classes.dialogContentWrappers, classes.error)}
            id={setId("court-not-found-dialog")}
          >
            <span className={classes.error}>{t("APPEAL_COURT_NOT_FOUND")}</span>
          </DialogTitle>
          <DialogActions
            className={cx(classes.actions, classes.dialogContentWrappers)}
            id={setId("dialog-actions")}
          >
            <Button
              color="white"
              onClick={closeCourtNotFound}
              id={setId("close-button")}
              setId={(elementName) =>
                setId(`court-not-found-close-${elementName}`)
              }
            >
              {t("CLOSE")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <RegularCard
        headerColor="primary"
        setId={setId}
        cardTitle={
          <PageCardTitle
            text={title}
            status={claim ? claim.state : ""}
            stepName={stepName}
            templateId={template ? template.id : null}
            isLastStep={activeStepId === stepOrders.length - 1}
            setId={setId}
            pdf={pdf}
            t={t}
            videourl={videourl}
            documentStateInfo={documentStateInfo}
            placement="right"
            claim={claim}
            wikiUrl={CLAIM_WIKI_URL}
          />
        }
        content={
          <div id={setId("content")}>
            {activeStepId === -1 && !blockScreen && (
              <div
                className="button-raw"
                style={{
                  right: "0",
                  display: "inline",
                  marginRight: "5px",
                }}
              >
                {/* {formatedSource && ( */}
                <IconButton
                  color="inherit"
                  // onClick={showHistory}
                  onClick={showHistory}
                  title={t("DOCUMENT_HISTORY")}
                  className={classes.menuButton}
                >
                  <Timeline />
                </IconButton>
                {/* )} */}

                <IconButton
                  color="inherit"
                  onClick={() => download()}
                  title={t("SAVE_TO_DEVICE")}
                  className={classes.menuButton}
                >
                  <SaveAlt />
                </IconButton>

                {isOpenHistory && historyRows && (
                  <HistoryCardDialog
                    t={t}
                    classes={classes}
                    openDialog={isOpenHistory}
                    toggleDialog={() => setIsOpenHistory(false)}
                    title={t("СLAIM_HISTORY_CART")}
                    historyRows={historyRows}
                    setId={(name) => setId(`HistoryCardDialog - ${name} `)}
                  />
                )}
              </div>
            )}
            {children}
            {claimCommited && (
              <Dialog
                className={classes.dialog}
                open={claimCommited}
                id={setId("commited-dialog")}
                onClose={closeCommitModal}
              >
                <DialogTitle
                  className={classes.dialogContentWrappers}
                  id={setId("commited-dialog-title")}
                >
                  {t(`${redirectTo.text || redirectTo.title}_COMMITED`)}
                </DialogTitle>
                <DialogContent className={classes.dialogContentWrappers}>
                  <DialogContentText id={setId("commited-dialog-text")}>
                    {t(`${redirectTo.text || redirectTo.title}_COMMITED_TEXT`)}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  id={setId("commited-dialog-actions")}
                  className={classes.dialogContentWrappers}
                >
                  <Button
                    onClick={redirect(redirectTo.getUrl(claim))}
                    color="yellow"
                    id={setId(`redirect-to-${redirectTo.name}`)}
                    setId={(elmentName) =>
                      setId(`redirect-to-${redirectTo.name}-${elmentName}`)
                    }
                  >
                    {t(`REDIRECT_TO_${redirectTo.title}`)}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
        }
      />
      {claim && (claim.state !== 1 || (claim.state === 1 && pdf)) && (
        <Attaches
          claim={claim}
          handleDownload={handleDownloadAttach}
          requestPreview={requestAttachPreviews}
          setId={(elmentName) => setId(`attaches-${elmentName}`)}
        />
      )}
      <BlockScreen
        open={loading || blockScreen}
        transparentBackground={!!pdf}
      />
    </Fragment>
  );
};

ClaimWizardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,
  claim: PropTypes.object,
  template: PropTypes.object,
  claimCommited: PropTypes.bool.isRequired,
  error: PropTypes.any,
  stepOrders: PropTypes.array.isRequired,
  activeStepId: PropTypes.number.isRequired,
  pdf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  blockScreen: PropTypes.bool.isRequired,
  courtNotFound: PropTypes.bool.isRequired,
  children: PropTypes.node,
  closeCourtNotFound: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  closeCommitModal: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  redirectTo: PropTypes.object.isRequired,
};

ClaimWizardLayout.defaultProps = {
  setId: setComponentsId("claim-wizard"),
  claim: null,
  template: null,
  error: null,
  pdf: null,
  children: <Fragment />,
};

export default ClaimWizardLayout;

// ---
// ---
// unusable code (in development)

// const showECP = async () => {
//   try {
//     // this.setLoading(true);

//     setWizardStates({
//       loading: true,
//     });
//     // let localPdf = await checkECP(claim?.id);
//     // console.log("localPdf", localPdf);
//     // return localPdf;
//     setWizardStates({
//       docSignPDF: await checkECP(claim?.id),
//     });
//     console.log('docSignPDF', docSignPDF)
//   } catch (e) {
//     alert(e.message || "404 File not found");
//   } finally {
//     // this.setLoading(false);

//     setWizardStates({
//       loading: false,
//     });
//   }
// };

// const checkECP = async () => {
//   try {
//     setLoading(true);
//     const resp = await checkECP(this.documentId);
//     if (resp instanceof Error) {
//       throwError(resp.message);
//     }
//     setDocSignPDF(docPDF);
//   } catch (e) {
//     console.log(e.message || "404 File not found");
//   } finally {
//     setLoading(false);
//   }
// };

/* showECP - В розробці */
/* <IconButton
  color="inherit"
  // onClick={() => showECP()}
  onClick={() => alert("Developing...")}
  title={t("CHECK_ECP")}
  className={classes.menuButton}
>
  <img
    src={KeyPng}
    alt={t("CHECK_ECP")}
    width={24}
    height={24}
  />
</IconButton> */

// ---
// ---
