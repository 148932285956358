import promiseChain from "../../../../helpers/promiseChain";
import { replacePoint, valueToString } from "../Payment.jsx";

export default function (openForm = false, itsFirstUpdate = false) {
  const {
    actions: { requestPaymentForm },
    t,
  } = this.props;
  const { isUpdating, useBenefits } = this.state;
  let { newSum } = this.state;
  if (useBenefits) {
    return null;
  }
  if (isUpdating) {
    return this.updatePaymentForm(openForm, itsFirstUpdate);
  }
  if (newSum === "0" && !itsFirstUpdate) {
    return this.setState({ noCaseError: t("NO_PAY_ERROR") });
  }
  return this.setState({ busy: true, isUpdating: true }, () =>
    promiseChain([
      requestPaymentForm,
      (form) => {
        let noCaseError = "";
        if (form.message) {
          switch (form.message) {
            case "Court not found in document data.":
            case "Court not found.":
              noCaseError = t("NO_CASE_ERROR");
              break;
            case "Court code 2007 not found but needed for payment system.":
            case "Can't get payment form.":
              noCaseError = t("NO_FORM_ERROR");
              break;
            case "Amount to pay not defined.":
              noCaseError = t("NO_PAY_ERROR");
              break;
            case "Payer info not defined.":
              noCaseError = t("NO_PAYER_INFO_ERROR");
              break;
            default:
              noCaseError = t("NO_PAY_OR_CASE_ERROR");
              break;
          }
        }
        const {
          value,
          value: { totalSum },
        } = this.props;
        ({ newSum } = this.state);
        const { otherAmount } = this.state;
        const sum = otherAmount
          ? valueToString(value, newSum)
          : replacePoint(totalSum);
        if (sum !== newSum) {
          newSum = sum;
        }
        return this.setState(
          {
            newSum,
            noCaseError,
            form,
            busy: false,
            isUpdating: false,
          },
          () => {
            openForm && !noCaseError && this.handleOpenPayWindow();
          }
        );
      },
    ])
  );
}
