import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { IconButton, LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { Visibility, SaveAlt, Close } from "@material-ui/icons";

import setComponentsId from "../../helpers/setComponentsId";

import { Table } from "../../components";

import downloadBase64Attach from "../../helpers/downloadBase64Attach";
import { blobToTextEncoded } from "../../helpers/blobToText";
import stringToBlob from "../../helpers/stringToBlob";
import blobToBase64 from "../../helpers/blobToBase64";

import getFormat from "../../helpers/getAttachFormat";
import { getFormat as getAttachFormat } from "../../helpers/getAttachFormat";

import formatFile from "../../helpers/formatFile";
import getFileUrl from "../../helpers/getFileUrl";
import getAttachName from "../../helpers/getAttachName";
import PreviewDialog from "./PreviewDialog";

const SHOW_FORMATS = ["pdf", "video", "audio", "html", "image"];
const NOT_SHOW_FORMATS = ["binary", "googleViewDoc", "zip", "text", "unknown"];

const fields = {
  pagination: true,
  tableFields: [
    {
      key: "name",
      title: "NAME",
      classNames: ["cell"],
      grid: [1, 4, 1, 2],
    },
    {
      key: "type",
      title: "TYPE",
      classNames: ["cell"],
      grid: [1, 4, 3, 4],
    },
    {
      key: "icon",
      title: "PREVIEW",
      classNames: ["cell", "textRight"],
      grid: [5, 7, 1, 4],
    },
  ],
};

const initalState = {
  name: "",
  url: "",
  preview: "",
  doc: "",
  openDialog: false,
  file: null,
  format: "",
  activeAttach: null,
  text: "",
};

class AttachTable extends Component {
  state = { ...initalState };

  setDocType = async (file, string = "") => {
    const text = string || (await blobToTextEncoded("Windows-1251")(file));
    const formatingFile = await formatFile(file, text);
    const format = getFormat(formatingFile, text);
    const url = await getFileUrl(formatingFile, format, text);
    this.setState({
      format,
      type: file.type,
      url,
      text,
      file: formatingFile,
    });
  };

  setDoc = (toggleDialog = false) => {
    const { handleDownload } = this.props;
    const { activeAttach } = this.state;
    this.setState({
      doc: null,
      format: "",
      url: "",
      file: null,
    });
    handleDownload(activeAttach)().then((file) => {
      if (typeof file === "object" && (file.message || !file.size)) {
        this.setState({
          text: file ? file.message || "404 File not found" : "",
          format: file ? "text" : "",
          preview: "",
          doc: null,
        });
      } else if (file && typeof file === "object" && !file.message) {
        blobToBase64(file).then((doc) =>
          this.setState(
            {
              doc,
              preview: doc,
              loading: !doc,
            },
            () => this.setDocType(file)
          )
        );
      } else if (typeof file === "string") {
        const blob = stringToBlob(file);
        this.setState({ loading: false }, () => this.setDocType(blob, file));
      }

      // toggleDialog && this.toggleDialog();
      if (file) {
        this.setState({ openDialog: true });
      } else {
        this.setState({ ...initalState });
      }
    });
  };

  toggleDialog = () => this.setState({ openDialog: !this.state.openDialog });

  openDialog = (attach) => () => {
    const { activeAttach } = this.state;
    const {
      contentType,
      fileName,
      name: attachName,
      userFileName,
      type,
      mimeType,
    } = attach;

    const name = userFileName || attachName || fileName;

    const PREVIEW_FORMAT = getAttachFormat(
      contentType || type || mimeType || ""
    );

    if (
      !SHOW_FORMATS.includes(PREVIEW_FORMAT) &&
      NOT_SHOW_FORMATS.includes(PREVIEW_FORMAT)
    ) {
      this.handleDownload(attach)();
    }
    // if (format === "binary" || format === "unknown") {
    //   this.handleDownload(attach)();
    // }
    else if (
      activeAttach &&
      activeAttach.attachId === attach.attachId &&
      this.state.name === name
    ) {
      this.setState({ openDialog: true });
    } else {
      this.setState(
        {
          ...initalState,
          activeAttach: attach,
        },
        () => this.setDoc(true)
      );
    }
    this.setState({ name });
  };

  handleDownload = (attach) => () =>
    this.props
      .handleDownload(attach)()
      .then((file) => {
        if (typeof file === "string") {
          file = stringToBlob(file);
        }
        const { name, fileName, userFileName } = attach;
        downloadBase64Attach(
          {
            userFileName,
            propsName: name,
            fileName,
          },
          file
        );
      });

  getText = (item, key) => {
    const { t, classes, dataIsLoading, handleDelete } = this.props;
    const { contentType, fileName, name, type, mimeType, userFileName } = item;
    const PREVIEW_FORMAT = getAttachFormat(
      contentType || type || mimeType || ""
    );
    switch (key) {
      case "name":
        return getAttachName({
          userFileName,
          name,
          fileName,
          item,
        });
      case "type":
        return t(PREVIEW_FORMAT.toUpperCase());
      case "icon":
        return (
          <Fragment>
            <IconButton
              color="inherit"
              onClick={this.openDialog(item)}
              className={classes.menuButton}
              disabled={
                (!SHOW_FORMATS.includes(PREVIEW_FORMAT) &&
                  NOT_SHOW_FORMATS.includes(PREVIEW_FORMAT)) ||
                dataIsLoading
                // format === "binary" || format === "unknown" || dataIsLoading
              }
            >
              <Visibility />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={!dataIsLoading ? this.handleDownload(item) : () => null}
              className={classes.menuButton}
              disabled={dataIsLoading}
            >
              <SaveAlt />
            </IconButton>
            {handleDelete && (
              <IconButton
                color="inherit"
                onClick={!dataIsLoading ? handleDelete(item) : () => null}
                className={classes.menuButton}
                disabled={dataIsLoading}
              >
                <Close />
              </IconButton>
            )}
          </Fragment>
        );
      default:
        return item[key];
    }
  };

  render() {
    const {
      list,
      t,
      setId,
      pagination,
      changeCount,
      dataSource,
      dataIsLoading,
      classes,
    } = this.props;

    let { activeAttach, openDialog } = this.state;

    return (
      <div className={classes.relativePosition}>
        {dataIsLoading && (
          <LinearProgress className={classes.absolutePosition} />
        )}
        <Table
          fields={fields}
          getText={this.getText}
          setId={setId}
          onCheckItem={() => null}
          pagination={pagination}
          changeCount={changeCount}
          list={list}
          t={t}
          labelRowsPerPage="COUNT"
          labelDisplayedRows="DISPLAYED"
          needFullData={true}
          dataSource={dataSource}
        />
        {dataIsLoading && (
          <LinearProgress className={classes.absolutePosition} />
        )}
        {this.props.extState === null && (
          <PreviewDialog
            {...this.state}
            // text={"Для можливості переглянути чи завантажити додаток до документу судового засідання необхідно сплатити судовий збір."}
            error="Для можливості переглянути чи завантажити додаток до документу судового засідання необхідно сплатити судовий збір."
            openDialog={openDialog}
            setId={(elementName) => setId(`preview-dialog-${elementName}`)}
            toggleDialog={this.toggleDialog}
            handleDownload={this.handleDownload(activeAttach)}
          />
        )}
        {this.props.extState !== null && (
          <PreviewDialog
            {...this.state}
            openDialog={openDialog}
            setId={(elementName) => setId(`preview-dialog-${elementName}`)}
            toggleDialog={this.toggleDialog}
            handleDownload={this.handleDownload(activeAttach)}
          />
        )}
      </div>
    );
  }
}

AttachTable.propTypes = {
  list: PropTypes.array.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  pagination: PropTypes.func.isRequired,
  changeCount: PropTypes.func.isRequired,
  dataIsLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
};

AttachTable.defaultProps = {
  setId: setComponentsId("claim-table"),
  handleDelete: undefined,
};

const translated = translate("Attach")(AttachTable);

const mapStateToProps = ({ datafetched: { loading: dataIsLoading } }) => ({
  dataIsLoading,
});

export default connect(mapStateToProps)(translated);
