export default {
  dropZone: {
    width: "calc(100% - 4px)",
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 0.54)",
    borderStyle: "dashed",
    borderRadius: 5,
    marginTop: 20,
    padding: "8px 0",
    textAlign: "center",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  },
  dropZoneActive: {
    background: "#cdd7e3",
  },
};
