import blue from "@material-ui/core/colors/blue";

export default {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  search: {
    width: "100%",
    marginTop: 0,
  },
  dialog: {
    "& > :last-child": {
      "@media (max-width:767px)": {
        // eslint-disable-line no-useless-computed-key
        margin: "48px 10px",
      },
    },
  },
  dialogContentWrappers: {
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "24px 15px 20px",
    },
  },
};
