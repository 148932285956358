export const getRoleSimpleStr = (execMyRole, t) => {
  if (execMyRole === "debtor") return t("DEBTOR");
  if (execMyRole === "collector") return t("COLLECTOR");
  if (execMyRole === "payer") return t("PAYER");
  if (execMyRole === "debtorRepresentative")
    return (
      <div title={t("REPRESENTATIVE") + " " + t("DEBTOR") + "a"}>
        {" "}
        {t("REPRESENTATIVE")} <br /> {t("DEBTOR") + "a"}
      </div>
    );
  if (execMyRole === "collectorRepresentative")
    return (
      <div title={t("REPRESENTATIVE") + " " + t("COLLECTOR") + "a"}>
        {" "}
        {t("REPRESENTATIVE")} <br /> {t("COLLECTOR") + "a"}
      </div>
    );
  if (execMyRole === "payerRepresentative")
    return (
      <div title={t("REPRESENTATIVE") + " " + t("PAYER") + "a"}>
        {" "}
        {t("REPRESENTATIVE")} <br /> {t("PAYER") + "a"}
      </div>
    );
};

export const getRoleLineStr = (execMyRole, t) => {
  if (execMyRole === "debtor") return t("DEBTOR");
  if (execMyRole === "collector") return t("COLLECTOR");
  if (execMyRole === "payer") return t("PAYER");
  if (execMyRole === "debtorRepresentative")
    return t("REPRESENTATIVE") + " " + t("DEBTOR") + "a";
  if (execMyRole === "collectorRepresentative")
    return t("REPRESENTATIVE") + " " + t("COLLECTOR") + "a";
  if (execMyRole === "payerRepresentative")
    return t("REPRESENTATIVE") + " " + t("PAYER") + "a";
};
