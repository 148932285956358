import React from "react";
import PropTypes from "prop-types";
// import {connect} from 'react-redux';
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CaseNumberFilterChip = ({ t, value, ...rest }) => (
  <Chip label={`${t("CASE_NUMBER")}: ${value}`} {...rest} />
);

CaseNumberFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CaseNumberFilterChip.defaultProps = {
  value: "",
};

export default translate("SearchPage")(CaseNumberFilterChip);
