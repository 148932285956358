import * as api from "../services/api";
import store from "../store";
import { ADD_ACTIVE_MESSAGE } from "../reducers/notifications";

const { dispatch } = store;

const DELETE_SNACKBAR_MESSAGE = "DELETE_SNACKBAR_MESSAGE";
const SET_IS_READ_NOTIFICATION = "SET_IS_READ_NOTIFICATION";
const SET_IS_NOT_READ_NOTIFICATION = "SET_IS_NOT_READ_NOTIFICATION";
const SET_DELETE_NOTIFICATION = "SET_DELETE_NOTIFICATION";
const SET_DELETE_ALL_NOTIFICATIONS = "SET_DELETE_ALL_NOTIFICATIONS";
const REQUEST_ACTIVE_MESSAGE = "REQUEST_ACTIVE_MESSAGE";

export function deleteSnackbarMessage(id) {
  return dispatch({ type: DELETE_SNACKBAR_MESSAGE, payload: id });
}

export function setIsRead(messageIds, message = null, needRequest = true) {
  if (!needRequest || (message && !!message.isRead)) {
    return dispatch({ type: ADD_ACTIVE_MESSAGE, payload: message });
  }
  dispatch({ type: ADD_ACTIVE_MESSAGE, payload: message });
  return api.put(
    "api/messages/set_is_read",
    { messageIds },
    SET_IS_READ_NOTIFICATION,
    dispatch
  );
}

export function setIsNotReadMessage(messageIds) {
  return api.put(
    "api/messages/set_is_unread",
    { messageIds },
    SET_IS_NOT_READ_NOTIFICATION,
    dispatch
  );
}

export function setIsDeleteAllMessages() {
  return api.put(
    "api/messages/set_is_deleted_all",
    undefined,
    SET_DELETE_ALL_NOTIFICATIONS,
    dispatch
  );
}

export function setIsDeleteMessage(messageIds) {
  return api.put(
    "api/messages/set_is_deleted",
    { messageIds },
    SET_DELETE_NOTIFICATION,
    dispatch
  );
}

export function requestActiveMessage(messageId) {
  return api.get(`api/messages/${messageId}`, REQUEST_ACTIVE_MESSAGE, dispatch);
}
