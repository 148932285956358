import { fade } from "@material-ui/core/styles/colorManipulator";

const drawerWidth = 420;

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  header: {
    background: "#00224e",
    color: "#ffffff",
    padding: "18px 0",
    "& a": {
      color: "#ffffff",
    },
  },
  panelSummary: {
    background: "#0059aa",
    color: "#ffffff",
    minHeight: "48px !important",
  },
  panelSummaryContent: {
    margin: "12px 0 !important",
  },
  panelDetails: {
    padding: 24,
    flexDirection: "column",
  },
  absolutePosition: {
    position: "absolute",
    width: "100%",
    zIndex: 2,
  },
  grow: {
    flexGrow: 1,
  },
  legend: {
    marginBottom: 0,
    marginTop: 32,
  },
  headerButtons: {
    textAlign: "center",
  },
  wrapper: {
    padding: "20px",
  },
  wrapperSearch: {
    padding: 20,
    marginBottom: 24,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  expanded: {
    "& > div": {
      overflow: "visible",
    },
  },
  optionsContainer: {
    boxShadow: "none",
    margin: 0,
  },
  dialogBody: {
    background: "#aaa",
  },
  scrollable: {
    overflow: "auto",
  },
  page: {
    maxWidth: 900,
    padding: 20,
    background: "#ffffff",
    "& > ul": {
      display: "none",
    },
    "& > hr": {
      display: "none",
    },
    "& em": {
      background: "#fcd700",
      fontStyle: "inherit",
    },
  },
  paper: {
    boxShadow: "2px 2px 12px rgba(0,0,0,0.5)",
    "@media (min-width:900px)": {
      // eslint-disable-line no-useless-computed-key
      margin: "20px auto",
    },
  },
  card: {
    boxShadow: "2px 2px 12px rgba(0,0,0,0.5)",
    margin: "-12px",
    padding: 12,
  },
  documentHeader: {
    padding: "0 8px",
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {},
  right: {
    position: "absolute",
    right: 0,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 48,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    background: "#ffffff",
    flexGrow: 1,
    height: "100%",
  },
  proceedingChip: {
    margin: 1,
  },
  dateRange: {
    padding: 0,
  },
  dateRangeDivider: {
    margin: "20px 12px 0",
    opacity: 0.5,
  },
  clearFiltersBtn: {
    marginRight: 8,
  },
  withToolbar: {
    height: "calc(100% - 64px)",
  },
});

export default styles;
