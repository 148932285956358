export default {
  BACK: "Повернутись",
  NEXT: "Наступний крок",
  FINISH_STEPPER: "Сформувати",
  FINISH: "Підписати та відправити",
  SIGN: "Підписати",
  REQUIRED: "Обов'язкове поле",
  SAVING_PROCESS: "Збереження...",
  ALL_DATA_SAVED:
    "Дані автоматично збережено. Ви можете повернутись до створення документу в будь-який момент.",
  LIST_BACK_LINK: "До списку",
  SKIP: "Пропустити",
  SEND: "Надіслати",
  SIGN_AND_SEND: "Підписати та надіслати",
  SIGNING: "Підписання",
  SIGNING_DOCUMENT: "Підписання запиту",
  CONTINUE_EDITING: "Повернутись до редагування",
  REQUIRED_FIELDS: "* - заповнювати обов'язково",
  ADD_ITEM: "Додати",
  DELETE_ITEM: "Видалити",
  SERVER_ERROR: "Помилка на сервері",
  CANT_SIGN_DOCUMENT: "Сервер АЦСК не відповідає",
  FAILED_TO_FETCH: "не вдалось передати дані",
  VALIDATION_ERROR: "дані не пройшли перевірку",
  SIGNED_CONTENT_NOT_MATCH_NEEDED:
    "Підписані дані не співпадають з оригіналом.",
  SIGN_CERTIFICATE_PEM_NOT_MATCH_USERS_PEM:
    "Сертифікат не співпадає з сертифікатом користувача.",
  UNKNOWN_ERROR: "Сталася помилка, спробуйте пізніше.",
  CLOSE: "Закрити",
  ERROR_TITLE: "Сталась помилка",
  SAVE: "Зберегти",
  SIGNED: "підписано",
  WAITING_FOR_SIGN: "Очікує на підписання",
  NO_PERM: "Ви не маєте право підпису",
  ERROR_UPLOADING_FILE: "Виникла помилка при завантаженні файлу",
  CHECK_CERTIFICATE_ERROR:
    "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено",
};
