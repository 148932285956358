import Attach from "./ua/Attach";
import Auth from "./ua/Auth";
import CaseList from "./ua/CaseList";
import CheckCabinet from "./ua/CheckCabinet";
import ClaimList from "./ua/ClaimList";
import ClaimNew from "./ua/ClaimNew";
import ClaimWizard from "./ua/ClaimWizard";
import ConferenceForm from "./ua/ConferenceForm";
import ConferenceList from "./ua/ConferenceList";
import DatePicker from "./ua/DatePicker";
import DocumentWizard from "./ua/DocumentWizard";
import Elements from "./ua/Elements";
import ExecutiveDocs from "./ua/ExecutiveDocs";
import ExecutiveOrders from "./ua/ExecutiveOrders";
import Header from "./ua/Header";
import IdGovWidget from "./ua/IdGovWidget";
import InputDocs from "./ua/InputDocs";
import LoginPage from "./ua/LoginPage";
import MimeType from "./ua/MimeType";
import Notifications from "./ua/Notifications";
import Payment from "./ua/Payment";
import PhoneEditModal from "./ua/PhoneEditModal";
import RequestList from "./ua/RequestList";
import SchemaForm from "./ua/SchemaForm";
import SchemaWizard from "./ua/SchemaWizard";
import SearchPage from "./ua/SearchPage";
import SelectFileArea from "./ua/SelectFileArea";
import SelectUserDialog from "./ua/SelectUserDialog";
import Share from "./ua/Share";
import Sidebar from "./ua/Sidebar";
import SignForm from "./ua/SignForm";
import StateRegisters from "./ua/StateRegisters";
import TwoFactorModal from "./ua/TwoFactorModal";
import UserProfile from "./ua/UserProfile";
import VideoManual from "./ua/VideoManual";
import Warning from "./ua/Warning";
import IndexPage from "./ua/IndexPage";

export default {
  // the `locale` parameter is mandatory, it enables react-translate to use
  // the right rules for singular and plural
  locale: "en",
  Attach,
  Auth,
  CaseList,
  CheckCabinet,
  ClaimList,
  ClaimNew,
  ClaimWizard,
  ConferenceForm,
  ConferenceList,
  DatePicker,
  DocumentWizard,
  Elements,
  ExecutiveDocs,
  ExecutiveOrders,
  Header,
  IdGovWidget,
  InputDocs,
  LoginPage,
  MimeType,
  Notifications,
  Payment,
  PhoneEditModal,
  RequestList,
  SchemaForm,
  SchemaWizard,
  SearchPage,
  SelectFileArea,
  SelectUserDialog,
  Share,
  Sidebar,
  SignForm,
  StateRegisters,
  TwoFactorModal,
  UserProfile,
  VideoManual,
  Warning,
  IndexPage,
};
