import React from "react";
import PropTypes from "prop-types";
import RegisterChip from "./RegisterChip";

const JudgmentCode = ({ value, ...rest }) => {
  const filter = (region) => value.includes(region.id);

  return (
    <RegisterChip
      value={value}
      filter={filter}
      arrayKey="regions"
      valueKey="region_code"
      title="REGIONS"
      {...rest}
    />
  );
};

JudgmentCode.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

JudgmentCode.defaultProps = {
  value: [],
};

export default JudgmentCode;
