// import { emphasize } from "@material-ui/core/styles/colorManipulator";

export default {
  schemaForm: {
    maxWidth: 800,
  },
  schemaFormContent: {
    margin: 0,
    pending: 0,
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    // position: "relative",
    maxWidth: 700,
    display: "flex",
    "@media (max-width:500px)": {
      // eslint-disable-line no-useless-computed-key
      "& label": {
        fontSize: "0.75rem",
      },
    },
  },
  formLabel: {
    margin: "10px 0 0 10px",
    fontSize: "1.25rem",
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    // flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  // chip: {
  //   margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  // },
  // chipFocused: {
  //   backgroundColor: emphasize(
  //     theme.palette.type === "light"
  //       ? theme.palette.grey[300]
  //       : theme.palette.grey[700],
  //     0.08
  //   ),
  // },
  // noOptionsMessage: {
  //   padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  // },
  singleValue: {
    fontSize: 16,
  },
  tab: {
    margin: "1px 15px 0 0",
    background: "#E1E1E1",
    color: "#818181",
    display: "inline-flex",
  },
  activeTab: {
    "&, &:focus": {
      background: "#A8CFE8",
      color: "#818181",
    },
  },
  placeholder: {
    // position: "absolute",
    left: 2,
    fontSize: 16,
  },
};
