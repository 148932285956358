export default {
  TITLE: "Додатки",
  NAME: "НАЗВА",
  TYPE: "Тип",
  PREVIEW: "",
  TOTAL: "Всього: {{total}}",
  IMAGE: "Зображення",
  PDF: "PDF документ",
  VIDEO: "Відео",
  AUDIO: "Аудіо",
  BINARY: "Тільки для скачування",
  GOOGLEVIEWDOC: "Документ",
  HTML: "html документ",
  UNKNOWN: "Тільки для скачування",
  DISPLAYED: "с {{from}} по {{to}} із {{total}}",
  COUNT: "Відображати:",
  FILE_NOT_FOUND_1: "Документ в процесі завантаження. ",
  FILE_NOT_FOUND_2: "Спробуйте, будь ласка, пізніше.",
  SAVE_TO_DEVICE: "зберегти файл",
  PRINT: "друк",
};
