/**
 * Проверяет файл name корректность.
 * @param {string} name
 * @param t
 * @returns {null | string} null - если с файлом все ок, string - строка с ошибкой
 */
export function testFileName(name, t) {
  const testFileName =
    /^[0-9A-Za-zА-Яа-яёїЁЇіІЄєҐґ\- /_/'/+/(/)/=/;/^/$/№/₴/@/,!.A-Za-z]+$/i;
  const test = testFileName.test(name || "");

  switch (true) {
    case !name || !(name?.length > 0):
      return t("ERROR_EMPTY_FILE_NAME");
    case !test:
      return t("ERROR_ALLOWED_FILE_SYMBOLS", { fileName: name });
    case name.length > 250:
      return t("ERROR_FILE_NAME_LENGTH");
    default:
      return null;
  }
}

export const normalizeFileName = (filename) => {
  return filename ? filename.normalize("NFC") : "";
};
