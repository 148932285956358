export const InfoBlock = (classes, title, rows, t) => {
  return (
    <div className={classes.infoblock}>
      <div className={classes.title}>{title}</div>
      <table>
        <tbody>
          {rows
            .filter((item) => !!item?.[0] && !!item?.[1])
            .map((item) => (
              <tr>
                <td className={classes.stylesInfoBlockTitle}>{item[0]}</td>
                <td
                  className={
                    t &&
                    item?.[0] === t("VD_STATE") &&
                    item?.[0] === t("VP_STATE")
                      ? classes.stylesWithoutPaddingInfoBlockData
                      : classes.stylesInfoBlockData
                  }
                >
                  {item[1]}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
