import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import cx from "classnames";
import styles from "../../variables/styles/openInNewWindow";

const OpenInNewWindow = ({ url, children, className, classes, title }) => (
  <a
    style={{ color: "inherit" }}
    className={cx(className, classes.link)}
    href={url}
    target={"_blank"}
    rel={"noopener noreferrer"}
    title={title}
  >
    {children}
  </a>
);

OpenInNewWindow.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

OpenInNewWindow.defaultProps = {
  url: "",
  children: "",
  className: "",
  title: "",
};

export default withStyles(styles)(OpenInNewWindow);
