import React, { Component, Children, cloneElement } from "react";
import PropTypes from "prop-types";

import { translate } from "react-translate";

import objectPath from "object-path";

import { withStyles, FormControl } from "@material-ui/core";

import { Button } from "../../components";
import styles from "../../variables/styles/filterInput";

class FilterForm extends Component {
  handleChange =
    (filterName) =>
    ({ target: { checked, value, type } }) => {
      const { autoload, load } = this.props;
      const { dataSource } = this.props;

      let filterValue = type === "checkbox" ? checked : value;
      if (Array.isArray(filterValue) && !filterValue.length) {
        filterValue = "";
      }

      const { aggs } = dataSource;
      aggs[filterName] = !!filterValue || undefined;
      dataSource.setValue("aggs", aggs);
      dataSource.setFilter(filterName, filterValue);
      !autoload && dataSource.setValue("needSearch", true);
      autoload && load();
      this.forceUpdate();
    };

  updateChildProps = (child) => {
    if (typeof child !== "object" || !child) {
      return child;
    }

    const { dataSource } = this.props;
    const newProps = {};

    if (child.props && child.props.name) {
      newProps.onChange = this.handleChange(child.props.name);
      newProps.value =
        objectPath.get(dataSource.filters, child.props.name) || "";
      if (child.type.Naked && child.type.Naked.name === "Checkbox") {
        newProps.checked = newProps.value;
      }
    }

    if (child.props && child.props.children) {
      newProps.children = Children.map(
        child.props.children,
        this.updateChildProps
      );
    }

    if (child.props && child.props.control) {
      newProps.control = this.updateChildProps(child.props.control);
    }

    return cloneElement(child, newProps);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.load();
  };

  render() {
    const { classes, setId, children, load, autoload, t } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <FormControl
          fullWidth={true}
          className={classes.formControl}
          id={setId("popover-form")}
        >
          {Children.map(children, this.updateChildProps)}
        </FormControl>
        {!autoload && (
          <div id={setId("popover-action")}>
            <Button
              className={classes.searchBtn}
              color="yellow"
              onClick={load}
              setId={(elementName) => setId(`submit-${elementName}`)}
              type="submit"
            >
              {t("SEARCH")}
            </Button>
          </div>
        )}
      </form>
    );
  }
}

FilterForm.propTypes = {
  load: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  children: PropTypes.node,
  autoload: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

FilterForm.defaultProps = {
  autoload: false,
  children: "",
};

const styled = withStyles(styles)(FilterForm);
export default translate("ClaimList")(styled);
