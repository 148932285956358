import React from "react";
import RegisterChip from "./RegisterChip";

const PublicationEnd = (props) => (
  <RegisterChip
    title="PUBLICATION_END"
    valueKey="receipt_date_end"
    valueType="string"
    {...props}
  />
);

export default PublicationEnd;
