import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core";
import { PersonalDataChangedHandler, PersonalDataState } from "./types";
import AdvocateEditDialog from "./AdvocateEditDialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import customInputStyle from "../../../variables/styles/customInputStyle";
import classNames from "classnames";

type Props = {
  handleInterfaceChanged: PersonalDataChangedHandler;
  firstName: string;
  lastName: string;
  middleName: string;
  t: (s: string) => string;
  classes: any;
} & Pick<PersonalDataState, "isAdvocate" | "advocate">;

function AdvocateControl({
  isAdvocate,
  handleInterfaceChanged,
  t,
  classes,
  ...rest
}: Props) {
  const [showEditDialog, setShowDialog] = useState(false);

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdvocate}
              onChange={(_, checked) => {
                handleInterfaceChanged("isAdvocate", checked);
              }}
              name={"showAttachesPreview"}
              color="primary"
            />
          }
          label={t("IS_ADVOCATE")}
        />
      </div>

      {isAdvocate && (
        <TextField
          label={t("CERTIFICATE_NUMBER")}
          id={"advocate-certificate-input"}
          className={classNames(classes.input, classes.formControl)}
          value={rest.advocate.certnum}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id={"advocate-certificate-btn"}
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      {showEditDialog && (
        <AdvocateEditDialog
          {...rest}
          t={t}
          handleInterfaceChanged={handleInterfaceChanged}
          closeDialog={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}

const styles = {
  ...customInputStyle,
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
  },
} as const;

export default withStyles(styles)(AdvocateControl);
