import PropTypes from "prop-types";

import { Table, withStyles } from "@material-ui/core";
import cx from "classnames";
import styles from "../../variables/styles/tableStyle";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";

import { hasPermissionForDelete } from "./hasPermissionForDelete";

const getTotal = (list, meta) => {
  return meta?.pagination?.total
    ? meta.pagination.total
    : list?.meta?.pagination?.total
    ? list.meta.pagination.total
    : list.length;
};

const getClasses = (classes, item, ownerList = true) => {
  const { classNames, grid } = item;
  const cn = classNames.map((className) => classes[className]);
  if (grid) {
    cn.push(classes[`columnStart${!ownerList ? grid[0] - 1 : grid[0]}`]);
    cn.push(classes[`columnEnd${grid[1]}`]);
    cn.push(classes[`rowStart${grid[2]}`]);
    cn.push(classes[`rowEnd${grid[3]}`]);
  }
  return cx(cn);
};

const CustomTable = (props) => {
  const {
    classes,
    list,
    meta,
    // dataSource,
    calcDataRowClass,
    setId,
    fields: {
      tableFields,
      checkbox,
      pagination: needPagination,
      selectAllCheckbox,
    },
    isOwner,
    // ownerList,
    favorites,
    noNeedAllCheckbox,
  } = props;

  const total = props.total || getTotal(list, meta);

  if (total === 0) return null;

  const listWithPermissions = checkbox
    ? list.map((item) => ({
        ...item,
        havePermissions: hasPermissionForDelete(item, isOwner),
      }))
    : list;

  const havePermissionsList = Array.isArray(listWithPermissions)
    ? listWithPermissions.filter(({ havePermissions }) => havePermissions)
    : [];
  // const needAllCheckbox = !!(checkbox && selectAllCheckbox && ownerList);
  const itIsFavorite = (item) =>
    "id" in item ? !!favorites.find(({ id }) => id === item.id) : false;

  return (
    <div className={classes.tableWrapper}>
      <Table
        className={cx(classes.table, classes.mobileBlock, props.customClass)}
        id={setId("table")}
      >
        <TableHead
          {...props}
          needAllCheckbox={!noNeedAllCheckbox ? true : false}
          checkbox={checkbox}
          getClasses={getClasses}
          selectAllCheckbox={selectAllCheckbox}
          havePermissionsList={havePermissionsList}
          tableFields={tableFields}
        />
        <TableBody
          {...props}
          itIsFavorite={itIsFavorite}
          checkbox={checkbox}
          getClasses={getClasses}
          tableFields={tableFields}
          listWithPermissions={listWithPermissions}
          calcDataRowClass={calcDataRowClass}
        />
        {needPagination && <TableFooter {...props} total={total} />}
      </Table>
    </div>
  );
};

CustomTable.propTypes = {
  fields: PropTypes.object.isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  getText: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object,
  onCheckItem: PropTypes.func.isRequired,

  createSortHandler: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  pagination: PropTypes.func,
  changeCount: PropTypes.func,

  list: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,

  labelDisplayedRows: PropTypes.string,
  labelRowsPerPage: PropTypes.string,
  onSelectAllClick: PropTypes.func,
  calcDataRowClass: PropTypes.func,
  highlightClaim: PropTypes.bool,
  needFullData: PropTypes.bool,
  isOwner: PropTypes.func,
  ownerList: PropTypes.bool,
  favorites: PropTypes.array,
  dontHaveDelPerLabel: PropTypes.string,
  delLabel: PropTypes.string,
  customClass: PropTypes.string,
  usePagination: PropTypes.bool,
};

CustomTable.defaultProps = {
  dataSource: {},
  checked: [],
  labelDisplayedRows: "COUNT_CASES",
  labelRowsPerPage: "DISPLAYED_CASES",
  onSelectAllClick: undefined,
  highlightClaim: false,
  needFullData: false,
  isOwner: undefined,
  pagination: undefined,
  changeCount: undefined,
  ownerList: true,
  createSortHandler: undefined,
  onCheckboxClick: () => null,
  favorites: [],
  dontHaveDelPerLabel: "",
  customClass: "",
  delLabel: "Вибрати",
};

export default withStyles(styles)(CustomTable);
