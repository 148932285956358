import React from "react";
import PropTypes from "prop-types";
// import IconButton from '@material-ui/core/IconButton';
// import {List} from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import setComponentsId from "../../../helpers/setComponentsId";

import { formatUserName } from "../../../helpers/userName";

import statuses from "../../../variables/claimStatuses";

import { ExecuteStatusChip, Table } from "../../../components";
import StatusChip from "../../../components/StatusChip";
import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";
import redirectes from "../../../helpers/documentRedirected";
import { ReturnAndLocateInTableHOC } from "../../../components/ReturnAndLocateInTableHOC";
import openInNewWindowStyles from "../../../variables/styles/openInNewWindow";

const defaultFields = () => ({
  pagination: true,
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 6],
  },
  tableFields: [
    {
      key: "fullName",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCell", "onlyMobile"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "description",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCell", "onlyBigScreen"],
    },
    {
      key: "state",
      sort: true,
      title: "DOCUMENT_STATUS",
      classNames: ["cell", "textCentered", "shortCell"],
      grid: [2, 7, 3, 4],
    },
    {
      key: "court",
      sort: false,
      title: "COURT_NAME",
      classNames: ["cell", "onlyBigScreen"],
    },
    {
      key: "courtTitle",
      classNames: ["cell", "onlyMobile"],
      grid: [2, 7, 4, 5],
    },
    {
      key: "courtName",
      classNames: ["cell", "onlyMobile"],
      grid: [2, 7, 5, 6],
    },
    {
      key: "ownerName",
      sort: false,
      title: "OWNER",
      classNames: ["cell"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "createdAt",
      sort: true,
      title: "DOCUMENT_CREATING_DATE",
      classNames: ["cell", "shortCell", "onlyBigScreen"],
    },
    {
      key: "goToProcessing",
      title: "CASE_PROC_NUMBERS",
      classNames: ["cell", "shortCell"],
      grid: [2, 7, 6, 7],
    },
  ],
  filters: ["courtId", "courtType", "justiceType", "createdAt"],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
});

const executiveFields = {
  pagination: true,
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 6],
  },
  tableFields: [
    {
      key: "fullName",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCell", "onlyMobile"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "description",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCell", "onlyBigScreen"],
    },
    {
      key: "executiveState",
      sort: true,
      title: "DOCUMENT_STATUS",
      classNames: ["cell", "textCentered", "shortCell"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "ownerName",
      sort: false,
      title: "RECORDER",
      classNames: ["cell"],
      grid: [2, 7, 3, 4],
    },
    {
      key: "COLLECTOR",
      sort: false,
      title: "COLLECTOR",
      classNames: ["cell"],
      grid: [2, 7, 4, 5],
    },
    {
      key: "DEBTOR",
      sort: false,
      title: "DEBTOR",
      classNames: ["cell"],
      grid: [2, 7, 5, 6],
    },
    {
      key: "createdAt",
      sort: true,
      title: "DOCUMENT_CREATING_DATE",
      classNames: ["cell", "shortCell", "onlyBigScreen"],
    },
  ],
  filters: ["courtId", "courtType", "justiceType", "createdAt"],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
};

const approvalFields = {
  pagination: true,
  tableFields: [
    {
      key: "fullName",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCell", "onlyMobile"],
      grid: [2, 7, 1, 2],
    },
    {
      key: "description",
      sort: true,
      title: "DOCUMENT_TITLE",
      classNames: ["cell", "longCell", "onlyBigScreen"],
    },
    {
      key: "court",
      sort: false,
      title: "COURT_NAME",
      classNames: ["cell", "onlyBigScreen"],
    },
    {
      key: "courtTitle",
      classNames: ["cell", "onlyMobile"],
      grid: [2, 7, 4, 5],
    },
    {
      key: "courtName",
      classNames: ["cell", "onlyMobile"],
      grid: [2, 7, 5, 6],
    },
    {
      key: "ownerName",
      sort: false,
      title: "OWNER",
      classNames: ["cell"],
      grid: [2, 7, 2, 3],
    },
    {
      key: "createdAt",
      sort: true,
      title: "DOCUMENT_CREATING_DATE",
      classNames: ["cell", "onlyBigScreen"],
    },
  ],
  filters: ["createdAt"],
};

const getFields = (docCategory, approval, trash) => {
  if (approval) return approvalFields;

  if (docCategory === 4) return executiveFields;

  if (!trash) {
    return defaultFields();
  }

  const result = defaultFields();
  result.tableFields = result.tableFields.filter(
    (fld) => fld.key !== "goToProcessing"
  );

  return result;
};

const getCourts = (item, courts) => {
  let { courtId } = item;
  let courtName = "";
  if (!courtId) {
    const { digitalDocumentData } = item;
    const data =
      digitalDocumentData && digitalDocumentData[0] === "{"
        ? JSON.parse(digitalDocumentData)
        : {};
    courtId = (data.court || {}).court;
  }
  if (courtId && courts) {
    const foundCourt = courts.find(({ id }) => id === courtId);
    if (foundCourt) {
      courtName = foundCourt.name;
    }
  }
  return courtName || "";
};

const ClaimTable = ({
  t,
  list,
  highlightClaim,
  checked,
  dataSource,
  onSelectAllClick,
  createSortHandler,
  getTemplate,
  onCheckItem,
  handleClick,
  pagination,
  changeCount,
  setId,
  courts,
  docCategory,
  approval,
  history,
  trash,
  returnObject,
  classes,
}) => {
  const getText = (item, key) => {
    const {
      description,
      // digitalDocumentTemplateId,
      state,
      createdAt,
      ownerName,
      execMembers,
      documentStateInfo,
      eddStatuses,
    } = item;
    // const template = getTemplate(digitalDocumentTemplateId);
    switch (key) {
      case "fullName":
        return t("FULL_NAME", {
          number: description,
          createdAt: humanDateTimeFormat(createdAt),
        });
      case "description":
        return description;
      case "ownerName":
        return formatUserName(ownerName);
      case "COLLECTOR":
      case "DEBTOR":
        return formatUserName(
          ((execMembers || []).find((i) => i.type === key) || {}).name
        );
      case "court":
      case "courtName":
        return getCourts(item, courts);
      case "courtTitle":
        return `${t("COURT_NAME")} :`;
      case "createdAt":
        return humanDateTimeFormat(item[key]);
      case "state":
        return (
          <StatusChip
            setId={setId}
            status={state}
            tableChip={true}
            catStatuses={statuses}
            documentStateInfo={documentStateInfo}
          />
        );
      case "goToProcessing":
        if (!(item && item.caseId && item.proceedingId)) return null;

        return (
          <div
            className={classes.link}
            title={t("REDIRECT_TO_CASE")}
            onClick={(event) => {
              event.stopPropagation();
              sessionStorage.setItem("claim_documentId", item.id);
              returnObject.setReturnObject({
                form: "claims",
                id: item.id,
                filters: dataSource.filters,
                sort: dataSource.sort,
                search: dataSource.search,
                path: window.location.pathname + window.location.search,
              });
              history.push(redirectes[6].getUrl(item));
            }}
          >
            {item.caseNumber || "б/н"} <br />
            {item.procNumber || "б/н"}
          </div>
        );
      case "executiveState":
        return (
          <ExecuteStatusChip
            setId={setId}
            documentStateInfo={documentStateInfo}
            eddStatuses={eddStatuses}
          />
        );
      default:
        return item[key];
    }
  };
  return (
    <Table
      fields={getFields(docCategory, approval, trash)}
      checked={checked}
      getText={getText}
      setId={setId}
      dataSource={dataSource}
      onCheckItem={handleClick}
      createSortHandler={createSortHandler}
      onCheckboxClick={onCheckItem}
      pagination={pagination}
      changeCount={changeCount}
      list={list}
      t={t}
      labelRowsPerPage="COUNT_CLAIMS"
      labelDisplayedRows="DISPLAYED_CLAIMS"
      onSelectAllClick={onSelectAllClick}
      highlightClaim={highlightClaim}
      needFullData
      dontHaveDelPerLabel={t("DONT_HAVE_DELETE_PERMISSIONS")}
      delLabel={t("ADD_TO_DELETE")}
    />
  );
};
ClaimTable.propTypes = {
  list: PropTypes.array.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  highlightClaim: PropTypes.bool,
  trash: PropTypes.bool,
  checked: PropTypes.array.isRequired,

  onSelectAllClick: PropTypes.func.isRequired,
  createSortHandler: PropTypes.func.isRequired,

  getTemplate: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  pagination: PropTypes.func.isRequired,
  changeCount: PropTypes.func.isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  docCategory: PropTypes.number.isRequired,
  approval: PropTypes.bool.isRequired,
};

ClaimTable.defaultProps = {
  highlightClaim: false,
  trash: false,
  setId: setComponentsId("claim-table"),
  courts: null,
};

export default compose(
  withRouter,
  ReturnAndLocateInTableHOC,
  withStyles(openInNewWindowStyles)
)(ClaimTable);
