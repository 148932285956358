import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";

import {
  withStyles,
  Popover,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";

import { SettingsOutlined } from "@material-ui/icons";

import waiter from "../../helpers/waitForAction";
import styles from "../../variables/styles/filterInput";

import TemplateBuilder from "./TemplateBuilder";
import FilterChips from "./filterChips";
import FilterForm from "./FilterForm";
import ExecutiveFilterForm from "./ExecutiveFilterForm";
import dataSource, { DataSourceClass } from "../../helpers/dataSource";

const SEARCH_INTERVAL = 1000;

function createLocalDataSource(filters, search, aggs) {
  const ds = new DataSourceClass("localDataSource");
  ds.filters = filters;
  ds.search = search;
  ds.aggs = aggs;
  return ds;
}

class FilterInput extends Component {
  anchorEl = null;
  state = {
    open: false,
    openTemplateDialog: false,
    searchValue: "",
    isDirty: false,
    localDataSource: new DataSourceClass("localDataSource"),
    cursorPosition: 0, // Добавленная переменная состояния
    isMounting: true,
  };

  static getDerivedStateFromProps(props, state) {
    const { dataSource } = props;

    if (
      dataSource.filters !== state.localDataSource.filters ||
      dataSource.search !== state.localDataSource.search
    ) {
      return {
        localDataSource: createLocalDataSource(
          { ...(dataSource.filters || {}) },
          state.searchValue,
          { ...(dataSource.aggs || {}) }
        ),
        isMounting: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { dataSource } = this.props;
    const searchActualVal = this.state.searchValue || dataSource.search;

    this.props.history.listen((e) => {
      if (e?.pathname.includes("cases")) {
        this.setState({ searchValue: "" });
      }
    });

    if (this.props.saveSearchValueCallback)
      this.props.saveSearchValueCallback(
        searchActualVal > 0 ? searchActualVal : ""
      );

    dataSource &&
      this.setState({
        localDataSource: createLocalDataSource(
          { ...(dataSource.filters || {}) },
          searchActualVal,
          { ...(dataSource.aggs || {}) }
        ),
      });

    this.handleSearch({
      target: {
        value: searchActualVal,
        selectionStart: 0,
      },
    });
  }

  handleToggleFilters = () => {
    this.setState({ open: !this.state.open });
    const { localDataSource } = this.state;
    localDataSource.setSearch(this.state.searchValue);
    if (this.props.onCloseFilterCallback) {
      this.props.onCloseFilterCallback();
    }
  };

  handleSearch = ({ target: { value, selectionStart } }) => {
    this.setState({ searchValue: value?.length > 0 ? value : "" });
    this.setState({ cursorPosition: selectionStart, isDirty: true });
    if (this.props.saveSearchValueCallback)
      this.props.saveSearchValueCallback(value?.length > 0 ? value : "");

    const { localDataSource } = this.state;
    localDataSource.setSearch(value?.length > 0 ? value : "");
  };

  handleSearchApply = (value) => {
    const { dataSource } = this.props;
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.setSearch(value);

    if (this.props.saveSearchValueCallback)
      this.props.saveSearchValueCallback(value?.length > 0 ? value : "");
    this.load();
  };

  clearFilters = () => {
    const { localDataSource } = this.state;
    localDataSource.setSearch("");
    this.load();
    this.forceUpdate();
    this.setState({ searchValue: "" });
    this.props.returnObject?.setReturnObject({
      ...this.props.returnObject?.getReturnObject,
      search: "",
    });
    this.setState({ cursorPosition: 0 });
  };

  handleDeleteFilter = (filter) => () => {
    const { dataSource } = this.props;
    const { localDataSource } = this.state;
    delete dataSource.filters[filter];
    delete localDataSource.filters[filter];
    this.load();
  };

  load = async (force = false) => {
    const { dataSource, load } = this.props;

    const { localDataSource } = this.state;

    dataSource.filters = localDataSource.filters;
    dataSource.search = localDataSource.search;
    dataSource.aggs = localDataSource.aggs;

    dataSource.setValue("withSearch", true);
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.setValue("needSearch", false);
    await waiter.addAction(
      "dataSourceSearch",
      load || dataSource.load,
      SEARCH_INTERVAL
    );
    force === true && (await waiter.run("dataSourceSearch"));
    await waiter.onFinish(
      () => this.setState({ open: false }),
      "dataSourceSearch"
    );
    this.setState({ open: false });
  };

  renderFilters() {
    const { classes, statuses, chips, roles, forms, dataSource, hideStatus } =
      this.props;
    const { filters } = dataSource;
    if (dataSource.needSearch) return null;
    return (
      <div>
        {Object.keys(filters).map((filter) => {
          const pack = chips ? FilterChips[chips] : FilterChips;
          const Chip = (pack || {})[filter];
          if (filter === "proceeding" || filter === "case") return null;
          if (filter === "state" && hideStatus) return null;
          if (filter === "is_read") return null;
          if (filter === "sort") return null;
          if (filter === "execMyRole" || filter === "myRole") {
            return (
              <Chip
                key={filter}
                className={classes.chip}
                value={filters[filter]}
                list={roles}
                onDelete={this.handleDeleteFilter(filter)}
              />
            );
          }
          if (filter === "collectorIssuanceType") {
            return (
              <Chip
                key={filter}
                className={classes.chip}
                value={filters[filter]}
                list={forms}
                onDelete={this.handleDeleteFilter(filter)}
              />
            );
          }
          if (filter === "statusId" || filter === "vpStateId") {
            return (
              <Chip
                key={filter}
                filter={filter}
                className={classes.chip}
                value={filters[filter]}
                list={statuses}
                onDelete={this.handleDeleteFilter(filter)}
              />
            );
          }
          if (filter === "updatedAt")
            return (
              <Chip
                key={filter}
                className={classes.chip}
                value={filters[filter]}
                list={statuses}
                onDelete={this.handleDeleteFilter(filter)}
              />
            );
          if (filter === "dateForce" || filter === "docAcceptDate")
            return (
              <Chip
                key={filter}
                filter={filter}
                className={classes.chip}
                value={filters[filter]}
                list={statuses}
                onDelete={this.handleDeleteFilter(filter)}
              />
            );
          if (!Chip) return <div key={filter}>{filter}</div>;
          return (
            <Chip
              key={filter}
              className={classes.chip}
              value={filters[filter]}
              list={statuses}
              onDelete={this.handleDeleteFilter(filter)}
            />
          );
        })}
      </div>
    );
  }

  toggleTemplateDialog = () => {
    this.setState({ openTemplateDialog: !this.state.openTemplateDialog });
  };

  renderTemplateGenerator() {
    const { t, classes, dataSource } = this.props;
    const { openTemplateDialog } = this.state;
    return (
      <Fragment>
        <IconButton onClick={this.toggleTemplateDialog}>
          <SettingsOutlined />
        </IconButton>
        <Dialog
          open={openTemplateDialog}
          onClose={this.toggleTemplateDialog}
          aria-labelledby="simple-dialog-content"
        >
          <DialogTitle>{t("TEMPLATE_DIALOG_HEADER")}</DialogTitle>
          <DialogContent
            id="simple-dialog-content"
            className={classes.dialogContainer}
          >
            <DialogContentText className={classes.dialogHelpText}>
              {t("TEMPLATE_DIALOG_HELP_TEXT")}
            </DialogContentText>
            <TemplateBuilder
              dataSource={dataSource}
              load={() => {
                this.setState(
                  {
                    openTemplateDialog: !this.state.openTemplateDialog,
                  },
                  () => this.load(true)
                );
              }}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  onClearCallback = () => {
    this.setState({
      searchValue: "",
    });
    dataSource.sort = {};
    this.props.dataSource.load();
    this.props.onClearCallback();
  };

  renderSearchForm() {
    const { t, classes, setId, children } = this.props;
    const { open, localDataSource } = this.state;

    return (
      <Fragment>
        <IconButton
          style={{ width: "40px", height: "40px" }}
          onClick={this.handleToggleFilters}
          id={setId("dropdown-icon")}
        >
          <Icon style={{ fontSize: "35px" }}>arrow_drop_down</Icon>
        </IconButton>

        <Popover
          classes={{ paper: classes.popover }}
          anchorEl={this.anchorEl}
          id={setId("popover")}
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={(e) => this.handleToggleFilters(e, true)}
        >
          <Typography
            variant="h5"
            component="h3"
            id={setId("popover-header")}
            className={classes.popoverHeader}
          >
            <span>{t("FILTERS")}</span>
            <IconButton onClick={this.handleToggleFilters}>
              <Icon>close</Icon>
            </IconButton>
          </Typography>
          {this.props.isExecutiveDocs ? (
            <ExecutiveFilterForm
              {...{
                t,
                classes,
                setId,
                children,
                dataSource: localDataSource,
                load: this.load,
                onChange: this.props.onChange && this.props.onChange,
                value: this.props.value && this.props.value,
                name: this.props.name && this.props.name,
                onSearchCallback: this.props.onSearchCallback,
                onClearCallback: this.onClearCallback,
              }}
            />
          ) : (
            <FilterForm
              {...{
                t,
                classes,
                setId,
                children,
                dataSource: localDataSource,
                load: this.load,
              }}
            />
          )}
        </Popover>
      </Fragment>
    );
  }

  render() {
    const { classes, dataSource, children, chips, templates } = this.props;
    const setId = this.props.setId
      ? (elementName) => this.props.setId(`filters-${elementName}`)
      : (elementName) => `filters-${elementName}`;

    return (
      <div style={{ width: "inherit" }}>
        <div
          // style={{ display: "flex", aligItems: "flex-start", gap: "10px" }}
          className={classNames(classes.flex, classes.container, classes.grid)}
          ref={(ref) => {
            this.anchorEl = ref;
          }}
          id={setId("wrapper")}
        >
          <TextField
            style={{ width: "100%" }}
            id={setId("text-field")}
            placeholder="Пошук"
            value={
              this.state.searchValue ||
              this.state.localDataSource.search ||
              (!this.state.isDirty && dataSource.search) ||
              ""
            }
            onKeyUp={({ key }) => key === "Enter" && this.handleSearchApply()}
            onChange={(e) => this.handleSearch(e)}
            InputProps={{
              selectionStart: this.state.cursorPosition,
              selectionEnd: this.state.cursorPosition,
              endAdornment: (
                <>
                  <InputAdornment position="end" id={setId("close-icon")}>
                    {dataSource.search && (
                      <IconButton onClick={this.clearFilters}>
                        <Icon>close</Icon>
                      </IconButton>
                    )}
                    {templates && this.renderTemplateGenerator()}
                    {children && this.renderSearchForm()}
                  </InputAdornment>
                </>
              ),
            }}
          />

          <Button
            variant="outlined"
            style={{
              marginBottom: "5px",
              padding: "5px 13px",
              alignSelf: "flex-end",
            }}
            onClick={this.handleSearchApply}
          >
            <div
              style={{
                height: "22px",
                maxHeight: "22px",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                gap: window.innerWidth > 767 ? "5px" : "2px",
                margin: "0 auto",
              }}
            >
              <span>{this.props.t("SEARCH")}</span>
              <InputAdornment
                position="start"
                id={setId("search-icon")}
                style={{ padding: 0, margin: 0 }}
              >
                <Icon
                  style={{
                    fontSize: window.innerWidth > 767 ? "24px" : "16px",
                  }}
                >
                  search
                </Icon>
              </InputAdornment>
            </div>
          </Button>
        </div>
        {chips !== false && <div>{this.renderFilters()}</div>}
      </div>
    );
  }
}

FilterInput.propTypes = {
  load: PropTypes.func,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  children: PropTypes.node,
  statuses: PropTypes.array,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  courtTypes: PropTypes.array,
  justiceTypes: PropTypes.array,
  courts: PropTypes.array,
  templates: PropTypes.bool,
  chips: PropTypes.string,
  hideStatus: PropTypes.bool,
};

FilterInput.defaultProps = {
  load: undefined,
  statuses: [],
  courtTypes: [],
  justiceTypes: [],
  courts: [],
  children: "",
  templates: false,
  chips: "",
  hideStatus: false,
};

const TableFilterInput = compose(
  withStyles(styles),
  translate("ClaimList"),
  withRouter
)(FilterInput);

export default TableFilterInput;
