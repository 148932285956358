export default {
  // Claim list
  claim: "VyxpTisLzkw", // Створити заяву
  addClaim: "_Q9SxmOBeJ0", // Подача заяви
  addClaimIE: "WZlQ1aoI-D4", // Подача заяви(ФОП)
  addClaimShare: "U7zsXRIWBlI", // Довіреності
  addClaimShareIE: "U7zsXRIWBlI", // Довіреності(ФОП)
  selectTemplates: "LrrlKkovTTw", // Шаблон заяви
  // Claim wizard
  court: "Mn7SPN0s5GE", // Регіон та суд
  claimant: "5GxJj862lKM", // Позивач
  applicant: "O9AIy5z84_Y", // Заявник
  creditor: "Yf5HtfY6-FQ", // Кредитор
  representativeClaimant: "VfSwBYAX9tc", // Представник позивача
  defendant: "UyImqczFzQw", // Відповідач
  otherPerson: "TTtduItwvUc", // Інші учасники
  price: "tmaxlNy8b18", // Ціна позову
  form: "wwqfq4EZHow", // Текст заяви
  form2: "45jgqx4sSko", // Текст заяви для вторинних документів
  confirmNoSameClaim: "QkxfBuXE6cI", // Підтвердження
  attaches: "zU3IpuKyViQ", // Додатки
  payment: "02zOFJqIYm8", // Судовий збір
  representativeCreditor: "cmWyM2vNMro", // Представник кредитора
  debitor: "XiSeIPMY9E8", // Боржник
  representativeApplicant: "nZkJFEkKs-g", // Представник заявника
  representativeComplainant: "RDUCFNYiXiw", // Представник скаржника
  interestedPerson: "fW0aUFHFYyg", // Зацікавлена особа
  complainant: "sxEO2NkKgRc", // Скаржник
  READY_TO_SEND_STATUS: "sJjpab7X3ZQ", // Сформована заява,
  prosecutor: "N1wzWcx2kjs", // Прокурор
  checkboxesPage: "XzRbfV-QvMs", // Порушення
  // Pages
  claims: "cuxMBG8Jk1o", // Заяви
  notifications: "zLUl1rVkWKk", // Повідомлення
  trash: "dbhe3jJs3D0", // Кошик
  favorites: "5zKJ-5ivSxQ", // Вибране
  userProfile: "n6gAjfBqFWU", // Профіль користувача
  userProfileLegal: "suHs3brTCK8", // Профіль користувача для Юридичної особи
  myDocuments: "LcUhbwmAI6w", // Мої документи
  cases: "beBaAk8T2wg", // Мої справи (главная)
  signing: "beBaAk8T2wg", // Мої справи (подписание доверенности)
  caseList: "beBaAk8T2wg", // Мої справи (главная)
  documentView: "beBaAk8T2wg", // Мої справи (просмотр документа)
  documentCaseList: "_gHrO0C_2V4", // Мої справи (список документов по делу)
  procList: "pS-tXJ8iGLw", // Мої справи (провадження)
  documentProcList: "_gHrO0C_2V4", // Мої справи (документи по провадженню)
};
