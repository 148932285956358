export const formatMoney = (
  sum: number | string,
  currency?: string | undefined
) => {
  const formatSum = Math.round(Number(sum) * 100) / 100;
  return formatSum <= 0
    ? ""
    : Number.isInteger(Number(formatSum))
    ? `${Number(formatSum)}`
    : `${Number(formatSum).toFixed(2)}`;
};
