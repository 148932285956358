/* eslint key-spacing: ['error', { 'mode': 'strict' }] */

// ##############################
// // // App styles
// #############################

import { drawerWidth, transition, container } from "../../variables/styles.jsx";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      minHeight: "100%",
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    padding: "30px 15px",
    minHeight: "calc(100% - 123px)",
    "@media (max-width:959px)": {
      // eslint-disable-line no-useless-computed-key
      padding: "15px 0",
    },
  },
  noSidebarContent: {
    padding: "30px 15px",
    minHeight: "calc(100% - 123px)",
    textAlign: "center",
  },
  container,
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
