export default {
  grid: {
    display: "flex",
    aligItems: "flex-start",
    gap: "10px",
    "@media (max-width:500px)": {
      display: "grid",
      gridTemplateColumns: "100%",
      aligItems: "start",
      gap: "10px",
    },
  },
  container: {
    minWidth: "50%",
  },
  flex: {
    flex: 1,
    maxWidth: "100%",
    "& > div": {
      maxWidth: "100%",
    },
  },
  dropdown: {
    padding: 10,
  },
  popover: {
    padding: 10,
    maxHeight: "calc(100vh - 100px)",
  },
  pooperResponsive: {
    zIndex: 1105,
  },
  popperClose: {
    display: "none",
  },
  popoverHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  chip: {
    margin: 2,
    maxWidth: "100%",
    "& span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "block",
    },
  },
  dialogContainer: {
    paddingBottom: 10,
  },
  dialogHelpText: {
    marginBottom: 20,
  },
};
