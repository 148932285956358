import { requestDocument } from "../../../actions/cases";
import DataSource from "./dataSource";

const { procDocDataSource, myProcDocDataSource } = DataSource;

export function createSortHandler(property) {
  return () => {
    const { dataSource } = this.state;
    this.setState({ blockScreen: true });
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }
    dataSource.sort = { [property]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    this.load();
  };
}

// установка чекбокса в списке дел, напротив дела
export function setActiveCase(id, cb) {
  const { list } = this.props;
  let { activeCase } = this.props;
  if (id) {
    if (!activeCase || activeCase.id !== id) {
      activeCase = (list || []).find((item) => item.id === id);
    }
    this.setState(
      {
        checked: id,
        activeCase,
        blockScreen: false,
      },
      cb
    );
  } else {
    this.setState(
      {
        checked: id,
        activeCase: null,
        blockScreen: false,
      },
      cb
    );
  }
}

export function onCheckboxClick(id) {
  return () => this.setActiveCase(this.state.checked === id ? "" : id);
}

export function onProcCheckboxClick(id) {
  return () =>
    this.setState({ procChecked: id === this.state.procChecked ? "" : id });
}

export function onCheckItem(id, foo, nextDisplay = "") {
  return () =>
    this.setActiveCase(id, () => this.nextDisplay(nextDisplay || "procList"));
}

/**
 * Список производств по делу. Клик по строке.
 * @param id
 * @return {(function(): void)|*}
 */
export function onCheckProc(id) {
  return () => {
    const { proceedingsList } = this.props;
    const activeProc = Array.isArray(proceedingsList)
      ? proceedingsList.find((proc) => id === proc.id)
      : null;

    this.setState({ loading: true });
    procDocDataSource.setValue("params", { proceeding_id: id });
    myProcDocDataSource.setFilter("proceeding", id);
    myProcDocDataSource.load().then(() =>
      this.setState(
        {
          procChecked: id,
          activeProc,
        },
        () => this.nextDisplay("documentProcList")
      )
    );
  };
}

/**
 * Внутри производства по делу список документов. Клик по строке.
 * @param id
 * @return {function(): *}
 */
export function onViewDocument(id) {
  const { viewDocument } = this.state;
  if (!viewDocument || viewDocument.id !== id) {
    return () =>
      this.setState({ blockScreen: true }, () =>
        requestDocument(id).then((data) =>
          this.setState((prevState) => {
            return {
              viewDocument: data,
              scanDocumentId: id,
              blockScreen: false,
              procChecked: prevState.procChecked || (data && data.proceedingId),
            };
          })
        )
      );
  }
  return () => this.setState({ scanDocumentId: id });
}

export function onViewAttaches(id) {
  const { viewDocument } = this.state;
  if (id && (!viewDocument || viewDocument.id !== id)) {
    return () =>
      requestDocument(id).then((data) =>
        this.setState(
          {
            viewDocument: data,
            scanDocumentId: id,
          },
          () => this.nextDisplay("documentView")
        )
      );
  }
  return () =>
    this.setState({ scanDocumentId: id }, () =>
      this.nextDisplay("documentView")
    );
}

export function pagination(e, page) {
  const { dataSource } = this.state;
  const start = page * dataSource.count;
  dataSource.setValue("start", start);
  dataSource.setValue("page", page);
  this.load();
}

export function changeCount({ target: { value } }) {
  const { dataSource } = this.state;
  dataSource.setValue("start", 0);
  dataSource.setValue("count", value);
  dataSource.setValue("page", 0);
  this.load();
}
