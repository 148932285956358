import React from "react";
import PropTypes from "prop-types";

import humanDateFormat from "../../../helpers/humanDateFormat";
import customInputStyle from "../../../variables/styles/customInputStyle";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import cx from "classnames";
import { Button } from "../../../components";

const MarkDialog = ({
  t,
  openMarkDialog,
  classes,
  setId,
  toggleMarkDialog,
  updateActiveBookmark,
  newBookmarkName,
  addNewBookmarkName,
  userMetaData,
  saveBookmark,
  activeMark,
  deleteBookmark,
  dontAutoSaveState,
}) => {
  let bookmarks = [];
  let dontAutoSave = false;
  if (
    userMetaData &&
    userMetaData.meta &&
    userMetaData.meta.cases &&
    userMetaData.meta.cases.bookmarks
  ) {
    ({
      meta: {
        cases: { bookmarks },
      },
    } = userMetaData);
  }
  if (
    userMetaData &&
    userMetaData.meta &&
    userMetaData.meta.cases &&
    userMetaData.meta.cases.dontAutoSave
  ) {
    ({
      meta: {
        cases: { dontAutoSave },
      },
    } = userMetaData);
  }
  return (
    <Dialog
      open={openMarkDialog}
      fullWidth={true}
      onClose={toggleMarkDialog}
      maxWidth="md"
      id={setId("dialog-wrapper")}
      className={classes.dialog}
    >
      <DialogTitle
        id={setId("dialog-title")}
        className={classes.dialogContentWrappers}
      >
        {t("BOOKMARK")}
      </DialogTitle>
      <DialogContent
        id={setId("dialog-content")}
        className={classes.dialogContentWrappers}
      >
        <FormControl
          id={setId("new-bookmark-wrap")}
          className={cx(classes.formControl, classes.formMultiControl)}
        >
          <TextField
            id={setId("new-mark-name")}
            label={t("NEW_BOOKMARK_LABEL")}
            value={newBookmarkName}
            onChange={addNewBookmarkName}
          />
          <Button
            color="yellow"
            disabled={!newBookmarkName.length}
            onClick={saveBookmark(newBookmarkName, true)}
            id={setId("add-new-button")}
            setId={(elementName) => setId(`add-new-${elementName}`)}
          >
            {t("ADD_NEW_BOOKMARK")}
          </Button>
        </FormControl>
        <FormControl
          id={setId("select-mark-wrap")}
          className={classes.formControl}
        >
          <Typography id={setId("select-mark-title")}>
            {t("SELECT_BOOKMARK")}
          </Typography>
          <TextField
            select={true}
            onChange={updateActiveBookmark}
            value={activeMark}
            id={setId("mark")}
            SelectProps={{
              id: setId("select-mark"),
              SelectDisplayProps: {
                id: setId("select-mark-wrapper"),
              },
              MenuProps: { className: classes.menu },
            }}
          >
            {bookmarks.map((option, key) => (
              <MenuItem
                key={`${option.name}-${key}`}
                value={option.name}
                id={setId(`option-${option.name}`)}
                className={classes.menuItem}
                title={option.name}
              >
                {`${option.name} ${t("FROM")} ${humanDateFormat(
                  option.createdAt
                )}`}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          id={setId("delete-mark-wrap")}
          className={classes.formControl}
        >
          <Typography id={setId("select-mark-title")}>
            {t("DELETE_BOOKMARK")}
          </Typography>
          <TextField
            select={true}
            onChange={deleteBookmark}
            value={"auto"}
            id={setId("delete-mark")}
            SelectProps={{
              id: setId("select-delete-mark"),
              SelectDisplayProps: {
                id: setId("select-delete-mark-wrapper"),
              },
              MenuProps: { className: classes.menu },
            }}
          >
            {bookmarks.map((option, key) => (
              <MenuItem
                key={`${option.name}-${key}`}
                value={option.name}
                id={setId(`option-${option.name}`)}
                className={classes.menuItem}
                title={option.name}
              >
                {`${option.name} ${t("FROM")} ${humanDateFormat(
                  option.createdAt
                )}`}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControlLabel
          id={setId("form-control-dontAutoSaveState-checkbox-wrap")}
          control={
            <Checkbox
              checked={!dontAutoSave}
              onClick={dontAutoSaveState}
              color="primary"
              classes={{ checked: classes.checked }}
              id={setId("form-control-dontAutoSaveState-checkbox")}
            />
          }
          label={t("REMEMBER_STATE")}
        />
      </DialogContent>
      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
        id={setId("dialog-actions")}
      >
        <Button
          color="white"
          onClick={toggleMarkDialog}
          id={setId("close-button")}
          setId={(elementName) => setId(`close-${elementName}`)}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MarkDialog.propTypes = {
  updateActiveBookmark: PropTypes.func.isRequired,
  openMarkDialog: PropTypes.bool.isRequired,
  newBookmarkName: PropTypes.string,
  setId: PropTypes.func.isRequired,

  toggleMarkDialog: PropTypes.func.isRequired,

  addNewBookmarkName: PropTypes.func.isRequired,
  userMetaData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  activeMark: PropTypes.string,
  saveBookmark: PropTypes.func.isRequired,
  deleteBookmark: PropTypes.func.isRequired,
  dontAutoSaveState: PropTypes.func.isRequired,
};

MarkDialog.defaultProps = {
  newBookmarkName: "",
  userMetaData: {},
  activeMark: "auto",
};

export default withStyles(customInputStyle)(MarkDialog);
