export default {
  wrap: {
    width: 700,
    marginTop: 105,
    paddingLeft: 50,
    "@media (max-width: 767px)": {
      width: "100%",
      marginTop: 50,
      paddingLeft: 0,
    },
  },
  title: {
    padding: "0 12px",
    color: "#00224e",
    marginTop: 15,
    marginBottom: 20,
  },
  subtitle: {
    padding: "0 12px",
    color: "#2e2e2e",
    marginBottom: 20,
    textTransform: "inherit",
    fontSize: 18,
    lineHeight: "24px",
    fontFamily: "Helvetica",
  },
};
