const REQUEST_BENEFIT_TYPES_SUCCESS = "PAYMENTS/REQUEST_BENEFIT_TYPES_SUCCESS";
const REQUEST_PART_TYPES_SUCCESS = "PAYMENTS/REQUEST_PART_TYPES_SUCCESS";
const REQUEST_TRANSACTION_TYPES_SUCCESS =
  "PAYMENTS/REQUEST_TRANSACTION_TYPES_SUCCESS";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_BENEFIT_TYPES_SUCCESS:
      return { ...state, benefitTypes: action.payload };
    case REQUEST_PART_TYPES_SUCCESS:
      if (!Array.isArray(action.payload)) return state;
      return {
        ...state,
        partTypes: action.payload.sort((a, b) => a.name.localeCompare(b.name)),
      };
    case REQUEST_TRANSACTION_TYPES_SUCCESS:
      return { ...state, transactionTypes: action.payload };
    default:
      return state;
  }
};
