import {
  GET_LEGAL_SETTINGS,
  PUT_LEGAL_SETTINGS,
  SET_LEGAL_SETTINGS,
} from "../actions/legal-entity";

/**
 * @type {PersonalDataState}
 */
const initialState = {
  //если showAttachesPreview false то во вложениях отображает таблицу вместо превью
  showAttachesPreview: false,
  notSendIndividual: false,
  notSendRepresantative: false,
  onlyProcedural: false,
  emailAttachments: false,
  devices: [],
};

/**
 * @param {string} str
 */
function parseSettings(str) {
  try {
    if (typeof str === "string") {
      return JSON.parse(str);
    }
    return str;
  } catch (e) {
    return {};
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LEGAL_SETTINGS:
      return {
        ...state,
        ...payload,
      };
    case GET_LEGAL_SETTINGS + "_SUCCESS":
    case PUT_LEGAL_SETTINGS + "_SUCCESS": {
      const data = parseSettings(payload.settings);
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};
