import React from "react";
import PropTypes from "prop-types";

import { TableFooter, TablePagination, TableRow } from "@material-ui/core";
import useWindowSize from "../../hooks/useWindowSize";

const Footer = ({
  t,
  classes,
  dataSource,
  setId,
  pagination,
  changeCount,
  labelDisplayedRows,
  labelRowsPerPage,
  usePagination = true,
  total,
  count,
}) => {
  const size = useWindowSize();

  if (!usePagination) {
    return null;
  }

  return (
    <TableFooter id={setId("table-footer")} className={classes.bottomRow}>
      <TableRow id={setId("table-footer-row")}>
        <TablePagination
          id={setId("pagination")}
          className={classes.pagination}
          count={total ?? count}
          onChangePage={pagination}
          rowsPerPage={dataSource.count}
          labelRowsPerPage={size?.width > 575 ? t(labelRowsPerPage) : ""}
          labelDisplayedRows={({ from, to }) =>
            t(labelDisplayedRows, {
              from,
              to,
              total,
            })
          }
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={changeCount}
          page={dataSource.page}
          SelectProps={{
            SelectDisplayProps: { style: { width: "30px" } },
          }}
          backIconButtonProps={{ className: classes.pagButton }}
          nextIconButtonProps={{ className: classes.pagButton }}
        />
      </TableRow>
    </TableFooter>
  );
};

Footer.propTypes = {
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object,
  pagination: PropTypes.func,
  changeCount: PropTypes.func,
  usePagination: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,

  labelDisplayedRows: PropTypes.string,
  labelRowsPerPage: PropTypes.string,
  total: PropTypes.number.isRequired,
  count: PropTypes.number,
};

Footer.defaultProps = {
  dataSource: {},
  labelDisplayedRows: "COUNT_CASES",
  labelRowsPerPage: "DISPLAYED_CASES",
  pagination: undefined,
  changeCount: undefined,
};

export default Footer;
