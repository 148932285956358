import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import Select from "../../../components/Select";
import * as api from "../../../services/api";
import { CertByType } from "../../../components/withAdvocate";

type Props = {
  onChange: (data?: { type: CertByType; id: number }) => void;
  classes: any;
  byType?: CertByType | null;
  value?: number | null;
};

const loadRa = (): Promise<{ id: number; title: string }[]> => {
  return api.get("api/advocates/ra", "", () => {});
};

const loadKDKA = (): Promise<{ id: number; title: string }[]> => {
  return api.get("api/advocates/kdka", "", () => {});
};

type Option = {
  id: string;
  name: string;
  type: CertByType;
  dictionaryID: number;
};

function createID(id: number, type: CertByType) {
  return type + id;
}

function AuthorityDictionaryControl({
  onChange,
  classes,
  byType,
  value,
}: Props) {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    let result: Option[] = [];

    Promise.all([loadRa(), loadKDKA()]).then(([ra, kdka]) => {
      result = ra.map((item) => ({
        name: item.title,
        type: "RA" as const,
        dictionaryID: item.id,
        id: createID(item.id, "RA"),
      }));

      result.push(
        ...kdka.map((item) => ({
          name: item.title,
          dictionaryID: item.id,
          type: "KDKA" as const,
          id: createID(item.id, "KDKA"),
        }))
      );

      setOptions(result);
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      <Select
        multi={false}
        value={value && byType && createID(value, byType)}
        onChange={({ target: { value } }) => {
          const data = options.find((data) => data.id === value);

          if (!data) return onChange();

          onChange({
            type: data.type,
            id: data.dictionaryID,
          });
        }}
        options={options}
        selectProps={{
          textFieldProps: { label: "Орган, що видав свідоцтво*" },
        }}
      />
    </div>
  );
}

const styles = {
  wrapper: {
    marginBottom: 8,
  },
} as const;

export default withStyles(styles)(AuthorityDictionaryControl);
