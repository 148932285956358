export default {
  BACK: "Назад",
  BACK_TO_EDIT: "Повернутись до редагування",
  CASE_NUMBER: "Номер справи",
  CREATE_REQUEST: "Створити запит",
  COUNT_CASES: "з {{from}} по {{to}} із {{total}}",
  DOCUMENT_STATUS: "Статус",
  DATE: "Дата",
  DELETED: "Видалено",
  DISPLAYED_CASES: "Відображати:",
  DRAFT: "Чернетка",
  ERROR: "Виправте помилку та спробуйте знову",
  ERROR_INSIDE: "Внутрішня помилка",
  GET_RESPONSE_ERROR:
    "Відповідь на запит ще не сформована, повторіть спробу через деякий час",
  IN_DEVELOPMENT: "знаходиться в стадії розробки",
  MAIN_TITLE: "Державні реєстри",
  NO_ACCESS_TO_CASE: "Ви не маєте доступу до цієї справи як суддя",
  NO_PREVIEW: "Немає даних для перегляду",
  PREVIEW_LINK: "відкрити у новому вікні",
  REQ_NUMBER: "Номер",
  REQUEST_ERROR:
    "Помилка відправки до реєстру, перевірте склад та правильність заповнення параметрів запиту",
  REQUEST_ERROR_504:
    'На жаль, запит вимагає більш тривалого часу для виконання. Будь-ласка, перезавантажте сторінку через деякий час та перевірте наявність результату (статус "Отримано відповідь")..',
  CREATE_PREVIEW: "Сформувати запит",
  REQUEST_GET_RESPONSE: "Отримати відповідь",
  REQUEST_RESEND: "Надіслати повторно",
  REQUEST_SEND: "Надіслати запит",
  SAVE_TO_DEVICE: "Зберегти файл та електронний підпис архівом",
  SEND: "Надіслано",
  SERVICE_UNAVALABLE: "Сервіс тимчасово недоступний, спробуйте пізніше",
  SIGNING: "Підписання",
  SIGNING_DOCUMENT: "Підписання документа",
  SUCCESS: "Отримано відповідь",
  TEXT: "Текст запиту",
};
