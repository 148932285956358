import DataSource from "../helpers/dataSource";

const initialState = {
  list: null,
  vpStatuses: null,
  vdStatuses: null,
};

const dataSource = DataSource("api/executive/decisions");

export default (state = initialState, action) => {
  const { type } = action;
  state = dataSource.reducer()(state, action);

  switch (type) {
    case "REQUEST_VP_STATUSES_SUCCESS": {
      return { ...state, vpStatuses: action.payload };
    }
    case "REQUEST_VD_STATUSES_SUCCESS": {
      return { ...state, vdStatuses: action.payload };
    }
    default:
      return state;
  }
};

export const vpStatusesSelector = (state) => {
  return state.executiveOrders.vpStatuses;
};

export const vdStatusesSelector = (state) => {
  return state.executiveOrders.vdStatuses;
};
