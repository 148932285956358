import * as api from "../services/api";
import store from "../store";
import { TEMPLATES_URL } from "./document";
// import schemaTest from "./schemeTest.json";

export const REGISTER_GET_RESPONSE = "REGISTER_GET_RESPONSE";
export const STATE_REGISTERS = "STATE_REGISTERS";
export const REGISTER_REQ_LIST = "REGISTER_REQ_LIST";
export const REGISTER_REQ = "REGISTER_REQ";
export const CREATE_REGISTER_REQ = "CREATE_REGISTER_REQ";
export const PATCH_REGISTER_REQ = "PATCH_REGISTER_REQ_OBJ";
export const REQUEST_TEMPLATE = "REQUEST_TEMPLATE";
export const RESET_REGISTERS_STATE = "RESET_REGISTERS_STATE";
export const SEND_REGISTER_REQ = "SEND_REGISTER_REQ";
export const SET_ACTIVE_REGISTER = "SET_ACTIVE_REGISTER";

export const REGISTER_URL = "api/register";
const JUDGE_CASE_URL = "api/cases/my_as_judge";

export interface StateRegister {
  code?: string;
  id: number;
  name: string;
  statusId: number;
  templateId?: number;
}

export interface StateRegisterRequest {
  caseId?: string;
  caseNumber: string;
  createdAt?: Date;
  courtId: number;
  docId?: string;
  id: string;
  registerId: number;
  reqDate: Date;
  reqObject?: any;
  request?: any;
  response: any;
  statusId: number;
  text?: string;
  updatedAt?: Date;
}

export async function getRegisterList(): Promise<any> {
  const resp = await api.get(
    `${REGISTER_URL}`,
    STATE_REGISTERS,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function openRegister(register: StateRegister): Promise<any> {
  return store.dispatch({
    type: REGISTER_REQ_LIST,
    payload: register,
  });
}

export async function getRegisterReqList(registerId: number): Promise<any> {
  const resp = await api.get(
    `${REGISTER_URL}/${registerId}/request`,
    REGISTER_REQ_LIST,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function resetRegistersState(): Promise<any> {
  return store.dispatch({
    type: RESET_REGISTERS_STATE,
  });
}

export async function setActiveRegister(register: any): Promise<any> {
  return store.dispatch({
    type: SET_ACTIVE_REGISTER,
    payload: register,
  });
}

export async function getRegisterReq(
  requestId: string
): Promise<StateRegisterRequest> {
  const resp = await api.get(
    `${REGISTER_URL}/request/${requestId}`,
    REGISTER_REQ,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function getCaseForRegisterReq(caseNumber: string): Promise<any> {
  const resp = await api.get(
    `${JUDGE_CASE_URL}?filter[caseNumber]=${caseNumber}`,
    REGISTER_REQ,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  const foundCase = resp.find((item: any) => item.number === caseNumber);
  return foundCase;
}

export async function createRegisterReq(
  registerId: number,
  data?: any
): Promise<StateRegisterRequest> {
  const resp = await api.post(
    `${REGISTER_URL}/${registerId}/request`,
    data,
    CREATE_REGISTER_REQ,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function patchRegisterReq(
  requestId: string,
  data: any
): Promise<StateRegisterRequest> {
  const resp = await api.put(
    `${REGISTER_URL}/request/${requestId}`,
    data,
    PATCH_REGISTER_REQ,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function sendRegisterReq(
  requestId: string,
  data?: Partial<StateRegisterRequest>
): Promise<StateRegisterRequest> {
  const resp = await api.post(
    `${REGISTER_URL}/request/${requestId}/send`,
    data,
    SEND_REGISTER_REQ,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function registerRequestResult(
  requestId: string,
  data?: Partial<StateRegisterRequest>
): Promise<StateRegisterRequest> {
  const resp = await api.get(
    `${REGISTER_URL}/request/${requestId}/result`,
    REGISTER_GET_RESPONSE,
    store.dispatch
  );
  if (resp instanceof Error) throw resp;
  return resp;
}

export async function templateRequest(
  templateId: number | undefined
): Promise<any> {
  let result = await api.get(
    `${TEMPLATES_URL}/${templateId}`,
    REQUEST_TEMPLATE,
    store.dispatch
  );

  if (result instanceof Error) throw result;

  const { jsonSchema } = result;
  result = {
    ...result,
    // jsonSchema: schemaTest,
    jsonSchema: JSON.parse(jsonSchema),
  };
  return result;
}

export default {
  createRegisterReq,
  getCaseForRegisterReq,
  getRegisterList,
  getRegisterReq,
  getRegisterReqList,
  openRegister,
  patchRegisterReq,
  registerRequestResult,
  resetRegistersState,
  sendRegisterReq,
  setActiveRegister,
  templateRequest,
};
