import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip,
} from "@material-ui/core";
import cx from "classnames";
import { Button, BlockScreen } from "../../components";
import setComponentsId from "../../helpers/setComponentsId";
import VerifiedDialog from "../../components/SelectFilesDialog/VerifiedDialog";
import SelectFilesDialogContent from "../../components/SelectFilesDialog/SelectFilesDialogContent";

const errorTitles = {
  error: "ERROR_FILE_TITLE",
  nameError: "ERROR_ATTACH_TITLE",
};

const renderError = (classes, t, setId, errorName, error) => {
  const title = errorTitles[errorName];
  return (
    <Tooltip classes={{ tooltip: classes.errorToolTip }} title={t(title)}>
      <Typography
        className={classes.flex}
        align="left"
        color="error"
        id={setId("error")}
      >
        {error}
      </Typography>
    </Tooltip>
  );
};

const SelectFilesDialogLayout = ({
  t,
  classes,
  open,
  accept,
  maxSize,
  fileName,
  setId,
  fileLimit,
  claim,

  file,
  showEmptyNameError,
  busy,
  name,
  error,
  openVerifiedDialog,

  handleClose,
  handleChange,
  removeFile,
  onDrop,
  handleDone,
  toggleVerifiedDialog,
  uploadFile,
  nameError,
}) => {
  const getCurrentTitle = (title) =>
    t(`${title}${fileName ? `_${fileName.toUpperCase()}` : ""}`);

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby={setId("dialog-title")}
      id={setId("")}
      className={classes.dialog}
    >
      <DialogTitle
        id={setId("dialog-title")}
        className={classes.dialogContentWrappers}
      >
        {getCurrentTitle("UPLOAD_FILE")}
      </DialogTitle>
      <SelectFilesDialogContent
        classes={classes}
        setId={setId}
        t={t}
        busy={busy}
        handleChange={handleChange}
        getCurrentTitle={getCurrentTitle}
        itIsClaim={claim}
        name={name}
        showEmptyNameError={showEmptyNameError}
        file={file}
        removeFile={removeFile}
        accept={accept}
        fileLimit={fileLimit}
        maxSize={maxSize}
        onDrop={onDrop}
      />
      <DialogActions
        className={cx(classes.actions, classes.dialogContentWrappers)}
        id={setId("actions")}
      >
        {error && renderError(classes, t, setId, "error", error)}
        {nameError && renderError(classes, t, setId, "nameError", nameError)}
        <Button
          disabled={busy || !file || !!error || !!nameError}
          onClick={handleDone}
          color="yellow"
          id={setId("save-button")}
          setId={(elementName) => setId(`save-${elementName}`)}
        >
          {t("SAVE")}
        </Button>
      </DialogActions>
      <VerifiedDialog
        toggleVerifiedDialog={toggleVerifiedDialog}
        openVerifiedDialog={openVerifiedDialog}
        setId={setId}
        classes={classes}
        t={t}
        name={name}
        uploadFile={uploadFile}
      />
      <BlockScreen open={busy} />
    </Dialog>
  );
};

SelectFilesDialogLayout.propTypes = {
  fileName: PropTypes.string,
  fileLimit: PropTypes.object,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  accept: PropTypes.string,
  setId: PropTypes.func,
  maxSize: PropTypes.number,
  claim: PropTypes.bool,
  file: PropTypes.object,
  showEmptyNameError: PropTypes.bool.isRequired,
  busy: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  openVerifiedDialog: PropTypes.bool.isRequired,

  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
  toggleVerifiedDialog: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  nameError: PropTypes.string.isRequired,
};

SelectFilesDialogLayout.defaultProps = {
  fileName: undefined,
  open: false,
  accept: undefined,
  setId: setComponentsId("select-files-dialog"),
  maxSize: undefined,
  claim: false,
  file: null,
  fileLimit: null,
};

export default SelectFilesDialogLayout;
