import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";

const CriminalProcNumFilterChip = ({ t, value, ...rest }) => (
  <Chip label={`${t("ERDR_NUMBER")}: ${value}`} {...rest} />
);

CriminalProcNumFilterChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CriminalProcNumFilterChip.defaultProps = {
  value: "",
};

export default translate("ClaimList")(CriminalProcNumFilterChip);
