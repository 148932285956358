import Search from "../containers/Search";

const indexRoutes = [
  {
    path: "/",
    component: Search,
  },
  {
    redirect: true,
    path: "/",
    to: "/",
  },
];

export default indexRoutes;
