export default {
  SEARCH_PLACEHOLDER: "Пошук",
  MY_REQUESTS: "Мої заяви",
  FORM_GENERATOR: "Форм генератор",
  USER_PROFILE: "Профіль користувача",
  MY_PROFILE: "Мій профіль",
  PROFILE: "Профіль",
  MY_DOCUMENTS: "Мої документи",
  CONFERENCES_LIST: "Конференції",
  LOGOUT: "Вихід",
  CONFERENCES_NEW: "Нова конференція",
  CLAIM_LIST: "Заяви",
  READ_ALL: "Читати всі повідомлення...",
  ROLE_TITLE: "Увійти як:",
  UN_LEGAL: "Фізична особа",
  LEGAL: "Юридична особа",
  HOW: "Як",
};
