import { createStyles, WithStyles } from "@material-ui/core/styles";
import wizardStyles from "../../variables/styles/documentWizard";
import { WizardData, WizardSchema, EmptyWizardSchema } from "../SchemaWizard";

export const styles = createStyles(wizardStyles);

export interface DocumentTemplate {
  jsonSchema: WizardSchema;
  [key: string]: any;
}

export const EmptyDocumentTemplate: DocumentTemplate = {
  jsonSchema: EmptyWizardSchema,
};

export interface AttachInfo {
  attachId: number;
  contentType: string;
  fileHash?: string;
  fileName?: string;
  hashName?: string;
  link: string;
  userFileName?: string;
}

export interface Document {
  attaches?: AttachInfo[];
  digitalDocumentData?: WizardData;
  digitalDocumentTemplateId: number;
  id: string;
  state?: number;
  [key: string]: any;
}

export const EmptyDocument: Document = {
  id: "",
  digitalDocumentTemplateId: 0,
  digitalDocumentData: {
    activeStep: "",
  },
};

export interface DocumentWizardProps extends WithStyles<typeof styles> {
  isResendClaim?: boolean;
  caseId?: string;
  context: any;
  courtId: number;
  dictionary: any;
  documentId?: string;
  isRepresentative?: boolean;
  finishButtonText?: string;
  history: any;
  onClose?: (document?: Document) => Promise<void>;
  onDocumentCreated?: (document?: Document) => Promise<void>;
  onDocumentCommit?: (document?: Document) => Promise<void>;
  onDocumentLoaded?: (document?: Document) => Promise<void>;
  onReturn?: () => void;
  parentDocumentId?: string;
  proceedingId?: string;
  owner?: any;
  permissionId: any;
  returnTo?: string;
  returnToText?: string;
  setId: (s: string) => string;
  t: (key: string, params?: any[]) => string;
  templateId?: number;
  userInfo?: any;
  cardTitle?: any;
}

export interface DocumentWizardState {
  doc?: any;
  document?: Document;
  documentPermissions?: any;
  error?: string;
  pdf?: any;
  template?: DocumentTemplate;
}

export interface DocumentStepData {
  activeStep: string;
  [key: string]: any;
}

export interface DocumentCreateDto {
  caseId?: string;
  isEntrepreneur?: boolean;
  isRepresentative?: boolean;
  parentDocumentId?: string;
  permissionId?: string;
  proceedingId?: string;
  templateId: number;
}

export const defaultT = (key: string, params?: any[]): string => key;
