import getWizardProps from "../../../../helpers/getWizardProps";

export default function updateObjectValue(value, stepId, claim) {
  const {
    template: { jsonSchema },
  } = getWizardProps();
  const stepProps = jsonSchema.properties[stepId];

  const { digitalDocumentData } = claim;
  let stepData = (digitalDocumentData || {})[stepId] || {};

  if (stepProps.type !== "object" || Array.isArray(value)) {
    return claim;
  }

  Object.keys(value)
    .filter((key) => {
      if (
        !stepProps.properties ||
        !stepProps.properties[key] ||
        key === "isLegal"
      ) {
        return true;
      }
      return (
        !stepProps.properties[key].readonly &&
        (!stepData[key] || stepData[key] !== value[key])
      );
    })
    .forEach((key) => {
      const needClear = value[key] === null || value[key] === "";
      stepData[key] = needClear ? undefined : value[key];

      if (
        stepProps.properties &&
        stepProps.properties[key] &&
        stepProps.properties[key].value
      ) {
        stepData[key + "userDefined"] = needClear ? undefined : true;
      }
    });

  if (
    !Object.keys(stepData).filter((key) => stepData[key] !== undefined).length
  ) {
    stepData = undefined;
  }

  return Promise.resolve({
    ...claim,
    digitalDocumentData: { ...digitalDocumentData, [stepId]: stepData },
  });
}
