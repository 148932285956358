export default {
  registerContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
  },
  backArrow: {
    color: "#fff",
    marginRight: "5px",
  },
  registersLink: {
    title: "Єдиний державний демографічний реєстр",
    color: "#0059aa",
    cursor: "pointer",
    "&:hover": {
      color: "#034f94",
      borderBottom: "1px #034f94 solid",
      fontWeight: "bold",
    },
  },
  actions: {
    justifyContent: "flex-start",
  },
  flex: {
    flex: 1,
  },
};
