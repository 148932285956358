import React from "react";
import Downshift from "downshift";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";

import keycode from "keycode";

import {
  TextField,
  MenuItem,
  Chip,
  Paper,
  InputAdornment,
  IconButton,
  withStyles,
} from "@material-ui/core/";

import { Add } from "@material-ui/icons";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

renderSuggestion.defaultProps = {
  highlightedIndex: 0,
  index: 0,
  itemProps: {},
  selectedItem: "",
};

function getSuggestions(value, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : (suggestions || []).filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

class DownshiftMultiple extends React.Component {
  state = {
    inputValue: this.props.inputValue || "",
    selectedItem: this.props.selectedItem || [],
  };

  UNSAFE_componentWillReceiveProps({ selectedItem }) {
    if (Array.isArray(selectedItem)) {
      this.setState({ selectedItem });
    }
  }

  handleKeyDown = (event) => {
    const { inputValue, selectedItem } = this.state;
    if (
      selectedItem.length &&
      !inputValue.length &&
      keycode(event) === "backspace"
    ) {
      return this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1),
      });
    }

    if (inputValue.length && keycode.isEventKey(event, "enter")) {
      return this.handleChange(inputValue);
    }
    return null;
  };

  handleInputChange = ({ target: { value } }) => {
    if (value && !/[\wа-я]+?/gi.test(value)) {
      return;
    }
    this.setState({ inputValue: value });
  };

  handleChange = (item) => {
    const { onChange } = this.props;
    let { selectedItem } = this.state;

    if (selectedItem.indexOf(item) === -1) {
      selectedItem = [...selectedItem, item];
    }

    this.setState({ inputValue: "" }, () => onChange && onChange(selectedItem));
  };

  handleDelete = (item) => () => {
    const { onChange } = this.props;
    const { selectedItem } = this.state;
    selectedItem.splice(selectedItem.indexOf(item), 1);
    this.setState({ selectedItem }, () => onChange && onChange(selectedItem));
  };

  render() {
    const { classes, options, placeholder, label } = this.props;
    const { inputValue, selectedItem } = this.state;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                startAdornment: selectedItem.map((item) => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={this.handleDelete(item)}
                  />
                )),
                endAdornment: inputValue && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.handleChange(inputValue)}>
                      <Add />
                    </IconButton>
                  </InputAdornment>
                ),
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown,
                placeholder,
              }),
              label,
            })}
            {isOpen ? (
              <Paper className={classes.paper} square={true}>
                {getSuggestions(inputValue2, options).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.label,
                    }),
                    highlightedIndex,
                    selectedItem: selectedItem2,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
  inputValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

DownshiftMultiple.defaultProps = {
  inputValue: "",
  options: [],
  placeholder: "",
  label: "",
  selectedItem: "",
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export default withStyles(styles)(DownshiftMultiple);
