import React, { useState } from "react";
// @ts-ignore
import BlockScreen from "./BlockScreen";

export interface InjectedProps {
  showBlockScreen: (open: boolean) => void;
}

export const withBlockScreen =
  (defaultOpen = false, transparentBackground = false) =>
  <P extends InjectedProps = InjectedProps>(
    WrappedComponent: React.ComponentType<P>
  ) => {
    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithBlockScreen = (props: Omit<P, keyof InjectedProps>) => {
      const [open, showBlockScreen] = useState(defaultOpen);

      return (
        <>
          <WrappedComponent
            {...(props as P)}
            showBlockScreen={showBlockScreen}
          />
          <BlockScreen
            open={open}
            transparentBackground={transparentBackground}
          />
        </>
      );
    };

    ComponentWithBlockScreen.displayName = `withBlockScreen(${displayName})`;

    return ComponentWithBlockScreen;
  };
