export default {
  tooltip: {
    background: "rgba(240, 240, 240, 0.98)",
    borderRadius: 0,
    border: "1px solid #000",
    fontSize: "0.8em",
    color: "#000",
    boxShadow:
      "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
    "& span": {
      background: "rgba(240, 240, 240, 1)",
    },
  },
};
