import React from "react";

import setId from "../../helpers/setComponentsId";

import svgIcon from "../../assets/img/gear-loading-icon.svg";

const Preloader = () => (
  <div
    style={{ textAlign: "center", padding: "20px 20px 10px" }}
    id={setId("preloader-wrap")("")}
  >
    <img src={svgIcon} alt="Loading..." id={setId("preloader")("")} />
  </div>
);

export default Preloader;
